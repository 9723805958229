import { Button, useSnackbar } from "@walmart-web/livingdesign-components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getService } from "../../../../axios/axios";
import LoadComponent from '../../../../components/LoadComponent/LoadComponent';
import Timeline from "../../../../components/Timeline/Timeline";
import TimelineItem from "../../../../components/Timeline/TimelineItem";
import { BUDGET_SERVICE } from "../../../../constants/baseURLs";
import { NO_RESULTS_FOUND } from "../../../../constants/constants";
import { MOMENT_DATE_FORMAT } from "../../../../constants/dateFormats";
import { BUDGET_VIEW_VERSION_ERROR_MESSAGE } from '../../../../constants/messages';
import { getErrorMessage } from "../../../../helpers/utils";
import BudgetTimelineStyles from "./BudgetTimeline.styles";

// Format and Display the Rate history Date
const TimelineDate = ({ date }) => {
  const classes = BudgetTimelineStyles();
  const fDate = moment(date).format(MOMENT_DATE_FORMAT);
  return <strong className={classes.timelineDate}>{fDate}</strong>
}

// Format and Display the Narrative text along with Time
const TimelineHeaderDetails = ({ history, idnx }) => {
  const classes = BudgetTimelineStyles();
  const fTime = moment(history?.createdTs).format('h:mmA');
  const headline = (!history?.narrativeText && history?.currStatus && history?.prevStatus) ?
    <div key={idnx} id={idnx}>
      <b>{history?.createdBy}</b> {`Budget status changed from ${history?.prevStatus} to ${history?.currStatus} state`}
    </div> :
    <div key={idnx} id={idnx}>
      <b>{history?.createdBy}</b> {history?.narrativeText}
    </div>;

  return <div className={classes.timelineHeaderDetails}>
    <p>{headline}</p>
    <p className={classes.timelineDate}>{fTime}</p>
  </div>
}

const LoadResult = (props) => {
  const classes = BudgetTimelineStyles();
  const {budgetStatusHistory, groupStatusHistory, handleVersionClick, budgetDetail} = props;
  return budgetStatusHistory?.length > 0 ?
    <Timeline>
      {groupStatusHistory?.map((timeline, idx) => {
        return (
          <TimelineItem key={`${timeline?.date}`}>
            <TimelineDate date={timeline?.date} key={`${timeline?.date}`}/>
            {timeline?.statusHistory.map((timelineDetails, i) => {
              return (
                <div key={`${timelineDetails?.version}`} className={classes.historyGroups}>
                  {
                    timelineDetails?.statusHistories.map((history, idnx) => {
                      return (
                        <div className={classes.historyDetail} key={`${history}`}>
                          <TimelineHeaderDetails history={history} idnx={idnx} />
                          <p className={classes.versionNumber}>Version {timelineDetails?.version}</p>
                        </div>
                      )
                    })
                  }
                  {timelineDetails?.version !== budgetDetail?.version && <div className={classes.viewVersionButton}>
                    <Button
                      size="small"
                      variant="tertiary"
                      onClick={() => handleVersionClick(timelineDetails)}
                      data-testid={`view-version-btn-${idx}-${i}`}
                      id={`view-version-btn-${timelineDetails?.version}`}
                    >
                      View Version {timelineDetails?.version}
                    </Button>
                  </div>
                  }
                </div>
              );
            })}
          </TimelineItem>
        );
      })}
    </Timeline> : <React.Fragment>{NO_RESULTS_FOUND}</React.Fragment>;
}

const BudgetTimeline = (props) => {
  const { addSnack } = useSnackbar();
  const { budgetDetail } = useSelector((state) => state.budget);
  const [budgetStatusHistory, setBudgetStatusHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { setIsCurrent, fromMatter } = props;
  const loadComponentProps = {
    loader: loading,
    skeletonHeight: '18px',
    skeletonVariant: 'reactangle',
    skeletonWidth: '100%',
    skeletonLines: 1
  };

  /**
   * This function will use for
   * Get Budget timeline
   */
  const getBudgetTimeline = () => {
    setLoading(true);
    getService(
      BUDGET_SERVICE,
      `/budgets/v1/matters/${budgetDetail?.matterId}/organizations/${budgetDetail?.organizationId}/status-histories?returnActiveOnly=${false}`
    ).then((response) => {
      setBudgetStatusHistory(response.data.budgets);
      setLoading(false);
    })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  }

  useEffect(() => {
    getBudgetTimeline();
    return () => {
      setBudgetStatusHistory([]);
    };
  },[budgetDetail])

  /**
   * this gives an object with dates as keys
  **/
  const groups = budgetStatusHistory?.reduce((arr, status) => {
    const histories = status.statusHistories;
    histories.forEach((subgroup) => {
      const date = moment(subgroup.createdTs).format('YYYY-MM-DD');
      if (!arr[date]) {
        arr[date] = [];
      }
      const existsD = arr[date].find(element => {
        return element.id === status.id;
      });
      if (!existsD) {
        arr[date].push(status);
      }
    })
    return arr;
  }, {});

  /**
   * to add history in the array format
  **/
  const groupStatusHistory = groups && Object.keys(groups).map((date) => {
    return {
      date,
      statusHistory: groups[date]
    };
  }).sort((a, b) => new Date(b.date) - new Date(a.date));

  const handleVersionClick = (timelineDetails) => {
    if (timelineDetails?.id) {
      history.push(`/budget/${timelineDetails?.id}`, { fromMatter: fromMatter });
      setIsCurrent('Overview');
    } else {
      addSnack({
        message: BUDGET_VIEW_VERSION_ERROR_MESSAGE,
      });
    }
  }

  return (
    <div data-testid={"budgetTimeline"}>
      <LoadComponent {...loadComponentProps}>
        <LoadResult budgetStatusHistory={budgetStatusHistory} groupStatusHistory={groupStatusHistory} handleVersionClick={handleVersionClick} budgetDetail={budgetDetail}/>
      </LoadComponent>
    </div>
  );
};

export default BudgetTimeline;
