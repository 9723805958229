import types from "../types/invoiceCoordinatorActionTypes";

export const initialState = {
  peopleInvoiceData: {
    events: [],
    count: 0,
  },
  peopleInvoiceValues: {
    firstName: "",
    lastName: "",
    email: "",
    primaryOfficeLocation: "",
    tkid: "",
    classification: "",
    organization: "",
    phoneNo: "",
    role: "",
    jdYear: "",
    addressType : "",
    addressLine1 : "",
    addressLine2 : "",
    city : "",
    state : "",
    country : "United States",
    walmartApprovingAttorneyUserId:"",
    walmartApprovingAttorneyFirstName:"",
    walmartApprovingAttorneyLastName:"",
    walmartApprovingAttorneyEmailId:"",
    walmartApprovingAttorneyFullName:""
  },
  peopleInvoiceInitialValues: {
    firstName: "",
    lastName: "",
    email: "",
    primaryOfficeLocation: "",
    tkid: "",
    classification: "",
    organization: "",
    phoneNo: "",
    role: "",
    jdYear: "",
    approvalAttorney: "",
    addressType : "",
    addressLine1 : "",
    addressLine2 : "",
    city : "",
    state : "",
    country : "",
    walmartApprovingAttorneyUserId:"",
    walmartApprovingAttorneyFirstName:"",
    walmartApprovingAttorneyLastName:"",
    walmartApprovingAttorneyEmailId:"",
    walmartApprovingAttorneyFullName:""
  },
};

const InvoiceCoordinatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INPUT_VALUE: {
      const { name, value } = action.payload;
      return {
        ...state,
        peopleInvoiceValues: {
          ...state.peopleInvoiceValues,
          [name]: value,
        },
      };
    }
    case types.RESET_PEOPLE_INVOICE_VALUES:
      return {
        ...state,
        peopleInvoiceValues: initialState.peopleInvoiceValues
      }
    default:
      return state;
  }
};

export default InvoiceCoordinatorReducer;
