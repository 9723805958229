import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Breadcrumb, BreadcrumbItem, Grid,
  GridColumn, Heading,
  TextArea,
  useSnackbar
} from "@walmart-web/livingdesign-components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { postService } from "../../../../axios/axios";
import Label from "../../../../components/TextField/Label/Label";
import MatterAutocomplete from "../../../../components/Autocomplete/MatterAutocomplete/MatterAutocomplete";
import { EXTERNAL_USER_SERVICE, PRACTICE_AREA_SERVICE } from "../../../../constants/baseURLs";
import {
  CPR_TITLE, CURRENT_STANDARD_RATE,
  detailModes as modes, DISCOUNT, MATTER_NUMBER, MSR_BREADCRUMB_TITLE,
  MSR_BREADCRUMB_TITLE_PRACTICE_AREA, MSR_JUSTIFICATION_FOR_RATE,
  MSR_PRACTICE_AREA, MSR_TITLE, MSR_WALMART_ATTORNEY_NAME, MSR_WALMART_ATTORNEY_NAME_SELECT,
  PEOPLE, PRACTICE_AREA, PROPOSED_WALMART_RATE, RATE_END_DATE, RATE_START_DATE, REQUESTER_EMAIL,
  MIN_CALENDAR_DAYS,
  CS_IS_OC_INTERNAL_FILTER,
} from "../../../../constants/constants";
import { CREATE_PRATICE_AREA_SUCCESS_MSG, MSR_CREATE_SUCCESS_MSG, INVALID_DATE_RANGE_MESSAGE } from "../../../../constants/messages";
import {
  BACKEND_DATE_FORMAT, DATE_END_TIME, DATE_START_TIME} from "../../../../constants/dateFormats";
import { getErrorMessage, onInputNumberKeyDown, onInputNumberChange,renderTernary, convertPANameToCode } from "../../../../helpers/utils";
import { isValueValidList, isValueEmptyCheck } from "../../../../helpers/validationUtils";
import { resetMatterSpecificRateDetail, setMatterSpecificRateInputValue } from "../../../../store/actions/peopleAction";
import Detail from "../../../Detail/Detail";
import CreateMSRPARStyles from './CreateMSRPAR.styles';
import { convertToUTCDateTime } from "../../../../helpers/utcUtils";
import MuiValidationMessage from "../../../../Validation/MuiValidationMessage/MuiValidationMessage";
import { timekeeperValidationRules } from "../../../../Validation/validationRules";
import { useForm } from "react-hook-form";
import { filterPayload } from "../../../../helpers/commonUtils";
import { FeatureValueToggle } from '../../../../components/FeatureFlags/FeatureFlags';
import { FEATURES } from '../../../../constants/features';
import { Autocomplete, TextField } from '@mui/material';
import PeopleAutocompleteWithOptions from '../../../../components/Autocomplete/PeopleAutocompleteWithOptions/PeopleAutocompleteWithOptions';

const CHARACTER_LIMIT = 100;
/**
 * This component is used to only edit timekeeper profile.
 */
const CreateMSRPAR = () => {
  const classes = CreateMSRPARStyles();
  const dispatch = useDispatch();
  const [walmartAttorney, setWalmartAttorney] = useState('');
  const history = useHistory();
  const { state: { fromMatterSpecificRates, peopleIdentifier, organizationName, userName, selectedTab } = {} } = useLocation();
  const { userRequestId } = useParams();
  const { addSnack } = useSnackbar();
  const {
    matter,
    currentStandardRate,
    proposedWalmartRate,
    startDate,
    endDate,
    justificationForRate,
    practiceAreaCode,
    discount
  } = useSelector((state) => state?.people?.matterSpecificRateDetail);
  const practiceAreas = useSelector((state) => state?.matter?.practiceAreas);
  const adminTimekeeperDetail = useSelector((state) => state?.people?.adminTimekeeperDetail);
  const userDetails = useSelector(state => state?.user?.userDetail?.attributes);
  const { register, formState: { errors }, setError, clearErrors } = useForm({ mode: "all" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetMatterSpecificRateDetail({}));
    }
  }, [])
  /**
     * Gets called when data changes in input fields.
     * @param {object} e - input event
     */
  const onInputChange = (name, value) => {
    dispatch(setMatterSpecificRateInputValue(name, value));
  };
  const handleCreate = () => {
    setLoading(true)
    const baseUrl = fromMatterSpecificRates ? EXTERNAL_USER_SERVICE : PRACTICE_AREA_SERVICE;
    const urlPath = fromMatterSpecificRates ? `/matter-specific-rates/v1?isSubmitted=true` : `/practice-area-specific-rates/v1?isSubmitted=true`;
    const matterSpecificBody = {
      currentStandardRate: currentStandardRate,
      endDate: endDate ? convertToUTCDateTime({date: endDate, time: DATE_END_TIME}) : null,
      internalUserId: adminTimekeeperDetail?.identifier,
      matterId: matter?.matterId,
      matterName: matter?.matterName,
      matterNumber: matter?.referenceKey,
      outsideOrgJustification: justificationForRate,
      proposedWalmartRate: proposedWalmartRate,
      discount: discount,
      startDate: convertToUTCDateTime({date: startDate, time: DATE_START_TIME}),
      userId: adminTimekeeperDetail?.userId,
      userRequestId: userRequestId,
      walmartApprovingAttorneyEmailId: walmartAttorney?.email,
      walmartApprovingAttorneyFirstName: walmartAttorney?.firstName,
      walmartApprovingAttorneyFullName: `${walmartAttorney?.firstName} ${walmartAttorney?.lastName}`,
      walmartApprovingAttorneyLastName: walmartAttorney?.lastName,
      walmartApprovingAttorneyUserId: walmartAttorney?.userId,
    }
    const practiceAreaSpecificBody = {
      internalUserId: adminTimekeeperDetail?.identifier,
      userId: adminTimekeeperDetail?.userId,
      userRequestId: userRequestId,
      firstName: adminTimekeeperDetail?.firstName,
      lastName: adminTimekeeperDetail?.lastName,
      emailId: adminTimekeeperDetail?.email,
      contactNumber: adminTimekeeperDetail?.phoneNo,
      startDate: convertToUTCDateTime({date: startDate, time: DATE_START_TIME}),
      endDate: endDate ? convertToUTCDateTime({date: endDate, time: DATE_END_TIME}) : null,
      proposedWalmartRate: proposedWalmartRate,
      currentStandardRate: currentStandardRate,
      discount: discount,
      outsideOrgJustification: justificationForRate,
      walmartApprovingAttorneyEmailId: walmartAttorney?.email,
      walmartApprovingAttorneyFirstName: walmartAttorney?.firstName,
      walmartApprovingAttorneyFullName: `${walmartAttorney?.firstName} ${walmartAttorney?.lastName}`,
      walmartApprovingAttorneyLastName: walmartAttorney?.lastName,
      walmartApprovingAttorneyUserId: walmartAttorney?.userId,
      practiceAreaCode: convertPANameToCode(practiceAreaCode)
    }
    const body = fromMatterSpecificRates ? matterSpecificBody : practiceAreaSpecificBody;
    const selectedTab = fromMatterSpecificRates ? 1 : 2;
    const filteredBody=filterPayload(body)
    postService(baseUrl, urlPath, filteredBody)
      .then(() => {
        setLoading(false)
        history.push(`/people/${peopleIdentifier}/view`, { selectedTab: selectedTab })
        addSnack({
          message: fromMatterSpecificRates ? MSR_CREATE_SUCCESS_MSG : CREATE_PRATICE_AREA_SUCCESS_MSG,
        });
        window.scrollTo({ top: 0, behaviour: 'smooth' })
      })
      .catch((error) => {
          setLoading(false)
          addSnack({
            message: getErrorMessage(error)
          });
      });
  };

  let isDirty;
  if(fromMatterSpecificRates){
    isDirty = isValueValidList([matter, startDate, walmartAttorney?.firstName, proposedWalmartRate, currentStandardRate])
  }else{
    isDirty = isValueValidList([practiceAreaCode, startDate, walmartAttorney?.firstName, proposedWalmartRate, currentStandardRate])
  }
  /**
   * Gets called when clicked on cancel button.
   */
  const handleCancel = () => {
    const selectedTab = fromMatterSpecificRates ? 1 : 2;
    history.push(`/people/${peopleIdentifier}/view`, { selectedTab: selectedTab })
    window.scrollTo({ top: 0, behaviour: 'smooth' })
  };

  /**
   * Function to handle date picker error
   * @param {*} err
   * @param {*} dateType
   */
  const handleDateError = (err, dateType) => {
    if(err) {
      setError(dateType, { type: 'custom', message: INVALID_DATE_RANGE_MESSAGE })
    } else {
      clearErrors(dateType)
    }
  };

  useEffect(()=>{
    if(proposedWalmartRate === '0'){
      dispatch(setMatterSpecificRateInputValue('proposedWalmartRate', ''));
    }
    if(currentStandardRate === '0'){
      dispatch(setMatterSpecificRateInputValue('currentStandardRate', ''));
    }
  },[proposedWalmartRate, currentStandardRate])

  return (
    <div className={classes.listing}>
        <Breadcrumb className={classes.breadcrumb}>
          <BreadcrumbItem data-testid="org-breadcrumb" onClick={()=>history.push("/organization")}>{organizationName}</BreadcrumbItem>
          <BreadcrumbItem data-testid="people" onClick={() => history.push("/organization", { selectedTab: 1 })}>
            {PEOPLE}
          </BreadcrumbItem>
          <BreadcrumbItem data-testid="user-name" onClick={() => history.push(`/people/${peopleIdentifier}/view`, { selectedTab: selectedTab } )}>
            {userName}
          </BreadcrumbItem>
          <BreadcrumbItem data-testid="addrate-nav-title">
            {renderTernary(fromMatterSpecificRates, MSR_BREADCRUMB_TITLE, MSR_BREADCRUMB_TITLE_PRACTICE_AREA)}
          </BreadcrumbItem>
        </Breadcrumb>
      <Detail
        mode={modes.CREATE}
        setMode={() => { }}
        breadcrumbValues={[]}
        onCreate={handleCreate}
        onCancel={handleCancel}
        isDirty={isDirty}
        isValid={Object.keys(errors).length > 0? false: true}
        hideDeleteBtn
        loading={loading}
      >
        <div>
          <Heading as="h1" size="large">
            {renderTernary(fromMatterSpecificRates, MSR_TITLE, CPR_TITLE)}
          </Heading>
        </div>
        <Grid>
          <GridColumn sm={6} lg={6} md={6}>
            <div>
              <div className={classes.contentSpacing16}>
                <React.Fragment>
                  {renderTernary(
                    fromMatterSpecificRates,
                    <React.Fragment>
                      <Label text={MATTER_NUMBER} mandatoryField />
                      <MatterAutocomplete
                        value={matter}
                        setValue={(value) => onInputChange("matter", value)}
                        required={true}
                        register={register}
                        errors={errors}
                        name={"matter"}
                        validationRules={timekeeperValidationRules}
                      />
                    </React.Fragment>,
                    <React.Fragment>
                      <Label text={PRACTICE_AREA} mandatoryField />
                      <Autocomplete
                      multiple={false}
                      disablePortal
                      options={practiceAreas?.map(option=>option.name)}
                      value={practiceAreaCode}
                      renderInput={(params) => <TextField {...params} label="" placeholder={MSR_PRACTICE_AREA}  textFieldProps={{
                        name: "practiceAreaCode",
                      }}
                      />}
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(event,value) =>{onInputChange("practiceAreaCode", value)}}
                      id="edittimekeeperprofile-practice-area"
                      data-testid="edittimekeeperprofile-practice-area"
       							/>
                    </React.Fragment>)
                  }
                </React.Fragment>
              </div>
              <div className={classes.contentSpacing16}>
                <GridColumn sm={8}>
                  <React.Fragment>
                    <Label text={MSR_WALMART_ATTORNEY_NAME} mandatoryField />
                    <PeopleAutocompleteWithOptions
                      value={walmartAttorney}
                      setValue={(value) => {
                        setWalmartAttorney(value);
                      }}
                      placeholderValue={MSR_WALMART_ATTORNEY_NAME_SELECT}
                      filterCheck={false}
                      testid="edittimekeeperprofile-walmart-attorney-name"
                      additionalQuery={CS_IS_OC_INTERNAL_FILTER}
                    />
                  </React.Fragment>
                </GridColumn>
              </div>
              <div className={classes.contentSpacing16}>
                <Grid>
                  <GridColumn sm={6} className={classes.gridColumn}>
                    <React.Fragment>
                      <Label text={CURRENT_STANDARD_RATE} mandatoryField />
                      <TextField
                        onChange={(e) =>
                          onInputChange("currentStandardRate", e.target.value)
                        }
                        onKeyDown={(e) => onInputNumberChange(e)}
                        value={currentStandardRate}
                        name={"currentStandardRate"}
                        id={"currentStandardRate"}
                        type="number"
                        data-testid="edittimekeeperprofile-currentStandardRate"
                        InputProps={{
                          startAdornment: <span>$</span>,
                          endAdornment: <span>/hr</span>,
                          inputProps: {
                            min: 0,
                          }
                        }}
                        fullWidth={true}
                      />
                    </React.Fragment>
                  </GridColumn>
                </Grid>
              </div>
              <div className={classes.contentSpacing16}>
                <Grid>
                  <GridColumn sm={6} className={classes.gridColumn}>
                    <React.Fragment>
                      <Label text={PROPOSED_WALMART_RATE} mandatoryField />
                      <TextField
                        onChange={(e) =>
                          onInputChange("proposedWalmartRate", e.target.value)
                        }
                        name={"proposedWalmartRate"}
                        onKeyDown={(e) => onInputNumberChange(e)}
                        value={proposedWalmartRate}
                        type="number"
                        data-testid="edittimekeeperprofile-proposedWalmartRate"
                        id="proposedWalmartRate"
                        InputProps={{
                          startAdornment: <span>$</span>,
                          endAdornment: <span>/hr</span>,
                          inputProps: {
                            min: 0,
                          }
                        }}
                        fullWidth={true}
                      />
                    </React.Fragment>
                  </GridColumn>
                </Grid>
              </div>
              <div className={classes.contentSpacing16}>
                <Grid>
                  <GridColumn sm={6} className={classes.gridColumn}>
                    <React.Fragment>
                      <Label text={DISCOUNT} />
                      <TextField
                        onChange={(e) =>
                          onInputChange("discount", e.target.value)
                        }
                        name={"discount"}
                        onKeyDown={(e) => onInputNumberKeyDown(e)}
                        value={discount}
                        type="number"
                        data-testid="edittimekeeperprofile-discount"
                        InputProps={{
                          endAdornment: <span>%</span>,
                        }}
                        fullWidth={true}
                      />
                    </React.Fragment>
                  </GridColumn>
                </Grid>
              </div>
              <div className={classes.contentSpacing16}>
                <Grid>
                  <GridColumn sm={4} className={classes.gridColumn}>
                    <React.Fragment>
                      <Label text={RATE_START_DATE} mandatoryField />
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{width:"100%"}}
                          required={true}
                          value={renderTernary(startDate, moment(startDate), null)}
                          onChange={(e) => {
                            onInputChange(
                              'startDate',
                              e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                            );
                          }}
                          onError={(err) => {
                            handleDateError(err,'startDate')
                          }}
                          slotProps={{inputAdornment:{position:"end"},
                          textField:{"data-testid":"edittimekeeperprofile-startdate","id":"startDate",inputProps:{ style: { color: '#2E2F32' } }, name:"startDate"}}}
                          // FEATURE - TKBackdating - value toggle
                          minDate={FeatureValueToggle(FEATURES.TK_BACKDATING, moment().subtract(MIN_CALENDAR_DAYS, 'days'))}
                          maxDate={endDate ? moment(endDate) : null}
                        />
                      </LocalizationProvider>
                      {errors?.startDate && <MuiValidationMessage message={errors?.startDate.message} />}
                    </React.Fragment>
                  </GridColumn>
                  <GridColumn sm={4}>
                    <React.Fragment>
                      <Label text={RATE_END_DATE} />
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{width:"100%"}}
                          value={endDate ? moment(endDate): null}
                          onChange={(e) => {
                            onInputChange(
                              'endDate',
                              e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                            );
                          }}
                          onError={(err) => {
                            handleDateError(err,'endDate')
                          }}
                          slotProps={{inputAdornment:{position:"end"},
                          textField:{"data-testid":"edittimekeeperprofile-enddate",inputProps:{ style: { color: '#2E2F32' } },
                           name:"endDate"}}}
                          minDate={moment(startDate)}
                        />
                      </LocalizationProvider>
                      {errors?.endDate && <MuiValidationMessage message={errors?.endDate.message} />}
                    </React.Fragment>
                  </GridColumn>
                </Grid>
              </div>
              <div className={classes.contentSpacing16}>
                <React.Fragment>
                  <Label text={MSR_JUSTIFICATION_FOR_RATE} />
                  <TextArea
                    onChange={(e) =>
                      onInputChange(e.target.name, e.target.value)
                    }
                    value={justificationForRate}
                    maxLength={CHARACTER_LIMIT}
                    textAreaProps={{
                      name: "justificationForRate",
                    }}
                    data-testid="edittimekeeperprofile-justificationForRate"
                    id="justificationForRate"
                  />
                </React.Fragment>
              </div>
              <div className={classes.contentSpacing16}>
                <Label text={REQUESTER_EMAIL} />
              </div>
              <div className={classes.contentSpacing16}>
                {isValueEmptyCheck(userDetails?.emailId)}
              </div>
            </div>
          </GridColumn>
        </Grid>
      </Detail>
    </div>
  );
};

export default CreateMSRPAR;
