import types from '../types/matterActionTypes';

export const setMatterListingData = (payload) => {
	return { type: types.SET_MATTER_LISTING_DATA, payload }
}
export const setMatterDetail = (payload) => {
    return { type: types.SET_MATTER_DETAIL, payload }
}
export const setInputValue = (name, value) => {
  return { type: types.SET_INPUT_VALUE, payload: {name, value } }
}

export const saveInputValue = (section) => {
    return { type: types.UPDATE_MATTER_DETAIL_COPY, payload: { section } }
}

export const revertMatterDetail = (section) => {
    return { type: types.REVERT_MATTER_DETAIL, payload: { section } }
}

export const setNoteData = (payload) => {
    return { type: types.SET_NOTE_DATA, payload }
}

export const setMatterDetailSectionsBasedOnPracticeArea = (payload) => {
  return { type: types.SET_MATTER_DETAILS_SECTIONS_BASED_ON_PRACTICE_AREA, payload }
}

export const setMatterPracticeAreaDetail = (payload) => {
  return { type: types.SET_MATTER_PRACTICE_AREA_DETAIL, payload }
}

export const setMatterDefinitions = (payload) => {
  return { type: types.SET_MATTER_DEFINITIONS, payload }
}
export const setPracticeAreas = (payload) => {
    return { type: types.SET_PRACTICE_AREAS_DATA, payload }
}

export const setMatterDocumentsTabIndex = (payload) => {
    return { type: types.SET_MATTER_DOCUMENT_TAB_INDEX, payload }
}

export const deleteClaimsByIndex = (index) => {
  return { type: types.DELETE_CLAIMS_BY_INDEX, payload: { index } }
}

export const setClaimsInputValue = (index, key, value) => {
  return { type: types.SET_CLAIMS_INPUT_VALUE, payload: { index, key, value } }
}

export const setMatterClaimsList = (payload) => {
  return { type: types.SET_MATTER_CLAIMS_LIST, payload }
}

export const resetMatterClaimsById = (index) => {
  return { type: types.RESET_MATTER_CLAIMS_BY_ID, payload: { index } }
}
