import { LINE_ITEM_TYPE_FEE } from "../constants/constants";
/**
 * Utility function to check value is there or not - used for display
 * if value exists return value otherwise return dash
 * @param {string} item
 */
export const isValueEmptyCheck = (item) => {
  if (item === undefined || item === "") {
    return '-';
  } else {
    return item;
  }
}

/**
 * Utility function to check value(Currency) is there or not - used for display
 * if value exists return value otherwise return dash
 * @param {string} item
 */
export const isCurrencyValueEmptyCheck = (item) => {
  if (item === undefined || item === "") {
    return '-';
  } else {
    return `$${item}/hr`;
  }
}

/**
* Utility function to check value mandatory form validations
* return boolean
* @param {string} item
*/
export const isValueValid = (item) => {
  let flag = false
  if(item !== undefined && item !== "") {
    flag = true;
  }
  return flag;
}

export const isEmptyObj = (obj) => Object.keys(obj).length === 0;
export const isEmpty = (value) => value === null || value === "null" || value === undefined || value === ""
export const isString = value => typeof value === 'string' || value instanceof String;

// UTBMS code that starts with AFA
export const tkidRequiredOnlyForUtbmsPattern = /^(AFA)/;

/**
 * Utility function to check if arguments passed are valid
 * @param {*} list
 * @returns
 */
export const isValueValidList = (list) => {
  // Iterate through the list of values
  for (const item of list) {
    // Check if the current value is undefined or null
    if (isEmpty(item)) {
      // If any value is missing, return false
      return false;
    }
  }
  // If all values are present, return true
  return true;
}

/**
 * Utility function to conditionally check if TKID is mandatory for type fee
 * @param {*} type
 * @param {*} utbmsObj
 * @returns
 */
export const isTkidMandatory = (type = "", utbmsObj = "{}") => {
  // Check if utbmsValue is selected
  const utbmsValue = isString(utbmsObj) && JSON.parse(utbmsObj)?.value?.toUpperCase();
  // TKId is mandatory only if type is fee and utbms selected does not start with AFA
  return type?.toLowerCase() === LINE_ITEM_TYPE_FEE && utbmsValue && !tkidRequiredOnlyForUtbmsPattern.test(utbmsValue)
}


