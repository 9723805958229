import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from "react-jss";
import {GlassSearch} from '../../../constants/images';
import { SEARCH_CHAR_LIMIT } from '../../../constants/constants';
import TextField from "@mui/material/TextField";

const useStyles = createUseStyles({
  searchWrap: {
    width: 'calc(100% - 2em)',
    margin: '10px 0',
    "& svg": {
      zIndex: 1
    },
  },
  searchTextField: {
    '&:focus-visible': {
      outline: 'none',
    },
    ' & input': {
      fontSize: '14px !important',
      paddingLeft: '42px !important',
      marginLeft: '-35px',
      '&:focus-visible': {
        outline: 'none',
      },
      '&:focus': {
        background: '#f8f8f8'
      },
    },
    '& > div': {
      boxShadow: 'none !important',
      borderRadius: '8px',
      overflow: 'hidden',
      marginLeft: '10px',
      '&:focus-visible': {
        outline: 'none',
      },
      '&:focus': {
        background: '#f8f8f8'
      },
    },
    '& svg': {
      '&:focus-visible': {
        outline: 'none',
      },
      '&:focus': {
        background: '#f8f8f8'
      },
    },
  },
});

/**
 * SearchField component can be used to provide search functionality.
 *
 * @visibleName SearchField
 */

const SearchField = (props) => {
  const {onSearch, searchText, serverSide, placeHolder} = props;
  const [userSearchText, setUserSearchText] = useState(searchText);
  const [userSearchTextServerSide, setUserSearchTextServerSide] = useState(searchText);
  const classes = useStyles();
  const isFirstRun = useRef(true);

  /**
   * Fucntion to handle enterkey press for serverside search
   * @param {object} e - keydown event
   */
   const handleKeyDown = (e) => {
    if(serverSide === true) {
      const code = (e.keyCode ? e.keyCode : e.which);
      if(code === 13) { //Enter keycode
        setUserSearchTextServerSide(e.target.value);
      }
    }
  };

  /**
   * handleChange - method will be called after search
   * @param {*} value
   * @returns
   */
  const handleChange = (value) => {
    if(value && value?.length <= SEARCH_CHAR_LIMIT){
      setUserSearchText(value)
    }
  }

  useEffect(() => {
    if(!isFirstRun?.current && !serverSide) {
      onSearch(userSearchText)
    }
    if(!isFirstRun?.current && serverSide && userSearchText === '') {
      setUserSearchTextServerSide(userSearchText);
    }
  }, [userSearchText]);

  useEffect(() => {
    if(!isFirstRun?.current) {
      onSearch(userSearchText)
    }
  }, [userSearchTextServerSide]);

  useEffect (() => {
    if (isFirstRun?.current) {
      isFirstRun.current = false;
    }
  });

  return (
    <div className={classes.searchWrap}>
      <TextField
        id="search-field"
        data-testid="search-field"
        placeholder={placeHolder || "Search By"}
        className={classes.searchTextField}
        value={userSearchText || ''}
        onChange={(e) => e?.target?.value?.trim() !=="" ? handleChange(e.target.value) : setUserSearchText("")}
        onKeyDown={handleKeyDown}
        type="search"
        fullWidth={true}
        InputProps={{
          startAdornment: <GlassSearch />,
        }}
        sx={{
          "& fieldset": { border: 'none' },
        }}
      />
    </div>
  )
}

SearchField.propTypes = {
  /**
   * searchText is the value we get from input.
  * */
  searchText: PropTypes.string,
  /**
   * Gets called when user change the input value.
   *
   * @param {String} accepts search string
  * */
  onSearch: PropTypes.func
}

export default SearchField;
