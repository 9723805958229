import { createUseStyles } from "react-jss";

const InActivePeopleStyles = createUseStyles({
  rowStyle: {
    "& div>table>tbody>tr>td": {
      fontFamily: "Bogle",
      height: "24px",
      fontWeight: "normal",
      fontStrech: "normal",
      lineHeight: "1.33",
    },
  },
  exportName: {
    marginBottom: 20
  },
  exportNameAs: {
    paddingTop: 20,
    marginBottom: 20
  },
  modalSubText: {
    fontSize: 14,
    fontWeight: 'normal'
  },
  addNewBtnWrap: {
    margin: "0 0.8rem",
  }
});

export default InActivePeopleStyles;
