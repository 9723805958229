import React from "react";
import { AppRenderErrorPage } from "../AppRenderErrorPage/AppRenderErrorPage";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <AppRenderErrorPage
        onClickRetry={() => window.location.assign(window.location.origin)}
      />;
    }

    return this.props.children;
  }
}

