import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { USER_INACTIVITY_TIME } from '../../constants/constants';

/**
 * IdleTimer component tracks activity / interaction of user with the application
 * If timer exceeds 15 minutes, user will be logged out of the application
 * @returns
 */
export const IdleTimer = (props) => {
    const { logoutOnInactivity } = props;
    const location = useLocation();

    let timeout = null;

    // reset existing timeout & start new timer
    const restartAutoReset = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            logoutOnInactivity();
        },USER_INACTIVITY_TIME);
    };

    // method will be called on mousemove event
    const onMouseMove = () => {
        restartAutoReset();
    };

    // detecting change in location and adding eventlistner on mousemove
    useEffect(() => {
        // initiate timeout
        restartAutoReset();

        // listen for mouse events
        window.addEventListener('mousemove', onMouseMove);

        // cleanup
        return () => {
            if (timeout) {
                clearTimeout(timeout);
                window.removeEventListener('mousemove', onMouseMove);
            }
        };
    }, [location]);
    return <></>;
};
