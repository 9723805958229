import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setStarredItemsIds } from "../../store/actions/preferencesActions";

/**
 * Wrapper Function to set repective starred item's identifier list
 */
const withStarredItemsList = (OriginalComponent, preferenceTypeCode) => {

  function SetStarredIdsList() {
    const dispatch = useDispatch();
    const starredItems  = useSelector(state => state?.preferences?.userPreferences[preferenceTypeCode]);

    useEffect(() => {
      if(starredItems?.length > 0) {
        const idList = JSON.parse(starredItems)?.map(item => item?.identifier);
        dispatch(setStarredItemsIds(idList));
      }
    }, [starredItems])

    return (
      <OriginalComponent />
    )
  }

  return SetStarredIdsList;
}

export default withStarredItemsList;

