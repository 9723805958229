import { Grid, GridColumn, Link } from '@walmart-web/livingdesign-components';
import React from 'react';
import { PRIVACY_POLICY, PRIVACY_POLICY_LINK } from '../../../constants/constants';
import ThemisFooterStyles from './ThemisFooter.styles';
import { copyRightText } from '../../../constants/templates';

const ThemisFooter = () => {
  const classes = ThemisFooterStyles();
  const year = new Date().getFullYear();
  
  return (
    <div className={classes.footerContainer}>
      <Grid>
        <GridColumn lg={10}>
          <div className={classes.copyLeftStyle}>
            {copyRightText(year)}
          </div>
        </GridColumn>
        <GridColumn lg={2}>
          <div className={classes.copyRightStyle}>
            <Link href={PRIVACY_POLICY_LINK} target="_blank">
            {PRIVACY_POLICY}
            </Link>
          </div>
          {/* <Body as="span" size="medium" weight={400}>
            <span className={classes.bottomText}>
            {HELP}
            </span>
          </Body>
          <Body as="span" size="medium" weight={400}>
            {LANGUAGE}
          </Body>
          <ChevronDown size="small" />  will need in future*/}
        </GridColumn>
      </Grid>
    </div>
  );
};
export default ThemisFooter;
