import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MuiDataTable from "../../../components/Table/MuiDataTable/MuiDataTable";
import moment from 'moment';
import ActionPopover from "../../../components/Table/ActionPopover/ActionPopover";
import MoreTagsPopover from "../../../components/Table/MoreTagsPopover/MoreTagsPopover";
import AddFolderModal from "../../../components/Modal/AddFolderModal/AddFolderModal";
import EditTags from "../../../components/common/EditTags/EditTags";
import { Plus, Close } from "@walmart-web/livingdesign-icons";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { default as MUIModal } from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Refresh } from "@livingdesign/icons";
import DocumentPreview from "../../Document/DocumentPreview";
import {
  Caption,
  useSnackbar,
  ButtonGroup,
  Button,
  Heading,
} from "@walmart-web/livingdesign-components";
import TextField from "@mui/material/TextField";

import {
  ITEM_NAME,
  TAGS,
  ACTIONS,
  CREATE_DATE,
  CREATED_BY,
  LINK_COPIED,
} from "../../../constants/constants";
import { DATE_YYYY_MM_DD, MOMENT_DATE_FORMAT } from "../../../constants/dateFormats";
import { TAGS_EDITED_SUCCESS_MSG, FILE_RENAME_MSG } from "../../../constants/messages";
import { ROWS_PER_PAGE_DOCUMENTS, ROWS_PER_PAGE_OPTIONS_DOCUMENTS } from "../../../constants/tableConfigs";
import { SAVE, CANCEL, EXPORT, SHARE, REFRESH } from "../../../constants/actionButtons";
import {
  Folder,
  File,
  Star
} from "../../../constants/images";
import { postService, postServiceWithHeaders } from "../../../axios/axios";
import {
  setDocumentBreadcrumbIndex,
  setDocumentsMetadata,
  setDocumentsPath,
  setFolderSearchQuery, updateBreadCrumbFolderData, setDocumentsData
} from "../../../store/actions/documentActions";
import { getErrorMessage, isEmptyObj } from "../../../helpers/utils";
import { NODE_JS_URL } from "../../../constants/baseURLs";
import DocumentListStyles from "./DocumentList.styles";
import StarItem from "../../../components/StarItem/StarItem";



/**
 * Add new action items for sub folder
 */
const addNewActionItems = [
  {
    "actionId": 0,
    "label": "Document",
    "isAboveDivider": true
  },
  {
    "actionId": 1,
    "label": "Folder",
    "isAboveDivider": true
  }
]
/**
 * Component to return action popover on add new button
 * @returns
 */
const AddNewActionPopover = ({ handleAddNew }) => {
  return (
    <ActionPopover
      actionItems={addNewActionItems}
      onClickOption={(selectedId) => { handleAddNew(selectedId) }}
      data-testid="add-new-action-btn"
      buttonIcon={<Plus />}
      buttonText={"Add New"}
    />
  )
}

/**
* Document preview in modal
*/
const DocumentPreviewModal = (setIsPreviewOpen, isPreviewOpen, previewModalFileData, fromMatter) => {
  const classes = DocumentListStyles();
  return (
    <MUIModal
      onClose={() => setIsPreviewOpen(false)}
      open={isPreviewOpen}
      size="large"
      aria-labelledby="document-preview-modal-title"
      data-testid="document-preview-modal"
    >
      <Box className={classes.documentPreviewModalBox}>
        <div className={classes.documentPreviewModalTitleWrap}>
          <Heading
            as="h2"
            size="medium"
            weight={700}
            id="document-preview-modal-title"
          >
            {previewModalFileData.docTitle}
          </Heading>
          <Close
            data-testid="document-preview-modal-close"
            id="document-preview-modal-close"
            onClick={() => setIsPreviewOpen(false)}
            className={classes.closeMuiModal} />
        </div>
        <DocumentPreview openInModal={true} fromMatter={fromMatter} />
      </Box>
    </MUIModal>
  )
}

/**
 * DocumentList component used to see the list of documents and files.
 *
 * @author [Ratheesh]
 */
const DocumentList = (props) => {
  const [disableAllStar, setDisableAllStar] = useState(false);

  const fileActionItems = [
    {
      "actionId": 0,
      "label": "Preview",
      "isAboveDivider": true
    },
    {
      "actionId": 1,
      "label": "Rename",
      "isAboveDivider": true
    },
    {
      "actionId": 2,
      "label": "Edit Tags",
      "isAboveDivider": true
    },
    {
      "actionId": 3,
      "label": SHARE,
      "isAboveDivider": true
    },
    {
      "actionId": 4,
      "label": EXPORT,
      "isAboveDivider": true
    }
  ];

  const renderFileId = (value, tableMeta, updateValue) => {
    return (
      <>
        {value !== undefined ?
          <StarItem
            preferenceCode="starredDocuments"
            identifier={value}
            customClassName={`dataTableStarButton`}
            disableAllStar={disableAllStar}
            handleDisableAllStar={setDisableAllStar}
          />
          : <></>
        }
      </>
    )
  }

  const renderFolder = (value, tableMeta) => {
    return (
      <div
        className={classes.itemName}
        data-testid="folder-btn"
      >
        {(value) ?
          <Folder fontSize="small" style={{ color: "#ffd75e" }} />
          :
          <File fontSize="small" />}
      </div>
    )
  }

  const renderItemName = (value, tableMeta, updateValue) => {
    const cellId = `Mui-file-name-row-${tableMeta?.rowIndex}`;
    return (
      <div
        className={classes.itemName}
        data-testid="file-btn"
        id={`file-btn-${value}`}
      >
        {(tableMeta?.tableData[tableMeta?.rowIndex][16]) ? (
          <TextField
            onChange={(event) => itemNameChange(event, updateValue)}
            onClick={(event) => itemNameClick(event)}
            type="text"
            value={value}
            data-testid="rename-text-field"
            id="rename-text-field"
            fullWidth={true}
          />
        ) : (
          <Caption as="p" id={cellId}>
            {value}
          </Caption >
        )}
      </div>
    )
  }

  const renderTags = (tags, tableMeta) => {
    return (
      tableMeta?.rowData[2] !== true ? (
        <MoreTagsPopover
          tags={tags}
          tagLimit={TAGS_LIMIT}
          id={tableMeta?.rowIndex}
        />
      ) : null
    );
  }

  const renderCreatedTs = (value, tableMeta) => {
    return (
      tableMeta?.rowData[2] !== true ? (
        <Caption as="p">
          {moment(value).format(MOMENT_DATE_FORMAT)}
        </Caption>
      ) : null
    )
  }

  const renderCreatedBy = (value, tableMeta) => {
    return (
      tableMeta?.rowData[2] !== true ? (
        <Caption as="p">
          {value}
        </Caption>
      ) : null
    )
  }

  const renderHeadLabelFileId = () => {
    return <Star size="small" className={classes.starColumnHeader} />
  }

  const renderHeadLabelItemName = () => {
    return <div data-testid="headcol-0">{ITEM_NAME}</div>
  }

  const renderHeadLabelTags = () => {
    return <div data-testid="headcol-1">{TAGS}</div>
  }

  const COLUMNS = [
    {
      name: "fileID",
      label: "id",
      colKey: "fileID",
      options: {
        display: false,
      }
    },
    {
      name: "fileID",
      label: "",
      colKey: "fileID",
      options: {
        sort: false,
        customHeadLabelRender: renderHeadLabelFileId,
        customBodyRender: renderFileId
      }
    },
    {
      name: "folder",
      label: "folder",
      colKey: "folder",
      options: {
        sort: false,
        setCellProps: () => ({ style: { width: "10px" } }),
        customHeadLabelRender: () => { },
        customBodyRender: renderFolder
      },
    },
    {
      name: "name",
      label: ITEM_NAME,
      colKey: "name",
      options: {
        sort: true,
        customBodyRender: renderItemName,
        customHeadLabelRender: renderHeadLabelItemName,
      },
    },
    {
      name: "tags",
      label: TAGS,
      colKey: "tags",
      options: {
        sort: false,
        customBodyRender: renderTags,
        customHeadLabelRender: renderHeadLabelTags,
        customFilterListOptions: { render: (v) => `Tags: ${v}` },
      },
    },
    {
      name: "createdTimeStamp",
      label: CREATE_DATE,
      colKey: "createdTimeStamp",
      options: {
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        customBodyRender: renderCreatedTs,
        customFilterListOptions: {
          render: (v) => {
            return `Created Min-Max Date: ${v[0]} - ${v[1]}`;
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === -1) {
              filterList = [];
            }
            return filterList;
          },
        },
        filter: true,
        filterType: 'custom',
        filterList: [],
        filterOptions: {
          names: [],
          logic(date, filters) {
            if (filters[0] && filters[1]) {
              return date < filters[0] || date > filters[1];
            } else if (filters[0]) {
              return date < filters[0];
            } else if (filters[1]) {
              return date > filters[1];
            }
            return false;
          }
        }
      }
    },
    {
      name: "createdBy",
      label: CREATED_BY,
      colKey: "createdBy",
      options: {
        sort: true,
        customBodyRender: renderCreatedBy,
        customFilterListOptions: { render: (v) => `Created by: ${v}` },
      },
    },
    {
      name: "version",
      label: "Version",
      colKey: "version",
      options: {
        display: true,
        customFilterListOptions: { render: (v) => `Version: ${v}` },
      },
    },
    {
      name: "actions",
      label: ACTIONS,
      colKey: "actions",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          // actions not needed in case of folder
          if (tableMeta?.rowData[2] === true) {
            return
          }
          return (
            <ActionPopover
              actionItems={fileActionItems}
              onClickOption={onClickOption}
              data-testid="action-btn"
              tableMeta={tableMeta}
            />
          )
        },
      },
    },
    {
      name: "matterName",
      label: "Matter Name",
      colKey: "matterName",
      options: {
        display: false,
      },
    },
    {
      name: "matterNumber",
      label: "Matter Number",
      colKey: "matterNumber",
      options: {
        display: false,
      },
    },
    {
      name: "IL_OBJECT_ID",
      label: "Matter Id",
      colKey: "IL_OBJECT_ID",
      options: {
        display: false,
      },
    },
    {
      name: "userId",
      label: "User ID",
      colKey: "userId",
      options: {
        display: false,
      },
    },
    {
      name: "fileExtension",
      label: "Doc Type",
      colKey: "fileExtension",
      options: {
        display: false,
      },
    },
    {
      name: "practiceArea",
      label: "Practice Area",
      colKey: "practiceArea",
      options: {
        display: false,
      },
    },
    {
      name: "assignedParties",
      label: "Assigned Parties",
      colKey: "assignedParties",
      options: {
        display: false,
      },
    },
    {
      name: "partiesToMatter",
      label: "Parties To Matter",
      colKey: "partiesToMatter",
      options: {
        display: false,
      },
    },
    {
      name: "isPublic",
      label: "isPublic",
      colKey: "isPublic",
      options: {
        display: false,
      },
    },
    {
      name: "rename",
      label: "rename",
      colKey: "rename",
      options: {
        display: false,
      },
    },
    {
      name: "path",
      label: "path",
      colKey: "path",
      options: {
        display: false,
      },
    },
    {
      name: "IL_FILE_SIZE",
      label: "File size",
      colKey: "IL_FILE_SIZE",
      options: {
        display: false,
      },
    },
  ];

  const {
    fromMatter,
    currentFileTab,
    filterColumns,
    defaultQuery,
    defaultSortQuery,
    filterConfigs,
    defaultSearchQuery,
    defaultFilterQuery,
    hideAddNewButton,
    onClickClear = null } = props;

  const TAGS_LIMIT = 4;
  const dispatch = useDispatch()
  const classes = DocumentListStyles();
  const history = useHistory();
  const { addSnack } = useSnackbar();

  const documentMatterDetails = useSelector((state) => state?.matter?.matterDetail);
  const documentsData = useSelector((state) => state?.document?.documentsData);
  const breadCrumbFolders = useSelector((state) => state?.document?.breadCrumbFolders);
  const breadcrumbIndex = useSelector((state) => state?.document?.breadcrumbIndex);
  const folderSearchQuery = useSelector((state) => state?.document?.folderSearchQuery);
  const advanceSearchQuery = useSelector((state) => state?.document?.advanceSearchQuery);


  const [addFolderModalOpen, setAddFolderModalOpen] = useState(false);
  const [documentTableColumns, setDocumentTableColumns] = useState(COLUMNS);
  const [docList, setDocList] = useState([]);
  const [, setFilteredDocList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [openEditTagsModal, setOpenEditTagsModal] = useState(false);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [tableRowIndex, setTableRowIndex] = useState([]);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [previewModalFileData, setPreviewModalFileData] = useState({
    docTitle: '',
  });

  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DOCUMENTS);
  const [pageDocuments, setpageDocuments] = useState(0);
  // apply default queries from props
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery ? defaultSearchQuery : "");
  const [filterQuery, setFilterQuery] = useState(defaultFilterQuery ? defaultFilterQuery : []);
  const [sortQuery, setSortQuery] = useState(defaultSortQuery ? [defaultSortQuery] : []);
  const [folderQuery, setFolderQuery] = useState(folderSearchQuery ? folderSearchQuery : "");
  const [appliedSortQuery, setAppliedSortQuery] = useState("");

  // documents from canopus api
  useEffect(() => {
    setDocList(documentsData?.documents);
  }, [documentsData])

  useEffect(() => {
    setFilteredDocList(docList)
  }, [docList])

  // breadcrumb index to configure search query
  useEffect(() => {
    if (breadcrumbIndex !== null) {
      handleFolderBreadCrumbs(breadcrumbIndex);
    }
  }, [breadcrumbIndex]);

  // advanceSearchQuery : filters from documents home component
  // filterColumns : filters from matter listing component
  useEffect(() => {
    if (advanceSearchQuery && !isEmptyObj(advanceSearchQuery)) {
      const filterValues = isEmptyObj(advanceSearchQuery) ? 'All' : advanceSearchQuery;
      onFilter(filterValues);
    } else if (filterColumns && !isEmptyObj(filterColumns)) {
      const filterValues = isEmptyObj(filterColumns) ? 'All' : filterColumns;
      onFilter(filterValues);
    }
  }, [advanceSearchQuery, filterColumns])

  // on change of filterQuery, searchQuery,sortQuery, folderQuery call canopus api
  useEffect(() => {
    fetchDocuments(`limit=${rowsPerPage}&offset=0`);
  }, [filterQuery, searchQuery, sortQuery, folderQuery, defaultQuery])


  // this function applies filter options to columns
  const onFilter = (value) => {
    const filteredCols = [...documentTableColumns];
    if (value !== "All") {
      filteredCols.forEach(item => {
        if (value[item?.colKey] === undefined) {
          item.options.filterList = [];
        } else if (item?.colKey === 'createdTimeStamp' && value[item?.colKey]?.start && value[item?.colKey]?.end) {
          item?.options?.filterList?.splice(0, 0, moment(value?.[item?.colKey]?.start)?.format(DATE_YYYY_MM_DD));
          item?.options?.filterList?.splice(1, 0, moment(value?.[item?.colKey]?.end)?.format(DATE_YYYY_MM_DD));
        }
        else {
          item.options.filterList = value[item?.colKey];
        }
      })
    } else {
      filteredCols.forEach(item => {
        item.options.filterList = [];
      })
    }
    setDocumentTableColumns(filteredCols);
  };
  /**
   * This function is called when api call throws an error
   */
  const handleError = (error) => {
    addSnack({
      message: getErrorMessage(error),
    });
  }

  /*
  * this function will be used to fetch documents
  */
  const fetchDocuments = (query = "") => {
    if (loading) {
      return
    }
    // clear all filters case
    if (defaultQuery?.length === 0 && folderQuery === "" && filterQuery?.length === 0 && sortQuery?.length === 0 && searchQuery === "") {
      onClickClear && onClickClear();
      setDocList([]);
      return;
    }
    setLoading(true);
    const body = {
      "condition": "AND",
      queryParameters: [
        ...defaultQuery,
        ...(folderQuery !== "" ? [{
          "key": "metaData.IL_SYS_FOLDER",
          "operation": "term",
          "value": folderQuery?.slice(0, -1) // removing last / from the folder path
        }] : []),
        ...filterQuery
      ],
      ...((sortQuery?.length > 0 && !searchQuery) ?
      {
        "sortBy": sortQuery
      } : (appliedSortQuery?.length > 0 && {
        "sortBy": appliedSortQuery
      })),
      rawSearch: searchQuery,
    }
    postService(NODE_JS_URL, `/files/search?${query}${folderQuery !== "" ? '&folderSearch=' + folderQuery : ''}`, body)
      .then((res) => {
        setLoading(false);
        dispatch(setDocumentsData(res?.data ? res?.data : {}));
      })
      .catch((error) => {
        dispatch(setDocumentsData({}));
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      })
  }

  /*
* This function calls API to update metadata
*/
  const updateMetaData = (selectedTableData, metaData, updateMsg) => {
    const body = {
      fileId: selectedTableData?.[0],
      version: selectedTableData[7],
      metaData: {
        IL_OBJECT_ID: selectedTableData?.[10],
        IL_OBJECT_TYPE: "MATTER",
        ...metaData
      }
    }
    postService(NODE_JS_URL, "/updateMetada", body)
      .then((res) => {
        setOpenEditTagsModal(false);
        setModalLoading(false);
        addSnack({
          message: updateMsg,
        });
      })
      .catch((error) => {
        setModalLoading(false);
        setOpenEditTagsModal(false);
        addSnack({
          message: getErrorMessage(error)
        });
      })
  }

  /*
 * This function calls API to dowwnload file
 */
  const directFileDownload = (documentId, docName, version) => {
    postServiceWithHeaders(NODE_JS_URL, `/download?version=${version}` , { id: documentId }, {}, "blob")
      .then((res) => {
        const url = URL.createObjectURL(res?.data);
        const anchor = document?.createElement("a");
        anchor.href = url;
        anchor.setAttribute("download", docName);
        document?.body.appendChild(anchor);
        anchor.click();
        addSnack({
          message: `File Downloaded!`,
        });
      })
      .catch((error) => {
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

  /**
   * Gets called when clicked on action item
   */
  const onClickOption = (selectedId, tableMeta) => {
    switch (selectedId) {
      case 0:
        setIsPreviewOpen(true);
        setPreviewModalFileData({
          docTitle: tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[3],
        });
        dispatch(setDocumentsMetadata({
          fileID: tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[0],
          fileName: tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[3],
          fileExtension: tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[13],
          matterId: tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[11],
          matterName: tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[9],
          matterNumber: tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[10],
          createdBy: tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[6],
          tags: tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[4],
          uploadedAt: tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[5],
          isPublic: !tableMeta?.currentTableData[tableMeta?.rowIndex]?.data[17],
          IL_FILE_SIZE: tableMeta?.currentTableData?.[tableMeta?.rowIndex]?.data[20],
          version: tableMeta?.currentTableData?.[tableMeta?.rowIndex]?.data[7]
        }));
        break;
      case 1:
        setTableRowIndex([tableMeta?.rowIndex]);
        setSelectedTableData(tableMeta);
        tableMeta?.tableData.map((item, index) => {
          item[16] = Boolean(index === tableMeta?.rowIndex);
        })
        setDocList(tableMeta?.tableData);
        break;
      case 2:
        setSelectedTableData(tableMeta);
        setSelectedTags(tableMeta?.tableData?.[tableMeta?.rowIndex]?.[4] ?? [])
        setOpenEditTagsModal(true);
        break;
      case 3:
        shareDocument(
          tableMeta?.currentTableData?.[tableMeta?.rowIndex]?.data?.[0],
          tableMeta?.currentTableData?.[tableMeta?.rowIndex]?.data?.[7],
          tableMeta?.currentTableData?.[tableMeta?.rowIndex]?.data?.[3]
        );
        break;
      case 4:
        directFileDownload(
          tableMeta?.currentTableData?.[tableMeta?.rowIndex]?.data?.[0],
          tableMeta?.currentTableData?.[tableMeta?.rowIndex]?.data?.[3],
          tableMeta?.currentTableData?.[tableMeta?.rowIndex]?.data?.[7], // version
        );
        break;
      default:
        return null;
    }
  }

  /*
  * function to handle server side search
  */
  const handleServerSideSearch = (searchText) => {
    setSearchQuery(searchText);
  }

  /*
 * function to handle server side filter
 */
  const handleServerSideFilter = (query) => {
    setFilterQuery(query);
  }

  /**
 * Function to call api on pagination action
 * @param {number} rowsPerPage
 * @param {number} page
 */

  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPage(rowsPerPage);
    setpageDocuments(page)
    fetchDocuments(`limit=${rowsPerPage}&offset=${rowsPerPage * page}`);
  };

  /**
   * Function to call api on sort action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSideSort = ({ name, direction }) => {
    let formattedName = name;
    if (name === "name") {
      formattedName = "fileName"
    } else if (name === "tags") {
      formattedName = "metaData.tags";
    }
    setAppliedSortQuery([
      {
        key: formattedName,
        type: direction
      }])
    setSortQuery([
      ...(defaultSortQuery ? [defaultSortQuery] : []),
      {
        key: formattedName,
        type: direction
      }
    ]);
  };

  const documentTableCustomOptions = {
    isDocument: true,
    filterConfigs: filterConfigs,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    isRowExpandable: (tableRowIndex, expandedRows) => {
      return true;
    },
    rowsExpanded: tableRowIndex,
    renderExpandableRow: (rowData, rowMeta, updateValue) => {
      const colSpan = rowData?.length + 1;
      return (
        <TableRow className={classes.expandableRow}>
          <TableCell colSpan={colSpan}>
            <div className={classes.renameBtns}>
              <ButtonGroup>
                <Button
                  data-testid="cancel-btn"
                  id="cancel-btn"
                  size="small"
                  onClick={() => cancelRename(rowData, rowMeta, updateValue)}
                >
                  {CANCEL}
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  data-testid="update-btn"
                  id="update-btn"
                  onClick={() => saveRename(rowData, rowMeta, updateValue)}
                >
                  {SAVE}
                </Button>
              </ButtonGroup>
            </div>
          </TableCell>
        </TableRow>
      );
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
    },
    serverSide: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_DOCUMENTS,
    serverSideSearchFilter: true,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSort: handleServerSideSort,
    searchPlaceHolder: "Search",
    totalCount: documentsData?.count ?? 0,
    customPage: pageDocuments,
    searchText: defaultSearchQuery
  };


  /**
 * Gets called when clicked on save button.
 */
  const saveRename = (rowData, rowMeta, updateValue) => {
    if (rowData[3]?.props.children.props.value !== "") {
      selectedTableData.tableData[selectedTableData?.rowIndex][16] = false;
      selectedTableData.tableData[selectedTableData?.rowIndex][3] = rowData?.[3]?.props?.children?.props?.value;
      renameDocument(
        selectedTableData?.tableData?.[selectedTableData?.rowIndex]?.[0],
        selectedTableData?.tableData?.[selectedTableData?.rowIndex]?.[7],
        selectedTableData?.tableData?.[selectedTableData?.rowIndex]?.[3]
      )
      setDocList(selectedTableData?.tableData);
      setTableRowIndex([]);
    }
  }

  /**
   * Gets called when clicked on cancel button.
   */
  const cancelRename = (rowData, rowMeta, updateValue) => {
    selectedTableData.tableData[selectedTableData?.rowIndex][16] = false;
    setDocList(selectedTableData?.tableData);
    setTableRowIndex([]);
  }

  const itemNameChange = (event, updateValue) => {
    event.stopPropagation();
    updateValue(event.target.value);
  }

  const itemNameClick = (event) => {
    event.stopPropagation();
  }

  /**
    * Function to save edited tags
    */
  const onClickSaveTags = (tags) => {
    setModalLoading(true);
    selectedTableData.tableData[selectedTableData?.rowIndex][4] = tags;
    updateMetaData(selectedTableData?.tableData[selectedTableData?.rowIndex], { tags: tags ?? [] }, TAGS_EDITED_SUCCESS_MSG);
    setDocList(selectedTableData?.tableData);
  }

  /*
*  This function calls API to rename file
*/
  const renameDocument = (fileId, version, fileName) => {
    postService(NODE_JS_URL, `/rename?fileId=${fileId}&version=${version}&fileName=${fileName}`, {})
      .then((res) => {
        addSnack({
          message: FILE_RENAME_MSG
        })
      })
      .catch((error) => {
        handleError(error)
      })

  }

  /*
*  This function calls API to share file link
*/
  const shareDocument = (fileId, version, fileName) => {
    postService(NODE_JS_URL, `/share?fileId=${fileId}&version=${version}`, {})
      .then((res) => {
        navigator.clipboard.writeText(res?.data?.url).then(() => {
          addSnack({
            message: `${fileName} ${LINK_COPIED}`,
          });
        });
      })
      .catch((error) => {
        handleError(error)
      })

  }

  /*
* this function is used to navigate between breadcrumbs
*/
  const handleFolderBreadCrumbs = (index) => {
    if (index === "RESET") {
      dispatch(setFolderSearchQuery(null));
      dispatch(setDocumentBreadcrumbIndex(null));
      dispatch(updateBreadCrumbFolderData([]));
      dispatch(setDocumentsPath("/"));
      setFolderQuery("");
    } else {
      breadCrumbFolders?.splice(index + 1);
      dispatch(updateBreadCrumbFolderData([]));
      dispatch(updateBreadCrumbFolderData(breadCrumbFolders));
      setFolderQuery(breadCrumbFolders?.[index]?.value);
    }
  }

  /**
   * Gets called when clicked on Add new button.
   */
  const handleAddNew = (selectedId) => {
    switch (selectedId) {
      case 0:
        history.push(`/document/adddocument`, { fromMatter: fromMatter })
        break;
      case 1:
        setAddFolderModalOpen(true)
        break;
      default:
        break;
    }
  };

  const handleRowClick = (rowData, rowMeta) => {
    // file
    if (!docList[rowMeta?.rowIndex]?.folder) {
      dispatch(setDocumentsMetadata(docList[rowMeta?.rowIndex]));
      history.push(
        `/document/${docList[rowMeta?.rowIndex]?.fileID}`,
        {
          fromMatter: fromMatter,
          isCurrent: currentFileTab
        }
      );
    }
    // folder block
    else if (docList[rowMeta?.rowIndex]?.folder) {
      const folderPath = docList[rowMeta?.rowIndex]?.path;
      dispatch(setDocumentsPath(folderPath));
      dispatch(updateBreadCrumbFolderData([...breadCrumbFolders, {
        label: docList[rowMeta?.rowIndex]?.name,
        value: folderPath
      }]))
      setFolderQuery(folderPath ?? "")
    }
  };
  /**
   * Function to call api on Refresh button click
   */
  const handleRefreshClick = () => {
    fetchDocuments(`limit=${rowsPerPage}&offset=${rowsPerPage * pageDocuments}`);
  };
  const filterButton = () => {
    return (
      <Button
        trailing={<Refresh size="small" />}
        size="small"
        variant="secondary"
        data-testid="refresh-button"
        onClick={handleRefreshClick}
        style={
          {
            margin: "0 0.8rem",
          }
        }
      >
        {REFRESH}
      </Button>
    );
  };
  return (
    <>
      <div
        className={classes.detail}
        data-testid="document-listing"
      >
        <div className={classes.dataTableWrap}>
          <MuiDataTable
            data={docList ?? []}
            loading={loading}
            columns={documentTableColumns}
            customOptions={documentTableCustomOptions}
            onAddNew={handleAddNew}
            setTableColumns={setDocumentTableColumns}
            disableExport={true}
            onRowClick={handleRowClick}
            showCustomAddNewButton={true}
            customAddNewButton={<AddNewActionPopover handleAddNew={handleAddNew} />}
            customClassName={"hideCollpaseIcon customMuiClass"}
            customFilterButton={filterButton()}
            hideAddNewButton={documentMatterDetails?.status === "Closed" || hideAddNewButton}
          />
        </div>
        <AddFolderModal
          open={addFolderModalOpen}
          handleAddFolderModalState={setAddFolderModalOpen}
          fromMatter={fromMatter}
        />
        <EditTags
          openEditTagsModal={openEditTagsModal}
          setOpenEditTagsModal={setOpenEditTagsModal}
          selectedTableData={selectedTags}
          handleAddFolderModalState={setAddFolderModalOpen}
          onClickSaveTags={onClickSaveTags}
          editLoading={modalLoading}
        />
      </div>
      {DocumentPreviewModal(setIsPreviewOpen, isPreviewOpen, previewModalFileData, fromMatter)}
    </>
  )
}

export default DocumentList;
