import {
	Button, ButtonGroup, Grid, Select,
	GridColumn, Modal, Spinner, TextArea
} from "@walmart-web/livingdesign-components";
import PropTypes from 'prop-types';
import React, {useState, useRef, useEffect} from "react";
import { CANCEL, SUBMIT } from "../../../constants/actionButtons";
import { FEEDBACK_CATEGORIES, FEEDBACK_TITLE, FEEDBACK_SUBTITLE, RATING_DESC, FEEDBACK_CATEGORY_LABEL, FEEDBACK_COMMENT_LABEL,FEEDBACK_SELECT_CATEGORY, SITE_FEEDBACK } from "../../../constants/constants";
import Rating from '@mui/material/Rating';

import { FeedbackModalStyles } from "./FeedbackModalStyles.styles";

/**
 * Feedback Modal Component.
 * This component will use for getting the feedback from user.
 */
const FeedbackModal = (props) => {
	const {
		open,
		handleModalState,
	} = props;
	const classes = FeedbackModalStyles();
	const ref = useRef();
	const [loading, setLoading] = useState(false)
	const [additionalComment, setAdditionalComment] = useState("");
	const [feedbackCategory, setFeedbackCategory] = useState("");
	const [ratingValue, setRatingValue] = useState(0);
	let isDirty = false;

	/** Function to handle modal close action */
	const handleModalDismiss = () => {
		handleModalState(false);
	}

	/** Function to handle submit click */
	const handleSubmitFeedback = () => {
		setLoading(true);
		handleModalState(false)
		setLoading(false)		
	}

  useEffect(() => {
    if (additionalComment) {
      ref?.current?.focus();
    }
  }, [additionalComment])

	/** Function to handle textarea focus */
	const handleCommentFocus = (e) => {
    const val = e.target.value;
    e.target.value = "";
    e.target.value = val;
  };
	
	/** validation checks for form submission */
	isDirty = ratingValue !== 0 && feedbackCategory !== '' && additionalComment !== '';

	return (
		<Modal
			data-testid="feedback-modal"
			onClose={() => handleModalDismiss()}
			isOpen={open}
			size="small"
			title={SITE_FEEDBACK}
		>
			<div className={classes.Container}>
				<h3 className={classes.modalLabel}>{FEEDBACK_TITLE}</h3>
				<h6 className={classes.rateFeed}>{FEEDBACK_SUBTITLE}</h6>
				<div className={classes.ratingbox}>
					<Rating
						name="rating"
						className={classes.starRatingbox}
						value={ratingValue}
						onChange={(event, newValue) => {
							setRatingValue(newValue);
						}}
						size="large"
						data-testid="rating-star"
					/>
				</div>
				<h6 className={classes.rateExp}>({RATING_DESC})</h6>
				<hr className={classes.line}/>
				<h3 className={classes.modalLabel}>
          {FEEDBACK_CATEGORY_LABEL}
				</h3>
				<Select
					label={false}
					onChange={(e) => setFeedbackCategory(e.target.value)}
					value={feedbackCategory}
					data-testid="feedback-category"
				>
					<option value=''>
						{FEEDBACK_SELECT_CATEGORY}
					</option>
					{
						FEEDBACK_CATEGORIES?.map((category) =>{
							return <option value={category} key={category}>
								{category}
							</option>
						})
					}	
				</Select>		
				<h3 className={classes.modalLabel}>
          {FEEDBACK_COMMENT_LABEL}
				</h3>
				<TextArea
					ref={ref}
					value={additionalComment}
					onChange={(e) => setAdditionalComment(e.target.value)}
					data-testid="additional-comment"
					id="additional-comment"
					onFocus={(e) => {
						handleCommentFocus(e);
					}}
					maxLength={'500'}
				></TextArea>
			</div>
			<Grid className={classes.gridContainer}>
				<GridColumn sm={12}>
					<ButtonGroup className={classes.modalButtonGroup}>
						<Button
							data-testid="cancel-btn"
							id="modal-cancel-btn"
							size="small"
							onClick={() => handleModalDismiss()}
							disabled={loading}
							variant='tertiary'
						>
							{CANCEL}
						</Button>
						<Button
							variant='primary'
							size="small"
							data-testid="submit-btn"
							id="modal-submit-btn"
							onClick={()=> handleSubmitFeedback()}
							disabled={loading || !(isDirty)}
						>
							{loading ? (<Spinner color="white" size="small" />) : (SUBMIT)}
						</Button>
					</ButtonGroup>
				</GridColumn>
			</Grid>
		</Modal>
	);
};

export default FeedbackModal;

FeedbackModal.propTypes = {
	/**
	 * pass the open/close state of modal pop up
	 **/
	open: PropTypes.bool,
	/**
	 * handles the reset of modal open/close state
	 **/
	handleModalState: PropTypes.func
}
