import { createUseStyles } from "react-jss";
import { SPACING } from "../../../../constants/styleGuide";

/***
 * @visibleName Invoice View Component
 */

const InvoiceViewStyles = createUseStyles({
  leftContent: {
      paddingRight: SPACING.s32,
  },
  rightContent: {
      padding: SPACING.s16,
      backgroundColor: "#fff",
      marginBottom: "16px",
  },
  contentSpacing8: {
      paddingTop: SPACING.s8,
  },
  contentSpacing16: {
      paddingTop: SPACING.s16,
      width: "100%",
  },
  spacing16: {
    paddingTop: SPACING.s16,
},
  contentSpacing24: {
      paddingTop: SPACING.s24,
      width: "100%",
  },
  contentSpacing48: {
      paddingTop: SPACING.s48,
  },
  columnLeftPadding: {
      paddingLeft: SPACING.s10,
  },
  columnRightPadding: {
      paddingRight: SPACING.s10,
  },
  columnSpacing: {
      padding: SPACING.s16,
      backgroundColor: "#fff",
      marginBottom: "16px",
  },
  subTitle: {
      fontSize: "20px",
      lineHeight: "1.4",
      marginTop: "0",
      marginBottom: "16px",
  },
  subTitleLineItems: {
      fontSize: "20px",
      lineHeight: "1.4",
      marginTop: "0",
  },
  flexGrid: {
      display: "flex",
      flexDirection: "column",
  },
  container: {
      background: "#f1f1f2",
      width: "100%",
  },
  subType: {
      fontSize: "14px",
      lineHeight: "1.43",
      marginBottom: "10px",
  },
  unsaveSection: {
      padding: "26px 48px 26px 24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#002d58",
      position: "sticky",
      top: 0,
      zIndex: 9999,
  },
  unsaveText: {
      color: "#ffffff",
      margin: "0",
      fontSize: "20px",
      lineHeight: "1.4",
  },
  discardButtons: {
      marginRight: "16px",
  },
  divBorder: {
      border: "2px",
      borderRadius: "8px",
  },
  textAlignCenter: {
      marginLeft: "10px",
      marginRight: "10px",
      marginTop: "20px",
      textAlign: "center",
  },
  flex: {
      display: "flex",
      justifyContent: "space-between",
  },
  flexItem: {
      width: "47.5%",
  },
  tableNumericCell: {
      width: "80px",
      backgroundColor: "white",
      borderRadius: "5px",
      position: "absolute",
      top: "4px",
  },
  inputStyle: {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
      },
  },
  actionsItem: {
      width: "65px"
  },
  divider: {
      margin: "5px -15px 25px -25px",
  },
  divideLine: {
      margin: "5px -15px 0px -25px",
  },
  invoiceNoTxt: {
      marginTop: "30px"
  },
  lineItemDivider: {
      marginLeft: "-18px",
      marginRight: "-15px",
      marginTop: "22px",
  },
  lineItemBtn: {
      marginTop: "10px",
			padding: 0
  },
  lineItemsTableStyle:{
		paddingTop:"15px",
		"& table.MuiTable-root":{
			borderTop: '1px solid rgb(238, 238, 238)'
		},
		"& div.MuiToolbar-regular":{
			display: 'none'
		}
  },
  totalNetAmountStyle: {
      paddingTop: "20px",
      margin: "0px 18px 0px 20px"
  },
  gridcolumnLeft: {
    paddingRight: "10px",
  },
  gridcolumnRight: {
    paddingLeft: "10px",
  },
  typeItemSelect: {
    '& span > svg': {
      display: 'none'
    },
    '& div': {
      marginTop: '12px !important'
    },
    '& div > div> select': {
      padding: '0.9rem 2.25rem 0.9rem 1rem !important'
    },
    '& div.Select-module_helperTextContainer__v0n3a': {
      display: 'none',
    }
  },
  descriptionItem: {
		'& div': {
      marginTop: '7px !important'
    },
    '& div > div > input': {
      padding: '14.5px !important'
    },
  },
  paddingTop:{
    paddingTop: '4px'
  },
  invoiceNumber:{
    '& div.TextField-module_inputContainer__nLUeX':{
    boxShadow : "inset 0 0 0 0.0625rem #C4C4C3 !important"},
    '& div.TextField-module_inputContainer__nLUeX.TextField-module_error__YXWVF': {
      boxShadow: "inset 0 0 0 0.0625rem #de1c24 !important"
  }
 }
});

export default InvoiceViewStyles;
