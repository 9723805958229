import { createUseStyles } from "react-jss";

const WarningPanelStyles = createUseStyles({
  textStyle: {
    "& p": {
      color: "#74767C",
      fontSize: "12px",
      fontWeight: "300"
    }
  },
  alertWrap: {
    '&>div':{
      background: 'none',
      border: 'none',
      color: '#74767C',
      fontSize: "12px",
      padding: "5px 0"
    }
  }
});


export default WarningPanelStyles;
