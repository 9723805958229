import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getService } from "../../axios/axios";
import { isValueValid } from "../../helpers/validationUtils";

import { setUserPrefences } from "../../store/actions/preferencesActions";

/**
 * Wrapper Function to fetch User preference by using user ID.
 */
const withUserPreferences = (OriginalComponent) => {

  function ReadUserPreferences() {
    const baseUrl = "PREFERENCES_SERVICE";
    const userId  = useSelector((state) => state?.user?.userDetail?.attributes?.userUniqueId);
    const dispatch = useDispatch();

    const getUserPreferences = () => {
      getService(
        baseUrl,
        `/preferences/v1/users/${userId}`
      )
        .then(response => {
          dispatch(setUserPrefences(response?.data?.preference));
        })
        .catch((error)=> {

        })
    }

    useEffect(() => {
      if(isValueValid(userId)) {
        getUserPreferences();
      }
    }, [userId]);

    return (
      <OriginalComponent />
    )
  }

  return ReadUserPreferences;
}

export default withUserPreferences;
