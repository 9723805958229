import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  Spinner,
} from "@walmart-web/livingdesign-components";
import {
  Star,
  StarFilled
} from "../../constants/images";
import {
  getService,
  postService,
  patchService,
} from "../../axios/axios";
import {
  setStarredItemsIds,
  setUserPrefences
} from "../../store/actions/preferencesActions";
import { PREFERENCES_SERVICE } from '../../constants/baseURLs';
import StarItemStyles from './StarItemStyles.styles';
import { renderTernary } from "../../helpers/utils";


const apiCall=(starredData,starredDataCreateUpdateHandler,postData,userId,preferenceCode,setLoading)=>{
  if(starredData) {
    patchService(PREFERENCES_SERVICE, `/preferences/v1/users/${userId}/preferenceTypes/${preferenceCode}`, {
      "preferenceObject": postData
    })
      .then((res) => {
        starredDataCreateUpdateHandler(postData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  } else { // postService needs to be called if no preference has been created for preferenceCode
    postService(PREFERENCES_SERVICE, `/preferences/v1/users/`, {
      "preferenceObject": postData,
      "preferenceTypeCode": preferenceCode,
      "userId": userId
    })
      .then((res) => {
        starredDataCreateUpdateHandler(postData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false); // set loader to false if api fails
      });
  }
}
/**
 * Component to show and handle starred functionality
 * @param {*} props
 * @returns
 */
const StarItem = (props) => {
  const {
    preferenceCode,
    identifier,
    customClassName,
    disableAllStar=false,
    handleDisableAllStar,
    id=''
  } = props;

	const classes = StarItemStyles();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.user?.userDetail?.attributes?.userId);
  const preferences = useSelector(state => state?.preferences);
  const [isStarred, setIsStarred] = useState();
  const [loading, setLoading] = useState(Boolean(isStarred));

  useEffect(() => {
    const idList = preferences?.starredItemsIds;
    if(idList?.length > 0) {
      setIsStarred(idList.includes(identifier));
    }
  }, [identifier, preferences?.starredItemsIds]);

  /**
   * Callback function to set identifiers list and fetch preferences
   * @param {*} postData
   */
  const starredDataCreateUpdateHandler = (postData) => {
    const idList = postData?.map(item => item?.identifier);
    dispatch(setStarredItemsIds(idList));

    getService(
      PREFERENCES_SERVICE,
      `/preferences/v1/users/${userId}`
    )
      .then(response => {
        dispatch(setUserPrefences(response.data?.preference));
        setLoading(false);
        //set flag to enable all the star in datatable
        if(handleDisableAllStar) {
          handleDisableAllStar(false);
        }
      })
      .catch((error)=> {
        setLoading(false);
      })
  }

  /**
   * function to handle star icon click
   * @param {*} event
   * @param {String} type - "star" / "unstar"
   * @returns
   */
  const handleStarClick = (event, type) => {
    event.stopPropagation();

    //no action required if stars are disabled in datatable
    if(disableAllStar) {
      return;
    }
    //set flag to disable all the star in datatable
    if(handleDisableAllStar) {
      handleDisableAllStar(true);
    }
    setLoading(true);

    /**
     * Reading saved list from store and converting to json,
     * as changes has to be appended to the existing list
     */
    let postData = [];
    const starredData = preferences?.userPreferences[preferenceCode];
    if(starredData) {
      postData = JSON.parse(starredData);
    }

    if(type === 'star') { // including the item to the post data list
      // if the saved list is empty, have to remove empty object before pushing item
      postData = postData?.filter(value => JSON.stringify(value) !== '{}');
      postData?.push({"identifier": identifier});
    } else { // removing the item from the post data list, if type is "unstar"
      postData = postData?.filter((item) => item?.identifier !== identifier)
      // if post data list is empty, have to sent empty object
      if(postData?.length < 1) {
        postData?.push({});
      }
    }

    // patchService needs to be called for update, if user preference already initiated for preferenceCode
    apiCall(starredData,starredDataCreateUpdateHandler,postData,userId,preferenceCode,setLoading)
  }

  /**
   * Component to return star vs. star filled icon based on flag status
   * @param {*} flag
   * @returns
   */
  const StarButton = ({flag}) => {
    return renderTernary(flag,
      <StarFilled
        data-testid="star-filled"
        id={`star-filled-${id}`}
        size="small"
        className={`${classes.starFilled} ${classes.icon} ${renderTernary(disableAllStar , classes.disabled , '')}`}
        onClick={(event) => handleStarClick(event, 'unstar')}
        disabled={disableAllStar}
      />,
      <Star
        data-testid="star"
        id={`star-${id}`}
        size="small"
        className={`${classes.star} ${classes.icon} ${renderTernary(disableAllStar , classes.disabled , '')}`}
        onClick={(event) => handleStarClick(event, 'star')}
        disabled={disableAllStar}
      />)
  }

  const LoadField = ({ node }) => {
    return renderTernary(loading,<div className={classes.spinner} data-testid="spinner"><Spinner color="gray" size="small" /></div>,node)
  };

	return (
		<div className={`${customClassName ?? ''}`} data-testid="star-item-wrapper">
      {LoadField({node: <StarButton flag={isStarred} />})}
		</div>
	);
};

export default StarItem;

StarItem.propTypes = {
  /** pass the preference code key */
  preferenceCode: PropTypes.string.isRequired,
  /** pass the identifier */
  identifier: PropTypes.string.isRequired,
  /** pass the custom class name, if any */
  customClassName: PropTypes.string,
  /** flag to disable all the star in parent datatable */
  disableAllStar: PropTypes.bool,
  /** callback to disable all the star in parent datatable */
  handleDisableAllStar: PropTypes.func
}
