const types =  {
  SET_DOCUMENT_DATA: 'SET_DOCUMENT_DATA',
  SET_DOCUMENT_UPLOAD: 'SET_DOCUMENT_UPLOAD',
  UPDATE_DOCUMENT_UPLOAD: 'UPDATE_DOCUMENT_UPLOAD',
  RESET_DOCUMENT_UPLOAD: 'RESET_DOCUMENT_UPLOAD',
  DELETE_DOCUMENT_UPLOAD: 'DELETE_DOCUMENT_UPLOAD',
  UPDATE_BREADCRUMB_FOLDER_DATA: "UPDATE_BREADCRUMB_FOLDER_DATA",
  SET_DOCUMENTS_MATTERS_DATA: 'SET_DOCUMENTS_MATTERS_DATA',
  SET_DOCUMENTS_MATTERS_DETAIL: 'SET_DOCUMENTS_MATTERS_DETAIL',
  SET_DOCUMENTS_PATH: 'SET_DOCUMENTS_PATH',
  SET_DOCUMENTS_METADATA: 'SET_DOCUMENTS_METADATA',
  SET_BREADCRUMB_INDEX: 'SET_BREADCRUMB_INDEX',
  SET_TAB_INDEX: 'SET_TAB_INDEX',
  SET_FOLDER_SEARCH_QUERY : 'SET_FOLDER_SEARCH_QUERY',
  SET_ADVANCE_SEARCH_QUERY : 'SET_ADVANCE_SEARCH_QUERY'
}

export default types;
