export const getISODate = (date) => {
  if (date === null) {
    return date;
  } else {
    const dt = new Date(date);
    // Extract raw date value to ignore timezone and then construct the isoString manually to use UTC and avoid timezone issues (All DB queries need UTC values!)
    const stringifiedDate = [
      String(dt.getFullYear()),
      String(101 + dt.getMonth()).substring(1),
      String(100 + dt.getDate()).substring(1),
    ].join('-');
    const isoDate = `${stringifiedDate}T00:00:00.000Z`;
    return `dt'${isoDate}'`;
  }
}
