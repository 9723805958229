import {
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getService, getServiceWithResponseType,
} from "../../../../axios/axios";
import { Operations } from "../../../../axios/operations";
import ExportModal from "../../../../components/Modal/ExportModal/ExportModal";
import MuiDataTable from "../../../../components/Table/MuiDataTable/MuiDataTable";
import { USER_REQUEST_SERVICE } from "../../../../constants/baseURLs";
import {
  EXPORT_MAX_LIMIT_CONF, SHOW_NO_RESULT, USER_REQUESTS
} from "../../../../constants/constants";
import {
  ENABLE_SERVER_SIDE_FEATURES_USER_REQUESTS,
  ROWS_PER_PAGE_OPTIONS_USER_REQUESTS,
  ROWS_PER_PAGE_USER_REQUESTS
} from "../../../../constants/tableConfigs";
import { exportClientData, formSearchQuery, getAllowedActionsList, getExportedData } from "../../../../helpers/tableUtils";
import { getErrorMessage } from "../../../../helpers/utils";
import { setUserRequestData } from "../../../../store/actions/adminUserRequestActions";
import PendingUserRequestsStyles from "./PendingUserRequests.styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { COLUMNS_PEOPLE, FILTER_CONFIGS_PEOPLE } from "../OrganizationPeople";

/**
 * Function to form search query from user entered search string
 *
 * @param {string} searchText
 * @returns search query
 */
export const getSearchQuery = (searchText, COLUMNS) => {
  let query = "";
  COLUMNS.forEach((column, index) => {
    if (column?.options?.display) {
      const colQuery = formSearchQuery(column?.name, column?.operation, searchText, column?.type);
      if (colQuery !== "") {
        query += index > 0 ? " OR " + colQuery : colQuery;
      }
    }
  });
  return query;
};

// ***** This compoent is to show the list of pending user requests*****//

const PendingUserRequests = () => {
  const pendingTimekeeperAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.organization?.tabs?.people?.tabs?.pending);

  const [userColumns, setUserColumns] = useState(COLUMNS_PEOPLE);
  const { userRequestData } = useSelector((state) => state?.adminUserRequest);
  const identifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
  const classes = PendingUserRequestsStyles();
  const { addSnack } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [sortQuery, setSortQuery] = useState("&order=createdTs desc");
  const [exportLoading, setExportLoading] = useState(false);
  const [createExportLoading, setCreateExportLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectRowsData, setSelectRowsData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [currentPageExport, setCurrentPageExport] = useState(false);
  const [rowsPerPageUserRequests, setRowsPerPageUserRequests] = useState(ROWS_PER_PAGE_USER_REQUESTS);
  const showExportAllOption = userRequestData?.count < EXPORT_MAX_LIMIT_CONF.USER_REQUESTS;
  const defaultExportOption = showExportAllOption ? 'all' + USER_REQUESTS : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);

  const defaultFilterCondition = `${formSearchQuery(
    "isActive",
    Operations.EQ,
    true,
    'boolean'
  )}+ ${formSearchQuery(
    "status",
    Operations.LIKEIC,
    'pending',
    'id')}+${formSearchQuery(
      "organizationId",
      Operations.EQ,
      identifier,
      'id')}`;

  /**
   * Function to get Time keeper requests data
   * @param {string} query
   */
  const getUserRequestData = (query = "") => {
    setLoading(true);
    getService(
      USER_REQUEST_SERVICE,
      `/search/user-requests/v1?filter=(${defaultFilterCondition})${searchQuery !== '' ?
        'and (' : ''}${searchQuery}${searchQuery !== '' ? ')' : ''}${filterQuery !== '' ?
          'and (' : ''}${filterQuery}${filterQuery !== '' ? ')' : ''}${sortQuery} ${query}`
    )
      .then((response) => {
        dispatch(setUserRequestData(response?.data));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };
  /**
   * Function to validate search query and set query or show no results UI
   * @param {string} searchText
   */
  const handleServerSideSearch = (searchText) => {
    const searchQuery = searchText ? getSearchQuery(searchText, COLUMNS_PEOPLE) : "";
    if (searchQuery !== SHOW_NO_RESULT) {
      setSearchQuery(searchQuery);
    } else {
      dispatch(setUserRequestData([]));
    }
  };

  /**
   * Function to set filter query on filter action
   * @param {string} query
   */
  const handleServerSideFilter = (query) => {
    setFilterQuery(query);
  };

  /**
   * Function to call api on pagination action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPageUserRequests(rowsPerPage);
    getUserRequestData(`&limit=${rowsPerPage}&offset=${rowsPerPage * page}`);
  };

  /**
   * Function to call api on sort action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSideSort = ({ name, direction }) => {
    setSortQuery(`&order=${name} ${direction}`);
  };
  const userAccessCustomOptions = {
    rowsPerPage: rowsPerPageUserRequests,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_USER_REQUESTS,
    filterConfigs: FILTER_CONFIGS_PEOPLE,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_USER_REQUESTS,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSort: handleServerSideSort,
    totalCount: userRequestData?.count
  };
  /**
   * Cancel Export Modal
   */
  const onCancelBtnClk = () => {
    setExportLoading(false)
    setModalOpen(false)
    setExportSelection(defaultExportOption);
  }

  /**
   * function is to open the export modal to show download options
   */
  const handleExportBtnClk = () => {
    setExportSelection(defaultExportOption);
    setModalOpen(true);
    setExportLoading(true);
    setCurrentPageExport(true);
  }

  /**
   * function will use when row is select/deselect
   */
  const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
    const bData = userRequestData?.userRequests;
    const result = allSelected?.map(item => {
      return bData && bData[item?.index]
    });
    setSelectRowsData(selectRowsData);
    setRowsData(result);
  }

  const handleRowClick = (rowData, rowMeta) => {
    history.push(`/people/pending-request/${userRequestData?.userRequests[rowMeta?.dataIndex]?.id}/view`,{selectedPeopleTab :3})
  }

  /**
  * Reset Selected Rows
  */
  const resetSelectedRows = () => {
    setSelectRowsData([]);
    setRowsData([]);
  }

  /**
   * After click on create button download Actions
   */
  const onClickCreate = () => {
    setCreateExportLoading(true);
    if (ENABLE_SERVER_SIDE_FEATURES_USER_REQUESTS === false) {
      exportClientData(userRequestData?.userRequests, exportSelection, rowsData, 'user-requests');
      setModalOpen(false);
      setExportLoading(false);
      setCreateExportLoading(false);
    } else {
      let exportQuery = `filter=${defaultFilterCondition} ${searchQuery} ${filterQuery} ${sortQuery}&limit=${rowsPerPageUserRequests}&offset=0`;
      if (exportSelection === 'currentSelection') {
        const result = rowsData?.map(a => a?.id);
        exportQuery = `filter=${encodeURIComponent(formSearchQuery('id', Operations.IN, result, 'array'))}`;
      } else if (exportSelection === 'currentPage') {
        const result = userRequestData?.userRequests?.map(a => a?.id);
        exportQuery = `filter=${encodeURIComponent(formSearchQuery('id', Operations.IN, result, 'array'))}`;
      }
      const csv = "csv=true";
      getServiceWithResponseType(
        USER_REQUEST_SERVICE,
        `/search/user-requests/v1?${csv}&${exportQuery}`,
        "arraybuffer"
      )
        .then((response) => {
          const data = response?.data;
          const contentType = response?.headers["content-type"];
          getExportedData(data, contentType, 'user-requests');
          setModalOpen(false);
          setExportLoading(false);
          setCreateExportLoading(false);
        })
        .catch((error) => {
          setModalOpen(false);
          setExportLoading(false);
          setCreateExportLoading(false);
          addSnack({
            message: getErrorMessage(error)
          });
        });
    }
  }

  /**
   * To load initial data and handle search/filter/sort query change on Time keeper requests datatable
   */
  useEffect(() => {
    let offsetQuery = "";
    if (ROWS_PER_PAGE_OPTIONS_USER_REQUESTS) {
      offsetQuery = `&limit=${rowsPerPageUserRequests}&offset=0`;
    }
    getUserRequestData(offsetQuery);
  }, [searchQuery, filterQuery, sortQuery]);
  return (
    <div className={classes.rowStyle} data-testid="user-access">
      <MuiDataTable
        data={userRequestData?.userRequests}
        columns={userColumns}
        loading={loading}
        selectableRowsType={"multiple"}
        customOptions={userAccessCustomOptions}
        setTableColumns={setUserColumns}
        exportLoading={exportLoading}
        onExportClick={handleExportBtnClk}
        selectedRows={selectRowsData}
        onRowSelection={handleRowSelectionChange}
        onRowClick={handleRowClick}
        hideAddNewButton={true}
        allowedActions={getAllowedActionsList(pendingTimekeeperAccessibility)}
        resetSelectedRows={() => resetSelectedRows()}
      />
      <ExportModal
        title={USER_REQUESTS}
        mode={modalOpen}
        onCancel={onCancelBtnClk}
        onCreate={onClickCreate}
        rowsData={rowsData}
        exportSelection={exportSelection}
        setExportSelection={setExportSelection}
        currentPageExport={currentPageExport}
        showExportAllOption={showExportAllOption}
        setCurrentPageExport={setCurrentPageExport}
        exportLoading={createExportLoading}
      />
    </div>
  );
}

export default PendingUserRequests;
