import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useSelector, useDispatch } from "react-redux";
import MuiDataTable from "../../../components/Table/MuiDataTable/MuiDataTable";
import {
  Button,
	useSnackbar,
} from "@walmart-web/livingdesign-components";
import {
	MATTER_DEFAULT_SORT_QUERY,
  CS_SCOPES,
  CS_SCORE_SORT_OBJ,
  EXPORT_MAX_LIMIT_CONF,
  MATTERS
} from "../../../constants/constants";
import {
  ENABLE_SERVER_SIDE_FEATURES_MATTER,
  ROWS_PER_PAGE_MATTER,
  ROWS_PER_PAGE_OPTIONS_MATTER
} from "../../../constants/tableConfigs";
import MuiTagItem from "../../../components/Table/MuiDataTable/MuiTagItem/MuiTagItem";
import { matterListStatusTagColors } from "../../../constants/colors";
import { getExportedData, handleMatterNumberSort, getErrorMessage, useUserAssociatedRelation, getSensitiveMatterPayload } from "../../../helpers/utils";
import {
	postService, postServiceWithHeaders
} from "../../../axios/axios";
import { setMatterDetail, setMatterListingData } from "../../../store/actions/matterAction";
import { useHistory } from "react-router";
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import { FONT_FAMILY } from "../../../constants/styleGuide";
import { getAllowedActionsList, matterDataMapping } from "../../../helpers/tableUtils";
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery } from "../../../helpers/csQueryUtils";
import { FeatureToggle } from "../../../components/FeatureFlags/FeatureFlags"
import { Tooltip } from "@mui/material";
import { TableCellDisplay } from "../../../helpers/commonUtils";
import ExportModal from "../../../components/Modal/ExportModal/ExportModal";
/**
 * styles used in the component.
 */
 const useStyles = createUseStyles({
    matterWrapper: {
		background: "#F5F5F5",
		width: "100%"
	},
    dataTableWrap: {
      margin: "0",
      "& div>table>tbody>tr>td": {
        fontFamily: FONT_FAMILY.BOGLE,
      },
      "& div>table>thead>tr>th": {
        color: "#2E2F32;",
        fontFamily: FONT_FAMILY.BOGLE,
        fontWeight: "bold"
      },
    },
    tabContainer: {
      "& nav > ul > li > a": {
        background: "none",
      }
    },
    starColumnHeader: {
      padding: "6px",
    },
    star: {
      padding: "6px",
    },
    handCursor: {
      cursor: "pointer",
    },
    exportName: {
      marginBottom: 20
    },
    exportNameAs: {
      paddingTop: 20,
      marginBottom: 20
    },
    modalSubText: {
      fontSize: 14,
      fontWeight: 'normal'
    },
  });

/**
 * "All Matters" matter listing component
 */
const AllMattersList = () => {
	const classes = useStyles();

	const { addSnack } = useSnackbar();

	const dispatch = useDispatch();

  const renderMatterName=(value, tableMeta) => {
    const cellId = `Mui-matter-name-row-${tableMeta?.rowIndex}`
    return (
      <Tooltip title={value}>
        <span id={cellId}>{value}</span>
      </Tooltip>
    )
  }

  const renderStatus=(value, tableMeta) => {
    const cellId = `Mui-matter-status-row-${tableMeta?.rowIndex}`
    return <MuiTagItem
      id={cellId}
      value={value}
      color={matterListStatusTagColors[value]?.color || "gray"}
      variant={matterListStatusTagColors[value]?.variant || "tertiary"} />;
  }

  const COLUMNS = [
    {
      name: "matterNumber",
      label: "Matter No.",
      colKey: "matterNumber",
      options: {
        sort: true,
        sortCompare: handleMatterNumberSort,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`Mui-matter-number-row-${tableMeta?.rowIndex}`, value),
        customFilterListOptions: { render: (v) => `Matter Number: ${v}` },
      },
    },
    {
      name: "matterName",
      label: "Matter Name",
      colKey: "matterName",
      options: {
        sort: true,
        customBodyRender: renderMatterName,
        customFilterListOptions: { render: (v) => `Matter Name: ${v}` },
      },
    },
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        sort: true,
        customBodyRender: renderStatus,
        customFilterListOptions: { render: (v) => `Status: ${v}` },
        setCellProps: () => ({style: {padding: '16px'}}),
      },
    },
    {
      name: "matterType",
      label: "Matter Type",
      colKey: "matterType",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const cellId = `Mui-matter-type-row-${tableMeta?.rowIndex}`
          return TableCellDisplay(cellId, value)
        },
        customFilterListOptions: { render: (v) => `Matter Type: ${v}` },
      },
    },
    {
      name: "matterSubType",
      label: "Matter Subtype",
      colKey: "matterSubType",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const cellId = `Mui-matter-subtype-row-${tableMeta?.rowIndex}`
          return TableCellDisplay(cellId, value)
        }
      },
    },
    {
      name: "createdBy",
      label: "Created By",
      colKey: "createdBy",
      options: {
        sort: true,
      },
    },
    {
      name: "matterCreateDate",
      label: "Matter Create Date",
      colKey: "matterCreateDate",
      options: {
        sort: true,
      },
    },
    {
      name: "closureDate",
      label: "Matter Close Date",
      colKey: "closureDate",
      options: {
        sort: true,
      },
    },
    {
      name: "leadAttorney",
      label: "Lead Attorney",
      colKey: "leadAttorney",
      options: {
        sort: false,
        customFilterListOptions: { render: (v) => `Lead Attorney: ${v}` },
      },
    },
    {
      name: "facilityNumber",
      label: "Facility Number",
      colKey: "facilityNumber",
      options: {
        display: false,
        customFilterListOptions: { render: (v) => `Facility Number: ${v}` },
      },
    },
    {
      name: "facilityState",
      label: "State",
      colKey: "facilityState",
      options: {
        display: false,
        customFilterListOptions: { render: (v) => `State: ${v}` },
      },
    },
    {
      name: "facilityCity",
      label: "City",
      colKey: "facilityCity",
      options: {
        display: false,
        customFilterListOptions: { render: (v) => `City: ${v}` },
      },
    },
    {
      name: "identifier",
      label: "identifier",
      colKey: "identifier",
      options: {
        display: false,
      },
    },
    {
      name: "practiceArea",
      label: "Practice Area",
      colKey: "practiceArea",
      options: {
        display: false,
        customBodyRender: (value) => {
          const practiceAreaObj = practiceAreas?.find(o => o.code.toLowerCase() === value.toLowerCase());
          return practiceAreaObj?.name;
        },
        customFilterListOptions: { render: (v) => `Practice Area: ${v}` },
      }
    }
  ];

	const history = useHistory();
	const { matterListingData, practiceAreas } = useSelector((state) => state?.matter);
  const matterAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.matters);
  const relations = useUserAssociatedRelation(matterAccessibility);
  const orgIdentifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
  const userDetails = useSelector((state) => state?.user?.userDetail?.attributes);
	const [matterColumns, setMatterColumns] = useState(COLUMNS);
	const [exportLoading, setExportLoading] = useState(false)
	const [loading, setLoading] = useState(false);
  const [practiceAreaDropDown, setPracticeAreaDropDown] = useState([]);
  const [rowsPerPageMatters, setRowsPerPageMatters] = useState(ROWS_PER_PAGE_MATTER);
  const [searchQuery, setSearchQuery] = useState({});
  const [filterQuery, setFilterQuery] = useState({});
  const [sortQuery, setSortQuery] = useState(MATTER_DEFAULT_SORT_QUERY);
  const [modalOpen, setModalOpen] = useState(false);
  const [createExportLoading, setCreateExportLoading] = useState(false);

  const [sortValue, setSortValue] = useState([]);
  const [pageSortValueMap, setPageSortValueMap] = useState({});
  const [page, setPage] = useState(0);
  const showExportAllOption = matterListingData?.count < EXPORT_MAX_LIMIT_CONF.MATTER;
  const defaultExportOption = showExportAllOption ? 'allMatters' : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);
  const [currentPageExport, setCurrentPageExport] = useState(false);

  useEffect(() => {
    if(practiceAreas?.length > 0) {
      const practiceAreasList = [...new Set(practiceAreas?.map(val => val?.name))];
      setPracticeAreaDropDown(practiceAreasList);
    }
  }, [practiceAreas]);

  useEffect(() => {
    const paginationQuery = {
      "offset": 0,
      "limit": rowsPerPageMatters,
    }
    getMatterData(paginationQuery);
  }, [sortQuery, searchQuery, filterQuery]);

  // Conversion of pratice area code to corresponding names in hidden column
  useEffect(() => {
    if(practiceAreaDropDown.length > 0) {
      setMatterColumns(COLUMNS);
    }
  }, [practiceAreaDropDown]);

	const FILTER_CONFIGS = [
    {
      colKey: "status",
      type: "checkbox",
      label: "Status",
      defaultOpen: true,
      options: ["Open", "Closed"],
    },
    {
      colKey: "matterNumber",
      type: "matterDropdownAutocomplete",
      label: "Matter Number",
      defaultOpen: true,
    },
    {
      colKey: "matterName",
      type: "matterDropdownAutocomplete",
      label: "Matter Name",
      defaultOpen: true,
    },
    {
      colKey: "matterType",
      type: "masterDataMultiAutocomplete",
      dataType: "matterType",
      label: "Select Matter Type",
      defaultOpen: true,
    },
    {
      colKey: "practiceArea",
      type: "autocompleteMultiSelect",
      label: "Practice Area",
      defaultOpen: true,
      options: practiceAreaDropDown,
    },
    {
      colKey: "leadAttorney",
      type: "peopleDropdownAutocomplete",
      label: "Lead Attorney",
      defaultOpen: true,
      options: [],
    },
    {
      colKey: "facilityNumber",
      type: "textField",
      label: "Facility Number",
      defaultOpen: true,
    },
    {
      colKey: "facilityCity",
      type: "textField",
      label: "City",
      defaultOpen: true,
    },
    {
      colKey: "facilityState",
      type: "masterDataAutocomplete",
      label: "State",
      dataType: "state",
      defaultOpen: true,
    }
  ];

  /**
  * Function to call api on pagination action (server side)
  * @param {number} rowsPerPage
  * @param {number} page */
  const handleServerSidePagination = ({ rowsPerPage, page: pageNumber }) => {
    let prevPage = page;
    if(rowsPerPageMatters !== rowsPerPage) {
      pageNumber = 0;
      prevPage = 0;
    }
    setPage(pageNumber);
    setRowsPerPageMatters(rowsPerPage);
    let paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * pageNumber));
    if((rowsPerPage + (rowsPerPage * pageNumber)) >= 10000)
    {
      if(pageNumber > prevPage) {
        setPageSortValueMap({...pageSortValueMap, [pageNumber]: sortValue})
        paginationQuery = {
         "sortValue": sortValue,
         "limit": rowsPerPage
        }
      }
      else {
        paginationQuery = {
          "sortValue": pageSortValueMap?.[pageNumber],
          "limit": rowsPerPage
        }
      }
    }
    getMatterData(paginationQuery);
  };

    /**
   * Cancel Export Modal
   */
    const onCancelBtnClk = () => {
      setExportLoading(false)
      setModalOpen(false)
    }

    /**
     * function is to open the export modal to show download options
     */
    const handleExportBtnClk = () => {
      setExportSelection(defaultExportOption)
      setModalOpen(true);
      setExportLoading(true);
      setCurrentPageExport(true)
    }

  /**
  * Function to validate search query and set query or show no results UI (server side)
  * @param {string} searchText
  */
  const handleServerSideSearch = (searchText) => {
    if(searchText === '')
    {
      setPage(0);
    }
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };

  /**
  *  Function to set filter query on filter action (server side)
  * @param {string} query
  */
  const handleServerSideFilter = (filterQuery) => {
    setFilterQuery(filterQuery);
  };

  /**
  * Function to call api on sort action (server side)
  * @param {string} name
  * @param {string} direction
  */
  const handleServerSideSort = ({ name, direction }) => {
    if(name === "matterCreateDate") {
      name = "creationDate";
    }
    const sortQuery = formCsSortQuery(name,direction);
    setSortQuery(sortQuery);
  };

	const matterTableCustomOptions = {
		//To be renamed along with export functionality
		downloadOptions: {
			filename: "MatterList",
		},
		responsive: "standard",
		rowsPerPage: rowsPerPageMatters,
		rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_MATTER,
		filterConfigs: FILTER_CONFIGS,
    isMatter: true,
    page: page,
		serverSide: ENABLE_SERVER_SIDE_FEATURES_MATTER,
		totalCount: matterListingData?.count,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSideSort: handleServerSideSort,
	};


	/**
	 * After click on export button
	 */
	const onClickCreate = () => {
    setCreateExportLoading(true);
    const isActiveFilter = formCsFilterObject('isActive', 'match', true);
    const orgIdFilter = formCsFilterObject('organizations.id', 'match', orgIdentifier);
    const defaultQuery = {
      "filters": [isActiveFilter, orgIdFilter],
      "operation": "AND",
      "properties": null
    }
    const userRelation = {
      "filters": relations,
      "operation": "AND",
      "properties": null
    }
    let sensitiveMatterQuery = getSensitiveMatterPayload(matterAccessibility?.viewAll, userDetails?.userUniqueId);
    let exportQuery = formCompositeSearchQuery([CS_SCOPES.MATTER],  filterQuery, searchQuery, sortQuery, {}, {getAll: true}, defaultQuery, userRelation,sensitiveMatterQuery);

    if(exportSelection === 'currentPage'){
      const result = matterListingData?.matters?.map(a => a?.identifier);
      const selectionQuery =
      {
        "filters": [formCsFilterObject("id", "in", result)],
        "operation": "AND",
        "properties": null
      }
      exportQuery = formCompositeSearchQuery([CS_SCOPES.MATTER], {}, {}, sortQuery, {}, {getAll: true}, defaultQuery, selectionQuery,sensitiveMatterQuery);
    }

    postServiceWithHeaders(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=true&organizationId=${orgIdentifier}&options=restrictMode`,
      exportQuery,
      {},
      "arraybuffer"
    )
    .then((response) => {
      const data = response?.data;
      const contentType = response?.headers["content-type"];
      getExportedData(data, contentType, 'matter');
      setModalOpen(false)
      setExportLoading(false);
      setCreateExportLoading(false);
    })
    .catch((error) => {
      setModalOpen(false)
      setExportLoading(false);
      setCreateExportLoading(false);
      addSnack({
        message: getErrorMessage(error)
      });
    });
	}

	/**
	 * Function to call Matter Search Service
	 * @param {string} query
	 */
  const getMatterData = (paginationQuery) => {
    setLoading(true);
    const isActiveFilter = formCsFilterObject('isActive', 'match', true);
    const orgIdFilter = formCsFilterObject('organizations.id', 'match', orgIdentifier);
    const defaultQuery = {
      "filters": [isActiveFilter, orgIdFilter],
      "operation": "AND",
      "properties": null
    }

    const userRelation = {
      "filters": relations,
      "operation": "AND",
      "properties": null
    }

    const sortQueryMatter = {...sortQuery};
    if(searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(MATTER_DEFAULT_SORT_QUERY)) {
      sortQueryMatter.sortBy = [CS_SCORE_SORT_OBJ, ...sortQueryMatter?.sortBy];
    }
    let sensitiveMatterQuery = getSensitiveMatterPayload(matterAccessibility?.viewAll, userDetails?.userUniqueId);
    const query = formCompositeSearchQuery([CS_SCOPES.MATTER], filterQuery, searchQuery, sortQueryMatter, paginationQuery, {}, defaultQuery, userRelation,sensitiveMatterQuery);

    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=false&organizationId=${orgIdentifier}&options=restrictMode`,
      query
    )
      .then((response) => {
        const mappedMatterResponse = matterDataMapping(response?.data?.matter);
        dispatch(setMatterListingData(mappedMatterResponse));
        setSortValue(response?.data?.matter?.sortValue)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

	/**
	 * This function will use for click on the any row
	 */
  const handleRowClick = (rowData, rowMeta) => {
    const selectedMatter=matterListingData?.matters[rowMeta?.dataIndex]
    dispatch(setMatterDetail({ selectedMatter}))
    history.push(`/matter/${selectedMatter?.identifier}`);
  }

	return (
		<div className={classes.matterWrapper} data-testid="AllMatters">
			<div className={classes.dataTableWrap}>
				<MuiDataTable
					data={matterListingData?.matters}
					columns={matterColumns}
					onRowClick={handleRowClick}
					setTableColumns={setMatterColumns}
					customOptions={matterTableCustomOptions}
					disableFilter={false}
					exportLoading={exportLoading}
					onExportClick={handleExportBtnClk}
					loading={loading}
					hideAddNewButton={true}
          allowedActions={getAllowedActionsList(matterAccessibility)}
          // FEATURE - TABLE ACTION BUTTON
          featureButton= {
            <FeatureToggle name="featureFlag">
              <Button>
                Feature Button
              </Button>
            </FeatureToggle>
          }
				/>
			</div>
      <ExportModal
          title={MATTERS}
          mode={modalOpen}
          onCancel={onCancelBtnClk}
          onCreate={onClickCreate}
          showExportAllOption={showExportAllOption}
          exportSelection={exportSelection}
          setExportSelection={setExportSelection}
          currentPageExport={currentPageExport}
          setCurrentPageExport={setCurrentPageExport}
          showCurrentSelection={false}
          exportLoading={createExportLoading}
        />
		</div>
	);
};

export default AllMattersList;
