import { Body, Button } from "@walmart-web/livingdesign-components"
import React from "react"
import { RETRY } from "../../constants/actionButtons";
import { APPLICATION_ERROR_MSG, SOMETHING_WENT_WRONG } from "../../constants/messages";
import { appRenderErrorPageStyles } from "./AppRenderErrorPage.styles";

export const AppRenderErrorPage = ({ onClickRetry }) => {
    const classes = appRenderErrorPageStyles();
    return (
        <Body as="p" size="large">
            <div className={classes.failureDiv}>
                <div className={classes.boldTitle}>
                    {SOMETHING_WENT_WRONG}
                </div>
                <div className={classes.infoMsg}>
                    {APPLICATION_ERROR_MSG}
                </div>
                <Button
                    size="medium"
                    variant="primary"
                    onClick={() => onClickRetry()}
                    data-testid="retry-btn"
                >
                    {RETRY}
                </Button>
            </div>
        </Body>
    )
}
