import {
    Modal,
    Divider,
    Button,
  } from "@walmart-web/livingdesign-components";
  import React, { useEffect } from "react";
  import { ReleaseNotesModalStyles } from "./ReleaseNotesModal.styles";
  import { OK } from "../../../constants/actionButtons";
  import { useDispatch, useSelector } from "react-redux";
  import { setShowReleaseNotes } from "../../../store/actions/commonActions";
  import moment from "moment";
  
  /**
   * ModalActions
   */
  const ModalActions = (props) => {
    const { onOkClick } = props;
    return (
      <Button
        variant="primary"
        size="small"
        data-testid="ok-btn"
        id="ok-btn"
        onClick={onOkClick}
      >
        {OK}
      </Button>
    );
  };
  
  const ReleaseNotesModal = () => {
    const classes = ReleaseNotesModalStyles();
    const releaseNotesData = useSelector((state) => state?.user?.releaseNotesData);
    const dispatch = useDispatch();
    const showReleaseNotesModal = useSelector((state) => state?.common?.showReleaseNotesModal);
  
    useEffect(() => {
      if (localStorage.getItem("releaseNotesData") === null) {
        localStorage.setItem("releaseNotesData", releaseNotesData?.releaseVersion)
        dispatch(setShowReleaseNotes(true))
      } else {
        const lastReleaseNoteVersion = localStorage.getItem('releaseNotesData');
        if (lastReleaseNoteVersion !== releaseNotesData?.releaseVersion &&
          moment(releaseNotesData?.expiry).isAfter(moment(new Date()))) {
          dispatch(setShowReleaseNotes(true))
          localStorage.setItem("releaseNotesData", releaseNotesData?.releaseVersion)
        } else {
          dispatch(setShowReleaseNotes(false))
        }
      }
    }, [releaseNotesData])
  
    return (
      <Modal
        data-testid="delete-Modal"
        isOpen={showReleaseNotesModal}
        onClose={() => setShowReleaseNotes(false)}
        size="medium"
        title={"Release Notes"}
        actions={<ModalActions onOkClick={() => dispatch(setShowReleaseNotes(false))} />}
      >
        <div className={classes.wrapperDiv}>
          <Divider />
          {releaseNotesData?.content?.map((item) => (
            <>
              <h4 key={item?.heading} className={classes.subTitle}>{item?.heading}</h4>
              {item?.content?.map((row) => <ul className={classes.list} key={row}>
                <strong className={classes.bullets}>&bull;</strong> {row}
              </ul>)}
            </>
          ))}
        </div>
      </Modal>
    );
  };
  
  export default ReleaseNotesModal;