import React, { useState } from "react";
import PropTypes from "prop-types";
import { DropzoneArea } from "material-ui-dropzone";
import { Folder } from "../../constants/images";
import { LIGHT_DARK_BLACK } from "../../constants/colors";
import  { DROPZONE_LABEL, LEDES_SAMPLE_FILE_NAME, NEW_DOCUMENTS } from "../../constants/constants";
import { MAX_FILE_UPLOAD_SIZE_MSG } from "../../constants/messages";
import { renderTernary, niceBytes, getErrorMessage } from "../../helpers/utils";
import { DescriptionOutlined } from '@mui/icons-material';
import { Filter } from "@livingdesign/icons";
import { Button, Spinner, StyledText, useSnackbar } from "@walmart-web/livingdesign-components";
import { CloudDownload, InfoCircle } from "@walmart-web/livingdesign-icons";
import { ThemeProvider, createStyles, createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { postServiceWithHeaders } from "../../axios/axios";
import { NODE_JS_URL } from "../../constants/baseURLs";

const useStyles = makeStyles(theme => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  folderTags: {
    marginRight: '5px'
  },
  dropzoneWrap: {
    background: "#fff",
    padding: "40px"
  },
  dropzoneArea: {
    border: "2px dashed #80b8ee !important",
    background: "#e6f1fc !important",
    height: "285px",
    "& .MuiDropzoneArea-textContainer": {
      height: "100%",
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
      justifyContent: "center",
    }
  },
  dropzoneText: {
    fontFamily: "Bogle !Important",
    margin: "0 !important"
  },
  formFieldsWrap: {
    paddingLeft: "20px"
  },
  root: {
    "& .MuiInputLabel-formControl": {
      fontFamily: "Bogle"
    }
  },
  newDocTitle: {
    fontSize: "24px",
    margin: "24px 0"
  },
  dropzoneLabelText: {
    display: "block",
    fontSize: "16px"
  },
  dropzoneSubLabelText: {
    display: "block",
    fontSize: "12px",
    width: '410px',
    margin: '0 auto'
  },
  marginTop: {
    marginTop: "10px",
  },
  marginBottom: {
    marginBottom: "10px",
  },
  fileToUploadIconWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    padding: "5px",
    boxShadow: "3px 3px 12px rgba(0,0,0,0.2)",
    borderRadius: "5px",
  },
  fileToUploadIcon: {
    height: "50px !important",
    width: "50px !important",
    color: '#74767c',
  },
  fileToUploadSize: {
    marginTop: "2px",
    fontSize: "0.85rem",
    color: '#74767c',
  },
  fileDetailsWrap: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  fileName: {
    color: LIGHT_DARK_BLACK,
    marginTop: "5px",
    display: "block",
    fontWeight: "bold",
    wordBreak: "break-word",

  },
  downloadIcon: {
    marginLeft: "5px",
    marginTop: "5px"
  },
  spinner:{
    marginLeft: "5px",
    display:"flex"
  },
  downloadText: {
    color: "#515357",
    cursor: "pointer",
    paddingLeft: 0
  }
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTypography: {
        subtitle1: {
          color: LIGHT_DARK_BLACK,
          margin: "15px 0 50px",
          display: "block",
          fontWeight: "bold",
        }
      },
      MuiDropzonePreviewList: {
        imageContainer: {
          '& .MuiTypography-body1': {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "none",
          },
          '&:hover span:last-child': {
            display: "block",
          }
        },
        removeButton: {
          boxShadow: "none",
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' fill='currentColor' data-testid='banner-close' aria-hidden='true' role='presentation' style='font-size: 1rem; vertical-align: -0.175em;'%3E%3Cpath d='M3.05 3.05a.5.5 0 0 1 .707 0L8 7.293l4.243-4.243a.5.5 0 0 1 .707.707L8.707 8l4.243 4.243a.5.5 0 0 1-.707.707L8 8.707 3.757 12.95a.5.5 0 0 1-.707-.707L7.293 8 3.05 3.757a.5.5 0 0 1 0-.707z' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "12px 12px",
          backgroundPosition: "center",
          top: "8px",
          right: "8px",
          "& .MuiSvgIcon-root": {
            display: "none"
          },
        },
      },
      MuiDropzoneArea: {
        textContainer: {
          textAlign: "center",
          display: "flex",
          flexDirection: "column-reverse",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        },
        icon: {
          height: "48px",
          width: "48px",
        }
      }
    },
  });

  /**
   * Function to return dropzone label component
   * @returns dropzone label component
   */
  const DropzoneLabelText = (props) => {
    const classes = useStyles();
    const {dropZoneLabel, acceptedFilesLabel} = props
    return (
      <>
        <span className={classes.dropzoneLabelText}>
          {dropZoneLabel || DROPZONE_LABEL}
        </span>
        <span className={classes.dropzoneSubLabelText}>
          {acceptedFilesLabel}
        </span>
      </>
    );
  };

/**
 * Document dropzone component
 */
const DocumentDropzone = (props) => {
  const classes = useStyles();
  const { addSnack } = useSnackbar();
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const {acceptedFilesLabel, acceptedFileTypes, onFileUpload, fileDropStatus,
    dropZoneLabel, showFilePreview = false, showFilterIcon = false,
    maxFileUploadSize, fileUploadInfoMsg, hideInfoMsg, downloadLink, version="1"} = props

  /**
   * Function to trigger callback on file upload
   * @param {array} files
   */
  const handleFileUpload = (files) => {
    onFileUpload(files)
  }

  /**
   * Function to handle snackbar messages in case of file drop error
   * @param {String} message
   * @param {String} variant [success/error/info]
   */
  const handleOnAlert = (message, variant) => {
    if(variant === 'error') {
      addSnack({
        message: `${message}`
      });
    }
  }

  /**
   * Function callback for custom file preview
   * @param {*} fileObject
   * @returns
   */
  const handlePreviewIcon = (fileObject) => {
    const { size, name} = fileObject?.file;
    return (
      <div className={classes.fileToUploadIconWrap} data-testid="file-to-upload-preview">
        <DescriptionOutlined fontSize="medium" className={classes.fileToUploadIcon} />
        <div className={classes.fileDetailsWrap}>
          <div><span className={classes.fileName}>{name}</span></div>
          <div className={classes.fileToUploadSize}>{niceBytes(size)}</div>
        </div>
      </div>
    )
  }

  /**
  * Fumction to trigger callback on file download
  */
  const onClickDownLoad = () => {
    setSpinnerLoading(true);
    postServiceWithHeaders(NODE_JS_URL,`/download?version=${version}`,{id: "LEDESSampleFileId", isContent: true}, {},"blob")
      .then((res) => {
        const url = URL.createObjectURL(res?.data);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.setAttribute("download", LEDES_SAMPLE_FILE_NAME);
        document.body.appendChild(anchor);
        anchor.click();
        setSpinnerLoading(false);
      })
      .catch((error) => {
        setSpinnerLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      })
  }


  return (
    <ThemeProvider theme={getMuiTheme()}>
      {renderTernary(showFilePreview,
        <div className={classes.marginBottom}>
          <StyledText
            color="gray"
            leading={<InfoCircle size="small" />}
            size="large"
          >
            {fileUploadInfoMsg ?? MAX_FILE_UPLOAD_SIZE_MSG}
          </StyledText>
        </div>,
        <></>)
      }
      <DropzoneArea
        clearOnUnmount={true}
        dropzoneProps={ { disabled: !fileDropStatus} }
        onChange={(files) => handleFileUpload(files)}
        Icon={showFilterIcon ? Filter : Folder}
        dropzoneText={<DropzoneLabelText dropZoneLabel={dropZoneLabel} acceptedFilesLabel={acceptedFilesLabel} />}
        showPreviewsInDropzone={false}
        previewText={NEW_DOCUMENTS}
        filesLimit={showFilePreview ? 20 : 1}
        showAlerts={false}
        dropzoneClass={classes.dropzoneArea}
        dropzoneParagraphClass={classes.dropzoneText}
        acceptedFiles={acceptedFileTypes}
        maxFileSize={maxFileUploadSize ?? null}
        onAlert={(message, variant) => handleOnAlert(message, variant)}
        showPreviews={showFilePreview}
        showFileNames={true}
        showFileNamesInPreview={showFilePreview}
        getPreviewIcon={handlePreviewIcon}
      />
      { !hideInfoMsg &&
      <div className={classes.marginTop}>
        <StyledText
          color="gray"
          leading={<InfoCircle size="small" />}
          size="large"
        >
          {fileUploadInfoMsg ?? MAX_FILE_UPLOAD_SIZE_MSG}
        </StyledText>
        {downloadLink &&
        <StyledText
          color="gray"
          leading={<div className={classes.spinner}>
            |{spinnerLoading ? <Spinner size="small" color="gray" className={classes.spinner}/> : <CloudDownload size="small" className={classes.downloadIcon}/>}
            </div>}
          size="large"
        >
          <Button size="small" variant="tertiary" onClick = {onClickDownLoad} disabled={spinnerLoading} className={classes.downloadText}>
            {downloadLink}
          </Button>
        </StyledText>}
      </div> }
    </ThemeProvider>
  )
}

export default DocumentDropzone;

DocumentDropzone.propTypes = {
  /**
   * label text to indicate accepted file types
   **/
  acceptedFilesLabel: PropTypes.string,
  /**
   * array of accepted mime `types
   **/
  acceptedFileTypes: PropTypes.array,
  /**
   * Function to return list of file to upload
  * */
   onFileUpload: PropTypes.func,
    /**
   * Boolean to enable/disable dropzone area as per business conditions
   **/
   fileDropStatus: PropTypes.bool,
};

DocumentDropzone.defaultProps = {
  acceptedFilesLabel: "",
  acceptedFileTypes: [ `image/jpeg` ],
  onFileUpload: (files) => {
    // callback function to handle upload function here
  },
  fileDropStatus: false,
};
