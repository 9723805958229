import { createUseStyles } from "react-jss";

/**
 * styles used in the component.
 */
export const appRenderErrorPageStyles = createUseStyles({
    spinner: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    failureDiv: {
        textAlign: "center",
        paddingTop: "22%"
    },
    boldTitle: {
        fontWeight: "bold",
        fontSize: "30px",
    },
    infoMsg: {
        marginTop: "20px",
        marginBottom: "20px"
    }
});
