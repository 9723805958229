//Budget Details-Validation Messages
export const BUDGET_NAME_MESSAGE = "Budget Name should be alpha numeric.";
export const BUDGET_AMOUNT_MESSAGE = "Budget Number should be numeric.";
export const DUE_DATE_VALIDATION_MESSAGE = "Due Date cannot be prior to today's date."

//Organization Details-Validation Messages
export const NUMBER_FEMALE_ASSOCIATE_MSG = "Number of Female Associates should be numeric.";
export const NUMBER_MINORITY_ASSOCIATE_MSG = "Number of Minority Associates should be numeric.";
export const PHONE_NUMBER_MSG = "Phone Number should be numeric.";
export const FAX_NUMBER_MSG = "Fax Number should be numeric.";

//External User-Validation Msgs
export const TKID_MESSAGE="TKID should be alpha numeric."
export const TKID_LENGTH_MESSAGE="Only 10 characters are allowed."
export const FACILITY_NUMBER_MESSAGE = "Facility number should be numeric.";
export const REGION_MESSAGE = "Region should be numeric.";
export const MARKET_NUMBER_MESSAGE = "Market number should be numeric.";
export const SETTLEMENT_AUTHORITY_MESSAGE = "Settlement Authority should be numeric.";
export const DOCKET_NUMBER_MESSAGE = "Docket number should be alpha numeric.";
export const INVOICE_NUMBER_MESSAGE = "Invoice number should be numeric.";
export const ITEM_NUMBER_MESSAGE = "Item number should be alpha numeric.";
export const MAX_LENGTH_MESSAGE = "Only 15 digit are allowed.";
export const EMAIL_ID_MESSAGE = "Email id is not valid.";
export const ZIPCODE_MESSAGE = "Only alphanumeric characters and ( - ) are allowed.";
export const TOKEN_MESSAGE = "Invalid Token.";
export const NEW_PASSWORD_MESSAGE = "Password should contain 1 Uppercase, 1 Lower Case, 1 Digit and 1 Special character.";
export const RE_ENTER_PASSWORD_MESSAGE = "It should be identical to New Password.";
export const ONLY_LETTERS_ALLOWED = 'Only letters allowed.';
export const SUBJECT_MATTER_EXPERTISE_MSG = "Subject matter expertise is a mandatory field."
export const COMPENSATORY_DAMAGES_MESSAGE = "Compensatory Damages should be numeric.";
export const COUNTER_CLAIMS_MESSAGE = "Counter Claims Damages should be numeric.";
export const CURRENT_DEMAND_MESSAGE = "Current Demand should be numeric.";
export const CURRENT_OFFER_MESSAGE = "Current Offer should be numeric.";
export const CURRENT_AUTHORITY_MESSAGE = "Current Authority should be numeric.";
export const PUNITIVE_DAMAGES_MESSAGE = "Punitive Damages should be numeric.";
export const FIELD_REQUIRED = 'Required field';
export const UNITS_VALIDATION_MESSAGE = "Units should be greater than zero";
export const RATE_VALIDATION_MESSAGE = "Rate cannot be zero";

//matter details
export const ONLY_ALPHA_NUMERIC_ALLOWED_COURT_NAME = 'Only alphanumeric characters, dots ( . ) and commas ( , ) are allowed.';
export const ONLY_ALPHA_NUMERIC_ALLOWED_DOCKET = 'Only alphanumeric characters, dashes ( - ) and colons ( : ) are allowed.';

export const CHARACTER_RESTRICTION = (v) => {
    return (`Only ${v} characters are allowed.`)
  }

  export const DIGIT_RESTRICTION = (v) => {
    return (`Only ${v} digits are allowed.`)
  }

  export const MIN_DIGIT_RESTRICTION = (v) => {
    return (`Minimum length should be ${v}.`)
  }

  export const MANDATORY_FIELD = (v) => {
    return (`${v} is mandatory.`)
  }
