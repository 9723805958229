import { createUseStyles } from "react-jss";

/**
 * styles used in the component.
 */
export const FeedbackModalStyles = createUseStyles({
  modalButtonGroup: {
		marginTop: "20px",
		justifyContent: "flex-end"
	},
	gridContainer: {
		width: "100%",
		marginTop: "10px",
	},
	Container: {
		marginBottom: "10px",
		maxWidth: "100%",
	},
	modalLabel:{
		fontSize: '13px',
		marginBottom: '2px',
		marginTop: '10px'
	},
	ratingbox: {
		marginLeft: '20px',
		"& span.MuiRating-root":{
			"& label":{
				padding: '0 15px'
			}
		}
	},
	rateExp:{
		color: 'gray',
		fontSize: '13px',
		textAlign: 'center',
    margin: '5px 0 10px 0'
	},
	rateFeed:{
		color: 'gray',
		fontSize: '13px',
		textAlign: 'left',
    margin: '5px 0 10px 0'
	},
	line:{
		margin: '15px -24px',
		display: 'block',
		height: '1px',
		border: 0,
		borderTop: '1px solid #ccc'
	}
});
