import { createUseStyles } from "react-jss";
import { SPACING } from "../../../constants/styleGuide";

const EditTimeKeeperProfileStyles = createUseStyles({
  leftContent: {
    paddingRight: SPACING.s72,
  },
  rightContent: {
    paddingTop: SPACING.s32,
    paddingLeft: SPACING.s24,
    paddingRight: SPACING.s24,
  },
  contentSpacing8: {
    paddingTop: SPACING.s8,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  contentSpacing42: {
    paddingTop: "42px",
  },
  tag: {
    padding: 4,
    display: "inline-block",
  },
  info: {
    marginLeft: SPACING.s10,
  },
  unAssigned: {
    fontStyle: "italic",
  },
  approvalDataRightSection: {
    marginTop: "32px",
  },
  grid: {
    justifyContent: "space-between",
  },
  gridColumn: {
    marginTop: "70px",
  },
  divider: {
    marginTop: "80px",
  },
  divider1: {
    marginTop: "118px",
  },
  divider2: {
    marginTop: "43px",
  },
  createdDate: {
    marginTop: "25px",
  },
  companyNameStyle:{
    display:"flex",marginTop:"30px"
  },
  errorMsg:{
    marginLeft:"12px",
  }
});

export default EditTimeKeeperProfileStyles;
