import React, { useEffect, useMemo, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from '@mui/material/utils';
import { CS_SCOPES, SELECT_MATTER_NUMBER, SELECT_MATTER_NAME, CS_TYPEAHEAD_PAGINATION_QUERY, MATTER_NUMBER_AUTOCOMPLETE_DEFAULT_SORT_QUERY, MATTER_AUTOCOMPLETE_DEFAULT_SORT_QUERY } from "../../../constants/constants";
import { useSnackbar } from "@walmart-web/livingdesign-components";
import { postService } from "../../../axios/axios";
import { getErrorMessage, getHighlightedText, getSensitiveMatterPayload, useUserAssociatedRelation } from "../../../helpers/utils";
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import { formCompositeSearchQuery, formCsFilterObject, formCsMatterAutocompleteQuery  } from "../../../helpers/csQueryUtils";
import { matterDataMapping } from "../../../helpers/tableUtils";
import { setMatterFilterDropDownData } from "../../../store/actions/masterDataAction";
import { createAutoStyles } from "../AutocompleteStyles.style";

const MatterFilterAutocomplete = (props) => {
	const { value, setValue, required = false, colKey, name="matterAutocomplete", disabled = false, id="matter-autocomplete", isRestricted = true } = props;
	const { addSnack } = useSnackbar();
	const classes = createAutoStyles();
	const dispatch = useDispatch();
	const orgIdentifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
	const userDetails = useSelector((state) => state?.user?.userDetail?.attributes);
	const { matterFilterDropDownData } = useSelector((state) => state?.masterData);
	const [loading,setLoading]=useState();
  const [inputValue, setInputValue] = useState('');
	const matterNumberDropDown = [...new Set(matterFilterDropDownData?.nodeList?.map(val => `${val?.matterNumber}`))];
	const matterNameDropDown = [...new Set(matterFilterDropDownData?.nodeList?.map(val => `${val?.matterName}`))];
	const matterAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.matters);
	const relations = useUserAssociatedRelation(matterAccessibility);
	const SORT_QUERY = (colKey==='matterNumber') ? MATTER_NUMBER_AUTOCOMPLETE_DEFAULT_SORT_QUERY : MATTER_AUTOCOMPLETE_DEFAULT_SORT_QUERY;

	/**
	*Function to call Composite Search Service for matter
	*@param {string} query
	*/
	const getMattersData = (searchQuery = {}) => {
		setLoading(true);
		const isActiveFilter = formCsFilterObject('isActive', 'match', true);
		const orgIdFilter = formCsFilterObject('organizations.id', 'match', orgIdentifier);
		const defaultQuery = {
			"filters": [isActiveFilter,orgIdFilter],
			"operation": "AND",
			"properties": null
		}
		const userRelation = {
      "filters": relations,
      "operation": "AND",
      "properties": null
    }
		const sensitiveMatterQuery = getSensitiveMatterPayload(matterAccessibility?.viewAll, userDetails?.userUniqueId);

		const query = formCompositeSearchQuery([CS_SCOPES.MATTER], {}, {}, SORT_QUERY, CS_TYPEAHEAD_PAGINATION_QUERY, {}, defaultQuery, searchQuery, userRelation,sensitiveMatterQuery);

		postService(
			COMPOSITE_SEARCH_SERVICE,
			`/composite-search/v1?returnCsv=false&organizationId=${orgIdentifier}${isRestricted ? '&options=restrictMode':''}`,
			query
		)
			.then((response) => {
				const matterResponse = matterDataMapping(response?.data?.matter);
				dispatch(setMatterFilterDropDownData({ count: matterResponse?.count, nodeList: matterResponse?.matters }))
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				addSnack({
					message: getErrorMessage(error)
				});
			});
	}

  // Debounce logic to form search query
	const fetch = useMemo(() =>
		debounce((searchText, callback) => {
			const searchQueryObj = formCsMatterAutocompleteQuery(searchText);
			if(searchText?.length > 2) {
				getMattersData(searchQueryObj)
			}
		}, 600),
		[],
	);

	useEffect(() => {
		if(inputValue?.length < 1) {
			dispatch(setMatterFilterDropDownData([]))
			setLoading(false)
		}
	},[inputValue])

	return (
		<Autocomplete
			multiple={false}
			disablePortal
			disabled={disabled}
			options={((colKey==='matterNumber')?matterNumberDropDown:matterNameDropDown)}
			noOptionsText={inputValue?.length < 1 ? "Please type to search" : "No options" }
			loading={loading}
			getOptionLabel={(option) => `${option}`}
			renderOption={(props, option) => (
				<li {...props} className={classes.boxLi}>
					{ getHighlightedText(`${option}`,inputValue) }
				</li>
			)}
			value={value}
			renderInput={(params) => <TextField {...params} label="" placeholder={((colKey==='matterNumber')? SELECT_MATTER_NUMBER : SELECT_MATTER_NAME)} inputProps={{
				...params?.inputProps, required, style: {
						color: '#2E2F32',
						fontFamily: 'Bogle',
						fontSize: 16
					}
				}}
			name= {name}
			/>}
			isOptionEqualToValue={(option, value) => option === value}
			onInputChange={(event, newInputValue) => {
				if(!((!event) || (event?.type === "click" && newInputValue !== ""))) {
					setInputValue(newInputValue);
					setLoading(true)
					fetch(newInputValue)
				}
			}}
			onChange={(e, v) => {
				setValue(e, v)
			}}
			data-testid="filter-panel-dropdownAutocomplete"
			id={id}
			margin="dense"
			variant="outlined"
		/>
	);
};

export default MatterFilterAutocomplete;
