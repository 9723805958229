import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import {
  Body,
  Breadcrumb,
  BreadcrumbItem,
  Grid,
  GridColumn,
  Heading,
  IconButton,
} from "@walmart-web/livingdesign-components";
import { Pencil, TrashCan } from "@walmart-web/livingdesign-icons";
import React from "react";
import { detailModes as modes } from "../../../constants/constants";
import { CopyLink } from "../../../constants/images";

const useStyles = createUseStyles({
  icons: {
    display: "flex",
    justifyContent: "right",
  },
  breadcrumb: {
    cursor: "pointer",
  },
});

const DetailHeader = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    mode,
    setMode,
    onTrashClick,
    onCopyLinkClick,
    subject,
    schedule,
    newTitle,
    breadcrumbValues,
    newSubtitle,
    hideEditBtn,
    loading,
    hideDeleteBtn,
  } = props;

  return (
    <Grid data-testid="detail-header">
      {mode === modes.CREATE ? (
        <GridColumn>
          <Heading as="h1" size="large">
            {newTitle}
          </Heading>
          {newSubtitle ? <Body as="p">{newSubtitle}</Body> : null}
        </GridColumn>
      ) : (
        <>
          <GridColumn sm={7}>
              {breadcrumbValues && breadcrumbValues?.length>0 && breadcrumbValues && (
                <Breadcrumb>
                  {breadcrumbValues?.map((item, idx) => {
                    return (
                      <BreadcrumbItem
                        onClick={() =>
                          (item?.path || item?.path === "") &&
                          history.push(
                            `/${item?.path}`,
                            item?.path.includes("matter")&& {
                              currentMatterTab: item?.currentMatterTab,
                            }
                          )
                        }
                        data-testid={`breadcrumb-${idx}`}
                        key={`${item?.path}`}
                      >
                        {item?.label}
                      </BreadcrumbItem>
                    );
                  })}
                </Breadcrumb>
              )}
            <Heading as="h1" size="small">
              {subject}
            </Heading>
            <div className={classes.topSpacing}>
              {schedule ? (
                <Body as="p" size="large">
                  {schedule}
                </Body>
              ) : null}
            </div>
          </GridColumn>
          <GridColumn sm={5}>
            <div className={classes.icons}>
              {!hideEditBtn && mode === modes.VIEW ? (
                <IconButton
                  a11yLabel="edit"
                  size="medium"
                  onClick={() => setMode(modes.EDIT)}
                  disabled={loading}
                >
                  <Pencil a11yLabel={"detail-edit-button"} size="medium" data-testid="detail-edit-button" />
                </IconButton>
              ) : null}
              {onCopyLinkClick && (
                <IconButton
                  a11yLabel={"link-icon"}
                  size="medium"
                  onClick={onCopyLinkClick}
                  disabled={loading}
                >
                  <CopyLink fill={loading ? "#babbbe" : "#000"} fontSize="medium" data-testid="linkIcon" />
                </IconButton>
              )}
              {!hideDeleteBtn ? (
                <IconButton
                  a11yLabel={"delete-icon"}
                  size="medium"
                  onClick={onTrashClick}
                  disabled={loading}
                >
                  <TrashCan  a11yLabel={"detailDeleteButton"} size="medium" data-testid="detailDeleteButton" />
                </IconButton>
              ) : null}
            </div>
          </GridColumn>
        </>
      )}
    </Grid>
  );
};

export default DetailHeader;
