import React, { useEffect, useState } from "react";
import {
  TextField,
  Autocomplete,
  Box,
  createFilterOptions,
} from "@mui/material";

const UtbmsActivityAutocomplete = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    name,
    value,
    setValue,
    label,
    placeholder,
    helperText,
    data,
    handleBlur,
    tableMeta,
    showDescription = true,
    codeId = ""
  } = props;

  useEffect(() => {
    if(value !== '' && value !== undefined) {
      setSelectedOption(JSON.parse(value))
    }
  }, [value])

  const set = new Set;
  const optionsData = data?.filter(o => !set.has(o?.['value']) && set.add(o?.['value']))

  const filterOptions = !showDescription ? createFilterOptions({
    stringify: (option) =>
      `${option?.value}`,
  }):createFilterOptions({
    stringify: (option) =>
      `${option?.value} (${option?.description})`,
});

  return (
    <Autocomplete
      open={open}
      onOpen={() => { setOpen(true); }}
      onClose={() => { setOpen(false); }}
      name={`${name}-autocomplete`}
      data-testid={`${name}-autocomplete`}
      id={`${name}-autocomplete`}
      autoHighlight
      autoComplete
      options={optionsData}
      getOptionLabel={(option) => {
        if(!showDescription)
        {
          return `${option?.value}`
        }
        else
        {
        return `${option?.value} (${option?.description})`
        }
      }}
      renderOption={(props, option) => {
        if(!showDescription)
        {
          return (<Box component="li" {...props}>{option?.value}</Box>)
        }
        else
        {
        return (<Box component="li" {...props}>{option?.value} ({option?.description})</Box>)
        }
      }}
      filterOptions={filterOptions}
      value={(value !== '' && value !== undefined)?JSON.parse(value):value}
      onChange={(e, v) => {
        setSelectedOption(v);
        if(codeId !== "")
        {
          setValue(JSON.stringify(v), codeId)
        }
        else
        {
          setValue(JSON.stringify(v))
        }
      }}
      onBlur={() => handleBlur(name, tableMeta, JSON.stringify(selectedOption))}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params?.inputProps }}
          margin="dense"
          label={label}
          placeholder={placeholder}
          // helperText={helperText}
          variant="outlined"
          error={Boolean(helperText)}
        />
      )}
    />
  );
};

export default UtbmsActivityAutocomplete;
