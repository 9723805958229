const types = {
  SET_MATTER_DATA: 'SET_MATTER_DATA',
  SET_MASTER_DATA: 'SET_MASTER_DATA',
  SET_MATTER_DROP_DOWN_DATA: 'SET_MATTER_DROP_DOWN_DATA',
  SET_MATTER_FILTER_DROP_DOWN_DATA: 'SET_MATTER_FILTER_DROP_DOWN_DATA',
  SET_PEOPLE_DROP_DOWN_DATA: 'SET_PEOPLE_DROP_DOWN_DATA',
  SET_PEOPLE_DROP_DOWN_DATA_WITH_OPTIONS: 'SET_PEOPLE_DROP_DOWN_DATA_WITH_OPTIONS',
  SET_EXTERNAL_PEOPLE_DROP_DOWN_DATA: 'SET_EXTERNAL_PEOPLE_DROP_DOWN_DATA',
  SET_ORGANIZATION_DROP_DOWN_DATA: 'SET_ORGANIZATION_DROP_DOWN_DATA',
}

export default types;
