import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Grid, GridColumn, Heading, Skeleton, useSnackbar } from "@walmart-web/livingdesign-components";
import { useSelector } from "react-redux";
import { getService } from "../../../axios/axios";
import { ORGANIZATION_SERVICE } from "../../../constants/baseURLs";
import { ADDRESS_LINE_1, ADDRESS_LINE_2, CITY, COUNTRY, NO, STATE, YES, ZIPCODE } from "../../../constants/constants";
import { cleanValueString, getErrorMessage, renderTernary } from "../../../helpers/utils";
import { amountFormat, formatPhoneNumber } from "../../../helpers/commonUtils";
import { Typography } from "@mui/material";

const useStyles = createUseStyles({
	container: {
		paddingBottom: "24px"
	},
	contactContainer: {
		backgroundColor: "#ffffff",
		marginBottom: "32px",
		padding: "24px"
	},
	spacing24: {
		paddingTop: 20,
	},
	spacing36: {
		paddingBottom: 10,
	},
	pcGridLeftInside: {
		display: "inline-flex",
		width: "100%",
	},
	pcTitle: {
		fontSize: "14px !important",
		fontFamily: "Bogle !important",
		fontWeight: 'bold !important'
	},
	rightContactContent: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	pcTitleAnswer: {
		fontSize: 14,
		fontFamily: "Bogle",
	},
	fontSize14: {
		fontSize: "14px !important",
		fontFamily: "Bogle !important",
	},
})

const OrganizationDetails = (props) => {
	const classes = useStyles();
	const { addSnack } = useSnackbar();
	const { loading } = props;
	const [totalBudgetAmount, setTotalBudgetAmount] = useState({ balanceAmount: 0, budgetAmount: 0 });
	const {
		identifier,
		addressType,
		addressLine1,
		addressLine2,
		city,
		state,
		country,
		email,
		phoneNo,
		faxNo,
		mELApproved,
		expertise,
		primaryPracticeArea,
		taxId,
		billingContactName,
		relationshipPartnerName,
		relationshipPartnerEmail,
		zipCode
	} = useSelector((state) => state?.organization?.organizationDetailValues);

	const getTotalBudgetAmount = (data) => {
		let budgetAmount = 0;
		let balanceAmount = 0;
		data?.map((a) => {
			balanceAmount = balanceAmount + (a?.balanceAmount ?? 0);
			budgetAmount = budgetAmount + (a?.budgetAmount ?? 0);
		})
		setTotalBudgetAmount({ balanceAmount, budgetAmount })
	}

	const getBudgetOrgData = () => {
		getService(
			ORGANIZATION_SERVICE,
			`/budgets/v1/organizations/${identifier}`
		).then((response) => {
			const data = response?.data?.budgets;
			getTotalBudgetAmount(data);
		}).catch((error) => {
			setTotalBudgetAmount({balanceAmount: 0, budgetAmount: 0 })
			addSnack({
				message: getErrorMessage(error)
			  });
			});
	}

	useEffect(() => {
		getBudgetOrgData();
	}, [])

	return <div className={classes.container} data-testid="org-detail-tab-content">
		<div className={classes.contactContainer}>
			<Grid className={classes.spacing36}>
				<Heading as="h3" size="small" id="contact">
					Contact
				</Heading>
			</Grid>
			<Grid>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>Email:</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(email)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>Phone Number:</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : (formatPhoneNumber(phoneNo) ?? "-")}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>

			</Grid>
			<Grid>
			<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>Fax Number:</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(faxNo)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
			<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>Address Type:</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={6}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(addressType)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>{ ADDRESS_LINE_1 }</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={6}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(addressLine1)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>{ADDRESS_LINE_2}</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(addressLine2)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>{CITY}</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(city)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>{STATE}</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(state)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>{COUNTRY}</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(country)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>{ZIPCODE}</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(zipCode)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
			</Grid>
		</div>
		<div className={classes.contactContainer}>
			<Heading as="h3" size="small" id="authorization">
				Authorization
			</Heading>
			<Grid>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>Expertise:</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(expertise)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>MEL Approved:</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={6}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : (renderTernary(mELApproved, YES, NO))}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
			</Grid>
			<Grid>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>Primary Practice Area:</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(primaryPracticeArea)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
			</Grid>
		</div>
		<div className={classes.contactContainer}>
			<Heading as="h3" size="small" id="financial">
				Financial
			</Heading>
			<Grid>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>Billing Contact Name:</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(billingContactName)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>Total Budgeted:</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={6}>
							  <Grid className={classes.pcTitleAnswer}>
								<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : `$ ${cleanValueString(amountFormat(totalBudgetAmount?.budgetAmount))}`}</Typography>
							  </Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
			</Grid>
			<Grid>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={3}>
								<Typography className={classes.pcTitle}>Relationship Partner Name:</Typography>
							</GridColumn>
							<GridColumn lg={1}>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(relationshipPartnerName)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={4}>
								<Typography className={classes.pcTitle}>Total Spent:</Typography>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={6}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" />
										: `$ ${amountFormat((totalBudgetAmount?.budgetAmount ?? 0) - (totalBudgetAmount?.balanceAmount ?? 0))}`}
									</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
			</Grid>
			<Grid>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={3}>
								<Typography className={classes.pcTitle}>Relationship Partner Email:</Typography>
							</GridColumn>
							<GridColumn lg={1}>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={8}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14}>{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(relationshipPartnerEmail)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
				<GridColumn sm={12} md={12} lg={6}>
					<Grid className={classes.spacing24}>
						<Grid className={classes.pcGridLeftInside}>
							<GridColumn sm={2} lg={3}>
								<Typography className={classes.pcTitle}>Tax ID:</Typography>
							</GridColumn>
							<GridColumn lg={1}>
							</GridColumn>
							<GridColumn className={classes.rightContactContent} sm={6} lg={6}>
								<Grid className={classes.pcTitleAnswer}>
									<Typography className={classes.fontSize14} id="tax-id">{loading ? <Skeleton width="150px" height="15px" /> : cleanValueString(taxId)}</Typography>
								</Grid>
							</GridColumn>
						</Grid>
					</Grid>
				</GridColumn>
			</Grid>
			<Grid>
			</Grid>
		</div>
	</div>
}
export default OrganizationDetails
