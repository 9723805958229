import { TextArea, Radio, Checkbox, Switch, Body,Grid, GridColumn } from "@walmart-web/livingdesign-components";
import { TextField, Autocomplete, createFilterOptions, Select, MenuItem } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInputValue } from "../../store/actions/matterAction";
import DisplayValue from "../../components/TextField/DisplayValue/DisplayValue";
import { Box } from "@mui/system";
import moment from 'moment';
import MuiValidationMessage from "../../Validation/MuiValidationMessage/MuiValidationMessage";
import { matterDetailValidationRules } from "../../Validation/validationRules";
import { MANDATORY_FIELD } from "../../Validation/validationMessages";
import { renderTernary,isNumeric } from "../../helpers/utils";
import MatterJSON from "./MatterJSON";
import MatterComponentStyles from "./MatterComponentStyles";
import MasterDataAutocomplete from "../Autocomplete/MasterDataAutocomplete/MasterDataAutocomplete";
import MatterMultiSelect from "./MatterMultiSelect";

/**
* Function to return field Label with optional *(mandatory) symbol
* @param {*} label
* @param {*} isRequired
* @returns
*/
const FieldLabel = ({ label, isRequired }) => {
return (<Body as="p" size="small">
  <strong>{`${label}${isRequired ? '*' : ''}:`}</strong>
</Body>)
}

const MatterComponent = (props) => {
  const classes = MatterComponentStyles();

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const matterDetail = useSelector((state) => state?.matter?.matterDetailsTab);
  const {node, errors, register, isDisabled} = props;

  /**
    * Gets called when data changes in input fields.
    */
  const handleInputChange = (name, value) => {
    dispatch(setInputValue(name, value))
  }

  /**
    * Gets called when data changes in input fields.
    */
  const handleMultiInputChange = (name, value) => {
    const selectedOptions = (typeof value === 'string' ? (value?.split(',')) : value);
    dispatch(setInputValue(name, selectedOptions))
  }

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option?.name} (${option?.referenceKey})`,
  });

  const required = node?.required;

  const renderComponent = (node, errors, register) => {
    const sectionInput = matterDetail;
    const nodeValue = sectionInput?.[node?.propertyName] ?? node?.value ?? node?.defaultValue;
    // When callMasterData is explicitly mentioned in renderingHints and the value is true, use masterDataAutocomplete component
    const callMasterDataFlag = (JSON.parse(decodeURI(node?.renderingHints))?.hasOwnProperty('callMasterData') && JSON.parse(decodeURI(node?.renderingHints))?.callMasterData);
    if(callMasterDataFlag)
    {
      const masterDataTypes = JSON.parse(decodeURI(node?.renderingHints))?.masterDataTypes.join(',');
      const nodeValue = sectionInput?.[node?.propertyName] ?? node?.value ?? node?.defaultValue;
      return <div className={classes.contentSpacing16}> <FieldLabel label={node?.title} isRequired={node?.required} />
      <MasterDataAutocomplete
        value={nodeValue}
        setValue={(v) => {
          return handleInputChange(node?.propertyName, v.value)}}
        dataType={masterDataTypes}
        testid="matter-select"
        placeholderValue={node?.title}
        componentType={"matterDetail"}
      /> </div>
    }
    else
    {
    switch (node?.dataType) {
      case "Text":
        return (node?.displayProperty === "Editable" ?
          <div className={classes.contentSpacing16}>
            <FieldLabel label={node?.title} isRequired={node?.required} />
            <TextField
              name={node?.propertyName}
              type="text"
              value={nodeValue}
              placeholder={node?.title}
              error={
                ((!nodeValue && node?.required) && <MuiValidationMessage  message={MANDATORY_FIELD("Matter Name")} mode="LD"/>) ||
                errors?.[node?.propertyName] && <MuiValidationMessage  message={errors?.[node?.propertyName]?.message} mode="LD"/>
              }
              {...register(
                node?.propertyName,
                {...matterDetailValidationRules[node?.propertyName], onChange: (e) => {handleInputChange(node?.propertyName, e.target.value)}}
              )}
              disabled={isDisabled}
              fullWidth={true}
              required= {node?.required}
              data-testid="matter-textfeild"
              id={`matter-textfeild-${node?.propertyName}`}
            /> </div > : <></>
        );
        case "Number":
          return (node?.displayProperty === "Editable" ?
            <div className={classes.contentSpacing16}>
              <FieldLabel label={node?.title} isRequired={node?.required} />
              <TextField
                name={node?.propertyName}
                value={renderTernary(node?.propertyName === "paidToDate" , `$${nodeValue}` , nodeValue)}
                error={
                  ((!nodeValue && node?.required) && <MuiValidationMessage  message={MANDATORY_FIELD(nodeValue)} mode="LD"/>) ||
                  (errors?.[node?.propertyName] && <MuiValidationMessage  message={errors?.[node?.propertyName]?.message} mode="LD"/>)
                }
                {...register(
                  node?.propertyName,
                  {...matterDetailValidationRules[node?.propertyName], onChange: (e) => {handleInputChange(node?.propertyName,  isNumeric(e.target.value) ? parseInt(e.target.value) : e.target.value)}}
                )}
                disabled={isDisabled}
                placeholder={node?.title}
                fullWidth={true}
                required= {node?.required}
                data-testid="matter-textfeild"
                id={`matter-textfeild-${node?.propertyName}`}
              /> </div > : <></>
          );
      case "Textarea":
        return (node?.displayProperty === "Editable" ?
          <div className={classes.contentSpacing16}>
            <FieldLabel label={node?.title} isRequired={node?.required} />
            <TextArea
              name={node?.propertyName}
              textAreaProps={{
                name: node?.propertyName,
              }}
              value={nodeValue}
              error={
                ((!nodeValue && node?.required) && <MuiValidationMessage  message={MANDATORY_FIELD} mode="LD"/>) ||
                errors?.[node?.propertyName] && <MuiValidationMessage  message={errors?.[node?.propertyName]?.message} mode="LD"/>
              }
              {...register(
                node?.propertyName,
                {...matterDetailValidationRules[node?.propertyName], onChange: (e) => {handleInputChange(node?.propertyName, e.target.value)}}
              )}
              disabled={isDisabled}
              fullWidth={true}
              required= {node?.required}
              data-testid="matter-textArea"
              placeholder={node?.title}
              id={`matter-textArea-${node?.propertyName}`}
            /> </div> : <></>
        );
      case "Select": {
        return (node?.displayProperty === "Editable" ?
          <div className={classes.contentSpacing16}>
            <FieldLabel label={node?.title} isRequired={node?.required} />
            <Select
              value={nodeValue}
              onChange={(v) => handleInputChange(node?.propertyName, v.target.value)}
              name= {node?.propertyName}
              required= {node?.required}
              disabled={isDisabled}
              placeholder={node?.title}
              data-testid="matter-select"
              id={`matter-select-${node?.propertyName}`}
              fullWidth={true}
            >
              {node?.range?.map((item, idx) => {
                return (
                  <MenuItem value={item} key={`${item}`} id={`select-${node?.propertyName}-opt-${idx}`}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select></div> : <></>
        );
      }
      case "checkbox":
        return (node?.displayProperty === "Editable" ?
          <div className={classes.contentSpacing16}>
            <FieldLabel label={node?.title} isRequired={node?.required} />
            <Checkbox
              checked={nodeValue}
              name={node?.propertyName}
              onChange={(v) => handleInputChange(node?.propertyName, v.target.checked)}
              checkboxProps={{
                name: node?.propertyName,
                required: node?.required
              }
              }
              value={nodeValue}
              disabled={isDisabled}
              label={node?.propertyName}
              data-testid="matter-checkbox"
              id={`matter-checkbox-${node?.propertyName}`}
            /> </div> : <></>
        );
      case "radio":
        return (node?.displayProperty === "Editable" ?
          <div className={classes.contentSpacing16}>
            <FieldLabel label={node?.title} isRequired={node?.required} />
            <Radio
              name={node?.propertyName}
              value={nodeValue}
              onChange={(v) => handleInputChange(node?.propertyName, v.target.checked)}
              checked={nodeValue}
              disabled={isDisabled}
              radioProps={{
                name: node?.propertyName,
                required: node?.required,
              }}
              label={node?.propertyName}
              data-testid="matter-radio"
              id={`matter-radio-${node?.propertyName}`}
            /> </div> : <></>
        );
      case "autocomplete":
        return (node?.displayProperty === "Editable" ?
          <div className={classes.contentSpacing16}>
            <FieldLabel label={node?.title} isRequired={node?.required} />
            <Autocomplete
              multiple={node?.multiple}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              autoHighlight
              autoComplete
              options={node?.options}
              getOptionLabel={(option) => option?.name || ""}
              renderOption={(node, option) => (
                <Box component="li" {...node} >
                  {`${option?.name} (${option?.referenceKey})`}
                </Box>
              )}
              filterOptions={filterOptions}
              value={nodeValue}
              onChange={(e, v) => {
                handleInputChange(node?.propertyName, v)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params?.inputProps, required }}
                  margin="dense"
                  label={node?.label}
                  placeholder={node?.placeholder}
                  variant="outlined"
                  />
                  )}
                  fullWidth={true}
                  data-testid="matter-autoComplete"
                  id={`matter-autoComplete-${node?.propertyName}`}
            />
          </div> : <></>
        )
      case "Date": {
        return (
          node?.displayProperty === "Editable" ?
            <div className={classes.contentSpacing16}>
              <LocalizationProvider dateAdapter={AdapterMoment} >
                <FieldLabel label={node?.title} isRequired={node?.required} />
                <DatePicker
                  sx={{width:"100%"}}
                  name={node?.propertyName}
                  value={nodeValue ? moment(nodeValue) : null}
                  disablePast={node?.disablePast}
                  disabled={isDisabled}
                  onChange={(v) => handleInputChange(
                    node?.propertyName,
                    moment(v).format("MM/DD/yyyy")
                  )}
                  slotProps={{textField:{"data-testid":"matter-datePicker",id:`matter-datepicker-${node?.propertyName}`,placeholder:node?.title}}}
                />
              </LocalizationProvider > </div> : <></>);
      }
      case "label":
        return (
          <div className={classes.contentSpacing16} data-testid="matter-displaylabel">
            <DisplayValue
              label={node?.label}
              value={node?.value}
            />
          </div>
        );
      case "Boolean":
        return (
          <div className={classes.contentSpacing16}>
            <Grid>
              <GridColumn sm={12} md={12} lg={6}><span>{`${node?.title}: `}</span></GridColumn>
              <GridColumn sm={12} md={12} lg={6}>
                <Switch
                  isOn={nodeValue}
                  a11yLabelledBy
                  onClick={() => handleInputChange(node?.propertyName, !nodeValue)}
                  disabled={isDisabled}
                  data-testid="matter-switch"
                  id={`matter-switch-${node?.propertyName}`}
                />
              </GridColumn>
            </Grid></div>
        );
      case "Multi-Select":
        return <MatterMultiSelect node={node} handleInputChange={handleMultiInputChange} />;
      case "JSON":
        return <MatterJSON node={node} handleInputChange={handleInputChange} FieldLabel={FieldLabel} />;
      default:
        return <></>;
    }
  }
  };

  return <div>{renderComponent(node, errors, register)}</div>;
};

export default MatterComponent;
