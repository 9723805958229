import types from "../types/peopleActionTypes";

export const initialState = {
  initialValues: {
    companyName:"",
    companyWebsite:"",
    status:"",
    orgType:"",
    orgSubType:[],
    orgTags:"",
    companyNotes:"",
    firstName:"",
    lastName:"",
    phoneNumber:"",
    address:"",
    mobileNumber:"",
    email:"",
    fax:"",
    primaryContactWebsite:"",
    primaryContactNotes:"",
    primaryContactTags:"",
    organizationName: "",
    addressType: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    firmEmailAddress: "",
    website: "",
    firmPhoneNo: "",
    firmFaxNo: "",
    MELApproved: "",
    expertise: "",
    primaryPracticeArea: "",
    taxId: "",
    billingContractName: "",
    relationshipPartnerName: "",
    numberofFemaleAssociates: "",
    numberofMinorityAssociates: "",
    relationshipPartnerEmail: "",
  },

  organizationData: {
    organizations: [],
    count: 0,
  },
  externalUserData: {
    nodeList: [],
    count: 0,
  },
  organizationsPeopleData: {
    peopleData: [],
    count: 0,
  },
  externalUserDetail: {
    id: '',
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    company: '',
    conatNotes: '',
    phone: '',
    mobile: '',
    fax: '',
    webSite: '',
    role: '',
    tags: '',
    tkId: '',
    classification: '',
    startDate: '',
    endDate: '',
    rate: '',
    rateStartDate: '',
    rateEndDate: ''
  },
  organizationDetail: {
    organizationName: "",
    addressType: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    firmEmailAddress: "",
    website: "",
    firmPhoneNo: "",
    firmFaxNo: "",
    MELApproved: "",
    expertise: "",
    primaryPracticeArea: "",
    taxId: "",
    billingContractName: "",
    relationshipPartnerName: "",
    numberofFemaleAssociates: "",
    numberofMinorityAssociates: "",
    relationshipPartnerEmail: "",

    companyName: "",
    companyWebsite: "",
    status: "",
    orgType: "",
    orgSubType: [],
    orgTags: "",
    companyNotes: "",

    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    mobileNumber: "",
    email: "",
    fax: "",
    primaryContactwebsite: "",
    primaryContactNotes: "",
    primaryContactTags: "",
  },
  associatedMattersData: {
    associatedMatters: [],
    count: 0,
  },
  userDetail: {
    lastName: "",
    role: "",
    isExternalUser: false,
    access: false,
    billingContact: false,
    gender: "",
    walmartApprovingAttroney: "",
    endDate: "",
    description: "",
    isActive: false,
    phoneNo: "",
    classsification: "",
    starBarLicense: "",
    ethinicity: "",
    jdYear: "",
    email: "",
    identifier: "",
    tkid: "",
    classification: "",
    justificationForTimeKeeper: "",
    birthDate: "",
    versionKey: "",
    firstName: "",
    subjectMatterExpertise: "",
    organizationName: "",
    pk: "",
    primaryOfficeLocation: "",
    createdDate:"",
    startDate: "",
    userId:"",
    status:"",
    vendorId:"",
    userRequestId:"",
    isTimeKeeperRoleEnabled: false
},
  matterSpecificRates: [],
  practiceAreaRates:[],
  matterSpecificRateDetail:{
    matter: null,
    walmartAttorneyName: "",
    currentStandardRate: "",
    proposedWalmartRate: "",
    startDate: null,
    endDate: null,
    justificationForRate: "",
    requesterEmail: "",
    practiceAreaCode: ""
  },
  matterSpecificRateDetailInitialValues:{
    matter: null,
    walmartAttorneyName: "",
    currentStandardRate: "",
    proposedWalmartRate: "",
    startDate: null,
    endDate: null,
    justificationForRate: "",
    requesterEmail: "",
    practiceAreaCode: "",
  }
};

const peopleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ORGANIZATION_DATA:
      return {
        ...state,
        organizationData: action.payload,
      };

    case types.SET_EXTERNAL_USER_DATA:
      return {
        ...state,
        externalUserData: action.payload,
      };

    case types.SET_NEW_ORGANIZATION_DATA:
      const { name, value } = action.payload;
      return {
        ...state,
        organizationDetail: {
          ...state.organizationDetail,
          [name]: value,
        },
      };

    case types.SET_ASSOCIATED_MATTERS_DATA:
      return {
        ...state,
        associatedMattersData: action.payload,
      };

    case types.RESET_ORGANIZATION_DETAIL:
      return {
        ...state,
        organizationDetail: initialState.organizationDetail,
      };

    case types.SET_ORGANIZATION_DETAIL:
      return {
        ...state,
        organizationDetail: action.payload,
      }

    case types.SET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
      }

    case types.SET_MATTER_SPECIFIC_RATES:
      return {
        ...state,
        matterSpecificRates: action.payload,
      };

    case types.SET_PRACTICE_AREA_RATES:
      return {
        ...state,
        practiceAreaRates: action.payload,
      };

    case types.SET_MATTER_SPECIFIC_RATE_INPUT_VALUE: {
      const { name, value } = action.payload;
      return {
        ...state,
        matterSpecificRateDetail: {
          ...state.matterSpecificRateDetail,
          [name]: value,
        },
      }
    }

    case types.RESET_MATTER_SPECIFIC_RATE_DETAIL:
      return {
        ...state,
        matterSpecificRateDetail: initialState.matterSpecificRateDetailInitialValues,
      };

    default:
      return state;
  }
};

export default peopleReducer;
