import React, { useState, useEffect } from "react";
import { Body, Grid, GridColumn, Skeleton } from "@walmart-web/livingdesign-components";
import { renderTernary } from "../../../helpers/utils";
import DisplayValueStyles from "./DisplayValue.styles";
import { NO_RESULTS_FOUND } from "../../../constants/constants";
import { displayLocalDate } from "../../../helpers/utcUtils";

const DisplayValue = (props) => {
  const classes = DisplayValueStyles();
  const { label, value, node, loadingFields = [] } = props;
  const [nestedProperties, setNestedProperties] = useState([]);
  const displayTypeTable = node?.dataType === "JSON" && JSON.parse(decodeURI(node?.renderingHints))?.displayType === "table";
  const displayTypeEditableTable = node?.dataType === "JSON" && JSON.parse(decodeURI(node?.renderingHints))?.displayType === "editableTable";

  useEffect(() => {
    if (node !== undefined && node !== null && node?.renderingHints !== undefined) {
      setNestedProperties(JSON.parse(decodeURI(node?.renderingHints))?.nestedDefinition);
    }
  }, [node])

  const convertDisplayValue = (value, label) => {
    if(['fileDate', 'courtInfoEndDate'].some(fieldName => fieldName === label)) {
      return (value) ? displayLocalDate(value) : null;
    } else if(Array.isArray(value)) {
      return value.join(", ")
    } else {
      return value;
    }
  }

  return (
    <Grid>
      <GridColumn sm={12} md={12} lg={6}>
        <Body as="p" variant="2">
          <strong>{`${label}: `}</strong>
        </Body>
      </GridColumn>
      <GridColumn sm={12} md={12} lg={6}>
        {node?.dataType !== "JSON" &&
          renderTernary(loadingFields?.length !== 0 && loadingFields?.includes(node?.propertyName),
            <Skeleton height="18px" variant="rectangle" />,
            <Body as="p" variant="2" id={`display-value-${label?.split(' ')?.join('-')}`} data-testid={`display-value-${label?.split(' ')?.join('-')}`} UNSAFE_style={{ wordWrap: "break-word", whiteSpace: "break-spaces" }}>
              {convertDisplayValue(value ? value : '-')}
            </Body>)}
      </GridColumn>
      <GridColumn sm={12} md={12} lg={11}>
        {displayTypeTable &&
          <div className={classes.dataTableWrap} data-testid={`table-${node?.title?.toLowerCase()?.split(' ')?.join('-')}`}>
            <table className={classes.tableBody}>
              <tr>
                {(nestedProperties && nestedProperties !== null && nestedProperties !== undefined && nestedProperties?.length !== 0)
                  && nestedProperties?.map((item, idx) => {
                    return (
                      <th className={classes.tableHeader} key={`${item?.title}`}>{renderTernary(item?.title, item?.title, "-")}</th>
                    )
                  })}
              </tr>
              {(value === null || value === undefined || value?.length === 0) &&
                <tr>
                  {renderTernary(loadingFields?.length !== 0 && loadingFields?.includes(node?.propertyName),
                    <td className={classes.tableEmptyData} colspan="3"><Skeleton height="18px" variant="rectangle" /></td>,
                    <td className={classes.tableEmptyData} colspan="3">{NO_RESULTS_FOUND}</td>
                  )}
                </tr>
              }
              {value ? value?.map((item, key) => {
                return (
                  <tr key={item?.id}>
                    {(nestedProperties && nestedProperties !== null && nestedProperties !== undefined && nestedProperties?.length !== 0)
                      && nestedProperties?.map((val, idx) => {
                        if (loadingFields?.length !== 0 && loadingFields?.includes(node?.propertyName)) {
                          return <td className={classes.tableCell} key={`${val?.propertyName}`}><Skeleton height="18px" variant="rectangle" /></td>
                        }
                        else {
                          return <td className={classes.tableCell} key={`${val?.propertyName}`}>{convertDisplayValue(item[val?.propertyName], val?.propertyName)}</td>
                        }
                      })}
                  </tr>
                )
              }) : <></>}
            </table>
          </div>
        }
        {displayTypeEditableTable &&
          <div className={classes.dataTableWrap} data-testid={`editableTable-${node?.title?.toLowerCase()?.split(' ')?.join('-')}`}>
            <table className={classes.tableBody}>
              <tr>
                {(nestedProperties && nestedProperties !== null && nestedProperties !== undefined && nestedProperties?.length !== 0)
                  && nestedProperties?.map((item, idx) => {
                    return (
                      <th className={classes.tableHeader} key={`${item?.title}`}>{renderTernary(item?.title, item?.title, "-")}</th>
                    )
                  })}
              </tr>
              {(value === null || value === undefined || value?.length === 0) &&
                <tr>
                  {renderTernary(loadingFields?.length !== 0 && loadingFields?.includes(node?.propertyName),
                    <td className={classes.tableEmptyData} colSpan={nestedProperties.length}><Skeleton height="18px" variant="rectangle" /></td>,
                    <td className={classes.tableEmptyData} colSpan={nestedProperties.length}>{NO_RESULTS_FOUND}</td>
                  )}
                </tr>
              }
              {value ? value?.map((item, key) => {
                return (
                  <tr key={item?.id}>
                    {(nestedProperties && nestedProperties !== null && nestedProperties !== undefined && nestedProperties?.length !== 0)
                      && nestedProperties?.map((val, idx) => {
                        if (loadingFields?.length !== 0 && loadingFields?.includes(node?.propertyName)) {
                          return <td className={classes.tableCell} key={`${val?.propertyName}`}><Skeleton height="18px" variant="rectangle" /></td>
                        }
                        else {
                          return <td className={classes.tableCell} key={`${val?.propertyName}`}>{convertDisplayValue(item[val?.propertyName], val?.propertyName)}</td>
                        }
                      })}
                  </tr>
                )
              }) : <></>}
            </table>
          </div>
        }
      </GridColumn>
    </Grid>
  );
};

export default DisplayValue;
