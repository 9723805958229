import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { createUseStyles } from "react-jss";
import {
  ButtonGroup,
  Button,
  Modal,
  Grid,
  Select,
  GridColumn,
  Divider,
  useSnackbar,
  Spinner
} from "@walmart-web/livingdesign-components";

import { Pencil, ArrowRight, Money } from "@livingdesign/icons";
import {
  patchService,
} from "../../../../axios/axios";
import { INVOICE_SERVICE } from "../../../../constants/baseURLs";
import { getErrorMessage, onInputNumberKeyDown } from "../../../../helpers/utils";
import { SELECT_ADJUSTMENT_REASON } from "../../../../constants/constants";
import MasterDataAutocomplete from "../../../../components/Autocomplete/MasterDataAutocomplete/MasterDataAutocomplete";
import { filterPayload, getCurrencyAmount } from "../../../../helpers/commonUtils";
import { CANCEL, UPDATE } from "../../../../constants/actionButtons";
import {TextField} from "@mui/material";

/**
 * styles used in the component.
 */
const border = '1px solid #F1F1F2';
const useStyles = createUseStyles({
  fullWidth: {
    width: "100%"
  },
  uploadModalText: {
    fontSize: "14px",
    marginBottom: "20px",
  },
  textStyle: {
    color: "#2e2f32",
    fontFamily: "Bogle, \"Helvetica Neue\", Helvetica, Arial, sans-serif",
    fontSize: "12px"
  },
  whiteRow: {
    background: '#fff',
    fontWeight: 'bold',
    borderRadius: 5,
    border: border,
    padding: '15px',
    marginTop: '10px',
    color: '#2E2F32',
    '& div > p': {
      fontWeight: 'normal',
      marginTop: '15px',
      marginBottom: 0
    },
    '& div > label': {
      fontWeight: 'normal',
      height: 0
    },
    '& div > svg': {
      top: '1rem'
    }
  },
  greyRow: {
    background: '#F8F8F8',
    borderRadius: 5,
    border: border,
    padding: '15px',
    marginTop: '10px',
    color: '#2E2F32'
  },
  greyRowForEdit: {
    background: '#F8F8F8',
    borderRadius: '0 0 5px 5px',
    borderTop: border,
    padding: '5px 15px 15px 15px',
    color: '#2E2F32',
    marginLeft: '-15px',
    marginRight: '-15px',
    marginBottom: '-15px',
    marginTop: '15px'
  },
  rightText:{
    textAlign: 'right'
  },
  divider:{
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '-15px',
    marginRight: '-15px'
  },
  dividerOutside:{
    marginTop: '10px',
    marginBottom: '10px'
  },
  editIcon: {
    marginRight: '18px',
    cursor: 'pointer'
  },
  editCancel: {
    marginRight: '18px',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  boldText: {
    fontWeight: 'bold'
  }
});

/*
* Thsi function will use for calculating the new total fees and expenses
*/
export const calculateAmount = (prevValue, adjustment, value) => {
  let finalValue = Number(value);
  if(adjustment === 'increaseByPercentage'){
    const percent = (prevValue * value) / 100;
    finalValue = prevValue + Number(percent);
  }else if(adjustment === 'decreaseByPercentage'){
    const percent = (prevValue * value) / 100;
    finalValue = prevValue - Number(percent);
  }else if(adjustment === 'increaseByAmount'){
    finalValue = prevValue + Number(value);
  }else if(adjustment === 'decreaseByAmount'){
    finalValue = prevValue - Number(value);
  }
  return finalValue;
}

/**
 *render action buttons on the modal based on action type add,edit,delete
 **/
const EditModalActions = (props) => {
  const {setEditLoading, handleModalState, editLoading, isDirty, submitEditedInvoice} = props
  const handleCancelClick=()=>{
    setEditLoading(true)
    handleModalState(false)
  }
  return (
    <Grid>
      <GridColumn sm={5}>
        <ButtonGroup>
          <Button
            data-testid="goback-btn"
            id="goback-btn"
            size="small"
            onClick={handleCancelClick}
            disabled={editLoading}
          >
            {CANCEL}
          </Button>
          <Button
            variant="primary"
            size="small"
            data-testid="upload-btn"
            id="update-btn"
            onClick={() => submitEditedInvoice()}
            disabled={editLoading || !isDirty}
          >
            {
              editLoading ? <Spinner color="white" size="small" /> : UPDATE
            }
          </Button>
        </ButtonGroup>
      </GridColumn>
    </Grid>
  );
};

/**
 * InvoiceEditModal component used to add,edit delete party.
 */
const InvoiceEditModal = (props) => {
  const {openEl, handleModalState, invoiceDetail, fetchInvoiceDetail} = props;
  const [isOpen, setIsOpen] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [changeFees, setChangeFees] = useState(0);
  const [changeExpenses, setChangeExpenses] = useState(0);
  const [updatedFees, setUpdatedFees] = useState(0);
  const [updatedExpenses, setUpdatedExpenses] = useState(0);
  const [totalFees, setTotalFees] = useState(invoiceDetail?.totalFees);
  const [comment, setComment] = useState('');
  const [adjustmentReason, setAdjustmentReason] = useState('');
  const [feesAdjustmentType, setFeesAdjustmentType] = useState('');
  const [expenseAdjustmentType, setExpenseAdjustmentType] = useState('');
  const [totalExpenses, setTotalExpenses] = useState(invoiceDetail?.totalExpenses);
  const [isDirty, setIsDirty] = useState(false);
  const classes = useStyles();
  const { addSnack } = useSnackbar();
  const [isPrevValue,setIsPrevValue] = useState(false)


  /**
   * Function for opening the dit toggle
   **/
  const toggleOpen = id => () => setIsOpen(
    isOpen => isOpen === id ? null : id,
  );

  /**
   * calls this function when we edit the party.
   **/
  const submitEditedInvoice = () => {
    setEditLoading(true);
    const bodyData = {
      totalFees: Number(totalFees),
      totalExpenses: Number(totalExpenses),
      adjustmentReason: adjustmentReason,
      comment : comment
    }
    const filteredBody=filterPayload(bodyData)
		patchService(INVOICE_SERVICE, `/invoices/v1/${invoiceDetail?.id}`,filteredBody )
      .then((res) => {
        setEditLoading(false);
        setChangeFees(0);
        setTotalFees(invoiceDetail?.totalFees);
        setUpdatedFees(0)
        setFeesAdjustmentType('');
        setChangeExpenses(0);
        setTotalExpenses(invoiceDetail?.totalExpenses);
        setUpdatedExpenses(0)
        setExpenseAdjustmentType('');
        addSnack({
          message: 'Invoice edited succesfully!',
        });
        handleModalState(false);
        fetchInvoiceDetail();
      })
      .catch((error) => {
        setEditLoading(false);
        handleModalState(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  }

  /*
  * The function will use for selecting reason
  */
  const reasonChange = (type, value) => {
    if(type === 'fee'){
      setChangeFees(0);
      setUpdatedFees(0)
      setTotalFees(invoiceDetail?.totalFees);
      setFeesAdjustmentType(value);
    }else if(type === 'expense'){
      setChangeExpenses(0);
      setUpdatedExpenses(0)
      setTotalExpenses(invoiceDetail?.totalExpenses);
      setExpenseAdjustmentType(value);
    }
    else if(type === 'comment'){
      setComment(value);
    } else if(type === 'adjustmentreason') {
      setAdjustmentReason(value);
    }
  }

  /*
  * The function will use for setting the form values
  */
  const valueChange = (type, value) => {
    const prevValue = (type === 'fee') ? invoiceDetail?.totalFees : invoiceDetail?.totalExpenses;
    const adjustment = (type === 'fee') ? feesAdjustmentType : expenseAdjustmentType;
    const finalValue = calculateAmount(prevValue, adjustment, value);

    if(type === 'fee'){
      setTotalFees(finalValue);
      setUpdatedFees(finalValue)
    }else if(type === 'expense'){
      setTotalExpenses(finalValue);
      setUpdatedExpenses(finalValue)
    }
    setIsPrevValue(((type === 'fee') ? Number(finalValue) !== invoiceDetail?.totalFees :  Number(finalValue) !== invoiceDetail?.totalExpenses) && value!=='')
  }

  useEffect(() => {
    setIsDirty((totalFees + totalExpenses) > 0 && isPrevValue)
  }, [changeFees, changeExpenses])

  return (
    <Modal
      onClose={() => handleModalState(false)}
      isOpen={openEl}
      actions={<EditModalActions setEditLoading={setEditLoading} handleModalState={handleModalState} editLoading={editLoading} isDirty={isDirty} submitEditedInvoice={submitEditedInvoice}/>}
      size="large"
      title="Edit Invoice"
      data-testid="edit-invoice-modal"
      id="edit-invoice-modal"
    >
      <div className={classes.whiteRow}>
        <Grid>
          <GridColumn sm={12} md={6} lg={6}>
            Invoice Total Amount
          </GridColumn>
          <GridColumn sm={12} md={6} lg={6}>
            <div className={classes.rightText}>
              {getCurrencyAmount(invoiceDetail?.totalNetAmount)}
            </div>
          </GridColumn>
        </Grid>
      </div>
      <div className={classes.whiteRow}>
        <Grid>
          <GridColumn sm={12} md={6} lg={6}>
            Total Fees
          </GridColumn>
          <GridColumn sm={12} md={6} lg={6}>
            <div className={classes.rightText}>
              {
                (isOpen === 1)?
                <span
                  className={classes.editCancel}
                  onClick={toggleOpen(1)}
                  data-testid="cancel-toggle1"
                  id="cancel-toggle1"
                >{CANCEL}</span>:
                <span
                  className={classes.editIcon}
                  onClick={toggleOpen(1)}
                  data-testid="edit-toggle1"
                  id="edit-toggle1"
                >
                  <Pencil size="small" />
                </span>
              }
              {getCurrencyAmount(invoiceDetail?.totalFees)}
            </div>
          </GridColumn>
        </Grid>
        {
          (isOpen === 1)?
          <div className={classes.greyRowForEdit}>
            <div className={classes.whiteRow}>
              <Grid>
                <GridColumn sm={12} md={12} lg={12}>
                  <div>
                    <p>Adjustment Type</p>
                    <Select
                      data-testid="adjustments-reason-fee"
                      onChange={(e) => reasonChange(e.target.name, e.target.value)}
                      selectProps={{
                        name: "fee",
                      }}
                      fullWidth={true}
                    >
                      <option id= "increaseByPercentage" value="increaseByPercentage">Increase By Percentage</option>
                      <option id= "decreaseByPercentage" value="decreaseByPercentage">Decrease By Percentage</option>
                      <option id= "increaseByAmount" value="increaseByAmount">Increase By Amount</option>
                      <option id= "decreaseByAmount" value="decreaseByAmount">Decrease By Amount</option>
                      <option id= "setToAmount" selected value="setToAmount">Set to Amount</option>
                    </Select>
                  </div>
                  <div>
                    <p>Value</p>
                    <TextField
                      label={false}
                      type="number"
                      value={changeFees}
                      onChange={(e) => {
                        setChangeFees(e.target.value)
                        valueChange('fee',e.target.value)
                      }}
                      onKeyDown={(e) => onInputNumberKeyDown(e)}
                      leadingIcon={<Money size="small" />}
                      data-testid="fee-value"
                      id="fee-value"
                      fullWidth={true}
                    />
                  </div>
                  <div>
                    <p>Update</p>
                    <p>
                      {getCurrencyAmount(invoiceDetail?.totalFees)} <ArrowRight size="small" />
                      <span className={classes.boldText}>{getCurrencyAmount(Number(updatedFees))}</span>
                    </p>
                  </div>
                </GridColumn>
              </Grid>
            </div>
          </div>:
          <></>
        }
      </div>
      <div className={classes.greyRow}>
        <Grid>
          <GridColumn sm={12} md={6} lg={6}>
            Discounts
          </GridColumn>
          <GridColumn sm={12} md={6} lg={6}>
            <div className={classes.rightText}>
              {getCurrencyAmount(invoiceDetail?.totalFeeDiscounts)}
            </div>
          </GridColumn>
        </Grid>
        <div className={classes.divider}>
          <Divider />
        </div>
        <Grid>
          <GridColumn sm={12} md={6} lg={6}>
            Adjustments
          </GridColumn>
          <GridColumn sm={12} md={6} lg={6}>
            <div className={classes.rightText}>
              {getCurrencyAmount(invoiceDetail?.totalFeeAdjustments)}
            </div>
          </GridColumn>
        </Grid>
      </div>
      <div className={classes.dividerOutside}>
        <Divider />
      </div>
      <div className={classes.whiteRow}>
        <Grid>
          <GridColumn sm={12} md={6} lg={6}>
            Total Expenses
          </GridColumn>
          <GridColumn sm={12} md={6} lg={6}>
            <div className={classes.rightText}>
              {
                (isOpen === 2)?
                <span
                  className={classes.editCancel}
                  onClick={toggleOpen(2)}
                  data-testid="cancel-toggle2"
                  id="cancel-toggle2"
                >{CANCEL}</span>:
                <span
                  className={classes.editIcon}
                  onClick={toggleOpen(2)}
                  data-testid="edit-toggle2"
                  id="edit-toggle2"
                >
                  <Pencil size="small" />
                </span>
              }
              {getCurrencyAmount(invoiceDetail?.totalExpenses)}
            </div>
          </GridColumn>
        </Grid>
        {
          (isOpen === 2)?
          <div className={classes.greyRowForEdit}>
            <div className={classes.whiteRow}>
              <Grid>
                <GridColumn sm={12} md={12} lg={12}>
                  <div>
                    <p>Adjustment Type</p>
                    <Select
                      data-testid="adjustments-reason-expense"
                      onChange={(e) => reasonChange(e.target.name, e.target.value)}
                      selectProps={{
                        name: "expense",
                      }}
                      fullWidth={true}
                    >
                      <option id= "increaseByPercentage" value="increaseByPercentage">Increase By Percentage</option>
                      <option id= "decreaseByPercentage" value="decreaseByPercentage">Decrease By Percentage</option>
                      <option id= "increaseByAmount" value="increaseByAmount">Increase By Amount</option>
                      <option id= "decreaseByAmount" value="decreaseByAmount">Decrease By Amount</option>
                      <option id= "setToAmount" selected value="setToAmount">Set to Amount</option>
                    </Select>
                  </div>
                  <div>
                    <p>Value</p>
                    <TextField
                      label={false}
                      type="number"
                      value={changeExpenses}
                      onChange={(e) => {
                        setChangeExpenses(e.target.value);
                        valueChange('expense',e.target.value)
                      }}
                      onKeyDown={(e) => onInputNumberKeyDown(e)}
                      leadingIcon={<Money size="small" />}
                      data-testid="expense-value"
                      id="expense-value"
                      fullWidth={true}
                    />
                  </div>
                  <div>
                    <p>Update</p>
                    <p>
                      {getCurrencyAmount(invoiceDetail?.totalExpenses)} <ArrowRight size="small" />
                      <span className={classes.boldText}>{getCurrencyAmount(Number(updatedExpenses))}</span>
                    </p>
                  </div>
                </GridColumn>
              </Grid>
            </div>
          </div>:
          <></>
        }
      </div>
      <div className={classes.greyRow}>
        <Grid>
          <GridColumn sm={12} md={6} lg={6}>
            Discounts
          </GridColumn>
          <GridColumn sm={12} md={6} lg={6}>
            <div className={classes.rightText}>
              {getCurrencyAmount(invoiceDetail?.totalExpenseDiscounts)}
            </div>
          </GridColumn>
        </Grid>
        <div className={classes.divider}>
          <Divider />
        </div>
        <Grid>
          <GridColumn sm={12} md={6} lg={6}>
            Adjustments
          </GridColumn>
          <GridColumn sm={12} md={6} lg={6}>
            <div className={classes.rightText}>
              {getCurrencyAmount(invoiceDetail?.totalExpenseAdjustments)}
            </div>
          </GridColumn>
        </Grid>
      </div>
      <div className={classes.dividerOutside}>
        <Divider />
      </div>
      <div className={classes.whiteRow}>
        <Grid>
          <GridColumn sm={12} md={6} lg={6}>
            Details
          </GridColumn>
          <GridColumn sm={12} md={6} lg={6}>
          </GridColumn>
        </Grid>
        <div className={classes.divider}>
          <Divider />
        </div>
        <div>
          <p>Adjustment Reason</p>
          <MasterDataAutocomplete
                  value={adjustmentReason}
                  setValue={(value)=>{reasonChange("adjustmentreason",value?.value)}}
                  dataType={"invoiceAdjustmentReason"}
                  testid="adjustments-reason"
                  placeholderValue={SELECT_ADJUSTMENT_REASON}
                  id={"adjustments-reason"}
            />
        </div>
        <div>
          <p>Comment</p>
          <TextField
            type="text"
            value={comment}
            name ="comment"
            onChange={(e)=>{reasonChange(e.target.name,e.target.value)}}
            data-testid="comment"
            id="comment"
            fullWidth={true}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InvoiceEditModal;

InvoiceEditModal.propTypes = {
  /**
   * pass the open/close state of modal pop up
   **/
  openEl: PropTypes.bool,
  /**
   * handles the reset of modal open/close state
   **/
  handleModalState: PropTypes.func,
  /**
   * Invoice detail Object
   **/
  invoiceDetail: PropTypes.object,
  /**
   * Reset Invoice detail Object
   **/
   fetchInvoiceDetail: PropTypes.func
}
