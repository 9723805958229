import {Tag} from "@walmart-web/livingdesign-components";

const MuiTagItem = (props) => {
  const { value, color, variant, id="Mui-Tag-Item" } = props;

  return (
    <Tag color={color} variant={variant} data-testid="Mui-Tag-Item" id={id}>
      {value}
    </Tag>
  );
}

export default MuiTagItem;
