const oneDay = 1000 * 60 * 60 * 24;

const isToday = (testDate) => {
  testDate = new Date(testDate);
  const today = new Date();
  return testDate.getDate() === today.getDate() &&
    testDate.getMonth() === today.getMonth() &&
    testDate.getFullYear() === today.getFullYear()
}

function getMidnight(day) {
  const date = new Date(day);
  date.setMilliseconds(999);
  date.setSeconds(59);
  date.setMinutes(59);
  date.setHours(23);
  return date;
}

const isTomorrow = (testDate) => {
  testDate = new Date(testDate);
  const midnightTonight = getMidnight(new Date());
  const midnightTomorrow = new Date(midnightTonight.getTime() + oneDay);

  return testDate > midnightTonight && testDate < midnightTomorrow;
}


const isYesterday = (testDate) => {
  testDate = new Date(testDate);
  const midnightTonight = getMidnight(new Date());
  const midnightYesterday = new Date(midnightTonight.getTime() - oneDay);
  return testDate.getDate() === midnightYesterday.getDate() &&
    testDate.getMonth() === midnightYesterday.getMonth() &&
    testDate.getFullYear() === midnightYesterday.getFullYear();
}


const YYYYMMDDhhmmss = (inputDate) => {
  const date = new Date(inputDate);
  return `${date.getFullYear().toString()} - ${((date.getMonth() + 1).toString().length === 2 ?
  (date.getMonth() + 1).toString() : 0 + (date.getMonth() + 1).toString())} -
  ${(date.getDate().toString().length === 2 ? date.getDate().toString() : 0)} ${date.getDate().toString()}
  ${(date.getHours().toString().length === 2 ? date.getHours().toString() : 0)} ${date.getHours().toString()} :
  ${(parseInt(date.getMinutes() / 5) * 5).toString().length === 2 ? (parseInt(date.getMinutes() / 5) * 5).toString() : 0}
  ${(parseInt(date.getMinutes() / 5) * 5).toString()} :00`;
}

const formatTimeTohhmmss = (inputTime) => {
  const getInputTime = inputTime.split(':');
  const hh = getInputTime[0];
  const mm = getInputTime[1];

  return (`${hh  }:${  mm  }:00`);

}

const todayDate = new Date();
const tomorrowDate = () => {
  const tomorrowDate = new Date();
  tomorrowDate.setDate(todayDate.getDate() + 1);
  return tomorrowDate;
}
const defaultEventEndDate = () => {
  const defaultEventEndDate = new Date();
  defaultEventEndDate.setDate(todayDate.getDate() + 5);
  return defaultEventEndDate;
}

const currentMonthFirstDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
const currentMonthLastDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

const getMonthFirstDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

const getMonthLastDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

const getISODate = (date) => {
  if (date === null) {
    return date;
  } else {
    const dt = new Date(date);
    // Extract raw date value to ignore timezone and then construct the isoString manually to use UTC and avoid timezone issues (All DB queries need UTC values!)
    const stringifiedDate = [
      String(dt.getFullYear()),
      String(101 + dt.getMonth()).substring(1),
      String(100 + dt.getDate()).substring(1),
    ].join('-');
    const isoDate = `${stringifiedDate}T00:00:00.000Z`;
    return `dt'${isoDate}'`;
  }
}

const dateToHHMMAMPM = (inputDate) => {
  const splitInputDateTime = inputDate.split(' ');
  const inputTime = (splitInputDateTime[1].split(':'));
  const hh = inputTime[0];
  const mm = inputTime[1];
  const ampm = hh >= 12 ? 'pm' : 'am';

  return (`${hh} : ${mm} ${ampm}`);
}

const timeSince = (date) => {
  const dateinp = new Date(`${date} UTC`)
  const seconds = Math.floor((new Date() - dateinp) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) && Math.floor(seconds) > 30 ? Math.floor(seconds) + " seconds ago" : "just now";
}

export {
  isToday,
  isTomorrow,
  isYesterday,
  getISODate,
  todayDate,
  tomorrowDate,
  dateToHHMMAMPM,
  defaultEventEndDate,
  YYYYMMDDhhmmss,
  formatTimeTohhmmss,
  currentMonthFirstDate,
  currentMonthLastDate,
  timeSince,
  getMonthFirstDate,
  getMonthLastDate,
  getMidnight
}
