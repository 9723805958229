
import types from "../types/documentActionTypes";

export const initialState = {
  documentsData: {
    documents: [],
    count: 0
  },
  uploadedDocumentData: {
    uploadedDocs: [],
    count: 0
  },
  documentMatterDetails: {
  },
  breadCrumbFolders: [],
	documentsMatterData: {
    matters:[],
    count:0
  },
  documentPath: "/", // path for uploading documents
  documentMetadata: {}, // to store document metadata which will be displayed in document preview
  breadcrumbIndex: null, // on click of breadcrumb we are storing breadcrumb index to pass it from upload , preview & listing component
  documentTabIndex : 0, // tab index for documents
  folderSearchQuery : null, // after navigating back to listing from upload & preview. storing search query
  advanceSearchQuery : [] // passing filter search query from documents home to all documents tab
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DOCUMENT_UPLOAD:
      return {
        ...state,
        uploadedDocumentData: {
          uploadedDocs: [
            ...state.uploadedDocumentData.uploadedDocs,
            ...action.payload.uploadedDocs],
          count: action.payload.count
        }
      }
    case types.UPDATE_DOCUMENT_UPLOAD:
      const updatedList = state.uploadedDocumentData.uploadedDocs.map((item) => {
        return item;
      });
      return {
        ...state,
        uploadedDocumentData: {
          uploadedDocs: updatedList
        }
      }
    case types.RESET_DOCUMENT_UPLOAD:
      return {
        ...state,
        uploadedDocumentData: {
          uploadedDocs: []
        }
      }
    case types.DELETE_DOCUMENT_UPLOAD:
      const docList = state.uploadedDocumentData.uploadedDocs
      const indexList = []
      action.payload.deleteRecords.map(updatedItem => {
        const index = state.uploadedDocumentData.uploadedDocs.findIndex(item => item.id === updatedItem.id);
        indexList.push(index)
      })
      indexList.sort((a,b)=> a-b)

      for (let i = indexList.length -1; i >= 0; i--){
        docList.splice(indexList[i],1)
      }
      return {
        ...state,
        uploadedDocumentData: {
          uploadedDocs: docList,
          count: docList.length
        }
      }
    case types.UPDATE_BREADCRUMB_FOLDER_DATA:
      return {
        ...state,
        breadCrumbFolders: action.payload
      }
    case types.SET_DOCUMENTS_MATTERS_DATA:
      return {
        ...state,
        documentsMatterData: action.payload
      }
    case types.SET_DOCUMENTS_MATTERS_DETAIL:
      return {
        ...state,
        documentMatterDetails: action.payload
      }
    case types.SET_DOCUMENTS_PATH:
        return {
          ...state,
          documentPath: action.payload
        }
    case types.SET_DOCUMENTS_METADATA:
      return {
        ...state,
        documentMetadata: action.payload
      }
    case types.SET_BREADCRUMB_INDEX:
      return {
        ...state,
        breadcrumbIndex: action.payload
      }
    case types.SET_TAB_INDEX:
      return {
        ...state,
        documentTabIndex: action.payload
      }
    case types.SET_FOLDER_SEARCH_QUERY:
      return {
        ...state,
        folderSearchQuery: action.payload
      }
    case types.SET_ADVANCE_SEARCH_QUERY:
      return {
        ...state,
        advanceSearchQuery: action.payload
      }
    case types.SET_DOCUMENT_DATA:
      return {
        ...state,
        documentsData: action.payload
      }
    default:
      return state;
  }
}

export default documentReducer;
