import {
  Caption,
  useSnackbar
} from '@walmart-web/livingdesign-components';
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { postService, getService } from "../../../../axios/axios";
import ActionPopover from "../../../../components/Table/ActionPopover/ActionPopover";
import MuiDataTable from "../../../../components/Table/MuiDataTable/MuiDataTable";
import { INVOICE_SERVICE } from "../../../../constants/baseURLs";
import { INVOICE_UPDATE_MESSAGE } from "../../../../constants/messages";
import { TableCellDisplay, amountFormat, filterPayload } from "../../../../helpers/commonUtils";
import {
  ENABLE_SERVER_SIDE_FEATURES_LINE_ITEMS,
  ROWS_PER_PAGE_LINE_ITEMS,
  ROWS_PER_PAGE_OPTIONS_LINE_ITEMS
} from "../../../../constants/tableConfigs";
import { getErrorMessage } from '../../../../helpers/utils';
import { useParams, useHistory } from 'react-router-dom';
import { Operations } from '../../../../axios/operations';
import { formSearchQuery, getSerialNumber } from '../../../../helpers/tableUtils';
import { displayLocalDate } from '../../../../helpers/utcUtils';
import {
  setAdjustedExpenseLineItems,
  setAdjustedFeeLineItems,
  setNonAdjustedFeeLineItems,
  setNonAdjustedExpenseLineItems,
  setNonAdjustedPaginationForLineItems,
  setAdjustedPaginationForLineItems
} from "../../../../store/actions/invoiceActions";
import { MOMENT_DATE_FORMAT } from '../../../../constants/dateFormats';

const LineItemDatatable = ({ isCurrent, invoiceStatus, isAdjusted, lineItemType, fromMatter, currentMatterTab, parentLoading = false }) => {
  const [lineItemsLoading, setLineItemsLoading] = useState(false);
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const { addSnack } = useSnackbar();
  const history = useHistory();
  const [sortQueryAdjusted, setSortQueryAdjusted] = useState("");
  const [sortQueryNonAdjusted, setSortQueryNonAdjusted] = useState("");
  const [rowsPerPageLineItemsAdjusted, setRowsPerPageLineItemsAdjusted] = useState(ROWS_PER_PAGE_LINE_ITEMS);
  const [rowsPerPageLineItemsNonAdjusted, setRowsPerPageLineItemsNonAdjusted] = useState(ROWS_PER_PAGE_LINE_ITEMS);
  const adjustedLineItemsPaginationDetail = useSelector((state) => state?.invoice?.adjustedLineItemsPaginationDetail);
  const nonAdjustedLineItemsPaginationDetail = useSelector((state) => state?.invoice?.nonAdjustedLineItemsPaginationDetail);
  const nonAdjustedFeeLineItems = useSelector((state) => state?.invoice?.nonAdjustedFeeLineItems);
  const nonAdjustedExpenseLineItems = useSelector((state) => state?.invoice?.nonAdjustedExpenseLineItems);
  const adjustedFeeLineItems = useSelector((state) => state?.invoice?.adjustedFeeLineItems);
  const adjustedExpenseLineItems = useSelector((state) => state?.invoice?.adjustedExpenseLineItems);
  const lineItemTypeFeeExpense = lineItemType === "Fees" ? 'fee' : 'expense';
  const defaultSortQuery = "&order=lineItemDate asc"
  const isAdjustedFee = isAdjusted ? adjustedFeeLineItems : nonAdjustedFeeLineItems;
  const isNonAdjustedFee = isAdjusted ? adjustedExpenseLineItems : nonAdjustedExpenseLineItems;
  const lineItemsData = lineItemType === "Fees" ? isAdjustedFee :
  isNonAdjustedFee;

  const defaultFilterCondition = `${formSearchQuery(
    "isActive",
    Operations.EQ,
    true,
    'boolean'
  )}+${formSearchQuery(
    "invoice.id",
    Operations.EQ,
    invoiceId,
    'id'
  )}+${formSearchQuery(
    "lineItemType",
    Operations.EQ,
    lineItemTypeFeeExpense,
    'id'
  )}+${formSearchQuery(
    "isAdjusted",
    Operations.EQ,
    isAdjusted,
    'id'
  )}`;

  const actionsBodyRender = (value, tableMeta, update) => {
    return (
      <React.Fragment>
        {(tableMeta?.tableData[tableMeta?.rowIndex][6] &&
          tableMeta?.tableData[tableMeta?.rowIndex][7]) ? //check if unit & rate present to identify, if row is an invoice level edited row
          <ActionPopover
            actionItems={lineItemActionslist}
            onClickOption={onActionItemClick}
            tableMeta={tableMeta}
          /> : <></>
        }
      </React.Fragment>
    )
  }

  /**
   * Gets called when clicked on action item in datatable row
   */
  const onActionItemClick = (selectedId, tableMeta) => {
    const body = {
      lineItemType: tableMeta.tableData[tableMeta.rowIndex][13],
      lineItemDate: tableMeta.tableData[tableMeta.rowIndex][1],
      description: tableMeta.tableData[tableMeta.rowIndex][2],
      utbmsCode: tableMeta.tableData[tableMeta.rowIndex][3],
      activityCode: tableMeta.tableData[tableMeta.rowIndex][4],
      unit: tableMeta.tableData[tableMeta.rowIndex][6],
      rate: tableMeta.tableData[tableMeta.rowIndex][7],
      tkId: tableMeta.tableData[tableMeta.rowIndex][5],
      timekeeperUserId: tableMeta.tableData[tableMeta.rowIndex][18],
      timekeeperEmail: tableMeta.tableData[tableMeta.rowIndex][16],
      timekeeperName: tableMeta.tableData[tableMeta.rowIndex][17],
      adjustmentValue: tableMeta.tableData[tableMeta.rowIndex][9],
      discountValue:
        tableMeta.tableData[tableMeta.rowIndex][8] < 0
          ? Math.abs(tableMeta.tableData[tableMeta.rowIndex][8])
          : -tableMeta.tableData[tableMeta.rowIndex][8],
      parentId: tableMeta.tableData[tableMeta.rowIndex][15],
    };
    const filteredBody = filterPayload(body)
    postService(
      INVOICE_SERVICE,
      `/invoices/v1/${invoiceId}/sub-line-items`,
      filteredBody
    )
      .then((res) => {
        fetchLineItemDetails();
        addSnack({
          message: INVOICE_UPDATE_MESSAGE,
        });
      })
      .catch((error) => {
        addSnack({
          message: getErrorMessage(error)
        });
      });
  }

  const handleRowClick = (rowData, rowMeta) => {
    history.push(`/invoice/${invoiceId}/lineItem/${rowData[12]}`, { fromMatter, currentMatterTab });
  }

  const renderLineItemDate=(value) => {
    return <Caption as="p">
      {displayLocalDate(value, MOMENT_DATE_FORMAT, "")}
    </Caption>
  }

  const LINEITEMS_COLUMNS = [
    {
      name: "",
      label: "#",
      colKey: "",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, update) => getSerialNumber(tableMeta)
      },
    },
    {
      name: "lineItemDate",
      label: "Date",
      colKey: "lineItemDate",
      options: {
        sort: true,
        customBodyRender: renderLineItemDate
      },
    },
    {
      name: "description",
      label: "Description",
      colKey: "description",
      options: {
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: "230px", padding: "5px 16px" } }),
        setCellProps: () => ({ style: { minWidth: "230px", padding: "5px 16px" } }),
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-description-${tableMeta.rowIndex}`,value),
      },
    },
    {
      name: "utbmsCode",
      label: "UTBMS",
      colKey: "utbmsCode",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-utbmsCode-${tableMeta.rowIndex}`,value),
      },
    },
    {
      name: "activityCode",
      label: "ACT",
      colKey: "activityCode",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-activityCode-${tableMeta.rowIndex}`,value),
      },
    },
    {
      name: "tkId",
      label: "TK ID",
      colKey: "tkId",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-tkId-${tableMeta.rowIndex}`,value),
      },
    },
    {
      name: "unit",
      label: "Units",
      colKey: "unit",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-unit-${tableMeta.rowIndex}`,value),
      },
    },
    {
      name: "rate",
      label: "$ Rate",
      colKey: "rate",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-rate-${tableMeta.rowIndex}`,value),
      },
    },
    {
      name: "netAmount",
      label: "$ Net Amount",
      colKey: "netAmount",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const val = amountFormat(value)
          return TableCellDisplay(`table-row-netAmount-${tableMeta.rowIndex}`,val)
        },
      },
    },
    {
      name: "discountValue",
      label: "$ Discount",
      colKey: "discountValue",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-discountValue-${tableMeta.rowIndex}`,value),
      },
    },
    {
      name: "adjustmentValue",
      label: "$ Adjustment",
      colKey: "adjustmentValue",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-adjustmentValue-${tableMeta.rowIndex}`,value),
      },
    },
    {
      name: "actions",
      label: "Actions",
      colKey: "actions",
      options: {
        sort: false,
        display: (invoiceStatus ==="appealed"),
        setCellHeaderProps: () => ({ style: { color: "#2E2F32", fontWeight: "bold" } }),
        customBodyRender: actionsBodyRender,
      },
    },
    {
      name: "id",
      label: "id",
      colKey: "id",
      options: {
        display: false,
      },
    },
    {
      name: "lineItemType",
      label: "Line Item Type",
      colKey: "lineItemType",
      options: {
        display: false,
      },
    },
    {
      name: "ruleResult",
      label: "Rule Result",
      colKey: "ruleResult",
      options: {
        display: false,
      },
    },
    {
      name: "parentId",
      label: "parentId",
      colKey: "parentId",
      options: {
        display: false,
      },
    },
    {
      name: "timekeeperEmail",
      label: "timekeeperEmail",
      colKey: "timekeeperEmail",
      options: {
        display: false,
      },
    },
    {
      name: "timekeeperName",
      label: "timekeeperName",
      colKey: "timekeeperName",
      options: {
        display: false,
      },
    },
    {
      name: "timekeeperUserId",
      label: "timekeeperUserId",
      colKey: "timekeeperUserId",
      options: {
        display: false,
      },
    },
  ];

  /**
   * Function to call api on pagination action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSidePaginationAdjusted = ({ rowsPerPage, page }) => {
    dispatch(setAdjustedPaginationForLineItems({
      currentPage: page,
      rowsPerPage: rowsPerPage,
      tabName: isCurrent,
      adjustedFeeCount: adjustedLineItemsPaginationDetail?.adjustedFeeCount,
      adjustedExpenseCount: adjustedLineItemsPaginationDetail?.adjustedExpenseCount
    }));
    setRowsPerPageLineItemsAdjusted(rowsPerPage);
    fetchLineItemDetails(`&limit=${rowsPerPage}&offset=${rowsPerPage * page}`, sortQueryAdjusted);
  };

  const handleServerSidePaginationNonAdjusted = ({ rowsPerPage, page }) => {
    dispatch(setNonAdjustedPaginationForLineItems({
      currentPage: page,
      rowsPerPage: rowsPerPage,
      tabName: isCurrent,
      nonAdjustedFeeCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount,
      nonAdjustedExpenseCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount
    }));
    setRowsPerPageLineItemsNonAdjusted(rowsPerPage);
    fetchLineItemDetails(`&limit=${rowsPerPage}&offset=${rowsPerPage * page}`, sortQueryNonAdjusted);
  };

  /**
  * Function to call api on sort action (server side)
  * @param {number} rowsPerPage
  * @param {number} page
  */
  const handleServerSideSortAdjusted = ({ name, direction }) => {
    setSortQueryAdjusted(`&order=${name} ${direction}`);
    fetchLineItemDetails(`&limit=${adjustedLineItemsPaginationDetail?.rowsPerPage}
      &offset=${adjustedLineItemsPaginationDetail?.rowsPerPage * adjustedLineItemsPaginationDetail?.currentPage}`,
      `&order=${name} ${direction}`
    );
  };

  const handleServerSideSortNonAdjusted = ({ name, direction }) => {
    setSortQueryNonAdjusted(`&order=${name} ${direction}`);
    fetchLineItemDetails(`&limit=${nonAdjustedLineItemsPaginationDetail?.rowsPerPage}
      &offset=${nonAdjustedLineItemsPaginationDetail?.rowsPerPage * nonAdjustedLineItemsPaginationDetail?.currentPage}`,
      `&order=${name} ${direction}`
    );
  };

  const lineItemsTableCustomOptionsAdjusted = {
    page: adjustedLineItemsPaginationDetail?.currentPage,
    rowsPerPage: adjustedLineItemsPaginationDetail?.rowsPerPage ?? rowsPerPageLineItemsAdjusted,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_LINE_ITEMS,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_LINE_ITEMS,
    onServerSidePagination: handleServerSidePaginationAdjusted,
    onServerSideSort: handleServerSideSortAdjusted,
    totalCount: lineItemsData?.count,
  };

  const lineItemsTableCustomOptionsNonAdjusted = {
    page: nonAdjustedLineItemsPaginationDetail?.currentPage,
    rowsPerPage: nonAdjustedLineItemsPaginationDetail?.rowsPerPage ?? rowsPerPageLineItemsNonAdjusted,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_LINE_ITEMS,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_LINE_ITEMS,
    onServerSidePagination: handleServerSidePaginationNonAdjusted,
    onServerSideSort: handleServerSideSortNonAdjusted,
    totalCount: lineItemsData?.count,
  };

  const [invoiceLineItemsTableColumns, setInvoiceLineItemsTableColumns] = useState(LINEITEMS_COLUMNS)
  const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.financial?.invoice?.details?.tabs?.lineItems);
  const lineItemActionslist = [{
    "actionId": 0,
    "label": "Set Net Amount to Zero",
    "isAboveDivider": true,
    "showAction": accessibility?.edit
  }];

  /**
   * Function to fetch invoice details of specific invoice id
   */
  const fetchLineItemDetails = (paginationQuery = "", sortQuery="&order=lineItemDate asc") => {
    setLineItemsLoading(true);
    getService(INVOICE_SERVICE, `/search/line-items/v1/?filter=(${defaultFilterCondition})& ${paginationQuery} ${sortQuery}`)
      .then((res) => {
        const lineItems = res?.data;
        if (lineItemType === "Fees") {
          if (isAdjusted) {
            dispatch(setAdjustedFeeLineItems(lineItems))
          } else {
            dispatch(setNonAdjustedFeeLineItems(lineItems))
          }
        } else {
          if (isAdjusted) {
            dispatch(setAdjustedExpenseLineItems(lineItems))
          } else {
            dispatch(setNonAdjustedExpenseLineItems(lineItems))
          }
        }
        setLineItemsLoading(false);
      })
      .catch((error) => {
        setLineItemsLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

  useEffect(() => {
    let offsetQuery = "";
    if (ENABLE_SERVER_SIDE_FEATURES_LINE_ITEMS) {
      let nonAdjustedCountCheck;
      let adjustedCountCheck;
      if(nonAdjustedLineItemsPaginationDetail?.tabName !== '' && nonAdjustedLineItemsPaginationDetail?.tabName === 'Fees'){
        if(nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount !== undefined || nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount !== 0){
          nonAdjustedCountCheck = (nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount <= nonAdjustedLineItemsPaginationDetail?.rowsPerPage) ? 0 : (nonAdjustedLineItemsPaginationDetail?.rowsPerPage * nonAdjustedLineItemsPaginationDetail?.currentPage);
        }        
      }else if(nonAdjustedLineItemsPaginationDetail?.tabName !== '' && nonAdjustedLineItemsPaginationDetail?.tabName === 'Expenses'){
        if(nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount !== undefined || nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount !== 0){
          nonAdjustedCountCheck = (nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount <= nonAdjustedLineItemsPaginationDetail?.rowsPerPage) ? 0 : (nonAdjustedLineItemsPaginationDetail?.rowsPerPage * nonAdjustedLineItemsPaginationDetail?.currentPage);
        }
      }
      if(adjustedLineItemsPaginationDetail?.tabName !== '' && adjustedLineItemsPaginationDetail?.tabName === 'Fees'){
        if(adjustedLineItemsPaginationDetail?.adjustedFeeCount !== undefined || adjustedLineItemsPaginationDetail?.adjustedFeeCount !== 0){
          adjustedCountCheck = (adjustedLineItemsPaginationDetail?.adjustedFeeCount <= adjustedLineItemsPaginationDetail?.rowsPerPage) ? 0 : (adjustedLineItemsPaginationDetail?.rowsPerPage * adjustedLineItemsPaginationDetail?.currentPage);
        }        
      }else if(adjustedLineItemsPaginationDetail?.tabName !== '' && adjustedLineItemsPaginationDetail?.tabName === 'Expenses'){
        if(adjustedLineItemsPaginationDetail?.adjustedExpenseCount !== undefined || adjustedLineItemsPaginationDetail?.adjustedExpenseCount !== 0){
          adjustedCountCheck = (adjustedLineItemsPaginationDetail?.adjustedExpenseCount <= adjustedLineItemsPaginationDetail?.rowsPerPage) ? 0 : (adjustedLineItemsPaginationDetail?.rowsPerPage * adjustedLineItemsPaginationDetail?.currentPage);
        }
      }
      
      const tabCurrPage = (isCurrent !== nonAdjustedLineItemsPaginationDetail?.tabName) ? 0: nonAdjustedCountCheck;
      const tabAdustedCurrPage = (isCurrent !== adjustedLineItemsPaginationDetail?.tabName) ? 0: adjustedCountCheck;
      const rows = isAdjusted ? rowsPerPageLineItemsAdjusted : rowsPerPageLineItemsNonAdjusted;
      const currPage = isAdjusted ? tabAdustedCurrPage : tabCurrPage;
      offsetQuery = `&limit=${rows}&offset=${currPage}`;
    }
    //Reset line items data
    if (lineItemType === "Fees") {
      if (isAdjusted) {
        dispatch(setAdjustedFeeLineItems({}))
      } else {
        dispatch(setNonAdjustedFeeLineItems({}))
      }
    } else {
      if (isAdjusted) {
        dispatch(setAdjustedExpenseLineItems({}))
      } else {
        dispatch(setNonAdjustedExpenseLineItems({}))
      }
    }
    if (!parentLoading) {
      fetchLineItemDetails(offsetQuery);
    }
  }, [isAdjusted, lineItemType, parentLoading])

  useEffect(()=>{
    if(nonAdjustedFeeLineItems?.count !== 0){
      dispatch(setNonAdjustedPaginationForLineItems({
        currentPage: nonAdjustedLineItemsPaginationDetail?.currentPage,
        rowsPerPage: nonAdjustedLineItemsPaginationDetail?.rowsPerPage,
        tabName: nonAdjustedLineItemsPaginationDetail?.tabName,
        nonAdjustedFeeCount: nonAdjustedFeeLineItems?.count,
        nonAdjustedExpenseCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount
      }))
    }
    if(nonAdjustedExpenseLineItems?.count !== 0){
      dispatch(setNonAdjustedPaginationForLineItems({
        currentPage: nonAdjustedLineItemsPaginationDetail?.currentPage,
        rowsPerPage: nonAdjustedLineItemsPaginationDetail?.rowsPerPage,
        tabName: nonAdjustedLineItemsPaginationDetail?.tabName,
        nonAdjustedFeeCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount,
        nonAdjustedExpenseCount: nonAdjustedExpenseLineItems?.count
      }))
    }
    if(adjustedFeeLineItems?.count !== 0){
      dispatch(setAdjustedPaginationForLineItems({
        currentPage: adjustedLineItemsPaginationDetail?.currentPage,
        rowsPerPage: adjustedLineItemsPaginationDetail?.rowsPerPage,
        tabName: adjustedLineItemsPaginationDetail?.tabName,
        adjustedFeeCount: adjustedFeeLineItems?.count,
        adjustedExpenseCount: adjustedLineItemsPaginationDetail?.adjustedExpenseCount
      }))
    }
    if(adjustedExpenseLineItems?.count !== 0){
      dispatch(setAdjustedPaginationForLineItems({
        currentPage: adjustedLineItemsPaginationDetail?.currentPage,
        rowsPerPage: adjustedLineItemsPaginationDetail?.rowsPerPage,
        tabName: adjustedLineItemsPaginationDetail?.tabName,
        adjustedFeeCount: adjustedLineItemsPaginationDetail?.adjustedFeeCount,
        adjustedExpenseCount: adjustedExpenseLineItems?.count
      }))
    }
  },[nonAdjustedFeeLineItems?.count, nonAdjustedExpenseLineItems?.count, adjustedFeeLineItems?.count, adjustedExpenseLineItems?.count])


  useEffect(() => {
    if(isAdjusted) {
      setSortQueryAdjusted(defaultSortQuery)
      dispatch(setAdjustedPaginationForLineItems({
        currentPage: adjustedLineItemsPaginationDetail?.currentPage,
        rowsPerPage: adjustedLineItemsPaginationDetail?.rowsPerPage,
        tabName: isCurrent,
        adjustedFeeCount: adjustedFeeLineItems?.count,
        adjustedExpenseCount: adjustedExpenseLineItems?.count
      }))
    } else {
      setSortQueryNonAdjusted(defaultSortQuery)
      dispatch(setNonAdjustedPaginationForLineItems({
        currentPage:  nonAdjustedLineItemsPaginationDetail?.currentPage,
        rowsPerPage:  nonAdjustedLineItemsPaginationDetail?.rowsPerPage,
        tabName: isCurrent,
        nonAdjustedFeeCount: nonAdjustedFeeLineItems?.count,
        nonAdjustedExpenseCount: nonAdjustedExpenseLineItems?.count
      }))
    }
  },[lineItemType])

  return (
      <MuiDataTable
        data={lineItemsData?.lineitems}
        columns={invoiceLineItemsTableColumns}
        customOptions={isAdjusted ? lineItemsTableCustomOptionsAdjusted : lineItemsTableCustomOptionsNonAdjusted}
        setTableColumns={setInvoiceLineItemsTableColumns}
        disableSearch={true}
        disableFilter={true}
        disablePagination={false}
        disableExport={true}
        customClassName={`lineItemsTable customMuiClass`}
        loading={lineItemsLoading || parentLoading}
        onRowClick={handleRowClick}
      />
  )
};

export default React.memo(LineItemDatatable);