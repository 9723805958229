export const BUDGET_CREATE = "BUDGET_CREATE";
export const BUDGET_LIST = "BUDGET_LIST";
export const BUDGET_READ = "BUDGET_READ";
export const BUDGET_UPDATE = "BUDGET_UPDATE";
export const DOCUMENT_MGMT = "DOCUMENT_MGMT";

export const INVOICE_LIST = "INVOICE_LIST";
export const INVOICE_READ = "INVOICE_READ";
export const INVOICE_UPDATE_NET_AMOUNT = "INVOICE_UPDATE_NET_AMOUNT";
export const INVOICE_EDIT_LINE_ITEM_DISCOUNT = "INVOICE_EDIT_LINE_ITEM_DISCOUNT";

export const MATTER_LIST = "MATTER_LIST";
export const MATTER_VIEW_ALL = "MATTER_VIEW_ALL";
export const MATTER_ORG_EDIT = "MATTER_ORG_EDIT";
export const MATTER_ORG_READ = "MATTER_ORG_READ";
export const MATTER_PPL_READ = "MATTER_PPL_READ";
export const MATTER_READ = "MATTER_READ";
export const MATTER_UPDATE = "MATTER_UPDATE";

export const NOTES_CREATE = "NOTES_CREATE";
export const NOTES_LIST = "NOTES_LIST";
export const NOTES_PUBLIC = "NOTES_PUBLIC";
export const NOTES_READ = "NOTES_READ";

export const ORGANIZATION_READ = "ORGANIZATION_READ";
export const ORGANIZATION_UPDATE = "ORGANIZATION_UPDATE";
export const INVOICE_CREATE = "INVOICE_CREATE";

// to be added from BE
export const ORG_PPL_READ = "ORG_PPL_READ"; // TBD
export const EDIT_TK_ASSIGN = "EDIT_TK_ASSIGN"; // TBD
export const CREATE_TK_MATTER_RATE_REQ = "CREATE_TK_MATTER_RATE_REQ"; // TBD
export const CREATE_TK_PRACTICE_RATE_REQ = "CREATE_TK_PRACTICE_RATE_REQ"; // TBD
export const MATTER_PPL_ASSIGN = "MATTER_ORG_ASSIGN"; // TBD
export const MATTER_PPL_UNASSIGN = "MATTER_ORG_UNASSIGN" // TBD
export const PEOPLE_LIST = "PEOPLE_LIST"; // TBD
export const CREATE_TK_REQ = "CREATE_TK_REQ"; // TBD
export const DE_ACTIVATE_TK = "DE_ACTIVATE_TK"; // TBD
