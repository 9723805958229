import { createUseStyles } from "react-jss";

const HelpCenterStyles = createUseStyles({
  pageContainer: {
    backgroundColor: '#F5F5F5',
    padding: '35px 40px',
    height: '100%'
  },
  bannerContainer: {
    width: "100%"
  },
  banner: {
    width: "100%",
    height: "auto",
  },
  tabContainer: {
    marginTop: "20px",
    "& nav > ul > li > a": {
      background: "none",
    }
  },
  chipContainer: {
    paddingTop: 16,
    paddingBottom: 16
  },
  chip: {
    marginRight: 8
  },
  dataContainer: {
    height: "100%",
    width: "100%",
  }
})

export default HelpCenterStyles;

