import * as React from 'react';
import { More } from "../../../constants/images";
import { createUseStyles } from "react-jss";
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import { Button, Skeleton } from "@walmart-web/livingdesign-components";
import { renderTernary } from '../../../helpers/utils';

const useStyles = createUseStyles({
  list: {
    minWidth: 100,
    listStyleType: "none",
    "& li": {
      fontSize: 12,
      padding: "8px 12px",
      margin: 0,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#F1F1F2",
      },
    },
    "& li:first-child": {
      borderRadius: "4px 4px 0 0",
    },
    "& li:last-child": {
      borderRadius: "0 0 4px 4px",
    },
    padding: "0px"
  },
  divider: {
    padding: "0px 12px"
  },
  moreIcon: {
    cursor: 'pointer'
  },
  disabledMoreIcon: {
    opacity: 0.2,
    cursor: 'not-allowed',
  },
  popoverAction: {
    cursor: 'pointer',
    paddingTop: '5px',
    paddingLeft: '15px',
  },
  enabledItem: {
    cursor: "pointer"
  },
  disabledItem: {
    pointerEvents:'none',
    cursor: "auto",
    color:'#949494'
  }
});

const ActionPopover = (props) => {
  const classes = useStyles();
  const aboveDivider = [];
  const belowDivider = [];

  const { actionItems, onClickOption, tableMeta, buttonText, variant='primary', buttonIcon, disableStatus, textContent, textIcon, textLoading } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
    setAnchorEl(null);
  };

  const handleItemClick = (event, value) => {
    event.stopPropagation();
    setOpen(false);
    onClickOption(value, tableMeta)
  }

  const createItem = (value, index) => (
    <li
      data-testid={`action-option-${value?.actionId}`}
      id={`action-option-${value?.actionId}`}
      key={value?.key}
      onClick={(e) => handleItemClick(e, e.target.value)}
      value={value?.actionId}
    >
      {value?.label}
    </li>
  );

  actionItems.length && actionItems.forEach((value, index) => {
    if(value?.showAction === undefined || value?.showAction === true) {
      const item = createItem(value, index);
      value?.isAboveDivider === true
        ? aboveDivider.push(item)
        : belowDivider.push(item);
    }
  })

  const displayItems = [
    <></>,
    ...aboveDivider,
    <div className={classes.divider} key={`divider`}>
      {belowDivider.length ? <Divider /> : null}
    </div>,
    ...belowDivider,
  ];

  const renderButtons = (buttonText) => {
    if(buttonText) {
      return  renderTernary([...aboveDivider,...belowDivider]?.length !== 0,
        <Button
        trailing={buttonIcon}
        size="small"
        variant={variant}
        data-testid={"add-new-button-datatable"}
        id={"add-new-button-datatable"}
        onClick={handleClick}
        disabled={Boolean(disableStatus)}
        key={buttonText}
      >
        {buttonText}
      </Button>,
       <React.Fragment></React.Fragment> )
    } else {
      return renderTernary([...aboveDivider,...belowDivider]?.length !== 0,
        <More onClick={handleClick} data-testid="action-btn" id={`action-btn-${tableMeta?.rowIndex}`} className={classes.moreIcon} />,
        <More data-testid="disabled-action-btn" className={classes.disabledMoreIcon} /> )
    }
  }

  return (
    <>
      {
        textContent?
          <span>
            {
              textLoading?
              <Skeleton width="100px" height="16px" />:
              <span>
                {textContent}
                {textIcon?
                  <span className={classes.popoverAction} onClick={handleClick}>
                    {textIcon}
                  </span>:
                  <></>
                }
              </span>
            }

          </span>:
          renderButtons(buttonText)
      }
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        data-testid="action-close-btn"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ul className={classes.list}>
          {displayItems}
        </ul>
      </Popover>
    </>
  );
}

export default ActionPopover;
