import React from "react";
import { Body } from "@walmart-web/livingdesign-components";
import PropTypes from "prop-types";

const Label = (props) => {
  const { text, addOptionalLabel, mandatoryField, textWithColon=true } = props;

  return (
        <Body as="p" size="small">
      <strong>{`${text}${mandatoryField ? '*' : ""}${textWithColon ? ":": ""} `}</strong>
           {addOptionalLabel && "(optional)"}
        </Body>

  );
};

export default Label;

Label.propTypes = {
  text: PropTypes.string,
  mandatoryField: PropTypes.bool,
  addOptionalLabel: PropTypes.string,
}

Label.defaultProps = {
  text: "",
  mandatoryField: false,
  addOptionalLabel: ""
}
