import { createUseStyles } from "react-jss";

const OrganizationDetailMattersStyles = createUseStyles({
    dataTableWrap: {
        "& div>table>tbody>tr>td": {
          fontFamily: "Bogle",
          height: "24px",
          fontWeight: "normal",
          fontStrech: "normal",
          lineHeight: "1.33",
        },
      },
      customButton:{
        paddingLeft:"15px"
      },
      exportName: {
        marginBottom: 20
      },
      exportNameAs: {
        paddingTop: 20,
        marginBottom: 20
      },
      modalSubText: {
        fontSize: 14,
        fontWeight: 'normal'
      },
});

export default OrganizationDetailMattersStyles;
