import types from "../types/organizationActionTypes";

export const initialState = {
  selectedTimeKeeperData: [],
  selectedMattersData: [],
  removeSelectedTimeKeeperData: [],
  removeSelectedMattersData: [],
  matterListingData: {
    count: 0,
    matters: []
  },
  organizationDetailValues: {
    identifier: "",
    orgType: "",
    orgSubType: [],
    addressType: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "United States",
    email: "",
    website: "",
    phoneNo: "",
    faxNo: "",
    comments: "",
    companyName:"",
    wIREFirm: "",
    fCPAApproved: "",
    mELApproved: "",
    hostId: "",
    sAPId: "",
    cZId: "",
    cZSuffix: "",
    expertise: "",
    primaryPracticeArea: "",
    permittedAdverseRepresentation: "",
    competitorNotice: "",
    taxId: "",
    billingContactName: "",
    relationshipPartnerName: "",
    relationshipPartnerEmail: "",
    conflictWaiver: "",
    createdDate : "",
    totalBudget:"",
    totalSpent:"",
    versionKey:"1.0",
    zipCode : ""
  },

};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELECTED_TIMEKEEPERS_DATA:
      return {
        ...state,
        selectedTimeKeeperData: action.payload,
      };

    case types.SET_SELECTED_MATTERS_DATA:
      return {
        ...state,
        selectedMattersData: action.payload,
      };

    case types.SET_REMOVE_SELECTED_TIMEKEEPERS_DATA:
      return {
        ...state,
        removeSelectedTimeKeeperData: action.payload,
      };

    case types.SET_REMOVE_SELECTED_MATTERS_DATA:
      return {
        ...state,
        removeSelectedMattersData: action.payload,
      };

    case types.SET_MATTER_LISTING_DATA:
      return {
        ...state,
        matterListingData: action.payload
      }

    case types.SET_ORG_DETAIL:
      return {
        ...state,
        organizationDetailValues: action.payload,
      };

    case types.SET_INPUT_DATA:
      const { name, value } = action.payload;
      return {
        ...state,
        organizationDetailValues: {
          ...state.organizationDetailValues,
          [name]: value,
        },
      };

    default:
      return state;
  }
};

export default organizationReducer;
