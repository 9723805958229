import React from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";
import { StyledText } from "@walmart-web/livingdesign-components";
import { createUseStyles } from 'react-jss';
import { isToday, isTomorrow } from "../../../../helpers/dateUtils";
import { dateLabels } from "../../../../constants/constants";

const useStyles = createUseStyles((theme) => ({
  redText: {
    color: theme?.palette?.error?.light,
  },
}));

/**
 * Component to render formatted date in datatable
 *
 * @visibleName MuiDateItem
 */
const MuiDateItem = ({ value, dateFormat }) => {
  const format = dateFormat ? dateFormat : "MMM D, YYYY";
  const classes = useStyles();
  let displayValue = value;
  let convertedText = false;
  let textColorClass = "";
  if (isToday(value)) {
    displayValue = dateLabels.today;
    textColorClass = "redText";
    convertedText = true;
  } else if (isTomorrow(value)) {
    displayValue = dateLabels.tomorrow;
    textColorClass = "redText";
    convertedText = true;
  }

  const displayValueConversion = (value) => {
    if(convertedText) {
      return value;
    } else {
      return  <Moment format={format}>{displayValue}</Moment>
    }
  }

  return (
    <StyledText className={classes[textColorClass]} data-testid="Mui-Date-Item">
      {displayValue ? displayValueConversion(displayValue) : (
        "-"
      )}
    </StyledText>
  );
};

export default MuiDateItem;

MuiDateItem.propTypes = {
  /** input date string to format */
  value: PropTypes.string,
};
