import types from '../types/preferencesTypes';

export const initialState = {
  userPreferences: {},
  starredItemsIds: [],
};

const preferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_PREFERENCES:
      return {
        ...state,
        userPreferences: action.payload,
      };
    case types.RESET_USER_PREFERENCES:
      return {
        ...state,
        userPreferences: {}
      };
    case types.SET_STARRED_ITEMS_IDS:
      return {
        ...state,
        starredItemsIds: action.payload,
      };
    default:
      return state;
  }
};

export default preferencesReducer;
