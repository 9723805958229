import { Divider, Grid, GridColumn, IconButton, Spinner, useSnackbar } from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { COPY_SHARABLE_LINK, OPEN_IN_NEW_TAB, fileMimeType } from "../../constants/constants";
import { Tooltip } from "@mui/material";
import { CloudDownload, ExternalLink } from "@walmart-web/livingdesign-icons";
import { CopyLink } from "../../constants/images";
import { DOWNLOAD } from "../../constants/actionButtons";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { postService, postServiceWithHeaders } from "../../axios/axios";
import { NODE_JS_URL } from "../../constants/baseURLs";
import { getErrorMessage } from "../../helpers/utils";
import { useSelector } from "react-redux";
import { LINK_COPY_MESSAGE } from "../../constants/messages";
import HelpCenterDocumentPreviewStyles from "./HelpCenterDocumentPreview.styles";
import PropTypes from 'prop-types';

/**
 * Component to view pdf/png/jpeg files
 * @param {*} props
 * @returns
 */
const HelpCenterDocumentPreview = (props) => {
   const {currentCard,loading,onClickDownLoad,spinnerLoading} = props
   const classes = HelpCenterDocumentPreviewStyles();
   const {trainingGuidesData} = useSelector((state) => state?.helpCenter)
   const [docs, setDocs] = useState([]);
   const [url,setUrl] = useState();
   const [downloadLoading, setDownloadLoading] = useState(false);
   const {addSnack} = useSnackbar();

    /*
    *  This function calls API to share file link
    */
    const shareDocument = (url=false) => {
        postService(NODE_JS_URL, `/share?fileId=${trainingGuidesData?.contents?.[currentCard]?.location}&version=1`, {isContent: true})
        .then((res) => {
            if(url)
            {
            setUrl(res?.data?.url)
            }
            else {
            navigator.clipboard.writeText(res?.data?.url).then(() => {
                addSnack({
                message: LINK_COPY_MESSAGE
                });
            });
            }
        }
        )
        .catch((error) => {
            if(!url)
            {
            addSnack({
            message: getErrorMessage(error)
            })
            }
        })
    }


    // function to download file data
    const downloadFile = (id,fileType) => {
        setDownloadLoading(true);
        postServiceWithHeaders(NODE_JS_URL,'/download',{id: id, isContent: true}, {},"blob")
        .then((res)=>{
        const file = new Blob(
            [res?.data],
            {type: fileMimeType[fileType]});
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
            const base64data = reader.result;
            setDocs([{uri: base64data}]);
            setDownloadLoading(false);
        }
        shareDocument(true)
        }
        )
        .catch((error)=>{
        setDownloadLoading(false);
        setDocs([]);
            addSnack({
            message: getErrorMessage(error)
            });
        })
    }

    useEffect(() => {
        downloadFile(trainingGuidesData?.contents?.[currentCard]?.location, trainingGuidesData?.contents?.[currentCard]?.mediaType);
      }, [currentCard]);

    return (
      (downloadLoading || loading) ?
      <Spinner size="large" className={classes.spinner}/>
      :
      <Grid className={classes.docContent}>
        <GridColumn sm={10} className={classes.header}>
          <div className={classes.fileName}>{trainingGuidesData?.contents?.[currentCard]?.title}</div>
          <div className={classes.icons}>
          <Tooltip title={OPEN_IN_NEW_TAB}>
            <IconButton a11yLabel="ExternalLink" size="medium" id="external-link">
              <a target="_blank" href={url} className={classes.externalLink}>
              <ExternalLink size = "medium"/>
              </a>
            </IconButton>
          </Tooltip>
          {spinnerLoading ? <Spinner color="black" size="small" className={classes.downloadSpinner}/> :
          <Tooltip title={DOWNLOAD}>
            <IconButton a11yLabel="Download" size="medium" id="doc-download">
              <CloudDownload size="medium" data-testid="doc-download"
              onClick={() => {onClickDownLoad()}} />
            </IconButton>
          </Tooltip>
           }
          <Tooltip title={COPY_SHARABLE_LINK}>
            <IconButton a11yLabel="Copy Link" size="medium" onClick={() => {shareDocument()}}>
              <CopyLink size="medium" data-testid="doc-copy"/>
            </IconButton>
          </Tooltip>
        </div>
        </GridColumn>
          <Divider className={classes.divider}></Divider>
          <DocViewer
          className={classes.docViewer}
          pluginRenderers={DocViewerRenderers}
          documents={docs}
          config={{
            header: {
              disableHeader: false,
              disableFileName: true,
              retainURLParams: false
            }
          }}
        />
      </Grid>
    )

  }

  export default HelpCenterDocumentPreview;

  HelpCenterDocumentPreview.propTypes = {
    currentCard: PropTypes.number,
    loading: PropTypes.bool,
    onClickDownLoad: PropTypes.func,
    spinnerLoading: PropTypes.bool,
 }