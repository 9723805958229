import { createUseStyles } from "react-jss";
import { SPACING } from "../../../../constants/styleGuide";

const BORDER = "solid 1px #e3e4e5";
export const CreateEditBudgetStyles = createUseStyles({
	leftContent: {
        paddingRight: SPACING.s32,
      },
      rightContent: {
        padding: SPACING.s16,
        backgroundColor: "#fff",
        marginBottom: "16px",
      },
      contentSpacing8: {
        paddingTop: SPACING.s8,
      },
      contentSpacing16: {
        paddingTop: SPACING.s16,
        width: "100%",
      },
      contentSpacing24: {
        paddingTop: SPACING.s24,
        width: "100%",
      },
      contentSpacing48: {
        paddingTop: SPACING.s48,
      },
      columnLeftPadding: {
        paddingLeft: SPACING.s10,
      },
      columnRightPadding: {
        paddingRight: SPACING.s10,
      },
      columnSpacing: {
        padding: SPACING.s16,
        backgroundColor: "#fff",
        marginBottom: "16px",
      },
      subTitle: {
        fontSize: "20px",
        lineHeight: "1.4",
        marginTop: "0",
        marginBottom: "16px",
      },
      flexGrid: {
        display: "flex",
        flexDirection: "column",
      },
      container: {
        background: "#f1f1f2",
        width: "100%",
      },
      subType: {
        fontSize: "14px",
        lineHeight: "1.43",
        marginBottom: "10px",
      },
      budgetTypeTotal: {
        marginTop: "18px",
      },
      overviewTableHeader: {
        display: "contents",
      },
      overviewTableHeaderRow: {
        padding: "16px",
        fontSize: "12px",
        lineHeight: "1.33",
        color: "#2e2f32",
        fontWeight: "bold",
        border: BORDER,
      },
      overviewHeaderRowRight: {
        textAlign: "right",
        padding: "16px",
        fontSize: "12px",
        lineHeight: "1.33",
        color: "#2e2f32",
        fontWeight: "bold",
        border: BORDER,
      },
      overviewTableBody: {
        display: "contents",
      },
      overviewTableBodyRow: {
        padding: "16px",
        fontSize: "12px",
        lineHeight: "1.33",
        color: "#2e2f32",
        border: BORDER,
      },
      overviewTableBodyRowEdit: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "8px",
        fontSize: "12px",
        lineHeight: "1.33",
        color: "#2e2f32",
        border: BORDER,
      },
      overviewTableBodyRowRemove: {
        border: BORDER,
        display : "flex",
        alignItems: "center",
        justifyContent : "center"
      },
      overviewBodyRowRight: {
        textAlign: "right",
        padding: "16px",
        fontSize: "12px",
        lineHeight: "1.33",
        color: "#2e2f32",
        border: BORDER,
      },
      overviewBodyRowRightEdit: {
        textAlign: "right",
        paddingBottom: "8px",
        paddingLeft: "16px",
        paddingRight: "16px",
        fontSize: "12px",
        lineHeight: "1.33",
        color: "#2e2f32",
        border: BORDER,
      },
      paddingRight8: {
        paddingRight: "8px",
      },
      paddingLeft8: {
        paddingLeft: "8px",
      },
      unsaveSection: {
        padding: "26px 48px 26px 24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#002d58",
        position: 'sticky',
        top: 0,
        zIndex: 9999
      },
      unsaveText: {
        color: "#ffffff",
        margin: "0",
        fontSize: "20px",
        lineHeight: "1.4",
      },
      discardButtons: {
        marginRight: "16px",
      },
      addPhase: {
        padding: SPACING.s16,
        backgroundColor: "#f8f8f8",
      },
      addNewText: {
        color: "#000000",
        fontSize: "12px",
        lineHeight: 1.33,
        paddingLeft: "10px",
      },
      cursorPointer: {
        cursor: "pointer",
      },
      timePeriodSpacingTop: {
        marginTop: "18px",
      },
      textAlignCenter: {
        textAlign: "center"
      },
      loaderPosition:{
        top: '10px',
        position: 'relative'
      },
      divBorder: {
        border: "2px",
        borderRadius: "8px"
      },
      budgetTypePhase: {
        paddingTop: SPACING.s16,
      },
      textFieldTop: {
        "& div":{
          marginTop: "9px",
        }
      },
      dueDateError:{
        paddingLeft:"14px",
        paddingTop:"3px"
      }
});
