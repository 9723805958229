import types from '../types/documentActionTypes';

export const setDocumentUploadData = (payload) => {
  return { type: types.SET_DOCUMENT_UPLOAD, payload }
}

export const updateDocumentUploadData = (payload) => {
  return { type: types.UPDATE_DOCUMENT_UPLOAD, payload }
}

export const resetDocumentUploadData = () => {
  return { type: types.RESET_DOCUMENT_UPLOAD }
}

export const deleteDocumentUploadData = (payload) => {
  return { type: types.DELETE_DOCUMENT_UPLOAD, payload }
}

export const updateBreadCrumbFolderData = (payload) => {
  return { type: types.UPDATE_BREADCRUMB_FOLDER_DATA, payload }
}

export const setDocumentsMattersData = (payload) => {
  return { type: types.SET_DOCUMENTS_MATTERS_DATA, payload }
}

export const setDocumentsMattersDetails = (payload) => {
  return { type: types.SET_DOCUMENTS_MATTERS_DETAIL, payload }
}

export const setDocumentsPath = (payload) => {
  return { type: types.SET_DOCUMENTS_PATH, payload }
}

export const setDocumentsMetadata = (payload) => {
  return { type: types.SET_DOCUMENTS_METADATA, payload }
}

export const setDocumentBreadcrumbIndex= (payload) => {
  return { type: types.SET_BREADCRUMB_INDEX, payload }
}

export const setDocumentTabIndex= (payload) => {
  return { type: types.SET_TAB_INDEX, payload }
}

export const setFolderSearchQuery = (payload) => {
  return { type: types.SET_FOLDER_SEARCH_QUERY, payload }
}

export const setAdvanceSearchQuery = (payload) => {
  return { type: types.SET_ADVANCE_SEARCH_QUERY, payload }
}

export const setDocumentsData = (payload) => {
  return { type: types.SET_DOCUMENT_DATA, payload }
}


