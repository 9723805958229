import React, { useState } from "react";
import {
	TabNavigation,
	TabNavigationItem
} from "@walmart-web/livingdesign-components";
import { createUseStyles } from "react-jss";

import { useLocation } from "react-router-dom";
import MatterInvoiceList from "./MatterInvoiceList";
import MatterBudgetList from "./MatterBudgetList";

const useStyles = createUseStyles({
	detailWrapper: {
		padding: "15px",
	},
	detailItem: {
		display: "inline-block",
		paddingRight: "62px",
	},
	smallText: {
		display: "block",
		paddingBottom: 4,
		fontSize: "14px",
		textAlign: "center",
	},
	detail: {
		textAlign: "center",
		"& h5": {
			fontSize: "18px",
		},
	},
	statusButton: {
		border: "1px solid #1d5f02",
		color: "#1d5f02",
		background: "#fff",
		fontSize: "11px",
		padding: "4px 10px 3px",
		borderRadius: "2px",
	},
	lightGreen: {
		color: "#2a8703",
	},
	financialTabNavigation: {
		backgroundColor: "#fff",
	},
	star: {
		marginRight: "24px",
		height: "24px",
		width: "24px"
	},
	starFilled: {
		marginRight: "14px",
		height: "44px",
		width: "44px"
	},
	icon: {
		cursor: "pointer",
	},
	financialActionButtons: {
		justifyContent: "flex-end",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		height: "100%",
	},
});

/**
 * Matter Financial component - It will use for displaying the overview of financial
 * alongside with Budgets, Invoices
 *
 */
const MatterFinancials = (props) => {
	const classes = useStyles();
	const { state: { currentFinancialTab: currentTab } = {} } = useLocation();
	const { currentMatterTab } = props;
	const [isCurrent, setIsCurrent] = useState(currentTab ? currentTab : 0);
	const tabContent = () => {
		switch (isCurrent) {
			case 0:
				return <div data-testid="budget-wrapper">
					<MatterBudgetList
            currentFinancialTab={isCurrent}
            currentMatterTab={currentMatterTab} />
				</div>;
			case 1:
				return <MatterInvoiceList currentFinancialTab={isCurrent} currentMatterTab={currentMatterTab} />;
			default:
				return null;
		}
	};

	const items = [
		"Budgets",
		"Invoices",
	];

	return (
		<div>
			<div className={classes.financialTabNavigation}>
				<TabNavigation>
					{items?.map((item, index) => (
						<TabNavigationItem
							id={`matter-financials-tab-${index}`}
							data-testid={`matter-financials-tab-${index}`}
							onClick={() => setIsCurrent(index)}
							isCurrent={isCurrent === index}
							key={`${item}-index`}
						>
							{item}
						</TabNavigationItem>
					))}
				</TabNavigation>
			</div>
			<div data-testid="matter-financials-content">
				{tabContent()}
			</div>
		</div>
	);
};

export default MatterFinancials;
