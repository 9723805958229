import { createUseStyles } from "react-jss";
import { SPACING } from "../../../../constants/styleGuide";
import { LIGHT_DARK_BLACK } from "../../../../constants/colors";

// Upload Invoice related styles
const UploadInvoiceStyles = createUseStyles({
  addDocWrap : {
    flex: 1,
    paddingLeft: SPACING.s32,
    paddingRight: SPACING.s32,
    paddingTop: SPACING.s24,
  },
  dropzoneWrap: {
    background: "#fff",
    padding: "20px 20px 40px",
    marginBottom: '40px',
    marginTop: "20px",
    "& .MuiTypography-subtitle1": {
      color: LIGHT_DARK_BLACK,
      margin: "15px 0 50px",
      display: "block",
      fontWeight: "bold",
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      '& .MuiTypography-body1': {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "none",
      },
      '&:hover span:last-child': {
        display: "block",
      }
    },
    "& .MuiDropzonePreviewList-removeButton": {
      boxShadow: "none",
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' fill='currentColor' data-testid='banner-close' aria-hidden='true' role='presentation' style='font-size: 1rem; vertical-align: -0.175em;'%3E%3Cpath d='M3.05 3.05a.5.5 0 0 1 .707 0L8 7.293l4.243-4.243a.5.5 0 0 1 .707.707L8.707 8l4.243 4.243a.5.5 0 0 1-.707.707L8 8.707 3.757 12.95a.5.5 0 0 1-.707-.707L7.293 8 3.05 3.757a.5.5 0 0 1 0-.707z' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "12px 12px",
      backgroundPosition: "center",
      top: "8px",
      right: "8px",
      "& .MuiSvgIcon-root": {
        display: "none"
      },
    }
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  uploadButtonWrap: {
    borderTop: "1px solid #e3e4e5",
    marginTop: SPACING.s32,
  },
  goBackButton: {
    marginRight: SPACING.s16,
  },
  uploadInvoicesTitle: {
    marginBottom: SPACING.s16,
  },
  hideModalTitle: {
    '& > div > div > div > div:first-child': {
      display: "none",
    },
  },
  uploadInprogressModalContent: {
    padding: "1.7rem 0 0",
  },
  uploadPartialListItem: {
    marginBottom: SPACING.s10,
    '&:last-child': {
      marginBottom: "0",
    },
  },
  uploadPartialDivider: {
    margin: "10px 0",
  },
  uploadConfirmationText: {
    margin: "1.5rem 0 0",
  },
  failedFilesList: {
    maxHeight: "160px",
    overflowY: "auto",
  }
});

export default UploadInvoiceStyles;
