import {
  Body,
  Button,
  ButtonGroup,
  Caption, Grid, GridColumn, Heading, Modal, Spinner, useSnackbar
} from "@walmart-web/livingdesign-components";
import moment from "moment";
import { Close } from "@walmart-web/livingdesign-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postService } from "../../../../axios/axios";
import MuiDataTable from "../../../../components/Table/MuiDataTable/MuiDataTable";
import MuiTagItem from "../../../../components/Table/MuiDataTable/MuiTagItem/MuiTagItem";
import { COMPOSITE_SEARCH_SERVICE, MATTER_SERVICE } from "../../../../constants/baseURLs";
import { matterListStatusTagColors } from "../../../../constants/colors";
import {
  CS_SCOPES,
  CS_SCORE_SORT_OBJ,
  MATTER_DEFAULT_SORT_QUERY,
} from "../../../../constants/constants";
import { ENABLE_SERVER_SIDE_FEATURES_MATTER, ROWS_PER_PAGE_MATTER, ROWS_PER_PAGE_OPTIONS_MATTER } from "../../../../constants/tableConfigs";
import { CANCEL, CONFIRM, GO_BACK, SUBMIT_REQUEST } from "../../../../constants/actionButtons";
import { MOMENT_DATE_FORMAT } from "../../../../constants/dateFormats";
import { PEOPLE_LINK_SUCCESS_MESSAGE, MATTER_MESSAGE } from "../../../../constants/messages";
import { isValueEmptyCheck } from "../../../../helpers/validationUtils";
import { setMatterListingData } from "../../../../store/actions/matterAction";
import { setSelectedMattersData, setSelectedTimekeepersData } from "../../../../store/actions/organizationAction";
import SelectMatterStyles from "./AssignTimekeepersSelectMatters.styles";
import { getErrorMessage, getSensitiveMatterPayload, returnPracticeAreaName } from "../../../../helpers/utils";
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery } from "../../../../helpers/csQueryUtils";

  /**
   * exportModalActions Actions
   */
  const SubmitModalActions = ({onCancelBtnClk, loading, onConfirm, isDisabled}) => {
    return (
      <ButtonGroup>
        <Button
          data-testid="goback-btn"
          id="goback-btn"
          size="small"
          onClick={() => onCancelBtnClk()}
        >
          {CANCEL}
        </Button>
				{ loading ?
					<Button
						variant="primary"
						size="small"
						data-testid="continue-btn"
					>
						<Spinner color="white" size="small" />
					</Button> :
					<Button
						variant="primary"
						size="small"
						data-testid="continue-btn"
						id="continue-btn"
						disabled={isDisabled}
						onClick={() => onConfirm()}
					>
						{CONFIRM}
					</Button>}
      </ButtonGroup>
    );
  };
  /**
  * A Pop Modal will be opened for submitting request
  */
  const SubmitRequestModal = (props) => {
    const classes = SelectMatterStyles();
    const {modalOpen, onCancelBtnClk, selectedMattersData, selectedTimeKeeperData, isDisabled, loading, onConfirm, cancelSelectedMatters, cancelSelectedTimekeeper} = props
    return (
      <Modal
        isOpen={modalOpen}
        onClose={() => onCancelBtnClk()}
        actions={<SubmitModalActions onCancelBtnClk={onCancelBtnClk} loading={loading} onConfirm={onConfirm} isDisabled={isDisabled}/>}
        size="large"
        title={
          <React.Fragment>
            <Heading as="h6" size="large" weight={700}>Confirm Timekeeper Assignment</Heading>
            <div className={classes.modalSubText}>Adding user to a matter will allow them to view or edit the matter</div>
            <div className={classes.selectedMatters}>
              <Body as="div" size="large" weight={700} >
                Selected Matters:
              </Body>
              {
                selectedMattersData?.length > 0 ?
                  <div>
                    {selectedMattersData?.map((item, index) =>
                      <Button
                        size="medium"
                        variant="tertiary"
                        trailing={<Close
                          data-testid="cancel-selected-matter"
                          onClick={() => cancelSelectedMatters(item)}
                        />}
                        key={`${item?.lastName}`}
                      >
                        {`${item?.matterName} (${item?.matterNumber})`}
                      </Button>
                    )}
                  </div> : <div className={classes.modalNoSelectionStyle}> No Matters Were Selected</div>
              }
            </div>
            <div className={classes.selectedTimekeepers}>
              <Body as="div" size="large" weight={700} >
                Timekeepers to Assign
              </Body>
              {selectedTimeKeeperData?.length > 0 ?
                <div>
                  {selectedTimeKeeperData?.map((item, index) =>
                    <Button
                      size="medium"
                      variant="tertiary"
                      trailing={<Close
                        data-testid="cancel-selected-timekeeper"
                        onClick={() => cancelSelectedTimekeeper(item)}
                      />}
                      key={`${item?.lastName}`}
                    >
                      {`${item?.lastName} ${item?.firstName}`}
                    </Button>
                  )}
                </div> : <div className={classes.modalNoSelectionStyle}> No Timekeepers Were Selected</div>
              }
            </div>
          </React.Fragment>
        }
      >
      </Modal>
    )
  }
/**
 * Service of Process Select Matters data component
 */
const AssignTimekeepersSelectMatters = () => {
    const classes = SelectMatterStyles();
    const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);

    const displayValue = (value, tableMeta) => {
      return (
        <Caption as="p">{isValueEmptyCheck(value)}</Caption>
      )
    }
    const renderStatus=(value) => {
      return <MuiTagItem
          value={isValueEmptyCheck(value)}
          color={matterListStatusTagColors[value]?.color || "gray"}
          variant={matterListStatusTagColors[value]?.variant || "tertiary"} />;
  }
  const renderCreationDate=(value, tableMeta) => {
    return (
        <Caption as="p">
        {isValueEmptyCheck(value) !== "-" ? moment(value).format(MOMENT_DATE_FORMAT) : "-"}
        </Caption>
    )
}
const renderHeaderMatterNumber=() => {
  return <p id={`headcol-0`}>Matter No.</p>
}
    const COLUMNS = [
        {
            name: "id",
            label: "id",
            colKey: "id",
            options: {
                display: false,
            },
        },
        {
            name: "matterNumber",
            label: "Matter No.",
            colKey: "matterNumber",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
                customFilterListOptions: { render: (v) => `Matter Number: ${v}` },
                customHeadLabelRender: renderHeaderMatterNumber,
            },
        },
        {
            name: "matterName",
            label: "Matter Name",
            colKey: "matterName",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
                customFilterListOptions: { render: (v) => `Matter Name: ${v}` },
            },
        },
        {
            name: "status",
            label: "Status",
            colKey: "status",
            options: {
                sort: true,
                customBodyRender: renderStatus,
              customFilterListOptions: { render: (v) => `Status: ${v}` },
            },
        },
        {
            name: "matterType",
            label: "Matter Type",
            colKey: "matterType",
            options: {
                sort: true,
				        customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
                customFilterListOptions: { render: (v) => `Matter Type: ${v}` },
            },
        },
        {
            name: "creationDate",
            label: "Create Date",
            colKey: "creationDate",
            options: {
                sort: true,
                customBodyRender: renderCreationDate
            },
        },
        {
            name: "leadAttorney",
            label: "Lead Attorney",
            colKey: "leadAttorney",
            options: {
                sort: true,
				        customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
                customFilterListOptions: { render: (v) => `Lead Attorney: ${v}` },
            },
        },
        {
          name: "practiceArea",
          label: "Practice Area",
          colKey: "practiceArea",
          options: {
            display: false,
            customBodyRender: (value) => returnPracticeAreaName(value, practiceAreas),
            customFilterListOptions: { render: (v) => `Practice Area: ${v}` },
          }
        }
    ];

    const [matterColumns, setmatterColumns] = useState(COLUMNS);
    const [rowsPerPageMatter, setRowsPerPageMatter] = useState(ROWS_PER_PAGE_MATTER);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false);
    const [selectRowsData, setSelectRowsData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const { selectedTimeKeeperData, selectedMattersData } = useSelector((state) => state?.organization)
    const matterAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.matters);
    const userDetails = useSelector((state) => state?.user?.userDetail?.attributes);
    const [practiceAreaDropDown, setPracticeAreaDropDown] = useState([]);
    const { matterListingData, practiceAreas } = useSelector((state) => state?.matter);
    const { addSnack } = useSnackbar();
    const matterNumberDropDown = [...new Set(matterListingData?.matters?.map(val => val?.matterNumber))];
  	const matterNameDropDown = [...new Set(matterListingData?.matters?.map(val => val?.matterName))];
    const [searchQuery, setSearchQuery] = useState({});
    const [filterQuery, setFilterQuery] = useState({});
    const [sortQuery, setSortQuery] = useState(MATTER_DEFAULT_SORT_QUERY);

    useEffect(() => {
      if(practiceAreas?.length > 0) {
        const practiceAreasList = [...new Set(practiceAreas?.map(val => val?.name))];
        setPracticeAreaDropDown(practiceAreasList);
      }
    }, [practiceAreas]);

  // Conversion of pratice area code to corresponding names in hidden column
  useEffect(() => {
    if (practiceAreaDropDown?.length > 0) {
      setmatterColumns(COLUMNS);
    }
  }, [practiceAreaDropDown]);

  const FILTER_CONFIGS = [
    {
      colKey: "status",
      type: "checkbox",
      label: "Status",
      defaultOpen: true,
      options: ["Open", "Closed"],
    },
    {
      colKey: "matterNumber",
      type: "dropdownAutocomplete",
      label: "Matter Number",
      defaultOpen: true,
      options: matterNumberDropDown,
    },
    {
      colKey: "matterName",
      type: "dropdownAutocomplete",
      label: "Matter Name",
      defaultOpen: true,
      options: matterNameDropDown,
    },
    {
      colKey: "matterType",
      type: "masterDataMultiAutocomplete",
      dataType: "matterType",
      label: "Select Matter Type",
      defaultOpen: true,
    },
    {
      colKey: "practiceArea",
      type: "autocompleteMultiSelect",
      label: "Practice Area",
      defaultOpen: true,
      options: practiceAreaDropDown,
    },
    {
      colKey: "leadAttorney",
      type: "peopleDropdownAutocomplete",
      label: "Lead Attorney",
      defaultOpen: true,
      options: [],
    },
  ];

  /**
  * Function to call api on pagination action (server side)
  * @param {number} rowsPerPage
  * @param {number} page */
  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPageMatter(rowsPerPage);
    const paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * page));
    getMatterData(paginationQuery);
  };

  /**
  * Function to validate search query and set query or show no results UI (server side)
  * @param {string} searchText
  */
  const handleServerSideSearch = (searchText) => {
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };

  /**
  *  Function to set filter query on filter action (server side)
  * @param {string} query
  */
  const handleServerSideFilter = (filterQuery) => {
    setFilterQuery(filterQuery);
  };

  /**
  * Function to call api on sort action (server side)
  * @param {string} name
  * @param {string} direction
  */
  const handleServerSideSort = ({ name, direction }) => {
    const sortQuery = formCsSortQuery(name,direction);
    setSortQuery(sortQuery);
  };

    const matterTableCustomOptions = {
        responsive: "standard",
        rowsPerPage: rowsPerPageMatter,
        rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_MATTER,
        filterConfigs: FILTER_CONFIGS,
      isMatter: true,
      totalCount: matterListingData?.count,
      serverSide: ENABLE_SERVER_SIDE_FEATURES_MATTER,
      onServerSidePagination: handleServerSidePagination,
      onServerSideSearch: handleServerSideSearch,
      onServerSideFilter: handleServerSideFilter,
      onServerSideSort: handleServerSideSort,
    };

  /**
* Function to call Matter Search Service
* @param {string} query
*/
  const getMatterData = (paginationQuery) => {
    setLoading(true);
    const defaultQuery = {
      "filters": [
        formCsFilterObject("isActive", "match", true),
        formCsFilterObject("organizations.id", "match", identifier)
      ],
      "operation": "AND",
      "properties": null
    }
    const sortQueryMatter = {...sortQuery};

    if(searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(MATTER_DEFAULT_SORT_QUERY)) {
      sortQueryMatter.sortBy = [CS_SCORE_SORT_OBJ, ...sortQuery?.sortBy];
    }

    let sensitiveMatterQuery = getSensitiveMatterPayload(matterAccessibility?.viewAll, userDetails?.userUniqueId);
    const query = formCompositeSearchQuery([CS_SCOPES.MATTER], filterQuery, searchQuery, sortQueryMatter, paginationQuery, {}, defaultQuery,sensitiveMatterQuery);
    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=false&organizationId=${identifier}&options=restrictMode`,
      query
    )
    .then((response) => {
      dispatch(setMatterListingData({
        matters: response?.data?.matter?.data,
        count: response?.data?.matter?.count
      }));
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      addSnack({
        message: getErrorMessage(error)
      });
    });
  };

  useEffect(() => {
    const paginationQuery = {
      "offset": 0,
      "limit": ROWS_PER_PAGE_MATTER,
    }
    getMatterData(paginationQuery);
  }, [sortQuery, searchQuery, filterQuery]);

  /**
  * function will use when row is select/deselect
  */
  const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
    const mattersData = matterListingData?.matters;
    const result = allSelected?.map(item =>  mattersData && mattersData[item?.dataIndex] );
    setSelectRowsData(selectRowsData);
    setSelectedData(result)
  }

  /**
* Cancel Submit Modal
*/
  const onCancelBtnClk = () => {
    setModalOpen(false)
  }

  /*
  *Checking for No Timekeepers and No Matters
  */
  const isDisabled = (selectedMattersData?.length === 0 || selectedTimeKeeperData?.length === 0)


  /**
   * function is to open the  modal to show selected options
   */
  const handleSubmitRequest = () => {
    dispatch(setSelectedMattersData(selectedData))
    setModalOpen(true);
  }

  /**
   * function to go back to previous screen
   */
  const handleBack = () => {
    history.goBack()
  }

  /**
 * function to remove timekeeper
 */
  const cancelSelectedTimekeeper = (data) => {
    const filteredData = selectedTimeKeeperData?.filter((item) => item?.identifier !== data?.identifier)
    dispatch(setSelectedTimekeepersData(filteredData))
  }

    /**
 * function to remove matters
 */
     const cancelSelectedMatters = (data) => {
      const filteredData = selectedMattersData?.filter((item) => item?.matterNumber !== data?.matterNumber)
      dispatch(setSelectedMattersData(filteredData))
    }

  /**
* function to confirm submit request
*/
  const onConfirm = (data) => {
    const requestDetails = []

    selectedTimeKeeperData?.map(userItem => {
      const matterData=[]
      selectedMattersData?.map(matterItem => {
        matterData?.push({
          "matterId": matterItem?.id,
          "matterName": matterItem?.matterName,
          "matterNumber": matterItem?.matterNumber
        })
      })

      requestDetails?.push({
        "matterDetails": [...matterData],
        "userRequestId": userItem?.userRequestId
      })
    })

    setLoading(true);
    const body = {
      "matterAccessRequestDetails": requestDetails,
    }
    postService(
      MATTER_SERVICE,
      `/matter-access/v1`,
      body
    ).then((response) => {
      setLoading(false)
			addSnack({
				message: PEOPLE_LINK_SUCCESS_MESSAGE
			})
			setModalOpen(false)
			history.push(`/organization`, { selectedTab: 2 })
    }).catch((error) => {
      setLoading(false)
        addSnack({
          message: getErrorMessage(error)
        });
    })

  }

  return (
    <div className={classes.pageContent}>
      <div className={classes.heading}>
        <Grid>
          <GridColumn sm={6}>
            Assign to Matters
          </GridColumn>
          <GridColumn sm={6} className={classes.rightAlign}>
            <Button size="medium" variant="tertiary" data-testId="go-back" id="go-back" onClick={() => handleBack()}>
              {GO_BACK}
            </Button>
            <Button size="medium" variant="primary" data-testId="submit-request" id="submit-request" onClick={() => handleSubmitRequest()}>
              {SUBMIT_REQUEST}
            </Button>
          </GridColumn>
        </Grid>
      </div >
      {selectedTimeKeeperData?.length > 0 &&
        <div className={classes.timeKeepersContainer}>
          <Body as="span" size="large" weight={700}>
            {`${selectedTimeKeeperData?.length} Timekeepers Selected : `}
          </Body>
          {selectedTimeKeeperData?.map((item, index) =>
            <Button
              size="medium"
              variant="tertiary"
              trailing={<Close onClick={() => cancelSelectedTimekeeper(item)}/>}
              key={`${item?.lastName}`}
              data-testid="close-icon"
            >
              {`${item?.lastName} ${item?.firstName}`}
            </Button>
          )}
        </div>
      }
      <div className={classes.dataTableWrap}>
        <MuiDataTable
          data={matterListingData?.matters}
          columns={matterColumns}
          setTableColumns={setmatterColumns}
          customOptions={matterTableCustomOptions}
          loading={loading}
          selectableRowsType={"multiple"}
          hideAddNewButton={true}
          disableExport={true}
          showSelectedRow={true}
          selectedRows={selectRowsData}
          onRowSelection={handleRowSelectionChange}
          selectedRowMsg={MATTER_MESSAGE}
        />
      </div>
      <SubmitRequestModal modalOpen={modalOpen} onCancelBtnClk={onCancelBtnClk} selectedMattersData={selectedMattersData} selectedTimeKeeperData={selectedTimeKeeperData} isDisabled={isDisabled} loading={loading} onConfirm={onConfirm} cancelSelectedMatters={cancelSelectedMatters} cancelSelectedTimekeeper={cancelSelectedTimekeeper}/>
    </div>
  );
};

export default AssignTimekeepersSelectMatters;
