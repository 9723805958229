import { createUseStyles } from "react-jss";

const RemoveTimekeepersStyles = createUseStyles({
	dataTableWrap: {
		"& div>table>tbody>tr>td": {
			borderBottom: "none",
			fontFamily: "Bogle",
			height: "24px",
			fontWeight: "normal",
			fontStrech: "normal",
			lineHeight: "1.33",
		},
		padding: "36px"
	},
	heading: {
		padding: "72px 84px 14px 48px",
		fontSize: 36,
		fontWeight: "bold",
	},
	mattersContainer: {
    padding: "18px",
    margin: "18px 36px 2px 36px",
    backgroundColor: "#F2F8FD",
    border: "1px solid #80B8EE",
    borderRadius: "8px",
    alignItems: "center"
  },
	modalNoSelectionStyle: {
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: "24px"
  },
	modalSubText: {
    fontSize: 18,
    fontWeight: 'normal',
    paddingBottom: "8px"
  },
});

export default RemoveTimekeepersStyles;
