const types = {
    SET_INVOICE_DATA: 'SET_INVOICE_DATA',
    ADD_NEW_INVOICE: 'ADD_NEW_INVOICE',
    SET_INPUT_VALUE: 'SET_INPUT_VALUE',
    SET_INVOICE_DETAIL: 'SET_INVOICE_DETAIL',
    RESET_INVOICE_DETAIL: 'RESET_INVOICE_DETAIL',
    SET_MATTER_INVOICE_DATA: 'SET_MATTER_INVOICE_DATA',
    SET_LINE_ITEMS_DATA: "SET_LINE_ITEMS_DATA",
    SET_NON_ADJUSTED_FEE_LINE_ITEMS: "SET_NON_ADJUSTED_FEE_LINE_ITEMS",
    SET_NON_ADJUSTED_EXPENSE_LINE_ITEMS: "SET_NON_ADJUSTED_EXPENSE_LINE_ITEMS",
    SET_ADJUSTED_FEE_LINE_ITEMS: "SET_ADJUSTED_FEE_LINE_ITEMS",
    SET_ADJUSTED_EXPENSE_LINE_ITEMS: "SET_ADJUSTED_EXPENSE_LINE_ITEMS",
    SET_LINE_ITEM_PAGINATION_DETAIL_ADJUSTED: "SET_LINE_ITEM_PAGINATION_DETAIL_ADJUSTED",
    SET_LINE_ITEM_PAGINATION_DETAIL_NON_ADJUSTED: "SET_LINE_ITEM_PAGINATION_DETAIL_NON_ADJUSTED",
    RESET_INVOICE_LINE_ITEM_ADJUSTED: "RESET_INVOICE_LINE_ITEM_ADJUSTED",
    RESET_INVOICE_LINE_ITEM_NON_ADJUSTED: "RESET_INVOICE_LINE_ITEM_NON_ADJUSTED"
}

export default types;
