export const Operations = {
	EQ: 'EQ', // Equals
	EQIC: 'EQIC', // Equals ignore case
	NEQ: 'NEQ', // Not equal
	NEQIC: 'NEQIC', // Not equal ignoring case
	LIKE: 'LIKE', // Pattern matching (% replaces characters, _ replaces a single character)
	LIKEIC: 'LIKEIC', // Pattern matching ignore case (% replaces characters, _ replaces a single character)
	GT: 'GT', // Greater than
	GTE: 'GTE', // Greater than or equal
	LT: 'LT', // Lower than
	LTE: 'LTE', // Lower than or equal
	IN: 'IN', // In set
	INIC: 'INIC', // In set ignore case
	NIN: 'NIN', // Not in set
	NINIC: 'NINIC', // Not in set ignore case
	ISNULL: 'ISNULL', // Null
	ISNOTNULL: 'ISNOTNULL', // Not null,
	MATCH: "match", // exact match,
	OR: "OR", // logical OR
	AND:"AND"
};
