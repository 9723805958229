import React from "react";
import PropTypes from 'prop-types';
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * Component to render Autocomplete multi select dropdown
 *
 * @visibleName Autocomplete Multi Select
 */
const AutocompleteMultiSelect = (props) => {
  const {options, colKey, handleOnChange, defaultValue, placeholder, id="autocomplete-multi-select"} = props;

  return (
    <Autocomplete
      id={id}
      multiple
      options={options}
      disableCloseOnSelect={false}
      disablePortal={true}
      defaultValue={defaultValue}
      onChange={(event, value) => handleOnChange(event, value, colKey)}
      getOptionLabel={(label) => label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder}/>
      )}
      data-testid="filter-panel-autocompleteMultiselect"
    />
  );
}

export default AutocompleteMultiSelect;

AutocompleteMultiSelect.propTypes = {
  /** multiselect options for the dropdown */
  options: PropTypes.array,
  /** unique key to identify the filter */
  colKey: PropTypes.string,
  /** callback function on user input */
  handleOnChange: PropTypes.func,
  /** to display default value or pre selected values on load */
  defaultValue: PropTypes.array
};

AutocompleteMultiSelect.defaultProps = {
  options: ['Item1', 'Item2', 'Item3', 'Item4'],
  colKey: 'ItemAutocompleteMultiSelect',
  handleOnChange: (event) => {
    // eslint-disable-next-line no-console
    console.log('Option changed!');
  },
  defaultValue: []
}
