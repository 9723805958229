import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import DocumentList from "../DocumentListing/DocumentList";
import { useDispatch, useSelector } from "react-redux";
import { setDocumentBreadcrumbIndex } from "../../../store/actions/documentActions";
import { CREATED_BY } from "../../../constants/constants";
import { useParams } from "react-router-dom";
import withStarredItemsList from "../../Hoc/withStarredItemsList";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  wrapper: {
    background: "#F5F5F5",
    width: "100%"
  }
});

/**
 * Matter Documents tab component
 */
const MatterDocumentsTab = () => {

  const classes = useStyles();
  const { matterId } = useParams();

  const dispatch = useDispatch();
  const folderSearchQuery = useSelector((state) => state?.document?.folderSearchQuery);
  const MATTER_FILE_FILTER_CONFIG = [
    {
      colKey: "tags",
      type: "masterDataMultiAutocomplete",
      label: "Tagged with",
      dataType: "documentTags",
      defaultOpen: true,
      section: "left",
      id: "document-tag",
    },
    {
      colKey: "createdBy",
      type: "peopleMultiDropdownAutocomplete",
      label: CREATED_BY,
      options: [],
      defaultOpen: true,
      isUserAutocomplete: true,
      isInternalExternal: true,
      section: "left",
      id: "document-created-by",
    },
    {
      colKey: "createdTimeStamp",
      type: "rangePicker",
      label: "Created Date Range",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Created Date Range'
    },
    {
      colKey: "version",
      type: "textField",
      label: "Version",
      section: "right",
      defaultOpen: true,
      id :"version"
    },
  ];
  useEffect(()=>{
    if(folderSearchQuery === null) {
      dispatch(setDocumentBreadcrumbIndex("RESET"));
    }
  },[folderSearchQuery])

  // default query to fetch matter related documents
  const defaultQuery = [{
    "key": "metaData.IL_OBJECT_ID",
    "operation": "match",
    "value": matterId
  },
  {
    "key": "metaData.isPublic", // fetch only public documents
    "operation": "match",
    "value": true
  }
  ];

  return (
    <div className={classes.wrapper}>
       <DocumentList
       defaultQuery={defaultQuery}
       filterConfigs={MATTER_FILE_FILTER_CONFIG}
       fromMatter={true}
       />
    </div>
  );
};

export default withStarredItemsList(MatterDocumentsTab, 'starredDocuments');

