import React, { useState } from 'react';
import { createUseStyles } from "react-jss";
import Popover from '@mui/material/Popover';
import PropTypes from "prop-types";
import {
  Button,
  Tag
} from "@walmart-web/livingdesign-components";

import { MORE } from "../../../constants/actionButtons";

const useStyles = createUseStyles({
  tag: {
    margin: 10
  },
  tags: {
    margin: "3px 5px"
  },
  tagsMargin: {
    margin: "8px 0px"
  },
  tabContainer: {
    "& nav > ul > li > a": {
      background: "none",
    }
  },
  tagsContainer: {
    display: "flex"
  },
  moreText: {
    cursor: "pointer"
  }
});

const MoreTagsPopover = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const { tags, tagLimit, id } = props;
  const additionalTagsCount = tags?.length - tagLimit;
  const additionalTags = tags?.slice(tagLimit);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const allTags = tags?.map((item, idx) => {
    if (idx < 4) {
      return (
        <Tag color="blue" variant="tertiary" className={classes.tags} id={item} key={`${item}`}>
          {item}
        </Tag>
      )
    }
  })

  const moreTags = () => {
    return (
      <>
        <Button
          size="medium"
          variant="tertiary"
          onClick={handleClick}
          className={classes.moreText}
          data-testid="action-btn"
        >
          + {additionalTagsCount} {MORE}
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {additionalTags?.map((tag, idx) => {
            return (
              <div className={classes.tag} key={`${tag}`}>
                <Tag color="blue" variant="tertiary">
                  {tag}
                </Tag>
              </div>
            )
          })}
        </Popover>
      </>
    )
  }

  return (
    <div>
      <div className={classes.tagsContainer}>
        <div className={classes.tagsMargin} id={`tag-row-${id}`}>{allTags}</div>
        {additionalTagsCount > 0 ? moreTags() : null}
      </div>
    </div>
  );
}

export default MoreTagsPopover;

MoreTagsPopover.propTypes = {
  tags: PropTypes.array, 
  tagLimit: PropTypes.number, 
  id: PropTypes.string
};
