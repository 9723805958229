import types from '../types/jobStatusActionTypes';

export const initialState = {
  jobStatusData: {
    jobRequestDtoList: [],
    count: 0
  },
  jobStatusDetail: {
    id: "",
    title: "",
    description: "",
    taskCount: "",
    isFileAttached: false,
    fileName: "",
    canopusFileId: "",
    executionType: false,
    validationType: false,
    requestIdentifiers: "",
    selectorQuery: "",
    selectorQueryOffset: "",
    selectorQueryLimit: "",
    requestDataBag: "",
    command: "",
    status: "",
    isActive: false,
    createdBy: "",
    createdTs: "",
    lastUpdatedBy: "",
    lastUpdatedTs: "",
    jobTask: []
  }
}
const jobStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_JOB_STATUS_DATA:
      return {
        ...state,
        jobStatusData: action.payload,
      };
    case types.SET_JOB_STATUS_DETAIL:
      return {
        ...state,
        jobStatusDetail: action.payload,
      };
    default:
      return state;
  }
};

export default jobStatusReducer;
