import React, { useEffect, useState } from "react";
import MatterComponentStyles from "./MatterComponentStyles";
import { useSelector } from "react-redux";
import { Button, Grid } from "@walmart-web/livingdesign-components";
import { Minus, Plus } from "@walmart-web/livingdesign-icons";
import { ADD_ROW, EDITABLE } from "../../constants/constants";
import { onInputNumberKeyDown, renderTernary } from "../../helpers/utils";
import {TextField, Select, MenuItem} from "@mui/material";
import MasterDataAutocomplete from "../Autocomplete/MasterDataAutocomplete/MasterDataAutocomplete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { BACKEND_DATE_FORMAT, INVALID_DATE } from "../../constants/dateFormats";

const MatterJSON = ({ node, handleInputChange, FieldLabel }) => {
  const classes = MatterComponentStyles();
  const [nestedProperties, setNestedProperties] = useState([]);
  const sectionInput = useSelector((state) => state.matter.matterDetailsTab);
  const nodeValue = renderTernary(sectionInput?.[node?.propertyName] !== undefined, sectionInput?.[node?.propertyName], node?.value);
  const displayTypeTable = JSON.parse(decodeURI(node?.renderingHints))?.displayType === "table";
  const displayTypeEditableTable = JSON.parse(decodeURI(node?.renderingHints))?.displayType === "editableTable";
  const identifier = displayTypeTable ? "total-case-resolution" : "court-information";
  const defaultEditList = displayTypeTable ? [{
    id: `${node?.title?.toLowerCase()?.split(' ')?.join('-')}-1`,
    paymentType: "",
    amount: "",
    comments: ""
  }] : [{
    id: `${node?.title?.toLowerCase()?.split(' ')?.join('-')}-1`,
    courtInfoComments: '',
    courtName: '',
    docketNumber: '',
    venueState :''
  }];

  const [editList, setEditList] = useState(defaultEditList);
	useEffect(() => {
		setNestedProperties(JSON.parse(decodeURI(node?.renderingHints))?.nestedDefinition);
    if(nodeValue !== undefined && nodeValue !== null && nodeValue?.length > 0) {
      const editListWithID = nodeValue?.map((item,idx) => {
        const obj = item;
        obj.id = `${identifier}-${idx}`
        return obj;
      })
      setEditList(editListWithID);
    } else {
      setEditList(defaultEditList);
    }
	}, [])

  /**
* Function to handle add new row in table
*/
  const handleAddNewRow = () => {
    const masterList = JSON.parse(JSON.stringify(editList));
    let idNumber = 1;
    if (masterList.length !== 0) {
      const masterListCopy = JSON.parse(JSON.stringify(editList));
      idNumber = parseInt(masterListCopy?.slice(-1)[0]?.id?.split("-")?.slice(-1)[0]);
    }
    const newItem = {
      ...defaultEditList[0],
      id: `${node?.title?.toLowerCase()?.split(' ')?.join('-')}-${idNumber + 1}`,
    }
    masterList.push(newItem);
    setEditList(masterList);
  }

  /**
  * Function to handle delete row in table
  * @param {*} id
  */
  const handleDeleteRow = (id) => {
    const masterList = [...editList];
    const itemIndex = masterList?.findIndex((item => item?.id === id));
    masterList.splice(itemIndex, 1);
    if (masterList?.length === 0) {
      setEditList(defaultEditList);
      handleInputChange(node?.propertyName, []);
    }
    else {
      setEditList(masterList);
      handleInputChange(node?.propertyName, [...masterList]);
    }
  }

  /**
  * Function to handle change of row in table
  * @param {*} id
  * @param {*} key
  * @param {*} value
  */
  const handleOnChangeRow = (id, key, value) => {
    const masterList = JSON.parse(JSON.stringify(editList));
    const itemIndex = masterList?.findIndex((item => item?.id === id));
    masterList[itemIndex][key] = value;
    setEditList([...masterList]);
    handleInputChange(node?.propertyName, [...masterList]);
  }

    /**
  * Function to handle change of dates for a row in table
  * @param {*} id
  * @param {*} key
  * @param {*} value
  */
    const handleOnDateChangeRow = (id, key, value) => {
      if(value === undefined || value === INVALID_DATE) {
        value = null;
      }
      handleOnChangeRow(id, key, value);
    }

  return (renderTernary(node?.displayProperty === EDITABLE,
    <React.Fragment>
      <FieldLabel label={node?.title} isRequired={node?.required} />
      {editList && (displayTypeTable || displayTypeEditableTable) && editList?.map((item, index) => (
        <Grid key={`${item?.id}`}>
          <div className={classes.flex} key={item?.id} data-testid={`add-row-item-${item?.id}`}>
            {nestedProperties?.map((val, idx) => {
            const callMasterDataFlag = val?.renderingHints && (JSON.parse(decodeURI(val?.renderingHints))?.hasOwnProperty('callMasterData') && JSON.parse(decodeURI(val?.renderingHints))?.callMasterData);
            return (<div className={classes.flexItem} key={`${val?.title}`}>
                <label className={classes.labelTitle}>{val?.title}</label>
                {val?.dataType === "Select" && callMasterDataFlag && <div className={classes.autocomplete}>
                  <MasterDataAutocomplete
                    value={renderTernary(item?.hasOwnProperty(val?.propertyName), item[val?.propertyName], "-")}
                    setValue={(v) => {
                      return handleOnChangeRow(item?.id, val?.propertyName, v.value)
                    }}
                    dataType={JSON.parse(decodeURI(val?.renderingHints))?.masterDataTypes.join(',')}
                    testid="matter-select"
                    placeholderValue={val?.title}
                  /> </div>}
                {val?.dataType === "Select" && !callMasterDataFlag &&
                  <Select
                    size="small"
                    className={classes.fieldWidth}
                    value={renderTernary(item?.hasOwnProperty(val?.propertyName), item[val?.propertyName], "-")}
                    onChange={(e) => {
                        console.log(item?.id, e.target.name, e.target.value)
                        handleOnChangeRow(item?.id, val?.propertyName, e.target.value)
                      }
                    }
                    data-testid={`${val?.title?.toLowerCase()?.split(' ')?.join('-')}`}
                    selectProps={{
                      name: val?.propertyName,
                      required: val?.required
                    }}
                  >
                    <MenuItem value="" className={classes.displayNone}>{`Select ${val?.title.toLowerCase()}`}</MenuItem>
                      {val?.range?.map((itm,idx) =>
                        <MenuItem value={itm} key={itm}>{itm}</MenuItem>
                      )}
									</Select>}
                {(val?.dataType === "Number") &&
                  <TextField
                    type="number"
                    className={classes.fieldWidth}
                    value={renderTernary(item?.hasOwnProperty(val?.propertyName), item[val?.propertyName], "-")}
                    onKeyDown={(e) => onInputNumberKeyDown(e)}
                    onChange={(e) => handleOnChangeRow(item?.id, val?.propertyName, e.target.value)}
                    data-testid={`${val?.title?.toLowerCase()?.split(' ')?.join('-')}`}
                    textFieldProps={{
                      name: val?.propertyName,
                      required: val?.required
                    }}
                  />}
                {(val?.dataType === "Text") &&
                  <TextField
                    type="text"
                    className={classes.fieldWidth}
                    value={renderTernary(item?.hasOwnProperty(val?.propertyName), item[val?.propertyName], "-")}
                    onChange={(e) => handleOnChangeRow(item?.id, val?.propertyName, e.target.value)}
                    data-testid={`${val?.title?.toLowerCase()?.split(' ')?.join('-')}`}
                    id={`${val?.title?.toLowerCase()?.split(' ')?.join('-')}`}
                    textFieldProps={{
                      name: val?.propertyName,
                      required: val?.required
                    }}
                  />}
                  {(val?.dataType === "Date") &&
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      id="end-date-wrap"
                      value={item?.hasOwnProperty(val?.propertyName) ? moment(item[val?.propertyName]) : null}
                      onChange={(e) => handleOnDateChangeRow(item?.id, val?.propertyName, moment(e).format(BACKEND_DATE_FORMAT))}
                      onError={(err) => {
                        console.log(err)
                      }}
                      slotProps={{
                        inputAdornment: { position: "end" },
                        textField: {
                          "data-testid": "end-date",
                          placeholder: "Enter File Date",
                          name: val?.propertyName, id: val?.propertyName,
                          // helperText: errors?.startDate?.message,

                        }
                      }}
                    />
                  </LocalizationProvider>}
              </div>
            )}
            )}
            <div className={classes.minusIcon}>
              <Minus size="small"
                className={classes.deleteIcon}
                onClick={() => handleDeleteRow(item?.id)}
                data-testid={`row-delete-icon-${item?.id}`} />
            </div>
          </div>
        </Grid>
      ))}
      <div className={classes.addRowButton}>
        <Button
          leading={<Plus size="small" />}
          size="small"
          variant="tertiary"
          onClick={() => handleAddNewRow()}
          data-testid="add-new-row"
        >
          {ADD_ROW}
        </Button>
      </div>
    </React.Fragment>, <React.Fragment></React.Fragment>)
  );
};

export default MatterJSON;
