import {
  BUDGET_CREATE,
  BUDGET_LIST,
  BUDGET_READ,
  CREATE_TK_MATTER_RATE_REQ,
  CREATE_TK_PRACTICE_RATE_REQ,
  DOCUMENT_MGMT,
  INVOICE_CREATE,
  INVOICE_LIST,
  INVOICE_READ,
  MATTER_LIST,
  MATTER_ORG_READ,
  MATTER_READ,
  MATTER_UPDATE,
  ORGANIZATION_UPDATE,
  ORG_PPL_READ,
  PEOPLE_LIST,
  DE_ACTIVATE_TK,
  CREATE_TK_REQ,
  ORGANIZATION_READ,
  BUDGET_UPDATE,
  NOTES_READ,
  NOTES_CREATE,
  NOTES_LIST,
  INVOICE_UPDATE_NET_AMOUNT,
  MATTER_PPL_UNASSIGN,
  MATTER_PPL_ASSIGN,
  EDIT_TK_ASSIGN,
  MATTER_VIEW_ALL,
  INVOICE_EDIT_LINE_ITEM_DISCOUNT
} from "../constants/ResponsibilityCodes";
import { TAB_LABEL } from "../constants/tabLabel";

const invoicesDetails = (accessibleResponsibilitiesMap) => {
  return {
    edit: Boolean(accessibleResponsibilitiesMap[INVOICE_UPDATE_NET_AMOUNT]),
    tabs: {
      lineItems: {
        showTab: true,
        label: TAB_LABEL.LINE_ITEMS,
        tabIndex: TAB_LABEL.LINE_ITEMS,
        edit: Boolean(accessibleResponsibilitiesMap[INVOICE_UPDATE_NET_AMOUNT]),
        read: Boolean(accessibleResponsibilitiesMap[INVOICE_UPDATE_NET_AMOUNT]),
        editDiscount: Boolean(accessibleResponsibilitiesMap[INVOICE_EDIT_LINE_ITEM_DISCOUNT]),
        adjustedLineItems: {
          view: Boolean(accessibleResponsibilitiesMap[INVOICE_READ]),
          tabs: {
            fees: {
              showTab: true,
              label: TAB_LABEL.FEES
            },
            expenses: {
              showTab: true,
              label: TAB_LABEL.EXPENSES
            }
          }
        },
        lineItems: {
          view: Boolean(accessibleResponsibilitiesMap[INVOICE_READ]),
          tabs: {
            fees: {
              showTab: true,
              label: TAB_LABEL.FEES,
              read: Boolean(accessibleResponsibilitiesMap[INVOICE_UPDATE_NET_AMOUNT]),
            },
            expenses: {
              showTab: true,
              label: TAB_LABEL.EXPENSES,
              read: Boolean(accessibleResponsibilitiesMap[INVOICE_UPDATE_NET_AMOUNT]),
            }
          }
        }
      },
      document: {
        showTab: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
        label: TAB_LABEL.DOCUMENTS,
        tabIndex: TAB_LABEL.DOCUMENTS,
        upload: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
        listing: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
        download: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT])
      },
    }
  }
}

const budgetDetails = (accessibleResponsibilitiesMap) => {
  return {
    actions: {
      edit: Boolean(accessibleResponsibilitiesMap[BUDGET_UPDATE])
    },
    tabs: {
      overview: {
        showTab: true,
        label: TAB_LABEL.OVERVIEW,
        tabIndex: 0
      },
      details: {
        showTab: true,
        label: TAB_LABEL.DETAILS,
        tabIndex: 1
      },
      invoices: {
        showTab: Boolean(accessibleResponsibilitiesMap[INVOICE_LIST]) ||
          Boolean(accessibleResponsibilitiesMap[INVOICE_READ]),
        label: TAB_LABEL.INVOICES,
        tabIndex: 2,
        read: Boolean(accessibleResponsibilitiesMap[INVOICE_READ]) ||
          Boolean(accessibleResponsibilitiesMap[INVOICE_LIST]),
        export: true,
        details: invoicesDetails(accessibleResponsibilitiesMap)
      },
      timeline: {
        showTab: true,
        label: TAB_LABEL.TIMELINE,
        tabIndex: 3
      },
    }
  }
};

const documentDetails = (accessibleResponsibilitiesMap) => {
  return {
    export: true,
    edit: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
    view: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT])
  }
}

const matterDetails = (accessibleResponsibilitiesMap) => {
  return {
    tabs: {
      overview: {
        showTab: true,
        tabIndex: 0,
        label: TAB_LABEL.OVERVIEW
      },
      details: {
        showTab: true,
        tabIndex: 1,
        label: TAB_LABEL.DETAILS,
        edit: Boolean(accessibleResponsibilitiesMap[MATTER_UPDATE]),
      },
      document: {
        showTab: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
        tabIndex: 2,
        label: TAB_LABEL.DOCUMENTS,
        tabs: {
          matterDocument: {
            showTab: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
            tabIndex: 0,
            label: TAB_LABEL.MATTER_DOCUMENTS,
            read:  Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
            edit: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
            addNew: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
            details: documentDetails(accessibleResponsibilitiesMap),
          },
          recentlyModified: {
            showTab: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
            tabIndex: 1,
            label: TAB_LABEL.RECENTLY_MODIFIED,
            read:  Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
            edit: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
            details: documentDetails(accessibleResponsibilitiesMap),
          },
          myStarred: {
            showTab: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
            tabIndex: 2,
            label: TAB_LABEL.MATTER_STARRED,
            read:  Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
            edit: Boolean(accessibleResponsibilitiesMap[DOCUMENT_MGMT]),
            details: documentDetails(accessibleResponsibilitiesMap),
          }
        }
      },
      financials: {
        showTab: Boolean(accessibleResponsibilitiesMap[INVOICE_LIST]) ||
          Boolean(accessibleResponsibilitiesMap[INVOICE_READ]) ||
          Boolean(accessibleResponsibilitiesMap[BUDGET_LIST]) ||
          Boolean(accessibleResponsibilitiesMap[BUDGET_READ]),
        tabIndex: 3,
        label: TAB_LABEL.FINANCIALS,
        tabs: {
          budget: {
            showTab: Boolean(accessibleResponsibilitiesMap[BUDGET_LIST]) ||
              Boolean(accessibleResponsibilitiesMap[BUDGET_READ]),
            label: TAB_LABEL.BUDGETS,
            export: true,
            addNew: Boolean(accessibleResponsibilitiesMap[BUDGET_CREATE]),
            read: Boolean(accessibleResponsibilitiesMap[BUDGET_READ]) ||
              Boolean(accessibleResponsibilitiesMap[BUDGET_LIST]),
            details: budgetDetails(accessibleResponsibilitiesMap)
          },
          invoice: {
            showTab: Boolean(accessibleResponsibilitiesMap[INVOICE_LIST]) ||
              Boolean(accessibleResponsibilitiesMap[INVOICE_READ]),
            label: TAB_LABEL.INVOICES,
            export: true,
            addNew: Boolean(accessibleResponsibilitiesMap[INVOICE_CREATE]),
            read:  Boolean(accessibleResponsibilitiesMap[INVOICE_LIST]) ||
            Boolean(accessibleResponsibilitiesMap[INVOICE_READ]),
            details: invoicesDetails(accessibleResponsibilitiesMap)
          }
        }
      },
      notes: {
        showTab: Boolean(accessibleResponsibilitiesMap[NOTES_LIST]),
        label: TAB_LABEL.NOTES,
        tabIndex: 4,
        export: true,
        addNew: Boolean(accessibleResponsibilitiesMap[NOTES_CREATE]),
        read: Boolean(accessibleResponsibilitiesMap[NOTES_READ])
      }
    }
  }
}

const externalUserDetails = (accessibleResponsibilitiesMap) => {
  return {
    edit: Boolean(accessibleResponsibilitiesMap[EDIT_TK_ASSIGN]),
    tabs: {
      details: {
        showTab: true,
        tabIndex: 0,
        label: TAB_LABEL.DETAILS
      },
      matterSpecificRates: {
        showTab: Boolean(accessibleResponsibilitiesMap[CREATE_TK_MATTER_RATE_REQ]),
        label: TAB_LABEL.MATTER_SPECIFIC_RATES,
        tabIndex: 1,
        addNew: Boolean(accessibleResponsibilitiesMap[CREATE_TK_MATTER_RATE_REQ])
      },
      practiceAreaRates: {
        showTab: Boolean(accessibleResponsibilitiesMap[CREATE_TK_PRACTICE_RATE_REQ]),
        label: TAB_LABEL.PRACTICE_AREA_RATES,
        tabIndex: 2,
        addNew: Boolean(accessibleResponsibilitiesMap[CREATE_TK_PRACTICE_RATE_REQ])
      },
      associatedMatters: {
        showTab: true,
        label: TAB_LABEL.ASSOCIATED_MATTERS,
        tabIndex: 3,
        addNew: false,
        export: true,
        read: false,
      }
    }
  }
}

const userRequestDetails = (accessibleResponsibilitiesMap) => {
  return {
    tabs: {
      details: {
        showTab: true,
        tabIndex: 0,
        label: TAB_LABEL.DETAILS
      },
      practiceAreaRates: {
        showTab: Boolean(accessibleResponsibilitiesMap[CREATE_TK_REQ]),
        label: TAB_LABEL.PRACTICE_AREA_RATES,
        tabIndex: 1,
        addNew: false
      },
    }
  }
}

export const applicationAccess = (accessibleResponsibilitiesMap) => {
  return {
    matters: {
      showTab: Boolean(accessibleResponsibilitiesMap[MATTER_LIST]) ||
        Boolean(accessibleResponsibilitiesMap[MATTER_VIEW_ALL]),
      viewAll: Boolean(accessibleResponsibilitiesMap[MATTER_VIEW_ALL]),
      viewAssigned: Boolean(accessibleResponsibilitiesMap[MATTER_LIST]),
      read: Boolean(accessibleResponsibilitiesMap[MATTER_READ]),
      addNew: false,
      export: true,
      details: matterDetails(accessibleResponsibilitiesMap)
    },
    financial: {
      budget: {
        tabIndex: 0,
        showTab: Boolean(accessibleResponsibilitiesMap[BUDGET_LIST]) ||
          Boolean(accessibleResponsibilitiesMap[BUDGET_READ]),
        viewAll: Boolean(accessibleResponsibilitiesMap[BUDGET_LIST]),
        viewAssigned: Boolean(accessibleResponsibilitiesMap[BUDGET_READ]),
        label: TAB_LABEL.BUDGETS,
        export: true,
        addNew: Boolean(accessibleResponsibilitiesMap[BUDGET_CREATE]),
        read: true,
        details: budgetDetails(accessibleResponsibilitiesMap)
      },
      invoice: {
        tabIndex: 1,
        showTab: Boolean(accessibleResponsibilitiesMap[INVOICE_READ]) ||
          Boolean(accessibleResponsibilitiesMap[INVOICE_LIST]),
        viewAll: Boolean(accessibleResponsibilitiesMap[INVOICE_LIST]),
        viewAssigned: Boolean(accessibleResponsibilitiesMap[INVOICE_READ]),
        label: TAB_LABEL.INVOICES,
        export: true,
        addNew: Boolean(accessibleResponsibilitiesMap[INVOICE_CREATE]),
        read: true,
        details: invoicesDetails(accessibleResponsibilitiesMap)
      }
    },
    organization: {
      edit: Boolean(accessibleResponsibilitiesMap[ORGANIZATION_UPDATE]),
      tabs: {
        details: {
          showTab: true,
          tabIndex: 0,
          label: TAB_LABEL.DETAILS
        },
        people: {
          showTab: Boolean(accessibleResponsibilitiesMap[PEOPLE_LIST]),
          label: TAB_LABEL.PEOPLE,
          tabIndex: 1,
          tabs:{
            active: {
              showTab: Boolean(accessibleResponsibilitiesMap[ORG_PPL_READ]),
              label: TAB_LABEL.ACTIVE,
              tabIndex: 0,
              export: true,
              read: Boolean(accessibleResponsibilitiesMap[ORG_PPL_READ]),
              addNew: Boolean(accessibleResponsibilitiesMap[CREATE_TK_REQ]),
              deactivate: Boolean(accessibleResponsibilitiesMap[DE_ACTIVATE_TK]),
              details: externalUserDetails(accessibleResponsibilitiesMap)
            },
            inactive: {
              showTab: Boolean(accessibleResponsibilitiesMap[ORG_PPL_READ]),
              label: TAB_LABEL.INACTIVE,
              tabIndex: 1,
              export: true,
              read: Boolean(accessibleResponsibilitiesMap[ORG_PPL_READ]),
              details: externalUserDetails(accessibleResponsibilitiesMap)
            },
            rejected: {
              showTab: Boolean(accessibleResponsibilitiesMap[CREATE_TK_REQ]),
              label: TAB_LABEL.REJECTED,
              tabIndex: 2,
              export: true,
              read: Boolean(accessibleResponsibilitiesMap[CREATE_TK_REQ]),
              details: userRequestDetails(accessibleResponsibilitiesMap),
            },
            pending: {
              showTab: Boolean(accessibleResponsibilitiesMap[CREATE_TK_REQ]),
              label: TAB_LABEL.PENDING,
              tabIndex: 3,
              read: Boolean(accessibleResponsibilitiesMap[CREATE_TK_REQ]),
              export: true,
              details: userRequestDetails(accessibleResponsibilitiesMap),
            },
          }
        },
        matters: {
          showTab: Boolean(accessibleResponsibilitiesMap[MATTER_ORG_READ]),
          label: TAB_LABEL.MATTERS,
          tabIndex: 2,
          export: true,
          read: Boolean(accessibleResponsibilitiesMap[MATTER_ORG_READ]),
          remove: Boolean(accessibleResponsibilitiesMap[ORGANIZATION_UPDATE])||
            Boolean(accessibleResponsibilitiesMap[MATTER_PPL_UNASSIGN]),
          addNew: Boolean(accessibleResponsibilitiesMap[ORGANIZATION_UPDATE]) ||
            Boolean(accessibleResponsibilitiesMap[MATTER_PPL_ASSIGN])
        },
      }
    }
  }
}

/**
 * Side Menu Access Control method
 * @param {*} accessibleResponsibilitiesMap
 * @returns
 */
export const sidemenuAccess = (accessibleResponsibilitiesMap)  => {
  return {
    matters: Boolean(accessibleResponsibilitiesMap[MATTER_LIST]) ||
      Boolean(accessibleResponsibilitiesMap[MATTER_VIEW_ALL]),
    financial: Boolean(accessibleResponsibilitiesMap[INVOICE_LIST]) ||
      Boolean(accessibleResponsibilitiesMap[BUDGET_LIST]) ||
      Boolean(accessibleResponsibilitiesMap[BUDGET_READ]) ||
      Boolean(accessibleResponsibilitiesMap[INVOICE_LIST]),
    organization: Boolean(accessibleResponsibilitiesMap[ORGANIZATION_READ])
  }
}

/**
 * Application Access & Side Menu Access
 * @param {*} accessibleResponsibilities
 * @returns
 */
export const mapAccesibilityControls = (accessibleResponsibilities) => {
  const accessibleResponsibilitiesMap = accessibleResponsibilities.reduce((a, v) => ({ ...a, [v]: true }), {})
  return {
    applicationAccess : applicationAccess(accessibleResponsibilitiesMap),
    sidemenuAccess :  sidemenuAccess(accessibleResponsibilitiesMap)
  }
}

