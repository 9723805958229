import { createUseStyles } from "react-jss";

const ForgotPasswordStyles = createUseStyles({
  forgotPageContainer: {
    textAlign: "center",
    width: "343px",
    margin: "150px auto"
  },
  label: {
    textAlign: "left",
    paddingTop: "16px"
  },
  button: {
    textAlign: "right",
    marginTop: "12px",
    paddingLeft: "190px"
  },
  loginButton: {
    marginTop: "12px",
  },
  passwordResetText: {
    textAlign: "left",
    paddingTop: "24px",
    paddingBottom: "12px"
  },
  rememberButton: {
    display: "flex",
    textAlign: "left",
    paddingTop: "24px",
    paddingBottom: "12px"
  },
  rememberText: {
    paddingTop: "0.5rem"
  },
  heading: {
    display: "inline-flex",
    marginBottom: "20px",
    marginRight: "12px"
  },
  spark: {
    paddingRight: "8px"
  },
  forgotPasswordText: {
    paddingTop: "0.25rem"
  },
  showMessageContainer:{
    textAlign: "center",
    width: "400px",
    margin: "auto",
    "&>p":{
      color: '#2E2F32'
    },
    "&>p>span":{
      fontWeight: 'bold',
      color: '#000'
    }
  },
  mailIcon: {
    width: '180px',
    height: '180px',
    margin: '0 auto',
    borderRadius: '50%',
    background: '#17B7FF',
    "& > svg": {
      marginTop: '40px'
    }
  }
})

export default ForgotPasswordStyles;

