import { useSnackbar } from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { postService } from "../../../../axios/axios";
import MuiDataTable from "../../../../components/Table/MuiDataTable/MuiDataTable";
import { COMPOSITE_SEARCH_SERVICE } from "../../../../constants/baseURLs";
import {
  CS_DEFAULT_SORT_QUERY, CS_IS_ACTIVE_FILTER, CS_IS_EXTERNAL_USER_FILTER, CS_SCOPES,
  CS_SCORE_SORT_OBJ,
  EXPORT_MAX_LIMIT_CONF,
  PEOPLE,
  PEOPLE_DATA
} from "../../../../constants/constants";
import { ROWS_PER_PAGE_OPTIONS_PEOPLE, ROWS_PER_PAGE_PEOPLE, } from "../../../../constants/tableConfigs";
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery } from "../../../../helpers/csQueryUtils";
import { getAllowedActionsList, getExportedData } from "../../../../helpers/tableUtils";
import { getErrorMessage } from "../../../../helpers/utils";
import { setExternalUserData } from "../../../../store/actions/peopleAction";
import InActivePeopleStyles from "./InActivePeople.styles";
import { COLUMNS_PEOPLE, FILTER_CONFIGS_PEOPLE } from "../OrganizationPeople";
import ExportModal from "../../../../components/Modal/ExportModal/ExportModal";

const InActivePeople = (props) => {
  const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.organization?.tabs?.people?.tabs?.inactive);
  const identifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false);
  const [createExportLoading, setCreateExportLoading] = useState(false);
  const [selectRowsData, setSelectRowsData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState({});
  const [filterQuery, setFilterQuery] = useState({});
  const [sortQuery, setSortQuery] = useState(CS_DEFAULT_SORT_QUERY);
  const [rowsPerPagePeople, setRowsPerPagePeople] = useState(ROWS_PER_PAGE_PEOPLE);
  const dispatch = useDispatch();
  const { addSnack } = useSnackbar();

  const [peopleColumns, setPeopleColumns] = useState(COLUMNS_PEOPLE);
  const { nodeList, count } = useSelector(
    (state) => state?.people?.externalUserData
  );
  const [externalUsersData, setExternalUsersData] = useState([]);
  const showExportAllOption = count < EXPORT_MAX_LIMIT_CONF.EXTERNAL_USER;
  const defaultExportOption = showExportAllOption ? 'all' + PEOPLE : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);
  const [currentPageExport, setCurrentPageExport] = useState(false);
  const history = useHistory();
  const classes = InActivePeopleStyles();

  useEffect(() => {
    const data = nodeList?.filter(item => item?.userRequestId);
    setExternalUsersData(data);
  }, [nodeList])

  /**
  * Function to call api on pagination action (server side)
  * @param {number} rowsPerPage
  * @param {number} page */
  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPagePeople(rowsPerPage);
    setPage(page);
    const paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * page));
    getPeopleData(paginationQuery);
  };

  /**
  * Function to validate search query and set query or show no results UI (server side)
  * @param {string} searchText
  */
  const handleServerSideSearch = (searchText) => {
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };
  /**
  *  Function to set filter query on filter action (server side)
  * @param {string} query
  */
  const handleServerSideFilter = (filterQuery) => {
    setFilterQuery(filterQuery);
  };

  /**
  * Function to call api on sort action (server side)
  * @param {number} rowsPerPage
  * @param {number} page
  */
  const handleServerSideSort = ({ name, direction }) => {
    const sortQuery = formCsSortQuery(name, direction);
    setSortQuery(sortQuery);
  };


  const peopleCustomOptions = {
    rowsPerPage: rowsPerPagePeople,
    responsive: "standard",
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_PEOPLE,
    filterConfigs: FILTER_CONFIGS_PEOPLE,
    page: page,
    serverSide: true,
    isPeople: true,
    totalCount: count,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSideSort: handleServerSideSort,
  };


  const handleRowClick = (rowData, rowMeta) => {
    history.push(`/people/${externalUsersData[rowMeta?.dataIndex]?.id}/view`, { selectedPeopleTab: 1 })
  }

  const associatedOrgFilter = formCsFilterObject("organizations.id", "match", identifier);
  const statusInActiveFilter = formCsFilterObject("status", "match", "InActive");

  const defaultQuery = {
    "filters": [
      CS_IS_ACTIVE_FILTER,
      CS_IS_EXTERNAL_USER_FILTER,
      associatedOrgFilter,
      statusInActiveFilter
    ],
    "operation": "AND",
    "properties": null
  }

  /**
   * Function to call associate people list Service
   */
  const getPeopleData = (paginationQuery) => {
    setLoading(true);
    const sortQueryPeople = { ...sortQuery };
    if (searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(CS_DEFAULT_SORT_QUERY)) {
      sortQueryPeople.sortBy = [CS_SCORE_SORT_OBJ, ...sortQuery?.sortBy];
    }
    const query = formCompositeSearchQuery([CS_SCOPES.PEOPLE], filterQuery, searchQuery, sortQueryPeople, paginationQuery, {}, defaultQuery);
    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=false`,
      query
    )
      .then((res) => {
        setLoading(false)
        const peopleData = res?.data?.people;
        dispatch(setExternalUserData({
          nodeList: peopleData?.data,
          count: peopleData?.count
        }));
      })
      .catch((error) => {
        setLoading(false)
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

  useEffect(() => {
    const paginationQuery = {
      "offset": 0,
      "limit": rowsPerPagePeople,
    }
    getPeopleData(paginationQuery);
  }, [sortQuery, searchQuery, filterQuery]);

  /**
 * Cancel Export Modal
 */
  const onCancelBtnClk = () => {
    setExportLoading(false)
    setModalOpen(false)
  }

  /**
 * function will use when row is select/deselect
 */
  const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
    const usersData = externalUsersData;
    const result = allSelected?.map(item => {
      return usersData && usersData[item?.index]
    });
    setSelectRowsData(selectRowsData);
    setRowsData(result);
  }

  /**
 * After click on create button download Actions
 */
  const onClickCreate = () => {
    setCreateExportLoading(true)
    let exportQuery = formCompositeSearchQuery([CS_SCOPES.PEOPLE], filterQuery, searchQuery, sortQuery, {}, { getAll: true }, {}, defaultQuery);
    if (exportSelection === 'currentSelection') {
      const result = rowsData?.map(a => a?.id);
      const selectionQuery = {
        "filters": [{
          "key": "id",
          "operation": "in",
          "value": result
        }],
        "operation": "AND",
        "properties": null
      }
      exportQuery = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, {}, {}, { getAll: true }, {}, defaultQuery, selectionQuery);
    }
    else if (exportSelection === 'currentPage') {
      const result = externalUsersData?.map(a => a?.id);
      const selectionQuery = {
        "filters": [{
          "key": "id",
          "operation": "in",
          "value": result
        }],
        "operation": "AND",
        "properties": null
      }
      exportQuery = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, {}, {}, { getAll: true }, {}, defaultQuery, selectionQuery);
    }

    postService(COMPOSITE_SEARCH_SERVICE, `/composite-search/v1?returnCsv=true`, exportQuery)
      .then((response) => {
        const data = response?.data;
        const contentType = response?.headers["content-type"];
        getExportedData(data, contentType, PEOPLE_DATA);
        setExportLoading(false);
        setCreateExportLoading(false);
        setModalOpen(false)
      })
      .catch((error) => {
        setExportLoading(false);
        setCreateExportLoading(false);
        setModalOpen(false)
        addSnack({
          message: getErrorMessage(error)
        });
      });
  }
  /**
   * After click on export button
   */
  const handleExportBtnClk = () => {
    setExportSelection(defaultExportOption);
    setExportLoading(true);
    setModalOpen(true);
  }

  const resetSelectedRows = () => {
    setSelectRowsData([]);
    setRowsData([]);
  }

  return (
    <div className={classes.rowStyle} data-testid="organization-people">
      <MuiDataTable
        columns={peopleColumns}
        data={externalUsersData}
        setTableColumns={setPeopleColumns}
        customOptions={peopleCustomOptions}
        hideAddNewButton={true}
        onRowClick={handleRowClick}
        exportLoading={exportLoading}
        onExportClick={handleExportBtnClk}
        loading={loading}
        selectableRowsType={"multiple"}
        onRowSelection={handleRowSelectionChange}
        selectedRows={selectRowsData}
        allowedActions={getAllowedActionsList(accessibility)}
        resetSelectedRows={() => resetSelectedRows()}
      />
      <ExportModal
        title={PEOPLE}
        mode={modalOpen}
        onCancel={onCancelBtnClk}
        onCreate={onClickCreate}
        rowsData={rowsData}
        exportSelection={exportSelection}
        setExportSelection={setExportSelection}
        currentPageExport={currentPageExport}
        showExportAllOption={showExportAllOption}
        setCurrentPageExport={setCurrentPageExport}
        exportLoading={createExportLoading}
      />
    </div>
  );
};

export default InActivePeople;
