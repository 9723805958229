import thunk from 'redux-thunk';
import { legacy_createStore as createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import masterDataReducer from '../reducers/masterDataReducer';
import matterReducer from '../reducers/matterReducer';
import peopleReducer from '../reducers/peopleReducer';
import organizationReducer from '../reducers/organizationReducer';
import documentReducer from '../reducers/documentReducer';
import noteReducer from '../reducers/noteReducer';
import budgetReducer from '../reducers/budgetReducer';
import invoiceReducer from '../reducers/invoiceReducer';
import timeKeeperAdminReducer from '../reducers/timeKeeperAdminReducer';
import invoiceCoordinatorReducer from '../reducers/invoiceCoordinatorReducer';
import userReducer from '../reducers/userReducer';
import preferencesReducer from '../reducers/preferencesReducer';
import accessibilityReducer from '../reducers/accessibilityReducer';
import jobStatusReducer from '../reducers/jobStatusReducer';
import helpCenterReducer from '../reducers/helpCenterReducer'
import adminUserRequestReducer from '../reducers/adminUserRequestReducer'
import commonReducer from '../reducers/commonReducer';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
  note: noteReducer,
  masterData: masterDataReducer,
  matter: matterReducer,
  people: peopleReducer,
  organization: organizationReducer,
  document: documentReducer,
  budget: budgetReducer,
  invoice: invoiceReducer,
  timeKeeperAdmin: timeKeeperAdminReducer,
  invoiceCoordinator: invoiceCoordinatorReducer,
  user: userReducer,
  accessibility: accessibilityReducer,
  preferences: preferencesReducer,
  processStatus: jobStatusReducer,
  helpCenter: helpCenterReducer,
  adminUserRequest: adminUserRequestReducer,
  common: commonReducer,
})


const rootReducer = (state, action) => {
  if (action.type === 'SET_LOGOUT') {
    localStorage.clear();
    // for all keys defined in your persistConfig(s)
    // storage.removeItem('persist:root')
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}

const configureStore = () => createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

export { configureStore };
