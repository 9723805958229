import React from 'react';
import { createUseStyles } from "react-jss";
import {Body, Button, Grid, GridColumn} from '@walmart-web/livingdesign-components';
import {Warning} from '@walmart-web/livingdesign-icons';
import { useHistory } from 'react-router-dom';
import { REQUEST_ACCESS_MSG, UNAUTHORISED_ACCESS_MSG } from '../../constants/messages';
import { REQUEST_ACCESS } from '../../constants/actionButtons';

const useStyles = createUseStyles({
  accessDeniedWrap: {
    marginTop: 200
  },
  accessDenied: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: 204
  }
});

const AccessDenied = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    // api call to trigger email
    history.goBack();
  }

  return (
    <div className={classes.accessDeniedWrap}>
      <Grid>
        <GridColumn>
          <div className={classes.accessDenied}>
            <Warning size="large"/>
            <Body as="p" variant="1">
              {UNAUTHORISED_ACCESS_MSG}
            </Body>
            <Body as="p" variant="2">
              {REQUEST_ACCESS_MSG}
            </Body>
            <Button
              size="large"
              variant="primary"
              onClick={handleClick}
							data-testid="requestAccessBtn"
            >
              {REQUEST_ACCESS}
            </Button>
          </div>
        </GridColumn>
      </Grid>
    </div>
  );
};

export default AccessDenied;
