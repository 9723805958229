import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from 'react-jss';
import { Button } from "@walmart-web/livingdesign-components";
import { Filter } from "@walmart-web/livingdesign-icons";
import { LIGHT_DARK_BLACK } from "../../../../constants/colors";
import { FILTERS } from "../../../../constants/actionButtons";
import { Tooltip } from "@mui/material";

const useStyles = createUseStyles((theme) => ({
  customFilterButtonWrap: {
    "& button": {
      color: LIGHT_DARK_BLACK,
      "&:focus-within": {
        boxShadow: "inset 0 0 0 0.0625rem #000",
      },
      "&:focus": {
        boxShadow: "inset 0 0 0 0.0625rem #000",
      },
    },
  },
}));

/**
 * Filter Button component which toggles filter panel
 *
 * @visibleName Filter Button
 */
const FilterButton = ({ togglePanel }) => {
  const classes = useStyles();

  /**
   * Function to handle filter button click to toggle the panel
   */
  const handleFilterButtonClick = () => {
    togglePanel(true);
  };

  return (
    <div className={classes.customFilterButtonWrap}>
      <Tooltip title={"Filter Table"}>
        <Button
          trailing={<Filter />}
          size="small"
          variant="secondary"
          data-testid="filter-button"
          id="filter-button"
          onClick={() => handleFilterButtonClick()}
        >
          {FILTERS}
        </Button>
      </Tooltip>
    </div>
  );
};

export default FilterButton;

FilterButton.propTypes = {
  /** Gets called when the user clicks on the filter button */
  togglePanel: PropTypes.func,
};
