import types from '../types/noteActionTypes';

export const setInputValue = (name, value) => {
	return { type: types.SET_INPUT_VALUE, payload: { name, value } }
}

export const setNoteDetail = (payload) => {
	return {type: types.SET_NOTE_DETAIL, payload}
}

export const resetNoteDetail = (payload) => {
  return {type: types.RESET_NOTE_DETAIL, payload}
}

export const setMatterNoteData = (payload) => {
  return { type: types.SET_MATTER_NOTE_DATA, payload };
};

export const setPublicNoteData = (payload) => {
  return { type: types.SET_PUBLIC_NOTE_DATA, payload };
};

export const setPrivateNoteData = (payload) => {
  return { type: types.SET_PRIVATE_NOTE_DATA, payload };
};
