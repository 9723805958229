import React from "react";
import { Skeleton } from "@walmart-web/livingdesign-components";
import LoadingCardsStyles from "./LoadingCards.styles";

const LoadingCards = ({
  loader=false,
  skeletonHeight="18px",
  skeletonWidth="100px",
  skeletonVariant="reactangle",
  skeletonLines = 1,
  styles = {},
  children,
}) => {
  const classes = LoadingCardsStyles();
  return (
    <div data-testid="load-component">
      {loader ? (
        [1,2,3]?.map((item) =>
        <span style={styles} key={item}>
          <div className={classes.contentSpacing16} key={item}>
          <Skeleton
            height={skeletonHeight}
            width={skeletonWidth}
            variant={skeletonVariant}
            lines={skeletonLines}
            className={classes.cards}
          />
          </div>
        </span>)
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </div>
  );
};
export default LoadingCards;
