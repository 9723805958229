import types from '../types/preferencesTypes';

export const setUserPrefences = (payload) => {
  return { type: types.SET_USER_PREFERENCES, payload }
}

export const resetUserPrefences = () => {
  return { type: types.RESET_USER_PREFERENCES }
}

export const setStarredItemsIds = (payload) => {
  return { type: types.SET_STARRED_ITEMS_IDS, payload }
}
