import { createUseStyles } from "react-jss";

const ChangePasswordStyles = createUseStyles({
  forgotPageContainer: {
    textAlign: "center",
    width: "343px",
    margin: "150px auto"
  },
  label: {
    textAlign: "left",
    paddingTop: "16px"
  },
  submitButton: {
    marginTop: "20px",
  },
  heading: {
    display: "inline-flex",
    marginBottom: "20px",
    marginRight: "12px"
  },
  spark: {
    paddingRight: "8px"
  },
  forgotPasswordText: {
    paddingTop: "0.25rem"
  },
  showButton: {
    "& button": {
      padding: "0px"
    }
  },
  showMessageContainer:{
    textAlign: 'center',
    "&>p":{
      color: '#2E2F32'
    },
    "&>p>span":{
      fontWeight: 'bold',
      color: '#000'
    }
  },
  errorInvalidUser:{
    padding: "0 25px",
    marginLeft: "5%",
    marginTop: "10px"
  }
})

export default ChangePasswordStyles;

