import { createUseStyles } from "react-jss";
import { SPACING } from "../../../../constants/styleGuide";

const CreateMSRPARStyles = createUseStyles({
  contentSpacing8: {
    paddingTop: SPACING.s8,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  contentSpacing48: {
    paddingTop: SPACING.s48,
  },
  paddingTop20: {
    padding: "20px 16px",
  },
  tag: {
    padding: 4,
    display: "inline-block",
  },
  info: {
    marginLeft: SPACING.s10,
  },
  unAssigned: {
    fontStyle: "italic",
  },
  approvalDataRightSection: {
    marginTop: "32px",
  },
  grid: {
    justifyContent: "space-between",
  },
  gridColumn: {
    marginRight: SPACING.s16,
  },
  divider: {
    marginTop: "80px",
  },
  divider1: {
    marginTop: "118px",
  },
  divider2: {
    marginTop: "43px",
  },
  createdDate: {
    marginTop: "25px",
  },
  listing: {
    backgroundColor: "white",
    padding: "32px 48px 0px 32px",
    flex: 1,
  },
  breadcrumb: {
    marginBottom: "-40px"
  },
  errorMsg:{
    marginLeft:"12px",
  },
  textfield:{
    width: "100%",
  }
});

export default CreateMSRPARStyles;
