import { createUseStyles } from "react-jss";
import { BORDERS } from "../../../constants/styleGuide";

const ThemisTopNavStyles = createUseStyles({
  userActionsNavBar: {
    position: "fixed",
    display: "flex",
    width: "100%",
    marginBottom: "34px",
    zIndex: 499
  },
  bannerContainer: {
    width: "100%",
    background: "#002242",
    height: "64px",
  },
  spark: {
    position: "absolute",
    width: "32px",
    height: "32px",
    margin: "16px 20px"
  },
  rightMenu: {
    position: "absolute",
    right: "24px",
    marginTop: "20px",
    display: "inline-flex"

  },
  rightMenuIcon: {
    width: "24px",
    height: "24px",
    marginLeft: "15px",
    "& > svg": {
      cursor: "pointer",
    }
  },
  rightMenuIconFeedback: {
    width: "22px",
    height: "22px",
    marginLeft: "15px",
    cursor: "pointer",
    color: '#ffffff',
    marginRight: '12px'
  },
  loggedInPopover: {
    '& > div': {
      left: "-90px",
      position: "absolute",
      textAlign: "center",
      top: "0",
      display:'inline-flex'
    }
  },
  loggenUserDetailWrap: {
    width: "180px"
  },
  userName: {
    marginBottom: "15px",
    paddingBottom: "10px",
    borderBottom: BORDERS.LIGHT_GREY,
    cursor: "pointer",
  },
  text: {
    position: "absolute",
    fontFamily: 'Bogle',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "28px",
    paddingTop: "18px",
    paddingLeft: "72px",
    color: "#FFFFFF",
  },
  helpCenterIcon:{
    cursor: "pointer",
    marginRight: '-5px'
  },
  userIcon:{
    cursor: "pointer",
  }
})

export default ThemisTopNavStyles;

