import { ArrowRight, Money } from "@livingdesign/icons";
import { Breadcrumb, BreadcrumbItem, Button, Divider, Grid, GridColumn, Heading, Skeleton, Spinner, useSnackbar } from "@walmart-web/livingdesign-components";
import { Pencil } from "@walmart-web/livingdesign-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Tooltip from '@mui/material/Tooltip';
import { getService, patchService, postService } from "../../../../axios/axios";
import { SELECT_ADJUSTMENT_REASON, ADJUSTED_LINE_ITEM_DESCRIPTION, CS_SCOPES, LABEL_APPEALED } from "../../../../constants/constants";
import { NO_COMMENT_AVAILABLE, NO_REASON_AVAILABLE, RESTORE_SUCCCESS_MESSAGE } from "../../../../constants/messages";
import { MOMENT_DATE_FORMAT } from "../../../../constants/dateFormats";
import { COMPOSITE_SEARCH_SERVICE, INVOICE_SERVICE } from "../../../../constants/baseURLs";
import { Percentage } from "../../../../constants/images";
import { setLineItemsData, setNonAdjustedPaginationForLineItems, setAdjustedPaginationForLineItems } from "../../../../store/actions/invoiceActions";
import { getErrorMessage, onInputNumberKeyDown, renderTernary } from "../../../../helpers/utils";
import MasterDataAutocomplete from "../../../../components/Autocomplete/MasterDataAutocomplete/MasterDataAutocomplete";
import { amountFormat, filterPayload } from "../../../../helpers/commonUtils";
import { CANCEL, DISCARD, RESTORE, SAVE } from "../../../../constants/actionButtons";
import { InvoiceLineItemDetailStyles } from "./ViewEditInvoiceLineItem.styles";
import { useLocation } from 'react-router-dom';
import { displayLocalDate } from "../../../../helpers/utcUtils";
import {TextField, Select, MenuItem} from "@mui/material";
import { formCompositeSearchQuery, formCsFilterObject } from "../../../../helpers/csQueryUtils";

/*
* This function will use for calculating the new total fees and expenses
*/
export const calculateAmount = (adjustment, value, prevValue = 0) => {
	let finalValue = Number(value);
	if (adjustment === 'increaseByPercentage') {
		const percent = (prevValue * value) / 100;
		finalValue = prevValue + Number(percent);
	} else if (adjustment === 'decreaseByPercentage') {
		const percent = (prevValue * value) / 100;
		finalValue = prevValue - Number(percent);
	} else if (adjustment === 'increaseByAmount') {
		finalValue = prevValue + Number(value);
	} else if (adjustment === 'decreaseByAmount') {
		finalValue = prevValue - Number(value);
	} else if (adjustment === 'increaseByUnits') {
		finalValue = prevValue + Number(value);
	} else if (adjustment === 'decreaseByUnits') {
		if ((prevValue - Number(value)) >= 0) {
			finalValue = prevValue - Number(value);
		} else {
			console.log("Final Units should not be Negative")
			finalValue = prevValue
		}
	}
	return finalValue;
}

const ShowDescription =({activeSubLineItem})=>{
	const classes = InvoiceLineItemDetailStyles();
	return (activeSubLineItem?.description)?
								<div className={classes.lineItemHeading}>
									<Heading as="h1" size="medium" weight={500}>
										{activeSubLineItem?.description}
									</Heading>
								</div>:<></>
}

const InvoiceLineItem = (props) => {
	const classes = InvoiceLineItemDetailStyles();
	const [comment, setComment] = useState("");
	const [isOpen, setIsOpen] = useState(null);
	const [blockOpen, setBlockOpen] = useState(null);
	const [invoiceDetail, setInvoiceDetail] = useState({});
	const [loading, setLoading] = useState(false);
	const [tkClassificationLoading, setTKClassificationLoading] = useState(false);
	const [summaryLoading, setSummaryLoading] = useState(false);
	const { addSnack } = useSnackbar();
	const { invoiceId, lineItemId } = useParams();
	const { lineItemsData } = useSelector((state) => state?.invoice);
	const { state: { fromMatter, currentMatterTab } = {} } = useLocation();
	const matterDetail = useSelector((state) => state?.matter?.matterDetail);
	const dispatch = useDispatch();
	const { subLineItems } = lineItemsData;
	const activeSubLineItem = subLineItems?.filter(subLineItem => subLineItem?.isActive === true)?.[0];
	const [metaLineItem, setMetaLineItem] = useState({});
	const [isEditable, setIsEditable] = useState(false);
	const history = useHistory();

	//Net Amount
	const [calculatedNetAmount, setCalculatedNetAmount] = useState(0);
	const [finalNetAmount, setFinalNetAmount] = useState(0);
	//Units
	const [changeUnits, setChangeUnits] = useState(0);
	const [unitsAdjustmentType, setUnitsAdjustmentType] = useState('increaseByUnits');
	const [finalUnits, setFinalUnits] = useState(0);
	//Rate
	const [changeRate, setChangeRate] = useState(0);
	const [rateAdjustmentType, setRateAdjustmentType] = useState('setToAmount');
	const [finalRate, setFinalRate] = useState(0);
	const [adjustmentIcon, setAdjustmentIcon] = useState('dollar');
  //Discounts
	const [changeDiscounts, setChangeDiscounts] = useState(0);
	const [discountsAdjustmentType, setDiscountsAdjustmentType] = useState('setToAmount');
	const [finalDiscounts, setFinalDiscounts] = useState(0);
  //Adjustments
	const [finalAdjustments, setFinalAdjustments] = useState(0);

  //Reason
	const [reason, setReason] = useState('');

  const masterDetails = useSelector(state => state?.masterData?.masterDatas);
  const [selectedUTBMSDesc, setSelectedUTBMSDesc] = useState('')
  const [selectedActivityDesc, setSelectedActivityDesc] = useState('');
  const utbmsLookup = [...masterDetails?.expenseCode?.masterData || '', ...masterDetails?.phaseCode?.masterData || '', ...masterDetails?.taskCode?.masterData || '']
  const actLookup = [...masterDetails?.activityCode?.masterData || ''];
	const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.financial?.invoice?.details?.tabs?.lineItems);
	const adjustedLineItemsPaginationDetail = useSelector((state) => state?.invoice?.adjustedLineItemsPaginationDetail);
  const nonAdjustedLineItemsPaginationDetail = useSelector((state) => state?.invoice?.nonAdjustedLineItemsPaginationDetail);

  /**
   * Function to get description of UTBMS and Activity code
   * @param {*} lookup
   * @param {*} code
   * @returns
   */
  const getCodedesc = (lookup, code) => {
    const selectedItem = lookup?.filter(obj => obj?.value === code)[0];
    return selectedItem?.description || '';
  }

  useEffect(() => {
    if(metaLineItem?.hasOwnProperty("utbmsCode") || metaLineItem?.hasOwnProperty("activityCode")) {
      setSelectedUTBMSDesc(getCodedesc(utbmsLookup, metaLineItem?.utbmsCode));
      setSelectedActivityDesc(getCodedesc(actLookup, metaLineItem?.activityCode));
    }
	if(metaLineItem?.hasOwnProperty("timekeeperEmail") && !metaLineItem?.hasOwnProperty("classification")){
		getPeopleClassification()
	}
  }, [metaLineItem])

   // Disable edit for invoice level edited line item (without rate and unit)
   useEffect(() => {
    if(Object.keys(activeSubLineItem??{}).length > 0) {
      //to identify IE and IF type: unit will will be 1, rate will be 0 and utbms will be undefined
      const isDiscountType = activeSubLineItem?.unit == 1 && activeSubLineItem?.rate == 0 && activeSubLineItem?.utbmsCode === undefined
		const editConditions = activeSubLineItem?.unit !== undefined &&  // for overall invoice edit
		activeSubLineItem?.rate !== undefined && // check if unit , rate is not present
		activeSubLineItem?.description !== ADJUSTED_LINE_ITEM_DESCRIPTION // and description is system generated
		&& !isDiscountType;
        setIsEditable(Boolean(editConditions));
    }
  }, [activeSubLineItem])

	/**
	* Function for opening the edit toggle
	**/
	const toggleOpen = id => () => setIsOpen(
		isOpen => isOpen === id ? null : id,
	);

	const handleCancelToggle = (id, name, value) => {
		setIsOpen(null);
		reasonChange(name, value);
		setCalculatedNetAmount(0);
	}

	const resetAllEditValues = () => {
		setIsOpen(null);
		setFinalRate(0);
		setFinalUnits(0);
		setFinalNetAmount(0);
    setFinalDiscounts(0);
    setFinalAdjustments(0);
		setChangeUnits(0);
		setChangeRate(0);
    setChangeDiscounts(0);
		setCalculatedNetAmount(0);
    setReason('');
	}

	/**
	* Function for opening the block restore button
	**/
	const toggleButton = id => () => setBlockOpen(
		blockOpen => blockOpen === id ? null : id,
	);

	/**
   * This function is called when api call throws an error
   */
	 const handleError = (error) => {
		setLoading(false);
		  addSnack({
			message: getErrorMessage(error),
		  });
	  }

	/*
	* The function will be used for getting the invoice Detail
	*/
	const getInvoiceData = () => {
		setSummaryLoading(true)
		getService(INVOICE_SERVICE, `/invoices/v1/${invoiceId}/summary`)
			.then((res) => {
				const data = res?.data;
				setInvoiceDetail(data);
				setSummaryLoading(false)
			})
			.catch((error) => {
				setSummaryLoading(false)
				handleError(error)
			});
	}

	/*
	* The function will be used for getting the Line Items Detail
	*/
	const getLineItemsData = () => {
		getService(INVOICE_SERVICE, `/invoices/v1/line-items/${lineItemId}/sub-line-items`)
			.then((res) => {
				const data = res?.data;
				setLoading(false)
				setMetaLineItem(data?.subLineItems?.[0]);
				dispatch(setLineItemsData(data));
			})
			.catch((error) => {
				handleError(error)
			});
	}

	useEffect(() => {
		setLoading(true);
		setLineItemsData({})
		getInvoiceData();
		getLineItemsData();
	}, [])

	/**
	 * Gets called when clicked on Restore button.
	 */
	const restoreLineItem = (parentLineItemId, lineItemId) => {
		const body = {
			parentLineItemId: parentLineItemId,
			lineItemId: lineItemId
		};
		setLoading(true);
		const filteredBody=filterPayload(body)
		patchService(INVOICE_SERVICE, `/invoices/v1/${activeSubLineItem?.invoiceMasterId}/sub-line-items/restore`, filteredBody)
			.then((res) => {
				setLoading(false);
				getLineItemsData();
				addSnack({
					message: RESTORE_SUCCCESS_MESSAGE,
				});
			})
			.catch((error) => {
				handleError(error)
			});
	};

	/*
* The function will use for selecting reason
*/
	const reasonChange = (type, value) => {
		if (type === 'rate') {
			setChangeRate(0);
			setFinalRate(0);
			setRateAdjustmentType(value);
		} else if (type === 'units') {
			setChangeUnits(0);
			setFinalUnits(0);
			setUnitsAdjustmentType(value);
		} else if (type === 'discounts') {
			setChangeDiscounts(0);
			setFinalDiscounts(0);
			setDiscountsAdjustmentType(value);
		}
		if (value === 'increaseByPercentage' || value === 'decreaseByPercentage') {
			setAdjustmentIcon('percent')
		} else {
			setAdjustmentIcon('dollar')
		}
	}

	/*
* The function will use for setting the form values
*/
	const valueChange = (type, value) => {
		let prevValue;
		let adjustment;
		let finalValue;
    switch(type) {
      case 'rate':
        prevValue = activeSubLineItem?.rate;
        finalValue = calculateAmount(rateAdjustmentType, value, prevValue);
        setFinalRate(finalValue);
        break;
      case 'units':
        prevValue = activeSubLineItem?.unit;
        finalValue = calculateAmount(unitsAdjustmentType, value, prevValue);
        setFinalUnits(finalValue);
        break;
      case 'discounts':
        adjustment = discountsAdjustmentType;
        if (adjustment === 'increaseByPercentage' || adjustment === 'decreaseByPercentage') {
          // if discount already applied, then consider the applied value, else consider as 0
          prevValue = calculateNetAmount(activeSubLineItem?.discountValue === 0.0, false);
        } else if (adjustment === 'increaseByAmount' || adjustment === 'decreaseByAmount') {
          prevValue = 0;
        } else {
          prevValue = (calculatedNetAmount === 0) ? activeSubLineItem?.netAmount : calculatedNetAmount;
        }
        finalValue = calculateAmount(adjustment, Number(value), prevValue);
        if (adjustment === 'increaseByPercentage' || adjustment === 'decreaseByPercentage') {
          finalValue = finalValue - prevValue;
        }
        const lineItemDiscount = activeSubLineItem?.discountValue ?? 0;
        const totalDiscount = Number(finalValue) + Number(lineItemDiscount);
        setFinalDiscounts(-1 * totalDiscount);
        break;
    }
	}

  const calculateNetAmount = (noDiscounts=false, noAdjustments=false) => {
		const unit = (finalUnits === 0) ? activeSubLineItem?.unit ?? 0 : finalUnits;
		const rate = (finalRate === 0) ? activeSubLineItem?.rate ?? 0 : finalRate;
		const discount = (finalDiscounts === 0) ? activeSubLineItem?.discountValue ?? 0 : finalDiscounts;
		const adjustments = activeSubLineItem?.adjustmentValue ?? 0;

		const finalNetAmount = (unit * rate) + (noDiscounts ? 0 : discount) + (noAdjustments ? 0 : adjustments);
		return Number(finalNetAmount?.toFixed(2))
	}

	/**
	 * This will use to calculate net amount
	 */
	useEffect(() => {
		const netAmount = calculateNetAmount();
		if (netAmount > 0) {
			setCalculatedNetAmount(netAmount);
		} else {
			setChangeDiscounts(0)
			setFinalDiscounts(0)
			setFinalAdjustments(0)
			if (activeSubLineItem?.netAmount > 0 && netAmount <= 0) {
				addSnack({
					message: "Net Amount should be greater than 0",
				});
			}
		}
	}, [finalUnits])

	useEffect(() => {
		const netAmount = calculateNetAmount();
		if (netAmount > 0) {
			setCalculatedNetAmount(netAmount);
		} else {
			setChangeDiscounts(0)
			setFinalDiscounts(0)
			setFinalAdjustments(0)
			if (activeSubLineItem?.netAmount > 0 && netAmount <= 0) {
				addSnack({
					message: "Net Amount should be greater than 0",
				});
			}
		}
	}, [finalRate])

  useEffect(() => {
		const netAmount = calculateNetAmount();
		if(netAmount > 0 ) {
			setCalculatedNetAmount(netAmount);
		} else {
			setChangeDiscounts(0)
			setFinalDiscounts(0)
			if(activeSubLineItem?.netAmount > 0 && netAmount <=0) {
				addSnack({
					message: "Net Amount should be greater than 0",
				});
			}
		}
	}, [finalDiscounts])

  // Reset lineItemsData from store to prevent flashing of previous values
	useEffect(() => {
		return () => {
			dispatch(setLineItemsData([]));
		}
	}, [])

	/**
	 * Function will use to create new Sub-Line Item
	 */
	const createSubLineItem = () => {
		const body = {
			lineItemType: metaLineItem?.lineItemType,
			lineItemDate: metaLineItem?.lineItemDate,
			description: activeSubLineItem?.description,
			utbmsCode: metaLineItem?.utbmsCode,
			activityCode: metaLineItem?.activityCode,
			unit: (finalUnits === 0) ? activeSubLineItem?.unit : finalUnits,
			rate: (finalRate === 0) ? activeSubLineItem?.rate : finalRate,
			tkId: activeSubLineItem?.tkId,
			timekeeperUserId: activeSubLineItem?.timekeeperUserId,
			timekeeperEmail: activeSubLineItem?.timekeeperEmail,
			timekeeperName: activeSubLineItem?.timekeeperName,
			adjustmentValue: (finalAdjustments === 0) ? activeSubLineItem?.adjustmentValue : finalAdjustments,
			reason: reason,
			comment: comment,
			discountType: "",
			discountValue: (finalDiscounts === 0) ? activeSubLineItem?.discountValue : finalDiscounts,
			parentId: activeSubLineItem?.parentId
		};
		setLoading(true);
		const filteredBody=filterPayload(body)
		postService(INVOICE_SERVICE, `/invoices/v1/${activeSubLineItem?.invoiceMasterId}/sub-line-items`, filteredBody)
			.then((res) => {
				setLoading(false);
				getLineItemsData();
				resetAllEditValues();
				setPaginationItems(metaLineItem?.lineItemType)
				setComment('');
				addSnack({
					message: "Submitted successfully",
				});
			})
			.catch((error) => {
				handleError(error)
			});
	}

	const setPaginationItems = (lineItemType) => {
		if(lineItemType == 'fee'){
			if(adjustedLineItemsPaginationDetail?.adjustedFeeCount !== undefined || adjustedLineItemsPaginationDetail?.adjustedFeeCount !== 0){
				const currpage = ((adjustedLineItemsPaginationDetail?.adjustedFeeCount - 1)%adjustedLineItemsPaginationDetail?.rowsPerPage)
        const zeroPageCheck = (adjustedLineItemsPaginationDetail?.currentPage !== 0)?(adjustedLineItemsPaginationDetail?.currentPage-1):0;
        dispatch(setAdjustedPaginationForLineItems({
					currentPage: (currpage === 0)?zeroPageCheck:adjustedLineItemsPaginationDetail?.currentPage,
					rowsPerPage: adjustedLineItemsPaginationDetail?.rowsPerPage,
					tabName: adjustedLineItemsPaginationDetail?.tabName,
					adjustedFeeCount: adjustedLineItemsPaginationDetail?.adjustedFeeCount - 1,
					adjustedExpenseCount: adjustedLineItemsPaginationDetail?.adjustedExpenseCount
				}))
			}
			if(nonAdjustedLineItemsPaginationDetail?.adjustedFeeCount !== undefined || nonAdjustedLineItemsPaginationDetail?.adjustedFeeCount !== 0){
        const currpage = ((nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount - 1)%nonAdjustedLineItemsPaginationDetail?.rowsPerPage);
        const zeroPageCheck = (nonAdjustedLineItemsPaginationDetail?.currentPage !== 0)?(nonAdjustedLineItemsPaginationDetail?.currentPage-1):0;
				dispatch(setNonAdjustedPaginationForLineItems({
					currentPage: (currpage === 0)?zeroPageCheck:nonAdjustedLineItemsPaginationDetail?.currentPage,
					rowsPerPage: nonAdjustedLineItemsPaginationDetail?.rowsPerPage,
					tabName: nonAdjustedLineItemsPaginationDetail?.tabName,
					nonAdjustedFeeCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount -1,
					nonAdjustedExpenseCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount
				}))
			}
		}else{
			if(adjustedLineItemsPaginationDetail?.adjustedExpenseCount !== undefined || adjustedLineItemsPaginationDetail?.adjustedExpenseCount !== 0){
        const currpage = ((adjustedLineItemsPaginationDetail?.adjustedExpenseCount - 1)%adjustedLineItemsPaginationDetail?.rowsPerPage)
        const zeroPageCheck = (adjustedLineItemsPaginationDetail?.currentPage !== 0)?(adjustedLineItemsPaginationDetail?.currentPage-1):0;
        dispatch(setAdjustedPaginationForLineItems({
					currentPage: (currpage === 0)?zeroPageCheck:adjustedLineItemsPaginationDetail?.currentPage,
					rowsPerPage: adjustedLineItemsPaginationDetail?.rowsPerPage,
					tabName: adjustedLineItemsPaginationDetail?.tabName,
					adjustedFeeCount: adjustedLineItemsPaginationDetail?.adjustedFeeCount,
					adjustedExpenseCount: adjustedLineItemsPaginationDetail?.adjustedExpenseCount - 1
				}))
			}
			if(nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount !== undefined || nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount !== 0){
				const currpage = ((nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount - 1)%nonAdjustedLineItemsPaginationDetail?.rowsPerPage)
        const zeroPageCheck = (nonAdjustedLineItemsPaginationDetail?.currentPage !== 0)?(nonAdjustedLineItemsPaginationDetail?.currentPage-1):0;
        dispatch(setNonAdjustedPaginationForLineItems({
					currentPage: (currpage === 0)?zeroPageCheck:nonAdjustedLineItemsPaginationDetail?.currentPage,
					rowsPerPage: nonAdjustedLineItemsPaginationDetail?.rowsPerPage,
					tabName: nonAdjustedLineItemsPaginationDetail?.tabName,
					nonAdjustedFeeCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount,
					nonAdjustedExpenseCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount -1
				}))
			}
		}
	}

	const discardChanges = () => {
		setChangeUnits(0);
		setChangeRate(0);
    setChangeDiscounts(0);
		history.goBack()
	}

	/**
	 * Function to call associate people list Service and set timekeeper Classification
	 * @param {String} text
	 */
	const getPeopleClassification = () => {
	  setTKClassificationLoading(true)
	  const tkEmail = metaLineItem?.timekeeperEmail
	  const defaultQuery = {
		"filters": [
		  formCsFilterObject("isExternalUser", "match", true),
		  formCsFilterObject("roleCodes", "raw", "OC_TIMEKEEPER"),
		  formCsFilterObject("emailId", "match", tkEmail),
		],
		"operation": "AND",
		"properties": null
	  }
	  const query = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, {}, {}, {}, defaultQuery);
	  postService(
		COMPOSITE_SEARCH_SERVICE,
		`/composite-search/v1?returnCsv=false`,
		query
	  )
		.then((res) => {
		  setTKClassificationLoading(false)
		  const classification = res?.data?.people?.data[0]?.classification;
		  setMetaLineItem({...metaLineItem,classification});
		})
		.catch((error) => {
		  setTKClassificationLoading(false);
		});
	};
	
	return (
		<div className={classes.container}>
      {((finalNetAmount !== 0 || finalUnits !== 0 || finalRate !== 0 || finalDiscounts !== 0 || finalAdjustments !== 0  ) ?
				<div className={classes.unsaveSection} data-testid="unsave-change-dialog">
					<h3 className={classes.unsaveText}>Unsaved Changes</h3>
					<div className={classes.unsaveButtons}>
						<Button
							className={classes.discardButtons}
							size="small"
							variant="secondary"
							data-testid="discard-btn"
							onClick={discardChanges}
						>
							{DISCARD}
						</Button>
						<Button
							size="small"
							variant="primary"
							data-testid="save-btn"
							id="save-btn"
							onClick={createSubLineItem}
							disabled={(finalNetAmount === 0
								&& finalUnits === 0
								&& finalRate === 0
								&& finalDiscounts === 0
								&& finalAdjustments === 0)}
						>
							{loading ? <Spinner color="white" size="small" className={classes.spinner}/> : SAVE}
						</Button>
					</div>
				</div> :
				<></>
			)}
			<div className={classes.breadcrumbWrapper}>
					{fromMatter ?
						<Breadcrumb>
							<BreadcrumbItem
								data-testid="invoice-nav-matter"
								onClick={() => history.push(`/`)}
							>
								Matters</BreadcrumbItem>
							<BreadcrumbItem
								data-testid="invoice-nav-matter-detail"
								onClick={() => history.push(`/matter/${matterDetail?.matter?.identifier}`)}
							>
								#{matterDetail?.matter?.matterNumber}</BreadcrumbItem>
							<BreadcrumbItem
								data-testid="invoice-nav-matter-invoice-list"
								onClick={() => history.push(`/matter/${matterDetail?.matter?.identifier}`,
									{ currentMatterTab: currentMatterTab, currentFinancialTab: 1 })}>Financials</BreadcrumbItem>
							<BreadcrumbItem
								data-testid="lineitem-invoice-number"
								onClick={() => history.push(`/invoice/${invoiceDetail?.id}`)}
							>
								#{invoiceDetail?.organizationInvoiceNumber}
							</BreadcrumbItem>
									<BreadcrumbItem
										data-testid="lineitem-invoice-description"
										isCurrent>
										{renderTernary(activeSubLineItem?.description, activeSubLineItem?.description, 'Line Item')}
									</BreadcrumbItem>
							</Breadcrumb>
						: <Breadcrumb>
							<BreadcrumbItem
								data-testid="lineitem-financial"
								onClick={() => history.push(`/financial`, { currentFinancialTab: 1 })}>
								Financial
							</BreadcrumbItem>
							<BreadcrumbItem
								data-testid="lineitem-invoice-number"
								onClick={() => history.push(`/invoice/${invoiceDetail?.id}`)}
							>
								#{invoiceDetail?.organizationInvoiceNumber}
							</BreadcrumbItem>
									<BreadcrumbItem
										data-testid="lineitem-invoice-description"
										isCurrent>
										{renderTernary(activeSubLineItem?.description, activeSubLineItem?.description, 'Line Item')}
									</BreadcrumbItem>
						</Breadcrumb>
					}
			</div>
			<Grid>
				<GridColumn sm={12} md={9} lg={9}>
					<div className={classes.leftWrapper}>
						<Grid >
							<GridColumn sm={12} md={12} lg={12}>
								{
									loading?
									<div className={classes.lineItemHeading}>
										<Skeleton height="26px" variant="rectangle" />
									</div>:
									<ShowDescription activeSubLineItem={activeSubLineItem}/>
								}
								<div className={classes.subHeading}>
									<Heading as="h1" size="small" weight={700}>
										Line Item #{
											loading?
											<span><Skeleton width="20px" height="20px" variant="rectangle" /></span>:
											(lineItemsData?.subLineItems?.map((el) => el.id).indexOf(activeSubLineItem?.id))+1
										}
									</Heading>
								</div>
							</GridColumn>
						</Grid>

						<div className={classes.lineItemDetail}>
							<Grid>
								<GridColumn sm={12} md={12} lg={12}>
									<Heading as="h1" size="small" weight={700}>
										Line Item Details
									</Heading>
								</GridColumn>
							</Grid>
							<div className={classes.divider}>
								<Divider />
							</div>
							<div className={classes.whiteRow}>
								<Grid>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemLeftText}>
											$ Net Amount
										</div>
									</GridColumn>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemRightText} id="total-net-amount">
											{calculatedNetAmount === 0 ? activeSubLineItem?.netAmount : parseFloat(calculatedNetAmount).toFixed(2)}
										</div>
									</GridColumn>
								</Grid>
							</div>
							<div className={classes.whiteRow}>
								<Grid>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemLeftText}>
											Units
										</div>
									</GridColumn>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemRightText}>
											{
												(isOpen === 1) ?
													<span
														className={classes.editCancel}
														onClick={() => handleCancelToggle(1, 'units', 'increaseByUnits')}
														data-testid="unit-edit-cancel-btn"
													>{CANCEL}</span> :
													renderTernary(invoiceDetail?.status?.toLowerCase() === "appealed" && isEditable,
													<span
														className={classes.editIcon}
														onClick={toggleOpen(1)}
													>
														<Pencil size="small" data-testid="unit-edit-btn" id="unit-edit-btn"/>
													</span>,<></>)

											}
											{finalUnits === 0 ? activeSubLineItem?.unit : finalUnits}
										</div>
									</GridColumn>
								</Grid>
								{
									(isOpen === 1) ?
										<div className={classes.greyRowForEdit}>
											<div className={classes.modalWhiteRow}>

												<Heading as="h1" size="small" weight={700}>
													Adjust Units
												</Heading>
												<Grid>
													<GridColumn sm={12} md={12} lg={12}>
														<div>
															<p className={classes.accordionText}>Adjustments Type</p>
															<Select data-testid="adjustments-reason-units"
																onChange={(e) => reasonChange(e.target.name, e.target.value)}
																name={"units"}
																id={"units-types"}
																fullWidth={true}
															>
																<MenuItem id="units-types-option-0" value="increaseByUnits">Increase By Units </MenuItem>
																<MenuItem id="units-types-option-1" value="decreaseByUnits">Decrease By Units </MenuItem>
															</Select>
														</div>
														<div>
															<p className={classes.accordionText}>Value</p>
															<TextField
																label={false}
																type="number"
																value={changeUnits}
																onKeyDown={(e) => onInputNumberKeyDown(e)}
																onChange={(e) => {
																	setChangeUnits(e.target.value)
																	valueChange('units', e.target.value)
																}}
																data-testid="value"
																id="unit-value"
																onWheel={(e) => { e.target.blur() }}
																fullWidth={true}
															/>
														</div>
														<div>
															<p>Update</p>
															<p>
                                {renderTernary(activeSubLineItem?.unit, activeSubLineItem?.unit, 0)}
																<ArrowRight size="small" />
																<span className={classes.boldText}>{finalUnits}</span>
															</p>
														</div>
													</GridColumn>
												</Grid>
											</div>
										</div> :
										<></>
								}

							</div>
							<div className={classes.whiteRow}>
								<Grid>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemLeftText}>
											$ Rate
										</div>
									</GridColumn>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemRightText}>
											{
												(isOpen === 2) ?
													<span
														className={classes.editCancel}
														onClick={() => handleCancelToggle(2, 'rate', 'setToAmount')}
														data-testid="rate-edit-cancel-btn"
													>{CANCEL}</span> :
													renderTernary(invoiceDetail?.status?.toLowerCase() === "appealed" && isEditable,
													<span
														className={classes.editIcon}
														onClick={toggleOpen(2)}
														id="rate-edit-btn"
													>
														<Pencil size="small" data-testid="rate-edit-btn" />
													</span>,<></>)
											}
											{renderTernary(finalRate === 0, activeSubLineItem?.rate, finalRate)}
										</div>
									</GridColumn>
								</Grid>

								{
									(isOpen === 2) ?
										<div className={classes.greyRowForEdit}>
											<div className={classes.modalWhiteRow}>

												<Heading as="h1" size="small" weight={700}>
													$ Adjust Rate
												</Heading>
												<Grid>
													<GridColumn sm={12} md={12} lg={12}>
														<div>
															<p className={classes.accordionText}>Adjustments Type</p>
															<Select data-testid="adjustments-reason-rate"
																onChange={(e) => reasonChange(e.target.name, e.target.value)}
																name= "rate"
																id= "rate-type"
																fullWidth={true}
															>
																<MenuItem value="increaseByPercentage">Increase By Percentage</MenuItem>
																<MenuItem value="decreaseByPercentage">Decrease By Percentage</MenuItem>
																<MenuItem value="increaseByAmount">Increase By Amount</MenuItem>
																<MenuItem value="decreaseByAmount">Decrease By Amount</MenuItem>
																<MenuItem selected value="setToAmount">Set to Amount</MenuItem>
															</Select>
														</div>
														<div>
															<p className={classes.accordionText}>Value</p>
															<TextField
																label={false}
																type="number"
																value={changeRate}
																onKeyDown={(e) => onInputNumberKeyDown(e)}
																onChange={(e) => {
																	setChangeRate(e.target.value)
																	valueChange('rate', e.target.value)
																}}
																leadingIcon={adjustmentIcon === 'percent' ? <Percentage size="small" /> : <Money size="small" />}
																data-testid="rate-value"
																id="rate-value"
																onWheel={(e) => { e.target.blur() }}
																fullWidth={true}
															/>
														</div>
														<div>
															<p>Update</p>
															<p>
																{renderTernary(activeSubLineItem?.rate, activeSubLineItem?.rate, 0)}
																<ArrowRight size="small" />
																<span className={classes.boldText}>{finalRate}</span>
															</p>
														</div>
													</GridColumn>
												</Grid>
											</div>
										</div> :
										<></>
								}
							</div>
							<div className={classes.whiteRow}>
								<Grid>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemLeftText}>
											$ Discounts
										</div>
									</GridColumn>
                  <GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemRightText}>
											{
												(isOpen === 3) ?
													<span
														className={classes.editCancel}
														onClick={() => handleCancelToggle(3, 'discounts', 'setToAmount')}
														data-testid="discounts-edit-cancel-btn"
													>{CANCEL}</span> :
													renderTernary((invoiceDetail?.status?.toLowerCase() === "appealed" && isEditable && accessibility?.editDiscount),
													<span
														className={classes.editIcon}
														onClick={toggleOpen(3)}
													>
														<Pencil size="small" data-testid="discounts-edit-btn" id="discounts-edit-btn"/>
													</span>,<></>)
											}
											{finalDiscounts === 0 ? amountFormat(activeSubLineItem?.discountValue) : amountFormat(finalDiscounts)}
										</div>
									</GridColumn>
								</Grid>
                {(isOpen === 3) ?
                  <div className={classes.greyRowForEdit}>
                    <div className={classes.modalWhiteRow}>
                      <Heading as="h1" size="small" weight={700}>
                        Adjust Discounts
                      </Heading>
                      <Grid>
                        <GridColumn sm={12} md={12} lg={12}>
                          <div>
                            <p className={classes.accordionText}>Adjustments Type</p>
                            <Select data-testid="adjustments-reason-discounts"
                              onChange={(e) => reasonChange(e.target.name, e.target.value)}
	    					  name= "discounts"
							  fullWidth={true}
                            >
                              <MenuItem value="increaseByPercentage">Increase By Percentage</MenuItem>
                              <MenuItem value="decreaseByPercentage">Decrease By Percentage</MenuItem>
                              <MenuItem value="increaseByAmount">Increase By Amount</MenuItem>
                              <MenuItem value="decreaseByAmount">Decrease By Amount</MenuItem>
                              <MenuItem selected value="setToAmount">Set to Amount</MenuItem>
                            </Select>
                          </div>
                          <div>
                            <p className={classes.accordionText}>Value</p>
                            <TextField
                              label={false}
                              type="number"
                              value={changeDiscounts}
															onKeyDown={(e) => onInputNumberKeyDown(e)}
                              onChange={(e) => {
                                setChangeDiscounts(e.target.value)
                                valueChange('discounts', e.target.value)
                              }}
                              leadingIcon={adjustmentIcon === 'percent' ? <Percentage size="small" /> : <Money size="small" />}
                              data-testid="discounts-value"
							  id="discounts-value"
							  onWheel={(e) => { e.target.blur() }}
							  fullWidth={true}
                            />
                          </div>
                          <div>
                            <p>Update Net Amount</p>
                            <p>
															{amountFormat(calculateNetAmount(true, false))}
                              <ArrowRight size="small" />
                              <span className={classes.boldText}>{calculatedNetAmount === 0 ? amountFormat(activeSubLineItem?.netAmount) : amountFormat(calculatedNetAmount)}</span>
                            </p>
                          </div>
                        </GridColumn>
                      </Grid>
                    </div>
                  </div> :
                  <></>
								}
							</div>
							<div className={classes.whiteRow}>
								<Grid>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemLeftText}>
											$ Adjustments
										</div>
									</GridColumn>
                  <GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemRightText}>
											{renderTernary(finalAdjustments === 0, activeSubLineItem?.adjustmentValue, finalAdjustments)}

										</div>
									</GridColumn>
								</Grid>
							</div>
							<div className={classes.divider}>
								<Divider />
							</div>
							<div className={classes.whiteRow}>
								<Grid>
									<GridColumn sm={12} md={6} lg={6}>
										Details
									</GridColumn>
								</Grid>
								<div className={classes.divider}>
									<Divider />
								</div>
								<div>
									<p>Adjustments Reason</p>
									<MasterDataAutocomplete
										value={reason}
										setValue={(value)=>{setReason(value?.value)}}
										dataType={"invoiceAdjustmentReason"}
										testid="adjustments-reason"
										placeholderValue={SELECT_ADJUSTMENT_REASON}
									/>
								</div>
								<div>
									<p>Comment</p>
									<TextField
										label={false}
										type="text"
										value={comment}
										onChange={(e) => setComment(e.target.value)}
										data-testid="comment"
										id="comment"
										fullWidth={true}
									/>
								</div>
							</div>
						</div>

						<div>
							{(subLineItems?.length >= 1) ?
								<div className={classes.versionHeader}>
									<Grid>
										<GridColumn sm={12} md={8} lg={9}> <Heading as="h1" size="small" weight={500}>Current Version</Heading></GridColumn>
										<GridColumn sm={12} md={1} lg={1}>Unit</GridColumn>
										<GridColumn sm={12} md={1} lg={1}>$ Rate</GridColumn>
										<GridColumn sm={12} md={2} lg={1}>$ Net Amt</GridColumn>
									</Grid>
								</div> : <></>}

							{(subLineItems?.map((activeSubLineItem, index) =>
								<div key={activeSubLineItem.id}>
									{
										(activeSubLineItem?.isActive === true) ?
											<div className={classes.currentVersionDetail} data-testid="version-div">
												<Grid>
                          <GridColumn sm={12} md={8} lg={9}>
                            <Heading as="h1" size="small" weight={500} id="current-version-description">
                              {renderTernary(activeSubLineItem?.description, activeSubLineItem?.description, "No Description Available")}
                            </Heading>
                          </GridColumn>
                          <GridColumn sm={12} md={1} lg={1} id="current-version-unit">
                            {renderTernary(activeSubLineItem?.unit, activeSubLineItem?.unit, 0)}
                          </GridColumn>
                          <GridColumn sm={12} md={1} lg={1} id="current-version-rate">
                            {renderTernary(activeSubLineItem?.rate, activeSubLineItem?.rate, 0)}
                          </GridColumn>
													<GridColumn sm={12} md={2} lg={1}>
														<Grid>
                              <GridColumn sm={12} md={12} lg={12} id="current-version-netAmount">
                                {renderTernary(activeSubLineItem?.netAmount, activeSubLineItem?.netAmount, 0)}
                              </GridColumn>
                              <GridColumn sm={12} md={12} lg={12} id="current-version-discountValue">
                                ({renderTernary(activeSubLineItem?.discountValue, activeSubLineItem?.discountValue, 0)})
                              </GridColumn>
                              <GridColumn sm={12} md={12} lg={12} id="current-version-adjustmentValue">
                                ({renderTernary(activeSubLineItem?.adjustmentValue, activeSubLineItem?.adjustmentValue, 0)})
                              </GridColumn>
														</Grid>
													</GridColumn>
												</Grid>
												<div className={classes.lineItemText}>
													<Grid>
														<GridColumn sm={12} md={12} lg={12} id="current-version-reason">
                              {renderTernary(activeSubLineItem?.reason, activeSubLineItem?.reason, NO_REASON_AVAILABLE)}
                            </GridColumn>
													</Grid>
												</div>
												<div className={classes.lineItemText}>
													<Grid>
														<GridColumn sm={12} md={12} lg={12} id="current-version-comment">
                              {renderTernary(activeSubLineItem?.comment, activeSubLineItem?.comment, NO_COMMENT_AVAILABLE)}
                            </GridColumn>
													</Grid>
												</div>
												<div className={classes.lineItemText}>
													<Grid>
														<GridColumn sm={12} md={10} lg={11} id="current-version-createdTs">
															<strong id="current-version-createdBy">
                                {renderTernary(activeSubLineItem?.createdBy, activeSubLineItem?.createdBy, "-") }
                              </strong>
														  {` Submitted on ${displayLocalDate(activeSubLineItem?.createdTs)}`}
                            </GridColumn>
														<GridColumn sm={12} md={2} lg={1}>-</GridColumn>
													</Grid>
												</div>
											</div> :
											<></>
										}
								</div>))}
						</div>

						<div>
							{(subLineItems?.length > 1) ?
								<div className={classes.versionHeader}>
									<Grid>
										<GridColumn sm={12} md={8} lg={9}> <Heading as="h1" size="small" weight={500} id="Previous-Edits">Previous Edits</Heading></GridColumn>
									</Grid>
								</div> : <></>}

							{subLineItems?.map((inActiveSubLineItem, idx) =>
								<div key={inActiveSubLineItem?.id}>
									{
										(inActiveSubLineItem?.isActive === false) ?
											<div className={classes.previousVersionDetail} onClick={toggleButton(idx)} data-testid="togglebutton">
												<Grid id={"previous-version"}>
													<GridColumn sm={12} md={8} lg={9}> <Heading as="h1" size="small" weight={500} id={`previous-version-description-${idx}`}>
														{renderTernary(inActiveSubLineItem?.description, inActiveSubLineItem?.description, "No Description Available")}
														</Heading></GridColumn>
													<GridColumn sm={12} md={1} lg={1} id={`previous-version-unit-${idx}`}>
                            {renderTernary(inActiveSubLineItem?.unit, inActiveSubLineItem?.unit, 0)}
                          </GridColumn>
													<GridColumn sm={12} md={1} lg={1} id={`previous-version-rate-${idx}`}>
                            {renderTernary(inActiveSubLineItem?.rate, inActiveSubLineItem?.rate, 0)}
                          </GridColumn>
													<GridColumn sm={12} md={2} lg={1}>
														<Grid>
															<GridColumn sm={12} md={12} lg={12} id={`previous-version-netAmount-${idx}`}>
                                {renderTernary(inActiveSubLineItem?.netAmount, inActiveSubLineItem?.netAmount, 0)}
                              </GridColumn>
															<GridColumn sm={12} md={12} lg={12} id={`previous-version-discountValue-${idx}`}>
                                ({renderTernary(inActiveSubLineItem?.discountValue, inActiveSubLineItem?.discountValue, 0)})
                              </GridColumn>
															<GridColumn sm={12} md={12} lg={12} id={`previous-version-adjustmentValue-${idx}`}>
                                ({renderTernary(inActiveSubLineItem?.adjustmentValue, inActiveSubLineItem?.adjustmentValue, 0)})
                              </GridColumn>
														</Grid>
													</GridColumn>
												</Grid>
												<div className={classes.lineItemText}>
													<Grid>
														<GridColumn sm={12} md={12} lg={12} id={`previous-version-reason-${idx}`}>
                              {renderTernary(inActiveSubLineItem?.reason, inActiveSubLineItem?.reason, "No Reason Available")}
                            </GridColumn>
													</Grid>
												</div>
												<Grid>
													<GridColumn sm={12} md={10} lg={11} id={`previous-version-createdTs-${idx}`}>
														<strong id={`previous-version-createdBy-${idx}`}>
                              {renderTernary(inActiveSubLineItem?.createdBy, inActiveSubLineItem?.createdBy, "-")}
														</strong>
                            {` Submitted on ${displayLocalDate(inActiveSubLineItem?.createdTs)}`}
                          </GridColumn>
													<GridColumn sm={12} md={2} lg={1}>-</GridColumn>
												</Grid>
												<div className={classes.button}>
													{blockOpen === idx ?
														<div>
															<div className={classes.divider}>
																<Divider />
															</div>
															<Button
																size="medium"
																variant="primary"
																onClick={() => restoreLineItem(inActiveSubLineItem?.parentId, inActiveSubLineItem?.id)}
																data-testid="restore-btn"
																id={`restore-btn-${idx}`}
																disabled = {invoiceDetail?.status?.toLowerCase() !== LABEL_APPEALED || summaryLoading}
															>
																{summaryLoading ? <Spinner size="small" color="white" /> : RESTORE}
															</Button>
														</div> : <></>}
												</div>
											</div> : <></>}
								</div>)}
						</div>
					</div>
				</GridColumn>
				<GridColumn sm={12} md={3} lg={3}>
					<div className={classes.rightWrapper}>
						<Grid>
							<GridColumn><Heading as="h6" size="small" weight="700">
								<div className={classes.metaDataText}>
									Metadata
								</div>
							</Heading></GridColumn>
						</Grid>
						<Grid>
							<GridColumn>
								<div className={classes.divider}>
									<Divider />
								</div></GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={3}>
								<div className={classes.leftText}>
									Date
								</div></GridColumn>
							<GridColumn sm={9}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{metaLineItem?.lineItemDate ? metaLineItem?.lineItemDate && moment(metaLineItem?.lineItemDate).format(MOMENT_DATE_FORMAT) : "-"}
									</div>
								}
							</GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={3}>
								<div className={classes.leftText}>
									Type
								</div></GridColumn>
							<GridColumn sm={9}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{renderTernary(metaLineItem?.lineItemType, metaLineItem?.lineItemType, "-")}
									</div>
								}
							</GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={3}>
								<div className={classes.leftText}>
									UTBMS
								</div></GridColumn>
							<GridColumn sm={9}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<Tooltip title={selectedUTBMSDesc}>
                    <div className={classes.rightText}>
                      {renderTernary(metaLineItem?.utbmsCode, metaLineItem?.utbmsCode, "-")}
                    </div>
                  </Tooltip>
								}
							</GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={3}>
								<div className={classes.leftText}>
									ACT
								</div></GridColumn>
							<GridColumn sm={9}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<Tooltip title={selectedActivityDesc}>
                    <div className={classes.rightText}>
                      {renderTernary(metaLineItem?.activityCode, metaLineItem?.activityCode, "-")}
                    </div>
                  </Tooltip>
								}
							</GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={3}>
								<div className={classes.leftText}>
									Timekeeper Name
								</div></GridColumn>
							<GridColumn sm={9}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{renderTernary(metaLineItem?.timekeeperName, metaLineItem?.timekeeperName, "-")}
									</div>
								}
							</GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={3}>
								<div className={classes.leftText}>
									Timekeeper Classification
								</div></GridColumn>
							<GridColumn sm={9}>
								{tkClassificationLoading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{metaLineItem?.classification ? metaLineItem?.classification : "-"}
									</div>
								}
							</GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={3}>
								<div className={classes.leftText}>
									Timekeeper ID
								</div></GridColumn>
							<GridColumn sm={9}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{renderTernary(metaLineItem?.tkId, metaLineItem?.tkId, "-")}
									</div>
								}
							</GridColumn>
						</Grid></div>
				</GridColumn>

			</Grid>
		</div>

	);
};

export default InvoiceLineItem;
