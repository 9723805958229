export const MISSING_PARAMETERS = "Please fill all the required fields to proceed.";
export const USER_CONTEXT_READ_ERROR = "Error! Unable to read user context object.";
export const INVALID_OFFSET_INDEX =	"Invalid offset index provided.";
export const REST_CALL_ERROR = "Error in calling the API.";
export const INVALID_PAGE_SIZE = "Invalid page size.";
export const ERROR_CODES = {
// Practice Area
LNG_PAM_MISSING_REQUIRED_PARAMETERS: MISSING_PARAMETERS,
LNG_PAM_USER_CONTEXT_READ_ERROR: USER_CONTEXT_READ_ERROR,
LNG_PAM_PRACTISE_AREA_ID_INVALID:	"Practice Area ID is invalid.",
LNG_PAM_PRACTISE_AREA_ID_NOT_FOUND:	"We couldn't find any Practice Areas for that ID.",
LNG_PTY_MATTER_ID_INVALID_OR_MISSING:	"Error! Invalid Matter ID provided.",
// Budget
LNG_BM_MISSING_REQUIRED_PARAMETERS:	MISSING_PARAMETERS,
LNG_BM_BUDGET_TIME_PERIOD_INVALID:	"Please update the budget time period. Use Life of Matter (LOM) or Custom Date range for FY (Feb 1 - Jan 31).",
LNG_BM_BUDGET_TYPE_INVALID:	"Budget type is invalid! Valid Types are Phase/Task/Total.",
LNG_BM_BUDGET_MATTER_ID_INVALID:	"Matter ID provided is invalid.",
LNG_BM_INVALID_ORGANIZATION_ID:	"Invalid Organization Id Provided.",
LNG_BM_BUDGET_MASTER_ID_INVALID_UUID:	"Error! Invalid Budget Master ID provided.",
LNG_BM_BUDGET_NOT_FOUND:	"Error! No Budget Found for the given Budget ID. Please Provide a valid Budget ID.",
LNG_BM_COMMENT_ID_INVALID:	"Comment ID provided is invalid.",
LNG_BM_COMMENT_NOT_FOUND:	"Comment corresponding to given Comment ID not found.",
LNG_BM_INVALID_COMMENT_ID:	"Invalid Comment Id Provided.",
LNG_BM_INVALID_OFFSET_INDEX:	INVALID_OFFSET_INDEX,
LNG_BM_DATE_PARSE_ERROR:	"Error parsing date.",
LNG_BM_CSV_WRITER_ERROR:	"Error writing to CSV file.",
LNG_BM_CSVFILE_NOT_FOUND_ERROR:	"Error CSV file not found.",
LNG_BM_FILE_DELETE_ERROR:	"Error deleting CSV/ZIP file.",
LNG_BM_EXECUTOR_INVOKEALL_EXECUTION_ERROR:	"Execution Error invoking all futures.",
LNG_BM_ZIPFILE_NOT_FOUND_ERROR:	"Error zip file not found.",
LNG_BM_ZIPFILE_WRITE_ERROR:	"Error writing to zip file.",
LNG_BM_CSVFILE_READ_ERROR:	"Error reading CSV file.",
LNG_BM_INVALID_PAGE_SIZE:	INVALID_PAGE_SIZE,
LNG_BM_USER_CONTEXT_READ_ERROR: USER_CONTEXT_READ_ERROR,
LNG_BM_REST_CALL_ERROR: REST_CALL_ERROR,
LNG_BM_ATTORNEY_INFO_REST_INVOCATION_ERROR: "Unable to create budget since a Lead Attorney was not found on the corresponding matter",
// Matter
LNG_MM_MISSING_REQUIRED_PARAMETERS:	"Missing/Blank Fields.",
LNG_MM_DUPLICATE_IDS:	"Error! Ensure that there are no duplicate IDs in the request body.",
LNG_MM_PEOPLE_IDS_INVALID:	"People id(s) invalid.",
LNG_MM_INTERNAL_SERVER_ERROR:	"Error! Internal Server Error.",
LNG_MM_MATTER_NOT_FOUND:	"Error! No Matter corresponding to Matter ID was found.",
LNG_MM_USER_CONTEXT_READ_ERROR:USER_CONTEXT_READ_ERROR,
LNG_MM_REST_CALL_ERROR:REST_CALL_ERROR,
LNG_MM_MATTER_ID_INVALID_OR_MISSING:	"Error! Invalid/Blank Matter ID provided.",
LNG_MM_INVALID_SEARCH_CRITERIA:	"Error! Invalid or 'null' Search Criteria.",
ERR_INVALID_INPUT:	"Invalid Object.",
LNG_MM_INVALID_PAGE_SIZE:	INVALID_PAGE_SIZE,
LNG_MM_INVALID_OFFSET_INDEX:	INVALID_OFFSET_INDEX,
// Access Control
LNG_AM_USER_CONTEXT_READ_ERROR: USER_CONTEXT_READ_ERROR,
LNG_AM_REST_CALL_ERROR: REST_CALL_ERROR,
//Notes
LNG_NOTES_ID_INVALID:"Notes ID provided is invalid.",
LNG_USER_ID_NOT_FOUND:"Error! Unable to fetch userId.",
NOTE_NOT_FOUND:"Note corresponding to given NoteID is not found.",
MISSING_REQUIRED_PARAMETERS:MISSING_PARAMETERS,
LNG_DESCRIPTION_LIMIT_EXCEEDED:"Description should be less than 250,000 characters.",
LNG_EXECUTOR_INVOKE_ALL_EXECUTION_ERROR:"Execution Error invoking all futures.",
LNG_CSV_FILE_READ_ERROR:"Error reading CSV file.",
LNG_INVALID_PAGE_SIZE:INVALID_PAGE_SIZE,
LNG_INVALID_OFFSET_INDEX:INVALID_OFFSET_INDEX,
LNG_REST_CALL_ERROR:REST_CALL_ERROR,
//Organization
LNG_ORGANIZATION_ID_INVALID_OR_MISSING: "Error! Invalid Organization ID provided.",
LNG_ORG_NOT_FOUND: "Organization Id Not Found.",
LNG_INVALID_SEARCH_CRITERIA: "Error! Invalid or 'null' Search Criteria.",
//People
LNG_USER_REQUEST_ALREADY_EXISTS: "LNG_USER_REQUEST_ALREADY_EXISTS", // if UI should show exact message as backend - please add value same as code.
LNG_PEOPLE_ID_MISSING: "Error! People ID is missing.",
LNG_PEOPLE_NOT_FOUND: "Record corresponding to given People ID not found.",
LNG_MISSING_REQUIRED_PARAMETERS: MISSING_PARAMETERS,
//Invoices
LNG_INVALID_LINE_TYPE: "Line Item Type should either be fee or expense.",
LNG_INVALID_INVOICE_ID: "Invalid Invoice Id.",
LNG_INVALID_PARENT_LINE_ITEM_ID: "Invalid Parent Line Item Id.",
LNG_INVOICE_NOT_FOUND: "Invoice not found.",
LNG_PARENT_LINE_ITEM_NOT_FOUND: "Parent Line Item not found.",
INVALID_ORGANIZATION_ID: "Invalid organization id.",
LNG_INVALID_LINE_ITEM_DATE_WRT_INVOICE_DATE: "One or more line item(s) date is outside of invoice date range.",
LNG_MISSING_LINE_ITEM_DATE: "One or more line item(s) date is missing.",
LNG_INVALID_DATE_RANGE: "Start date must be before end date.",
LNG_MISSING_LINE_ITEM: "Invoice must have at least one line item",
LNG_ACCOUNT_PAYABLE_INFO_COULDNT_RETRIEVED_FROM_MATTER: "Unable to retrieve Account Payable information for Matter.",
LNG_BUDGET_NOT_RETRIEVED_FOR_MATTER_AND_ORGANIZATION: "Budget not retrieved for provided matter and organization.",
LNG_INVALID_LINE_ITEM_ID: "Invalid Line Item Id.",
LNG_LINE_ITEM_NOT_FOUND: "Line Item not found.",
LNG_LINE_ITEM_WITH_PARENT_ITEM_NOT_FOUND: "Line Item with associated Parent Item not found.",
LNG_INVALID_REQUESTED_INVOICE_STATUS: "Error in Request Body, Request Body Attribute Validation Failed /  Invalid Requested Status.",
LNG_COMMENT_ID_INVALID: "Invalid comment id.",
LNG_COMMENT_NOT_FOUND: "Comment id not found.",
LNG_DUPLICATE_INVOICE_REQUEST: "Duplicate invoice request. Invoice already exists for this organization.",
//Matter Access requests
LNG_EXISTING_MATTER_ACCESS_REQUEST: "Matter Assign Request already exists for the selected combination.",
//Matter specific rates
LNG_DUPLICATE_MSR_REQUEST_FOR_USER_ID_MATTER_ID_DATE_RANGE : "Rate creation request already exists for the selected combination.",
// Reset password
INVALID_INPUT_PROVIDED_FOR_NEW_PASSWORD : "Token Invalid or Password is not matching the acceptance criteria.",
LNG_TOTAL_AMOUNT_IS_ZERO:"Total net amount of invoice must be greater than zero.",
//Process Status
LNG_JOB_NOT_FOUND: "Error! Job Not Found !",
LNG_INVALID_JOB_ID: "Error! Invalid Job Id!",
LNG_BJO_INVOICE_FILE_HEADERS_MISSING_ERROR: "LNG_BJO_INVOICE_FILE_HEADERS_MISSING_ERROR",
LNG_BJO_INVOICE_FILE_FORMAT_ERROR : "LNG_BJO_INVOICE_FILE_FORMAT_ERROR",
LNG_BJO_INVOICE_INVALID_FILE_TYPE: "LNG_BJO_INVOICE_INVALID_FILE_TYPE",
//IAM
LNG_NOTIFICATION_FAILED: "Some error occured while sending notification. Please try again later!",
INVALID_INPUT_PROVIDED: "Please provide valid input"
}

