import { Refresh } from "@livingdesign/icons";
import {
  Button,
  Heading,
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MuiDataTable from "../../components/Table/MuiDataTable/MuiDataTable";
import { REFRESH } from "../../constants/actionButtons";
import JobStatusListStyle from "./JobStatusList.styles";
import { useHistory } from 'react-router-dom';
import { getErrorMessage } from "../../helpers/utils";
import { setJobStatusData } from "../../store/actions/jobStatusActions";
import { JOB_SERVICE } from "../../constants/baseURLs";
import MuiDateItem from "../../components/Table/MuiDataTable/MuiDateItem/MuiDateItem";
import { getService } from "../../axios/axios";
import { displayLocalDate } from "../../helpers/utcUtils";
import { JOB_COMMANDS } from "../../constants/constants";
import { MOMENT_DATE_FORMAT } from "../../constants/dateFormats";
import { TableCellDisplay, renderStatus } from "../../helpers/commonUtils";

const JobStatusList = () => {
  const classes = JobStatusListStyle();
  const userDetails = useSelector(state => state?.user?.userDetail?.attributes);

  const renderSubmittedTs=(value, tableMeta, updateValue) => {
    return <MuiDateItem value={displayLocalDate(value,MOMENT_DATE_FORMAT ,"")} />;
  }
  const COLUMNS = [
    {
      name: "id",
      label: "id",
      colKey: "id",
      options: {
        display: false,
      }
    },
    {
      name: "jobNumber",
      label: "Job",
      colKey: "jobNumber",
      options: {
        customBodyRender: (value, tableMeta) => TableCellDisplay(`Mui-job-status-row-${tableMeta?.rowIndex}`, value),
      }
    },
		{
      name: "command",
      label: "Bulk Operation Type",
      colKey: "command",
      options: {
        setCellProps: () => ({ style: { maxWidth: "200px", minWidth: "200px" } }),
        setCellHeaderProps: () => ({ style: { maxWidth: "200px", minWidth: "200px" } }),
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-command-${tableMeta?.rowIndex}`, JOB_COMMANDS?.[value]?.type)
      }
    },
		{
      name: "title",
      label: "Title",
      colKey: "title",
      options: {
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-title-${tableMeta?.rowIndex}`, value)
      }
    },
		{
      name: "description",
      label: "Description",
      colKey: "description",
      options: {
        setCellProps: () => ({ style: { maxWidth: "200px", minWidth: "200px" } }),
        setCellHeaderProps: () => ({ style: {  maxWidth: "200px", minWidth: "200px" } }),
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-description-${tableMeta?.rowIndex}`, value)
      }
    },
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        setCellProps: () => ({ style: { maxWidth: "100px", minWidth: "100px" } }),
        setCellHeaderProps: () => ({ style: { maxWidth: "100px", minWidth: "100px" } }),
        customBodyRender: renderStatus,
      }
    },
    {
      name: "submittedTs",
      label: "Submitted Date",
      colKey: "submittedTs",
      options: {
        setCellProps: () => ({ style: { maxWidth: "200px", minWidth: "200px" } }),
        setCellHeaderProps: () => ({ style: { maxWidth: "200px", minWidth: "200px" } }),
        customBodyRender: renderSubmittedTs,
      },
    },
  ];

  const [jobStatusColumns, setJobStatusColumns] = useState(COLUMNS);
  const { jobStatusData } = useSelector(state => state?.processStatus);
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { addSnack } = useSnackbar();

  useEffect(() => {
    getJobStatusData()
  }, [])

  /*
* Function to get list of all the Process Status for the Logged In User ID
*/
  const getJobStatusData = () => {
    setLoading(true);
    getService(JOB_SERVICE, `/search/jobs/v1/users/${userDetails?.userUniqueId}`)
      .then((res) => {
        dispatch(setJobStatusData(res?.data))
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  }

  const jobStatusCustomOptions = {
    totalCount: jobStatusData?.count,
  };

  /*
  *On Click of Row Element of Job-Status List
  */
  const handleRowClick = (rowData, rowMeta) => {
    history.push(`/processStatus/${jobStatusData?.jobRequestDtoList[rowMeta?.dataIndex]?.id}`)
  }

  /*
  *On Click of Refresh Button
  */
  const refreshJobStatusData = () => {
    getJobStatusData()
  }

  return (
    <div className={classes.listing}>
      <div className={classes.parentHeader}>
        <Heading as="h1" size="small">
          <span className={classes.pageTitle} data-testid="job-status-title" id="job-status-title">Process Status</span>
        </Heading>
        <div className={classes.refreshButton}>
          <Button
            trailing={<Refresh size="small" />}
            size="small"
            variant="primary"
            data-testid="refresh-datatable"
            id="refresh-datatable"
            onClick={refreshJobStatusData}
            >
            {REFRESH}
          </Button>
            </div>
      </div>
      <MuiDataTable
        data={jobStatusData?.jobRequestDtoList}
        columns={jobStatusColumns}
        setTableColumns={setJobStatusColumns}
        disableExport={true}
        disableSearch={true}
        hideAddNewButton={true}
        disableFilter={true}
        customOptions={jobStatusCustomOptions}
        onRowClick={handleRowClick}
        loading={loading}
      />
    </div>)
};

export default JobStatusList;