import types from '../types/peopleActionTypes';

export const setOrganizationData = (payload) => {
  return { type: types.SET_ORGANIZATION_DATA, payload }
}

export const setExternalUserData = (payload) => {
  return { type: types.SET_EXTERNAL_USER_DATA, payload }
}

export const setNewOrganizationData = (name, value) => {
  return { type: types.SET_NEW_ORGANIZATION_DATA, payload: { name,value } }
}

export const setAssociatedMattersData = (name, value) => {
  return { type: types.SET_ASSOCIATED_MATTERS_DATA, payload: { name,value } }
}

export const resetOrganizationDetail = (payload) => {
  return { type: types.RESET_ORGANIZATION_DETAIL, payload }
}

export const setOrganizationDetail = (payload) => {
  return { type: types.SET_ORGANIZATION_DETAIL, payload }
}
export const setUserDetail = (payload) => {
  return { type: types.SET_USER_DETAIL, payload }
}

export const setMatterSpecificRates = (payload) => {
  return { type: types.SET_MATTER_SPECIFIC_RATES, payload }
}

export const setPracticeAreaRates = (payload) => {
  return { type: types.SET_PRACTICE_AREA_RATES, payload }
}

/**
 * This function will use for setting the form value
 * matter specific rate
 * @param {*} name
 * @param {*} value
 */
export const setMatterSpecificRateInputValue = (name, value) => {
  return { type: types.SET_MATTER_SPECIFIC_RATE_INPUT_VALUE, payload: { name, value } }
}

/**
 * This function will use for resetting the
 * matter specifc rate detail
 */
export const resetMatterSpecificRateDetail = () => {
  return { type: types.RESET_MATTER_SPECIFIC_RATE_DETAIL}
}
