import { createUseStyles } from "react-jss";
import { SPACING } from "../../../constants/styleGuide";

const DocumentListStyles = createUseStyles({
    dataTableWrap: {
      margin: "0",
      "& div>table>tbody>tr>td": {
        borderBottom: "none",
        fontFamily: "Bogle",
        height: "24px",
        fontWeight: "normal",
        fontStrech: "normal",
        lineHeight: "1.33",
      },
      '& table': {
        '& tr': {
          '& th': {
            color: '#2E2F32',
            fontWeight: 'bold',
            fontSize: 12,
          },
        },
      }
    },
    tabContainer: {
      "& nav > ul > li > a": {
        background: "none",
      },
      backgroundColor: '#fff',
    },
    detail: {
      flex: 1,
      background: "#f5f5f5"
    },
    contentSection: {
      padding: SPACING.s12
    },
    contentSpacing48: {
      marginTop: SPACING.s14
    },
    renameBtns: {
      float: "right",
      marginRight: "7%"
    },
    itemName: {
      '& input': {
        boxShadow: "none",
        paddingTop: "5px",
        paddingBottom: "5px"
      },
      cursor: "pointer"
    },
    expandableRow: {
      background: "#f8f8f8"
    },
    hideTable: {
      display: "none"
    },
    breadcrumbDiv: {
      display: "flex",
      backgroundColor: '#fff',
      height: "52px",
    },
    breadcrumb: {
      alignItems: "center",
      display: "flex",
      padding: SPACING.s16
    },

    BreadcrumbItem: {
      fontSize: "14px !important",
    },
    documentPreviewModalBox: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '800px',
      background: '#fff',
      borderRadius: '8px',
    },
    documentPreviewModalTitleWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e3e4e5',
      padding: '24px',
    },
    closeMuiModal: {
      cursor: "pointer",
      height: '24px',
      width: '24px',
    },
    modalActions: {
      '& div': {
        float: "right"
      }
    },
    makePublicModalBox: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60%',
      maxWidth: '477px',
      background: '#fff',
      borderRadius: '8px',
      padding: '24px',
    },
    makeModalButtonGroup: {
      justifyContent: 'flex-end',
      paddingTop: '16px',
      paddingBottom: '8px'
    },
    makePublicTitleWrap: {
      paddingBottom: '8px'
    },
  });
  export default DocumentListStyles;
