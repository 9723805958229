import types from "../types/adminUserRequestActionTypes";

export const initialState = {
	userRequestData: {
		"id": "",
		"firstName": "",
		"lastName": "",
		"emailId": "",
		"requestedRole": "",
		"primaryOfficeLocation": "",
		"organizationId": "",
		"organizationName": "",
		"phoneNumber": "",
		"stateBarLicence": "",
		"jdYear": null,
		"subjectMatterExpertise": "",
		"walmartApprovingAttorneyUserId": "",
		"walmartApprovingAttorneyFirstName": "",
		"walmartApprovingAttorneyLastName": "",
		"walmartApprovingAttorneyEmailId": "",
		"walmartApprovingAttorneyFullName": "",
		"justificationForRole": "",
		"requesterEmailId": "",
		"requesterUserId": "",
		"requesterFirstName": "",
		"requesterLastName": "",
		"requesterFullName": "",
		"tkId": "",
		"classification": "",
		"isBillingContact": true,
		"startDate": "",
		"endDate": "",
		"matterSpecificRates": [],
		"practiceAreaSpecificRates": [],
		"lastUpdatedBy": "",
		"lastUpdatedTs": "",
		"createdBy": "",
		"createdTs": "",
		"isActive": true,
		"addressLine1": ""
	},
	matterSpecificRatesData: {
		userRequets:[]
	},
	practiceAreaRatesData: {
		userRequets:[]
	},
	matterAccessRequestsData: {
		userRequets:[]
	},
};

const adminUserRequestReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_USER_REQUEST_DATA:
			return {
				...state,
				userRequestData: action.payload,
			};
		default:
			return state;
	}
};

export default adminUserRequestReducer;
