import React from "react";
import { BannerLeft, BannerRight, Spark } from "../../constants/images";
import HelpCenterBannerStyles from "./HelpCenterBanner.styles";

const HelpCenterBanner = (props) => {
  const classes = HelpCenterBannerStyles();

  const {heading} = props;

  return (
    <div className={classes.banner} data-testid={"help-center-banner"}>
      <div className={classes.bannerText}>
        <span className={classes.heading}>
          {heading}
        </span>
      </div>
      <div className={classes.bannerLeft}>
        <BannerLeft />
      </div>
      <div className={classes.bannerRight}>
        <BannerRight />
      </div>
      <div className={classes.spark}>
        <Spark />
      </div>
    </div>
  )
};

export default HelpCenterBanner
