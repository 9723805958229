export const SPACING = {
  s1: 1,
  s2: 2,
  s4: 4,
  s8: 8,
  s10: 10,
  s12: 12,
  s14: 14,
  s16: 16,
  s18: 18,
  s20: 20,
  s24: 24,
  s30: 30,
  s32: 32,
  s40: 40,
  s48: 48,
  s56: 56,
  s64: 64,
  s72: 72,
  s80: 80,
  s88: 88,
}

export const FONT_FAMILY  = {
  BOGLE: "Bogle, \"Helvetica Neue\", Helvetica, Arial, sans-serif",
}

export const BORDERS = {
  GREY: "1px solid #eee",
  LIGHT_GREY: "1px solid #f1f1f2",
  BLUE: "1px solid #135de6",
  BLUE_GREY: "1px solid #e3e4e5",
  WHITE: "1px solid #e0e0e0"
}
