import {
  Caption,
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postService } from "../../../axios/axios";
import ActionPopover from "../../../components/Table/ActionPopover/ActionPopover";
import MuiDataTable from "../../../components/Table/MuiDataTable/MuiDataTable";
import MuiTagItem from "../../../components/Table/MuiDataTable/MuiTagItem/MuiTagItem";
import { matterListStatusTagColors } from "../../../constants/colors";
import {
  CS_SCOPES,
  MATTER_DEFAULT_SORT_QUERY,
  CS_DEFAULT_SORT_QUERY,
  CS_SCORE_SORT_OBJ,
  EXPORT_MAX_LIMIT_CONF,
  MATTERS
} from "../../../constants/constants";
import {
  ENABLE_SERVER_SIDE_FEATURES_MATTER,
  ROWS_PER_PAGE_MATTER,
  ROWS_PER_PAGE_OPTIONS_MATTER,
} from "../../../constants/tableConfigs";
import { setMatterListingData } from "../../../store/actions/organizationAction";
import { getErrorMessage, getExportedData, getSensitiveMatterPayload } from "../../../helpers/utils";
import OrganizationDetailMattersStyles from "./OrganizationMatters.styles";
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import { isValueEmptyCheck } from "../../../helpers/validationUtils";
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery } from "../../../helpers/csQueryUtils";
import { matterDataMapping } from "../../../helpers/tableUtils";
import { TableCellDisplay } from "../../../helpers/commonUtils";
import ExportModal from "../../../components/Modal/ExportModal/ExportModal";

/**
* Component to return action popover on add new button
* @returns
*/
const AddNewActionPopover = ({addNewActionItems, handleAddNew}) => {
  const classes = OrganizationDetailMattersStyles();
  return (
    <div className={classes.customButton}>
    <ActionPopover
      actionItems={addNewActionItems}
      onClickOption={handleAddNew}
      buttonText={"Manage Timekeepers"}
    />
    </div>
  )
}

/**
 * Service of Process All status data component
 */
const OrganizationMatters = () => {
  const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.organization?.tabs?.matters);
  const classes = OrganizationDetailMattersStyles();
  const displayValue = (value, tableMeta) => {
		return (
			<Caption as="p">{isValueEmptyCheck(value)}</Caption>
		)
	}

  const renderStatus=(value) => {
    return <MuiTagItem
      value={value}
      color={matterListStatusTagColors[value]?.color || "gray"}
      variant={matterListStatusTagColors[value]?.variant || "tertiary"} />;
  }

  const COLUMNS = [
    {
      name: "id",
      label: "id",
      colKey: "id",
      options: {
        display: false,
      },
    },
    {
      name: "matterNumber",
      label: "Matter No.",
      colKey: "matterNumber",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-matterNumber-${tableMeta.rowIndex}`, value),
        customFilterListOptions: { render: (v) => `Matter Number: ${v}` },
      },
    },
    {
      name: "matterName",
      label: "Matter Name",
      colKey: "matterName",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-matterName-${tableMeta.rowIndex}`, value),
        customFilterListOptions: { render: (v) => `Matter Name: ${v}` },
      },
    },
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        sort: true,
        customBodyRender: renderStatus,
        customFilterListOptions: { render: (v) => `Status: ${v}` },
      },
    },
    {
      name: "matterType",
      label: "Matter Type",
      colKey: "matterType",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
        customFilterListOptions: { render: (v) => `Matter Type: ${v}` },
      },
    },
    {
      name: "matterCreateDate",
      label: "Create Date",
      colKey: "matterCreateDate",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => displayValue(value, tableMeta)
      },
    },
    {
      name: "leadAttorney",
      label: "Lead Attorney",
      colKey: "leadAttorney",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
        customFilterListOptions: { render: (v) => `Lead Attorney: ${v}` },
      },
    },
    {
      name: "facilityNumber",
      label: "Facility Number",
      colKey: "facilityNumber",
      options: {
        display: false,
        customFilterListOptions: { render: (v) => `Facility Number: ${v}` },
      },
    },
    {
      name: "facilityState",
      label: "State",
      colKey: "facilityState",
      options: {
        display: false,
        customFilterListOptions: { render: (v) => `State: ${v}` },
      },
    },
    {
      name: "facilityCity",
      label: "City",
      colKey: "facilityCity",
      options: {
        display: false,
        customFilterListOptions: { render: (v) => `City: ${v}` },
      },
    },
  ];

  const [matterColumns, setmatterColumns] = useState(COLUMNS);
  const [rowsPerPageMatter, setRowsPerPageMatter] = useState(ROWS_PER_PAGE_MATTER);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false)
  const [createExportLoading, setCreateExportLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { matterListingData } = useSelector((state) => state?.organization);
  const showExportAllOption = matterListingData?.count < EXPORT_MAX_LIMIT_CONF.MATTER;
  const [currentPageExport, setCurrentPageExport] = useState(false);
  const defaultExportOption = showExportAllOption ? 'allMatters' : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);
  const [rowsData, setRowsData] = useState([]);
  const [selectRowsData, setSelectRowsData] = useState([]);
  const { addSnack } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);
  const matterAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.matters);
  const userDetails = useSelector((state) => state?.user?.userDetail?.attributes);
  const [searchQuery, setSearchQuery] = useState({});
  const [filterQuery, setFilterQuery] = useState({});
  const [sortQuery, setSortQuery] = useState(MATTER_DEFAULT_SORT_QUERY);

	const FILTER_CONFIGS = [
		{
		  colKey: "status",
		  type: "checkbox",
		  label: "Status",
		  options: ["Open", "Closed"],
		  defaultOpen: true,
		},
		{
		  colKey: "matterNumber",
		  type: "matterDropdownAutocomplete",
		  label: "Matter Number",
		  defaultOpen: true,
		},
		{
		  colKey: "matterName",
		  type: "matterDropdownAutocomplete",
		  label: "Matter Name",
		  defaultOpen: true,
		},
		{
		  colKey: "leadAttorney",
		  type: "peopleDropdownAutocomplete",
		  label: "Lead Attorney",
		  options: [],
		  defaultOpen: true,
		},
		{
		  colKey: "facilityNumber",
		  type: "textField",
		  label: "Facility Number",
		  options: [],
		  defaultOpen: true,
		},
		{
		  colKey: "facilityCity",
		  type: "textField",
		  label: "City",
		  options: [],
		  defaultOpen: true,
		},
		{
		  colKey: "facilityState",
		  type: "masterDataAutocomplete",
		  label: "State",
      dataType: "state",
		  defaultOpen: true,
		},
	  ];

  /**
  * Function to call api on pagination action (server side)
  * @param {number} rowsPerPage
  * @param {number} page */
  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPageMatter(rowsPerPage);
    const paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * page));
    getMatterData(paginationQuery);
  };

  /**
  * Function to validate search query and set query or show no results UI (server side)
  * @param {string} searchText
  */
  const handleServerSideSearch = (searchText) => {
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };

  /**
  *  Function to set filter query on filter action (server side)
  * @param {string} query
  */
  const handleServerSideFilter = (filterQuery) => {
    setFilterQuery(filterQuery);
  };

  /**
  * Function to call api on sort action (server side)
  * @param {string} name
  * @param {string} direction
  */
  const handleServerSideSort = ({ name, direction }) => {
    const sortQuery = formCsSortQuery(name,direction);
    setSortQuery(sortQuery);
  };

  const matterTableCustomOptions = {
    filterConfigs: FILTER_CONFIGS,
    responsive: "standard",
    rowsPerPage: rowsPerPageMatter,
    page: 0,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_MATTER,
    totalCount: matterListingData?.count,
    isMatter: true,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_MATTER,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSideSort: handleServerSideSort,
  };

  /**
 * Function to call Matter Search Service
 * @param {string} query
 */
  const getMatterData = (paginationQuery) => {
    setLoading(true);
    const defaultQuery = {
      "filters": [
        formCsFilterObject("isActive", "match", true),
        formCsFilterObject("organizations.id", "match", identifier)
      ],
      "operation": "AND",
      "properties": null
    }

    const sortQueryMatter = {...sortQuery};
    if(searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(CS_DEFAULT_SORT_QUERY)) {
      sortQueryMatter.sortBy = [CS_SCORE_SORT_OBJ, ...sortQuery?.sortBy];
    }
    const query = formCompositeSearchQuery([CS_SCOPES.MATTER], filterQuery, searchQuery, sortQueryMatter, paginationQuery, {}, defaultQuery);

    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=false&organizationId=${identifier}`,
      query
    )
    .then((response) => {
      const mappedMatterResponse = matterDataMapping(response?.data?.matter);
      dispatch(setMatterListingData(mappedMatterResponse));
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      addSnack({
        message: getErrorMessage(error)
      });
    });
  };

  useEffect(() => {
    const paginationQuery = {
      "offset": 0,
      "limit": rowsPerPageMatter,
    }
    getMatterData(paginationQuery);
  }, [sortQuery, searchQuery, filterQuery]);

  /**
  * Gets called when clicked on Add new button.
  */
  const handleAddNew = (actionId) => {
    switch (actionId) {
      case 0:
        history.push(`/manageTimekeepers/assignTimekeeper`);
        break;
      case 1:
        history.push(`/manageTimekeepers/removeTimekeeper/selectMatters`);
        break;
    }
  }

  /**
  * Add new action items for external users
  */
  const addNewActionItems = [
    {
      "actionId": 0,
      "label": "Assign To Matters",
      "isAboveDivider": true,
      "showAction": accessibility?.addNew
    },
    {
      "actionId": 1,
      "label": "Remove From Matters",
      "isAboveDivider": true,
      "showAction": accessibility?.remove
    },
  ]
  /**
   * Cancel Export Modal
   */
   const onCancelBtnClk = () => {
    setExportLoading(false)
    setModalOpen(false)
  }

   /**
   * Gets called when user click on export button
   *
   * @public
   */
  const handleExport = () => {
    setExportSelection(defaultExportOption);
    setModalOpen(true);
    setExportLoading(true);
    setCurrentPageExport(true)
  };

  /**
   * function will use when row is select/deselect
   */
  const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
    const bData = matterListingData?.matters;
    const result = allSelected?.map(item => {
      return bData && bData[item?.index]
    });
    setSelectRowsData(selectRowsData);
    setRowsData(result);
  }

  /**
   * After click on create button download Actions
   */
  const onClickCreate = () => {
    setCreateExportLoading(true);
    const defaultQuery = {
      "filters": [
        formCsFilterObject("isActive", "match", true),
        formCsFilterObject("organizations.id", "match", identifier)
      ],
      "operation": "AND",
      "properties": null
    }
    const sensitiveMatterQuery = getSensitiveMatterPayload(matterAccessibility?.viewAll, userDetails?.userUniqueId);
    let exportQuery = formCompositeSearchQuery([CS_SCOPES.MATTER],  filterQuery, searchQuery, sortQuery,{},{getAll: true}, {}, defaultQuery,sensitiveMatterQuery);
    if(exportSelection === 'currentSelection'){
      const result = rowsData?.map(a => a?.identifier);
      const selectionQuery = {
        "filters": [formCsFilterObject("id", "in", result)],
        "operation": "AND",
        "properties": null
      }
      exportQuery = formCompositeSearchQuery([CS_SCOPES.MATTER], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery,sensitiveMatterQuery);
    }
    else if(exportSelection === 'currentPage'){
      const result = matterListingData?.matters?.map(a => a.identifier);
      const selectionQuery = {
        "filters": [formCsFilterObject("id", "in", result)],
        "operation": "AND",
        "properties": null
      }
      exportQuery = formCompositeSearchQuery([CS_SCOPES.MATTER], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery,sensitiveMatterQuery);
    }

    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=true&organizationId=${identifier}`,
      exportQuery
    )
    .then((response) => {
      const data = response?.data;
      const contentType = response?.headers["content-type"];
      getExportedData(data, contentType, 'organization-matters-data');
      setModalOpen(false);
      setExportLoading(false);
      setCreateExportLoading(false);
    })
    .catch((error) => {
      setModalOpen(false);
      setExportLoading(false);
      setCreateExportLoading(false);
      addSnack({
        message: getErrorMessage(error)
      });
    });
  }

  const resetSelectedRows = () => {
    setSelectRowsData([]);
    setRowsData([]);
  }

  return (
    <>
    <div className={classes.dataTableWrap}>
      <MuiDataTable
        data={matterListingData?.matters}
        columns={matterColumns}
        setTableColumns={setmatterColumns}
        customOptions={matterTableCustomOptions}
        loading={loading}
        customAddNewButton={<AddNewActionPopover addNewActionItems={addNewActionItems} handleAddNew={handleAddNew}/>}
        showCustomAddNewButton={true}
        selectableRowsType={"multiple"}
        selectedRows={selectRowsData}
        onRowSelection={handleRowSelectionChange}
        exportLoading={exportLoading}
        onExportClick={handleExport}
        customClassName={`orgMatters customMuiClass`}
        resetSelectedRows={() => resetSelectedRows()}
      />
    </div>
    <ExportModal
        title={MATTERS}
        mode={modalOpen}
        onCancel={onCancelBtnClk}
        onCreate={onClickCreate}
        rowsData={rowsData}
        exportSelection={exportSelection}
        setExportSelection={setExportSelection}
        currentPageExport={currentPageExport}
        showExportAllOption={showExportAllOption}
        setCurrentPageExport={setCurrentPageExport}
        exportLoading={createExportLoading}
      />
    </>
  );
};

export default OrganizationMatters;
