import { createUseStyles } from "react-jss";

/**
 * styles used in the component.
 */
export const ExportModalStyles = createUseStyles({
  exportName: {
    marginBottom: 20
  },
  modalSubText: {
    fontSize: 14,
    fontWeight: 'normal'
  },
});
