import { createUseStyles } from "react-jss";

const AssignTimekeepersStyles = createUseStyles({
	dataTableWrap: {
		"& div>table>tbody>tr>td": {
			fontFamily: "Bogle",
			height: "24px",
			fontWeight: "normal",
			fontStrech: "normal",
			lineHeight: "1.33",
		},
		padding: "36px 0 0"
	},
	pageContent: {
		padding: '25px 30px',
		marginLeft: '5px'
	},
	rightAlign: {
		textAlign: 'right'
	},
	heading: {
		fontSize: 36,
		fontWeight: "bold",
	}
});

export default AssignTimekeepersStyles;
