import { createUseStyles } from "react-jss";

const HelpCenterDocumentPreviewStyles = createUseStyles({
    spinner:{
        alignSelf:"center",
        display:"flex",
        flex:1,
        color:"#74767c"
      },
    externalLink :{
        color:"black"
    },
    header:{
        background:"white !important",
        width:"100%",
        display:"flex"
    },
    docContent:{
        background:"#F3F3F3",
        padding:"10px",
        margin:"20px 20px 20px 0",
        '& div > div > div.iSVAnT':{
            background:'white !important'
        },
    },
    icons:{
        display:"flex",
        justifyContent:"end",
        padding:"10px",
        flex:1
    },
    fileName:{
        background:"white !important",
        width:"100%",
        padding:"20px 0px 20px 20px",
        fontFamily: "Bogle",
        fontWeight: 700,
        lineHeight: "24px",
        fontSize:'36px',
        wordBreak: "break-word",
    },
    divider:{
        borderColor:"#9E9E9E",
        width:"100%",
    },
    docViewer: {
        height: '68vh !important',
    },
    downloadSpinner: {
       alignSelf: "center"
    }
})

export default HelpCenterDocumentPreviewStyles;
