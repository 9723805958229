import {
	Body,
	Button,
	ButtonGroup,
	Caption, Grid, GridColumn, Heading, Modal, useSnackbar
} from "@walmart-web/livingdesign-components";
import { Close } from "@walmart-web/livingdesign-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postService } from "../../../axios/axios";
import { Operations } from "../../../axios/operations";
import MuiDataTable from "../../../components/Table/MuiDataTable/MuiDataTable";
import { COMPOSITE_SEARCH_SERVICE, MATTER_SERVICE } from "../../../constants/baseURLs";
import {
	CONFIRM_TIMEKEEPER_REMOVAL,
	CONFIRM_TIMEKEEPER_REMOVAL_TEXT, CS_DEFAULT_PAGINATION_QUERY, CS_DEFAULT_SORT_QUERY, CS_SCOPES,
	SELECTED_MATTERS, SELECT_TIMEKEEPERS,
} from "../../../constants/constants";
import { ROWS_PER_PAGE_OPTIONS_TIMEKEEPER, ROWS_PER_PAGE_TIMEKEEPER } from "../../../constants/tableConfigs";
import { CANCEL, CONFIRM, GO_BACK, SUBMIT_REQUEST } from "../../../constants/actionButtons";
import { MOMENT_DATE_FORMAT } from "../../../constants/dateFormats";
import {
  PEOPLE_UNLINK_SUCCESS_MESSAGE,
  NO_MATTER_MSG,
  TIMEKEEPER_MESSAGE,
  TIMEKEEPER_REMOVE_MSG
} from "../../../constants/messages";
import { getErrorMessage, renderTernary } from "../../../helpers/utils";
import { isValueEmptyCheck } from "../../../helpers/validationUtils";
import { setRemoveSelectedMattersData, setRemoveSelectedTimekeepersData } from "../../../store/actions/organizationAction";
import { setExternalUserData } from "../../../store/actions/peopleAction";
import RemoveTimekeepersStyles from "./OrgRemoveTimekeepers.styles";
import { formatPhoneNumber } from "../../../helpers/commonUtils";
import { formCompositeSearchQuery, formCsFilterObject } from "../../../helpers/csQueryUtils";

/**
 * exportModalActions Actions
 */
const SubmitModalActions = ({onCancelBtnClk, onConfirm, isDisabled}) => {
	return (
		<ButtonGroup>
			<Button
				data-testid="goback-btn"
				id="goback-btn"
				size="small"
				onClick={() => onCancelBtnClk()}
			>
				{CANCEL}
			</Button>
			<Button
				variant="primary"
				size="small"
				data-testid="continue-btn"
				id="continue-btn"
				disabled={isDisabled}
				onClick={() => onConfirm()}
			>
				{CONFIRM}
			</Button>
		</ButtonGroup>
	);
};
/**
* A Pop Modal will be opened for submitting request
*/
const SubmitRequestModal = ({modalOpen, onCancelBtnClk, removeSelectedMattersData, removeSelectedTimeKeeperData, onConfirm, isDisabled, cancelSelectedMatters, cancelSelectedTimekeeper}) => {
	const classes = RemoveTimekeepersStyles();
	return (
		<Modal
			isOpen={modalOpen}
			onClose={() => onCancelBtnClk()}
			actions={<SubmitModalActions onCancelBtnClk={onCancelBtnClk} onConfirm={onConfirm} isDisabled={isDisabled}/>}
			size="large"
			title={
				<React.Fragment>
					<Heading as="h6" size="large" weight={700}>{CONFIRM_TIMEKEEPER_REMOVAL}</Heading>
					<div className={classes.modalSubText}>{CONFIRM_TIMEKEEPER_REMOVAL_TEXT}</div>
					<div className={classes.selectedMatters}>
						<Body as="div" size="large" weight={700} >
							{SELECTED_MATTERS}
						</Body>
						{
							removeSelectedMattersData?.length > 0 ?
								<div>
									{removeSelectedMattersData?.map((item, index) =>
										<Button
											size="medium"
											variant="tertiary"
											trailing={<Close  data-testid="cancel-selected-matter" onClick={() => cancelSelectedMatters(item)} />}
											key={`${item?.matterName}`}
										>
											{`${item?.matterName} (${item?.matterNumber})`}
										</Button>
									)}
								</div> : <div className={classes.modalNoSelectionStyle}> {NO_MATTER_MSG}</div>
						}
					</div>
					<div className={classes.selectedTimekeepers}>
						<Body as="div" size="large" weight={700} >
							{TIMEKEEPER_REMOVE_MSG}
						</Body>
						{removeSelectedTimeKeeperData?.length > 0 ?
							<div>
								{removeSelectedTimeKeeperData?.map((item, index) =>
									<Button
										size="medium"
										variant="tertiary"
										trailing={<Close  data-testid="cancel-selected-timekeeper" onClick={() => cancelSelectedTimekeeper(item)} />}
										key={`${item?.lastName}`}
									>
										{`${item?.lastName} ${item?.firstName}`}
									</Button>
								)}
							</div> : <div className={classes.modalNoSelectionStyle}> {NO_MATTER_MSG}</div>
						}
					</div>
				</React.Fragment>
			}
		>
		</Modal>
	)
}
/**
 * Service of Process All Timekeepers data component
 */
const OrgRemoveTimekeepers = () => {
	const classes = RemoveTimekeepersStyles();

	const displayValue = (value, tableMeta) => {
		return (
			<Caption as="p">{isValueEmptyCheck(value)}</Caption>
		)
	}
	const COLUMNS = [
		{
			name: "id",
			label: "id",
			colKey: "id",
			options: {
				display: false,
			},
		},
		{
			name: "lastName",
			label: "Last Name",
			colKey: "lastName",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => displayValue(value, tableMeta)
			},
		},
		{
			name: "firstName",
			label: "First Name",
			colKey: "firstName",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => displayValue(value, tableMeta)
			},
		},
		{
			name: "phoneNumber",
			label: "Phone #",
			colKey: "phoneNumber",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => displayValue(formatPhoneNumber(value), tableMeta)
			},
		},
		{
			name: "rate",
			label: "Rate",
			colKey: "rate",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
				customFilterListOptions: { render: (v) => `Rate: ${v}$/hr` },
			},
		},
		{
			name: "startDate",
			label: "Create Date",
			colKey: "startDate",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => renderTernary((isValueEmptyCheck(value) !== "-"), moment(value)?.format(MOMENT_DATE_FORMAT), "-"),
				customFilterListOptions: { render: (v) => `Created Date: ${v}` },
			},
			operation: Operations.EQ,
			type: "date"
		},
	];

	const [timekeeperColumns, setTimekeeperColumns] = useState(COLUMNS);
	const [selectedData, setSelectedData] = useState([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [selectRowsData, setSelectRowsData] = useState([]);
	const { nodeList, count } = useSelector(
    (state) => state?.people?.externalUserData
  );
	const externalUsersData = nodeList?.filter(item => item?.userRequestId)
	const { removeSelectedTimeKeeperData, removeSelectedMattersData } = useSelector((state) => state?.organization)
  const { addSnack } = useSnackbar();
	const dispatch = useDispatch()
	const [modalOpen, setModalOpen] = useState(false);

	/**********
@types : [
'textField',
'datePicker',
]
**********/
	const FILTER_CONFIGS = [
		{
			colKey: "rate",
			type: "textField",
			label: "Rate",
			defaultOpen: true,
		},
		{
			colKey: "startDate",
			label: "Date Submitted",
			type: "datePicker",
			defaultOpen: true,
			displayLabel: false
		}
	];

	const timekeepersCustomOptions = {
		responsive: "standard",
		rowsPerPage: ROWS_PER_PAGE_TIMEKEEPER,
		rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_TIMEKEEPER,
    totalCount: count,
    filterConfigs: FILTER_CONFIGS,
	};

	const getPeopleData = () => {
    setLoading(true)

    const defaultQuery = {
      "filters": [formCsFilterObject("isActive", "match", true)],
      "operation": "AND",
      "properties": null
    }
    defaultQuery.filters = [...defaultQuery?.filters, formCsFilterObject("id", "match", removeSelectedMattersData[0]?.id)]
		const query = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, CS_DEFAULT_SORT_QUERY, CS_DEFAULT_PAGINATION_QUERY, {}, defaultQuery);


    postService(COMPOSITE_SEARCH_SERVICE, `/composite-search/v1?returnCsv=false`, query)
      .then((res) => {
        setLoading(false)
        const peopleData = res?.data?.matter?.data[0]?.people;
        dispatch(setExternalUserData({
          nodeList: peopleData,
          count: peopleData?.length
        }));
      })
      .catch((error) => {
        setLoading(false)
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

  useEffect(() => {
    getPeopleData();
  }, []);

	/**
	* function will use when row is select/deselect
	*/
	const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
		const timekeepersDataList = externalUsersData;
		const result = allSelected?.map(item => {
			return timekeepersDataList && timekeepersDataList[item?.dataIndex]
		});
		setSelectRowsData(selectRowsData);
		setSelectedData(result)
	}

  /**
   * function is to open the  modal to show selected options
   */
  const handleSubmitRequest = () => {
    dispatch(setRemoveSelectedTimekeepersData(selectedData))
    setModalOpen(true);
  }

	/**
	 * function to go back to previous screen
	 */
	const handleBack = () => {
		history.goBack()
	}

	  /**
* Cancel Submit Modal
*/
const onCancelBtnClk = () => {
	setModalOpen(false)
}

  /**
 * function to remove timekeeper
 */
  const cancelSelectedTimekeeper = (data) => {
    const filteredData = removeSelectedTimeKeeperData?.filter((item) => item?.userRequestId !== data?.userRequestId)
    dispatch(setRemoveSelectedTimekeepersData(filteredData))
  }

    /**
 * function to remove matters
 */
     const cancelSelectedMatters = (data) => {
      const filteredData = removeSelectedMattersData?.filter((item) => item?.matterNumber !== data?.matterNumber)
      dispatch(setRemoveSelectedMattersData(filteredData))
    }

	/**
* function to confirm submit request
*/
	const onConfirm = (data) => {
		const removePeopleList = removeSelectedTimeKeeperData?.map((item) => item?.identifier)
		setLoading(true);
		const body = {
			"unlinkedPeople": removePeopleList,
		}
		postService(
			MATTER_SERVICE,
			`/matters/v1/${removeSelectedMattersData[0]?.id}/people`,
			body
		).then((response) => {
			setLoading(false)
			addSnack({
				message: PEOPLE_UNLINK_SUCCESS_MESSAGE
			})
			setModalOpen(false)
			history.push(`/organization`, { selectedTab: 2 })
		}).catch((error) => {
			setLoading(false)
			addSnack({
				message: getErrorMessage(error)
			})
		})
	}

	/*
*Checking for No Timekeepers and No Matters
*/
	const isDisabled = (removeSelectedMattersData?.length === 0 || removeSelectedTimeKeeperData?.length === 0)


	return (
		<div >

			<div className={classes.heading}>
				<Grid>
					<GridColumn sm={12} md={6} lg={10}>
						{SELECT_TIMEKEEPERS}
					</GridColumn>
					<GridColumn sm={12} md={6} lg={1}>
						<Button size="medium" variant="tertiary" data-testId="go-back"  id="go-back" onClick={() => handleBack()}>
							{GO_BACK}
						</Button>
					</GridColumn>
					<GridColumn sm={12} md={6} lg={1} onClick={() => handleSubmitRequest()}>
						<Button size="medium" variant="primary" data-testId="select-matters" id="select-matters">
							{SUBMIT_REQUEST}
						</Button>
					</GridColumn>
				</Grid>
			</div>
			{removeSelectedMattersData?.length > 0 &&
				<div className={classes.mattersContainer}>
					<Body as="span" size="large" weight={700}>
						{`${removeSelectedMattersData?.length} Matters Selected : `}
					</Body>
					{removeSelectedMattersData?.map((item, index) =>
						<Button
							size="medium"
							variant="tertiary"
							trailing={<Close onClick={() => cancelSelectedMatters(item)} />}
							key={`${item?.matterName}`}
						>
							{`${item?.matterName} ${item?.matterNumber}`}
						</Button>
					)}
				</div>
			}
			<div className={classes.dataTableWrap}>
				<MuiDataTable
					data={externalUsersData}
					columns={timekeeperColumns}
					setTableColumns={setTimekeeperColumns}
					customOptions={timekeepersCustomOptions}
					loading={loading}
					selectableRowsType={"multiple"}
					hideAddNewButton={true}
					disableExport={true}
					selectedRows={selectRowsData}
					onRowSelection={handleRowSelectionChange}
					showSelectedRow={true}
					selectedRowMsg={TIMEKEEPER_MESSAGE}
				/>
			</div>
      <SubmitRequestModal modalOpen={modalOpen} onCancelBtnClk={onCancelBtnClk} removeSelectedMattersData={removeSelectedMattersData} removeSelectedTimeKeeperData={removeSelectedTimeKeeperData} onConfirm={onConfirm} isDisabled={isDisabled} cancelSelectedMatters={cancelSelectedMatters} cancelSelectedTimekeeper={cancelSelectedTimekeeper}/>
		</div>
	);
};

export default OrgRemoveTimekeepers;
