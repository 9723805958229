import { createUseStyles } from "react-jss";

const ThemisFooterStyles = createUseStyles({
  footerContainer: {
    position: "fixed",
    width: "100%",
    lineHeight: "24px",
    backgroundColor: '#FFFFFF',
    borderTop: "1px solid #dbdbdb",
    bottom: "0",
    padding: "25px 30px",
    color: "#74767C",
  },
  copyRightStyle: {
    '& a': {
      color: "#74767C",
      textDecoration: 'none'
    },
    textAlign: 'right'
  },
  bottomText: {
    marginRight
      : "50px"
  }
})

export default ThemisFooterStyles;

