import React, { useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { Tag } from "@walmart-web/livingdesign-components";
import { createUseStyles } from "react-jss";
import { SPACING } from "../../../constants/styleGuide";
import { Close } from "@walmart-web/livingdesign-icons";

const useStyles = createUseStyles({
  tag: {
    padding: SPACING.s4,
  },
  tagClose: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const TagAutocomplete = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {
    value,
    setValue,
    tagData,
    label,
    placeholder,
    disabled,
    allowCustomTags } = props;

  return (
    <Autocomplete
      id="tag-autocomplete"
      data-testid="tag-autocomplete"
      multiple
      freeSolo={Boolean(allowCustomTags)}
      disabled={disabled}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      autoHighlight
      autoComplete
      options={tagData}
      getOptionLabel={(option) => option}
      value={value}
      onChange={(e, v) => {
        setValue(v);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params?.inputProps }}
          margin="dense"
          data-testid="tag-text"
          label={label}
          placeholder={placeholder}
          variant="outlined"
        />
      )}
      renderTags={(tags) => (
        <>
          {tags?.map((value, index) => (
            <div className={classes.tag} key={`${value}`}>
              <Tag
                color="blue"
                variant="tertiary"
                leading={
                  <Close
                    className={classes.tagClose}
                    data-testid="close-btn"
                    onClick={() =>
                      setValue(tags?.filter((tag) => tag !== value))
                    }
                  />
                }
              >
                {value}
              </Tag>
            </div>
          ))}
        </>
      )}
    />
  );
};

export default TagAutocomplete;
