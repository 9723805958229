import types from "../types/noteActionTypes";

export const initialState = {
	noteDetail: {
		notesId: "",
		id: "",
		description: "",
		title: "",
		createdBy: "",
		createdTs: "",
		color: "",
		matterNumber: "",
		tags: [],
		private: false,
		matter: ""
	},
  matterNoteData: {
    notes: [],
    count: 0
  },
  matterPublicNoteData: {
    notes: [],
    count: 0,
  },
  matterPrivateNoteData: {
    notes: [],
    count: 0,
  }
};

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INPUT_VALUE: {
      const { name, value } = action.payload;
      return {
        ...state,
        noteDetail: {
          ...state.noteDetail,
          [name]: value
        }
      }
    }
    case types.SET_NOTE_DETAIL:
			return {
				...state,
				noteDetail: {
					...state.noteDetail,
					...action.payload,
				}
			}
    case types.SET_MATTER_NOTE_DATA:
      return {
        ...state,
        matterNoteData: action.payload,
      };
    case types.SET_PUBLIC_NOTE_DATA:
      return {
        ...state,
        matterPubliceNoteData: action.payload,
      };
    case types.SET_PRIVATE_NOTE_DATA:
      return {
        ...state,
        matterPrivateNoteData: action.payload,
      };
    case types.RESET_NOTE_DETAIL:
      return {
        ...state,
        noteDetail: initialState.noteDetail
      }
		default:
			return state;
  }
}

export default noteReducer;
