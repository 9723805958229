import { createUseStyles } from "react-jss";
import { Body, Tag, Grid, GridColumn } from "@walmart-web/livingdesign-components";
import React from "react";
import { LIGHT_GRAY } from "../../constants/colors";

const useStyles = createUseStyles({
  listing: {
    backgroundColor: LIGHT_GRAY,
    padding: "25px 30px",
    flex: 1,
    "& .MuiPaper-root": {
      boxShadow: "none !important"
    }
  },
  whitelisting: {
    backgroundColor: '#ffffff',
    padding: "25px 30px",
    flex: 1,
  },
  matterListing: {
    backgroundColor: LIGHT_GRAY,
    padding: "25px 30px",
    flex: 1,
  },
  subtitle: {
    paddingTop: 8,
    paddingBottom: 24,
  },
  pageTitle: {
    fontSize: 36,
    fontWeight: "bold",
  },
  header: {
    paddingLeft: 16,
  },
  newTag:{
    bottom: '10px',
    display: 'inline-block',
    position: 'relative',
    paddingLeft: '5px'
  }
});

/**
 * Component to be used for listing page
 *
 * @visibleName Listing
 */
const Listing = (props) => {
  const classes = useStyles();

  const { title, subtitle, children, tag, actions, whiteBackground, matterDetail, id="pageTitle"} = props;
  const matterListingCheck = matterDetail ? classes.matterListing : classes.listing

  return (
    <div className={whiteBackground ? classes.whitelisting : matterListingCheck} id='content-container'>
      <div className={classes.header}>
        <Grid>
          <GridColumn sm={10}>
        <span as="h1" className={classes.pageTitle} id={id}>
          {title}
        </span>
        <span className={classes.newTag}>
          {(
            (tag === true)?
            <Tag
              color="green"
              variant="tertiary"
            >
              New
            </Tag>:
            ''
          )}
        </span>
        {subtitle ? (
          <div className={classes.subtitle}>
            <Body as="p" variant="1" data-testid="page-subtitle">
              {subtitle}
            </Body>
          </div>
        ) : null}
          </GridColumn>
          <GridColumn sm={2} align="right">
            {actions}
          </GridColumn>
        </Grid>
      </div>
      {children}
    </div>
  );
};

export default Listing;
