import { createUseStyles } from "react-jss";

/**
 * styles used in the component.
 */
export const ReleaseNotesModalStyles = createUseStyles({
  subTitle: {
    fontSize: "20px",
    margin: "10px 0 0 0"
  },
  list: {
    fontSize: "18px",
    margin: "0",
  },
  bullets: {
    fontSize: "1.5em",
    marginRight: "5px"
  },
  wrapperDiv: {
    height: "auto",
    maxHeight: "400px"
  }
});