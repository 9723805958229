import { createUseStyles } from "react-jss";

const IFrameViewerStyles = createUseStyles({
    docContent:{
        background:"#F3F3F3",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignSelf: "center",
        padding:"10px",
        display:"flex",
        margin:"20px 20px 20px 20px",
        '& div > div > div.iSVAnT':{
          background:'white !important'
        },
    },
    spinner:{
        alignSelf:"center",
        display:"flex",
        flex:1,
        color:"#74767c"
    },
    video:{
        width: "100%",
        minHeight: "500px",
        alignSelf: "center",
    },
})

export default IFrameViewerStyles;
