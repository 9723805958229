import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router";
import { createUseStyles } from "react-jss";

import Detail from "../Detail/Detail";
import TagAutocomplete from "../../components/Autocomplete/TagAutocomplete/TagAutocomplete";
import DisplayValue from "../../components/TextField/DisplayValue/DisplayValue";

import {
  detailModes as modes,
  NEW_NOTE,
  SUBJECT,
  NOTE_DESCRIPTION_HELPER_TEXT,
  DESCRIPTION,
  CANCEL_NOTE_CREATION,
  CANCEL_NOTE_CREATION_TEXT,
  MATTER_NAME,
  MATTER_NUMBER,
  CREATE_DATE,
  CREATED_BY,
  TAGS,
  DOCUMENT,
  SELECT_TAGS,
  MATTER,
} from "../../constants/constants";
import { NOTE_SAVE_MESSAGE, NOTE_LINK_COPY_MESSAGE, NOTE_CREATE_WARNING_MSG } from "../../constants/messages";
import { GO_BACK, CONTINUE } from "../../constants/actionButtons";
import { SPACING } from "../../constants/styleGuide";


import { getService, postService } from "../../axios/axios";

import {
  setNoteDetail,
  resetNoteDetail,
  setInputValue,
} from "../../store/actions/noteActions";
import TextField from "@mui/material/TextField";

import {
  Alert,
  Grid,
  GridColumn,
  TextArea,
  Modal,
  Button,
  ButtonGroup,
  Body,
  Divider,
  Tag,
  useSnackbar,
  Heading,
  Skeleton,
  SkeletonText
} from "@walmart-web/livingdesign-components";
import Subheading from "../../components/TextField/SubHeading/Subheading";
import Label from "../../components/TextField/Label/Label";
import { NOTE_SERVICE } from "../../constants/baseURLs";
import { getErrorMessage, renderTernary } from "../../helpers/utils";
import MuiValidationMessage from "../../Validation/MuiValidationMessage/MuiValidationMessage";
import { notesValidationRules } from "../../Validation/validationRules";
import { useForm } from "react-hook-form";
import { filterPayload } from "../../helpers/commonUtils";
import { displayLocalDate } from "../../helpers/utcUtils";

const useStyles = createUseStyles({
  contentSpacing8: {
    paddingTop: SPACING.s8,
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  contentSpacing48: {
    paddingTop: SPACING.s48,
  },
  leftContent: {
    paddingRight: SPACING.s72,
  },
  infoIconSpacing: {
    marginLeft: SPACING.s8,
  },
  tag: {
    marginLeft: SPACING.s4,
    marginBottom: SPACING.s4,
    display: "inline-block",
  },
  documentView: {
    padding: "16px 16px 10px",
    border: "1px solid #eeeeee",
    borderRadius: "10px",
    cursor: "pointer",
    margin: "5px 0",
  },
  descriptionText: {
    fontSize: "14px",
    whiteSpace: "break-spaces"
  },
  callMadeIconContainer: {
    display: "flex",
    justifyContent: "right",
  },
  sideDivider: {
    paddingTop: SPACING.s24,
    '& > hr': {
      borderBottom:'0.0325rem solid #E3E4E5'
    }
  },
  pointerCursor: {
    cursor: "pointer",
  },
  tagModule: {
    alignItems: "center",
    borderRadius: "0.125rem",
    display: "inline-flex",
    fontSize: "0.75rem",
    fontWeight: "400",
    lineHeight: "1.5rem",
    padding: "0 0.5rem",
    whiteSpace: "nowrap",
    background: "#002d58",
    color: "#fff",
  },
  urlBody: {
    fontSize: "16px",
    paddingTop: "3px",
    margin: 0,
  },
  publicSwitchLabel: {
    fontSize: "17px",
  },
  gridWrapper: {
    display: "flex",
    flexWrap: " wrap",
  },
  grid10: {
    width: "10%",
  },
  grid85: {
    width: "85%",
  },
  grid5: {
    width: "5%",
    display: "flex",
    justifyContent: "right",
  },
  noteTitle:{
    paddingBottom: '20px'
  },
  documentLink: {
    color: "#333",
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  icons: {
    textAlign: 'right',
    paddingBottom: '15px'
  },
  warningMsg: {
    padding: "20px 40px 0",
    background: "#fff",
  },
});


/**
 * NoteCreateModalActions Actions
 */
const NoteCreateModalActions = ({onClickGoBack, setNoteCreationModalOpen, matterDetail, currentMatterTab}) => {
  const history = useHistory();
  return (
    <Grid>
      <GridColumn sm={5}>
        <ButtonGroup>
          <Button
            data-testid="goback-btn"
            id="goback-btn"
            size="small"
            onClick={() => onClickGoBack()}
          >
            {GO_BACK}
          </Button>
          <Button
            variant="destructive"
            size="small"
            data-testid="continue-btn"
            id="continue-btn"
            onClick={() => {
              setNoteCreationModalOpen(false);
              history.push(`/matter/${matterDetail?.matter?.identifier}`, { currentMatterTab });
            }}
          >
            {CONTINUE}
          </Button>
        </ButtonGroup>
      </GridColumn>
    </Grid>
  );
};

const CreateViewNote = () => {
  const classes = useStyles();
  const history = useHistory();
  const { noteId, matterId } = useParams();
  const dispatch = useDispatch();
  const { state: { currentMatterTab } = {} } = useLocation();

  const { addSnack } = useSnackbar();
  const [mode, setMode] = useState(null);
  const [noteCreationModalOpen, setNoteCreationModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const noteDetail = useSelector((state) => state?.note?.noteDetail);
  const matterDetail = useSelector((state) => state?.matter?.matterDetail);
  const noteTags = useSelector(state => state?.masterData?.masterDatas?.noteTags);
  const { matterListingData } = useSelector((state) => state?.matter);
  const { register, formState: { errors, isValid }} = useForm({ mode: "all" });

  /**
   * This function is called when api call throws an error
   */
   const handleError = (error) => {
    setLoading(false);
      addSnack({
        message: getErrorMessage(error),
      });
  }

  useEffect(() => {
    setLoading(true);
    if (noteId === "0") {
      dispatch(resetNoteDetail());
      setMode(modes.CREATE);
      setLoading(false);
    } else {
      setMode(modes.VIEW);
      getService(NOTE_SERVICE, `/notes/v1/${noteId}`)
        .then((res) => {
          const { matterNumber } = res.data;
          const matter = matterListingData?.matters?.find(
            (m) => m?.referenceKey === matterNumber
          );
          const payload = {
            ...res.data,
            matter,
          };
          dispatch(setNoteDetail(payload));
          setLoading(false);
        })
        .catch((error) => {
          handleError(error)
        });
    }
  }, []);

  /**
   * Opens note creation modal when clicked on cancel button.
   */
  const handleCancel = () => {
    setNoteCreationModalOpen(true);
  };

  /**
   * Gets called when data changes in input fields.
   */
  const handleInputChange = (name, value) => {
    dispatch(setInputValue(name, value));
  };

  /**
   * Gets called when clicked on create button.
   */
  const handleCreate = () => {
    const body = {
      description: noteDetail?.description,
      title: noteDetail?.title,
      color: "blue",
      matterNumber:  matterDetail?.matter?.matterNumber,
      tags: noteDetail?.tags,
      private: false,
    };

    setLoading(true);
    const filteredBody=filterPayload(body)
    postService(NOTE_SERVICE, "/notes/v1", filteredBody)
      .then(() => {
        history.push(`/matter/${matterDetail?.matter?.identifier}`, { currentMatterTab });
        setLoading(false);
        addSnack({
          message: NOTE_SAVE_MESSAGE,
        });
      })
      .catch((error) => {
        handleError(error)
      });
  };


  /**
   * Cancel Note Creation Modal modal.
   */
  const cancelNoteCreationModal = () => {
    return (
      <Modal
        onClose={() => setNoteCreationModalOpen(false)}
        isOpen={noteCreationModalOpen}
        actions={<NoteCreateModalActions onClickGoBack={onClickGoBack} setNoteCreationModalOpen={setNoteCreationModalOpen} matterDetail={matterDetail} currentMatterTab={currentMatterTab}/>}
        size="small"
        title={CANCEL_NOTE_CREATION}
      >
        <Grid>
          <Body as="p" size="large">
            {CANCEL_NOTE_CREATION_TEXT}
          </Body>
        </Grid>
      </Modal>
    );
  };

  /**
   * View mode when clicked on Go Back button.
   */
  const onClickGoBack = () => {
    setNoteCreationModalOpen(false);
  };


  /**
   * DocumentLink component
   */
  const DocumentLink = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return `<div class=${classes.documentView}>
        <div class=${classes.gridWrapper}>
          <div class=${classes.grid10}>
            <span class=${classes.tagModule}>${DOCUMENT}</span>
          </div>
          <div class=${classes.grid85}>
            <p class=${classes.urlBody}>${url}</p>
          </div>
          <div class=${classes.grid5}>
            <a href=${url} target="_blank" class=${classes.documentLink}>
              <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="CallMadeIcon"><path d="M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5z"></path></svg>
            </a>
          </div>
        </div>
      </div>`;
    });
  };

   /**
   * Gets called when clicked on copy icon where the function return the current URL of the note.
   */
    const handleCopyLink = () => {
      navigator.clipboard.writeText(window.location.href).then(() => {
        addSnack({
          message: NOTE_LINK_COPY_MESSAGE,
        });
      });
    };

  return (
    <>
    {renderTernary(mode === modes.CREATE, <div className={classes.warningMsg}>
      <Alert variant="warning">{NOTE_CREATE_WARNING_MSG}</Alert></div>, '' )}
    <Detail
      mode={mode}
      setMode={setMode}
      breadcrumbValues={[
				{
					label: MATTER,
					path: ""
				},
				{
					label: `${noteDetail?.matterNumber}`,
					path: (`matter/${matterId}`)
				},
        {
          label: `Notes`,
          path: (`matter/${matterId}`),
          currentMatterTab: currentMatterTab,
        },
        {
          label: `${noteDetail?.notesId}`
        },
			]
			}
      subject=""
      newSubtitle={`Matter No. ${matterDetail?.matter?.matterNumber}, ${matterDetail?.matter?.matterName}`}
      newTitle={NEW_NOTE}
      //footerActions={<FooterActions />} might need in the future
      onCancel={handleCancel}
      onCreate={handleCreate}
      hideEditBtn={true}
      hideDeleteBtn={true}
      loading={loading}
      isDirty={true}
      isValid={isValid}
      onCpyLnkClk={handleCopyLink}
    >
      {mode === modes.VIEW ? (
          <Grid>
            <GridColumn sm={9}>
              <div className={classes.leftContent}>
                {loading ? (
                  <div className={classes.noteTitle}>
                  <Skeleton height="26px" variant="rectangle" />
                </div>
              ) : (
                <div className={classes.noteTitle}>
                  <Heading as="h1" id={"node-detail-title"}>
                    {noteDetail?.title}
                  </Heading>
                </div>
              )}
              {loading ? (
                  <SkeletonText lines={6} variant="rectangle" />
                ) : (
                  <>
                    <Subheading as="p" variant="2">
                      {DESCRIPTION}
                    </Subheading>
                    <div
                      className={classes.descriptionText}
                      dangerouslySetInnerHTML={{
                        __html: DocumentLink(noteDetail?.description),
                      }}
                      id={"node-detail-description"}
                    ></div>
                  </>
                )}
              </div>
            </GridColumn>
            <GridColumn sm={3}>
              {loading ? (
                <SkeletonText lines={1} variant="rectangle" />
              ) : (
                <DisplayValue
                  label={MATTER_NAME}
                  value={
                    matterDetail?.matter?.matterName ? matterDetail?.matter?.matterName : "-"
                  }
                />
              )}
              <div className={classes.contentSpacing24}>
                {loading ? (
                  <SkeletonText lines={1} variant="rectangle" />
                ) : (
                  <DisplayValue
                    label={MATTER_NUMBER}
                    value={
                      noteDetail?.matterNumber ? noteDetail?.matterNumber : "-"
                    }
                  />
                )}
              </div>
              <div className={classes.contentSpacing24}>
                {loading ? (
                  <SkeletonText lines={1} variant="rectangle" />
                ) : (
                  <DisplayValue
                    label={CREATE_DATE}
                    value={
                      noteDetail.createdTs
                        ? displayLocalDate(noteDetail?.createdTs)
                        : "-"
                    }
                  />
                )}
              </div>
              <div className={classes.contentSpacing24}>
                {loading ? (
                  <SkeletonText lines={1} variant="rectangle" />
                ) : (
                  <DisplayValue
                    label={CREATED_BY}
                    value={
                      noteDetail && noteDetail?.createdBy
                        ? noteDetail?.createdBy
                        : "-"
                    }
                  />
                )}
              </div>
              <div className={classes.sideDivider}>
                <Divider />
              </div>

              <div className={classes.contentSpacing24}>
                {loading ? (
                  <SkeletonText lines={1} variant="rectangle" />
                ) : (
                  <DisplayValue
                    label={TAGS}
                    value={
                      noteDetail?.tags.length !== 0?
                        <>
                          {noteDetail?.tags?.map((value, i) => (
                            <div className={classes.tag} key={`${value}`}>
                              <Tag variant="tertiary">
                                {value}
                              </Tag>
                            </div>
                          ))}
                        </>:
                        "-"
                    }
                  />
                )}
              </div>
            </GridColumn>
          </Grid>
      ) : (
          <Grid data-testid="edit-content">
            <GridColumn sm={9}>
              <div className={classes.leftContent}>
                  {loading ? (
                    <Skeleton height="40px" variant="rectangle" />
                  ) : (
                    <React.Fragment>
                      <Label text={SUBJECT} mandatoryField={true}/>
                    <TextField
                      type="text"
                      id= "title"
                      name= "title"
                      required= {true}
          						fullWidth={true}
                      data-testid="title-text"
                      value={noteDetail?.title}
                      error={errors?.title}
                      helperText={errors?.title && <MuiValidationMessage message={errors?.title?.message}/>}
                      {...register(
                        'title',
                        { ...notesValidationRules?.title, onChange: (e) => handleInputChange(e.target.name, e.target.value) }
                      )}
                    />
                    </React.Fragment>
                  )}
                <div className={classes.contentSpacing16}>
                  {loading ? (
                    <div className={classes.contentSpacing24}>
                      <Skeleton height="100px" variant="rectangle" />
                    </div>
                  ) : (
                    <React.Fragment>
                      <Label text={DESCRIPTION} mandatoryField={true}/>
                      <TextArea
                        helperText={NOTE_DESCRIPTION_HELPER_TEXT}
                        textAreaProps={{
                          id: "description",
                          name: "description",
                        }}
                        data-testid="description-text"
                        value={noteDetail?.description}
                        maxLength={notesValidationRules?.description?.maxLength?.value}
                        error={errors?.description && <MuiValidationMessage message={errors?.description?.message} mode="LD" />}
                        {...register(
                          'description',
                          { ...notesValidationRules?.description, onChange: (e) => handleInputChange(e.target.name, e.target.value) }
                        )}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </GridColumn>
            <GridColumn sm={3}>
              {loading ? (
                <Skeleton height="40px" variant="rectangle" />
              ) : (
                <React.Fragment>
                  <Label text={TAGS} />
                  <TagAutocomplete
                    placeholder={SELECT_TAGS}
                    setValue={(v) => handleInputChange("tags", v)}
                    tagData={noteTags?.masterData?.map(val => val?.value)}
                  />
                </React.Fragment>
              )}
            </GridColumn>
          </Grid>
      )}
      {cancelNoteCreationModal()}
    </Detail>
    </>
  );
};

export default CreateViewNote;
