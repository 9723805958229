import { createUseStyles } from "react-jss";
import { BORDERS, SPACING } from "../../../constants/styleGuide";

const PracticeAreaRatesStyles = createUseStyles({
	whiteContainer: {
		background: '#ffffff',
		borderBottom: '2px solid #E3E4E5',
	},
	contentSpacing16: {
		paddingTop: SPACING.s12,
	},
	contentSpacing3: {
		paddingTop: SPACING.s4,
	},
	rateDetails: {
		padding: SPACING.s20,
		borderBottom: BORDERS.LIGHT_GREY
	},
	contentStatus: {
		paddingTop: SPACING.s16,
		textAlign: 'right'
	},
	titleWrap: {
		display: "flex",
		padding: SPACING.s20,
		borderBottom: BORDERS.LIGHT_GREY,
		justifyContent: "space-between",
		'& h3': {
			lineHeight: "1",
		},
		'& h1': {
			fontSize: "20px"
		}
	},
	rejectReason: {
		margin: SPACING.s24
	},
	rejectGrid:{
		justifyContent:'space-between'
	}
});

export default PracticeAreaRatesStyles;
