const types = {
    SET_SELECTED_TIMEKEEPERS_DATA: "SET_SELECTED_TIMEKEEPERS_DATA",
    SET_SELECTED_MATTERS_DATA: "SET_SELECTED_MATTERS_DATA",
    SET_REMOVE_SELECTED_TIMEKEEPERS_DATA: "SET_REMOVE_SELECTED_TIMEKEEPERS_DATA",
    SET_REMOVE_SELECTED_MATTERS_DATA: "SET_REMOVE_SELECTED_MATTERS_DATA",
    SET_MATTER_LISTING_DATA:"SET_MATTER_LISTING_DATA",
    SET_ORG_DETAIL:"SET_ORG_DETAIL",
    SET_INPUT_DATA:"SET_INPUT_DATA"
  };

  export default types;
