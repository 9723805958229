import { createUseStyles } from "react-jss";

const LoginPageStyles = createUseStyles({
  loginPageContainer: {
    textAlign: "center",
    width: "343px",
    margin: "150px auto"
  },
  label: {
    textAlign: "left",
    paddingTop: "24px"
  },
  button: {
    marginTop: "12px",
    paddingLeft: "190px",
    paddingTop: "12px"
  },
  loginButton: {
    marginTop: "10px",
    marginBottom: "24px",
  },
  loginHeading: {
    display: "inline-flex",
    marginBottom: "20px",
    marginRight:"20px"
  },
  spark: {
    paddingRight: "8px"
  },
  showButton: {
    "& button": {
      padding: "10px"
    }
  },
  loginText: {
    paddingTop: "0.25rem"
  },
  errorInvalidUser:{
    marginTop: "20px",
    textAlign: "left"
  },
  passwordField: {
    "& > div": {
      alignItems: 'center'
    }
  }
})

export default LoginPageStyles;

