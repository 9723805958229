export const TAB_LABEL = {
    OVERVIEW: "Overview",
    DETAILS: "Details",
    DOCUMENTS: "Documents",
    MATTER_DOCUMENTS: "Matter Document",
    RECENTLY_MODIFIED: "Recently Modified",
    MATTER_STARRED: "My Starred",
    FINANCIALS: "Financials",
    BUDGETS: "Budgets",
    INVOICES: "Invoices",
    PEOPLE: "People",
    NOTES: "Notes",
    LINE_ITEMS: "Line Items",
    FEES: "Fees",
    EXPENSES: "Expenses",
    TIMELINE: "Timeline",
    ASSOCIATED_MATTERS: "Associated Matters",
    MATTER_SPECIFIC_RATES: "Matter Specific Rates",
    PRACTICE_AREA_RATES: "Practice Area Rates",
    MATTERS: 'Matters',
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    REJECTED: "Rejected",
    PENDING: "Pending",
}
