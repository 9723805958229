import { createUseStyles } from "react-jss";

const RemoveTimekeepersSelectMatters = createUseStyles({
  dataTableWrap: {
    "& div>table>tbody>tr>td": {
      borderBottom: "none",
      fontFamily: "Bogle",
      height: "24px",
      fontWeight: "normal",
      fontStrech: "normal",
      lineHeight: "1.33",
    },
    padding: "36px"
  },
  heading: {
    padding: "72px 84px 14px 48px",
    fontSize: 36,
    fontWeight: "bold",
  },
  modalSubText: {
    fontSize: 18,
    fontWeight: 'normal',
    paddingBottom: "8px"
  },
});

export default RemoveTimekeepersSelectMatters;
