import { createUseStyles } from "react-jss";
import { SPACING } from "../../../constants/styleGuide";

/**
 *
 * @visibleName Organization Detail styles used in the component.
 */

const OrganizationDetailStyles = createUseStyles({
  leftContent: {
    paddingLeft: SPACING.s40,
    paddingRight: SPACING.s72,
  },
  rightContent: {
    paddingRight: SPACING.s40,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  middleContent: {
    paddingRight: SPACING.s72,
  },
  pageContainer: {
    "& div": {
      backgroundColor: "#FFFFFF",
    },
  },
  editOrganization: {
    fontFamily: "Bogle",
    fontSize: "24px",
    fontWeight: "bold",
    paddingLeft: SPACING.s40,
    marginBottom: "32px"
  },
  btnGroup: {
    justifyContent: "flex-end",
    paddingTop: "24px",
    paddingRight: "64px",
    paddingBottom: "40px",
  },
  orgField: {
    marginTop: "16px"
  },
  contentSpacing24: {
    paddingTop: SPACING.s40,
    paddingBottom: SPACING.s40,
  },
  contentPadding24: {
    paddingTop: SPACING.s40,
  },
  addressTypeField: {
    width: "40%",
  },
  addressLine1Field: {
    width: "80%",
  },
  firmEmailAddressField: {
    width: "50%",
  },
  width15per: {
    width: "15%",
  },
  width30per: {
    width: "30%",
  },
  width35per: {
    width: "35%",
  },
  width40per: {
    width: "40%",
  },
  paddingLeft: {
    paddingLeft: "20px",
  },
  parent: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between"
  },
  parent1: {
    flex: 1,
    display: "flex",
  },
  accordianHeading: {
    marginLeft: "22px"
  },
  city: {
    paddingRight: "10px",
    "& input": {
      width:"180px"
    },
    "& div > div": {
      marginTop:"9px"
    }
  },
  state: {
    width: "180px",
  },
  country: {
    width: "200px",
    marginTop: "9px"
  },
  stateWrapper: {
    paddingRight: "10px",
  },
  countryWrapper: {
    width: "200px"
  }
});

export default OrganizationDetailStyles;
