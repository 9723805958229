import { createUseStyles } from "react-jss";
import { SPACING } from "../../constants/styleGuide";

const LoadingCardsStyles = createUseStyles({
    contentSpacing16: {
        paddingTop: SPACING.s16,
    },
    cards:{
      borderRadius: "5px",
    }
})

export default LoadingCardsStyles;

