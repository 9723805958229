import React from 'react';
import { Spinner } from "@walmart-web/livingdesign-components";

import { TableBody, TableBodyCell, TableBodyRow } from 'mui-datatables';
import { createUseStyles } from 'react-jss';
import { Typography, TableBody as MuiTableBody } from '@mui/material';

const useStyles = createUseStyles(() => ({
    loading: {
      padding: '30px 0',
      textAlign: 'center'
    }
  })
);

const LoadingTableBody = ({ loading, options, columns, ...others }) => {
  // @ts-ignore
  const visibleColCnt = columns?.filter(c => c?.display === 'true')?.length;
  const classes = useStyles();
  return loading ? (
    <MuiTableBody>
      <TableBodyRow options={options}>
        <TableBodyCell
          // @ts-ignore
          colSpan={
            options?.selectableRows !== 'none' || options?.expandableRows
              ? visibleColCnt + 1
              : visibleColCnt
          }
          options={options}
          colIndex={0}
          rowIndex={0}
          data-testid="datatable-spinner"
        >
          <Typography variant="body1" className={classes.loading} component={'div'}>
            <Spinner color="gray" size="small" />
          </Typography>
        </TableBodyCell>
      </TableBodyRow>
    </MuiTableBody>
  ) : (
    <TableBody options={options} columns={columns} {...others} />
  );
};

export default LoadingTableBody;
