import { createUseStyles } from "react-jss";
import { SPACING } from "../../../../constants/styleGuide";

const BudgetTimelineStyles = createUseStyles({
  timelineDate: {
    fontSize: "12px",
    color: "#74767c",
    paddingLeft: SPACING.s16
  },
  timelineHeaderDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    paddingLeft: SPACING.s16,
    "& p": {
      marginBottom: '5px'
    }
  },
  headerTime: {
    color: "#74767c",
    paddingRight: SPACING.s16
  },
  historyGroups: {
    "&:last-child": {
      marginBottom: '32px'
    },
    "& historyDetail:last-child": {
      marginBottom: '0px'
    }
  },
  historyDetail: {
    marginBottom: '0px',
  },
  versionNumber: {
    color: "#74767c",
    paddingLeft: SPACING.s16,
    margin: 0,
    fontSize: '15px'
  },
  viewVersionButton: {
    textAlign: 'right',
    marginTop: '-15px'
  }
});

export default BudgetTimelineStyles;
