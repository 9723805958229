import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { EDITABLE } from "../../constants/constants";
import MatterComponentStyles from "./MatterComponentStyles";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TextField, Autocomplete } from "@mui/material";
import { Box } from "@mui/system";
import { Body } from "@walmart-web/livingdesign-components";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
   * Function to return field Label with optional *(mandatory) symbol
   * @param {*} label
   * @param {*} isRequired
   * @returns
 */
const FieldLabel = ({ label, isRequired }) => {
  return (<Body as="p" size="small">
    <strong>{`${label}${isRequired ? '*' : ''}:`}</strong>
  </Body>)
}

const MatterMultiSelect = ({ node, handleInputChange }) => {
  const classes = MatterComponentStyles();
  const sectionInput = useSelector((state) => state?.matter?.matterDetailsTab);
  const nodeValue = sectionInput?.[node?.propertyName] ?? node?.value ?? node?.defaultValue;

  return (node?.displayProperty === EDITABLE ?
    <div className={classes.contentSpacing16}>
      <FieldLabel label={node?.title} isRequired={node?.required} />
      <Autocomplete
        multiple={true}
        disablePortal
        disabled={node?.displayProperty !== EDITABLE}
        data-testid="matter-multi-select"
        noOptionsText={nodeValue?.length < 1 ? "Please type to search" : "No options" }
        options={
          (node?.range)? node?.range : []
        }
        getOptionLabel={(option) =>`${option}`}
        renderOption={(props, option, {selected}) => (
          <Box component="li" {...props} key={option}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option}
          </Box>
        )}
        value={nodeValue === '' ? [] : nodeValue}
        defaultValue={[]}
        onChange={(event, value) => {
          handleInputChange(node?.propertyName, value)}
        }
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              placeholder: node?.label,
              name:node?.propertyName,
              required:node?.required,
              ...params.inputProps, style: {
                color: '#000000',
                fontFamily: 'Bogle',
                fontSize: 16,
              }
            }}
            margin="dense"
            placeholder={node?.label}
            variant="outlined"
            data-testid={"matter-multi-select-input"}
          />
        )}
        isOptionEqualToValue={(option, value) => option === value}
        id={node?.label}
        margin="dense"
        placeholder={node?.label}
        variant="outlined"
      />
      </div> : <></>
  );
};

export default MatterMultiSelect;


MatterMultiSelect.propTypes = {
  /**
   * Data to preselect applied filter and handle any filter state modification from outside panel
   * */
  node: PropTypes.object,
  /**
   * Callback funtion to handle filter operation
   * */
  handleInputChange: PropTypes.func
};

