import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import {ChevronDown} from "@walmart-web/livingdesign-icons";
import {
  Panel,
  Checkbox,
  Divider,
  Button,
  Grid,
  GridColumn
} from "@walmart-web/livingdesign-components";
import moment from 'moment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from "@mui/material/Autocomplete";
import {isEmptyObj, titleCase} from "../../../../helpers/utils";
import AutocompleteMultiSelect from "../../../Autocomplete/AutocompleteMultiSelect/AutocompleteMultiSelect";
import OrganizationAutocomplete from "../../../Autocomplete/OrganizationAutocomplete/OrganizationAutocomplete";
import PeopleAutocomplete from "../../../Autocomplete/PeopleAutocomplete/PeopleAutocomplete";
import PeopleMultiAutocomplete from "../../../Autocomplete/PeopleMultiAutocomplete/PeopleMultiAutocomplete";
import MatterFilterAutocomplete from "../../../Autocomplete/MatterFilterAutocomplete/MatterFilterAutocomplete";
import MatterFilterMultiAutocomplete from "../../../Autocomplete/MatterFilterMultiAutocomplete/MatterFilterMultiAutocomplete";
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import FilterPanelStyles from "./FilterPanelStyles";
import MasterDataAutocomplete from "../../../Autocomplete/MasterDataAutocomplete/MasterDataAutocomplete";
import MasterDataMultiAutocomplete from "../../../Autocomplete/MasterDataMultiAutocomplete/MasterDataMultiAutocomplete";
import { CLEAR, CLEAR_ALL_FILTERS, DONE } from "../../../../constants/actionButtons";
import { START_END_DATE_VALIDATION_MESSAGE } from "../../../../constants/constants";
import { setExternalPeopleDropDownData, setMatterFilterDropDownData, setOrganizationDropDownData, setPeopleDropDownData } from "../../../../store/actions/masterDataAction";
import { useDispatch } from "react-redux";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, List, ListItem, ThemeProvider, createTheme, TextField, Select, MenuItem } from "@mui/material";

const getMuiTheme = () => createTheme({
  component: {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0',
          display: "block"
        }
      }
    },
    MuiAccordionActions: {
      styleOverrides: {
        root: {
          left: '0',
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'space-between',
          position: 'absolute',
          bottom: '0',
          padding: '24px',
          background: '#fff',
          width: 'calc(100% - 48px)',
          boxShadow: '0 5px 10px 3px rgb(0 0 0 / 15%)',
          zIndex: '1',
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0',
          minHeight: '48px',
          fontWeight: 'bold',
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          notchedOutline: {
            border: 'none',
          }
        }
      }
    }
  }
})

export const getIcon = (iconText) => {
  let compn = '';
  if(iconText === 'dollor'){
    compn = <AttachMoneyOutlinedIcon />
  }
  return compn;
}

/**
 * Function to generate checkbox list fiter category
 *
 * @param {*} item - filter config item with option and key
 * @param {*} filtersSelected - state that holds applied filter list
 * @param {*} clickHandler - Handler function on checkbox selection
 * @returns List of checkbox items
 */
const CheckboxListComponent = ({item, filtersSelected, clickHandler}) => (
  <List disablePadding={true} data-testid="filter-panel-checkbox">
    {item?.options?.map(checkboxLabel => (
      <ListItem disableGutters={true} divider={false} key={checkboxLabel.toString()} data-testid={`filter-panel-item-${checkboxLabel.toString().split(" ").join('-')}`}>
        <Checkbox label={checkboxLabel}
          onChange={event => clickHandler(event, checkboxLabel, item?.colKey)}
          checked={filtersSelected[item?.colKey]?.includes(checkboxLabel)}
          id={`filter-panel-item-${checkboxLabel.toString().split(" ").join('-')}`}/>
      </ListItem>
      )
    )}
  </List>
);

/**
 * Filter Panel component which slides in on Filter Button CLick
 *
 * @visibleName Filter Panel
 */
const FilterPanel = (props) => {
  const {isOpen, togglePanel, applyFilterOnDataTable, filterConfigs, filterInput, groupFilter} = props;
  const [filtersSelected, setFiltersSelected] = useState(filterInput);
  const [isAnyActiveFilter, setIsAnyActiveFilter] = useState(false);
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [rangePickerValues, setRangePickerValues] = useState({startDate: '', endDate: '', colKey:''});
  const classes = FilterPanelStyles();
  const totalFilterItemsCount = filterConfigs?.length;
  const dispatch = useDispatch()
  useEffect(() => {
    let isAnyFilterOn = false;
    Object.entries(filtersSelected)?.forEach(([key, value]) => {
      if(value !== undefined && value?.length > 0) {
        isAnyFilterOn = true;
      }
    });
    setIsAnyActiveFilter(isAnyFilterOn);
    dispatch(setPeopleDropDownData([]))
    dispatch(setExternalPeopleDropDownData([]))
    dispatch(setOrganizationDropDownData([]))
    dispatch(setMatterFilterDropDownData([]))
  }, [filtersSelected])

  useEffect(() => {
    setFiltersSelected(filterInput);
  }, [filterInput])

  /**
   * Function to update filtersSelected state on checkobox item select
   *
   * @param {*} e - click event
   * @param {*} value - user selected value
   * @param {*} colKey - key to map to the data set
   */
  const checkboxClickHandler = (e, value, colKey) => {
    if(filtersSelected[colKey] !== undefined) {
      filtersSelected[colKey]?.includes(value) ?
        setFiltersSelected({...filtersSelected, [colKey]: filtersSelected[colKey]?.filter(c => c !== value)})
        : setFiltersSelected({...filtersSelected, [colKey]: [...filtersSelected[colKey], value]})
    } else {
      setFiltersSelected({...filtersSelected, [colKey]: [value]});
    }
  }

  /**
   * Function to update filtersSelected state on select dropdown change
   *
   * @param {*} event - click event
   * @param {*} value - user selected value
   * @param {*} colKey - key to map to the data set
   */
  const selectDropdownClickHandler = (event, value, colKey) => {
    if(value!==undefined && value!==null && value!=='') {
      setFiltersSelected({...filtersSelected, [colKey]: [value]});
    } else {
      setFiltersSelected({...filtersSelected, [colKey]: []});
    }
  }

  /**
   * Function to update filtersSelected state on select dropdown change
   *
   * @param {*} event - click event
   * @param {*} value - user selected value
   * @param {*} colKey - key to map to the data set
   */
  const selectOrgDropdownClickHandler = (value, colKey, needAdditionalData = false) => {
    if(value!==undefined && value!==null && value!=='') {
      const objKey = `${colKey}-obj`;
      setFiltersSelected({...filtersSelected, [colKey]: [value?.displayValue || value || ""], ...(needAdditionalData && {[objKey] : value?.obj}) });
    } else {
      setFiltersSelected({...filtersSelected, [colKey]: []});
    }
  }

  /**
   * Function to update filtersSelected state on multi select field input
   *
   * @param {*} e - click event
   * @param {*} value - user selected value
   * @param {*} colKey - key to map to the data set
   */
  const multiSelectHandler = (e, value, colKey) => {
    const selectedOptions = (typeof value === 'string' ? value?.split(',') : value);
    setFiltersSelected({...filtersSelected, [colKey]: selectedOptions});
  }

  /**
   * Click handler function to send list of filters applied to parent component
   *
   * @param {*} values - Object that carries user applied filter mapped with key
   */
  const filterClickHandler = (values) => {
    if(values?.dateSubmitted && isDateFilter) {
      values.dateSubmitted[0] = moment(values?.dateSubmitted[0]).format("MM/DD/YYYY");
    }
    const filterValues = isEmptyObj(values) ? 'All' : values;
    applyFilterOnDataTable(filterValues);
    togglePanel(false);
    setIsDateFilter(false)
  }

  /**
   * Function to clear filter selected per filter section / across all based on prop passed
   *
   * @param {*} key - Holds either 'All' / filter section name
   */
  const clearClickHandler = (key) => {
    if(key !== 'All') {
      setFiltersSelected(prevState => ({ ...prevState, [key]: [] }));
    } else {
      const result = filterConfigs?.map((item) => item?.colKey);
      const values = {};
      result?.forEach((item) => {
        values[item] = []
      })
      setFiltersSelected(values);
    }
  }

  const rangeClickHandler = (e, colKey, name) => {
    setRangePickerValues({ ...rangePickerValues, colKey: colKey });
    if (name === 'startDate') {
      setRangePickerValues({ ...rangePickerValues, 'startDate': e.target.value, colKey: colKey });
    } else if (name === 'endDate') {
      setRangePickerValues({ ...rangePickerValues, 'endDate': e.target.value, colKey: colKey });
    }
  }

  useEffect(()=>{
    const sDate = rangePickerValues?.startDate;
    const eDate = rangePickerValues?.endDate;
    const colKey = rangePickerValues?.colKey;
    if ((new Date(sDate) > new Date(eDate))) {
      setStartDateError(false);
      setEndDateError(true);
      setFiltersSelected({...filtersSelected, [colKey]: [sDate, '']});
    }else{
      setFiltersSelected({...filtersSelected, [colKey]: [sDate, eDate]});
      setStartDateError(false);
      setEndDateError(false);
    }
  },[rangePickerValues])

  const submitDatePickerClickHandler = (value, colKey) => {
    if(value === '') {
      setFiltersSelected({...filtersSelected, [colKey]: []});
    } else {
    setFiltersSelected({...filtersSelected, [colKey]: [value]});
    }
    setIsDateFilter(true);
  }

  const textFieldChangeHandler = (e, value, colKey) => {
    if(value === '') {
      setFiltersSelected({...filtersSelected, [colKey]: []});
    } else {
    setFiltersSelected({...filtersSelected, [colKey]: [value]});
    }
  }

  const radioChangeHandler = (e, value, colKey) => {
    setFiltersSelected({...filtersSelected, [colKey]: [value]});
  }

  const LoadLabel = ({loader, node}) => {
    if(loader) {
      return node
    }
    return "";
  }

  /**
   * Function to return Filter component based on type and configures in props.item
   *
   * @param {*} item - Filter item config
   * @returns - Component UI based on filter type
   */
  const filterComponent = ({item}) => {
    let component;
    if(filtersSelected['year']?.length > 0){
      filtersSelected['fiscalMinYear'] = filtersSelected['year'][0];
      filtersSelected['fiscalMaxYear'] = filtersSelected['year'][1];
    }
    switch(item?.type) {
      case 'checkbox':
        component = <>
          <CheckboxListComponent item={item} clickHandler={checkboxClickHandler} filtersSelected={filtersSelected}/>
          <div className={classes.clearButton}>
            <Button size="small" variant="tertiary"
              disabled={!filtersSelected[item?.colKey]?.length>0}
              onClick={() => clearClickHandler(item?.colKey)}
              data-testid="filter-panel-checkbox-clear">
                {CLEAR}
            </Button>
          </div>
        </>
      break;
      case 'dropdownAutocomplete': component = <>
        {LoadLabel({loader: item?.displayLabel === true, node: <label className={classes.boldFont}>{item?.label}</label>})}
        <Autocomplete
          multiple={false}
          disablePortal
          options={item?.options}
          value={filtersSelected[item?.colKey]?.toString() || null}
          renderInput={(params) => <TextField {...params} label="" placeholder={`Select ${item?.label}`} InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {((item?.icon)?getIcon(item?.icon):'')}
                </InputAdornment>
              ),
            }}
          />}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(event, value) => selectDropdownClickHandler(event, value, item?.colKey)}
          data-testid="filter-panel-dropdownAutocomplete"
          id={`filter-dropdown-autocomplete-${item?.colKey}`}
        /></>
      break;
      case 'matterDropdownAutocomplete': component = <>
        {LoadLabel({loader:item?.displayLabel, node: <label className={classes.boldFont}>{item?.label}</label>})}
        <MatterFilterAutocomplete
          value={filtersSelected[item?.colKey]?.toString() || null}
          setValue={(event, value) => {
            selectDropdownClickHandler(event, value, item?.colKey)
          }}
          colKey={item?.colKey}
          name={item?.colKey}
          testid="filter-panel-dropdownAutocomplete"
          id={item?.id}
          isRestricted={item?.isRestricted}
        /></>
      break;
      case 'matterDropdownMultiAutocomplete': component = <>
        {LoadLabel({loader:item?.displayLabel, node: <label className={classes.boldFont}>{item?.label}</label>})}
        <MatterFilterMultiAutocomplete
          value={filtersSelected[item?.colKey] || null}
          setValue={multiSelectHandler}
          colKey={item?.colKey}
          name={item?.colKey}
          testid="filter-panel-dropdownAutocomplete"
          id={item?.id}
          isRestricted={item?.isRestricted}
        /></>
      break;
      case 'masterDataAutocomplete': component = <>
        {LoadLabel({loader:item?.displayLabel, node: <label className={classes.boldFont}>{item?.label}</label>})}
        <MasterDataAutocomplete
            value={filtersSelected[item?.colKey]?.toString()}
            setValue={(value) => {
              selectOrgDropdownClickHandler(value, item?.colKey);
            }}
            dataType={item?.dataType}
            placeholderValue={`Select ${item?.label}`}
            colKey={item?.colKey}
            filterCheck={true}
            testid="filter-panel-dropdownAutocomplete"
            id={item?.id}
        /></>
      break;
      case 'masterDataMultiAutocomplete': component = <>
        {LoadLabel({loader:item?.displayLabel, node: <label className={classes.boldFont}>{item?.label}</label>})}
        <MasterDataMultiAutocomplete
            value={filtersSelected[item?.colKey]}
            setValue={multiSelectHandler}
            dataType={item?.dataType}
            placeholderValue={item?.label}
            colKey={item?.colKey}
            testid="filter-panel-autocompleteMultiselect"
            id={item?.id}
        /></>
      break;
      case 'orgDropdownAutocomplete': component = <>
        {LoadLabel({loader:item?.label, node: <label className={classes.boldFont}>{item?.label}</label>})}
        <OrganizationAutocomplete
            value={filtersSelected[item?.colKey]?.toString() || null}
            setValue={(value) => {
              selectOrgDropdownClickHandler(value, item?.colKey, true);
            }}
            placeholderValue={`Select ${item?.label}`}
            colKey={item?.colKey}
            filterCheck={true}
            testid="filter-panel-dropdownAutocomplete"
            fromBudget={true}
        /></>
      break;
      case 'peopleDropdownAutocomplete': component = <>
        {LoadLabel({loader:item?.displayLabel, node: <label className={classes.boldFont}>{item?.label}</label>})}
        <PeopleAutocomplete
            value={filtersSelected[item?.colKey]?.toString() || null}
            setValue={(value) => {
              selectOrgDropdownClickHandler(value, item?.colKey, true);
            }}
            placeholderValue={`Select ${item?.label}`}
            colKey={item?.colKey}
            filterCheck={true}
            isExternalUser={item?.externalUser}
            isInternalExternal={item?.isInternalExternal}
            testid="filter-panel-dropdownAutocomplete"
            id={item?.id}
        /></>
      break;
      case 'peopleMultiDropdownAutocomplete': component = <>
        {LoadLabel({ loader: item?.displayLabel, node: <label className={classes.boldFont}>{item?.label}</label> })}
        <PeopleMultiAutocomplete
            value={filtersSelected[item?.colKey] || null}
            setValue={multiSelectHandler}
            placeholderValue={`Select ${item?.label}`}
            colKey={item?.colKey}
            isExternalUser={item?.externalUser}
            isInternalExternal={item?.isInternalExternal}
            testid="filter-panel-autocompleteMultiselect"
            isUserAutoComplete={item?.isUserAutocomplete}
            id={item?.id}
        /></>
      break;
      case 'autocompleteMultiSelect': component = <>
          {LoadLabel({loader: item?.displayLabel === true, node: <label>{item?.label}</label>})}
          <AutocompleteMultiSelect
            label={item?.label}
            options={item?.options}
            colKey={item?.colKey}
            placeholder={`Select ${item?.label}`}
            handleOnChange={multiSelectHandler}
            defaultValue={filtersSelected[item?.colKey]}
            data-testid="filter-panel-autocompleteMultiSelect"
            id={item?.id}
          />
        </>
      break;
      case 'dropdown': component = <>
          {((item?.displayLabel === true)?<label>{item?.label}</label>:"")}
          {LoadLabel({loader: item?.displayLabel === true, node: <label>{item?.label}</label>})}
          <Select
            value={filtersSelected[item?.colKey]?.toString()}
            onChange={(event, value) => selectDropdownClickHandler(event, event.target.value, item?.colKey)}
            data-testid="filter-panel-dropdown"
            id={item?.id}
          >
            <MenuItem value="" className={classes.displayNone}>{`Select ${item?.label}`}</MenuItem>
            {item?.options?.map((option) =>
              <MenuItem key={option} value={option}>{option}</MenuItem>
            )}
          </Select>
        </>
      break;
      case 'radio': component = <>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            data-testid="filter-panel-radio"
            value={filtersSelected[item.colKey]?.toString()}
            name={item?.colKey}
            onChange={(e) => {
              radioChangeHandler(e, e.target.value, item?.colKey);
            }}
          >
            {item?.options?.map((option, idx) =>
              <FormControlLabel
                key={option}
                value={option}
                control={
                  <Radio
                    color="default"
                    size="small"
                    name={item?.colKey}
                    data-testid={`filter-radio-${item?.colKey}-${idx}`}
                  />
                }
                label={option}
              />
            )}
          </RadioGroup>
        </>
      break;
      case 'rangePicker': component = <>
        <Grid>
          <GridColumn sm={6}>
            <div className={classes.marginSpaceLeft}>
              {((item?.displayLabel === true)?<label style = {{fontWeight: "bold"}}>Start Date</label>:"")}
              <TextField
                value={rangePickerValues?.startDate}
                type="date"
                data-testid="filter-panel-start_date"
                id="filter-panel-start_date"
                error={startDateError}
                helperText={startDateError && START_END_DATE_VALIDATION_MESSAGE}
                onChange={(e) => {
                  rangeClickHandler(e, item?.colKey, 'startDate');
                }}
              />
            </div>
          </GridColumn>
          <GridColumn sm={6}>
            <div className={classes.marginSpaceRight}>
            {((item?.displayLabel === true)?<label style = {{fontWeight: "bold"}}>End Date</label>:"")}
              <TextField
                value={rangePickerValues?.endDate}
                type="date"
                data-testid="filter-panel-end_date"
                id="filter-panel-end_date"
                error={endDateError}
                helperText={endDateError && START_END_DATE_VALIDATION_MESSAGE}
                onChange={(e) => {
                  rangeClickHandler(e, item?.colKey, 'endDate');
                }}
              />
            </div>
          </GridColumn>
        </Grid>
      </>
        break;
        case 'datePicker': component = <>
        <Grid>
          <GridColumn sm={12}>
            <div className={classes.marginSpaceLeft}>
              {((item?.displayLabel === true)?<label style = {{fontWeight: "bold"}}>{item?.label}</label>:"")}
              <TextField
                value={filtersSelected[item?.colKey]}
                type="date"
                data-testid="filter-panel-datePicker"
                id="filter-panel-datePicker"
                onChange={(e) => {
                  submitDatePickerClickHandler(e.target.value, item?.colKey);
                }}
              />
            </div>
          </GridColumn>
        </Grid>
        </>
        break;
      default: return<></>
    }

    return component;
  }

  const filterList = (filterConfigs, groupFilter) => {
    const customFilter =(props)=>{
      const{item, idx} = props;
      return ((item?.width) ? <div className={classes.fullWidthTextBox} style={{display: "inline-block", width: "49%", marginRight: "1%"}}>
      {filterComponent({item: item})}
    </div> : <span className={classes.bottomSpace} key={idx}>{filterComponent({item: item})}</span>)
    }
    let view = filterConfigs?.map((item, index) => (
      <div key={item?.colKey}>
        <Accordion defaultExpanded={item?.defaultOpen} elevation={0}>
          <AccordionSummary  expandIcon={<ChevronDown/>}>
            {(item?.accordian)?item?.accordian:item?.label}
          </AccordionSummary>
          <AccordionDetails>
            {(item?.type === 'textField') ? (
              <>
                {LoadLabel({loader: item?.displayLabel === true, node: <label className={classes.boldFont}>{item?.label}</label>})}
                <div style={{ display: "inline-grid", width: "100%" }}>
                  <TextField
                    value={filtersSelected[item?.colKey]}
                    onChange={(event, value) => textFieldChangeHandler(event, event.target.value, item?.colKey)}
                    data-testid="filter-panel-textfield"
                    id={`filter-panel-textfield-${item?.colKey}`}
                    type="text"
                  />
                </div>
              </>
            ) : (
              filterComponent({item: item})
            )}
          </AccordionDetails>
        </Accordion>
        {index+1 < totalFilterItemsCount &&
          <div clasName={classes.top20}><Divider/></div>
        }
      </div>
    ));

    if(groupFilter === true){
      const lists = filterConfigs.reduce(function (r, a) {
        r[a.accordian] = r[a.accordian] || [];
        r[a.accordian].push(a);
        return r;
      }, Object.create(null));

      view = Object.entries(lists)?.map(([key, items], index) => (
        <div key={key}>
          <Accordion defaultExpanded={items[0].defaultOpen} elevation={0}>
            <AccordionSummary  expandIcon={<ChevronDown/>}>
              {key}
            </AccordionSummary>
            <AccordionDetails>
              {items?.map((item, idx) => (
                (item?.type === 'textField') ? (
                  <div style={{ display: "inline-grid", width: item?.width || "100%",
                   marginLeft : item?.marginLeft, paddingBottom: item?.paddingBottom || 0, marginBottom : item?.marginBottom || 0}} key={item.colKey}>
                    {LoadLabel({loader: item?.displayLabel === true, node: <label className={classes.boldFont}>{item?.label}</label>})}
                    <TextField
                      value={filtersSelected[item?.colKey]}
                      onChange={(event, value) => textFieldChangeHandler(event, event.target.value, item?.colKey)}
                      data-testid="filter-panel-textfield"
                      id={`filter-panel-textfield-${item?.colKey}`}
                      type="text"
                      onInput = {(e) =>{
                        if(item?.isPositiveNumber)
                        {
                          e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                        }
                        else if(item?.isPositiveDecimalNumber){
                          e.target.value = e.target.value.replace(/[^0-9.]/g, '') // Remove non-numeric characters except for the decimal point
                          const decimalIndex = e.target.value.indexOf('.');// Ensure there's only one decimal point
                          if (decimalIndex !== -1) {
                            e.target.value = e.target.value.slice(0, decimalIndex + 1) + e.target.value.slice(decimalIndex + 1).replace(/\./g, '');
                          }
                        }
                      }}
                    />
                  </div>
                ) : customFilter({ item: item, idx: idx })
              ))}
            </AccordionDetails>
          </Accordion>
          {index + 1 < totalFilterItemsCount &&
            <div clasName={classes.top20}><Divider/></div>
          }
        </div>
      ));
    }

    return view;
  }

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Panel
        isOpen={isOpen}
        onClose={() => togglePanel(false)}
        position="right"
        size="medium"
        title="Filters"
        data-testid="filter-panel"
      >
        <div className={classes.accordionItemsWrap}>
          {filterList(filterConfigs, groupFilter)}
        </div>
        <AccordionActions>
          <Button size="medium" variant="secondary"
            disabled={!isAnyActiveFilter} onClick={() => clearClickHandler('All')}
            data-testid="filter-panel-clear-button" id="filter-panel-clear-button"
          >
            {CLEAR_ALL_FILTERS}
          </Button>
          <Button size="medium" variant="primary" onClick={() => filterClickHandler(filtersSelected)} data-testid="filter-panel-done-button" id="filter-panel-done-button">
            {DONE}
          </Button>
        </AccordionActions>
      </Panel>
    </ThemeProvider>
  );
}

export default FilterPanel;

FilterPanel.propTypes = {
  /**
   * Flag that hold initial state of panel
   * */
  isOpen: PropTypes.bool,
  /**
   * Gets called when the user clicks on the filter button
   * */
  togglePanel: PropTypes.func,
  /**
   * Callback funtion to handle filter operation
   * */
  applyFilterOnDataTable: PropTypes.func,
  /**
   * Configuration Array to dynamically render filter types
   * */
  filterConfigs: PropTypes.array,
  /**
   * Data to preselect applied filter and handle any filter state modification from outside panel
   * */
  filterInput: PropTypes.object
};
