import { createUseStyles } from "react-jss";

const contentBox = "content-box";

const StarItemStyles = createUseStyles({
  star: {
    '.matterDetailStarButton &': {
      marginTop: "5px",
      marginRight: "24px",
      height: "24px",
      width: "24px",
      boxSizing: contentBox
    },
    '.dataTableStarButton &': {
      padding: "6px",
      boxSizing: contentBox
    },
    '.notificationsStarButton &': {
      width: "30px",
      height: "16px",
      boxSizing: contentBox
    }
  },
  starFilled: {
    '.matterDetailStarButton &': {
      marginTop: "-5px",
      marginRight: "14px",
      height: "44px",
      width: "44px"
    },
    '.notificationsStarButton &': {
      width: "30px",
      height: "30px"
    }
  },
  icon: {
    cursor: "pointer",
  },
  spinner: {
    '.matterDetailStarButton &': {
      padding: "5px 25px",
    }
  },
  disabled: {
    opacity: "0.6",
  }
});

export default StarItemStyles;
