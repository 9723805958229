import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import { renderTernary } from "../helpers/utils";

/*
*   This component is used to protect access of routes
*   If allowedActions matches with responsibility codes coming from API
*   then route will render
*   else it will redirect to unathorised i.e.Access Denied component
*/
export const ProtectedRoutes = ({ allowedActions=[], ...rest}) => {
    const location = useLocation();
    const accessibleResponsibilities = useSelector((state) => state?.user?.responsibilityCodes);
    const isAllowed = () => {
        return accessibleResponsibilities?.some(item => allowedActions.includes(item))
    }
    if(allowedActions?.length == 0 || isAllowed()) {
        return <Route {...rest}></Route>;
    }
    else {
       return <Redirect from={renderTernary(location?.pathname,location?.pathname,"")} to="/unauthorised" />;
    }
}
