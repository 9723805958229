import { createUseStyles } from "react-jss";

const SelectMatterStyles = createUseStyles({
  dataTableWrap: {
    "& div>table>tbody>tr>td": {
      fontFamily: "Bogle",
      height: "24px",
      fontWeight: "normal",
      fontStrech: "normal",
      lineHeight: "1.33",
    },
    padding: "36px 0 0"
  },
  rightAlign: {
    textAlign: 'right'
  },
  pageContent: {
		padding: '25px 30px',
		marginLeft: '5px'
	},
  heading: {
    padding: "",
    fontSize: 36,
    fontWeight: "bold",
  },
  modalSubText: {
    fontSize: 18,
    fontWeight: 'normal',
    paddingBottom: "8px"
  },
  modalNoSelectionStyle: {
    fontSize: 16,
    fontWeight: 'normal',
    paddingLeft: "24px"
  },
  timeKeepersContainer: {
    padding: "18px",
    marginTop: "18px",
    backgroundColor: "#F2F8FD",
    border: "1px solid #80B8EE",
    borderRadius: "8px",
    alignItems: "center"
  }
});

export default SelectMatterStyles;
