import React from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import {
  Grid,
  GridColumn,
  Tag,
  Divider,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  IconButton,
  Body,
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import { CloudDownload } from "@walmart-web/livingdesign-icons";
import { CopyLink } from "../../constants/images";
import DisplayValue from "../../components/TextField/DisplayValue/DisplayValue";
import { SPACING } from "../../constants/styleGuide";
import {
  MATTER_NAME,
  MATTER_NUMBER,
  CREATED_BY,
  TAGS,
  VERSION,
} from "../../constants/constants";
import DocumentViewer from "../../components/DocumentViewer/DocumentViewer";
import moment from "moment";
import { postService,postServiceWithHeaders } from "../../axios/axios";
import { useParams } from "react-router-dom";
import { setDocumentBreadcrumbIndex, setFolderSearchQuery } from "../../store/actions/documentActions";
import { NODE_JS_URL } from "../../constants/baseURLs";
import { getErrorMessage } from "../../helpers/utils";


/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  rightContent: {
    paddingTop: SPACING.s32,
    paddingLeft: SPACING.s48,
    paddingRight: SPACING.s48,
  },
  contentSpacing8: {
    paddingTop: SPACING.s8,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  tag: {
    display: "inline-block",
    margin: "5px 5px 0 0",
  },
  documentPreviewWrap: {
    padding: "24px 48px 32px 32px",
    '&.previewModal': {
      padding: '0 24px 24px 24px',
      width: 'calc(100% - 48px)'
    }
  },
  infoIconSpacing: {
    marginLeft: SPACING.s8,
  },
  pointerCursor: {
    cursor: "pointer",
  },
  docTitle: {
    fontSize: '36px',
    marginTop: SPACING.s24,
    marginLeft: SPACING.s16,
    marginBottom: SPACING.s16,
    float: 'left',
  },
  tagsLabel: {
    display: 'inline-block',
    fontWeight: 'bold',
    marginBottom: '11px',
  },
  makePublicModalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxWidth: '477px',
    background: '#fff',
    borderRadius: '8px',
    padding: '24px',
  },
  makeModalButtonGroup: {
    justifyContent: 'flex-end',
    paddingTop: '16px',
    paddingBottom: '8px'
  },
  makePublicTitleWrap: {
    paddingBottom: '8px'
  }
});

const DocumentPreview = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isModal = Boolean(props?.openInModal);
  const documentDetails = useSelector((state) => state?.document?.documentMetadata);
  const documentMatterDetails = useSelector((state) => state?.document?.documentMatterDetails);
  const breadCrumbFolders = useSelector((state) => state?.document?.breadCrumbFolders);
  let { documentId } = useParams();
  if(isModal) {
    documentId = documentDetails?.fileID;
  }
  const { addSnack } = useSnackbar();
  const type = documentDetails?.fileExtension;
  const dispatch = useDispatch();



  /**
  * Fumction to trigger callback on file download
  */
  const onClickDownLoad = () => {
    postServiceWithHeaders(NODE_JS_URL, `/download?version=${documentDetails?.version}`, { id: documentId },{},"blob")
      .then((res) => {
        const url = URL.createObjectURL(res?.data);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.setAttribute("download", documentDetails?.fileName);
        document.body.appendChild(anchor);
        anchor.click();
      })
      .catch((error) => {
        addSnack({
          message: getErrorMessage(error)
        });
      })
  }

  /*
*  This function calls API to share file link
*/
  const shareDocument = () => {
    postService(NODE_JS_URL, `/share?fileId=${documentDetails?.fileID}&version=${documentDetails?.version}`, {})
      .then((res) => {
        navigator.clipboard.writeText(res?.data?.url).then(() => {
          addSnack({
            message: `${documentDetails?.fileName} LINK_COPIED`,
          });
        });
      })
      .catch((error) => {
        addSnack({
          message: getErrorMessage(error)
        })
      })
  }

  const handleBreadCrumb = ( index ) => {
    dispatch(setDocumentBreadcrumbIndex(index));
    dispatch(setFolderSearchQuery(breadCrumbFolders?.[index]?.value));
    history.push(`/matter/${documentMatterDetails?.identifier}`,{ currentMatterTab: 2 })
  }


  const handleMatterBreadCrumb = ( index ) => {
    history.push(`/matter/${documentMatterDetails?.identifier}`,{ currentMatterTab: 2 })
  }

  return (
    <div className={`${classes.documentPreviewWrap} ${isModal ? "previewModal" : ''}`}>
      {!isModal &&
        <Grid data-testid="preview-header-section">
          <GridColumn sm={10}>
            {documentMatterDetails?.matterNumber &&
              <Breadcrumb>
                <BreadcrumbItem data-testid="breadcrumb-matter" onClick={() => history.push('/')}>{'Matter'}</BreadcrumbItem>
                <BreadcrumbItem data-testid="breadcrumb-matterNumber" onClick={() => handleMatterBreadCrumb()}>{documentMatterDetails?.matterNumber}</BreadcrumbItem>
                {
                  breadCrumbFolders?.map((item, idx) =>
                    <BreadcrumbItem data-testid={`breadcrumb-matter-${item}`} key={`${item?.label}`} onClick={(() => handleBreadCrumb(idx))}>{item?.label}</BreadcrumbItem>
                  )}
              </Breadcrumb>
            }
          </GridColumn>
          <GridColumn sm={2} >
            <IconButton a11yLabel="Download" size="medium" data-testid="doc-download" id="doc-download">
              <CloudDownload size="medium" onClick={() => onClickDownLoad()} />
            </IconButton>
            <IconButton a11yLabel="Copy Link" size="medium" data-testid="doc-copy" onClick={() => shareDocument()}>
              <CopyLink size="medium" />
            </IconButton>
          </GridColumn>
          <GridColumn sm={12}>
            <Heading as="h1" size="large" id="document-preview-modal-title">
              <span className={classes.docTitle}>{documentDetails?.fileName}</span>
            </Heading>
          </GridColumn>
        </Grid>
      }
      <Grid>
        <GridColumn sm={isModal ? 8 : 9}>
          <DocumentViewer fileType={type} fileId={documentDetails?.fileID} fileName={documentDetails?.fileName} fileSize={documentDetails?.IL_FILE_SIZE} version={documentDetails?.version}/>
        </GridColumn>
        <GridColumn sm={isModal ? 4 : 3}>
          <div className={classes.rightContent}>
            <div className={classes.contentSpacing24}>
              <Heading as="h6" size="small">
                Metadata
              </Heading>
            </div>
            <div className={classes.contentSpacing16}>
              <Divider />
            </div>
            <div className={classes.contentSpacing16}>
              <DisplayValue
                label={MATTER_NAME}
                value={documentDetails?.matterName}
              />
            </div>
            <div className={classes.contentSpacing8}>
              <DisplayValue
                label={MATTER_NUMBER}
                value={documentDetails?.matterNumber}
              />
            </div>
            <div className={classes.contentSpacing8}>
              <DisplayValue
                label={"Uploaded Date"}
                value={moment(documentDetails?.createdTimeStamp).format("ddd, MMM DD")}
              />
            </div>
            <div className={classes.contentSpacing8}>
              <DisplayValue
                label={CREATED_BY}
                value={documentDetails?.createdBy}
              />
            </div>
            <div className={classes.contentSpacing8}>
              <DisplayValue
                label={VERSION}
                value={documentDetails?.version}
              />
            </div>
            <div className={classes.contentSpacing16}>
              <Divider />
            </div>
            <div className={classes.contentSpacing16}>
              <Body as="p" variant="2">
                <span className={classes.tagsLabel}>{TAGS}</span>
              </Body>
              <div>
                {documentDetails?.tags?.map((value, idx) =>
                  <div className={classes.tag} key={`${value}`}>
                    <Tag variant="tertiary">{value}</Tag>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.contentSpacing24}>
              <Divider />
            </div>
          </div>
        </GridColumn>
      </Grid>
    </div>
  );
};

export default DocumentPreview;
