import React from 'react';
import { NotFound } from '../../constants/images';
import { ERROR_MSG, PAGE_NOT_FOUND_MSG } from '../../constants/messages';
import {
	Button,
} from "@walmart-web/livingdesign-components";
import ErrorPageStyles from './ErrorPage.styles';
import { GO_BACK } from '../../constants/actionButtons';

/*
*    Error Page is displayed in case of any errors
*    and the page is not accessible
*/
const ErrorPage = () => {
  const classes = ErrorPageStyles();
  return (
    <div className={classes.pageContainer} data-testid="errorPage-container">
      <div className={classes.bannerContainer}>
        <NotFound  className={classes.banner}/>
      </div>
      <div className={classes.text}>{PAGE_NOT_FOUND_MSG}</div>
      <div className={classes.ErrorMsg}>{ERROR_MSG}</div>
      <div className={classes.button}>
        <Button variant="primary" size="medium" data-testid="goBack-btn">
            {GO_BACK}
        </Button>
      </div>
    </div>
  );
};
export default ErrorPage;
