import { Grid, Spinner, useSnackbar } from '@walmart-web/livingdesign-components';
import React, { useEffect, useState } from 'react';
import { postService } from '../../axios/axios';
import { NODE_JS_URL } from '../../constants/baseURLs';
import { getErrorMessage } from '../../helpers/utils';
import { useSelector } from 'react-redux';
import VideoAudioPlayerStyles from './VideoAudioPlayer.styles';
import { VIDEO } from '../../constants/constants';
import PropTypes from 'prop-types';

/**
 * Component used to play video or audio files
 * @param {*} props
 * @returns
 */
const VideoAudioPlayer = (props) => {
    const classes = VideoAudioPlayerStyles();
    const {currentCard,loading} = props
    const [downloadLoading, setDownloadLoading] = useState(false);
    const {trainingGuidesData} = useSelector((state) => state?.helpCenter)
    const {addSnack} = useSnackbar();
    const [url,setUrl] = useState();

    /*
    *  This function calls API to share file link
    */
    const shareDocument = () => {
        setDownloadLoading(true);
        postService(NODE_JS_URL, `/share?fileId=${trainingGuidesData?.contents?.[currentCard]?.location}&version=1`, {isContent: true})
        .then((res) => {
            setUrl(res?.data?.url)
        setDownloadLoading(false);
        }
        )
        .catch((error) => {
            addSnack({
            message: getErrorMessage(error)
            })
        setDownloadLoading(false);
        })
    }

    useEffect(() => {
        shareDocument();
      }, [currentCard]);

    return (
        (downloadLoading || loading) ?
        <Spinner size="large" className={classes.spinner}/>
        :
        <Grid className={classes.docContent}>
          {trainingGuidesData?.contents?.[currentCard]?.mediaType === VIDEO ?
            <video controls autoPlay className={classes.video}>
                <source src={url} controls/>
            </video> :
            <audio controls>
                <source src={url} controls/>
            </audio>}
        </Grid>)
}
 export default VideoAudioPlayer;

 VideoAudioPlayer.propTypes = {
    currentCard: PropTypes.number,
    loading: PropTypes.bool
 }