import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { createUseStyles } from "react-jss";
import { Card, Grid, Skeleton, useSnackbar,
  Button,
  Body,
  Spinner} from "@walmart-web/livingdesign-components";
import { postServiceWithHeaders } from "../../axios/axios";
import { fileMimeType, MAX_FILE_PREVIEW_SIZE, SUPPORTED_PREVIEW_FORMAT } from "../../constants/constants";
import { PREVIEW_NOT_SUPPORTED_MSG } from "../../constants/messages";
import { useParams } from "react-router-dom";
import * as Icon from "@livingdesign/icons";
import { NODE_JS_URL } from "../../constants/baseURLs";
import { getErrorMessage } from "../../helpers/utils";
import { DOWNLOAD } from "../../constants/actionButtons";
/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  docViewerWrap: {
    height: '68vh',
    width: '100%',
    '& #header-bar': {
      minHeight: '40px'
    },
    '.previewModal & #header-bar': {
      minHeight: '0'
    },
    '& #pdf-controls': {
      backgroundColor: '#fff',
      zIndex: '999 !important'
    },
    '& #pdf-pagination-prev': {
      order: 2,
      boxShadow: 'none',
    },
    '& #pdf-pagination-info': {
      order: 1,
      paddingRight: '10px',
    },
    '& #pdf-pagination-next': {
      order: 3,
      boxShadow: 'none',
      marginRight: '0',
    },
    '& #pdf-toggle-pagination': {
      boxShadow: 'none',
    },
    '& #pdf-download': {
      display: "none"
    },
    '& #pdf-zoom-out, & #pdf-zoom-in, & #pdf-zoom-reset, & #pdf-toggle-pagination, & #pdf-pagination-next, & #pdf-pagination-prev': {
      opacity: 0.6
    },
    '& #image-renderer, & #pdf-renderer': {
      backgroundColor: '#f5f5f5',
      boxShadow: 'inset -1px 0 0 0 rgba(0, 0, 0, 0.15)'
    }
  },
  docViewer: {
    height: '68vh'
  },
  previewNotSupported: {
    margin: 20,
    height: "100%",
    alignItems: "center",
  },
  cardContainer: {
    alignSelf: "center",
    height: "68vh",
    textAlign: "center",
    padding: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  downloadButton: {
    alignSelf: "center",
    marginTop: 20
  },
  center: {
  }
});

const PreviewNotSupportedCard = (props) => {
  const classes = useStyles();
  const {loading,directDownload,fileId,fileName, version="1"} = props
   return (
     <Card size="small" className={classes.cardContainer}>
       <div className={classes.center}>
         <Body as="p" size="large" weight={700}>
           {PREVIEW_NOT_SUPPORTED_MSG}
         </Body>
         {
           loading ?
           <Button
           variant="primary"
           size="medium"
           data-testid="spinner-btn"
           className={classes.downloadButton}
         >
           <Spinner color="white" size="small" />
         </Button> :
         <Button
           variant="primary"
           size="medium"
           data-testid="download-btn"
           leading={<Icon.CloudDownload />}
           className={classes.downloadButton}
           onClick={()=>directDownload(fileId,fileName,version)}
         >
           {DOWNLOAD}
         </Button>
         }
       </div>
     </Card>
   );
 }

 export const LoadingDocViewer = (props) => {
  const {classes, DocViewerRenderers, docs, loading} = props;
  return loading ?
  <Skeleton height="100%" variant="rectangle" />
  :
  <DocViewer
    className={classes.docViewer}
    pluginRenderers={DocViewerRenderers}
    documents={docs}
    config={{
      header: {
        disableHeader: false,
        disableFileName: true,
        retainURLParams: false
      }
    }}
  /> 
}

const DocumentViewer = (props) => {
  const { fileType, fileId,fileName, fileSize, version="1"} = props;
  const classes = useStyles();
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ isPreviewSupported, setIsPreviewSupported] = useState(false);
  const { addSnack } = useSnackbar();
  const { documentId } = useParams();

  useEffect(() => {
    // as docviewer is supporting limited files
    if (SUPPORTED_PREVIEW_FORMAT?.indexOf(fileType) > -1 && fileSize < MAX_FILE_PREVIEW_SIZE) {
      setIsPreviewSupported(true);
      downloadFile();
    } else {
      setIsPreviewSupported(false);
    }
  }, []);

  /**
   * This function is called when api call throws an error
   */
  const handleError = (error) => {
    setLoading(false);
      addSnack({
        message: getErrorMessage(error),
      });
  }
  // function to download file data if format is supported
  const downloadFile = () => {
    setLoading(true);
    postServiceWithHeaders(NODE_JS_URL,`/download?version=${version}`,{id: documentId || fileId}, {},"blob")
    .then((res)=>{
      const file = new Blob(
        [res?.data],
        {type: fileMimeType[fileType]});
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDocs([{uri: base64data}]);
          setLoading(false);
      }
    })
    .catch((error)=>{
      handleError(error)
    })
  }

  // function to download file locally
  const directDownload = ( documentId, documentName ) => {
    setLoading(true);
    postServiceWithHeaders(NODE_JS_URL, `/download?version=${version}`, { id: documentId },{},"blob")
    .then((res) => {
      const url = URL.createObjectURL(res?.data);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.setAttribute("download", documentName);
      document.body.appendChild(anchor);
      anchor.click();
      setLoading(false);
    })
    .catch((error) => {
      handleError(error)
    })
  }

  

  return (
    <div className={classes.docViewerWrap} data-testid="doc-viewer-wrap">
      {
        isPreviewSupported ?
          <LoadingDocViewer classes={classes} DocViewerRenderers={DocViewerRenderers} docs={docs} loading={loading} /> :
          <div className={classes.previewNotSupported}>
             <Grid>
                <PreviewNotSupportedCard loading={loading} directDownload={directDownload} fileId={fileId} fileName={fileName} />
             </Grid>
          </div>
      }
    </div>
  );
}

export default DocumentViewer;

DocumentViewer.propTypes = {
  /** file type as string */
  fileType: PropTypes.string,
  /** file id to download file  */
  fileId: PropTypes.string,
  /** file name to download file  */
  fileName : PropTypes.string,
  /** file size to download file  */
  fileSize : PropTypes.string,
};

LoadingDocViewer.propTypes = {
  /** classes object */
  classes: PropTypes.object, 
  /** doc viewer render  */
  DocViewerRenderers: PropTypes.func, 
  /** docsto show  */
  docs: PropTypes.array, 
  /** loading  */
  loading: PropTypes.bool
};

PreviewNotSupportedCard.propTypes = {
  /** loading  */
  loading: PropTypes.bool,
  /** download func  */
  directDownload : PropTypes.func,
  /** file name to download file  */
  fileName : PropTypes.string,
  /** file id to download file  */
  fileId: PropTypes.string
};