import React from 'react';
import {Provider} from 'react-redux';
import App from './App';
import { configureStore } from './store/config/configureStore';
import { injectStore } from './axios/axios';

export const store = configureStore();
store.subscribe(() => { });

// initializing store into axios interceptor
injectStore(store);

const StoreProvider = () => (
  <Provider store={store}>
      <App/>
  </Provider>
);

export default StoreProvider;
