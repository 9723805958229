import { Body, Button, Grid, GridColumn, Heading, useSnackbar, Spinner } from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { RESET_PASSWORD_TEXT, NEW_PASSWORD, RE_ENTER_PASSWORD, SHOW, RESET_PASSWORD_CODE, HIDE, NO_CACHE_HEADER, INVALID_INPUT_PROVIDED } from '../../constants/constants';
import { RESET_PASSWORD } from '../../constants/actionButtons';
import { getService, getServiceWithHeaders, postServiceWithHeaders } from "../../axios/axios";
import { WalmartSpark } from '../../constants/images';
import { generateSecretKey, handleEncryptedString } from "../../helpers/utils";
import MuiValidationMessage from "../../Validation/MuiValidationMessage/MuiValidationMessage";
import { RE_ENTER_PASSWORD_MESSAGE } from "../../Validation/validationMessages";
import { PASSWORD_RESET_SUCCESS } from "../../constants/messages";
import { NODE_JS_URL, USER_SERVICE } from "../../constants/baseURLs";
import { ResetPasswordValidationRules } from "../../Validation/validationRules";
import ResetPasswordStyles from './ResetPassword.styles';
import TextField from "@mui/material/TextField";
import { setAuthSign, setAuthTkn, setStaticTk, setUserLoginTenantId } from "../../store/actions/userActions";
import { useDispatch } from "react-redux";
import { getIAMErrorMessage } from "../../helpers/commonUtils";
import { ERROR_CODES } from "../../constants/errorCodes";

/*
* Error Page is displayed in case of any errors
* and the page is not accessible
*/
const ResetPassword = () => {
  
  const dispatch = useDispatch();
  const classes = ResetPasswordStyles();
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [reEnterPassword, setReEnterPassword] = useState('')
  const [newPasswordType, setNewPasswordType] = useState('password')
  const [reEnterPasswordType, setReEnterPasswordType] = useState('password');
  const [invalidUserError, setInvalidUserError]=useState('');
  const history = useHistory();
  const { addSnack } = useSnackbar();
  const { register, formState: { errors, isValid, isDirty }, setError, clearErrors} = useForm({ mode: "all" });
  const email = localStorage.getItem('forgotPrincipalId');

  /**
*  API call to fetch static tk
*/
	const getTK = () => {
		getService(NODE_JS_URL, "/getTk").then((res) => {
			dispatch(setStaticTk(res?.data));
		}).catch(err => {
			// Letting API fail silently
			setLoading(false);
		})
	}

	/**
   *  API call to Node js for fetching configurations
   */
	const getConfig = () => {
		getService(NODE_JS_URL, "/getConfiguration").then((res) => {
			dispatch(setUserLoginTenantId(res?.data?.configResolution?.resolved?.tenantId));
		}).catch(err => {
			// Letting API fail silently
			setLoading(false);
		})
	}


	/**
  * getA2ADetails - This method used to fetch App to App Auth Details
  */
	const getA2ADetails = () => {
    getServiceWithHeaders(NODE_JS_URL,  "/getA2ATk?"+new Date().getTime(), NO_CACHE_HEADER).then((res) => {
			dispatch(setAuthSign(res?.data));
		}).catch(err => {
			console.log("Err from node app", err)
			dispatch(setAuthSign({}));
			setLoading(false);
		})
	}

  const resetPassword = () => {
    getService(USER_SERVICE,
      `/users/v1/token/${email}`
    ).then((response) => {
      const athtkn = response?.data?.payload?.principalAuthnToken?.authToken;
      dispatch(setAuthTkn(athtkn));
      const entityId = generateSecretKey();
      const headers = { 'X-Entity-Id': entityId }
      const postData = {
        loginId: email,
        token: token,
        password: newPassword
      }
      const payload = handleEncryptedString(JSON.stringify(postData), entityId);
      const body = { "payload": payload };
      postServiceWithHeaders(USER_SERVICE, `/users/v1/resetPassword`,
        body, headers
      ).then((response) => {
        addSnack({ message: PASSWORD_RESET_SUCCESS });
        localStorage.removeItem('forgotPrincipalId');
        setInvalidUserError('')
        history.push('/');
      }).catch((error) => {
        setLoading(false);
        if(error?.response?.data?.errorCode === INVALID_INPUT_PROVIDED) {
          setInvalidUserError(ERROR_CODES.INVALID_INPUT_PROVIDED_FOR_NEW_PASSWORD);
        } else {
          setInvalidUserError(getIAMErrorMessage(error,email))
        }
      });
    })
    .catch((error)=>{
      setLoading(false);
      setInvalidUserError(getIAMErrorMessage(error,email))
    })
  }

  const handleSubmit = () => {
    setLoading(true);
    getA2ADetails();
    getTK();
    getConfig();
    setTimeout(() => {
      resetPassword();
    }, 3000);
  }

  useEffect(() => {
    if (reEnterPassword !== newPassword) {
      setError('reEnterPassword', { type: 'custom', message: RE_ENTER_PASSWORD_MESSAGE });
    } else {
      clearErrors('reEnterPassword')
    }
  }, [newPassword, reEnterPassword])

  /*
*This function is called on show button
*/
  const showNewPassword = () => {
    if (newPasswordType === 'text') {
      setNewPasswordType('password')
    } else {
      setNewPasswordType('text')
    }
  }

  /*
  *This function is called on show button
  */
  const showReEnterPassword = () => {
    if (reEnterPasswordType === 'text') {
      setReEnterPasswordType('password')
    } else {
      setReEnterPasswordType('text')
    }
  }

  return (
    <div className={classes.forgotPageContainer} data-testid="reset-pwd-page-container">
      <div className={classes.heading}>
        <WalmartSpark className={classes.spark}></WalmartSpark>
        <div className={classes.forgotPasswordText}>
          <Heading as="h1" size="medium" weight={700}>
            {RESET_PASSWORD_TEXT}
          </Heading>
        </div>
      </div>
      <div className={classes.showMessageContainer}>
        <p> We have sent a reset password code to the <span>{email}</span> to create a new password.</p>
      </div>
      <form>
        <Grid>
          <GridColumn sm={12}>
            <div className={classes.label}>
              <Body as="p" size="small">
                <strong>{RESET_PASSWORD_CODE}</strong>
              </Body>
            </div>
            <TextField
              data-testid="token"
              id="reset-code"
              type="text"
              error={errors?.token}
              helperText={errors?.token && <MuiValidationMessage message={errors?.token?.message}/>}
              name= "token"
              required= {true}
              value={token}
              {...register(
                'token',
                { ...ResetPasswordValidationRules.token, onChange: (e) => { setToken(e.target.value) } }
              )}
              fullWidth={true}
            />
          </GridColumn>
          <GridColumn sm={12}>
            <div className={classes.label}>
              <Body as="p" size="small">
                <strong>{NEW_PASSWORD}</strong>
              </Body>
            </div>
            <TextField
              data-testid="newPassword"
              id="newPassword"
              type={newPasswordType}
              name= "newPassword"
              required= {true}
              value={newPassword}
              error={errors?.newPassword}
              helperText={errors?.newPassword && <MuiValidationMessage message={errors?.newPassword?.message}/>}
              {...register(
                'newPassword',
                { ...ResetPasswordValidationRules.newPassword, onChange: (e) => { setNewPassword(e.target.value.trim()) } }
              )}
              InputProps={{
                endAdornment: <div className={classes.showButton}>
                <Button size="medium" variant="tertiary" onClick={showNewPassword} data-testid="show-button">
                  {(newPasswordType==='password')?SHOW:HIDE}
                </Button>
              </div>, 
              }}
              fullWidth={true}
            />
          </GridColumn>
          <GridColumn sm={12}>
            <div className={classes.label}>
              <Body as="p" size="small">
                <strong>{RE_ENTER_PASSWORD}</strong>
              </Body>
            </div>
            <TextField
              data-testid="reEnterPassword"
              id="reEnterPassword"
              type={reEnterPasswordType}
              name= "reEnterPassword"
              required= {true}
              value={reEnterPassword}
              error={errors?.reEnterPassword}
              helperText={errors?.reEnterPassword?.message && <MuiValidationMessage message={errors?.reEnterPassword?.message}/>}
              {...register(
                'reEnterPassword',
                { ...ResetPasswordValidationRules.reEnterPassword, onChange: (e) => { setReEnterPassword(e.target.value.trim()) } }
              )}
              InputProps={{
                endAdornment: <div className={classes.showButton}>
                <Button size="medium" variant="tertiary" onClick={showReEnterPassword} data-testid="show-button">
                  {(reEnterPasswordType==='password')?SHOW:HIDE}
                </Button>
              </div>, 
              }}
              fullWidth={true}
            />
          </GridColumn>
          <GridColumn sm={12}>
            <div className={classes.submitButton}>
              <Button
                size="medium"
                variant="primary"
                type="submit"
                isFullWidth
                onClick={handleSubmit}
                disabled={loading || !(isValid && isDirty)}
                data-testid="submit-button"
                id="submit-button"
                trailing={loading && <Spinner color="white" size="small" />}
              >
                {RESET_PASSWORD}
              </Button>
              <div className={classes.errorInvalidUser}>
                {invalidUserError && <MuiValidationMessage message={invalidUserError} />}
              </div>
            </div>
          </GridColumn>
        </Grid>
      </form>
    </div>
  );
};
export default ResetPassword;
