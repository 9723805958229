import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import MUIDataTable, { TableFilterList } from "mui-datatables";
import {
  Chip,
  Button,
  Body,
} from "@walmart-web/livingdesign-components";
import { Close, Search, Plus } from "@walmart-web/livingdesign-icons";
import {
  ROWS_PER_PAGE_DEFAULT,
  ROWS_PER_PAGE_OPTIONS_DEFAULT,
} from "../../../constants/tableConfigs";
import SearchField from "../SearchField/SearchField";
import MuiExportButton from "./MuiExportButton/MuiExportButton";
import LoadingTableBody from "./MuiLoadingTableBody/LoadingTableBody";
import Filter from "../../common/Filter/Filter";
import { LIGHT_DARK_BLACK } from "../../../constants/colors";
import Subheading from "../../TextField/SubHeading/Subheading";
import MuiDataTableTheme from "./MuiDataTableTheme";
import { CLEAR_ALL_FILTERS } from "../../../constants/actionButtons";
import { createUseStyles } from "react-jss";
import { BORDERS } from '../../../constants/styleGuide';
import { ThemeProvider } from "@mui/material";
import { renderTernary } from "../../../helpers/utils";

const useStyles = createUseStyles({
  dataTable: ({tableContentClass, tableContentHeight}) => ({
    boxShadow: 'none !important',
    [tableContentClass]: {
      maxHeight: tableContentHeight,
      overflow: 'auto',
    },
    "& .MuiTablePagination-input input" : {
      display: 'block',
      boxSizing: 'border-box'
    },
    "& .MuiToolbar-regular": {
      borderBottom: '1px solid #eee'
    },
  }),
  noResults: {
    padding: "32px 24px 40px",
    "& h4": {
      fontFamily: "Bogle",
      fontWeight: "bold",
      fontSize: "16px",
    },
    "& p": {
      fontFamily: "Bogle",
      fontSize: "14px",
    },
  },
  clearAllExt: {
    marginBottom: "16px",
    marginTop: "8px",
    "& > button": {
      color: LIGHT_DARK_BLACK,
      fontFamily: "Bogle",
    },
  },
  addNewButton: {
    margin: "0 0.8rem",
  },
  toolbarText: {
    fontFamily : "Bogle",
    fontSize : "20px",
    fontWeight : "700",
    marginLeft : "15px"
  },
  filesTable: {
    boxShadow: "none",
    borderRight: BORDERS.GREY,
    borderLeft: BORDERS.GREY,
    borderRadius: "4px",
    overflow: "hidden",
    padding: 0,
  },
  lineItemsTable: {
    boxShadow: "none",
    borderRadius: "4px",
    overflow: "hidden",
    padding: 0,
  },
  noResultsForUpload: {
    padding: "52px 25px 60px",
  },
  selectedTimeKeeperStyle:{
    paddingLeft:"18px",
    paddingBottom:"5px"
  },
  exportBtn : {
    marginRight: 10
  },
  customMuiClass: {},
});

/**
 * Component to return no results UI
 * @param {string} customText
 */
const NoResults = ({ customText }) => {
  const classes = useStyles({});
  return (
    <div className={classes.noResults}>
      {customText ? (
        <Subheading
          as="h4"
          variant="1"
          data-testid="no-results-mui"
          id="no-results-mui"
          className={classes.noResultsForUpload}
        >
          {customText}
        </Subheading>
      ) : (
        <>
          <Search size="medium" />
          <Subheading as="h4" variant="1" data-testid="no-results-mui">
            No Results Found
          </Subheading>
          <Body as="p" variant="1" id="no-results-mui">
            Try changing the filters or search term
          </Body>
        </>
      )}
    </div>
  );
};

const calcHeight = () => {
  document.contentDivHeight = 250;
  const container_div = document.getElementsByClassName('customMuiClass');
  const count = container_div[0]?.children ? container_div[0]?.children?.length : 0;
  let height = 0;
  for (let i = 0; i < count; i++) {
    const hastableDiv = container_div[0]?.children[i]?.querySelector("table");
    if (hastableDiv !== null) {
      document.contentDivIndex = i;
    } else {
      height = height + container_div[0]?.children[i]?.offsetHeight;
    }
  }

  const tableContentClass = `& div:nth-child(${document.contentDivIndex + 1})`
  let tableContentHeight = `calc(100vh - ${document.contentDivHeight + height}px)`
  if(document.documentElement.clientHeight <= 750) {
    tableContentHeight = '500px';
  }
  return { tableContentClass, tableContentHeight }
}

/**
 * MUI Datatable component
 *
 * @visibleName MUI Datatable
 */
const MuiDataTable = (props) => {
  const {
    data,
    columns,
    onRowClick: handleRowClick,
    onAddNew: handleAddNew,
    addNewLabel,
    onExportClick,
    customOptions,
    setTableColumns,
    loading,
    disableSearch,
    disableFilter,
    disablePagination,
    disableExport,
    selectableRowsType,
    disableAddNew,
    onRowSelection,
    selectedRows,
    noResultsText,
    customClassName = "",
    exportLoading,
    showCustomAddNewButton,
    customAddNewButton,
    groupFilter,
    showRowsCounter,
    customFilterButton,
    hideAddNewButton,
    toolbarText,
    showSelectedRow,
    allowedActions,
    selectedRowMsg,
    resetSelectedRows,
    featureButton
  } = props;
  const [styles, setStyles] = useState({});
  const classes = useStyles(styles);
  const hideAddNewButtonFromAllowedActions = Boolean(hideAddNewButton || (allowedActions && !allowedActions?.addNew));
  const handleRowClickFromAllowedActions = allowedActions?.read ? handleRowClick : null;
  const showRowCounterCondition = (showRowsCounter === true? selectedRows.length > 0 && ` (${selectedRows.length})`: "");
  const addNewButtonConditions = (showCustomAddNewButton ? (
    customAddNewButton
  ) : (
    <Button
      trailing={addNewLabel === "Add New" && <Plus />}
      size="small"
      variant="primary"
      data-testid="add-new-button-datatable"
      id="add-new-button-datatable"
      onClick={handleAddNew}
      className={classes.addNewButton}
      disabled={disableAddNew}
    >
      {addNewLabel}
      {showRowCounterCondition}
    </Button>
  ));

  useEffect(() => {
    resetSelectedRows();
    let styles = calcHeight();
    if((document.documentElement.clientHeight <= 750) && (loading || data?.length  < 1)) {
      styles = { ...styles, tableContentHeight: 'auto' }
    }
    setStyles(styles)
  }, [data, loading])

  const searchField = (searchText, handleSearch)=> (
    <SearchField
      searchText={customOptions?.searchText? customOptions?.searchText : ( searchText || "")}
      serverSide={customOptions?.serverSide  || customOptions?.serverSideSearchFilter}
      onSearch={
        (customOptions?.serverSide || customOptions?.serverSideSearchFilter)
          ? customOptions?.onServerSideSearch
          : handleSearch
      }
      placeHolder={customOptions?.searchPlaceHolder}
    />
  );

  const toolBarText =()=> (toolbarText ? <div className={classes.toolbarText}>{toolbarText}</div> :
  <></>)

  const renderSearch=(searchText, handleSearch) => {
    return disableSearch ? toolBarText()
     : searchField(searchText, handleSearch)
  }

  const renderToolbar=()=>{
    return <>
    {
      hideAddNewButtonFromAllowedActions === false ?
        addNewButtonConditions : <></>
      }
      {(Boolean(disableExport) === false && data?.length !== 0) ? (
        <MuiExportButton
          exportLoading={exportLoading}
          onExportClick={onExportClick}
          className={classes.exportBtn}
        />
      ) : (
        ""
      )}
      {disableFilter ? (
        <></>
      ) : (
        <Filter
          setTableColumns={setTableColumns}
          columns={columns}
          filterConfigs={customOptions?.filterConfigs}
          serverSide={customOptions?.serverSide || customOptions?.serverSideSearchFilter}
          onServerSideFilter={customOptions?.onServerSideFilter}
          groupFilter={groupFilter}
          isMatter={customOptions?.isMatter}
          isDocument={customOptions?.isDocument}
          isPeople={customOptions?.isPeople}
          isInvoice={customOptions?.isInvoice}
          isBudget={customOptions?.isBudget}
          onlyFilter={Boolean(disableExport) && hideAddNewButtonFromAllowedActions}
        />
      )}
      {customFilterButton ?? <></>}
      {renderTernary(featureButton, featureButton, null)}
    </>
}
  /**
   * Configurations for MUI datatable
   */
  const options = {
    responsive:  customOptions?.responsive || "simple",
    selectableRows: selectableRowsType || "none",
    rowsSelected: selectedRows,
    onRowSelectionChange: onRowSelection,
    page: customOptions?.page?? undefined,
    rowsPerPage: customOptions?.rowsPerPage || ROWS_PER_PAGE_DEFAULT,
    rowsPerPageOptions:
      customOptions?.rowsPerPageOptions || ROWS_PER_PAGE_OPTIONS_DEFAULT,
    jumpToPage: false,
    pagination: !Boolean(disablePagination),
    print: false,
    download: false,
    downloadOptions: {
      filename:
        customOptions?.downloadOptions?.filename || "dataTableDownload.csv",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    filter: false,
    selectToolbarPlacement: 'none',
    viewColumns: false,
    onRowClick: allowedActions ? handleRowClickFromAllowedActions : handleRowClick,
    searchAlwaysOpen: true,
    search: !Boolean(disableSearch),
    customSearchRender: renderSearch,
    customToolbar: renderToolbar,
    textLabels: {
      body: {
        noMatch: <NoResults customText={noResultsText} />,
      },
      pagination: {
        rowsPerPage: "Rows per page",
        displayRows: " of ",
      },
    },
    enableNestedDataAccess: ".",
    serverSide: customOptions?.serverSide,
    count: customOptions?.serverSide ? customOptions?.totalCount : 0,
    onTableChange: (action, tableState) => {
      if (customOptions?.serverSide) {
        if (action === "changePage" || action === "changeRowsPerPage") {
          options.rowsPerPage = tableState.rowsPerPage;
          const data = {
            rowsPerPage: tableState.rowsPerPage,
            page: tableState.page,
          };
          customOptions?.onServerSidePagination(data);
        }
        if (action === "sort") {
          customOptions?.onServerSideSort(tableState.sortOrder);
        }
      }
      /*
      Used to send pagination data to the component in case of client side pagination
      */
      if(customOptions?.onClientSidePagination){
        if (action === "changePage" || action === "changeRowsPerPage") {
          options.rowsPerPage = tableState.rowsPerPage;
          const data = {
            rowsPerPage: tableState.rowsPerPage,
            page: tableState.page,
          };
          customOptions?.onClientSidePagination(data);
        }
      }
    },
    // condition to reset page number in case of common table
    ...(customOptions?.customPage !== undefined && {page : customOptions?.customPage}),
    expandableRows: customOptions?.expandableRows,
    expandableRowsHeader: customOptions?.expandableRowsHeader,
    expandableRowsOnClick: customOptions?.expandableRowsOnClick,
    isRowExpandable: customOptions?.isRowExpandable,
    rowsExpanded: customOptions?.rowsExpanded,
    renderExpandableRow: customOptions?.renderExpandableRow,
    onRowExpansionChange: customOptions?.onRowExpansionChange,
    selectableRowsHeader: customOptions?.selectableRowsHeader,
  };

  /**
   * Function to handle clear all filters selected from toolbar
   */
  const clearAllClickHandler = () => {
    const cols = [...columns];
    cols.forEach((item) => {
      item.options.filterList = [];
    });
    setTableColumns(cols);
  };

  /**
   * Function to clear applied filter from filter chip UI
   * @param {integer} index - column index of filtered item
   * @param {string} data - filter text to remove
   * @param {function} onDelete - callback function on delete
   */
  const filterDeleteClickHandler = (index, data, onDelete) => {
    const filteredCols = [...columns];
    if(filteredCols[index].colKey === 'year' || filteredCols[index].colKey === 'createdTs' || filteredCols[index].colKey === 'createdTimeStamp'){
      filteredCols[index].options.filterList = []
    }else{
      filteredCols[index].options.filterList = filteredCols[
        index
      ]?.options?.filterList?.filter((item) => item !== data);
    }
    onDelete();
    setTableColumns(filteredCols);
  };

  /**
   * Function to return Chip component
   * for applied filters added on top of datatable
   */
  const CustomChip = (props) => {
    const { label, onDelete, index, data } = props;
    return (
      <Chip
        size="small"
        label={label}
        data-testid="chip"
        id={`chip-${label.split(" ").join("")}`}
        trailing={
          <Close
            onClick={() => filterDeleteClickHandler(index, data, onDelete)}
          />
        }
      >
        {label}
      </Chip>
    );
  };

  /**
   * Component to form the custom filter lists with filter chips and
   * clear all option on top of datatable
   */
  const CustomFilterList = (props) => {
    const isAnyActiveFilter = props?.filterList?.some((item) => item?.length > 0);
    return (
      <>
        {showSelectedRow && selectedRows.length>0 &&
          <Body as="div" size="large" weight={700} className={classes.selectedTimeKeeperStyle}>
            {selectedRows.length} {selectedRowMsg}
          </Body>
        }
        <TableFilterList {...props} ItemComponent={CustomChip} />
        {isAnyActiveFilter && (
          <div className={classes.clearAllExt}>
            <Button
              variant="tertiary"
              color={LIGHT_DARK_BLACK}
              onClick={clearAllClickHandler}
              data-testid="muidatatable-clear-all-filters"
              id="muidatatable-clear-all-filters"
            >
              {CLEAR_ALL_FILTERS}
            </Button>
          </div>
        )}
      </>
    );
  };

  const BodyComponent = useMemo(
    () => (props) => <LoadingTableBody loading={loading} {...props} />,
    [loading]
  );
  /**
   * Overides with custom components
   */
  const components = {
    TableFilterList: CustomFilterList,
    TableBody: BodyComponent,
  };

  return (
    <div data-testid="mui-datatable">
      <ThemeProvider theme={MuiDataTableTheme()}>
        <MUIDataTable
          className={`${classes.dataTable} ${classes[customClassName]} ${customClassName}`}
          {...{ data, columns, options, components }}
        />
      </ThemeProvider>
    </div>
  );
};

export default MuiDataTable;

MuiDataTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  onAddNew: PropTypes.func,
  addNewLabel : PropTypes.string,
  customOptions: PropTypes.object,
  onExportButtonClick: PropTypes.func,
  setTableColumns: PropTypes.func,
  loading: PropTypes.bool,
  disableSearch: PropTypes.bool,
  disableFilter: PropTypes.bool,
  disablePagination: PropTypes.bool,
  disableExport: PropTypes.bool,
  selectableRowsType: PropTypes.string,
  onRowSelection: PropTypes.func,
  selectedRows: PropTypes.array,
  noResultsText: PropTypes.string,
  customClassName: PropTypes.string,
  disableAddNew: PropTypes.bool,
  showRowsCounter: PropTypes.bool,
  fromAssignTimekeeper: PropTypes.bool,
  fromSelectMatters: PropTypes.bool
};

MuiDataTable.defaultProps = {
  data: [],
  columns: [],
  onRowClick: () => {
    // callback function to handle row click
  },
  onAddNew: () => {
    // callback function to handle add new item
  },
  resetSelectedRows: () => {
    // callback function to reset selections
  },
  addNewLabel: "Add New",
  customOptions: {},
  setTableColumns: () => {
    // callback function to handle column options change
  },
  loading: false,
  disableSearch: false,
  disableFilter: false,
  disablePagination: false,
  disableExport: false,
  selectableRowsType: "none",
  disableAddNew: false,
  hideAddNewButton:false,
  onRowSelection: () => {
    // callback function to handle row selection
  },
  selectedRows: [],
  customClassName: "customMuiClass",
  showRowsCounter: true,
  fromAssignTimekeeper: false,
  fromSelectMatters: false
};
