import { createUseStyles } from "react-jss";

const FilterPanelStyles = createUseStyles({
  clearButton: {
    height: '20px',
    '& > button': {
      padding: '0',
    }
  },
  accordionItemsWrap: {
    paddingBottom: '98px'
  },
  marginSpaceLeft: {
    "marginRight": "4px",
    "& div": {
      width: "100%"
    }
  },
  marginSpaceRight: {
    "marginLeft": "4px",
    "& div": {
      width: "100%"
    }
  },
  bottomSpace: {
    display: 'block',
    marginBottom: '15px',
    '&:last-child':{
      marginBottom: '3px'
    }
  },
  displayNone: {
    display: 'none'
  },
  top20:{
    marginTop: '20px'
  }
});

export default FilterPanelStyles;
