import { createUseStyles } from "react-jss";
import {
  Grid,
  GridColumn,
  Skeleton,
} from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { amountFormat } from "../../../../../helpers/commonUtils";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  overviewTitleHead: {
    margin: "0",
    paddingBottom: "16px",
    fontSize: "24px",
    lineHeight: "36px",
  },
  overviewTotalBudget: {
    fontSize: "14px",
    color: "#2e2f32;",
  },
});

/**
 * OverviewTotal component used to view overview tab for sub-type "Total"
 *
 */
const OverviewTotal = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { budgetDetail } = useSelector(state => state?.budget);
  const [budgetTotal, setBudgetTotal] = useState({});

  useEffect(() => {
    if (budgetDetail !== "") {
      setBudgetTotal(budgetDetail?.budgetAmount);
      setLoading(false);
    }
  }, [budgetDetail]);

  return (
    <div data-testid="OverviewTotal">
      <h3 className={classes.overviewTitleHead} id="total-heading">Total</h3>
      {loading === false ?
        <Grid>
          <GridColumn sm="3" >
            <span className={classes.overviewTotalBudget}><strong data-testid="budget-amount-overview" id="budget-amount-overview">Budget Amount</strong></span>
          </GridColumn>
          <GridColumn sm="3" className={classes.pageInfo}>
            <span className={classes.overviewTotalBudget} id="budget-total-overview">{budgetDetail?.budgetAmount ? `$${amountFormat(budgetTotal)}` : "--"}</span>
          </GridColumn>
        </Grid> : <Skeleton height="20px" variant="rectangle" width="100%" />}
    </div>
  )
}

export default OverviewTotal;
