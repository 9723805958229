const types = {
  SET_ORGANIZATION_DATA: "SET_ORGANIZATION_DATA",
  SET_EXTERNAL_USER_DATA: "SET_EXTERNAL_USER_DATA",
  SET_NEW_ORGANIZATION_DATA: "SET_NEW_ORGANIZATION_DATA",
  SET_ASSOCIATED_MATTERS_DATA: "SET_ASSOCIATED_MATTERS_DATA",
  RESET_ORGANIZATION_DETAIL: "RESET_ORGANIZATION_DETAIL",
  SET_ORGANIZATION_DETAIL: "SET_ORGANIZATION_DETAIL",
  SET_USER_DETAIL: "SET_USER_DETAIL",
  SET_MATTER_SPECIFIC_RATES:"SET_MATTER_SPECIFIC_RATES",
  SET_PRACTICE_AREA_RATES:"SET_PRACTICE_AREA_RATES",
  SET_MATTER_SPECIFIC_RATE_INPUT_VALUE:"SET_MATTER_SPECIFIC_RATE_INPUT_VALUE",
  RESET_MATTER_SPECIFIC_RATE_DETAIL:"RESET_MATTER_SPECIFIC_RATE_DETAIL",
};

export default types;
