import {
	Button,
	Grid,
	GridColumn,
	Heading,
	Tag,
	useSnackbar,
} from "@walmart-web/livingdesign-components";
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getService } from "../../../axios/axios";
import DisplayValue from "../../../components/TextField/DisplayValue/DisplayValue";
import { isCurrencyValueEmptyCheck, isValueEmptyCheck } from "../../../helpers/validationUtils";
import {ADD_NEW_RATE, APPROVED_WALMART_RATE, APPROVING_ATTORNEY, CURRENT_STANDARD_RATE, CURRENT_WALMART_RATE,
	DISCOUNT, END_DATE, JUSTIFICATION_FOR_RATE, MATTER_NUMBER, MATTER_SPECIFIC_RATES,
  PROPOSED_WALMART_RATE, REJECTION_COMMENT, REJECTION_REASON, REQUESTER_EMAIL, START_DATE, STATUS_ACTIVE
} from "../../../constants/constants";
import { setMatterSpecificRates } from "../../../store/actions/peopleAction";
import LoadComponent from "../../../components/LoadComponent/LoadComponent";
import { matterRequestRatesStatusColor } from "../../../constants/colors";
import MatterSpecificRatesStyles from "./PeopleMatterSpecificRatesList.styles";
import { USER_REQUEST_SERVICE } from "../../../constants/baseURLs";
import { useHistory, useParams } from "react-router-dom";
import { getErrorMessage, getRejectionReason } from "../../../helpers/utils";
import { displayLocalDate } from "../../../helpers/utcUtils";

/*
* This component is for displaying Matter Specific Rates associated with any user
*/

const PeopleMatterSpecificRatesList = (props) => {
	const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.organization?.tabs?.people?.tabs?.active?.details?.tabs?.matterSpecificRates);
	const status = useSelector(state => state?.people?.userDetail?.status);
	const classes = MatterSpecificRatesStyles();
	const userRequestId = useSelector((state) => state?.user?.userRequestId);
  	const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory()
  	const { peopleId } = useParams()
	const { addSnack } = useSnackbar();
	const { matterSpecificRates } = useSelector((state) => state?.people);
	const { userDetail } = useSelector((state) => state?.people)
	const masterDetails = useSelector(state => state?.masterData?.masterDatas);
	const loadComponentProps = {
		loader: loading,
		skeletonHeight: '18px',
		skeletonVariant: 'reactangle',
		skeletonWidth: '250px',
		skeletonLines: 1
	};

	useEffect(() => {
		getMatterSpecificRates();
	}, []);

	/*
	*Function to call User Request Service to fetch Matter Specific Rates
	*/
	const getMatterSpecificRates = () => {
		setLoading(true);
		getService(USER_REQUEST_SERVICE, `/user-requests/v1/${userRequestId}?organizationId=${identifier}`).then((res) => {
			dispatch(setMatterSpecificRates(res?.data?.matterSpecificRates));
			setLoading(false);
		}).catch((error) => {
			addSnack({
			 message: getErrorMessage(error)
			});
			setLoading(false);
		});
	}

	/**
* Function will use for add New Rate
*/
	const addNewRate = () => {
		history.push(`/people/${userDetail?.userRequestId}/add-matter-specific-rate`, { fromMatterSpecificRates: true, peopleIdentifier: peopleId, organizationName: userDetail?.organizationName, userName:`${userDetail?.firstName} ${userDetail?.lastName}`, selectedTab:1 });
	}

	return (
		<div data-testid="specific-rates-matter-container">
			<div className={classes.whiteContainer}>
				<div className={classes.titleWrap}>
					<Heading as="h1" size="medium">
						{MATTER_SPECIFIC_RATES}
					</Heading>
					{(accessibility?.addNew && status?.toLowerCase() === STATUS_ACTIVE) &&
						<div>
							<Button
								size="small"
								variant="primary"
								data-testid="add-new-rate"
								id="add-new-rate"
								onClick={addNewRate}
							>
								{ADD_NEW_RATE}
							</Button>
						</div>
					}
				</div>
				<LoadComponent {...{ ...loadComponentProps, skeletonWidth: '100%', styles: { ...{ padding: '24px', display: 'block' } } }}>
					<div className={classes.scrollRates}>
						{matterSpecificRates?.map((item, idx) => {
							return <>
								<div className={classes.rateDetails}>
									<Grid key={item?.matterNumber}>
										<GridColumn sm="11" md="11" lg="11">
											<Heading as="h1" size="small">
												{isValueEmptyCheck(item?.matterName)}
											</Heading>
										</GridColumn>
										<GridColumn sm="1" md="1" lg="1">
											<Tag
												color={item?.status && matterRequestRatesStatusColor[item?.status?.toLowerCase()]?.color}
												variant={"primary"}
											>
												{item?.status && matterRequestRatesStatusColor[item?.status?.toLowerCase()]?.label}
											</Tag>
										</GridColumn>
										<GridColumn sm="5" md="5" lg="5">
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={MATTER_NUMBER}
													value={isValueEmptyCheck(item?.matterNumber)}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={START_DATE}
													value={item?.startDate ? displayLocalDate(item?.startDate) : "-"}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={END_DATE}
													value={item?.endDate ? displayLocalDate(item?.endDate) : "-"}
												/>
											</div>

											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={CURRENT_WALMART_RATE}
													value={isCurrencyValueEmptyCheck(item?.currentWalmartRate)}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={CURRENT_STANDARD_RATE}
													value={isCurrencyValueEmptyCheck(item?.currentStandardRate)}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={PROPOSED_WALMART_RATE}
													value={isCurrencyValueEmptyCheck(item?.proposedWalmartRate)}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={DISCOUNT}
													value={item?.discount ? `${item?.discount}%` : '-'}
												/>
											</div>
											{ item?.status === "Active" && <div className={classes.contentSpacing16}>
												<DisplayValue
													label={APPROVED_WALMART_RATE}
													value={isCurrencyValueEmptyCheck(item?.currentWalmartRate)}
												/>
											</div> }
										</GridColumn>
										<GridColumn sm="7" md="7" lg="7">
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={APPROVING_ATTORNEY}
													value={isValueEmptyCheck(item?.walmartApprovingAttorneyFullName)}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={REQUESTER_EMAIL}
													value={isValueEmptyCheck(item?.requesterEmailId)}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={JUSTIFICATION_FOR_RATE}
													value={isValueEmptyCheck(item?.outsideOrgJustification)}
												/>
											</div>
										</GridColumn>
									</Grid>
								</div>
								{item?.status === "Rejected" ?
								<div className={classes.rejectReason}>
								<LoadComponent {...loadComponentProps}>
								<Grid className={classes.rejectGrid}>
									<GridColumn sm={5}>
									<DisplayValue
										label={REJECTION_REASON}
										value={getRejectionReason(item?.rejectReasonCategoryCode, masterDetails?.timekeeperRateRejectReason)}
									/>
									</GridColumn>
									<GridColumn sm={5}>
									<DisplayValue
										label={REJECTION_COMMENT}
										value={item?.rejectReasonDetails ? item?.rejectReasonDetails : "-"}
									/>
									</GridColumn>
								</Grid>
								</LoadComponent>
      						</div> : <Fragment></Fragment>}
							</>
						})}
					</div>
				</LoadComponent>
			</div>
		</div>
	)
}

export default PeopleMatterSpecificRatesList;
