import types from "../types/matterActionTypes";

export const initialState = {
  matterListingData: {
    count: 0,
    matters: []
  },
  newMatterDetail: {
    matterName: '',
    matterType: '',
    matterDescription: '',
    paralegalAttorney: '',
    outsideCounsel: '',
    pa: '',
    isSensitive: false,
    isLegalHold: false,
    authorisedUsers: []
  },
  matterDetail: {},
  matterDetailsTab: {},
  matterDetailCopy: {},
  matterDefinitions: {},
  matterDocumentData: {
    documents: [],
    count: 0
  },
  practiceAreaDetails: [],
  matterSectionDetails: {},
  matterDocumentsUploadData: {},
  matterFoldersList: [],
  matterDetailsSection: {},
  matterDocumentTabIndex: 0,
  practiceAreas: [],
  initialClaimsList: {
    claims: [],
    count: 0
  },
  claimsList: {
    claims: [],
    count: 0
  },
};

const matterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INPUT_VALUE: {
      const { name, value} = action.payload;
      return {
        ...state,
        matterDetailsTab: {
          ...state.matterDetailsTab,
          [name]: value
        }
      }
    }
    case types.SET_NOTE_DATA:
      return {
        ...state,
        noteData: action.payload
      }
    case types.SET_MATTER_DEFINITIONS:
      return {
        ...state,
        matterDefinitions: action.payload
      }
    case types.REVERT_MATTER_DETAIL: {
      return {
        ...state,
        matterDetailsTab: {}
      }
    }
    case types.SET_MATTER_LISTING_DATA:
      return {
        ...state,
        matterListingData: action.payload
    }
    case types.SET_MATTER_DETAIL:
      return {
        ...state,
        matterDetail: action.payload
      };
    case types.SET_MATTER_DETAILS_SECTIONS_BASED_ON_PRACTICE_AREA:
      return {
        ...state,
        matterSectionDetails: action.payload,
      };
    case types.SET_MATTER_PRACTICE_AREA_DETAIL:
      return {
        ...state,
        practiceAreaDetails: action.payload,
      };
    case types.SET_PRACTICE_AREAS_DATA:
      return {
        ...state,
        practiceAreas: action.payload
      }
    case types.SET_MATTER_DOCUMENT_TAB_INDEX:
      return {
        ...state,
        matterDocumentTabIndex: action.payload
      }

    case types.DELETE_CLAIMS_BY_INDEX: {
      const { index } = action.payload;
      const newList = [...state.claimsList.claims];
      newList.splice(index, 1);
      return {
        ...state,
        claimsList: {
          claims: [...newList],
          count: state.claimsList.claims.length-1
        }
      }
    }

    case types.SET_CLAIMS_INPUT_VALUE: {
      const { index, key, value } = action.payload;
      const editList = [...state.claimsList.claims];
      editList[index] = {...editList[index], [key]: value};
      return {
        ...state,
        claimsList: {
          claims: editList,
          count: editList.length
        },
        initialClaimsList: {...state.initialClaimsList}
      }
    }

    case types.SET_MATTER_CLAIMS_LIST: {
      return {
        ...state,
        claimsList: {
          claims: action.payload,
          count: action.payload.length
        },
        initialClaimsList: {
          claims: action.payload,
          count: action.payload.length
        }
      }
    }

    case types.RESET_MATTER_CLAIMS_BY_ID: {
      const { index } = action.payload;
      const editList = [...state.claimsList.claims];
      editList[index] = {...state.initialClaimsList.claims[index], isEdit: false};
      return {
        ...state,
        claimsList: {
          claims: editList,
          count: editList.length
        }
      }
    }
    default:
      return state;
  }
}

export default matterReducer;
