import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector} from "react-redux";

import {
  Grid,
  GridColumn,
  Heading,
} from "@walmart-web/livingdesign-components";
import { SPACING } from "../../../constants/styleGuide";
import { MATTER_DESCRIPTION } from "../../../constants/constants";

const useStyles = createUseStyles({
  pageContainer: {
    margin: "0px 0px 24px 0px",
  },
  cardContent: {
    height: "100%",
    paddingTop: SPACING.s24,
    paddingLeft: SPACING.s24,
    paddingRight: SPACING.s24,
    backgroundColor: "#ffffff",
  },

  contentSpacing16: {
    paddingTop: SPACING.s24,
  },
  content: {
    fontFamily: "Bogle",
    fontSize: "16px",
    lineHeight: 1.5,
    color: "#2e2f32",
    whiteSpace: "break-spaces"
  },
});

const MatterOverview = () => {
  const classes = useStyles();
  const { matterDetail } = useSelector((state) => state?.matter);

  return (
    <>
      <div className={classes.pageContainer}>
        <Grid data-testid="matter-overview">
          <GridColumn sm={12}>
            <div className={classes.cardContent}>
              <Heading as="h1" size="small" weight={700}>
                {MATTER_DESCRIPTION}
              </Heading>
              <div className={classes.content}>
                <p id="matterDescription">
                  {matterDetail?.matter?.description || "Not Available"}
                </p>
              </div>
            </div>
          </GridColumn>
        </Grid>
      </div>
    </>
  );
};

export default MatterOverview;
