import React from "react";
import TimelineStyles from './TimelineStyles';


const TimelineSeparator = () => {
	const classes = TimelineStyles();
	return (<div className={classes.timelineItemSeparator}>
		<span className={classes.timelineSeparatorDot}></span>
		<span className={classes.timelineSeparatorLine + ' timelineSeparatorLine'}></span>
	</div>);
}
/**
 * Matter Specific Changes in Rates
 *
 * @visibleName Rate Changes History Content
 */
const TimelineItem = (props) => {
	const classes = TimelineStyles();

	return (
		<li className={classes.timelineItem}>
			<TimelineSeparator />
			<div className={classes.timelineItemContent + ' timelineItemContent'}>
				{props?.children}
			</div>
		</li>
	)
}

export default TimelineItem;
