import {
  ButtonGroup,
  Button,
  Modal,
  Spinner,
} from "@walmart-web/livingdesign-components";
import React from "react";
import { CANCEL } from "../../../constants/actionButtons";

const DeleteActions = (props) => {
  const { deleteButtonText, onDelete, onCancel, deleteLoading } = props;

  return (
    <ButtonGroup>
      <Button
        id="cancel-btn"
        data-testid="cancel-btn"
        size="medium"
        onClick={onCancel}
        disabled={deleteLoading}
      >
        {CANCEL}
      </Button>
      <Button
        variant="destructive"
        size="medium"
        data-testid="delete-btn"
        id="delete-btn"
        onClick={onDelete}
        disabled={deleteLoading}
      >
        {deleteLoading ? (
          <Spinner color="white" size="small" />
        ) : (
          deleteButtonText
        )}
      </Button>
    </ButtonGroup>
  );
};

const DeleteModal = (props) => {
  const {
    open,
    setOpen,
    title,
    message,
    deleteButtonText,
    onDelete,
    deleteLoading,
  } = props;

  return (
    <Modal
      data-testid="delete-Modal"
      actions={
        <DeleteActions
          deleteButtonText={deleteButtonText}
          onDelete={onDelete}
          onCancel={() => setOpen(false)}
          deleteLoading={deleteLoading}
        />
      }
      isOpen={open}
      onClose={() => setOpen(false)}
      children={message}
      size="medium"
      title={title}
    />
  );
};

export default DeleteModal;
