import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import MuiDataTable from "../../../components/Table/MuiDataTable/MuiDataTable";
import {
  Caption,
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import {
  CS_SCOPES,
  CS_IS_ACTIVE_FILTER,
  CS_SCORE_SORT_OBJ,
  CS_DEFAULT_SORT_QUERY,
  EXPORT_MAX_LIMIT_CONF,
  INVOICE
} from "../../../constants/constants";
import {
  ROWS_PER_PAGE_INVOICE,
  ROWS_PER_PAGE_OPTIONS_INVOICE,
  ENABLE_SERVER_SIDE_FEATURES_INVOICE,
} from "../../../constants/tableConfigs";
import { jsonToCsv, getExportedData, getErrorMessage, renderTernary } from "../../../helpers/utils";
import {
  postService,
} from "../../../axios/axios";
import { Operations } from "../../../axios/operations";
import { setInvoiceData, resetNonAdjustedPaginationForLineItems, resetAdjustedPaginationForLineItems } from "../../../store/actions/invoiceActions";
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import { getAllowedActionsList, getInvoiceStatus } from "../../../helpers/tableUtils";
import { displayLocalDate } from "../../../helpers/utcUtils";
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery, getUpdatedFilterQuery } from "../../../helpers/csQueryUtils";
import ActionPopover from "../../../components/Table/ActionPopover/ActionPopover";
import { Plus } from "@walmart-web/livingdesign-icons";
import { FEATURES } from "../../../constants/features";
import { TableCellDisplay } from "../../../helpers/commonUtils";
import ExportModal from "../../../components/Modal/ExportModal/ExportModal";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  dataTableWrap: {
    margin: "0",
    '& table > thead > tr > th.MuiTableCell-head': {
      color: '#000000',
      fontWeight: 'bold'
    },
    '& table > thead > tr > th.MuiTableCell-head button': {
      color: '#000000',
      fontWeight: 'bold'
    }
  },
  tabContainer: {
    "& nav > ul > li > a": {
      background: "none",
    }
  },
  starColumnHeader: {
    padding: "6px",
  },
  star: {
    padding: "6px",
  },
  handCursor: {
    cursor: "pointer",
  },
  percentChip: {
    width: '60px',
    height: '20px',
    display: 'block',
    margin: '0 auto',
    textAlign: 'center',
    borderRadius: '10px',
    background: '#E3E4E5'
  },
  exportName: {
    marginBottom: 20
  },
  exportNameAs: {
    paddingTop: 20,
    marginBottom: 20
  },
  modalSubText: {
    fontSize: 14,
    fontWeight: 'normal'
  },
  wd80: {
    width: '80px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  wd100: {
    width: '100px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  wd120: {
    width: '120px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  wd60: {
    width: '60px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
});

/**
 * Gets called when user download the export file
 * client side
 * @public
 */
export const exportClientData = (invoices, exportSelection, rowsData) => {
  let csvData = jsonToCsv(invoices);
  if(exportSelection === 'currentSelection'){
    csvData = jsonToCsv(rowsData);
  }else if(exportSelection === 'currentPage'){
    csvData = jsonToCsv(invoices);
  }
  //Download the file as CSV
  const url = window.URL.createObjectURL(
    new Blob([csvData]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `invoice-data.csv`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

/**
 * Component to return action popover on add new button
 * @returns
 */
const AddNewActionPopover = (props) => {
  const {addNewActionItems, handleAddNew} = props
  return (
    <ActionPopover
      actionItems={addNewActionItems}
      onClickOption={(selectedId) => { handleAddNew(selectedId) }}
      data-testid="add-new-action-btn"
      testID="add-new-button-datatable"
      buttonIcon={<Plus />}
      buttonText={"Add New"}
    />
  )
}

/**
 * Invoice component to list the invoice under financial component
 */
const FinancialInvoiceList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {currentFinancialTab} = props;
  const invoiceAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.financial?.invoice);
  const orgIdentifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
  const featureMap = useSelector((state) => state?.user?.featureMap);

  const renderMatterName=(value)=>{
    return <Caption as="p" className={classes.wd120}>{value}</Caption>
  }

  const renderMatterNumber=(value)=>{
    return <Caption as="p" className={classes.wd120} id={`matter-number-${value}`}>{value}</Caption>
  }

  const renderCreatedReceievedDate =(value, tableMeta) => {
    return (
      tableMeta?.rowData[2] !== true ? (
        <Caption as="p">
          {displayLocalDate(value)}
        </Caption>
      ) : null
    )
  }

  const renderStatus=(value, tableMeta) => {
    return <Caption as="p" id={`table-row-${value}-${tableMeta?.rowIndex}`}>{getInvoiceStatus(value)}</Caption>
  }

  const COLUMNS = [
    {
      name: "matterName",
      label: "Matter",
      colKey: "matterName",
      options: {
        sort: true,
        display: true,
        customBodyRender: renderMatterName,
        customFilterListOptions: { render: (v) => `Matter: ${v}` },
      },
      operation: Operations.LIKEIC,
      type: "tag"
    },
    {
      name: "matterNumber",
      label: "Matter No",
      colKey: "matterNumber",
      options: {
        sort: true,
        display: true,
        setCellHeaderProps: () => ({style: {minWidth: "100px"}}),
        setCellProps: () => ({style: {minWidth: "100px"}}),
        customBodyRender: renderMatterNumber,
        customFilterListOptions: { render: (v) => `Matter Number: ${v}` },
      },
      operation: Operations.LIKEIC,
      type: "tag"
    },
    {
      name: "organizationInvoiceNumber",
      label: "Invoice No",
      colKey: "organizationInvoiceNumber",
      options: {
        sort: true,
        display: true,
        setCellHeaderProps: () => ({style: {minWidth: "102px"}}),
        setCellProps: () => ({style: {minWidth: "102px"}}),
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-${tableMeta?.rowIndex}`, value),
        customFilterListOptions: { render: (v) => `Invoice Number: ${v}` },
      },
      operation: Operations.LIKEIC,
      type: "tag"
    },
    {
      name: "invoiceDate",
      label: "Invoice Date",
      colKey: "invoiceDate",
      options: {
        sort: true,
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            tableMeta?.rowData[2] !== true ? (
              renderTernary(value, displayLocalDate(value), '-')
            ) : null
          )
        },
      },
    },
    {
      name: "createdTs",
      label: "Created Date",
      colKey: "createdTs",
      options: {
        sort: true,
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        customBodyRender: renderCreatedReceievedDate,
        customFilterListOptions: {
          render: (v) => {
            return `Invoice Date Min-Max Date: ${v[0]} - ${v[1]}`;
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === -1) {
              filterList = [];
            }
            return filterList;
          },
        },
        filter: true,
        filterType: 'custom',
        filterList: [],
        filterOptions: {
          names: [],
          logic(date, filters) {
            if (filters[0] && filters[1]) {
              return date < filters[0] || date > filters[1];
            } else if (filters[0]) {
              return date < filters[0];
            } else if (filters[1]) {
              return date > filters[1];
            }
            return false;
          }
        }
      },
      operation: Operations.GTE,
      type:"date"
    },
    {
      name: "receivedDate",
      label: "Received Date",
      colKey: "receivedDate",
      options: {
        sort: true,
        display: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        customBodyRender: renderCreatedReceievedDate,
      },
      operation: Operations.GTE,
      type:"date"
    },
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        display: true,
        customBodyRender: renderStatus,
        customFilterListOptions: { render: (v) => `Status: ${v}` },
      },
      operation: Operations.EQ,
      type: "id"
    },
    {
      name: "invoiceType",
      label: "Invoice Type",
      colKey: "invoiceType",
      options: {
        display : false,
      },
    },
    {
      name: "totalNetAmount",
      label: "$ Total",
      colKey: "totalNetAmount",
      options: {
        display: true,
        setCellHeaderProps: () => ({style: {minWidth: "80px"}}),
        setCellProps: () => ({style: {minWidth: "80px"}}),
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-totalNetAmount-${tableMeta.rowIndex}`, value),
        customFilterListOptions: { render: (v) => `Total: ${v}` },
      },
      operation: Operations.EQ,
      type: "number"
    },
    {
      name: "assignedTo",
      label: "Assigned To",
      colKey: "assignedTo",
      options: {
        display : false,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-assignedTo-${tableMeta.rowIndex}`, value),
      },
      operation: Operations.LIKE,
      type: "tag"
    },
  ];

  const dispatch = useDispatch();
  const { invoiceData } = useSelector((state) => state?.invoice);
  const [invoiceColumns, setInvoiceColumns] = useState(COLUMNS);
  const [loading, setLoading] = useState(false);
  const [rowsPerPageInvoice, setRowsPerPageInvoice] = useState(ROWS_PER_PAGE_INVOICE);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [sortQuery, setSortQuery] = useState(CS_DEFAULT_SORT_QUERY);
  const [exportLoading, setExportLoading] = useState(false);
  const [createExportLoading, setCreateExportLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectRowsData, setSelectRowsData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const showExportAllOption = invoiceData?.count < EXPORT_MAX_LIMIT_CONF.INVOICE;
  const defaultExportOption = showExportAllOption ? 'allInvoice' : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);
  const [currentPageExport, setCurrentPageExport] = useState(false);

  const defaultQuery = {
    "filters": [
      CS_IS_ACTIVE_FILTER,
      formCsFilterObject("organizationId","match",orgIdentifier),
      formCsFilterObject("invoiceType", "match", "Invoice")
    ],
    "operation": "AND",
    "properties": null
    }

  const { addSnack } = useSnackbar();

  const FILTER_CONFIGS = [
    {
      colKey: "matterNumber",
      type: "matterDropdownAutocomplete",
      label: "Matter Number",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Matter',
      id: "matterNumber",
      isRestricted: false
    },
    {
      colKey: "matterName",
      type: "matterDropdownAutocomplete",
      label: "Matter Name",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Matter',
      id: "matterName",
      isRestricted: false
    },
    {
      colKey: "organizationInvoiceNumber",
      type: "textField",
      label: "Find an Invoice No.",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Invoice Number',
    },
    {
      colKey: "totalNetAmount",
      type: "textField",
      label: "$ Total",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Net Amount',
      isPositiveDecimalNumber: true
    },
    {
      colKey: "createdTs",
      type: "rangePicker",
      label: "Start Date",
      defaultOpen: true,
      section: "right",
      displayLabel: true,
      accordian: 'Created Date Range'
    },
    {
      colKey: "status",
      type: "checkbox",
      label: "Status",
      options: [
        "Pending",
        "Workflow",
        "Appealed",
        "Ready For Payment",
        "Sent For Payment",
        "Paid",
        "Rejected",
        "Void",
        "Payment Failed",
        "Failed"
      ],
      defaultOpen: true,
      displayLabel: false,
      accordian: 'Status'
    },
  ];


  /**
   * Function to call Invoice Search Service
   * @param {string} query
   */
  const getinvoiceData = (paginationQuery) => {
		setLoading(true);
    const sortQueryInvoice = {...sortQuery};
    if(searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(CS_DEFAULT_SORT_QUERY)) {
      sortQueryInvoice.sortBy = [CS_SCORE_SORT_OBJ, ...sortQuery?.sortBy];
    }
    const updatedfilterQuery = getUpdatedFilterQuery({...filterQuery});
    const query = formCompositeSearchQuery([CS_SCOPES.INVOICE], updatedfilterQuery, searchQuery, sortQueryInvoice, paginationQuery, {}, defaultQuery, {});
    let serviceEndPoint = `/composite-search/v1?returnCsv=false&organizationId=${orgIdentifier}`;
    // add flag to fetch matter id list for logged in user
    if(!invoiceAccessibility?.viewAll) {
      serviceEndPoint += '&fetchMatterIdListForUserId=true';
    }
    postService(
      COMPOSITE_SEARCH_SERVICE,
      serviceEndPoint ,
      query
    )
		.then((response) => {
      setLoading(false);
			dispatch(setInvoiceData({
        invoices:response?.data?.invoice?.data,
        count:response?.data?.invoice?.count
      }));
      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

  /**
   * Function to handle row click
   * @param {object} rowData: row data
   * @param {object} rowMeta: meta data of selected row
   */
  const handleRowClick = (rowData, rowMeta) => {
    const { id } = invoiceData?.invoices[rowMeta?.rowIndex];
    history.push(`/invoice/${id}`, { invoiceData: invoiceData?.invoices[rowMeta?.rowIndex] })
  }

  /**
   * Function to validate search query and set query or show no results UI
   * @param {string} searchText
   */
   const handleServerSideSearch = (searchText) => {
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };

  /**
   * Function to set filter query on filter action
   * @param {string} query
   */
  const handleServerSideFilter = (query) => {
    setFilterQuery(query);
  };

  /**
   * Function to call api on pagination action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPageInvoice(rowsPerPage);
    const paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * page));
    getinvoiceData(paginationQuery);
  };

  /**
   * Function to call api on sort action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSideSort = ({ name, direction }) => {
    const sortQuery = formCsSortQuery(name,direction);
    setSortQuery(sortQuery);
  };

  const invoiceListTableCustomOptions = {
    downloadOptions: {
      filename: "invoice",
    },
    responsive: "standard",
    rowsPerPage: rowsPerPageInvoice,
    isInvoice: true,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_INVOICE,
    filterConfigs: FILTER_CONFIGS,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_INVOICE,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSort: handleServerSideSort,
    totalCount: invoiceData?.count
  };

  /**
   * Cancel Export Modal
   */
  const onCancelBtnClk = () => {
    setExportLoading(false)
    setModalOpen(false)
  }

   /**
   * Gets called when user click on export button
   *
   * @public
   */
  const handleExport = () => {
    setExportSelection(defaultExportOption)
    setModalOpen(true);
    setExportLoading(true);
    setCurrentPageExport(true);
  };

  /**
   * function will use when row is select/deselect
   */
  const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
    const bData = invoiceData?.invoices;
    const result = allSelected?.map(item => {
      return bData && bData[item?.index]
    });
    setSelectRowsData(selectRowsData);
    setRowsData(result);
  }

  /**
   * After click on create button download Actions
   */
  const onClickCreate = () => {
    setCreateExportLoading(true);
    if( !ENABLE_SERVER_SIDE_FEATURES_INVOICE ){
      exportClientData(invoiceData?.invoices, exportSelection, rowsData);
      setModalOpen(false);
      setExportLoading(false);
      setCreateExportLoading(false);
    }else{
      let exportQuery = formCompositeSearchQuery([CS_SCOPES.INVOICE],  filterQuery, searchQuery, sortQuery, {}, {getAll: true}, {}, defaultQuery);
      if(exportSelection === 'currentSelection'){
        const result = rowsData?.map(a => a?.id);
        const selectionQuery =
        {
          "filters": [formCsFilterObject("id","in",result)],
          "operation": "AND",
          "properties": null
        }
        exportQuery = formCompositeSearchQuery([CS_SCOPES.INVOICE], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery);
      }else if(exportSelection === 'currentPage'){
        const result = invoiceData?.invoices?.map(a => a?.id);
        const selectionQuery =
        {
          "filters": [formCsFilterObject("id","in",result)],
          "operation": "AND",
          "properties": null
        }
        exportQuery = formCompositeSearchQuery([CS_SCOPES.INVOICE], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery);
      }
      let serviceEndPoint = `/composite-search/v1?returnCsv=true&organizationId=${orgIdentifier}`;
      // add flag to fetch matter id list for logged in user
      if (!invoiceAccessibility?.viewAll) {
        serviceEndPoint += '&fetchMatterIdListForUserId=true';
      }
      postService(
        COMPOSITE_SEARCH_SERVICE,
        serviceEndPoint,
        exportQuery
      )
        .then((response) => {
          const data = response?.data;
          const contentType = response?.headers["content-type"];
          getExportedData(data, contentType, 'invoice');
          setModalOpen(false);
          setExportLoading(false);
          setCreateExportLoading(false);
        })
        .catch((error) => {
          setModalOpen(false);
          setExportLoading(false);
          setCreateExportLoading(false);
          addSnack({
            message: getErrorMessage(error)
          });
        });
    }
  }

  /**
   * Add new action items
   */
  const addNewActionItems = [
    {
      "actionId": 0,
      "label": "Add New Invoice",
      "isAboveDivider": true,
      "showAction": true
    },
    {
      "actionId": 1,
      "label": "Upload Invoices",
      "isAboveDivider": true,
      "showAction": true
    }
  ]

  /**
   * Gets called when clicked on Add new button.
   */
  const handleAddNew = (selectedId) => {
    // Default behavior if feature flag disabled
    if(!featureMap?.[FEATURES.BULK_JOB]) {
      history.push("/invoice/0/view", {
        currentFinancialTab,
      });
      return;
    }
    switch (selectedId) {
      case 0:
        history.push("/invoice/0/view", {currentFinancialTab});
        break;
      case 1:
        history.push("/invoiceUpload")
        break;
      default:history.push("/invoice/0/view", {currentFinancialTab});
        break;
    }
  };

  /**
   * To load initial data and handle search/filter/sort query change on Invoice datatable
   */
  useEffect(() => {
    const paginationQuery = {
      "offset": 0,
      "limit": rowsPerPageInvoice,
    }
    getinvoiceData(paginationQuery);
  }, [searchQuery, filterQuery, sortQuery]);

  /**
   * Component Unmount
   */
  useEffect(() => {
    dispatch(resetNonAdjustedPaginationForLineItems())
    dispatch(resetAdjustedPaginationForLineItems())
  }, []);

  const resetSelectedRows = () => {
    setSelectRowsData([]);
    setRowsData([]);
  }

  return (
    <>
      <div className={classes.dataTableWrap}>
        <MuiDataTable
          data={invoiceData?.invoices}
          columns={invoiceColumns}
          setTableColumns={setInvoiceColumns}
          customOptions={invoiceListTableCustomOptions}
          selectableRowsType={"multiple"}
          onRowClick={handleRowClick}
          groupFilter={true}
          exportLoading={exportLoading}
          onExportClick={handleExport}
          selectedRows={selectRowsData}
          onRowSelection={handleRowSelectionChange}
          onAddNew={handleAddNew}
          loading={loading}
          showRowsCounter={false}
          allowedActions={getAllowedActionsList(invoiceAccessibility)}
          resetSelectedRows={() => resetSelectedRows()}
          showCustomAddNewButton={featureMap?.[FEATURES.BULK_JOB] || false}
          customAddNewButton={<AddNewActionPopover addNewActionItems={addNewActionItems} handleAddNew={handleAddNew}/>}
        />
      </div>
      <ExportModal
          title={INVOICE}
          mode={modalOpen}
          onCancel={onCancelBtnClk}
          onCreate={onClickCreate}
          rowsData={rowsData}
          showExportAllOption={showExportAllOption}
          exportSelection={exportSelection}
          setExportSelection={setExportSelection}
          currentPageExport={currentPageExport}
          setCurrentPageExport={setCurrentPageExport}
          exportLoading={createExportLoading}
        />
    </>
  );

}

export default FinancialInvoiceList;
