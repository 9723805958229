import { createUseStyles } from "react-jss";
import { LIGHT_GRAY } from "../../../constants/colors";

const ViewJobStatusStyle = createUseStyles({
  listing: {
    backgroundColor: LIGHT_GRAY,
    padding: "25px 30px",
    flex: 1,
    fontFamily: 'Bogle',
    "& > div > div > div.MuiToolbar-root:first-child":{
      display: 'none'
    },
    "& table > thead > tr > th.MuiTableCell-head": {
    fontFamily: 'Bogle',
    },
    "& table > tbody > tr > td.MuiTableCell-root": {
      fontFamily: 'Bogle',
    }
  },
  pageTitle: {
    fontSize: 36,
    display: 'flex',
    alignItems: 'center'
  },
  pageInfoBold: {
    fontWeight: "bold",
    '& h4': {
      fontSize: "20px"
    }
  },
  label: {
    fontSize: "14px",
  },
  header: {
    paddingLeft: 16,
  },
	gridLayout: {
    display: "grid",
    gap: '10px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    marginLeft: '16px',
    marginBottom: '10px'
  },
  parentHeader: {
    display: "flex",
    margin: '16px 10px',
    width: "100%",
    justifyContent: "space-between",
    '& h1': {
      width: "150px"
    }
  },
  comment: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "20px",
    fontWeight: 700
  },
  refreshButton: {
    marginTop:"-20px",
  },
  pageInfo: {
		'& span': {
			fontSize: "14px"
		}
	},
  pageInfoComment: {
    fontSize: "20px",
    fontWeight: "bold",
	}
});

export default ViewJobStatusStyle;
