import { createUseStyles } from "react-jss";
import React from 'react';

const useStyles = createUseStyles({
  subheading: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
    textAlign: 'left',
  },
});

const Subheading = (props) => {
	const {'data-testid': dataTestId, children} = props;
	const classes = useStyles();

	return (
		<span className={classes.subheading} data-testid={dataTestId}>
			{children}
		</span>
	);
};

export default Subheading;
