import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector} from "react-redux";
import {
  Modal,
  Caption,
  Grid,
  GridColumn,
  Button,
  ButtonGroup,
  Spinner
} from "@walmart-web/livingdesign-components";

import {
  MANAGE_TAGS,
  SELECT_TAGS,
} from "../../../constants/constants";
import { CANCEL, SAVE } from "../../../constants/actionButtons";
import { SPACING } from "../../../constants/styleGuide";
import AutocompleteMultiSelect from "../../Autocomplete/AutocompleteMultiSelect/AutocompleteMultiSelect";

const useStyles = createUseStyles({
  tagsContainer: {
    margin: "16px 0px",
  },
  tag: {
    marginRight: SPACING.s8,
    marginBottom: SPACING.s8,
    display: "inline-block",
    cursor: "pointer"
  },
  removeTagBtn: {
    "& button": {
      padding: "16px 0px",
      height: "0px"
    }
  },
  updateActions: {
    padding: "20px 0px"
  },
  btnGroup: {
    float: "right"
  }
})

const EditTags = (props) => {
  const classes = useStyles();

  const { openEditTagsModal, setOpenEditTagsModal, selectedTableData ,onClickSaveTags,editLoading} = props;


  const [documentTag, setDocumentTag] = useState([]);
  const masterDetails = useSelector(state => state?.masterData?.masterDatas);


  useEffect(() => {
    setDocumentTag(selectedTableData)
  }, [selectedTableData])

  /**
   * Gets called when clicked on save button.
   */
  const onClickSave = () => {
    onClickSaveTags(documentTag)
  }

  /**
   * Gets called when clicked on cancel button.
   */
  const closeModal = () => {
    setOpenEditTagsModal(false);
  }

  return (
    <Modal
      isOpen={openEditTagsModal}
      onClose={() => {
        closeModal();
      }}
      size="small"
      title={MANAGE_TAGS}
      data-testid="edit-tag-modal"
      id="edit-tag-modal"
    >
      <>
        <Caption as="p" weight={400}>
          {SELECT_TAGS}
        </Caption>
        {/* commenting below code, as tag is no longer editable field, it will be selected from master data table */}
        {/* <TagAutocomplete
            label={TAGS}
            placeholder={SELECT_TAGS}
            value={documentTag}
            setValue={(v) => setDocumentValue(v)}
            tagData={documentTagList}
            data-testid="add-new-tag"
          /> */}
        <AutocompleteMultiSelect
          options={masterDetails?.documentTags?.masterData?.map(val => val?.value)}
          handleOnChange={(e, value) => setDocumentTag(value)}
          defaultValue={documentTag}
          placeholder={SELECT_TAGS}
          data-testid="add-new-tag"
        />
        {/* <TextField
            helperText={FIND_OR_CREATE_TAG}
            type="text"
            onChange={(e) => onChangeNewTagValue(e.target.value)}
            onBlur={(e) => addTags(e.target.value)}
            value={newTag}
            data-testid="add-new-tag"
          /> */}
        {/* <div className={classes.tagsContainer}>
            {selectedTableData[4] && selectedTableData[4].map((item, idx) => {
              return (
                <div className={classes.tag} key={idx}>
                  <Tag
                    color="blue"
                    leading={<Pencil size="medium" data-testid="detail-edit-button" />}
                    variant="tertiary"
                    onClick={() => updateTag(idx)}
                    data-testid={`tag-${idx}`}
                  >
                    {item}
                  </Tag>
                </div>
              )
            })}
          </div> */}
      </>
      <div className={classes.updateActions}>
        <Grid data-testid="update-event">
          <GridColumn sm={6}>
          </GridColumn>
          <GridColumn sm={6}>
            <div className={classes.btnGroup}>
              <ButtonGroup>
                <Button
                  id="cancel-btn"
                  data-testid="cancel-btn"
                  size="small"
                  onClick={() => {
                    closeModal()
                  }
                  }
                >
                  {CANCEL}
                </Button>
                {
                  editLoading ?
                    <Button
                      variant="primary"
                      size="small"
                      data-testid="update-spinner-btn">
                      <Spinner size="small" color="white" />
                    </Button> :
                    <Button
                      variant="primary"
                      size="small"
                      id="update-btn"
                      data-testid="update-btn"
                      onClick={onClickSave}
                    >
                      {SAVE}
                    </Button>
                }

              </ButtonGroup>
            </div>
          </GridColumn>
        </Grid>
      </div>
    </Modal>
  )
}

export default EditTags;
