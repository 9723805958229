const types = {
    SET_INPUT_VALUE: 'SET_INPUT_VALUE',
    SET_NOTE_DATA: 'SET_NOTE_DATA',
    UPDATE_MATTER_DETAIL_COPY: 'UPDATE_MATTER_DETAIL_COPY',
    REVERT_MATTER_DETAIL: 'REVERT_MATTER_DETAIL',
    SET_MATTER_DEFINITIONS: 'SET_MATTER_DEFINITIONS',
    SET_MATTER_DETAILS_SECTIONS_BASED_ON_PRACTICE_AREA: 'SET_MATTER_DETAILS_SECTIONS_BASED_ON_PRACTICE_AREA',
    SET_MATTER_PRACTICE_AREA_DETAIL: 'SET_MATTER_PRACTICE_AREA_DETAIL',
    SET_NOTE_LIST_DATA: 'SET_NOTE_LIST_DATA',
    SET_MATTER_LISTING_DATA: "SET_MATTER_LISTING_DATA",
    SET_MATTER_DETAIL: "SET_MATTER_DETAIL",
    SET_PRACTICE_AREAS_DATA : "SET_PRACTICE_AREAS_DATA",
    SET_MATTER_DOCUMENT_TAB_INDEX : "SET_MATTER_DOCUMENT_TAB_INDEX",
    DELETE_CLAIMS_BY_INDEX: "DELETE_CLAIMS_BY_INDEX",
    SET_CLAIMS_INPUT_VALUE: "SET_CLAIMS_INPUT_VALUE",
    SET_MATTER_CLAIMS_LIST: "SET_MATTER_CLAIMS_LIST",
    RESET_MATTER_CLAIMS_BY_ID: "RESET_MATTER_CLAIMS_BY_ID"
}

export default types;
