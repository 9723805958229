import types from "../types/timeKeeperAdminTypes";

export const initialState = {
  timeKeeperData: {
    events: [],
    count: 0,
  },
  timeKeeperAdminValues: {
    firstName: "",
    lastName: "",
    email: "",
    primaryOfficeLocation: "",
    stateBarLicence: "",
    subjectMatterExpertise: "",
    subjectMatterExpertiseArray: [],
    justificationForTimeKeeper: "",
    tkid: "",
    classification: "",
    billingContact: false,
    organization: "",
    phoneNo: "",
    role: "",
    jdYear: "",
    walmartApprovingAttroney: "",
    walmartApprovingAttorneyUserId: "",
    walmartApprovingAttorneyFirstName: "",
    walmartApprovingAttorneyLastName: "",
    walmartApprovingAttorneyEmailId: "",
    requesterEmailId: "",
    requesterUserId: "",
    requesterFirstName: "",
    requesterLastName: "",
    requesterFullName: "",
    startDate:null,
    endDate: null,
    addressType : "",
    addressLine1 : "",
    addressLine2 : "",
    city : "",
    state : "",
    country : "United States",
    isTimeKeeperRoleEnabled: false,
    practiceAreaRate: [
      {
        id: 0,
        practiceArea: "",
        rateStartDate: null,
        rateEndDate: null,
        justificationForRate: "",
        walmartApprovingAttorney: "",
        proposedWalmartRate: "",
        currentStandardRate: "",
        discount : "",
      },
    ],
  },
  timeKeeperAdminInitialValues: {
    firstName: "",
    lastName: "",
    email: "",
    primaryOfficeLocation: "",
    stateBarLicence: "",
    subjectMatterExpertise: "",
    subjectMatterExpertiseArray: [],
    justificationForTimeKeeper: "",
    tkid: "",
    classification: "",
    billingContact: false,
    organization: "",
    phoneNo: "",
    role: "",
    jdYear: "",
    walmartApprovingAttroney: "",
    walmartApprovingAttorneyUserId: "",
    walmartApprovingAttorneyFirstName: "",
    walmartApprovingAttorneyLastName: "",
    walmartApprovingAttorneyEmailId: "",
    requesterEmailId: "",
    requesterUserId: "",
    requesterFirstName: "",
    requesterLastName: "",
    requesterFullName: "",
    endDate: null,
    addressType : "",
    addressLine1 : "",
    addressLine2 : "",
    city : "",
    state : "",
    country : "United States",
    isTimeKeeperRoleEnabled: false,
    practiceAreaRate: [
      {
        id: 0,
        practiceArea: "",
        rateStartDate: null,
        rateEndDate: null,
        justificationForRate: "",
        walmartApprovingAttorney: "",
        proposedWalmartRate: "",
        currentStandardRate: "",
        discount : ""
      },
    ],
  },
};

const timeKeeperAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INPUT_VALUE: {
      const { name, value } = action.payload;
      return {
        ...state,
        timeKeeperAdminValues: {
          ...state.timeKeeperAdminValues,
          [name]: value,
        },
      };
    }
    case types.SET_INPUT_VALUE_PRACTICE_AREA: {
      const { practiceAreaRate } = action.payload;
      return {
        ...state,
        timeKeeperAdminValues: {
          ...state.timeKeeperAdminValues,
          practiceAreaRate: practiceAreaRate,
        },
      };
    }
    case types.SET_PRACTICE_AREA_NEW:
      const { value } = action.payload;
      return {
        ...state,
        timeKeeperAdminValues: {
          ...state.timeKeeperAdminValues,
          practiceAreaRate: [...state.timeKeeperAdminValues.practiceAreaRate, value],
        },
      };

    case types.SET_TIMEKEEPER_DETAIL: {
      return {
        ...state,
        timeKeeperAdminValues: action.payload.value,
      };
    }

      case types.RESET_TIMEKEEPER_VALUES:
      return {
        ...state,
        timeKeeperAdminValues: initialState.timeKeeperAdminValues
      }
    default:
      return state;
  }
};

export default timeKeeperAdminReducer;
