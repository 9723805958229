import { createUseStyles } from "react-jss";
import { LIGHT_GRAY } from "../../constants/colors";

const OrganizationDetailViewStyles = createUseStyles({
  listing: {
    backgroundColor: LIGHT_GRAY,
    padding: "25px 30px",
    flex: 1,
  },
  pageTitle: {
    fontSize: 36,
    fontFamily:"Bogle",
    fontWeight: "bold",
    display: "block",
    marginLeft: "16px",
    marginBottom: "8px"
  },
  pageInfo: {
    display: "flex",
    flexDirection: "column",
    marginRight: "5rem",
    marginLeft: "16px",
  },
  website: {
    fontWeight: "bold",
  },
  label: {
    fontSize: "8px",
    fontFamily:"Bogle"
  },
  tabContainer: {
    "& nav > ul > li > a": {
      background: "none",
    }
  },
  gridLayout: {
    margin: "16px 0 16px",
  },
  icons: {
    display: "flex",
    justifyContent: "right",
    "& button": {
      backgroundColor: "#eeeeee",
    },
  },
  disableTabs: {
    opacity: 0.5,
    pointerEvents: "none"
  }
});

export default OrganizationDetailViewStyles;
