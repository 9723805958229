import { createUseStyles } from "react-jss";
import {
  Skeleton,
  Caption
} from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MuiDataTable from "../../../../../components/Table/MuiDataTable/MuiDataTable";
import { TableCellDisplay } from "../../../../../helpers/commonUtils";

/**
 * styles used in the component.
 */
const borderLeft = `1px solid rgba(224, 224, 224, 1)`;
const border = "solid 1px #e3e4e5";
const useStyles = createUseStyles({
  dataTableWrap: {
    margin: "0",
    "& table > thead > tr > th.MuiTableCell-head": {
      fontFamily: "Bogle",
      color: "#000000",
      fontWeight: "bold",
      borderLeft: borderLeft,
      "&:last-child": {
        borderRight: borderLeft
      }
    },
    "& table > tbody > tr > td.MuiTableCell-body": {
      borderLeft:"none",
      borderRight: borderLeft
    },
    "& table > tbody": {
      borderLeft:borderLeft,
    },
    "& table > tbody > tr > td.MuiTableCell-root": {
      color: "#000000",
      fontWeight: "bold",
      borderLeft: "borderLeft",
      "&:last-child": {
        borderRight: "borderLeft"
      }
    },
    "& table > tbody > tr.MuiTableRow-root.MuiTableRow-hover:hover": {
      cursor: "default",
      background: "#fff"
    },
    "& table > thead > tr > th.MuiTableCell-head button": {
      color: "#000000",
      fontWeight: "bold",
      fontFamily: "Bogle"
    },
    "& > div > div > div.MuiToolbar-root": {
      display: 'none'
    }
  },
  overviewTitleHead: {
    margin: "0",
    paddingBottom: "16px",
    fontSize: "24",
    lineHeight: "36px",
  },
  overviewTableHeader: {
    display: "contents",
  },
  overviewTableHeaderRow: {
    fontFamily: "Bogle",
    padding: "16px",
    fontSize: "12px",
    lineHeight: "1.33",
    color: "#2e2f32",
    fontWeight: "bold",
    border: border
  },
  overviewHeaderRowRight: {
    textAlign: "right",
    padding: "16px",
    fontSize: "12px",
    lineHeight: "1.33",
    color: "#2e2f32",
    fontWeight: "bold",
    border: border
  },
  overviewTableBody: {
    display: "contents",
  },
  overviewTableBodyRow: {
    padding: "16px",
    fontSize: "12px",
    lineHeight: "1.33",
    color: "#2e2f32",
    border: border
  },
  overviewBodyRowRight: {
    textAlign: "right",
    padding: "16px",
    fontSize: "12px",
    lineHeight: "1.33",
    color: "#2e2f32",
    border: border
  },
});

/**
 * OverviewPhase component used to view overview tab for sub-type "Phase"
 *
 */
const OverviewPhase = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { budgetDetail } = useSelector(state => state?.budget);
  const [budgetPhases, setBudgetPhases] = useState([]);

  const codeCustomBodyRender=(value, tableMeta)=>{
    return <Caption as="p" id={`Mui-code-row-${tableMeta?.rowIndex}`}>{value}</Caption>
  }
  const COLUMNS = [
    {
      name: "code",
      label: "Code",
      colKey: "code",
      options: {
        sort: true,
        customBodyRender: codeCustomBodyRender,
      },
    },
    {
      name: "displayName",
      label: "Display Name",
      colKey: "displayName",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`Mui-displayName-row-${tableMeta?.rowIndex}`, value),
      },
    },
    {
      name: "budgetAmount",
      label: "$ Budgeted",
      colKey: "budgetAmount",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`Mui-budgetAmount-row-${tableMeta?.rowIndex}`, value),
      },
    },
    {
      name: "paidAmount",
      label: "$ Paid",
      colKey: "paidAmount",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`Mui-paidAmount-row-${tableMeta?.rowIndex}`, value),
      },
    },
    {
      name: "balanceAmount",
      label: "$ Balance",
      colKey: "balanceAmount",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`Mui-balanceAmount-row-${tableMeta?.rowIndex}`, value),
      },
    },
  ];

  const [budgetPhaseColumns, setBudgetPhaseColumns] = useState(COLUMNS);

  useEffect(() => {
    if (budgetDetail !== "") {

      setBudgetPhases(budgetDetail?.budgetPhases);
      setLoading(false);
    }
  }, [budgetDetail]);

  const phaseCustomOptions = {
    responsive: "standard",
    totalCount: budgetPhases ? budgetPhases?.length : 0,
  };

  return (
    <div data-testid="OverviewPhase">
      <h2 className={classes.overviewTitleHead} id="budget-phase-overview">Phase</h2>
      {loading === false ?
        <>
          <div className={classes.dataTableWrap} data-testid="phases-list">
            <MuiDataTable
              data={budgetPhases}
              columns={budgetPhaseColumns}
              setTableColumns={setBudgetPhaseColumns}
              customOptions={phaseCustomOptions}
              onRowClick={() => { }}
              groupFilter={false}
              onExportClick={() => { }}
              onRowSelection={() => { }}
              onAddNew={() => { }}
              loading={loading}
              showRowsCounter={false}
              disableSearch={true}
              disableFilter={true}
              disablePagination={true}
              disableExport={true}
              hideAddNewButton={true}
            />
          </div>
        </>
        :
        <Skeleton height="20px" variant="rectangle" width="100%" />}

    </div>
  )
}

export default OverviewPhase;
