import React, { useState } from "react";
import { Grid, GridColumn, Skeleton} from "@walmart-web/livingdesign-components";
import { createUseStyles } from "react-jss";
import { SPACING } from "../../../../constants/styleGuide";
import {
  APPROVED,
  REJECTED,
  lineSeparatorAfter,
  budgetLabels
} from "../../../../constants/constants";

/**
 * styles used in the component.
 */
const fontFamily = `Bogle, \"Helvetica Neue\", Helvetica, Arial, sans-serif`;
const useStyles = createUseStyles({
  mainContent: {
    backgroundColor: "#FFF",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: "16px"
  },
  labelStyle: {
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: fontFamily,
    color: "#2e2f32"
  },
  contentStyle: {
    fontWeight: "normal",
    fontSize: "14px",
    fontFamily: fontFamily,
    color: "#2e2f32",
    width: "max-content"
  },
  headerStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    fontFamily: fontFamily,
    color: "#2e2f32",
    marginLeft: "0px",
    marginTop: "0px"
  },
  buttonStyle: {
    marginLeft: "0px",
    width: "121px",
    marginTop: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
    fontSize: "14px"
  },
  lineStyle: {
    width: "100%",
    marginTop: "34px",
    borderWidth: "0.25px",
    height: "0.25px"
  },
  contentSpacing8: {
    paddingTop: SPACING.s8,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
    width: "100%",
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
    width: "100%",
  },
  contentSpacing48: {
    paddingTop: SPACING.s48,
  },
});

/**
 * BudgetDetails component used to view details tab for a budget
 *
 */
const BudgetDetails = ({ budgetDetail }) => {

  const classes = useStyles();
  const [loading, ] = useState(false);

  const ShowValue=(props)=>{ 
    const {item} = props;
    let value;
    if (item === "balanceAmount" || item === "paidAmount") {
      value = `$${budgetDetail[item]}`;
    }else if(budgetDetail[item]){
      value = budgetDetail[item]
    }else{
      value = "-";
    }
    return value;
  }

  const getLeadAttorneyName=()=>{
    return budgetDetail?.leadAttorneyName ? budgetDetail?.leadAttorneyName : '-';
  }

  return (<div data-testid={"budgetDetailsContent"} >
    {loading ? (
      <Skeleton height="16px" variant="rectangle" />
    ) : (
      <>
        {
          Object.keys(budgetLabels)?.map((item, idx) => {
            return (
              <div key={item} className={classes.mainContent}>
                <Grid>
                  <GridColumn sm={6} md={4} lg={2}>
                    <div className={classes.labelStyle}>{budgetLabels[item]}</div>
                  </GridColumn>
                  <GridColumn sm={6} md={4} lg={2}>
                    <div className={classes.contentStyle} data-testid={`budget-details-${item}`} id={`budget-details-${item}`}>{<ShowValue item={item}/>}</div>
                  </GridColumn>
                </Grid>
                {lineSeparatorAfter?.includes(budgetLabels[item]) && <hr className={classes.lineStyle} />}
              </div>
            )
          })
        }
        <div className={classes.mainContent}>
          <Grid>
            <GridColumn sm={6} md={4} lg={2}>
              <div className={classes.labelStyle}>Approving Party</div>
            </GridColumn>
            <GridColumn sm={6} md={4} lg={2}>
              <div className={classes.contentStyle}>{
                (
                  (budgetDetail?.status?.toLowerCase() === APPROVED.toLowerCase()) ?
                    <span data-testid="approving-party">{getLeadAttorneyName()}</span>:
                    '-'
                )
              }</div>
            </GridColumn>
          </Grid>
        </div>
        <div className={classes.mainContent}>
          <Grid>
            <GridColumn sm={6} md={4} lg={2}>
              <div className={classes.labelStyle}>Rejecting Party</div>
            </GridColumn>
            <GridColumn sm={6} md={4} lg={2}>
              <div className={classes.contentStyle}>{
                (
                  (budgetDetail?.status === REJECTED) ?
                  <span data-testid="rejecting-party">{getLeadAttorneyName()}</span> :
                  '-'
                )
              }</div>
            </GridColumn>
          </Grid>
          <hr className={classes.lineStyle} />
        </div>

        <div className={classes.mainContent}>
          <Grid>
            <GridColumn sm={6} md={4} lg={2}>
              <div className={classes.labelStyle}>Version</div>
            </GridColumn>
            <GridColumn sm={6} md={4} lg={2}>
              <div className={classes.labelStyle}>{budgetDetail?.version}</div>
            </GridColumn>
          </Grid>
          {/*It will use in future
          deleteLoading?
            <Button
              className={classes.buttonStyle}
              size="small"
              variant="secondary"
              isFullWidth={false}
              data-testid="task-export"
            >
              <Spinner color="gray" size="small" />
            </Button>:
            <Button className={classes.buttonStyle} size="medium" variant="destructive" onClick={deleteBudget}>
              Delete Budget
            </Button>
            */}
        </div>
      </>
    )}
  </div>
  );
}

export default BudgetDetails;
