import { createUseStyles } from "react-jss";
import { SPACING } from "../../../../constants/styleGuide";

const InvoiceLineItemsStyles = createUseStyles({
    invoiceLineItemsContainer: {
      paddingTop: SPACING.s24,
      backgroundColor: '#fff',
    },
    invoiceTitleWrap: {
      paddingBottom: SPACING.s16,
      paddingLeft: SPACING.s24,
      paddingRight: SPACING.s24,
      paddingTop: SPACING.s16,
    },
    lineItemTabNav: {
      "& nav ": {
        boxShadow: 'none'
      },
      "& nav >ul>li>a": {
        backgroundColor: "#fff",
      },
    },
    warningStyle: {
      padding: "10px 0px",
      height: "42px"
    },
    actionStyle: {
      visibility: "hidden"
    },
    warning: {
      padding: "10px 0",
      "& div.MuiAccordionSummary-root":{
        minHeight: "20px",
        padding: "0"
      },
      "& div.MuiAccordionSummary-content":{
        margin: "10px 0"
      },
      "& div.MuiAccordionSummary-expandIcon":{
        color: "#000"
      },
      "& div.MuiAccordionDetails-root":{
        display: "block",
        padding: "0"
      },
    },
    subTitle: {
      paddingTop: "5px"
    },
    groupHeading:{
      color: '#2E2F32',
      fontWeight: 'bold',
      fontSize: '18px'
    },
    groupMessage:{
      padding: "12px 0",
      fontSize: '13px'
    }
  });

export default InvoiceLineItemsStyles;
