import React, { useEffect, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { useSnackbar } from "@walmart-web/livingdesign-components";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getErrorMessage } from "../../../helpers/utils";
import { MASTER_DATA } from "../../../constants/baseURLs";
import { getService } from "../../../axios/axios";
import { MASTER_PARAMS } from "../../../constants/constants";
import { setMasterData } from "../../../store/actions/masterDataAction";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * Component to render Master Data search Multi select Autocomplete dropdown
 *
 */
const MasterDataMultiAutocomplete = (props) => {
	const { dataType, value, setValue, required=false, colKey="", disabled = false, placeholderValue, id="masterData-autocomplete", testid="masterData-autocomplete" } = props;
	const [open, setOpen] = useState(false);
	const [loading,setLoading]=useState()
	const dispatch = useDispatch();
  	const { addSnack } = useSnackbar();
	const masterDetails = useSelector(state => state?.masterData?.masterDatas);

	/**
  	* It'll fetch all static data mainly for dropdown options across application
  	*/
	const callMasterData = () => {
		setLoading(true)
		getService(
		  MASTER_DATA,
		  `/master-data/v1?types=${MASTER_PARAMS}`
		)
		  .then((response) => {
			dispatch(setMasterData(response?.data));
			setLoading(false)
		  })
		  .catch((error) => {
			dispatch(setMasterData([]));
			addSnack({
			  message: getErrorMessage(error)
			});
			setLoading(false)
		  });
	}

	useEffect(()=>{
		if(!masterDetails?.[dataType]){
		 callMasterData()
		}
	  },[])

	const sortedMasterData = masterDetails?.[dataType]?.masterData?.sort((a, b) => a?.value?.localeCompare(b?.value));
	return (
		<Autocomplete
            multiple
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			disableCloseOnSelect={false}
			disablePortal={true}
			autoHighlight
			autoComplete
			loading={loading}
			id={id}
			options={masterDetails?.[dataType]?.masterData?.map(val => val?.value) ? sortedMasterData?.map(val => val?.value) : []}
			getOptionLabel={(option) =>`${option}`}
			renderOption={(props, option, {selected}) => (
				<Box component="li" {...props} key={option?.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
          />
					{`${option}`}
				</Box>
			)}
			defaultValue={value}
			value={value}
			disabled={disabled}
			fullWidth={true}
			onChange={(e, v) => {setValue(e,v,colKey)}}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params.inputProps, required, style: {
							color: '#000000',
							fontFamily: 'Bogle',
							fontSize: 16,
						}
					}}
					margin="dense"
					placeholder={placeholderValue}
					variant="outlined"
					data-testid={testid}
					/>
					)}
		/>
	);
};

export default MasterDataMultiAutocomplete;
