import moment from "moment-timezone";
import { BACKEND_DATE_FORMAT, DATE_YYYY_MM_DD, MOMENT_DATE_FORMAT } from "../constants/dateFormats";

const zone =  moment.tz.guess()

/**
 * Function to convert local datetime to UTC
 * @param {*} date
 * @param {*} time
 * @returns UTC equivalent
*/
export const convertToUTCDateTime = ({date, time}) => {
  const input = `${moment(date).format(DATE_YYYY_MM_DD)} ${time}`;
  return moment(input, BACKEND_DATE_FORMAT).utc().format(BACKEND_DATE_FORMAT);
}

/**
 * Fucntion to return display value of UTC date to local
 * @param {*} date
 * @returns
 */
export const displayLocalDate = (date, dtFormat = MOMENT_DATE_FORMAT, nullValue="-") => {
  return date ? moment.utc(date).local().format(dtFormat) : nullValue;
}
