import { createUseStyles } from "react-jss";

const AssociatedMatterStyles = createUseStyles({
	rowStyle: {
		"& div>table>tbody>tr>td": {
			borderBottom: "none",
			fontFamily: "Bogle",
			height: "24px",
			fontWeight: "normal",
			fontStrech: "normal",
			lineHeight: "1.33",
		},
		'& table': {
			'& tr': {
				'& th': {
					color: '#2E2F32',
					fontWeight: 'bold',
					fontSize: 12,
				},
			},
		}
	},
	manageUserButton: {
		paddingLeft: "0.8em"
	},
	breadcrumb: {
		paddingBottom: "24px",
	},
});

export default AssociatedMatterStyles;
