export const priorityTagColors = {
  'High': {
    'color': 'red',
    'variant': 'primary'
  },
  'Important': {
    'color': 'spark',
    'variant': 'primary'
  },
  'Low': {
    'color': 'gray',
    'variant': 'primary'
  },
  'None': {
    'color': 'gray',
    'variant': 'tertiary'
  },

};

export const priorityDropdownColors = {
  'High': {
    'color': '#9b1419'
  },
  'Important': {
    'color': '#b36a16'
  },
  'Medium': {
    'color': '#b36a16'
  },
  'Low': {
    'color': '#004f9a'
  },
  'None': {
    'color': '#515357'
  },
  '': {
    'color': '#2e2f32'
  },
};

export const statusTagColors = {
  'Upcoming': {
    'color': 'gray',
    'variant': 'tertiary'
  },
  'To-Do': {
    'color': 'purple',
    'variant': 'tertiary'
  },
  'In-Progress': {
    'color': 'blue',
    'variant': 'tertiary'
  },
  'Completed': {
    'color': 'green',
    'variant': 'tertiary'
  }
};

export const matterStatusTagColors = {
  'Open': {
    'color': 'blue',
    'variant': 'tertiary'
  },
  'Close': {
    'color': 'green',
    'variant': 'tertiary'
  }
};

export const statusDropdownColors = {
  'Upcoming': {
    'color': '#515357',
  },
  'To-Do': {
    'color': '#452358',
  },
  'In-Progress': {
    'color': '#004f9a',
  },
  'Completed': {
    'color': '#1d5f02',
  },
};

export const adminExternalUsersTagColors = {
  'Inactive': {
    'color': 'purple',
    'variant': 'secondary'
  },
  'Active': {
    'color': 'green',
    'variant': 'secondary'
  },
  'Pending':{
    'color': 'spark',
    'variant': 'secondary'
  },
  'Rejected': {
    'color': 'red',
    'variant': 'secondary'
  },
};

export const jobStatusTagColors = {
  'In Progress': {
    'color': 'blue',
    'variant': 'tertiary'
  },
  'Complete': {
    'color': 'green',
    'variant': 'tertiary'
  },
  'Partial Success': {
    'color': 'spark',
    'variant': 'tertiary'
  },
  'Error': {
    'color': 'red',
    'variant': 'tertiary'
  },
  'Failed': {
    'color': 'red',
    'variant': 'tertiary'
  }
};

export const organizationTagColors = {
  'Inactive': {
    'color': 'purple',
    'variant': 'secondary'
  },
  'Active': {
    'color': 'green',
    'variant': 'secondary'
  },
  'Limited Use': {
    'color': 'spark',
    'variant': 'secondary'
  },
  'Do Not Use': {
    'color': 'red',
    'variant': 'secondary'
  }
};



export const orgTagsStyle = {
  'Active': {
    'color': '#4169E1',
  },
  'InActive': {
    'color': '#9b1419'
  },
  "Select Tags": {
    'color': '#2e2f32'
  },
  "": {
    'color': '#2e2f32'
  },
};

export const matterListStatusTagColors = {
  'Open': {
    'color': 'blue',
    'variant': 'tertiary'
  },
  'Closed': {
    'color': 'purple',
    'variant': 'tertiary'
  },
  'Draft': {
    'color': 'gray',
    'variant': 'tertiary'
  },
  'Legal Hold': {
    'color': 'green',
    'variant': 'tertiary'
  }
};

export const eventTypeColorCodes = {
  'Arbitration Date': {'color': '#0071dc'},
  'Mediation Date': {'color': '#2a8703'},
  'Settlement Conference': {'color': '#ffc220'},
  'Holiday': {'color': '#80b8ee'},
  'Trial Date': {'color': '#de1c24'}
};

export const FULL_GRAY = '#888'
export const LIGHT_GRAY = '#F5F5F5'
export const LIGHT_DARK_BLACK = '#2e2f32'
export const WHITE = '#fff';
export const GRAY = 'gray';
export const BLACK = '#000';
export const GREEN = '#1d5f02';
export const DARK_GRAY = "#e3e4e5";
export const DISABLED_GRAY = "#BABBBE";
export const LIGHT_RED = "#ef8e92";
export const LIGHT_GREEN = "#95c381";
export const LIGHT_YELLOW = "#ffe190";
export const DARK_BLUE = "#002D58";

export const matterRequestRatesStatusColor = {
  "submitted": {
    label: 'Submitted',
    color: 'spark',
  },
  "expired": {
    label: 'Expired',
    color: 'red',
  },
  "rejected": {
    label: 'Rejected',
    color: 'red',
  },
  "active": {
    label: 'Active',
    color: 'green',
  },
  'inactive': {
    label: 'Inactive',
    color: 'gray'
  },
  'invalid': {
    label: 'Invalid',
    color: 'red'
  },
};

export const practiceAreaRatesStatusColor = {
  "submitted": {
    label: 'Submitted',
    color: 'spark',
  },
  "expired": {
    label: 'Expired',
    color: 'red',
  },
  "rejected": {
    label: 'Rejected',
    color: 'red',
  },
  "active": {
    label: 'Active',
    color: 'green',
  },
  'inactive': {
    label: 'Inactive',
    color: 'gray'
  },
  'invalid': {
    label: 'Invalid',
    color: 'red'
  },
};

