import { createUseStyles } from "react-jss";

const TrainingGuidesStyles = createUseStyles({
      contentWrapper: {
        width: '100%',
        background: 'white',
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.15)",
        display: "flex",
      },
      navigation: {
        maxWidth: 150,
        background: 'white',
        display: "flex",
        flexDirection: "column",
      },
      sideNavigation: {
        paddingTop: 30,
        overflowY: "auto",
        overflowX: "hidden",
        flex: 1,
        marginBottom: 70,
        "& ul": {
          height: "100%",
          wordBreak: "break-all",
        },
      },
      card:{
        margin:"15px 0px",
        cursor: "pointer",
        display:"flex",
        "&:hover":{
          background:"#f3f3f3"
        }
      },
      selectedCard:{
        margin:"15px 0px",
        cursor: "pointer",
        background: "#f2f8fe",
        display:"flex",
      },
      cardContainer:{
        marginTop:"10px",
        marginLeft:'1px',
      },
      dividerContainer:{
        display:'flex',
        marginLeft:'15px',
        width:'1%',
        marginTop:"20px",
        marginBottom:'20px'
      },
      article:{
        margin:'20px 0px 20px 20px',
        display:'flex',
      },
      textContainer:{
        display:'flex',
        flex:1,
        marginLeft:"10px"
      },
      title:{
        display:'flex',
        margin:'18px 10px 5px 0px',
        fontFamily: "Bogle",
        fontWeight: 700,
        lineHeight: "20px",
        fontSize:'16px'
      },
      subTitle:{
        flex:1,
        fontFamily: "Bogle",
        fontWeight: 400,
        lineHeight: "20px",
        fontSize:'14px',
        paddingBottom:'20px',
        paddingRight:"10px",
        textOverflow: "ellipsis",
        overflow:"hidden",
        width:"120px",
        whiteSpace:"nowrap",
        "&:hover": {
          overflow: "visible",
          whiteSpace:"wrap",
          wordBreak: "break-word",
        }
      },
      cardContent:{
        display:"flex",
        flex:1
      },
      docViewer: {
        height: '68vh !important',
      },
      docViewerWrap:{
        height: '80vh !important',
        width: '100%',
        display: "flex",
        flexDirection: "column",
        flex:1,
        overflowX: "scroll",
        '& #header-bar': {
          minHeight: '0'
        },
        '.previewModal & #header-bar': {
          minHeight: '0'
        },
        '& #pdf-controls': {
          backgroundColor: '#fff',
          zIndex: '999 !important'
        },
        '& #pdf-pagination-prev': {
          order: 2,
          boxShadow: 'none',
        },
        '& #pdf-pagination-info': {
          order: 1,
          paddingRight: '10px',
        },
        '& #pdf-pagination-next': {
          order: 3,
          boxShadow: 'none',
          marginRight: '0',
        },
        '& #pdf-toggle-pagination': {
          boxShadow: 'none',
        },
        '& #pdf-download': {
          display: "none"
        },
        '& #pdf-zoom-out, & #pdf-zoom-in, & #pdf-zoom-reset, & #pdf-toggle-pagination, & #pdf-pagination-next, & #pdf-pagination-prev': {
          opacity: 0.6
        },
        '& #image-renderer, & #pdf-renderer': {
          backgroundColor: '#f5f5f5',
          boxShadow: 'inset -1px 0 0 0 rgba(0, 0, 0, 0.15)'
        }
      },
      docContent:{
        background:"#F3F3F3",
        padding:"10px",
        margin:"20px 20px 20px 0",
        '& div > div > div.iSVAnT':{
          background:'white !important'
        },
      },
      icons:{
        display:"flex",
        justifyContent:"end",
        padding:"10px",
        flex:1
      },
      fileName:{
        background:"white !important",
        width:"100%",
        padding:"20px 0px 20px 20px",
        fontFamily: "Bogle",
        fontWeight: 700,
        lineHeight: "24px",
        fontSize:'36px',
        wordBreak: "break-word",
      },
      divider:{
        borderColor:"#9E9E9E",
        width:"100%",
      },
      previewNotSupported: {
        display:"flex",
        margin: 20,
        height: "100%",
        justifyContent: "center",
        background:"#F3F3F3",
      },
      downloadButton: {
        alignSelf: "center",
        marginTop: 20
      },
      previewCard: {
        alignSelf: "center",
        height: "64vh",
        textAlign: "center",
        padding: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },
      header:{
        background:"white !important",
        width:"100%",
        display:"flex"
      },
      spinner:{
        alignSelf:"center",
        display:"flex",
        flex:1,
        color:"#74767c"
      },
      externalLink :{
        color:"black"
      },
      cardWrap:{
        height: '80vh',
        overflowY: 'scroll',
        paddingRight:"2px"
      }

  })

export default TrainingGuidesStyles;



