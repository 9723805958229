import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  TabNavigation,
  TabNavigationItem,
} from "@walmart-web/livingdesign-components";
import {
    MATTER_DOCUMENTS_TAB,
} from "../../../constants/constants";
import MatterDocumentsTab from "./MatterDocumentsTab";
import MatterRecentDocumentsTab from "./MatterRecentDocumentsTab";
import MatterStarredDocumentsTab from "./MatterStarredDocumentTab";
import { setDocumentBreadcrumbIndex } from "../../../store/actions/documentActions";
import { useDispatch, useSelector } from "react-redux";
import { setMatterDocumentsTabIndex } from "../../../store/actions/matterAction";


/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  wrapper: {
    background: "#F5F5F5",
    width: "100%"
  },
  tabContainer: {
    "& nav > ul > li > a": {
      background: "none",
    },
    backgroundColor: '#fff'
  }
});

/**
 * Matter files component
 */
 const MatterFiles = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const documentTabIndex = useSelector((state) => state?.matter?.matterDocumentTabIndex);

  const [isCurrent, setIsCurrent] = useState(documentTabIndex ? documentTabIndex : 0);

  /**
   * Function to set data based on tab selection
   * @param {*} index
   */
  const tabContent = (index) => {
    switch (index) {
      case 0:
        return <MatterDocumentsTab/>;
      case 1:
        return <MatterRecentDocumentsTab />;
      case 2:
        return <MatterStarredDocumentsTab />;
      default:
        return <MatterDocumentsTab/>;
    }
  };


  return (
    <div className={classes.wrapper}>
        <div className={classes.tabContainer}>
          <TabNavigation>
            {MATTER_DOCUMENTS_TAB?.map((item, index) => (
              <TabNavigationItem
                id={`matter-files-${index}`}
                data-testid={`matter-files-${index}`}
                onClick={() =>{ setIsCurrent(index);  dispatch(setMatterDocumentsTabIndex(index));  dispatch(setDocumentBreadcrumbIndex("RESET")); }}
                isCurrent={isCurrent === index}
                key={item}
              >
                {item}
              </TabNavigationItem>
            ))}
          </TabNavigation>
        </div>
        {tabContent(isCurrent)}
    </div>
  );
};

export default MatterFiles;
