import types from '../types/invoiceActionTypes';

export const setInvoiceData = (payload) => {
  return { type: types.SET_INVOICE_DATA, payload }
}

export const setInputValue = (name, value) => {
  return { type: types.SET_INPUT_VALUE, payload: { name, value } }
}

export const setInvoiceDetail = (payload) => {
  return { type: types.SET_INVOICE_DETAIL, payload }
}

export const resetInvoiceDetail = (payload) => {
  return { type: types.RESET_INVOICE_DETAIL, payload }
}

export const setMatterInvoiceData = (payload) => {
  return { type: types.SET_MATTER_INVOICE_DATA, payload }
}

export const setLineItemsData = (payload) => {
  return { type: types.SET_LINE_ITEMS_DATA, payload }
}

export const setNonAdjustedFeeLineItems = (payload) => {
  return { type: types.SET_NON_ADJUSTED_FEE_LINE_ITEMS, payload }
}

export const setNonAdjustedExpenseLineItems = (payload) => {
  return { type: types.SET_NON_ADJUSTED_EXPENSE_LINE_ITEMS, payload }
}

export const setAdjustedFeeLineItems = (payload) => {
  return { type: types.SET_ADJUSTED_FEE_LINE_ITEMS, payload }
}

export const setAdjustedExpenseLineItems = (payload) => {
  return { type: types.SET_ADJUSTED_EXPENSE_LINE_ITEMS, payload }
}

export const setAdjustedPaginationForLineItems = (payload) => {
  return { type: types.SET_LINE_ITEM_PAGINATION_DETAIL_ADJUSTED, payload }
}

export const setNonAdjustedPaginationForLineItems = (payload) => {
  return { type: types.SET_LINE_ITEM_PAGINATION_DETAIL_NON_ADJUSTED, payload }
}

export const resetAdjustedPaginationForLineItems = () => {
  return { type: types.RESET_INVOICE_LINE_ITEM_ADJUSTED }
}

export const resetNonAdjustedPaginationForLineItems = () => {
  return { type: types.RESET_INVOICE_LINE_ITEM_NON_ADJUSTED }
}
