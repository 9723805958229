import React from "react";
import { createUseStyles } from "react-jss";
import moment from "moment";
import DocumentList from "../DocumentListing/DocumentList";
import { CREATED_BY} from "../../../constants/constants";
import { DATE_YYYY_MM_DD} from "../../../constants/dateFormats";
import { useParams } from "react-router-dom";
import withStarredItemsList from "../../Hoc/withStarredItemsList";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  wrapper: {
    background: "#F5F5F5",
    width: "100%"
  }
});

/**
 * Matter Documents tab component
 */
const MatterRecentDocumentsTab = () => {

  const classes = useStyles();
  const { matterId } = useParams();
  const MATTER_FILE_FILTER_CONFIG = [
    {
      colKey: "tags",
      type: "masterDataMultiAutocomplete",
      label: "Tagged with",
      dataType: "documentTags",
      defaultOpen: true,
      section: "left"
    },
    {
      colKey: "createdBy",
      type: "peopleMultiDropdownAutocomplete",
      label: CREATED_BY,
      options: [],
      defaultOpen: true,
      section: "left"
    },
    {
      colKey: "createdTimeStamp",
      type: "rangePicker",
      label: "Created Date Range",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Created Date Range'
    },
    {
      colKey: "version",
      type: "textField",
      label: "Version",
      section: "right",
      defaultOpen: true,
      id :"version"
    },
  ];
  // default query to fetch matter related documents
  const defaultQuery = [
    {
      "key": "metaData.IL_OBJECT_ID",
      "operation": "match",
      "value": matterId
    },
    {
      "key": "updatedTimeStamp",
      "operation": "lte",
      "value": moment().format(DATE_YYYY_MM_DD)
    },
    {
      "key": "metaData.isPublic", // fetch only public documents
      "operation": "match",
      "value": true
    }
  ];

  // default query to fetch matter related documents
  const defaultSortQuery = {
    "key": "updatedTimeStamp",
    "type": "desc"
  }

  return (
    <div className={classes.wrapper}>
      <DocumentList
      defaultQuery={defaultQuery}
      defaultSortQuery={defaultSortQuery}
      filterConfigs={MATTER_FILE_FILTER_CONFIG}
      fromMatter={true}
      hideAddNewButton={true}
      />
    </div>
  );
};

export default withStarredItemsList(MatterRecentDocumentsTab, 'starredDocuments');

