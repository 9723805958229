import { createUseStyles } from "react-jss";
import { LIGHT_GRAY } from "../../../constants/colors";

const InvoiceDetailsStyles = createUseStyles({
  listing: {
    backgroundColor: LIGHT_GRAY,
    padding: "25px 30px",
    flex: 1,
  },
  pageTitle: {
    fontSize: 36,
    display: 'flex',
    alignItems: 'center'
  },
  invoiceBadgesWrap: {
    display: "grid",
    gap: '15px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    margin: '16px 10px'
  },
  invoiceBadges: {
    backgroundColor: '#F2F8FD',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
    marginRight: '16px',
    textAlign: 'center',
    width:"100%",
    height:"80%"
  },
  invoiceBadgesLast: {
    marginRight: 0,
  },
  pageInfoBold: {
    fontWeight: "bold",
    '& h4': {
      fontSize: "18px"
    }
  },
  label: {
    fontSize: "14px",
  },
  header: {
    paddingLeft: 16,
  },
  tabContainer: {
    backgroundColor: "#ffffff",
    padding: "0px",
    margin: "24px 0px 24px 0px",
    "& > nav ": {
      backgroundColor: "#f5f5f5",
    },
    "& > nav >ul>li>a": {
      backgroundColor: "#f5f5f5",
    },
  },
  gridLayout: {
    display: "grid",
    gap: '15px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    margin: '16px 10px'
  },
  icons: {
    display: "flex",
    justifyContent: "right",
    "& button": {
      marginRight: "8px"
    },
  },
  pageTitleGroup: {
    margin: "16px 10px"
  },
  pageTitleWrap: {
    display: "flex",
    alignItems: 'center'
  },
  pageTitleBadge: {
    padding: "2px 8px",
    borderRadius: "2px",
  },
  invoiceDateSubtitle: {
    color: "#74767C",
    fontSize: "16px",
    fontWeight: "700",
    paddingTop: "8px"
  },
  contentSpacing8: {
    paddingTop: "8px"
  },
  alertStyle: {
    padding: "20px 0px 20px 0px"
  },
  groupHeading:{
    color: '#2E2F32',
    fontWeight: 'bold',
    fontSize: '18px'
  },
  groupMessage:{
    padding: "12px 0",
    fontSize: '13px'
  },
  disableTabs: {
    opacity: 0.5,
    pointerEvents: "none"
  },
  divTile: {
    display:"flex"
  },
  divMinus: {
    fontSize:"68px",
    alignSelf:"center",
  },
  divEqual: {
    fontSize:"45px",
    alignSelf:"center",
    paddingLeft:"10px"
  },
});

export default InvoiceDetailsStyles;
