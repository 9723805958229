import React from "react";
import PropTypes from 'prop-types';

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
	containerStyles: {
		height: 20,
    width: '100%',
    borderRadius: 50,
    position: 'relative'
	},
	labelStyles: {
		paddingTop: 2,
    color: '#2e2f32',
    fontWeight: 'bold',
    display: 'block',
    position: 'absolute',
    width: '100%',
    top: 0,
    textAlign: 'center'
	}
});

/**
 * ProgressBar -  Component will be used for displaying progress
 *
 * @visibleName ProgressBar
 */
const ProgressBar = (props) => {
	const classes = useStyles();
  const { bgColor, completed } = props;

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    background: `${bgColor}`,
    borderRadius: ((completed === '100')?'inherit': '10px 0 0 10px'),
    textAlign: 'center',
  }

  return (
    <div data-testid="progress-bar" className={classes.containerStyles}>
      <div style={fillerStyles}>
      </div>
      <span className={classes.labelStyles}>{`${completed}%`}</span>
    </div>
  );
};

ProgressBar.propTypes = {
  /**
   * Use for displaying the color of progress.
   *
  * */
  bgColor: PropTypes.string,
  /**
   * Use for displaying the value of progress.
   *
  * */
  completed: PropTypes.string
}

export default ProgressBar;
