
import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import {
  ButtonGroup,
  Button,
  Modal,
  Grid,
  GridColumn,
  TextField
} from "@walmart-web/livingdesign-components";
import { CANCEL } from "../../../constants/actionButtons";

/**
 * styles used in the component.
 */
 const useStyles = createUseStyles({
  fullWidth: {
    width: "100%"
  },
  uploadModalText: {
    fontSize: "14px",
    marginBottom: "20px",
  },
});

/**
 * Component to return add folder modal action button panel
 */
const AddFolderModalActions = (props) => {
  const history = useHistory();
  const {handleAddFolderModalState, isCurrent, fromMatter, subFolderName} = props
  return (
    <Grid>
      <GridColumn sm={5}>
        <ButtonGroup>
          <Button
            data-testid="goback-btn"
            size="small"
            onClick={() => handleAddFolderModalState(false)}
          >
            {CANCEL}
          </Button>
          <Button
            variant="primary"
            size="small"
            data-testid="upload-btn"
            disabled={!Boolean(subFolderName)}
            onClick={() => history.push(`/document/adddocument`, { newFolderName: subFolderName, isCurrent: isCurrent,fromMatter :fromMatter })}
          >
            Create Folder
          </Button>
        </ButtonGroup>
      </GridColumn>
    </Grid>
  );
};

/**
 * AddFolderModal component used to create new folder.
 */
const AddFolderModal = (props) => {
  const { open, handleAddFolderModalState, isCurrent, fromMatter= false } = props;
  const classes = useStyles();
  const [subFolderName, setSubFolderName] = useState("");
  const inputRef = useRef()

  return (
    <Modal
      onClose={() => handleAddFolderModalState(false)}
      isOpen={open}
      actions={<AddFolderModalActions subFolderName={subFolderName} isCurrent={isCurrent} fromMatter={fromMatter} handleAddFolderModalState={handleAddFolderModalState} />}
      size="small"
      title={"Add New Sub-Folder"}
      data-testid="add-folder-modal"
      onFocus={()=>{inputRef.current.focus()}}
    >
      <div className={classes.uploadModalText}>
        Provide a Sub-Folder name before adding documents.
      </div>
      <div>
        <TextField
          ref={inputRef}
          type="text"
          value={subFolderName}
          onChange={(e) => setSubFolderName(e.target.value)}
          data-testid="sub-folder-name"
          fullWidth={true}
        />
      </div>
    </Modal>
  );
};

export default AddFolderModal;

AddFolderModal.propTypes = {
  /**
   * pass the open/close state of modal pop up
  **/
  open: PropTypes.bool,
  /**
   * handles the reset of modal open/close state
  **/
  handleAddFolderModalState: PropTypes.func
}
