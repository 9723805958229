import { createUseStyles } from "react-jss";
import { SPACING } from "../../constants/styleGuide";
const MatterComponentStyles = createUseStyles({
  leftContent: {
    paddingRight: "18%",
    paddingLeft: SPACING.s8,
  },
  rightContent: {
    paddingLeft: "18%",
    paddingRight: SPACING.s8,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  contentSpacing12: {
    paddingTop: SPACING.s12,
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  minusIcon: {
		marginLeft: "10px",
		paddingTop: SPACING.s16,
		"&:last-child": {
			marginRight: 0
		}
	},
    addRowButton: {
		"& button": {
			padding: 0,
		},
		paddingTop: SPACING.s30,
	},
    deleteIcon: {
		border: "1px solid #46474a",
		borderRadius: "50%",
		cursor: "pointer",
	},
    flex: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap"
	},
    flexItem: {
		marginRight: "16px",
		paddingTop: SPACING.s16,
    display: 'flex',
    flexDirection: 'column',
		"&:last-child": {
			marginRight: 0
		}
	},
  autocomplete: {
    width: "240px",
  },
  fieldWidth: {
		width: "160px",
	},
  labelTitle: {
    fontSize: "14px",
    fontFamily: "Bogle",
    fontWeight: 'bold'
  },
  displayNone: {
  display: 'none'
  },
});

export default MatterComponentStyles;
