import React from "react";
import Listing from "../../Listing/Listing";
import AllMattersList from "../AllMatters/AllMattersList";
import { formSearchMatterQuery} from "../../../helpers/utils";
import withUserPreferences from "../../Hoc/withUserPreferences";

export const getSearchQuery = (searchText, COLUMNS) => {
  const value = [];
  COLUMNS?.forEach(function (item, index) {
    value[item?.colKey] = [searchText];
  });
  const {matterQuery} = formSearchMatterQuery(value);
  return matterQuery;
};

/**
 * Matter List component to list the matters under different tabs
 */
const MatterList = () => {
  return (
    <Listing title="Matters" subtitle=" " id="matterTitle">
      <div data-testid="matter-content">
        <AllMattersList/>
      </div>
    </Listing>
  );
};

export default withUserPreferences(MatterList);
