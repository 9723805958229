import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import DisplayValue from "../../../components/TextField/DisplayValue/DisplayValue";
import {
  Divider,
  Grid,
  GridColumn,
  Heading,
  Tag,
} from "@walmart-web/livingdesign-components";
import { ChevronDown, ChevronRight } from "@walmart-web/livingdesign-icons";
import {
  PHONE,
  STATE_BAR_LICENCE,
  SUBJECT_MATTER_EXPERTISE,
  JUSTIFICATION_FOR_TIMEKEEPER,
  JD_YEAR,
  WALMART_APPOVING_ATTORNEY,
  TKID,
  CLASSIFICATION,
  BILLING_CONTACT,
  CREATED_DATE,
  END_DATE,
  START_DATE,
  RATE_START_DATE,
  RATE_END_DATE,
  JUSTIFICATION_FOR_RATE,
  PROPOSED_WALMART_RATE,
  CURRENT_STANDARD_RATE,
  NAME,
  EMAIL,
  REQUESTER_EMAIL,
  TIMEKEEPER_DETAILS,
  CONTACT_INFO,
  DETAILS,
  APPROVAL_DATA,
  PRACTICEAREA_RATE_CARDS,
  NOT_SUBMITTED,
  ORGANIZATION,
  ADDRESS_TYPE,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  CITY,
  STATE,
  COUNTRY,
  JUSTIFICATION_FOR_ADMIN_TIMEKEEPER,
  JUSTIFICATION_FOR_ADMIN
} from "../../../constants/constants";
import { MOMENT_DATE_FORMAT } from "../../../constants/dateFormats";
import { SPACING } from "../../../constants/styleGuide";
import moment from "moment";
import { formatPhoneNumber } from "../../../helpers/commonUtils";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useLocation } from "react-router-dom";

const useStyles = createUseStyles({
  leftContent: {
    paddingRight: SPACING.s72,
  },
  rightContent: {
    paddingTop: SPACING.s32,
    paddingLeft: SPACING.s24,
    paddingRight: SPACING.s24,
  },
  contentSpacing8: {
    paddingTop: SPACING.s8,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  contentSpacing48: {
    paddingTop: SPACING.s48,
  },
  tag: {
    padding: 4,
    display: "inline-block",
  },
  info: {
    marginLeft: SPACING.s10,
  },
  unAssigned: {
    fontStyle: "italic",
  },
  approvalDataRightSection: {
    marginTop: "103px",
  },
  accordionDetails: {
    width: "100%",
    justifyContent: "space-between",
    padding: '0 10px 10px 10px'
  },
  gridColumn: {
    marginTop: "149px",
  },
  gridColumn1: {
    marginTop: "78px",
  },
  grid: {
    flex: 1,
  },
  accordionHeader: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    padding: '5px 10px 0px 5px'
  },
});

const TimeKeeperAdminSummary = (props) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const [expand, setExpand] = useState([]);

  const { practiceAreaRate } = useSelector(
    (state) => state?.timeKeeperAdmin?.timeKeeperAdminValues
  );

  const [selectedRole] = useState(
    pathname?.includes("timekeeper") ? "Timekeeper" : "Admin"
  )

  const {
    firstName,
    lastName,
    email,
    stateBarLicence,
    subjectMatterExpertise,
    justificationForTimeKeeper,
    tkid,
    classification,
    billingContact,
    phoneNo,
    jdYear,
    walmartApprovingAttroney,
    startDate,
    endDate,
    city,
    state,
    addressType,
    addressLine2,
    country,
    addressLine1,
    isTimeKeeperRoleEnabled
  } = useSelector((state) => state?.timeKeeperAdmin?.timeKeeperAdminValues);
  const { companyName } = useSelector((state) => state?.organization?.organizationDetailValues);
  const justificationVal = selectedRole === 'Admin' ? JUSTIFICATION_FOR_ADMIN : JUSTIFICATION_FOR_TIMEKEEPER
  
  return (
    <>
      <Grid>
        <GridColumn sm={6}>
          <div className={classes.leftContent}>
            <h2>{TIMEKEEPER_DETAILS}</h2>
            <div className={classes.contentSpacing8}>
              <h2>{CONTACT_INFO}</h2>
            </div>
            <DisplayValue label={NAME} value={`${firstName} ${lastName}`} />
            <div className={classes.contentSpacing16}>
              <DisplayValue label={EMAIL} value={email} />
            </div>
            <div className={classes.contentSpacing16}>
              <DisplayValue label={ORGANIZATION} value={companyName} />
            </div>
            <div className={classes.contentSpacing16}>
              <DisplayValue label={CITY} value={city} />
            </div>
            <div className={classes.contentSpacing16}>
              <DisplayValue label={STATE} value={state} />
            </div>
          </div>
        </GridColumn>
        <GridColumn sm={6} className={classes.gridColumn}>
          <DisplayValue label={PHONE} value={formatPhoneNumber(phoneNo)} />

          <div className={classes.contentSpacing16}>
            <DisplayValue label={ADDRESS_TYPE} value={addressType} />
          </div>
          <div className={classes.contentSpacing16}>
            <DisplayValue
              label={ADDRESS_LINE_1}
              value={addressLine1}
            />
          </div>
          <div className={classes.contentSpacing16}>
              <DisplayValue label={ADDRESS_LINE_2} value={addressLine2} />
          </div>
          <div className={classes.contentSpacing16}>
              <DisplayValue label={COUNTRY} value={country} />
            </div>
        </GridColumn>
      </Grid>
      <GridColumn sm={12}>
        <div className={classes.contentSpacing24}>
          <Divider />
        </div>
      </GridColumn>
      <Grid>
        <GridColumn sm={6}>
          <div className={classes.leftContent}>
            <div className={classes.contentSpacing8}>
              <h2>{APPROVAL_DATA}</h2>
              <DisplayValue label={STATE_BAR_LICENCE} value={stateBarLicence} />
            </div>
            <div className={classes.contentSpacing16}>
              <DisplayValue
                label={SUBJECT_MATTER_EXPERTISE}
                value={subjectMatterExpertise}
              />
            </div>
            <div className={classes.contentSpacing16}>
              <DisplayValue
                label={isTimeKeeperRoleEnabled ? JUSTIFICATION_FOR_ADMIN_TIMEKEEPER : justificationVal}
                value={justificationForTimeKeeper}
              />
            </div>
          </div>
        </GridColumn>
        <div className={classes.contentSpacing16}>
          <Divider />
        </div>
        <GridColumn sm={6} className={classes.gridColumn1}>
          <DisplayValue label={JD_YEAR} value={jdYear} />
          <div className={classes.contentSpacing16}>
            <DisplayValue
              label={WALMART_APPOVING_ATTORNEY}
              value={walmartApprovingAttroney}
            />
          </div>
          <div className={classes.contentSpacing16}>
            <DisplayValue label={REQUESTER_EMAIL} value={email} />
          </div>
        </GridColumn>
      </Grid>
      <GridColumn sm={12}>
        <div className={classes.contentSpacing24}>
          <Divider />
        </div>
      </GridColumn>
      <Grid>
        <GridColumn sm={6}>
          <div className={classes.leftContent}>
            <div className={classes.contentSpacing8}>
              <h2>{DETAILS}</h2>
              <DisplayValue label={TKID} value={tkid} />
            </div>
            <div className={classes.contentSpacing16}>
              <DisplayValue label={CLASSIFICATION} value={classification} />
            </div>
            <div className={classes.contentSpacing16}>
              <DisplayValue label={BILLING_CONTACT} value={billingContact ? 'Yes' : 'No'} />
            </div>
          </div>
        </GridColumn>
        <GridColumn sm={6} className={classes.gridColumn1}>
          <DisplayValue label={CREATED_DATE} value={moment().format(MOMENT_DATE_FORMAT)} />
          <div className={classes.contentSpacing16}>
            <DisplayValue label={START_DATE} value={moment(startDate)?.format(MOMENT_DATE_FORMAT)} />
          </div>
          <div className={classes.contentSpacing16}>
            <DisplayValue label={END_DATE} value={endDate ? moment(endDate)?.format(MOMENT_DATE_FORMAT) : "-"} />
          </div>
        </GridColumn>
      </Grid>
      <Grid>
        <GridColumn sm={12}>
          <div className={classes.contentSpacing24}>
            <h1>{PRACTICEAREA_RATE_CARDS}</h1>
          </div>
          {practiceAreaRate?.map((section, index) => {
            return (
              section?.id > 0 && (
                <Accordion
                  className={classes.accordion}
                  expanded={expand?.indexOf(index) > -1}
                  onChange={() =>
                    setExpand((expand) =>
                      expand?.indexOf(index) > -1
                        ? [...expand?.filter((value) => value !== index)]
                        : [...expand, index]
                    )
                  }
                  key={section?.id}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {expand?.indexOf(index) > -1 ? (
                      <ChevronDown size="large" />
                    ) : (
                      <ChevronRight size="large" />
                    )}
                    <div className={classes.accordionHeader}>
                      <Heading as="h1" size="small">
                        {section?.practiceArea}
                      </Heading>
                      <Tag color="gray" variant="tertiary">
                        {NOT_SUBMITTED}
                      </Tag>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid className={classes.grid}>
                      <Grid className={classes.accordionDetails}>
                        <GridColumn sm={6}>
                          <div className={classes.leftContent}>
                            <div className={classes.contentSpacing16}>
                              <DisplayValue
                                label={RATE_START_DATE}
                                value={moment(section?.rateStartDate)?.format(
                                  MOMENT_DATE_FORMAT
                                )}
                              />
                            </div>
                          </div>
                        </GridColumn>
                        <GridColumn sm={6}>
                          <div className={classes.contentSpacing16}>
                            <DisplayValue
                              label={CURRENT_STANDARD_RATE}
                              value={section?.currentStandardRate}
                            />
                          </div>
                        </GridColumn>
                        <GridColumn sm={6}>
                          <div className={classes.leftContent}>
                            <div className={classes.contentSpacing16}>
                              <DisplayValue
                                label={RATE_END_DATE}
                                value={section?.rateEndDate ? moment(section?.rateEndDate)?.format(
                                  MOMENT_DATE_FORMAT
                                ) : "-"}
                              />
                            </div>
                          </div>
                        </GridColumn>
                        <GridColumn sm={6}>
                          <div className={classes.contentSpacing16}>
                            <DisplayValue
                              label={PROPOSED_WALMART_RATE}
                              value={section?.proposedWalmartRate}
                            />
                          </div>
                        </GridColumn>
                        <GridColumn sm={6}>
                          <div className={classes.leftContent}>
                            <div className={classes.contentSpacing16}>
                              <DisplayValue
                                label={JUSTIFICATION_FOR_RATE}
                                value={section?.justificationForRate}
                              />
                            </div>
                          </div>
                        </GridColumn>
                        <GridColumn sm={6}>
                        <div className={classes.contentSpacing16}>
                            <DisplayValue
                              label={WALMART_APPOVING_ATTORNEY}
                              value={section?.walmartApprovingAttorneyFullName}
                            />
                          </div>
                        </GridColumn>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )
            );
          })}
        </GridColumn>
      </Grid>
    </>
  );
};

export default TimeKeeperAdminSummary;
