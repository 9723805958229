import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import {
  Grid,
  GridColumn,
  TextArea,
  Skeleton,
} from "@walmart-web/livingdesign-components";
import moment from "moment";
import {
  WALMART_APPOVING_ATTORNEY,
  PRACTICE_AREA,
  RATE_START_DATE,
  RATE_END_DATE,
  JUSTIFICATION_FOR_RATE,
  PROPOSED_WALMART_RATE,
  CURRENT_STANDARD_RATE,
  MSR_WALMART_ATTORNEY_NAME_SELECT,
  MIN_CALENDAR_DAYS,
  CS_IS_OC_INTERNAL_FILTER,
} from "../../../constants/constants";
import { INVALID_DATE_RANGE_MESSAGE } from "../../../constants/messages";
import { BACKEND_DATE_FORMAT } from "../../../constants/dateFormats";
import { SPACING } from "../../../constants/styleGuide";
import { setInputValuePracticeArea } from "../../../store/actions/timeKeeperAdminActions";
import Label from "../../../components/TextField/Label/Label";
import { timekeeperValidationRules } from "../../../Validation/validationRules";
import MuiValidationMessage from "../../../Validation/MuiValidationMessage/MuiValidationMessage";
import { onInputNumberChange, renderTernary } from "../../../helpers/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FeatureValueToggle } from "../../../components/FeatureFlags/FeatureFlags";
import { FEATURES } from "../../../constants/features";
import {TextField, Autocomplete} from "@mui/material";
import PeopleAutocompleteWithOptions from "../../../components/Autocomplete/PeopleAutocompleteWithOptions/PeopleAutocompleteWithOptions";

const CHARACTER_LIMIT = 100;

const useStyles = createUseStyles({
  leftContent: {
    paddingRight: SPACING.s72,
  },
  rightContent: {
    paddingTop: SPACING.s32,
    paddingLeft: SPACING.s24,
    paddingRight: SPACING.s24,
  },
  contentSpacing8: {
    paddingTop: SPACING.s8,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  contentSpacing48: {
    paddingTop: SPACING.s48,
  },
  tag: {
    padding: 4,
    display: "inline-block",
  },
  info: {
    marginLeft: SPACING.s10,
  },
  unAssigned: {
    fontStyle: "italic",
  },
  approvalDataRightSection: {
    marginTop: "103px",
  },
  grid: {
    justifyContent: "space-between",
  },
  gridColumn: {
    marginTop: "15px",
  },
  errorMsg:{
    marginLeft:"12px",
  },
  textfield:{
    width: "100%",
  }
});

const LoadField = ({ loader, skeletonHeight, skeletonVariant, skeletonLines = 1, node }) => {
  if (loader) {
    return <Skeleton height={skeletonHeight} variant={skeletonVariant} lines={skeletonLines} />;
  } else {
    return node;
  }
};

const TimeKeeperAdminCreatePracticeArea = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedRow, register, errors, setError, clearErrors } = props;

  const {
    id,
    practiceArea,
    rateStartDate,
    rateEndDate,
    justificationForRate,
    walmartApprovingAttorneyFullName,
    proposedWalmartRate,
    currentStandardRate,
  } = useSelector(
    (state) => state?.timeKeeperAdmin?.timeKeeperAdminValues?.practiceAreaRate[selectedRow]
  );

  const practiceAreaRateData = useSelector(
    (state) => state?.timeKeeperAdmin?.timeKeeperAdminValues?.practiceAreaRate
  );

  const practiceAreas = useSelector((state) => state?.matter?.practiceAreas);

  /**
   * Gets called when data changes in input fields.
   * @param {object} e - input event
   */
  const onInputChange = (name, value) => {
    const practiceAreaRate = practiceAreaRateData?.map((row) => {
      if (row?.id === id) {
        const obj = { ...row };
        obj[name] = value;
        obj.id = id === 0 ? 1 : id;
        return obj;
      } else {return row}
    });

    dispatch(setInputValuePracticeArea(practiceAreaRate));
  };

  /*
  *Loading Skeleton
  */
  const loadFieldProps = {
    loader: false,
    skeletonHeight: '56px',
    skeletonVariant: 'rectangle',
    skeletonLines: 1,
    node: <></>
  };

  /**
   * Gets called when data changes in input fields.
   * @param {object} e - input event
   */
  const handleWalmartAttorney = (value) => {
    const practiceAreaRate = practiceAreaRateData?.map((row) => {
      if (row?.id === id) {
        const obj = { ...row };
        obj['walmartApprovingAttorneyEmailId'] = value?.email;
        obj['walmartApprovingAttorneyFirstName'] = value?.firstName;
        obj['walmartApprovingAttorneyFullName'] = `${value?.firstName} ${value?.lastName}`;
        obj['walmartApprovingAttorneyLastName'] = value?.lastName;
        obj['walmartApprovingAttorneyUserId'] = value?.userId;
        obj.id = id === 0 ? 1 : id;
        return obj;
      } else {
        return row;
      }
    });

    dispatch(setInputValuePracticeArea(practiceAreaRate));
  };

  /**
   * Function to handle date picker error
   * @param {*} err
   * @param {*} dateType
   */
  const handleDateError = (err, dateType) => {
    if(err) {
      setError(dateType, { type: 'custom', message: INVALID_DATE_RANGE_MESSAGE })
    } else {
      clearErrors(dateType)
    }
  };

  return (
    <React.Fragment>
      <Grid>
        <GridColumn sm={9}>
          <div className={classes.leftContent}>
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <React.Fragment>
                  <Label text={PRACTICE_AREA} mandatoryField={true} />
                  <Autocomplete
                      multiple={false}
                      disablePortal
                      options={practiceAreas.map(option=>option.name)}
                      value={practiceArea}
                      renderInput={(params) => <TextField {...params} label="" placeholder={"Select Practice Area"}  textFieldProps={{
                        name: "practiceArea",
                      }}
                      error={errors && errors["practiceArea"]}
                      helperText={(errors && errors["practiceArea"]) && <MuiValidationMessage message={errors && errors["practiceArea"]?.message} />}
                      {...register(
                        'practiceArea',
                        { ...timekeeperValidationRules?.practiceArea, onChange: (e) => onInputChange(e.target.name, e.target.value) }
                      )}
                      />}
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(event,value) =>{onInputChange("practiceArea", value)}}
                      id={"timekeeper-practicearea-practice-area"}
                      data-testid="timekeeper-practicearea-practice-area"
       							/>
                </React.Fragment>
              })}
            </div>
            <div className={classes.contentSpacing24}>
              <Grid className={classes.grid}>
                <GridColumn lg={6}>
                  {LoadField({
                    ...loadFieldProps, node: <React.Fragment>
                      <Label text={RATE_START_DATE} mandatoryField={true} />
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{width:"100%"}}
                          required={true}
                          value={renderTernary(rateStartDate, moment(rateStartDate), null)}
                          onChange={(e) => {
                            onInputChange(
                              'rateStartDate',
                              e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                            );
                          }}
                          onError={(err) => {
                            handleDateError(err,'rateStartDate')
                          }}
                          slotProps={{inputAdornment:{position:"end"},textField:{InputProps:{style: { color: '#2E2F32' }},name:"rateStartDate","data-testid":"timekeeper-practicearea-startdate", "id":"timekeeper-practicearea-startdate"}}}
                          // FEATURE - TKBackdating - value toggle
                          minDate={FeatureValueToggle(FEATURES.TK_BACKDATING, moment().subtract(MIN_CALENDAR_DAYS, 'days'))}
                          maxDate={rateEndDate ? moment(rateEndDate) : null}
                        />
                      </LocalizationProvider>
                      {errors?.rateStartDate && <MuiValidationMessage message={errors?.rateStartDate.message} />}
                    </React.Fragment>
                  })}
                </GridColumn>
                <GridColumn lg={5}>
                  {LoadField({
                    ...loadFieldProps, node: <React.Fragment>
                      <Label text={RATE_END_DATE} />
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{width:"100%"}}
                          required={true}
                          value={renderTernary(rateEndDate, moment(rateEndDate), null)}
                          onChange={(e) => {
                            onInputChange(
                              'rateEndDate',
                              e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                            );
                          }}
                          onError={(err) => {
                            handleDateError(err,'rateEndDate')
                          }}
                          slotProps={{inputAdornment:{position:"end"},textField:{InputProps:{style: { color: '#2E2F32' }},name:"rateEndDate","data-testid":"timekeeper-practicearea-enddate", "id":"timekeeper-practicearea-enddate"}}}
                          minDate={moment(rateStartDate)}                        />
                      </LocalizationProvider>
                      {errors?.rateEndDate && <MuiValidationMessage message={errors?.rateEndDate.message} />}
                    </React.Fragment>
                  })}
                </GridColumn>
              </Grid>
            </div>
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, skeletonHeight: "128px", node: <React.Fragment>
                  <Label text={JUSTIFICATION_FOR_RATE} />
                  <TextArea
                    onChange={(e) =>
                      onInputChange(e.target.name, e.target.value)
                    }
                    value={justificationForRate}
                    maxLength={CHARACTER_LIMIT}
                    textAreaProps={{
                      name: "justificationForRate",
                    }}
                    data-testid="timekeeper-practicearea-justificationforrate"
                  />
                </React.Fragment>
              })}
            </div>
          </div>
        </GridColumn>
        <GridColumn sm={3} className={classes.gridColumn}>
          {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <Label text={WALMART_APPOVING_ATTORNEY} mandatoryField={true} />
              
            <PeopleAutocompleteWithOptions
              value={walmartApprovingAttorneyFullName}
              setValue={(value) => {
                handleWalmartAttorney(value);
              }}
              placeholderValue={MSR_WALMART_ATTORNEY_NAME_SELECT}
              filterCheck={false}
              testid="timekeeper-practicearea-walmartapprovingattorney"
              id="timekeeper-practicearea-walmartapprovingattorney"
              additionalQuery={CS_IS_OC_INTERNAL_FILTER}
            />
            </React.Fragment>
          })}
          <div className={classes.contentSpacing16}>
            {LoadField({
              ...loadFieldProps, node: <React.Fragment>
                <Label text={PROPOSED_WALMART_RATE} mandatoryField={true} />
                <TextField
                  onChange={(e) => onInputChange("proposedWalmartRate", e.target.value)}
                  value={proposedWalmartRate}
                  name= "proposedWalmartRate"
                  type="number"
                  onKeyDown={(e) => onInputNumberChange(e)}
                  data-testid="timekeeper-practicearea-proposedwalmartrate"
                  id="timekeeper-practicearea-proposedwalmartrate"
                  fullWidth={true}
                  className={classes.textfield}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    }
                  }}
                />
              </React.Fragment>
            })}
          </div>
          <div className={classes.contentSpacing16}>
            {LoadField({
              ...loadFieldProps, node: <React.Fragment>
                <Label text={CURRENT_STANDARD_RATE} mandatoryField={true} />
                <TextField
                  onChange={(e) => onInputChange("currentStandardRate", e.target.value)}
                  value={currentStandardRate}
                  name= "currentStandardRate"
                  type="number"
                  onKeyDown={(e) => onInputNumberChange(e)}
                  data-testid="timekeeper-practicearea-currentstandardrate"
                  id= "timekeeper-practicearea-currentstandardrate"
                  fullWidth={true}
                  className={classes.textfield}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    }
                  }}
                />
              </React.Fragment>
            })}
          </div>
        </GridColumn>
      </Grid>
    </React.Fragment>
  );
};

export default TimeKeeperAdminCreatePracticeArea;
