
import { createTheme } from "@mui/material";
import { LIGHT_DARK_BLACK, WHITE } from "../../../constants/colors";
import { BORDERS } from "../../../constants/styleGuide";
const borderTop = "1px solid #eee";

const MuiDataTableTheme = () =>
  createTheme({
    components: {
      MuiPaperElevation: {
        styleOverrides: {
          root: {
            boxShadow: 'none !important'
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            fontFamily: 'bogle',
            ".filesTable &": {
              borderTop: BORDERS.GREY,
            },
            ".lineItemsTable &": {
              borderTop: "none",
            }
          },
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: "12px",
            fontFamily: "Bogle",
            ".filesTable &": {
              borderBottom: BORDERS.GREY,
            },
            ".rowStyle &": {
              fontFamily: "Bogle",
            },
            borderBottom: BORDERS.GREY,
          },
          paddingCheckbox: {
            background: "#fff",
            ".MuiTableRow-root.MuiTableRow-hover:hover &": {
              background: "#e5e5e5"
            },
            ".hideCollpaseIcon &": {
              display: "none"
            }
          },
          head: {
            color: "#74767c",
            paddingTop: "12px",
            paddingBottom: "12px",
          },
          body: {
            paddingTop: "8px",
            paddingBottom: "8px",
            ".rowStyle &": {
              borderBottom: "none",
            },
            ".lineItemsTable &": {
              paddingTop: "0",
              paddingBottom: "0",
            },
          },
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            order: "2",
            padding: "0",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "@media (max-width: 740px)": {
              margin: "0.5rem 0",
            },
            "& .Mui-checked": {
              color: "#000 !important",
            },
          },
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: "#000 !important",
            },
            "&.MuiCheckbox-indeterminate": {
              color: "#000 !important",
            },
          },
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "12px",
            color: '#2E2F32',
            fontWeight: 'bold',
            fontFamily: "Bogle",
            textTransform: 'capitalize',
            ".lineItemsTable &": {
              color: "#2E2F32",
              fontWeight: "bold",
            },
          },
          label: {
            fontFamily: "Bogle"
          }
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontFamily: "Bogle",
            textTransform: 'capitalize',
            paddingLeft: '3px !important'
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            "&:last-child": {
              overflowX: "hidden",
            },
            "& > div.MuiToolbar-root": {
              borderBottom: 'none',
            },
          },
          selectRoot: {
            border: "1px solid #cdcdcd",
            padding: "0",
            borderRadius: "4px",
            marginLeft: "32px",
            "& > .MuiSelect-select.MuiSelect-select": {
              padding: "8px 12px",
              borderRadius: "4px",
              textAlignLast: "left",
              textAlign: "left",
              width: "72px",
              "&:focus": {
                background: "#fff",
              },
            },
            "& .MuiSelect-icon": {
              color: "rgb(46, 47, 50)",
            },
            "& + p": {
              color: "#000",
            },
          },
          actions: {
            "& .Mui-disabled": {
              width: "16px",
              height: "16px",
            },
            "& .MuiIconButton-root:first-child": {
              marginRight: "30px",
            },
          },
          caption: {
            fontFamily: "Bogle",
            color: "#74767c",
            paddingTop: "3px",
            fontSize: "14px"
          },
          toolbar: {
            minHeight: "56px",
            padding: "0",
            "@media (min-width: 600px)": {
              flexWrap: "nowrap",
            },
            ".lineItemsTable &": {
              minHeight: "52px",
              height: "auto",
            },
          },
          select: {
            fontFamily: "Bogle",
            fontSize: "14px"
          },
          menuItem: {
            fontFamily: "Bogle",
            fontSize: "14px"
          }
        }
      },
      MUIDataTablePagination: {
        styleOverrides: {
          navContainer: {
            width: "100%",
          },
          tableCellContainer: {
            padding: "0 1.7em",
          },
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            flexWrap: "wrap",
            ".filesTable &": {
              minHeight: 0,
              height: 0,
            },
            ".lineItemsTable &": {
              minHeight: 0,
              height: 0,
            },
            ".hidePagination &": {
              minHeight: 0,
              height: 0,
            },
            "& > p":{
              color: '#74767c',
              fontFamily: 'Bogle',
              fontSize: '14px'
            }
          },
          gutters: {
            "@media (min-width: 600px)": {
              padding: "0",
            },
          },
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.MuiTableRow-hover:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              ".orgMatters &": {
                cursor: "default !important",
              },
              cursor: "pointer"
            },
            "&.Mui-selected:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          },
        }
      },
      MUIDataTableFilterList: {
        styleOverrides: {
          root: {
            "& > button": {
              backgroundColor: LIGHT_DARK_BLACK,
              color: WHITE,
              fontFamily: "Bogle",
              fontSize: "12px",
              borderRadius: "4px",
              marginBottom: "5px",
              marginRight: "5px",
              lineHeight: "1",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
              "&:focus": {
                boxShadow: "none",
              },
            },
          },
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "& > fieldset": {
              borderColor: "#cdcdcd",
            },
          },
          input: {
            fontFamily: "Bogle",
            fontSize: "14px"
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Bogle",
            fontSize: "14px"
          }
        }
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          left: {
            ".sopAdjustSearchBarWidth &": {
              width: `calc(100% - 260px)`,
            },
          },
          actions: {
            display: "flex",
            flexDirection: "row-reverse",
            flexWrap: "wrap",
            margin: "1rem",
            "@media (max-width: 740px)": {
              flexDirection: "column-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: "0",
              marginRight: "0",
            },
          },
        }
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            ".filesTable &": {
              display: "none",
            },
            ".lineItemsTable &": {
              display: "none",
            },
          },
        }
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            "&.mui-row-selected": {
              backgroundColor: "transparent",
            },
          },
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            fontFamily: "Bogle",
            fontSize: '0.75rem',
            lineHeight: '1rem',
            padding: '6px !important'
          }
        }
      }
    },
  });

export default MuiDataTableTheme;
