import {
	Button,
	ButtonGroup,
	FormGroup,
	Grid,
	GridColumn,
	Modal, Radio, useSnackbar, Spinner
} from "@walmart-web/livingdesign-components";
import React from "react";
import { CANCEL, CREATE, EXPORT } from "../../../constants/actionButtons";
import { EXPORT_CONFIG_TEXT, SELECT_ATLEAST_ONE_ROW } from "../../../constants/constants";
import { ExportModalStyles } from "./ExportModal.styles";
import PropTypes from 'prop-types';
import Subheading from "../../TextField/SubHeading/Subheading";

/**
 * exportModalActions Actions
 */
const ExportModalActions = (props) => {
	const {onCancel, onCreate, exportLoading} = props;
	return (
		<ButtonGroup>
			<Button
				data-testid="goback-btn"
				id="goback-btn"
				size="small"
				onClick={onCancel}
			>
				{CANCEL}
			</Button>
			<Button
				variant="primary"
				size="small"
				data-testid="continue-btn"
				id="export-continue-btn"
				onClick={onCreate}
				disabled={exportLoading}
				trailing={exportLoading && <Spinner color="white" size="small" />}
			>
				{CREATE}
			</Button>
		</ButtonGroup>
	);
};

/*
*This Modal enables user to select export selection type- Current Selection, Current Page and All Data
*/
const ExportModal = (props) => {
	const {
		title,
		mode,
		onCancel,
		onCreate,
		rowsData,
		exportSelection,
		setExportSelection,
		setCurrentPageExport,
		serverSide=true,
		exportCurrentPage,
		exportLoading,
		showCurrentPageOption=true,
		showExportAllOption=false,
		showCurrentSelection
	} = props
	const { addSnack } = useSnackbar();
	const classes = ExportModalStyles();

	/*
	*Function to set the Export Selection Type
	*/
	const selectExportType = (value) => {
		setExportSelection(value)
		setCurrentPageExport(true);
	}

	return (
		<Modal
			isOpen={mode}
			onClose={onCancel}
			actions={<ExportModalActions onCancel={onCancel} onCreate={onCreate} exportLoading={exportLoading} />}
			size="medium"
			title={
				<>
					<div>{`Export ${title}`}</div>
					<div className={classes.modalSubText}>{EXPORT_CONFIG_TEXT}</div>
				</>
			}
			data-testid="Export-Modal"
		>
			<Grid>
				<div className={classes.exportName}>
					<Subheading as="div" size="small">
						{EXPORT}
					</Subheading>
				</div>
				<FormGroup>
					{showCurrentSelection && <GridColumn>
						<Radio
							label={"Current selection"}
							name="selection"
							data-testid="radio-selection"
							id="radio-selection"
							radioProps={{
								id: "radio-selection",
								name: "selection"
							}}
							onChange={(e) => {
								((rowsData?.length > 0) ?
									selectExportType(e.target.value) :
									addSnack({
										message: SELECT_ATLEAST_ONE_ROW,
									})
								)
							}}
							value="currentSelection"
							checked={exportSelection === 'currentSelection'}
						/>
					</GridColumn>}
					{showCurrentPageOption && <GridColumn>
						<Radio
							label={"Current page"}
							name="selection"
							data-testid="radio-page"
							id="radio-page"
							onChange={(e) => {
								serverSide?selectExportType(e.target.value):exportCurrentPage(e.target.value)
							}}
							value="currentPage"
							checked={exportSelection === 'currentPage'}
							radioProps={{
								id: "radio-page",
								name: "selection"
							}}
						/>
					</GridColumn>}
					{showExportAllOption &&
						<GridColumn>
							<Radio
								label={`All ${title}`}
								name="selection"
								data-testid="radio-all"
								id="radio-all"
								radioProps={{
									id: "radio-all",
									name: "selection"
								}}
								onChange={(e) => selectExportType(e.target.value)}
								value={`all${title}`}
								checked={exportSelection === `all${title}`}
							/>
						</GridColumn>}
				</FormGroup>
			</Grid>
		</Modal>
	)
}

export default ExportModal

ExportModal.propTypes = {
	/** Title fo Export Modal */
	title: PropTypes.string,
	/** callback function on cancel */
	onCancel: PropTypes.func,
	/** callback function on create */
	onCreate: PropTypes.func,
	/** selected items */
	rowsData: PropTypes.array,
	/** export selection type*/
	exportSelection: PropTypes.string,
	/** modal state variable */
	mode: PropTypes.bool,
	/** callback function on to change selection type */
	setExportSelection: PropTypes.func,
	/** callback function on to change status of currentPageExport */
	setCurrentPageExport: PropTypes.func,
	/** server side state variable */
	serverSide: PropTypes.bool,
	/** if server side true then this function will use */
	exportCurrentPage: PropTypes.func,
	/** When user clicks on submit liading will true */
	exportLoading: PropTypes.bool,
};

ExportModal.defaultProps = {
	rowsData: [],
	setCurrentPageExport : ()=>{},
	showCurrentSelection: true
}
