import {
	Button,
	Caption, Grid, GridColumn, useSnackbar
} from "@walmart-web/livingdesign-components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postService } from "../../../axios/axios";
import { Operations } from "../../../axios/operations";
import MuiDataTable from "../../../components/Table/MuiDataTable/MuiDataTable";
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import {
  CS_DEFAULT_PAGINATION_QUERY,
  CS_DEFAULT_SORT_QUERY,
  CS_IS_EXTERNAL_USER_FILTER,
  CS_SCOPES,
} from "../../../constants/constants";
  import { TIMEKEEPER_MESSAGE } from "../../../constants/messages";
import {
  ROWS_PER_PAGE_OPTIONS_TIMEKEEPER,
  ROWS_PER_PAGE_TIMEKEEPER,
} from "../../../constants/tableConfigs";
import { MOMENT_DATE_FORMAT } from "../../../constants/dateFormats";
import { getErrorMessage, renderTernary } from "../../../helpers/utils";
import { isValueEmptyCheck } from "../../../helpers/validationUtils";
import { setSelectedTimekeepersData } from "../../../store/actions/organizationAction";
import { setExternalUserData } from "../../../store/actions/peopleAction";
import AssignTimekeepersStyles from "./OrgAssignTimekeepers.styles";
import { formatPhoneNumber } from "../../../helpers/commonUtils";
import { formCompositeSearchQuery, formCsFilterObject } from "../../../helpers/csQueryUtils";
import { CANCEL } from "../../../constants/actionButtons";

/**
 * Service of Process All Timekeepers data component
 */
const OrgAssignTimekeepers = () => {
	const classes = AssignTimekeepersStyles();
  const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);

	const displayValue = (value, tableMeta) => {
		return (
			<Caption as="p">{isValueEmptyCheck(value)}</Caption>
		)
	}
	const COLUMNS = [
		{
			name: "id",
			label: "id",
			colKey: "id",
			options: {
				display: false,
			},
		},
		{
			name: "lastName",
			label: "Last Name",
			colKey: "lastName",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => displayValue(value, tableMeta)
			},
		},
		{
			name: "firstName",
			label: "First Name",
			colKey: "firstName",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => displayValue(value, tableMeta)
			},
		},
		{
			name: "phoneNumber",
			label: "Phone #",
			colKey: "phoneNumber",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => displayValue(formatPhoneNumber(value), tableMeta)
			},
		},
		{
			name: "rate",
			label: "Rate",
			colKey: "rate",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => isValueEmptyCheck(value),
				customFilterListOptions: { render: (v) => `Rate: ${v}$/hr` },
			},
		},
		{
			name: "startDate",
			label: "Create Date",
			colKey: "startDate",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => renderTernary((isValueEmptyCheck(value) !== "-"), moment(value).format(MOMENT_DATE_FORMAT), "-"),
				customFilterListOptions: { render: (v) => `Created Date: ${v}` },
			},
			operation: Operations.EQ,
			type: "date"
		},
	];

	const [timekeeperColumns, setTimekeeperColumns] = useState(COLUMNS);
	const [selectedData, setSelectedData] = useState([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [selectRowsData, setSelectRowsData] = useState([]);
	const { nodeList, count } = useSelector(
    (state) => state?.people?.externalUserData
  );
	const externalUsersData = nodeList?.filter(item => item?.userRequestId)
  const { addSnack } = useSnackbar();
	const dispatch = useDispatch()

	/**********
@types : [
'textField',
'datePicker',
]
**********/
	const FILTER_CONFIGS = [
		{
			colKey: "rate",
			type: "textField",
			label: "Rate",
			defaultOpen: true,
		},
		{
			colKey: "startDate",
			label: "Date Submitted",
			type: "datePicker",
			defaultOpen: true,
			displayLabel: false
		}
	];

	const timekeepersCustomOptions = {
		responsive: "standard",
		rowsPerPage: ROWS_PER_PAGE_TIMEKEEPER,
		rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_TIMEKEEPER,
    totalCount: count,
		filterConfigs: FILTER_CONFIGS
	};

	const getPeopleData = () => {
    setLoading(true)
    const defaultQuery = {
      "filters": [
        formCsFilterObject("isActive", "match", true),
        formCsFilterObject("organizations.id", "match", identifier)
      ],
      "operation": "AND",
      "properties": null
    }
    let query = {};
    const statusActiveFilter = formCsFilterObject("status", "match", "Active");
    defaultQuery.filters=[...defaultQuery.filters, CS_IS_EXTERNAL_USER_FILTER, statusActiveFilter]
    query = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, CS_DEFAULT_SORT_QUERY, CS_DEFAULT_PAGINATION_QUERY, {}, defaultQuery);

    postService(COMPOSITE_SEARCH_SERVICE, `/composite-search/v1?returnCsv=false`, query)
      .then((res) => {
        setLoading(false)
        const peopleData = res?.data?.people;
        dispatch(setExternalUserData({
          nodeList: peopleData?.data,
          count: peopleData?.count
        }));
      })
      .catch((error) => {
        setLoading(false)
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

  useEffect(() => {
    getPeopleData();
  }, []);

	/**
	* function will use when row is select/deselect
	*/
	const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
		const timekeepersDataList = externalUsersData;
		const result = allSelected?.map(item => {
			return timekeepersDataList && timekeepersDataList[item?.dataIndex]
		});
		setSelectRowsData(selectRowsData);
		setSelectedData(result)
	}

	/**
	* function will use when we click on select matters button to navigate to matters screen
	*/
	const selectMatters = () => {
		dispatch(setSelectedTimekeepersData(selectedData))
		history.push(`/manageTimekeepers/assignTimekeeper/selectMatters`);
	}

	/**
	 * function to go back to previous screen
	 */
	const handleBack = () => {
		history.goBack()
	}

	return (
		<div className={classes.pageContent}>
			<div className={classes.heading}>
				<Grid>
					<GridColumn sm={6}>
						Select Timekeepers
					</GridColumn>
					<GridColumn sm={6} className={classes.rightAlign}>
							<Button size="medium" variant="tertiary" data-testId="go-back" id="go-back" onClick={() => handleBack()}>
								{CANCEL}
							</Button>
							<Button size="medium" variant="primary" data-testId="select-matters" id="select-matters" onClick={() => selectMatters()}>
								Select Matters
							</Button>
					</GridColumn>
				</Grid>
			</div>
			<div className={classes.dataTableWrap}>
				<MuiDataTable
					data={externalUsersData}
					columns={timekeeperColumns}
					setTableColumns={setTimekeeperColumns}
					customOptions={timekeepersCustomOptions}
					loading={loading}
					selectableRowsType={"multiple"}
					hideAddNewButton={true}
					disableExport={true}
					selectedRows={selectRowsData}
					onRowSelection={handleRowSelectionChange}
					showSelectedRow={true}
					selectedRowMsg={TIMEKEEPER_MESSAGE}

				/>
			</div>
		</div>
	);
};

export default OrgAssignTimekeepers;
