export const ROWS_PER_PAGE_DEFAULT = 15;
export const ROWS_PER_PAGE_OPTIONS_DEFAULT = [5, 15, 50, 100];

export const ENABLE_SERVER_SIDE_FEATURES_RELATED_MATTERS = true;

export const ROWS_PER_PAGE_OPTIONS_BUDGET = [5, 15, 50, 100];
export const ROWS_PER_PAGE_BUDGET = 15;
export const ENABLE_SERVER_SIDE_FEATURES_BUDGET = true;

export const ROWS_PER_PAGE_OPTIONS_PEOPLE = [5, 15, 50, 100];
export const ROWS_PER_PAGE_PEOPLE = 15;
export const ENABLE_SERVER_SIDE_FEATURES_PEOPLE = true;

export const ROWS_PER_PAGE_OPTIONS_USER_REQUESTS = [5, 15, 50, 100];
export const ROWS_PER_PAGE_USER_REQUESTS = 15;
export const ENABLE_SERVER_SIDE_FEATURES_USER_REQUESTS = true;

export const ROWS_PER_PAGE_NOTE = 15;
export const ROWS_PER_PAGE_OPTIONS_NOTE = [5, 15, 50, 100];
export const ENABLE_SERVER_SIDE_FEATURES_NOTE = true;
export const DOWNLOAD_NOTE_FILENAME = "NoteListingTableDownload.csv";

export const ROWS_PER_PAGE_MATTER = 15;
export const ROWS_PER_PAGE_OPTIONS_MATTER = [5, 15, 50, 100];
export const ENABLE_SERVER_SIDE_FEATURES_MATTER = true;

export const ROWS_PER_PAGE_TIMEKEEPER = 15;
export const ROWS_PER_PAGE_OPTIONS_TIMEKEEPER = [5, 15, 50, 100];

export const ROWS_PER_PAGE_INVOICE = 15;
export const ROWS_PER_PAGE_OPTIONS_INVOICE = [5, 15, 50, 100];
export const ENABLE_SERVER_SIDE_FEATURES_INVOICE = true;

export const ROWS_PER_PAGE_DOCUMENTS = 15;
export const ROWS_PER_PAGE_OPTIONS_DOCUMENTS = [5, 15, 50, 100];

export const ROWS_PER_PAGE_CONTACT = 15;
export const ROWS_PER_PAGE_OPTIONS_CONTACT = [5, 15, 50, 100];
export const DOWNLOAD_CONTACT_FILENAME = "AssociatedContactTableDownload.csv";

export const ROWS_PER_PAGE_LINE_ITEMS = 5;
export const ROWS_PER_PAGE_OPTIONS_LINE_ITEMS = [5, 10, 15, 50];
export const ENABLE_SERVER_SIDE_FEATURES_LINE_ITEMS = true;

export const ROWS_PER_PAGE_ADD_INVOICE_LINE_ITEM = 15;
export const ROWS_PER_PAGE_OPTIONS_ADD_INVOICE_LINE_ITEM = [5, 15, 25, 50];
export const ENABLE_SERVER_SIDE_ADD_INVOICE_LINE_ITEM = false;
