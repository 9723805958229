import React from 'react';
import {Button,Spinner} from '@walmart-web/livingdesign-components';
import {CloudDownload} from '@walmart-web/livingdesign-icons';
import { EXPORT } from '../../../../constants/actionButtons';
import { Tooltip } from '@mui/material';

const MuiExportButton = (props) => {
  const {exportLoading, onExportClick , className} = props;
  return (
    <Tooltip title={"Export"}>
      <Button
        trailing={((exportLoading===true)?" ":<CloudDownload />)}
        size="small"
        variant="secondary"
        isFullWidth={false}
        onClick={onExportClick}
        data-testid="task-export"
        id="task-export"
        className={className}
      >
        {((exportLoading===true)?<Spinner color="gray" size="small" />: EXPORT)}
      </Button>
    </Tooltip>
  )
}

export default MuiExportButton;
