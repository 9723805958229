import React from "react";
import { Skeleton } from "@walmart-web/livingdesign-components";

const LoadComponent = ({
  loader=false,
  skeletonHeight="18px",
  skeletonWidth="100px",
  skeletonVariant="reactangle",
  skeletonLines = 1,
  styles = {},
  children,
}) => {
  return (
    <div data-testid="load-component">
      {loader ? (
        <span style={styles}>
          <Skeleton
            height={skeletonHeight}
            width={skeletonWidth}
            variant={skeletonVariant}
            lines={skeletonLines}
          />
        </span>
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </div>
  );
};
export default LoadComponent;
