import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { SPACING } from "../../constants/styleGuide";
import { detailModes as modes} from "../../constants/constants";

import DetailHeader from "../../components/TextField/DetailHeader/DetailHeader";
import DetailFooter from "../../components/TextField/DetailFooter/DetailFooter";
import DeleteModal from "../../components/Modal/DeleteModal/DeleteModal";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  detail: {
    flex: 1,
    padding: '25px 30px',
    background: '#ffffff',
    minHeight: '90vh'
  },
  transparentBackground: {
    background: 'transparent',
  },
  detailContent: {
    paddingTop: SPACING.s32,
    paddingBottom: SPACING.s32
  },
  spinner: {
    alignSelf: "center",
  },
});

/**
 * Detail component used to view details of a record, edit a record or create a new record
 *
 * @author [Prabhudev V]
 *
 * @param {string} mode - used to set view modes
 * @param {function} setMode - function will take string value as argument and used to display event and task information
 * @param {Array} breadcrumbValues - used to set breadcrumb values
 * @param {string} title - title of the Detail component
 * @param {string} newTitle - new title of the Detail component
 */
const Detail = (props) => {
  const {
    mode,
    setMode,
    breadcrumbValues,
    subject,
    newSubtitle,
    schedule,
    newTitle,
    deleteTitle,
    deleteMessage,
		deleteButtonText,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    loading,
    isDirty,
    isValid,
    footerActions,
    hideCancelButton,
    hideEditBtn,
    hideDeleteBtn,
    onCpyLnkClk,
    deleteLoading,
    editBtnTxt,
    cancelText,
    disableCancel,
    customClassName,
    handleSubmit
  } = props;

  const classes = useStyles();

  const [deleteOpen, setDeleteOpen] = useState(false);

  return (
    <form
      data-testid={"detail"}
      className={`${classes.detail} ${classes[customClassName ? customClassName : ""]}`}
      onSubmit={(e) => {
        mode === modes.CREATE ? onCreate() : onUpdate();
        e.preventDefault();
      }}
    >
      <DetailHeader
        mode={mode}
        setMode={setMode}
        breadcrumbValues={breadcrumbValues}
        newTitle={newTitle}
        onCopyLinkClick={onCpyLnkClk}
        onTrashClick={() => setDeleteOpen(true)}
        subject={subject}
        newSubtitle={newSubtitle}
        schedule={schedule}
        hideEditBtn={hideEditBtn}
        hideDeleteBtn={hideDeleteBtn}
        loading={loading}
      />
      <div className={classes.detailContent}>{props.children}</div>
      {mode !== modes.VIEW ? (
        <DetailFooter
          mode={mode}
          setMode={setMode}
          footerActions={footerActions}
          editBtnTxt={editBtnTxt}
          hideCancelButton={hideCancelButton}
          onCancel={onCancel}
          isDirty={isDirty}
          isValid={isValid}
          loading={loading}
          cancelText = {cancelText}
          disableCancel = {disableCancel}
          handleSubmit={handleSubmit}
        />
      ) : null}
      <DeleteModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        title={deleteTitle}
        message={deleteMessage}
        deleteButtonText={deleteButtonText}
        onDelete={onDelete}
        deleteLoading={deleteLoading}
      />
    </form>
  );
};

export default Detail;
