import React, { useEffect, useState } from "react";
import ViewJobStatusStyle from "./ViewJobStatus.styles";
import {
  Breadcrumb,
  BreadcrumbItem,
  Heading,
  Button,
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import { Refresh } from "@livingdesign/icons";
import MuiDataTable from "../../../components/Table/MuiDataTable/MuiDataTable";
import { BULK_OPERATION_TYPE, COMMENT, COMPLETED_TIME, JOB_COMMANDS, JOB_STATUS, STATUS, SUBMITTED_TIME, TITLE } from "../../../constants/constants";
import { REFRESH } from "../../../constants/actionButtons";
import { getColorVariant, getErrorMessage } from "../../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { JOB_SERVICE } from "../../../constants/baseURLs";
import { setJobStatusDetail } from "../../../store/actions/jobStatusActions";
import { DATE_MMMM_D_YYYY_HH_AT_MM_A } from "../../../constants/dateFormats";
import { displayLocalDate } from "../../../helpers/utcUtils";
import LoadComponent from "../../../components/LoadComponent/LoadComponent";
import { getService } from "../../../axios/axios";
import MuiTagItem from "../../../components/Table/MuiDataTable/MuiTagItem/MuiTagItem";
import { useParams, useHistory } from "react-router-dom";
import { ERROR_CODES } from "../../../constants/errorCodes";
import { TableCellDisplay, renderStatus } from "../../../helpers/commonUtils";

const ViewJobStatus = () => {
  const classes = ViewJobStatusStyle();
  const COLUMNS = [
    {
      name: "serial",
      label: "Serial #",
      colKey: "serial",
      options: {
        setCellProps: () => ({ style: { maxWidth: "75px", minWidth: "75px" } }),
        setCellHeaderProps: () => ({ style: { maxWidth: "75px", minWidth: "75px" } }),
        customBodyRender: (value, tableMeta, update) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return TableCellDisplay(`table-row-serial-${tableMeta.rowIndex}`,rowIndex)
        }
      }
    },
    {
      name: "taskIdentifier",
      label: "Identifier",
      colKey: "taskIdentifier",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
        return value;
      },
      }
    },
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
        customBodyRender: renderStatus,
      }
    },
    {
      name: "apiCallResponse",
      label: "Comment",
      colKey: "apiCallResponse",
      options: {
        setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta?.rowData[2] === "Failed") {
            const apiCallResponse = JSON.parse(value)
            try {
              const errorMessage = JSON.parse(apiCallResponse?.body)
              if (errorMessage?.errorCode === "LNG_DUPLICATE_INVOICE_REQUEST") {
                return ERROR_CODES?.[errorMessage?.errorCode]
              } else {
                return errorMessage?.message
              }
            } catch(error) {
              return apiCallResponse?.body
            }
          }
          else {
            return "-"
          }
        },
      },
    },
  ];

  const [Columns, setColumns] = useState(COLUMNS);
  const {
    title,
    description,
    status,
    command,
    submittedTs,
    jobFinishTs,
    id, //TODO Should be changed later to jobCode
    jobTasks,
    jobNumber } = useSelector(state => state?.processStatus?.jobStatusDetail);
  const { jobId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { addSnack } = useSnackbar();
  const dispatch = useDispatch();
  const { color, variant } = getColorVariant(status);

  const loadComponentProps = {
    loader: loading,
    skeletonHeight: '26px',
    skeletonWidth: '100%',
    skeletonVariant: 'reactangle',
    skeletonLines: 1
  };

  useEffect(() => {
    viewJobStatus()
  }, [])

    /*
  * Function to get details of specific Job ID
  */
    const viewJobStatus = () => {
      setLoading(true);
      getService(JOB_SERVICE, `/search/jobs/v1/${jobId}`)
        .then((res) => {
          dispatch(setJobStatusDetail(res?.data))
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          addSnack({
            message: getErrorMessage(error)
          });
        });
    }

    const refreshJobStatusDetail=()=>{
      viewJobStatus()
    }

  return (
    <div className={classes.listing}>
      <Breadcrumb>
      <BreadcrumbItem onClick={() => history.push("/processStatus")} data-testid="job-status-breadcrumb">{JOB_STATUS}</BreadcrumbItem>
        <BreadcrumbItem isCurrent>{jobNumber}</BreadcrumbItem>
      </Breadcrumb>
      <div className={classes.parentHeader}>
        <Heading as="h1" size="large">
          <LoadComponent {...loadComponentProps}>
            #{jobNumber}
          </LoadComponent>
        </Heading>
        <div className={classes.refreshButton}>
          <Button
            trailing={<Refresh size="small" />}
            size="small"
            variant="primary"
            data-testid="refresh-datatable"
            id="refresh-datatable"
            onClick={refreshJobStatusDetail}
            >
            {REFRESH}
          </Button>
            </div>
      </div>
        <div className={classes.gridLayout}>
          <div className={classes.pageInfo}>
            <div>
              <text className={classes.label}>
                {TITLE}
              </text>
            </div>
            <div className={classes.pageInfoBold}>
              <Heading as="h4" size="small">
              <LoadComponent {...loadComponentProps}>
                {title}
              </LoadComponent>
              </Heading>
            </div>
          </div>
          <div className={classes.pageInfo}>
            <div className={classes.label}>
              <text>
                {STATUS}
              </text>
            </div>
            <div className={classes.pageInfoBold}>
              <Heading as="h4" size="small">
              <LoadComponent {...loadComponentProps}>
                <MuiTagItem value={status} color={color} variant={variant} />
              </LoadComponent>
              </Heading>
            </div>
          </div>
          <div className={classes.pageInfo}>
            <div className={classes.label}>
              <text>
                {SUBMITTED_TIME}
              </text>
            </div>
            <div className={classes.pageInfoBold}>
              <Heading as="h4" size="small">
              <LoadComponent {...loadComponentProps}>
                {displayLocalDate(submittedTs, DATE_MMMM_D_YYYY_HH_AT_MM_A)}
              </LoadComponent>
              </Heading>
            </div>
          </div>
          <div className={classes.pageInfo}>
            <div className={classes.label}>
              <text>
                {COMPLETED_TIME}
              </text>
            </div>
            <div className={classes.pageInfoBold}>
            <Heading as="h4" size="small">
              <LoadComponent {...loadComponentProps}>
                {displayLocalDate(jobFinishTs, DATE_MMMM_D_YYYY_HH_AT_MM_A)}
              </LoadComponent>
            </Heading>
            </div>
          </div>
          <div className={classes.pageInfo}>
            <div className={classes.label}>
              <text>
                {BULK_OPERATION_TYPE}
              </text>
            </div>
            <div className={classes.pageInfoBold}>
              <Heading as="h4" size="small">
              <LoadComponent {...loadComponentProps}>
                {JOB_COMMANDS?.[command]?.type}
              </LoadComponent>
              </Heading>
            </div>
          </div>
          <div className={classes.pageInfo}>
            <div className={classes.label}>
              <text>
                {COMMENT}
              </text>
            </div>
            <div className={classes.pageInfoComment} >
              <LoadComponent {...loadComponentProps}>
              {description}
              </LoadComponent>
            </div>
          </div>
        </div>
      <MuiDataTable
        data={jobTasks}
        loading={loading}
        columns={Columns}
        setTableColumns={setColumns}
        disableExport={true}
        disableSearch={true}
        hideAddNewButton={true}
        disableFilter={true}
      />
    </div>)
};

export default ViewJobStatus;
