import {
  Body,
  Breadcrumb,
  BreadcrumbItem,
  Grid,
  GridColumn,
  Heading,
  Skeleton,
  TabNavigation,
  TabNavigationItem,
  Tag,
  useSnackbar
} from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { getService } from "../../../axios/axios";
import { USER_REQUEST_SERVICE } from "../../../constants/baseURLs";
import { LIGHT_GRAY } from "../../../constants/colors";
import { userStatusTagColors } from "../../../constants/constants";
import { getErrorMessage, getTabList } from "../../../helpers/utils";
import { isValueEmptyCheck } from "../../../helpers/validationUtils";
import { setUserRequestData } from "../../../store/actions/adminUserRequestActions";
import { setUserRequestId } from "../../../store/actions/userActions";
import { initialState } from '../../../store/reducers/adminUserRequestReducer';
import PeoplePracticeAreaRatesList from "../PeoplePracticeAreaRates/PeoplePracticeAreaRatesList";
import UserRequestDetail from "./UserRequestDetail";

const useStyles = createUseStyles({
  listing: {
    backgroundColor: LIGHT_GRAY,
    padding: "25px 30px",
    flex: 1,
  },
  pageTitle: {
    fontSize: 36,
    fontWeight: "bold",
    display: "block",
    marginLeft: "6px",
  },
  pageSubTitle: {
    marginLeft: "7px",
    marginBottom: "8px",
    fontWeight: "bold",
    fontSize: 20,
  },
  pageInfo: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "16px",
  },
  website: {
    fontWeight: "bold",
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
  },
  tabContainer: {
    "& nav > ul > li > a": {
      background: "none",
    },
  },
  gridLayout: {
    margin: "16px 0 16px",
  },
  editBtn: {
    display: "flex",
    justifyContent: "right",
  },
  breadcrumb: {
    marginBottom: "30px",
  },
  pageInfoBold: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  pageInfoNormal: {
    fontWeight: "400",
    fontSize: "14px",
  },
  disableTabs: {
    opacity: 0.5,
    pointerEvents: "none"
  },
});

export const LoadField = ({ loader, skeletonHeight, skeletonVariant, skeletonLines = 1, node }) => {
  if (loader) {
    return <Skeleton height={skeletonHeight} variant={skeletonVariant} lines={skeletonLines} />;
  } else {
    return node;
  }
};

const ViewUserRequest = () => {
  const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.organization?.tabs?.people?.tabs?.pending?.details);
  const identifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
  const pureAdminRole = useSelector((state) => state?.user?.featureMap?.pureAdminRole);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { state: { selectedTab, selectedPeopleTab } = {} } = useLocation();
  const { peopleId } = useParams()
  const { addSnack } = useSnackbar();
  const dispatch = useDispatch();
  const { listOfTabs, tabIndexMap } = getTabList(accessibility?.tabs);

  useEffect(() => {
    getUserDetail();
  }, [])

  const getUserDetail = () => {
    setLoading(true);
  getService(USER_REQUEST_SERVICE, `/user-requests/v1/${peopleId}?organizationId=${identifier}`).then((res) => {
        dispatch(setUserRequestData(res?.data))
        dispatch(setUserRequestId(peopleId))
        setLoading(false);
      })
      .catch((error) => {
        addSnack({
          message: getErrorMessage(error)
        });
        setLoading(false);
      });
  }

  useEffect(() => {
    return () => {
      dispatch(setUserRequestData(initialState?.userRequestData))
    }
  }, [])

  return (
    <div className={classes.listing}>
      <HeaderSection loading={loading} peopleId={peopleId} accessibility={accessibility} selectedPeopleTab={selectedPeopleTab}/>
      <TabsLayout loading={loading} selectedTab={selectedTab} listOfTabs={listOfTabs} tabIndexMap={tabIndexMap} pureAdminRole={pureAdminRole}/>
    </div>
  );
};

const HeaderSection = (props) => {
  const { loading,selectedPeopleTab } = props;
  const classes = useStyles();
  const history = useHistory();

  const {
    lastName,
    organizationName,
    firstName,
    requestedRole,
    userId,
    status } = useSelector(state => state?.adminUserRequest?.userRequestData)

  /*
*Loading Skeleton
*/
  const loadFieldProps = {
    loader: loading,
    skeletonHeight: '18px',
    skeletonVariant: 'rectangle',
    skeletonLines: 1,
    node: <></>
  };

  return (
    <div>
      <div className={classes.breadcrumb}>
        <Breadcrumb>
          <BreadcrumbItem data-testid="org-breadcrumb" onClick={() => history.push("/organization")}>{organizationName}</BreadcrumbItem>
          <BreadcrumbItem data-testid="people-breadcrumb" onClick={() => history.push("/organization", { selectedTab: 1 , selectedPeopleTab: selectedPeopleTab})}>
            People
          </BreadcrumbItem>
          <BreadcrumbItem isCurrent>
          {LoadField({
              ...loadFieldProps, node: <div className={classes.pageInfoNormal}>
                {isValueEmptyCheck(`${firstName} ${lastName}`)}
              </div>
            })}
          </BreadcrumbItem>
        </Breadcrumb>
      </div>

      <Grid>
        <GridColumn sm={7}>
          <Heading as="h1" size="large" className={classes.pageTitle} id={"fullName"}>
            {LoadField({
              ...loadFieldProps, node: <div className={classes.pageTitle}>
                {isValueEmptyCheck(`${firstName} ${lastName}`)}
              </div>
            })}
          </Heading>
          <Heading as="h1" size="small" className={classes.pageSubTitle}>
          {LoadField({
              ...loadFieldProps, node: <div className={classes.pageSubTitle}>
                {isValueEmptyCheck(organizationName)}
              </div>
            })}
          </Heading>
        </GridColumn>
      </Grid>
      <div className={classes.gridLayout}>
        <Grid>
          <GridColumn sm="1" className={classes.pageInfo}>
            <div className={classes.label}>
              <Body as="p" size="small">
                Role
              </Body>
            </div>
            {LoadField({
              ...loadFieldProps, node: <div className={classes.pageInfoBold}>
                {isValueEmptyCheck(requestedRole)}
              </div>
            })}
          </GridColumn>
          <GridColumn sm={2} className={classes.pageInfo}>
            <Body as="p" size="small">
              User ID
            </Body>
            {LoadField({
              ...loadFieldProps, node: <div className={classes.pageInfoBold}>
                {isValueEmptyCheck(userId)}
              </div>
            })}
          </GridColumn>
          <GridColumn sm="2" className={classes.pageInfo}>
            <div className={classes.label}>
              <Body as="p" size="small">
                Status
              </Body>
            </div>
            {LoadField({
              ...loadFieldProps, node: <div className={classes.pageInfoBold}>
                <Tag color={userStatusTagColors[status]?.color} variant="secondary">
                  {isValueEmptyCheck(status)}
                </Tag>
              </div>
            })}
          </GridColumn>
        </Grid>
      </div>
    </div>
  );
};

const TabsLayout = (props) => {
  const { selectedTab, loading, listOfTabs, tabIndexMap, pureAdminRole } = props;
  const classes = useStyles();
  const [isCurrent, setIsCurrent] = useState(selectedTab ? selectedTab : tabIndexMap[listOfTabs[0]]);
  const isTimeKeeperRoleEnabled = useSelector(state => state?.adminUserRequest?.isTimeKeeperRoleEnabled)
  const requestedRole = useSelector(state => state?.adminUserRequest?.userRequestData?.requestedRole)

  return (
    <div className={classes.tabContainer}>
      <TabNavigation>
        {listOfTabs?.map((item, index) => {
          if(pureAdminRole) {
            if(isTimeKeeperRoleEnabled) {
              return <TabNavigationItem
                onClick={() => setIsCurrent(tabIndexMap[item])}
                isCurrent={isCurrent === tabIndexMap[item]}
                key={tabIndexMap[item]}
                data-testid={item?.split(" ").join("-")}
                id={item?.split(" ").join("-")}
              >
                {item}
              </TabNavigationItem>
            } else {
              if (isTimeKeeperRoleEnabled === false || requestedRole === "Admin") {
                return (item === 'Details' && <TabNavigationItem
                  onClick={() => setIsCurrent(tabIndexMap[item])}
                  isCurrent={isCurrent === tabIndexMap[item]}
                  key={tabIndexMap[item]}
                  data-testid={item?.split(" ").join("-")}
                  id={item?.split(" ").join("-")}
                >
                  {item}
                </TabNavigationItem>)
							} else {
                return (
                  <TabNavigationItem
                    onClick={() => setIsCurrent(tabIndexMap[item])}
                    isCurrent={isCurrent === tabIndexMap[item]}
                    key={tabIndexMap[item]}
                    data-testid={item?.split(" ").join("-")}
                    id={item?.split(" ").join("-")}
                    UNSAFE_className={loading ? classes.disableTabs : ""}
                  >
                    {item}
                  </TabNavigationItem>
                )
							}
            }
          } else {
            return <TabNavigationItem
              onClick={() => setIsCurrent(tabIndexMap[item])}
              isCurrent={isCurrent === tabIndexMap[item]}
              key={tabIndexMap[item]}
              data-testid={item?.split(" ").join("-")}
              id={item?.split(" ").join("-")}
              UNSAFE_className={loading ? classes.disableTabs: ""}
            >
              {item}
            </TabNavigationItem>
          }

        })}
      </TabNavigation>
      {TabContent(isCurrent, loading)}
    </div>
  );
};

const TabContent = (isCurrent, loading) => {
  switch (isCurrent) {
    case 0:
      return <UserRequestDetail selectedTab={isCurrent} loading={loading} />;
    case 1:
      return <PeoplePracticeAreaRatesList selectedTab={isCurrent} loading={loading}/>;
    default:
      return null;
  }
};

export default ViewUserRequest;
