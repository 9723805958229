import { Body, Button, Grid, GridColumn, Heading, useSnackbar, Spinner } from "@walmart-web/livingdesign-components";
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CONTINUE, SIGN_IN } from '../../constants/actionButtons';
import { EMAIL, INVALID_INPUT_PROVIDED, NO_CACHE_HEADER, PASSWORD_RESET_TEXT, REMEMBER_YOUR_PASSWORD, VERIFY_ITS_YOU } from '../../constants/constants';
import { WalmartSpark } from '../../constants/images';
import { loginValidationRules } from "../../Validation/validationRules";
import { useForm } from "react-hook-form";
import MuiValidationMessage from "../../Validation/MuiValidationMessage/MuiValidationMessage";
import ForgotPasswordStyles from './ForgotPassword.styles';
import { NODE_JS_URL, USER_SERVICE } from "../../constants/baseURLs";
import { getServiceWithHeaders, getService } from "../../axios/axios";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { setAuthSign, setStaticTk, setUserLoginTenantId } from "../../store/actions/userActions";
import { getIAMErrorMessage } from "../../helpers/commonUtils";
import { getInvalidInputMessage } from "../../constants/templates";

/*
* Error Page is displayed in case of any errors
* and the page is not accessible
*/
const ForgotPassword = () => {
	const dispatch = useDispatch();
	const classes = ForgotPasswordStyles();
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const { addSnack } = useSnackbar();
	const { register, formState: { errors, isValid }} = useForm({mode: "all"});

	/**
*  API call to fetch static tk
*/
	const getTK = () => {
		getService(NODE_JS_URL, "/getTk").then((res) => {
			dispatch(setStaticTk(res?.data));
		}).catch(err => {
			// Letting API fail silently
			setLoading(false);
		})
	}

	/**
   *  API call to Node js for fetching configurations
   */
	const getConfig = () => {
		getService(NODE_JS_URL, "/getConfiguration").then((res) => {
			dispatch(setUserLoginTenantId(res?.data?.configResolution?.resolved?.tenantId));
		}).catch(err => {
			// Letting API fail silently
			setLoading(false);
		})
	}


	/**
  * getA2ADetails - This method used to fetch App to App Auth Details
  */
	const getA2ADetails = () => {
		getServiceWithHeaders(NODE_JS_URL,  "/getA2ATk?"+new Date().getTime(), NO_CACHE_HEADER).then((res) => {
			dispatch(setAuthSign(res?.data));
		}).catch(err => {
			console.log("Err from node app", err)
			dispatch(setAuthSign({}));
			setLoading(false);
		})
	}

	const getPrincipalTk = () => {
		getService(USER_SERVICE,
			`/users/v1/token/${email}`
		).then((response) => {
			const token = response?.data?.payload?.principalAuthnToken?.authToken;
			const headers = { 'X-Login-Id': email, 'Authorization': `Bearer ${token}`, 'WM_SEC_AUTH_TOKEN': token}
			getServiceWithHeaders(USER_SERVICE, `/users/v1/getOtp`, headers)
				.then((response) => {
					setLoading(false);
					localStorage.setItem("forgotPrincipalId", email)
					history.push('/reset-password')
				}).catch((error) => {
					setLoading(false);
					if(error?.response?.data?.errorCode === INVALID_INPUT_PROVIDED) {
						addSnack({ message: getInvalidInputMessage({email}) })
					} else {
						addSnack({ message: getIAMErrorMessage(error,email) });
					}
				});
		}).catch((error) => {
			setLoading(false);
			addSnack({ message: getIAMErrorMessage(error,email) });
		});
	}

	const handleContinue = () => {
		setLoading(true);
		getA2ADetails();
		getConfig();
		getTK();
		setTimeout(() => {
			getPrincipalTk();
		}, 3000);
	}
	
	const handleSignIn = () => {
		history.push('/')
	}

	return (
		<div className={classes.forgotPageContainer} data-testid="forgotpage-container">
			<div className={classes.heading}>
				<WalmartSpark className={classes.spark}></WalmartSpark>
				<div className={classes.forgotPasswordText}>
					<Heading as="h1" size="medium" weight={700}>
						{VERIFY_ITS_YOU}
					</Heading>
				</div>
			</div>
			<Grid>
				<GridColumn sm={12}>
					<div className={classes.label}>
						<Body as="p" size="small">
							<strong>{EMAIL}</strong>
						</Body>
					</div>
					<TextField
						data-testid="email"
						type="email"
						name= "email"
						id= 'emailId'
						fullWidth={true}
						value={email}
						error={errors?.email}
						helperText={errors?.email && <MuiValidationMessage message={errors?.email?.message}/>}
						{...register(
							'email',
							{...loginValidationRules.email, onChange: (e) => {setEmail(e.target.value)}}
						)}
					/>
				</GridColumn>
				<GridColumn sm={12}>
					<div className={classes.passwordResetText}>
						<Body as="p" size="medium" weight={400}>
							{PASSWORD_RESET_TEXT}
						</Body>
					</div>
				</GridColumn>
				<GridColumn sm={12}>
					<div className={classes.loginButton}>
						<Button size="medium" variant="primary" isFullWidth
							onClick={handleContinue} disabled={loading || !isValid} data-testid="continue-button" id="continue-button"
							trailing={loading && <Spinner color="white" size="small" />}
						>
							{CONTINUE}
						</Button>
					</div>
				</GridColumn>
				<GridColumn sm={12}>
					<div className={classes.rememberButton}>
						<div className={classes.rememberText}>
							<Body as="p" size="medium" weight={400}>
								{REMEMBER_YOUR_PASSWORD}
							</Body>
						</div>
						<Button size="medium" variant="tertiary" onClick={handleSignIn} data-testid="login-button">
							{SIGN_IN}
						</Button>
					</div>
				</GridColumn>
			</Grid>
		</div>
	);
};

export default ForgotPassword;
