import {
  Body,
  Divider,
  Grid,
  GridColumn,
  Heading,
  useSnackbar
} from "@walmart-web/livingdesign-components";
import { ChevronDown, ChevronRight } from "@walmart-web/livingdesign-icons";
import React, {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { patchService } from "../../../axios/axios";
import Label from "../../../components/TextField/Label/Label";
import { ORGANIZATION_SERVICE } from "../../../constants/baseURLs";
import {
  ADDRESS_LINE_1, ADDRESS_LINE_2, ADDRESS_TYPE, BILLING_CONTACT_NAME, CITY, COUNTRY, EXPERTISE, FIRM_EMAIL_ADDRESS, FIRM_FAX_NO,
  FIRM_PHONE_NO, MEL_APPROVED,
  PRIMARY_PRACTICE_AREA,
  SELECT_EXPERTISE_FIRST,
  RELATIONSHIP_PARTNER_EMAIL, RELATIONSHIP_PARTNER_NAME, SELECT_ADDRESS, SELECT_EXPERTISE, SELECT_STATE, STATE, TAX_ID, WEBSITE, ZIPCODE
} from "../../../constants/constants";
import { ORGANIZATION_UPDATE_MSG } from "../../../constants/messages";
import { setInputData, setOrgDetailValues } from "../../../store/actions/organizationAction";
import Detail from "../../Detail/Detail";
import OrganizationDetailStyles from "./EditOrganization.styles";
import MuiValidationMessage from "../../../Validation/MuiValidationMessage/MuiValidationMessage";
import { organizationValidationRules } from "../../../Validation/validationRules";
import { getErrorMessage } from "../../../helpers/utils";
import { filterPayload, formatPhoneNumber } from "../../../helpers/commonUtils";
import MasterDataAutocomplete from "../../../components/Autocomplete/MasterDataAutocomplete/MasterDataAutocomplete";
import MasterDataMultiAutocomplete from "../../../components/Autocomplete/MasterDataMultiAutocomplete/MasterDataMultiAutocomplete";
import { Accordion, AccordionDetails, AccordionSummary, TextField, MenuItem, Select } from "@mui/material";
import { convertToUTCDateTime } from "../../../helpers/utcUtils";
import { DATE_YYYY_MM_DD, TIME_HH_MM_SS_A } from "../../../constants/dateFormats";
import moment from "moment";

/**
 * EditOrganization component used to update details of an existing organization.
 *
 * @author [AKASH D R]
 */
const EditOrganization = (props) => {
  const classes = OrganizationDetailStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [expandContact, setExpandContact] = useState(true);
  const [expandAuthorization, setExpandAuthorization] = useState(true);
  const [expandFinancial, setExpandFinancial] = useState(true);
  const { addSnack } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const masterData = useSelector(state => state?.masterData);
  const organizationDetailValues = useSelector((state) => state?.organization?.organizationDetailValues);
  const [data] = useState(organizationDetailValues);
  const { register, formState: { errors, isValid } } = useForm({ mode: "all"});
  const [editValues, setEditValues] = useState({});

  //destructure organizationDetailValues
	const {
    identifier,
    companyName,
    orgType,
    orgSubType,
    addressType,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    email,
    website,
    phoneNo,
    faxNo,
    comments,
    wIREFirm,
    fCPAApproved,
    mELApproved,
    hostId,
    sAPId,
    cZId,
    cZSuffix,
    expertise,
    primaryExpertise,
    permittedAdverseRepresentation,
    limitedUseFirm,
    doNotUse,
    conflictWaiver,
    vendorId,
    contactReferenceId,
    competitorNotice,
    taxId,
    billingContactName,
    relationshipPartnerName,
    relationshipPartnerEmail,
    zipCode,
    primaryPracticeArea
	} = organizationDetailValues;
  const [expertiseArray, setExpertiseArray]=useState(expertise==='' ? [] : expertise?.split(','));

  /*
  * Function would be called on Change of Form Fields
  */
  const handleInputChange = (name, value) => {
    dispatch(setInputData(name,value))
    setEditValues({...editValues,[name]:value})
  }

  /**
   * This function will use to handle the multiple expertise
  */
  const multiSelectHandler = (e, value) => {
    const selectedOptions = (typeof value === 'string' ? value?.split(',') : value);
    setExpertiseArray(selectedOptions)
    dispatch(setInputData('expertise',selectedOptions?.toString()))
    setEditValues({...editValues,'expertise':value?.toString()})
  }

  /*
  * Function would be called on Click of Cancel button
  */
  const handleCancel = () => {
    dispatch(setOrgDetailValues(data))
    history.push(`/organization`)
  };

  /*
  * Function would be called on Click of Update Button
  */
  const handleUpdate = () => {
    const body = {
      companyName,
      orgType,
      orgSubType,
      addressType,
      addressLine1,
      addressLine2,
      city,
      state,
      country,
      email: editValues?.email,
      website: editValues?.website,
      phoneNo: editValues?.phoneNo,
      faxNo: editValues?.faxNo,
      expertise: editValues?.expertise,
      primaryPracticeArea: editValues?.primaryPracticeArea,
      zipCode : editValues?.zipCode,
      comments,
      wIREFirm,
      fCPAApproved,
      mELApproved,
      hostId,
      sAPId,
      cZId,
      cZSuffix,
      primaryExpertise,
      permittedAdverseRepresentation,
      limitedUseFirm,
      doNotUse,
      conflictWaiver,
      vendorId,
      contactReferenceId,
      competitorNotice,
      taxId,
      billingContactName,
      relationshipPartnerName,
      relationshipPartnerEmail,
      updatedDate: convertToUTCDateTime({ date: moment().format(DATE_YYYY_MM_DD), time: moment().format(TIME_HH_MM_SS_A) }),
      version: "1.0"
    }
    const filteredBody=filterPayload(body)
    patchService(ORGANIZATION_SERVICE, `/organizations/v1/${identifier}`, filteredBody)
      .then((res) => {
        setLoading(false);
        addSnack({
          message: ORGANIZATION_UPDATE_MSG,
        });
        history.push(`/organization`);
      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };
  const isDirty = (companyName !== "" && (addressType !== data?.addressType || addressLine1 !== data?.addressLine1 ||
    addressLine2 !== data?.addressLine2 || city !== data?.city || state !== data?.state || country !== data?.country || email !== data?.email ||
    website !== data?.website || phoneNo !== data?.phoneNo || faxNo !== data?.faxNo || expertise !== data?.expertise || primaryPracticeArea !== data?.primaryPracticeArea ||
    taxId !== data?.taxId || billingContactName !== data?.billingContactName || relationshipPartnerName !== data?.relationshipPartnerName ||
    relationshipPartnerEmail !== data?.relationshipPartnerEmail || zipCode !== data?.zipCode));
  return (
    <Detail
      onCancel={handleCancel}
      onUpdate={handleUpdate}
      hideDeleteBtn
      isDirty={isDirty}
      isValid={isValid}
      loading={loading}
    >
      <div className={classes.pageContainer}>
        <div className={classes.editOrganization}>
          Edit Organization Profile
        </div>
        <div className={classes.leftContent}>
          <Heading as="h1" size="small">
            {"Required Fields"}
          </Heading>
          <div className={classes.orgField}>
            <Grid>
              <GridColumn sm={4}>
                <Label text={"Organization Name"} mandatoryField={true}></Label>
                <TextField
                  value={companyName}
                  name= "companyName"
                  required= {true}
                  type="text"
                  data-testid="organization-detail-companyName"
                  disabled={true}
                  fullWidth={true}
                />
              </GridColumn>
            </Grid>
            <GridColumn sm={12}>
              <div className={classes.contentSpacing24}>
                <Divider />
              </div>
            </GridColumn>
          </div>
          <Heading as="h1" size="small">
            {"Optional Fields"}
          </Heading>
          <Accordion
            className={classes.accordion}
            expanded={expandContact}
            onChange={() => {
              setExpandContact(!expandContact);
            }}
            elevation={0}
            data-testid="accordion-contact"
          >
            <AccordionSummary>
              {expandContact ? (
                <ChevronDown size="large" />
              ) : (
                <ChevronRight size="large" />
              )}
              <Body as="p" size="medium">
                <strong className={classes.accordianHeading}>{`Contact`}</strong>
              </Body>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <div className={classes.addressTypeField}>
                  <Label text={ADDRESS_TYPE} />
                  <MasterDataAutocomplete
                    value={addressType}
                    setValue={(value)=>{handleInputChange("addressType", value?.value)}}
                    dataType={"organizationAddressType"}
                    name="addressType"
                    testid="organization-detail-addressType"
                    placeholderValue={SELECT_ADDRESS}
                    disabled
                    />
                </div>
                <div
                  className={[
                    classes.addressLine1Field,
                    classes.contentSpacing16,
                  ].join(" ")}
                >
                  <Label text={ADDRESS_LINE_1} />
                  <TextField
                    onChange={
                      (e) => {
                        handleInputChange(e.target.name, e.target.value);
                      }}
                    value={addressLine1}
                    name= "addressLine1"
                    type="text"
                    data-testid="organization-detail-addressLine1"
                    disabled
                    fullWidth={true}
                  />
                </div>
                <div
                  className={[
                    classes.addressLine1Field,
                    classes.contentSpacing16,
                  ].join(" ")}
                >
                  <Label text={ADDRESS_LINE_2} />
                  <TextField
                    onChange={
                      (e) => {
                        handleInputChange(e.target.name, e.target.value);
                      }}
                    value={addressLine2}
                    name= "addressLine2"
                    type="text"
                    data-testid="organization-detail-addressLine2"
                    disabled
                    fullWidth={true}
                  />
                </div>
                <div
                  className={[
                    classes.addressLine1Field,
                    classes.contentSpacing16,
                  ].join(" ")}
                >
                  <Label text={ZIPCODE} />
                  <TextField
                    error={errors?.zipCode}
                    helperText={errors?.zipCode && <MuiValidationMessage message={errors?.zipCode?.message}/>}
                    onChange={
                      (e) => {
                        handleInputChange(e.target.name, e.target.value);
                      }}
                    {...register(
                      'zipCode',
                      { ...organizationValidationRules?.zipCode, onChange: (e) => { handleInputChange(e.target.name, e.target.value) } }
                    )}
                    value={zipCode}
                    name= "zipCode"
                    data-testid="organization-detail-zipcode"
                    disabled
                    fullWidth={true}
                  />
                </div>
                <div className={[
                  classes.parent,
                  classes.contentSpacing16,
                ].join(" ")}>
                  <div className={classes.city}>
                    <Label text={CITY} />
                    <TextField
                      onChange={
                        (e) => {
                          handleInputChange(e.target.name, e.target.value);
                        }}
                      value={city}
                      name= "city"
                      type="text"
                      data-testid="organization-detail-city"
                      disabled
                      fullWidth={true}
                    />
                  </div>
                  <div className={classes.stateWrapper}>
                    <Label text={STATE} />
                    <div className={classes.state}>
                    <MasterDataAutocomplete
                    value={state}
                    setValue={(value)=>{handleInputChange("state", value?.value)}}
                    dataType={"state"}
                    name={"state"}
                    testid="organization-detail-state"
                    placeholderValue={SELECT_STATE}
                    disabled
                    />
                  </div>
                  </div>
                  <div className={classes.countryWrapper}>
                    <Label text={COUNTRY} />
                    <div className={classes.country}>
                    <Select
                      onChange={
                        (e) => {
                          handleInputChange(e.target.name, e.target.value)
                        }}
                      value={country}
                      name= "country"
                      data-testid="organization-detail-country"
                      disabled
                      fullWidth={true}
                    >
                      <MenuItem value="United States" data-testid="organization-detail-country-option">
                        United States
                      </MenuItem>
                      {/* {masterDatas?.country?.masterData?.map((item, index) => {
                        return (
                          <MenuItem
                            value={item?.value}
                            key={index}
                            data-testid="organization-detail-country-option"
                          >
                            {item?.value}
                          </MenuItem>
                        );
                      })} this might need in future*/ }
                    </Select>
                  </div>
                </div>
                </div>
                <div className={[classes.firmEmailAddressField, classes.contentSpacing16].join(" ")}>
                  <Label text={FIRM_EMAIL_ADDRESS} />
                  <TextField
                    value={email}
                    error={errors?.email}
                    helperText={errors?.email && <MuiValidationMessage message={errors?.email?.message}/>}
                    name= "email"
                    type="text"
                    data-testid="organization-detail-email"
                    {...register(
                      'email',
                      {...organizationValidationRules.email, onChange: (e) => {handleInputChange(e.target.name, e.target.value)}}
                    )}
                    fullWidth={true}
                  />
                </div>
                <div className={[classes.firmEmailAddressField, classes.contentSpacing16].join(" ")}>
                  <Label text={WEBSITE} />
                  <TextField
                    onChange={
                      (e) => {
                        handleInputChange(e.target.name, e.target.value);
                      }}
                    value={website}
                    name= "website"
                    id= "website"
                    type="text"
                    data-testid="organization-detail-website"
                    fullWidth={true}
                  />
                </div>
                <div className={[classes.width30per, classes.contentSpacing16].join(" ")}>
                  <Label text={FIRM_PHONE_NO} />
                  <TextField
                    error={errors?.phoneNo}
                    helperText={errors?.phoneNo && <MuiValidationMessage message={errors?.phoneNo?.message}/>}
                    value={formatPhoneNumber(phoneNo)}
                    name= "phoneNo"
                    id= "phoneNo"
                    type="text"
                    data-testid="organization-detail-phoneNo"
                    {...register(
                      'phoneNo',
                      { ...organizationValidationRules.phoneNo, onChange: (e) => { handleInputChange(e.target.name, formatPhoneNumber(e.target.value)) } }
                    )}
                    fullWidth={true}
                  />
                </div>
                <div className={[classes.width30per, classes.contentSpacing16].join(" ")}>
                  <Label text={FIRM_FAX_NO} />
                  <TextField
                    error={errors?.faxNo}
                    helperText={errors?.faxNo && <MuiValidationMessage message={errors?.faxNo?.message}/>}
                    value={faxNo}
                    name= "faxNo"
                    type="text"
                    data-testid="organization-detail-faxNo"
                    {...register(
                      'faxNo',
                      { ...organizationValidationRules.faxNo, onChange: (e) => { handleInputChange(e.target.name, e.target.value) } }
                    )}
                    fullWidth={true}
                  />
                </div>
                <div className={classes.contentPadding24}>
                  <Divider />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordion}
            expanded={expandAuthorization}
            onChange={() => {
              setExpandAuthorization(!expandAuthorization);
            }}
            elevation={0}
            data-testid="accordion-authorization"
          >
            <AccordionSummary>
              {expandAuthorization ? (
                <ChevronDown size="large" />
              ) : (
                <ChevronRight size="large" />
              )}
              <Body as="p" size="medium">
                <strong className={classes.accordianHeading}>{`Authorization`}</strong>
              </Body>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <div className={classes.width35per}>
                  <Label text={MEL_APPROVED} />
                  <Select
                    disabled={true}
                    value={mELApproved}
                    selectProps={{
                    }}
                    name= "mELApproved"
                    data-testid="organization-detail-mELApproved"
                    fullWidth={true}
                  >
                    <MenuItem value="">{mELApproved}</MenuItem>
                  </Select>
                </div>
                <div className={[classes.firmEmailAddressField, classes.contentSpacing16].join(" ")}>
                  <Label text={EXPERTISE} />
                  {
                    loading ? "-" :
                    <MasterDataMultiAutocomplete
                    value={expertiseArray}
                    dataType={"expertise"}
                    placeholderValue={SELECT_EXPERTISE}
                    setValue={multiSelectHandler}
                    testid="organization-detail-expertise"
                    />
                  }
                </div>
                <div className={[classes.width35per, classes.contentSpacing16].join(" ")}>
                  <Label text={PRIMARY_PRACTICE_AREA} />
                  <Select
                    onChange={
                      (e) => {
                        handleInputChange(e.target.name, e.target.value)
                      }}
                    value={primaryPracticeArea}
                    name= "primaryPracticeArea"
                    data-testid="organization-detail-primaryPracticeArea"
                    fullWidth={true}
                  >
                    {
                      (expertise !== '') ? expertise?.split(',')?.map((item)=>{
                        return <MenuItem key={item} value={item} id={`primaryExpertise-option-${item}`}>{item}</MenuItem>
                      }): <MenuItem value="">{SELECT_EXPERTISE_FIRST}</MenuItem>
                    }
                  </Select>
                </div>
                <div className={classes.contentPadding24}>
                  <Divider />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordion}
            expanded={expandFinancial}
            onChange={() => {
              setExpandFinancial(!expandFinancial);
            }}
            elevation={0}
            data-testid="accordion-financial"
          >
            <AccordionSummary>
              {expandFinancial ? (
                <ChevronDown size="large" />
              ) : (
                <ChevronRight size="large" />
              )}
              <Body as="p" size="medium" >
                <strong className={classes.accordianHeading}>{`Financial`}</strong>
              </Body>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <div className={classes.width30per}>
                  <Label text={TAX_ID} />
                  <TextField
                    disabled={true}
                    onChange={
                      (e) => {
                        handleInputChange(e.target.name, e.target.value);
                      }}
                    value={taxId}
                    name= "taxId"
                    type="text"
                    data-testid="organization-detail-taxId"
                    fullWidth={true}
                  />
                </div>
                <div className={[classes.addressTypeField, classes.contentSpacing16].join(" ")}>
                  <Label text={BILLING_CONTACT_NAME} />
                  <TextField
                    disabled={true}
                    onChange={
                      (e) => {
                        handleInputChange(e.target.name, e.target.value);
                      }}
                    value={billingContactName}
                    name= "billingContactName"
                    type="text"
                    data-testid="organization-detail-billingContactName"
                    fullWidth={true}
                  />
                </div>
                <div className={[
                  classes.parent1,
                  classes.contentSpacing16,
                ].join(" ")}>
                  <div className={classes.width40per}>
                    <Body as="p" size="small">
                      <strong>{RELATIONSHIP_PARTNER_NAME + ":"}</strong>
                      {"(approval required)"}
                    </Body>
                    <TextField
                      size={"large"}
                      disabled={true}
                      onChange={
                        (e) => {
                          handleInputChange(e.target.name, e.target.value);
                        }}
                      value={relationshipPartnerName}
                      name= "relationshipPartnerName"
                      type="text"
                      data-testid="organization-detail-relationshipPartnerName"
                      fullWidth={true}
                    />
                  </div>
                  <div className={[classes.width40per, classes.paddingLeft].join(" ")}>
                    <Body as="p" size="small">
                      <strong>{RELATIONSHIP_PARTNER_EMAIL + ":"}</strong>
                      {"(approval required)"}
                    </Body>
                    <TextField
                      disabled={true}
                      onChange={
                        (e) => {
                          handleInputChange(e.target.name, e.target.value);
                        }}
                      value={relationshipPartnerEmail}
                      name= "relationshipPartnerEmail"
                      type="text"
                      data-testid="organization-detail-relationshipPartnerEmail"
                      fullWidth={true}
                    />
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

    </Detail>
  );
};

export default EditOrganization;
