export const DATE_FORMAT = "MM/dd/yyyy";
export const BACKEND_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const MOMENT_DATE_FORMAT = "MM/DD/YYYY";
export const DATE_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_DD_MMM_YYYY = "DD MMMM, yyyy"; // 01 January, 2023
export const ISO_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS";
export const INVALID_DATE = "Invalid date";
export const NULL_DATE = '00/00/0000';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const TIME_HH_MM_SS = "HH:mm:ss";
export const TIME_HH_MM_SS_A = "HH:mm:ss A";
export const DATE_MM_DD_YYYY_H_MMA = "MM/DD/YYYY h:mmA";
export const DATE_MMMM_D_YYYY_HH_AT_MM_A="MM/DD/YYYY [at] hh:mm a";
export const DATE_END_TIME = "23:59:59 PM";
export const DATE_START_TIME = "00:00:00 AM";

