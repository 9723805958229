import types from '../types/budgetActionTypes';

export const initialState = {
  budgetData: {
    budgets: [],
    count: 0
  },
  budgetDetail: {
    id: '',
    taskNumber: '',
    title: '',
    description: '',
    matter: null,
    dueDate: null,
    reminder: false,
    primaryAssignee: null,
    isNotifyChange: true,
    isPrivate: true,
    status: '',
    priority: '',
    tags: [],
    creatorUserId: '',
    creatorFirstName: '',
    creatorLastName: '',
    comments: []
  }
};

const budgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BUDGET_DATA:
      return {
        ...state,
        budgetData: action.payload,
      };
    case types.SET_INPUT_VALUE:
      const { name, value } = action.payload;
      return {
        ...state,
        budgetDetail: {
          ...state.budgetDetail,
          [name]: value,
        },
      };
    case types.SET_BUDGET_DETAIL:
      return {
        ...state,
        budgetDetail: {
          ...state.budgetDetail,
          ...action.payload,
        },
      };
    case types.RESET_BUDGET_DETAIL:
      return {
        ...state,
        budgetDetail: initialState.budgetDetail,
      };
    default:
      return state;
  }
};

export default budgetReducer;
