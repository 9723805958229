import { createUseStyles } from "react-jss";

const TimelineStyles = createUseStyles({
	timelineList: {
		display: "flex",
		flexDirection: "column",
		flexGrow: "1",
		padding: '0px',
		marginTop: "50px"
	},
	timelineItem: {
		listStyle: "none",
		display: "flex",
		position: "relative",
		"&:last-child .timelineSeparatorLine": {
			display: 'none'
		},
		"&:last-child .timelineItemContent": {
			paddingBottom: '0px'
		},
	},
	timelineItemContent: {
		minHeight: '100px',
		width: '100%',
		marginTop: "-40px",
		paddingBottom: "40px"
	},
	timelineItemSeparator: {
		flexDirection: 'column',
		alignItems: "center",
		display: 'flex'
	},
	timelineSeparatorDot: {
		display: "flex",
		alignSelf: "baseline",
		borderStyle: 'solid',
		borderWidth: '2px',
		padding: '4px',
		borderRadius: '50%',
		margin: '0 10px',
		backgroundColor: '#012d58',
	},
	timelineSeparatorLine: {
		width: '1px',
		backgroundColor: '#ddd',
		flexGrow: '1'
	}
});

export default TimelineStyles;
