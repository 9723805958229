import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { createUseStyles } from "react-jss";
import {
  ButtonGroup,
  Button,
  Modal,
  Grid,
  GridColumn,
  TextArea,
  useSnackbar
} from "@walmart-web/livingdesign-components";
import {
  patchService,
} from "../../../../axios/axios";
import { changeInvoiceStatusItems, INVOICE_APPROVE_API, INVOICE_REJECT_API, INVOICE_HOLD_APPEALS_API,
  INVOICE_REJECT_REASON, INVOICE_REJECT_COMMENT, INVOICE_REJECT_REASON_OPTION } from "../../../../constants/constants";
import {
  INVOICE_STATUS_CHANGE_SUCCESS,
  INVOICE_APPEALS_MSG,
  INVOICE_HOLD_MSG,
  INVOICE_REJECT_MSG,
  INVOICE_APPROVE_MSG
} from "../../../../constants/messages";
import { INVOICE_SERVICE } from "../../../../constants/baseURLs";
import { getErrorMessage } from "../../../../helpers/utils";
import MasterDataAutocomplete from "../../../../components/Autocomplete/MasterDataAutocomplete/MasterDataAutocomplete";
import { filterPayload } from "../../../../helpers/commonUtils";
import { CANCEL, REJECT } from "../../../../constants/actionButtons";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  fullWidth: {
    width: "100%"
  },
  uploadModalText: {
    fontSize: "14px",
    marginBottom: "20px",
  },
  textStyle: {
    color: "#2e2f32",
    fontFamily: "Bogle, \"Helvetica Neue\", Helvetica, Arial, sans-serif",
    fontSize: "12px"
  },
  subHeading: {
    color: "#2e2f32",
    fontFamily: "Bogle, \"Helvetica Neue\", Helvetica, Arial, sans-serif",
    fontSize: "14px",
    marginTop: "-10px"
  },
  modalButtonGroup: {
    marginTop: "20px",
    justifyContent: "flex-end"
  },
  rejectFieldLabel: {
    fontWeight: "bold",
    marginTop: "24px"
  }

});

/**
 * Modal component for Invoice status change
 **/
const InvoiceChangeStatusModal = (props) => {
  const {
    open,
    actionId,
    invoiceId,
    handleStatusModalState,
    handleStatusCodeChange
  } = props;
  const classes = useStyles();
  const [rejectComment, setRejectComment] = useState("");
  const [reason, setReason] = useState("");
  const { addSnack } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [actionButtonConfig, setActionButtonConfig] = useState({
    variant: '',
    buttonTxt: '',
    actionButtonDisabled: false
  });
  const [modalTitle, setModalTitle] = useState("Invoice");
  const ref= useRef();


  useEffect(() => {
    handleActionButtonConfig()
  }, [actionId])

  useEffect(() => {
    if(actionId === 1) {
      if(reason === '') {
        setActionButtonConfig({
          variant: 'destructive',
          buttonTxt: REJECT,
          actionButtonDisabled: true
        })
      } else {
        setActionButtonConfig({
          variant: 'destructive',
          buttonTxt: REJECT,
          actionButtonDisabled: false
        })
      }
    }
  }, [reason])

  useEffect(()=>{
    if (rejectComment) {
      ref.current.focus();
    }
  },[rejectComment])

 const handleRejectChange = (e) => {
   setRejectComment(e.target.value);
 };
 const handleRejectFocus = (e) => {
   const val = e.target.value;
   e.target.value = "";
   e.target.value = val;
 };

  const ModalContent = () => {
    let result;
    switch (actionId) {
      case 0:
        result= (<>{INVOICE_APPROVE_MSG}</>);
        break;
      case 1:
        result= (
          <>
            {INVOICE_REJECT_MSG}
            <div>
              <div className={classes.rejectFieldLabel}>{INVOICE_REJECT_REASON}</div>
              <MasterDataAutocomplete
                  value={reason}
                  setValue={(value)=>{setReason(value?.value)}}
                  data={"invoiceRejectReasonCodes"}
                  testid="invoice-rejection-reason"
                  placeholderValue={INVOICE_REJECT_REASON_OPTION}
              />
              <div className={classes.rejectFieldLabel}>
                {INVOICE_REJECT_COMMENT}
              </div>
              <TextArea
                ref={ref}
                value={rejectComment}
                onChange={(e) => {
                  handleRejectChange(e);
                }}
                label={"Rejection Comment"}
                type="text"
                data-testid="invoice-reject-comment"
                onFocus={(e) => {
                  handleRejectFocus(e);
                }}
              ></TextArea>
            </div>
          </>
        );
        break;
      case 2:
        result= (<>{INVOICE_HOLD_MSG}</>);
        break;
      case 3:
        result= (<>{INVOICE_APPEALS_MSG}</>);
    }
    return result;
  }

  /**
   * Modal action button config
   **/
  const handleActionButtonConfig = () => {
    switch (actionId) {
      case 0:
        setModalTitle('Confirm Invoice Approval');
        setActionButtonConfig({
          variant: 'primary',
          buttonTxt: 'Approve',
          actionButtonDisabled: false
        })
        break;
      case 1:
        setModalTitle('Reject Invoice');
        setActionButtonConfig({
          variant: 'destructive',
          buttonTxt: REJECT,
          actionButtonDisabled: true
        })
        break;
      case 2:
        setModalTitle('Confirm Invoice Hold');
        setActionButtonConfig({
          variant: 'primary',
          buttonTxt: 'Confirm',
          actionButtonDisabled: false
        })
        break;
      case 3:
        setModalTitle('Confirm Invoice Appeals');
        setActionButtonConfig({
          variant: 'primary',
          buttonTxt: 'Confirm',
          actionButtonDisabled: false
        })
        break;
    }
  };

  /**
   * Function to trigger update invoice status api.
   **/
  const updateInvoiceStatus = (actionId) => {
    const body = [];
    const statusValue = changeInvoiceStatusItems?.find(
      (x) => x?.actionId === actionId
    )?.key;
    let url;
    if (actionId > 1) {
      url = INVOICE_HOLD_APPEALS_API;
      body?.push({
        id: invoiceId,
        status: statusValue,
      });
    } else if (actionId === 0) {
      url = INVOICE_APPROVE_API;
      body?.push({
        id: invoiceId,
        comment: "Approve",
      });
    } else if (actionId === 1) {
      url = INVOICE_REJECT_API;
      body?.push({
        id: invoiceId,
        comment: rejectComment,
        reason: reason,
      });
    }

    setLoading(true);
    const filteredBody=filterPayload(body)
    patchService(INVOICE_SERVICE, url, filteredBody)
      .then(() => {
        addSnack({
          message: INVOICE_STATUS_CHANGE_SUCCESS,
        });
        handleStatusModalState(false);
        setReason("");
        setLoading(false);
        handleStatusCodeChange(statusValue?.toLowerCase());
      })
      .catch((error) => {
        handleStatusModalState(false);
        setReason("");
        addSnack({
          message: getErrorMessage(error)
        });
        setLoading(false);
      });
  };

  /**
   * Function to handle modal close action
   */
  const handleModalDismiss = () => {
    setReason('');
    handleStatusModalState(false);
  }

  return (
    <Modal
      onClose={() => handleModalDismiss()}
      isOpen={open}
      size="medium"
      title={modalTitle}
      data-testid="invoice-change-status-modal"
    >
      <ModalContent />
      <Grid>
        <GridColumn sm={12}>
          <ButtonGroup className={classes.modalButtonGroup}>
            <Button
              data-testid="cancel-invoice-status-change-btn"
              size="small"
              onClick={() => handleModalDismiss()}
              disabled={loading}
            >
              {CANCEL}
            </Button>
            <Button
              variant={actionButtonConfig?.variant}
              size="small"
              data-testid="invoice-status-change-btn"
              onClick={() => updateInvoiceStatus(actionId)}
              disabled={actionButtonConfig?.actionButtonDisabled || loading}
            >
              {actionButtonConfig?.buttonTxt}
            </Button>
          </ButtonGroup>
        </GridColumn>
      </Grid>

    </Modal>
  );
};

export default InvoiceChangeStatusModal;

InvoiceChangeStatusModal.propTypes = {
  /**
   * pass the open/close state of modal pop up
   **/
  open: PropTypes.bool,
  /**
   * handles the reset of modal open/close state
   **/
  handleStatusModalState: PropTypes.func,
  /**
   * callback to update new status
   **/
  handleStatusCodeChange: PropTypes.func,
   /**
   * pass the action type id
   **/
  actionId: PropTypes.string,
  /**
   * pass the id of selected invoice
   **/
  invoiceId: PropTypes.string,
}
