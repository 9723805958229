import { createUseStyles } from "react-jss";

const HelpCenterBannerStyles = createUseStyles({
    banner: {
        height: '150px',
        width: '100%',
        background: '#002d58',
        borderRadius: '10px',
        position: 'relative'
      },
    bannerLeft: {
        position: 'absolute',
        left: '0px',
        bottom: 0,
        height: '52px',
        '& svg': {
            borderBottomLeftRadius: '8px'
        }
    },
    bannerRight: {
        position: 'absolute',
        right: '0px',
        bottom: 0,
        height: '138px',
        '& svg': {
            borderBottomRightRadius: '8px'
        }
    },
    bannerText: {
        padding: '40px 35px',
      },
    heading: {
        color: 'white',
        display: 'block',
        fontSize: '32px',
        fontWeight: 800
    },
    spark: {
        position: 'absolute',
        right: '35px',
        top: '30px'
    },
})

export default HelpCenterBannerStyles;

