import React from "react";
import TimelineStyles from './TimelineStyles';

/**
 * Timeline Component
 *
 * @visibleName Timeline
 */
const Timeline = (props) => {
	const classes = TimelineStyles();

	return (
		<ul className={classes.timelineList}>
			{props?.children}
		</ul>
	)
}

export default Timeline;

