import types from '../types/masterDataActionTypes';

export const setMatterDropDownData = (payload) => {
    return { type: types.SET_MATTER_DROP_DOWN_DATA, payload }
}

export const setMatterFilterDropDownData = (payload) => {
    return { type: types.SET_MATTER_FILTER_DROP_DOWN_DATA, payload }
}

export const setMasterData = (payload) => {
    return { type: types.SET_MASTER_DATA, payload }
}

export const setPeopleDropDownData = (payload) => {
    return { type: types.SET_PEOPLE_DROP_DOWN_DATA, payload }
}

export const setPeopleDropDownDataWithOptions = (payload) => {
    return { type: types.SET_PEOPLE_DROP_DOWN_DATA_WITH_OPTIONS, payload }
}

export const setExternalPeopleDropDownData = (payload) => {
    return { type: types.SET_EXTERNAL_PEOPLE_DROP_DOWN_DATA, payload }
}

export const setOrganizationDropDownData = (payload) => {
    return { type: types.SET_ORGANIZATION_DROP_DOWN_DATA, payload }
}
