import { BORDERS, SPACING } from "../../../constants/styleGuide";
import { createUseStyles } from "react-jss";

const DisplayValueStyles = createUseStyles({
	padRight: {
		paddingRight: SPACING.s10,
	},
  dataTableWrap: {
    paddingTop: SPACING.s16,
    margin: "0",
    "& table > thead > tr > th.MuiTableCell-head": {
      color: "#000000",
      fontWeight: "bold",
      borderLeft: BORDERS.WHITE,
      "&:last-child":{
        borderRight: BORDERS.WHITE
      }
    },
    "& table > tbody > tr > td.MuiTableCell-root": {
      color: "#000000",
      borderLeft: BORDERS.WHITE,
      "&:last-child":{
        borderRight: BORDERS.WHITE
      }
    },
    "& table > tbody > tr.MuiTableRow-root.MuiTableRow-hover:hover": {
      cursor: "default",
      background: "#fff"
    },
    "& table > thead > tr > th.MuiTableCell-head button": {
      color: "#000000",
      fontWeight: "bold",
    },
    "& > div > div > div.MuiToolbar-root":{
      display: 'none'
    }
  },
  tableBody: {
      width:"100%",
      border: BORDERS.WHITE,
      borderCollapse: 'collapse',
  },
  tableHeader: {
    border: BORDERS.WHITE,
    borderCollapse: 'collapse',
    fontSize: "12px",
    fontFamily: "Bogle",
    textAlign: "left",
    padding: "12px 16px",
  },
  tableCell: {
    border: BORDERS.WHITE,
    fontWeight: "400",
    fontSize: "12px",
    margin: "12px 0px",
    fontFamily: "Bogle",
    borderCollapse: 'collapse',
    padding: "8px 16px",
  },
  tableEmptyData: {
    border: BORDERS.WHITE,
    fontSize: "16px",
    margin: "12px 0px",
    fontFamily: "Bogle",
    borderCollapse: 'collapse',
    padding: "32px 24px 40px",
    textAlign: "center",
    fontWeight: "bold",
  },
  wordBreak: {
    overflowWrap: 'break-word',
    margin: '0'
  }
});

export default DisplayValueStyles;
