import { createUseStyles } from "react-jss";
import { Route, Switch, useLocation } from "react-router-dom";
import { SIDE_NAVIGATION_WIDTH } from "../constants/constants";
import { SnackbarProvider } from "@walmart-web/livingdesign-components";
import React, { useEffect } from "react";
import Navigation from '../components/common/Navigation/Navigation';
import MatterList from "../containers/Matter/Listing/MatterList";
import MatterDetail from "../containers/Matter/MatterDetail";
import CreateViewNote from "../containers/Note/CreateViewNote";
import DocumentUpload from "../containers/Document/DocumentUpload";
import DocumentPreview from "../containers/Document/DocumentPreview";
import EditOrganization from "../containers/Organization/EditOrganization/EditOrganization";
import ViewOrganization from "../containers/Organization/ViewOrganization";
import OrgAssignTimekeepers from "../containers/Organization/OrgAssignTimekeepers/OrgAssignTimekeepers";
import AssignTimekeepersSelectMatters from "../containers/Organization/OrgAssignTimekeepers/AssignTimekeepersSelectMatters/AssignTimekeepersSelectMatters";
import OrgRemoveTimekeepers from "../containers/Organization/OrgRemoveTimekeepers/OrgRemoveTimekeepers";
import TimekeeperAdminApi from "../containers/Organization/People/TimekeeperAdminApi";
import ViewPeople from "../containers/Organization/People/ViewPeople";
import EditTimeKeeperProfile from "../containers/Organization/EditTimeKeeperProfile/EditTimeKeeperProfile";
import Financial from "../containers/Financial/Financial";
import CreateEditInvoice from "../containers/Financial/Invoice/InvoiceView/CreateEditInvoice";
import UploadInvoice from "../containers/Financial/Invoice/UploadInvoice/UploadInvoice";
import ViewBudget from "../containers/Financial/BudgetView/ViewBudget";
import ViewEditInvoice from "../containers/Financial/InvoiceDetails/ViewEditInvoice";
import ViewEditInvoiceLineItem from "../containers/Financial/Invoice/InvoiceLineItemDetail/ViewEditInvoiceLineItem";
import CreateEditBudget from "../containers/Financial/Budget/BudgetDetail/CreateEditBudget";
import { ProtectedRoutes } from './ProtectedRoutes';
import {
    BUDGET_CREATE,
    BUDGET_LIST,
    BUDGET_READ,
    CREATE_TK_MATTER_RATE_REQ,
    CREATE_TK_PRACTICE_RATE_REQ,
    CREATE_TK_REQ,
    DOCUMENT_MGMT,
    INVOICE_CREATE,
    INVOICE_LIST,
    INVOICE_READ,
    INVOICE_UPDATE_NET_AMOUNT,
    MATTER_LIST,
    MATTER_READ,
    MATTER_VIEW_ALL,
    NOTES_CREATE,
    NOTES_READ,
    ORGANIZATION_READ,
    ORG_PPL_READ,
    PEOPLE_LIST
} from "../constants/ResponsibilityCodes";
import RemoveTimekeepersSelectMatters from "../containers/Organization/OrgRemoveTimekeepers/RemoveTimekeepersSelectMatters/RemoveTimekeepersSelectMatters";
import ThemisTopNav from "../components/common/ThemisTopNav/ThemisTopNav";
import CreateMSRPAR from "../containers/Organization/PeopleMatterSpecificRates/CreateMSRPAR/CreateMSRPAR";
import AccessDenied from "../components/AccessDenied/AccessDenied";
import ErrorPage from "../components/ErrorPage/ErrorPage";
import ViewJobStatus from "../containers/JobStatus/ViewJobStatus/ViewJobStatus";
import JobStatusList from "../containers/JobStatus/JobStatusList";
import HelpCenter from "../containers/HelpCenter/HelpCenter";
import ViewUserRequest from "../containers/Organization/UserRequests/ViewUserRequest";

const useStyles = createUseStyles({
    contentWrap: {
        marginLeft: SIDE_NAVIGATION_WIDTH,
        width: `calc(100% - ${SIDE_NAVIGATION_WIDTH}px)`,
        height: '100vh',
        paddingTop: '64px',
        backgroundColor: '#F5F5F5'
    },
		actionsWrap: {
			width: "100%"
		},
    content: {
        overflow: 'auto',
        marginLeft: '5px',
        backgroundColor: '#F5F5F5'
    }
});

const calcHeight = () => {
    document.contentDivHeight = 140;
    const container_div = document.getElementById("content-container");
    const count = container_div ? container_div?.children?.length : 0;
    let height = 0;
    for (let i = 0; i < count; i++) {
        const child = container_div?.children[i];
        if (child.id !== "tab-content") {
            height = height + child.offsetHeight;
        }
    }
    document.contentDivHeight = document.contentDivHeight + height;
};


export const RouterOutlet = () => {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        calcHeight();
    },[location])

    return (
        <React.Fragment>
            <ThemisTopNav />
            <div className={classes.body}>
                <Navigation />
                <div className={classes.contentWrap} id="router-content">
                    <SnackbarProvider>
                        <div className={classes.content}>
                            <Switch>
                                {/* Matter */}
                                <ProtectedRoutes path="/" exact component={MatterList} allowedActions={[MATTER_LIST, MATTER_VIEW_ALL]} />
                                <ProtectedRoutes path="/matter/:matterId" exact component={MatterDetail} allowedActions={[MATTER_READ]} />
                                {/* Notes */}
                                <ProtectedRoutes path="/note/:noteId" component={CreateViewNote} allowedActions={[NOTES_CREATE, NOTES_READ]} />
                                <ProtectedRoutes path="/matter/:matterId/note/:noteId" component={CreateViewNote} allowedActions={[NOTES_CREATE, NOTES_READ]} />
                                {/* Document */}
                                <ProtectedRoutes exact path="/document/adddocument" component={DocumentUpload}allowedActions={[DOCUMENT_MGMT]} />
                                <ProtectedRoutes exact path="/document/:documentId" component={DocumentPreview} allowedActions={[DOCUMENT_MGMT]} />
                                {/* Organization */}
                                <ProtectedRoutes path="/organization/:organizationId" exact component={EditOrganization} allowedActions={[ORGANIZATION_READ]} />
                                <ProtectedRoutes path="/organization" exact component={ViewOrganization} allowedActions={[ORGANIZATION_READ]} />
                                <ProtectedRoutes path="/organization/timekeeper/:peopleId/edit" exact component={EditTimeKeeperProfile} allowedActions={[ORG_PPL_READ]} />
                                <ProtectedRoutes path="/manageTimekeepers/assignTimekeeper" exact component={OrgAssignTimekeepers} allowedActions={[PEOPLE_LIST]} />
                                <ProtectedRoutes path="/manageTimekeepers/assignTimekeeper/selectMatters" exact component={AssignTimekeepersSelectMatters} allowedActions={[MATTER_LIST]} />
                                <ProtectedRoutes
                                    path="/manageTimekeepers/removeTimekeeper/selectMatters/selectTimekeeper"
                                    exact component={OrgRemoveTimekeepers}
                                    allowedActions={[PEOPLE_LIST]}
                                />
                                <ProtectedRoutes path="/manageTimekeepers/removeTimekeeper/selectMatters" exact component={RemoveTimekeepersSelectMatters} allowedActions={[MATTER_LIST]} />
                                {/* People */}
                                <ProtectedRoutes path="/people/timekeeper" exact component={TimekeeperAdminApi} allowedActions={[CREATE_TK_REQ]} />
                                <ProtectedRoutes path="/people/admin" exact component={TimekeeperAdminApi} allowedActions={[CREATE_TK_REQ]} />
                                <ProtectedRoutes path="/people/:peopleId/view" exact component={ViewPeople} allowedActions={[ORG_PPL_READ]} />
                                <ProtectedRoutes
                                    path="/people/:userRequestId/add-matter-specific-rate"
                                    exact
                                    component={CreateMSRPAR}
                                    allowedActions={[CREATE_TK_MATTER_RATE_REQ]}
                                />
                                <ProtectedRoutes
                                    path="/people/:userRequestId/add-practice-area-rate"
                                    exact
                                    component={CreateMSRPAR}
                                    allowedActions={[CREATE_TK_PRACTICE_RATE_REQ]}
                                />
                                <ProtectedRoutes path="/people/:requestType/:peopleId/view" exact component={ViewUserRequest} allowedActions={[CREATE_TK_REQ]}/>
                                {/* Financial */}
                                <ProtectedRoutes path="/financial" exact component={Financial} allowedActions={[BUDGET_LIST, INVOICE_LIST, BUDGET_READ, INVOICE_READ]} />
                                {/* Budget */}
                                <ProtectedRoutes path="/budget/:budgetId" exact component={ViewBudget} allowedActions={[BUDGET_CREATE]} />
                                <ProtectedRoutes path="/budget/:budgetId/view" exact component={CreateEditBudget} allowedActions={[BUDGET_READ, BUDGET_LIST]} />
                                {/* Invoice */}
                                <ProtectedRoutes path="/invoice/:invoiceId/view" exact component={CreateEditInvoice} allowedActions={[INVOICE_CREATE]} />
                                <ProtectedRoutes path="/invoice/:invoiceId" exact component={ViewEditInvoice} allowedActions={[INVOICE_READ, INVOICE_LIST]} />
                                <ProtectedRoutes path="/invoice/:invoiceId/lineItem/:lineItemId" exact component={ViewEditInvoiceLineItem} allowedActions={[INVOICE_UPDATE_NET_AMOUNT]} />
                                {/* Upload Invoices */}
                                <ProtectedRoutes path="/invoiceUpload" exact component={UploadInvoice} allowedActions={[INVOICE_CREATE]} />
                                <ProtectedRoutes path="/processStatus/:jobId" exact component={ViewJobStatus} />
                                <ProtectedRoutes path="/processStatus" exact component={JobStatusList} />
                                <ProtectedRoutes path="/helpCenter" exact component={HelpCenter} />
                                <Route
                                    path="/unauthorised"
                                    component={AccessDenied}
                                />
                                <Route
                                    path="*"
                                    component={ErrorPage}
                                />
                            </Switch>
                        </div>
                    </SnackbarProvider>
                </div>
            </div>
        </React.Fragment>
    )
}
