import { ROWS_PER_PAGE_LINE_ITEMS } from '../../constants/tableConfigs';
import types from '../types/invoiceActionTypes';

export const initialState = {
  invoiceData: {
    invoices: [],
    count: 0
  },
  matterInvoiceData: {
    invoices: [],
    count: 0
  },
  invoiceDetail: {
    "id": "",
    "invoiceNumber": null,
    "status": "",
    "startDate": "",
    "endDate": "",
    "isActive": true,
    "method": "",
    "hasAttachment": false,
    "invoiceType": "",
    "organizationId": "",
    "organizationName": "",
    "matterName": "",
    "matterNumber": "",
    "matterId": "",
    "lastUpdatedBy": "",
    "lastUpdatedTs": "",
    "createdTs": "",
    "createdBy": "",
    "lineItems": [],
    "totalNetAmount": 0,
    "totalFees": 0,
    "totalExpenses": 0,
    "totalFeeDiscounts": 0,
    "totalFeeAdjustments": 0,
    "totalExpenseDiscounts": 0,
    "totalExpenseAdjustments": 0
  },
  lineItemsData: {
    count: 0,
    subLineItems: []
  },
  adjustedFeeLineItems: [],
  adjustedExpenseLineItems: [],
  nonAdjustedFeeLineItems: [],
  nonAdjustedExpenseLineItems: [],
  adjustedLineItemsPaginationDetail: {
    currentPage: 0,
    rowsPerPage: ROWS_PER_PAGE_LINE_ITEMS,
    tabName: '',
    adjustedFeeCount: 0,
    adjustedExpenseCount: 0
  },
  nonAdjustedLineItemsPaginationDetail: {
    currentPage: 0,
    rowsPerPage: ROWS_PER_PAGE_LINE_ITEMS,
    tabName: '',
    nonAdjustedFeeCount: 0,
    nonAdjustedExpenseCount: 0
  }
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INVOICE_DATA:
      return {
        ...state,
        invoiceData: action.payload,
      };
    case types.SET_INPUT_VALUE:
      const { name, value } = action.payload;
      return {
        ...state,
        invoiceDetail: {
          ...state.invoiceDetail,
          [name]: value,
        },
      };
    case types.SET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceDetail: {
          ...state.invoiceDetail,
          ...action.payload,
        },
      };
    case types.RESET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceDetail: initialState.invoiceDetail,
      };
    case types.SET_MATTER_INVOICE_DATA:
      return {
        ...state,
        matterInvoiceData: action.payload,
      };
    case types.SET_LINE_ITEMS_DATA:
      return {
        ...state,
        lineItemsData: action.payload,
      };
    case types.SET_ADJUSTED_FEE_LINE_ITEMS:
      return {
        ...state,
        adjustedFeeLineItems: action.payload,
      };
    case types.SET_ADJUSTED_EXPENSE_LINE_ITEMS:
      return {
        ...state,
        adjustedExpenseLineItems: action.payload,
      };
    case types.SET_NON_ADJUSTED_FEE_LINE_ITEMS:
      return {
        ...state,
        nonAdjustedFeeLineItems: action.payload,
      };
    case types.SET_NON_ADJUSTED_EXPENSE_LINE_ITEMS:
      return {
        ...state,
        nonAdjustedExpenseLineItems: action.payload,
      };
    case types.SET_LINE_ITEM_PAGINATION_DETAIL_ADJUSTED:
      return {
        ...state,
        adjustedLineItemsPaginationDetail: action.payload,
      };
    case types.SET_LINE_ITEM_PAGINATION_DETAIL_NON_ADJUSTED:
      return {
        ...state,
        nonAdjustedLineItemsPaginationDetail: action.payload,
      };
    case types.RESET_INVOICE_LINE_ITEM_ADJUSTED:
      return {
        ...state,
        adjustedLineItemsPaginationDetail: initialState.adjustedLineItemsPaginationDetail
      }
    case types.RESET_INVOICE_LINE_ITEM_NON_ADJUSTED:
      return {
        ...state,
        nonAdjustedLineItemsPaginationDetail: initialState.nonAdjustedLineItemsPaginationDetail,
      }
    default:
      return state;
  }
};

export default invoiceReducer;
