export const PATTERNS = {
    PHONE_NUMBER: /^[0-9 ()-]*$/,
    NUMERIC: /^\d+$/,
    NUMERIC_DECIMAL: /^[0-9_.]+$/,
    CHARACTERS: /^[A-Za-z_ ]+$/,
    ALPHA_NUMERIC: /^[a-z0-9_ ]+$/i,
    ALPHA_NUMERIC_WITH_SPECIAL_COURT_NAME: /^[ A-Za-z0-9_ .,]*$/,
    ALPHA_NUMERIC_WITH_SPECIAL_DOCKET: /^[ A-Za-z0-9_ :-]*$/,
    ALPHA_NUMERIC_WITH_SPECIAL_CHAR: /^[ A-Za-z0-9_ @./#&+-]*$/,
    PASSWORD:/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{4,}$/,
    EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/,
    ZIPCODE: /^[a-zA-Z0-9-]+$/,
  }
