import React, { useState, useEffect } from "react";
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { getService } from "../../../axios/axios";
import { MASTER_PARAMS, STATE_BAR_LICENSE_NA } from "../../../constants/constants";
import { getErrorMessage } from "../../../helpers/utils";
import { useSnackbar } from "@walmart-web/livingdesign-components";
import MuiValidationMessage from "../../../Validation/MuiValidationMessage/MuiValidationMessage";
import { MASTER_DATA } from "../../../constants/baseURLs";
import { setMasterData } from "../../../store/actions/masterDataAction";
import { createUseStyles } from "react-jss";

export const AutocompleteStyles = createUseStyles({
	masterAutocompleteInput:{
		'&>div.MuiTextField-root':{
			margin: '4px 0 4px'
		},
		'&>div>div>input.MuiAutocomplete-input':{
			padding: '8px 4px 8px 5px'
		}
	}
})

/**
 * Component to render Master Data search Autocomplete dropdown
 *
 */
const MasterDataAutocomplete = (props) => {
	const { dataType, value, setValue, filterCheck, required=false, disabled = false, index = "",register, errors, name="masterDataAutocomplete", validationRules,
		placeholderValue, id = "masterData-autocomplete", testid = "masterData-autocomplete", componentType = null } = props;
	const [open, setOpen] = useState(false);
	const [loading,setLoading]=useState()
	const dispatch = useDispatch();
  	const { addSnack } = useSnackbar();
	const filterOptions = createFilterOptions({
		stringify: (option) => `${option?.value}`,
	});
	const masterDetails = useSelector(state => state?.masterData?.masterDatas);
	const classes = AutocompleteStyles();

	const isValueCheck = (v) =>{
		if(v?.id){
			return {
				referenceKey: v?.id,
				value: v?.value,
				code: v?.code,
				description: v?.description,
				type: v?.type,
				name: name,
				index: index
			}
		}else{ return null }
	}

	/**
  	* It'll fetch all static data mainly for dropdown options across application
  	*/
	const callMasterData = () => {
		setLoading(true)
		getService(
		  MASTER_DATA,
		  `/master-data/v1?types=${MASTER_PARAMS}`
		)
		  .then((response) => {
			dispatch(setMasterData(response?.data));
			setLoading(false)
		  })
		  .catch((error) => {
			dispatch(setMasterData([]));
			addSnack({
			  message: getErrorMessage(error)
			});
			setLoading(false)
		  });
	}
	useEffect(()=>{
		if(!masterDetails?.[dataType]){
		 callMasterData()
		}
	  },[])

	  // This method will return options of the dropdown
	  const returnOptions = () => {
		// Checks if masterdata for provodided data type is present in the store
		  if (masterDetails?.[dataType]?.masterData) {
			  const sortedMasterData = masterDetails?.[dataType]?.masterData?.sort((a, b) => a?.value?.localeCompare(b?.value));
			  if (masterDetails?.[dataType]?.masterData?.length !== 0 && dataType !== "stateBarLicense") {
				  return sortedMasterData;
			  }
			  // if master data type is stateBarLicense - add N/A to the top option
			  else if (masterDetails?.[dataType]?.masterData?.length !== 0 && dataType === "stateBarLicense") {
				  const masterDataWithNA = [STATE_BAR_LICENSE_NA, ...sortedMasterData]
				  return masterDataWithNA;
			  }
		  }
		  // Return empty result if master data is not present for provided data type
		  else {
			  return []
		  }
    }

	return (
		<Autocomplete
			id={id}
			className={classes.masterAutocompleteInput}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			disableCloseOnSelect={false}
			disablePortal={true}
			autoHighlight
			autoComplete
			loading={loading}
			options={
                returnOptions()
            }
			getOptionLabel={(option) => option?.value ? option?.value :option}
			renderOption={(props, option) => (
					<Box component="li" {...props} key={option?.referenceKey}>
						{`${option?.value}`}
					</Box>
				)
			}
			filterOptions={filterOptions}
			value={value}
			disabled={disabled}
			fullWidth={true}
			isOptionEqualToValue={(option, value) => option === value}
			onChange={(e, v) => {
				!filterCheck?setValue(isValueCheck(v)):
				setValue(v?.value)
			}}
			onInputChange={(e, v) => {
				if (componentType === "matterDetail" && v === "") {
					setValue({ value: "N/A" })
				}
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params.inputProps, required, style: {
							color: '#000000',
							fontFamily: 'Bogle',
							fontSize: 16,
						}
					}}
					name= "masterDataAutocomplete"
					error={errors && errors[name]}
					helperText={(errors && errors[name]) && <MuiValidationMessage message={errors && errors[name]?.message}/>}
					{
						...required ? {...register(
						name,
						{...validationRules[name], onChange: (v) => {
							setValue(isValueCheck(v))
						}}
					)}:null}
					id={id}
					margin="dense"
					placeholder={placeholderValue}
					variant="outlined"
					data-testid={testid}
				/>
			)}
		/>
	);

};

export default MasterDataAutocomplete;
