import { createUseStyles } from "react-jss";

const LogoutPageStyles = createUseStyles({
  logoutPageContainer: {
    textAlign: "center",
    marginTop: "37vh",
  },
  button: {
    marginTop: "15px"
  }
})

export default LogoutPageStyles;

