import types from "../types/accessibilityActionTypes";
import { applicationAccess, sidemenuAccess } from "../../access/AccessControls";

export const initialState = {
  applicationAccess: applicationAccess([]),
  sidemenuAccess: sidemenuAccess([])
}

const accessibilityReducer = (state = initialState, action) => {
  if (action.type === types.SET_ACCESSIBILITY) {
    return {
      ...state,
      applicationAccess: action.payload.applicationAccess,
      sidemenuAccess: action.payload.sidemenuAccess
    };
  }
  else {
    return state;
  }
}

export default accessibilityReducer;
