/**
 * This constant file will be used to maintain features
 * which are in progress/ not deployed to prod
 */
export const FEATURES = {
    TK_BACKDATING: "TKBackdating",
    BULK_JOB: "bulkJob",
    INVOICE_LINE_ITEM_DISCOUNT: "invoiceLineItemDiscount",
    SITE_FEEDBACK: "isSiteFeedbackEnabled"
}
