import types from '../types/jobStatusActionTypes';

export const setJobStatusData = (payload) => {
  return { type: types.SET_JOB_STATUS_DATA, payload }
}

export const setJobStatusDetail = (payload) => {
  return { type: types.SET_JOB_STATUS_DETAIL, payload }
}

