import types from "../types/commonActionTypes";

export const initialState = {
    showReleaseNotesModal : false,
}

const commonReducer = (state = initialState, action) => {
    if (action.type === types.SET_SHOW_RELEASE_NOTES) {
        return {
            ...state,
            showReleaseNotesModal: action.payload,
        };
    }
    else {
        return state;
    }
}
export default commonReducer;