import { createUseStyles } from "react-jss";
import {
  Breadcrumb,
  BreadcrumbItem,
  Grid,
  GridColumn,
  Heading,
  Skeleton,
  TabNavigation,
  TabNavigationItem,
  Tag,
  Body,
  Button,
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { LIGHT_GRAY } from "../../../constants/colors";
import PeopleDetails from "./PeopleDetails";
import { getService } from "../../../axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetail } from "../../../store/actions/peopleAction";
import { userStatusTagColors } from "../../../constants/constants";
import PeopleMatterSpecificRatesList from "../PeopleMatterSpecificRates/PeopleMatterSpecificRatesList";
import PeoplePracticeAreaRatesList from "../PeoplePracticeAreaRates/PeoplePracticeAreaRatesList";
import { PEOPLE_SERVICE } from "../../../constants/baseURLs";
import { isValueEmptyCheck } from "../../../helpers/validationUtils";
import PeopleAssociatedMatters from "../PeopleAssociatedMatters/PeopleAssociatedMatters";
import { getErrorMessage, getTabList } from "../../../helpers/utils";
import { initialState } from '../../../store/reducers/peopleReducer';
import { setUserRequestId } from "../../../store/actions/userActions";

const useStyles = createUseStyles({
  listing: {
    backgroundColor: LIGHT_GRAY,
    padding: "25px 30px",
    flex: 1,
  },
  pageTitle: {
    fontSize: 36,
    fontWeight: "bold",
    display: "block",
    marginLeft: "16px",
  },
  pageSubTitle: {
    marginLeft: "16px",
    marginBottom: "8px",
    fontWeight: "bold",
    fontSize: 20,
  },
  pageInfo: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "16px",
  },
  website: {
    fontWeight: "bold",
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
  },
  tabContainer: {
    "& nav > ul > li > a": {
      background: "none",
    },
  },
  gridLayout: {
    margin: "16px 0 16px",
  },
  editBtn: {
    display: "flex",
    justifyContent: "right",
  },
  breadcrumb: {
    marginBottom: "30px",
  },
  pageInfoBold: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  disableTabs: {
    opacity: 0.5,
    pointerEvents: "none"
  }
});

export const LoadField = ({ loader, skeletonHeight, skeletonVariant, skeletonLines = 1, node }) => {
  if (loader) {
    return <Skeleton height={skeletonHeight} variant={skeletonVariant} lines={skeletonLines} />;
  } else {
    return node;
  }
};

const ViewPeople = () => {
  const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.organization?.tabs?.people?.tabs?.active?.details);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { state: { selectedTab, selectedPeopleTab  } = {} } = useLocation();
  const { peopleId } = useParams()
  const { addSnack } = useSnackbar();
  const dispatch = useDispatch();
  const { listOfTabs, tabIndexMap } = getTabList(accessibility?.tabs);
  const userDetails = useSelector(state => state?.user?.userDetail?.attributes);
  const [isLoggedInProfile, setIsLoggedInUser] = useState(false);
  const {pureAdminRole} = useSelector((state) => state?.user?.featureMap);

  useEffect(() => {
    getUserDetail();
  }, [])

  const getUserDetail = () => {
    setLoading(true);
    getService(PEOPLE_SERVICE, `/people/v1/${peopleId}`)
      .then((res) => {
        dispatch(setUserDetail( res?.data?.result?.people))
        dispatch(setUserRequestId(res?.data?.result?.people?.userRequestId))
        if(res?.data?.result?.people?.userId === userDetails?.userId) {
          setIsLoggedInUser(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  }

  useEffect(() => {
    return () => {
      dispatch(setUserDetail(initialState?.userDetail))
    }
  }, [])

  return (
    <div className={classes.listing}>
      <HeaderSection loading={loading} peopleId={peopleId} accessibility={accessibility} isLoggedInProfile={isLoggedInProfile} selectedPeopleTab={selectedPeopleTab}/>
      <TabsLayout loading={loading} selectedTab={selectedTab} listOfTabs={listOfTabs} tabIndexMap={tabIndexMap} pureAdminRole={pureAdminRole} />
    </div>
  );
};

const HeaderSection = (props) => {
  const { loading, peopleId, accessibility, isLoggedInProfile, selectedPeopleTab } = props;
  const classes = useStyles();
  const history = useHistory();
  const handleEdit = () => {
    history.push(`/organization/timekeeper/${peopleId}/edit`);
  };
  const {
    lastName,
    organizationName,
    firstName,
    requestedRole,
    userId,
    status} = useSelector(state => state?.people?.userDetail)

  /*
*Loading Skeleton
*/
  const loadFieldProps = {
    loader: loading,
    skeletonHeight: '18px',
    skeletonVariant: 'rectangle',
    skeletonLines: 1,
    node: <></>
  };

  return (
    <div>
      <div className={classes.breadcrumb}>
        <Breadcrumb>
          <BreadcrumbItem data-testid="org-breadcrumb" onClick={()=>history.push("/organization")}>{organizationName}</BreadcrumbItem>
          <BreadcrumbItem data-testid="people-breadcrumb" onClick={() => history.push("/organization", { selectedTab: 1, selectedPeopleTab: selectedPeopleTab })}>
            People
          </BreadcrumbItem>
          <BreadcrumbItem isCurrent>{`${firstName} ${lastName}`}</BreadcrumbItem>
        </Breadcrumb>
      </div>

      <Grid>
        <GridColumn sm={7}>
          <Heading as="h1" size="large" className={classes.pageTitle} id={"fullName"}>
          {`${firstName} ${lastName}`}
          </Heading>
          <Heading as="h1" size="small" className={classes.pageSubTitle}>
            {organizationName}
          </Heading>
        </GridColumn>
        <GridColumn sm={5}>
          {(accessibility?.edit || isLoggedInProfile) &&
            <div className={classes.editBtn}>
              <Button size="small" data-testid="edit-btn" id="edit-btn" onClick={handleEdit}>
                Edit Profile
              </Button>
            </div>
          }
        </GridColumn>
      </Grid>
      <div className={classes.gridLayout}>
        <Grid>
          <GridColumn sm="1" className={classes.pageInfo}>
            <div className={classes.label}>
              <Body as="p" size="small">
                Role
              </Body>
            </div>
            {LoadField({
              ...loadFieldProps, node: <div className={classes.pageInfoBold}>
                {isValueEmptyCheck(requestedRole)}
              </div>
            })}
          </GridColumn>
          <GridColumn sm={2} className={classes.pageInfo}>
            <Body as="p" size="small">
              User ID
            </Body>
            {LoadField({
              ...loadFieldProps, node: <div className={classes.pageInfoBold}>
                {isValueEmptyCheck(userId)}
              </div>
            })}
          </GridColumn>
          <GridColumn sm="2" className={classes.pageInfo}>
            <div className={classes.label}>
              <Body as="p" size="small">
                Status
              </Body>
            </div>
            {LoadField({
              ...loadFieldProps, node: <div className={classes.pageInfoBold}>
                <Tag color={userStatusTagColors[status]?.color} variant="secondary">
                  {isValueEmptyCheck(status)}
                </Tag>
              </div>
            })}
          </GridColumn>
        </Grid>
      </div>
    </div>
  );
};

const TabsLayout = (props) => {
  const { selectedTab, loading, listOfTabs, tabIndexMap, pureAdminRole }=props;
  const { isTimeKeeperRoleEnabled} = useSelector(state => state?.people?.userDetail)
  const classes = useStyles();
  const [isCurrent, setIsCurrent] = useState(selectedTab ? selectedTab : tabIndexMap[listOfTabs[0]]);
  return (
    <div className={classes.tabContainer}>
      <TabNavigation>
        {listOfTabs?.map((item, index) => {
          if(pureAdminRole) {
            if(isTimeKeeperRoleEnabled) {
              return <TabNavigationItem
                onClick={() => setIsCurrent(tabIndexMap[item])}
                isCurrent={isCurrent === tabIndexMap[item]}
                key={tabIndexMap[item]}
                data-testid={item?.split(" ").join("-")}
                id={item?.split(" ").join("-")}
              >
                {item}
              </TabNavigationItem>
            } else {
              if (isTimeKeeperRoleEnabled === false) {
                return (item === 'Details' && <TabNavigationItem
                  onClick={() => setIsCurrent(tabIndexMap[item])}
                  isCurrent={isCurrent === tabIndexMap[item]}
                  key={tabIndexMap[item]}
                  data-testid={item?.split(" ").join("-")}
                  id={item?.split(" ").join("-")}
                >
                  {item}
                </TabNavigationItem>)
							} else {
                return (
                  <TabNavigationItem
                    onClick={() => setIsCurrent(tabIndexMap[item])}
                    isCurrent={isCurrent === tabIndexMap[item]}
                    key={tabIndexMap[item]}
                    data-testid={item?.split(" ").join("-")}
                    id={item?.split(" ").join("-")}
                    UNSAFE_className={loading ? classes.disableTabs : ""}
                  >
                    {item}
                  </TabNavigationItem>
                )
							}
            }
          } else {
            return <TabNavigationItem
              onClick={() => setIsCurrent(tabIndexMap[item])}
              isCurrent={isCurrent === tabIndexMap[item]}
              key={tabIndexMap[item]}
              data-testid={item?.split(" ").join("-")}
              id={item?.split(" ").join("-")}
              UNSAFE_className={loading ? classes.disableTabs: ""}
            >
              {item}
            </TabNavigationItem>
          }

        })}
      </TabNavigation>
      {TabContent(isCurrent, loading)}
    </div>
  );
};

const TabContent = (isCurrent, loading) => {
  switch (isCurrent) {
    case 0:
      return <PeopleDetails selectedTab={isCurrent} loading={loading} />;
    case 1:
      return <PeopleMatterSpecificRatesList />;
    case 2:
      return <PeoplePracticeAreaRatesList/>;
    case 3:
      return <PeopleAssociatedMatters/>;
    default:
      return null;
  }
};

export default ViewPeople;
