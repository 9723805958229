import React from 'react';
import { Button, Body } from "@walmart-web/livingdesign-components";
import LogoutPageStyles from './LogoutPage.styles';
import { SIGN_IN } from '../../constants/actionButtons';
import { LOGOUT_SUCCESS_MSG } from '../../constants/messages';
import { useHistory } from 'react-router-dom';

/*
*    Error Page is displayed in case of any errors
*    and the page is not accessible
*/
const LogoutPage = () => {
  const classes = LogoutPageStyles();
  const history = useHistory()

  const handleClick = () => {
    history.push('/')
  }

  return (
    <div className={classes.logoutPageContainer} data-testid="logoutpage-container">
      <Body as="p">{LOGOUT_SUCCESS_MSG}</Body>
      <div className={classes.button}>
        <Button variant="primary"
          onClick={handleClick}
          size="medium"
          data-testid="signin-btn"
          id="signin-btn">
            {SIGN_IN}
        </Button>
      </div>
    </div>
  );
};
export default LogoutPage;
