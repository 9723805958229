import types from "../types/userActionTypes";

export const initialState = {
  tenantId: "",
  userDetail: {
    ldapId: "",
    firstName: "",
    lastName: "",
    roleCode: "",
    primaryContactNo: "",
    userMasterId: "",
    principalId: "",
    creationTime: "",
    lastLoginTime: "",
    attributes : {
      firstName : "",
      lastName: "",
      primaryContactNo : "",
      workContactNo: null,
      emailId: "",
      userUniqueId: "",
      userId: "",
      organizationId: ""
    },
    // privatePass: null,
    refreshToken: null
  },
  tknDetail: {
    privatePass: null,
    privatePassCreationTime: null,
    refreshToken: null,
    refreshTokenCreationTime: null,
    validity: 1800
  },
  loginSuccess: false,
  forgotPrincipalId: '',
  staticTk: null,
  authTk: null,
  userRequestId: null,
  featureMap : {
    featureFlag : false // FEATURE - DUMMY FLAG dummy feature flag
  },
  releaseNotesData : {}
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_LOGIN_DETAILS:
      return {
        ...state,
        userDetail: action.payload
      };

    case types.SET_USER_LOGIN_TENANT_ID:
      return {
        ...state,
        tenantId: action.payload
      };

    case types.SET_USER_RESPONSIBILITY_CODES:
      return {
        ...state,
        responsibilityCodes: action.payload
      };

    case types.SET_LOGOUT:
      return {
        ...state,
        userDetail: {}
      }

    case types.SET_STATIC_TK:
      return {
        ...state,
        staticTk: action.payload
      }

    case types.SET_AUTH_SIGN:
      return {
        ...state,
        authSign: action.payload
      }

    case types.SET_AUTH_TK:
      return {
        ...state,
        authTk: action.payload
      }

    case types.SET_TKN_DETAILS:
      return {
        ...state,
        tknDetail: action.payload
      }
    case types.SET_USER_REQUEST_ID:
      return {
        ...state,
        userRequestId: action.payload
      }
    case types.SET_FEATURE_FLAGS:
      return {
        ...state,
        featureMap: {
          ...state.featureMap,
          ...action?.payload
        }
      }
    case types.SET_RELEASE_NOTES_DATA:
      return {
        ...state,
        releaseNotesData: action?.payload
        }
    default:
      return state;
  }
}

export default userReducer;
