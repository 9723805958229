import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import { setOrganizationDropDownData } from "../../../store/actions/masterDataAction";
import { useSnackbar } from "@walmart-web/livingdesign-components";
import { postService } from "../../../axios/axios";
import {COMPOSITE_SEARCH_SERVICE} from "../../../constants/baseURLs";
import { getErrorMessage, getHighlightedText } from "../../../helpers/utils";
import { formCompositeSearchQuery, formCsFilterObject, formCsOrgAutocompleteQuery  } from "../../../helpers/csQueryUtils";
import {CS_SCOPES, CS_TYPEAHEAD_PAGINATION_QUERY, NA_ORG_COMPANY_NAME, NA_ORG_ID, ORG_AUTOCOMPLETE_DEFAULT_SORT_QUERY } from "../../../constants/constants";
import { debounce } from '@mui/material/utils';

const createAutoStyles = createUseStyles({
	boxLi: {
		overflow: "hidden",
		cursor: "pointer",
		paddingTop: "6px",
		boxSizing: "border-box",
		outline: 0,
		paddingBottom: "6px",
		paddingLeft: "16px",
		paddingRight: "16px",
		'&:hover' : {
			background: "rgba(0, 0, 0, 0.04)"
		}
	}
})

/**
 * Component to render Organization search Autocomplete dropdown
 *
 */
const OrganizationAutocomplete = (props) => {
	const classes = createAutoStyles();
	const { value, setValue, filterCheck, required, disabled = false, placeholderValue, id = "organization-autocomplete",
		testid = "organization-autocomplete", fromBudget = false } = props;
	const { addSnack } = useSnackbar();
	const dispatch = useDispatch()
	const [open, setOpen] = useState(false);
	const { organizationDropDownData } = useSelector((state) => state?.masterData);
	const [loading,setLoading]=useState()
	const [inputValue, setInputValue] = useState('');

	const filterOptions = createFilterOptions({
		stringify: (option) => `${option?.companyName} ${option?.identifier}`,
	});

	/**
	*Function to call search org Service
	*/
	const getOrganizationData = (searchQuery = {}) => {

		setLoading(true);
    const defaultQuery = {
      "filters": [formCsFilterObject("isActive", "match", true)],
      "operation": "AND",
      "properties": null
    }

		const query = formCompositeSearchQuery([CS_SCOPES.ORGANIZATION], {}, {}, {}, CS_TYPEAHEAD_PAGINATION_QUERY, {}, defaultQuery, searchQuery);

    postService(
      	COMPOSITE_SEARCH_SERVICE,
      	`/composite-search/v1?returnCsv=false`,
      	query
    )
      .then((res) => {
        setLoading(false);
        const orgListData = res?.data?.organization;
		if(fromBudget)
		{
			const naOrgData = {
				id: NA_ORG_ID,
				companyName: NA_ORG_COMPANY_NAME
			}
			orgListData?.data?.push(naOrgData)
			orgListData.count++;
		}
        dispatch(setOrganizationDropDownData({nodeList: orgListData?.data, count: orgListData?.count}))
      })
      .catch((error) => {
        setLoading(false)
        addSnack({ message: getErrorMessage(error) })
      });
	}

	const fetch = useMemo(() =>
		debounce((searchText, callback) => {
				const searchQueryObj = formCsOrgAutocompleteQuery(searchText);
				if(searchText?.length > 2) {
					getOrganizationData(searchQueryObj)
				}
		}, 600),
		[],
	);

	useEffect(() => {
		if(inputValue?.length < 1) {
			dispatch(setOrganizationDropDownData([]))
			setLoading(false)
		}
	},[inputValue])


	const isValueCheck = (v) =>{
		if(v?.id){
			return {
				referenceKey: v?.id,
				companyName: v?.companyName
			}
		}else{ return null }
	}

	return (
		<Autocomplete
			id={id}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			disableCloseOnSelect={false}
			disablePortal={true}
			autoHighlight
			autoComplete
			loading={loading}
			noOptionsText={inputValue?.length < 1 ? "Please type to search" : "No options" }
			options={organizationDropDownData?.nodeList ?? []}
			getOptionLabel={(option) => option?.companyName ? option?.companyName : option}
			renderOption={(props, option) => (
					<li {...props} className={classes.boxLi} key={option?.referenceKey}>
					{ getHighlightedText(`${option?.companyName}`,inputValue) }
					</li>
				)
			}
			filterOptions={filterOptions}
			value={value}
			disabled={disabled}
			fullWidth={true}
			isOptionEqualToValue={(option, value) => option === value}
			onChange={(e, v) => {
				!filterCheck?setValue(isValueCheck(v)):
				setValue({
					displayValue: v?.companyName,
					obj: isValueCheck(v)})
			}}
			onInputChange={(event, newInputValue) => {
				if(!((!event) || (event?.type === "click" && newInputValue !== ""))) {
					setInputValue(newInputValue);
					setLoading(true)
					fetch(newInputValue)
				}
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params?.inputProps, required, style: {
							color: '#000000',
							fontFamily: 'Bogle',
							fontSize: 16,
						}
					}}
					margin="dense"
					placeholder={placeholderValue}
					variant="outlined"
					data-testid={testid}
				/>
			)}
		/>
	);
};

export default OrganizationAutocomplete;

OrganizationAutocomplete.propTypes = {
	/** to display default value on load */
	value: PropTypes.string,
	/** callback function on user input */
	setValue: PropTypes.func,
	/** for checking component is used for filter or not */
	filterCheck: PropTypes.bool,
	/** flag to determine if the field is mandatory */
	required: PropTypes.bool,
	/** flag to determine if input is disabled */
	disabled: PropTypes.bool,
	/** placeholder value for the input */
	placeholderValue: PropTypes.string,
	/** testid to identify the autocomplete component */
	testid: PropTypes.string
  };
