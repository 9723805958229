import { createUseStyles } from "react-jss";
import {
  ButtonGroup,
  Button,
  Divider,
  Grid,
  GridColumn,
  Spinner,
} from "@walmart-web/livingdesign-components";
import React from "react";
import { detailModes as modes } from "../../../constants/constants";
import { CANCEL, CREATE, UPDATE } from "../../../constants/actionButtons";
import { SPACING } from "../../../constants/styleGuide";

const useStyles = createUseStyles({
  footer: {
    paddingTop: SPACING.s16,
    paddingBottom: SPACING.s16,
  },
  footerActions: {
    display: "flex",
    alignItems: "center",
  },
  footerActionContainer: {
    display: "flex",
    alignItems: "center",
  },
  btnGroup: {
    justifyContent: "flex-end",
  },
  divider: {
    marginTop: SPACING.s8,
    marginBottom: SPACING.s8,
    '& > hr': {
      borderBottom:'0.0325rem solid #E3E4E5'
    }
  },
});

const DetailFooter = (props) => {
  const classes = useStyles();

  const {
    mode,
    footerActions,
    onCancel: handleCancel,
    isDirty,
    isValid,
    loading,
    hideCancelButton=false,
    editBtnTxt,
    cancelText,
    disableCancel=false
  } = props;

  return (
    <>
      <div className={classes.divider}>
        <Divider />
      </div>
      <div className={classes.footer}>
        <Grid data-testid="footer-component">
          <GridColumn sm={6} className={classes.footerActionContainer}>
            <div className={classes.footerActions}>{footerActions}</div>
          </GridColumn>
          <GridColumn sm={6}>
            <ButtonGroup className={classes.btnGroup}>
           {!hideCancelButton && <Button
                size="medium"
                id="cancel-btn"
                data-testid="cancel-btn"
                onClick={handleCancel}
                disabled={disableCancel || loading}
              >
                {cancelText ?? CANCEL}
              </Button>}
              <Button
                variant="primary"
                size="medium"
                id="create-btn"
                data-testid="create-btn"
                type="submit"
                disabled={!(isDirty && isValid) || loading}
              >
                {loading? <Spinner color="gray" size="small" />: <>{mode === modes.CREATE ? (editBtnTxt || CREATE) : (editBtnTxt || UPDATE)}</>}
              </Button>
            </ButtonGroup>
          </GridColumn>
        </Grid>
      </div>
    </>
  );
};

export default DetailFooter;
