import { createUseStyles } from "react-jss";

export const createAutoStyles = createUseStyles({
	displayBlock: {
		display: "block"
	},
	boxLi: {
		overflow: "hidden",
		cursor: "pointer",
		paddingTop: "6px",
		boxSizing: "border-box",
		outline: 0,
		paddingBottom: "6px",
		paddingLeft: "16px",
		paddingRight: "16px",
		'&:hover' : {
			background: "rgba(0, 0, 0, 0.04)"
		}
	}
})
