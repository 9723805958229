import moment from "moment";
import { Operations } from "../axios/operations";
import { getISODate } from "./dateTimeUtils";
import { jsonToCsv } from "./commonUtils";
import { DATE_YYYY_MM_DD, MOMENT_DATE_FORMAT } from "../constants/dateFormats";
import { renderTernary } from "./utils";

/**
 *
 * @param {string} name
 * @param {string} operator
 * @param {string} value
 * @param {string} type - array/name
 * @returns
 */
export const formSearchQuery = (name, operator, value, type) => {
	let query = '';
	switch (type) {
		case 'array':
			const inValue = value?.map((e) => `'${e}'`).join(',');
			query = `${name}:${operator}:%5B${inValue}%5D`;
			break;

		case 'name':
			const [firstName, lastName] = value?.split(' ');
			query = `${name}.firstName:${operator}:'${firstName}'`;
			if (lastName) {
				query += ` and ${name}.lastName:${operator}:'${lastName}'`;
			}
			break;
		case 'date':
			const isoDate = moment(value).format(DATE_YYYY_MM_DD);
			const nextDate = moment(value).add(1, 'days').format(DATE_YYYY_MM_DD)
			query = moment(isoDate).isValid() ? `(${name}:GTE:dt'${isoDate}T00:00:00.000Z' and ${name}:LTE:dt'${nextDate}T00:00:00.000Z')` : '';
			break;

		case 'fixedDate':
			const dateVal = moment(value[0]);
			const nextDay = moment(value[0]).add(1, 'days');
			query = moment(dateVal).isValid() ? `(${name}:GTE:${getISODate(dateVal)} and ${name}:LTE:${getISODate(nextDay)})` : '';
			break;
		case 'tag':
			query = `${name}:${operator}:'%2525${value}%2525'`;
			break;

		case 'id':
			query = `${name}:${operator}:'${value}'`;
			break;
		case 'boolean':
			query = `${name}:${operator}:${value}`;
			break;
		case 'daysLeft':
			query = `${name}:${operator}:'${value}'`;
			query = !isNaN(value) ? query : '';
			break;
		case 'visibilty':
			let isPrivate = false;
			if (value?.toLowerCase() === "private") {
				isPrivate = true
			}
			query = value?.toLowerCase() === "private" || value?.toLowerCase() === "public" ? `${name}:${operator}:'${isPrivate}'` : '';
			break;

		case 'notesContent':
			query = `(title:${operator}:'%2525${value}%2525' OR ${name}:${operator}:'%2525${value}%2525')`;
			break;

		case 'text':
			query = `${name}:${operator}:'%2525"${value}"%2525'`;
			break;

		case 'matterSpecific':
			query = `matterSpecificRates.matterName:${operator}:'%2525${value}%2525'+ OR +matterSpecificRates.matterNumber:${operator}:'%2525${value}%2525'`;
			break;

		default:
			query = `${name}:${Operations.LIKEIC}:'%2525${value}%2525'`;
			break;
	}
	return query;
}


/**
 * Gets called when user download the export file
 * server side
 * @public
 */
export const getExportedData = (payload, contentType, downloadName) => {
	const url = window.URL.createObjectURL(
		new Blob([payload], { type: contentType })
	);
	let ext = ".csv";
	if (contentType.includes("application/octet-stream") || contentType.includes("application/zip")) {
		ext = ".zip";
	}
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", `${downloadName}${ext}`);
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
};

/**
 * Gets called when user download the export file
 * client side
 * @public
 */
export const exportClientData = (budgets, exportSelection, rowsData, downloadName) => {
	let csvData = jsonToCsv(budgets);
	if (exportSelection === 'currentSelection') {
		csvData = jsonToCsv(rowsData);
	} else if (exportSelection === 'currentPage') {
		csvData = jsonToCsv(budgets);
	}
	//Download the file as CSV
	const url = window.URL.createObjectURL(
		new Blob([csvData]),
	);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `${downloadName}.csv`);
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
}

export const matterFilterConfig = (filterValues) => {
	const matterQuery = {
		metadata: []
	};
	const andArray = [];
	const orArray = [];
	Object.keys(filterValues).forEach(function (key) {
		if (filterValues?.[key].length !== 0) {
			if (key !== "Status") {
				const filterArray = [];
				filterValues?.[key]?.forEach(function (item, index) {
					const filter = {
						"property": `${key}`,
						"value": `${item}`,
						"operator": "="
					};
					filterArray.push(filter);

				});
				const operator = "OR";
				const orObj = {
					"filters": filterArray,
					"op": operator,
					"metadata": null
				};
				orArray.push(orObj);
			}
			else {
				filterValues?.[key]?.forEach(function (item, index) {
					const filter = {
						"property": `${key}`,
						"value": `${item}`,
						"operator": "="
					};
					andArray.push(filter);

				});
			}
		}
	});
	const query = {
		"filters": andArray,
		"op": "AND",
		"metadata": orArray
	}
	matterQuery.metadata.push(query);
	return { matterQuery };
}

export const documentFilterConfig = (filterValues) => {
	const documentQuery = [];
	let rawSearch = "";
	filterValues && Object?.keys(filterValues)?.forEach(function (key) {
		if (key === 'name') {
			if (filterValues?.[key]?.[0]) {
				rawSearch = filterValues?.[key]?.[0];
			}
		} else
			if (key === 'createdBy' || key === 'tags' || key === 'fileExtension' || key === 'userId' || key?.includes('matterNumber') || key === 'matterName' || key === 'practiceArea') {
				let newKey = key;
				if (key === 'tags' || key?.includes('matterNumber') || key === 'matterName' || key === 'practiceArea') {
					newKey = `metaData.${key}`;
				} else if (key === 'userId') {
					newKey = 'createdBy'
				}
				// In case of multiple selections we need to pass it inside "query"
				if (filterValues?.[key]?.length > 1) {
					const queryStructure = {
						query: {
							condition: Operations.OR,
							queryParameters: []
						}
					}
					filterValues?.[key]?.forEach((name) => {
						queryStructure?.query?.queryParameters?.push({
							key: newKey,
							operation: Operations.MATCH,
							value: name
						})
					})
					documentQuery.push(queryStructure);
				} else if (filterValues?.[key]?.[0]) {
					documentQuery.push(
						{
							key: newKey,
							operation: Operations.MATCH,
							value: filterValues?.[key]?.[0]
						}
					)
				}
			}
			// createdTs is date range
			else if (key === "createdTimeStamp") {
				// at index 0 - start date will be stored
				if (filterValues?.[key]?.["start"] || filterValues?.[key]?.[0]) {
					documentQuery.push(
						{
							key: "createdTimeStamp",
							operation: "gte",
							value: moment(filterValues?.[key]?.["start"] ?? filterValues?.[key]?.[0]).format(DATE_YYYY_MM_DD)
						}
					)
				}
				// at index 1 - end date will be stored
				if (filterValues?.[key]?.["end"] || filterValues?.[key]?.[1]) {
					documentQuery.push({
						key: "createdTimeStamp",
						operation: "lte",
						value: moment(filterValues?.[key]?.["end"] ?? filterValues?.[key]?.[1]).format(DATE_YYYY_MM_DD)
					})
				}
			}
	});
	return { documentQuery, rawSearch };
}

/**
 * Gets called when searching for matters
 * @param {object} filterValues
*/
export const formSearchMatterQuery = (filterValues) => {
	const matterQuery = {
		metadata: []
	};
	const filterArray = [];
	Object.keys(filterValues).forEach(function (key) {
		filterValues?.[key]?.forEach(function (item, index) {
			const filter = {
				"property": `${key}`,
				"value": `${item}`,
				"operator": "like"
			};
			filterArray.push(filter);
		});
	});
	const orObj = {
		"filters": filterArray,
		"op": "OR",
		"metadata": null
	};
	matterQuery.metadata.push(orObj);
	return { matterQuery };
}

/**
 * Gets called when searching for organization people
 * @param {object} filterValues
*/
export const formSearchOrganizationPeopleQuery = (filterValues) => {
	const matterQuery = {
		metadata: []
	};
	const filterArray = [];
	Object.keys(filterValues).forEach(function (key) {
		filterValues?.[key]?.forEach(function (item, index) {
			const filter = {
				"property": `${key}`,
				"value": `${item}`,
				"operator": "like"
			};
			filterArray.push(filter);
		});
	});
	const orObj = {
		"filters": filterArray,
		"op": "OR",
		"metadata": null
	};
	matterQuery.metadata.push(orObj);
	return { matterQuery };
}

/**
 * Gets called when searching for organization
 * @param {object} filterValues
*/
export const formSearchOrganizationQuery = (filterValues) => {
	const matterQuery = {
		metadata: []
	};
	const filterArray = [];
	Object.keys(filterValues).forEach(function (key) {
		filterValues?.[key]?.forEach(function (item, index) {
			const filter = {
				"property": `${key}`,
				"value": `${item}`,
				"operator": "like"
			};
			filterArray.push(filter);
		});
	});
	const orObj = {
		"filters": filterArray,
		"op": "OR",
		"metadata": null
	};
	matterQuery.metadata.push(orObj);
	return { matterQuery };
}

/**
 * Gets called when searching for organization
 * @param {object} filterValues
*/
export const formSearchTimekeeperQuery = (filterValues) => {
	const matterQuery = {
		metadata: []
	};
	const filterArray = [];
	Object.keys(filterValues).forEach(function (key) {
		filterValues?.[key]?.forEach(function (item, index) {
			const filter = {
				"property": `${key}`,
				"value": `${item}`,
				"operator": "like"
			};
			filterArray.push(filter);
		});
	});
	const orObj = {
		"filters": filterArray,
		"op": "OR",
		"metadata": [{
			filter: [{
				"operator": "=",
				"property": "isExternalUser",
				"value": true
			}]
		}]
	};
	matterQuery.metadata.push(orObj);
	return { matterQuery };
}

/**
 * Utility function to perform custom sort on 'matter number' field
 * @param {string} order
 */
export const handleMatterNumberSort = (order) => {
	return (obj1, obj2) => {
		const year1 = parseInt(obj1.data.split("-")[0]);
		const year2 = parseInt(obj2.data.split("-")[0]);
		const number1 = parseInt(obj1.data.split("-")[1]);
		const number2 = parseInt(obj2.data.split("-")[1]);
		if (year1 > year2) {
			return (order === 'asc' ? 1 : -1);
		} else if (year1 < year2) {
			return (order === 'asc' ? -1 : 1);
		} else {
			return (number1 - number2) * (order === 'asc' ? 1 : -1);
		}
	};
}

export const matterDataMapping = (payload) => {
	const data = {
		matters: [],
		count: payload?.count
	};

	payload?.data?.map(result => {
		const linkedPeople = result?.people;
		const linkedOrganizations = result?.organizations;
		let leadAttorney = "-";
		let outsideCounsel = "-";
		linkedPeople?.forEach((item) => {
			if(item?.isLeadAttorney === true)
			{
			leadAttorney = `${item?.firstName} ${item?.lastName}`;
			}
		  });
		linkedOrganizations?.forEach((item) => {
			if(outsideCounsel === "-")
			{
			outsideCounsel = item?.companyName;
			}
			else
			{
			outsideCounsel = `${outsideCounsel}, ${item?.companyName}`
			}
		  })

		data.matters.push({
			identifier: result?.id,
			matterNumber: result?.matterNumber,
			matterName: result?.matterName,
			matterSubType: result?.matterSubType,
			status: result?.status,
			matterType: result?.matterType,
			matterCreateDate: moment(result?.creationDate).format(MOMENT_DATE_FORMAT),
			closureDate: result?.closureDate ? moment(result.closureDate).format(MOMENT_DATE_FORMAT) : '-',
			practiceArea: result?.practiceArea,
			practiceAreaCode: result?.practiceAreaCode,
			createdBy: result?.createdBy,
			leadAttorney: leadAttorney,
			outsideCounsel: outsideCounsel,
			relationshipType: result?.relationshipType,
			comments: result?.comments
		})
	});
	return data;
}

export const matterSOPDataMapping = (payload) => {
	const data = {
		intakes: [],
		count: payload?.count
	};

	payload?.intakes?.map(result => {
		data.intakes.push({
			id: result?.id,
			logId: result?.logId,
			sopStatus: result?.sopStatus,
			caseType: result?.caseType,
			servedJurisdiction: result?.servedJurisdiction,
			lawsuitType: result?.lawsuitType,
			lawsuitSubType: result?.lawsuitSubType,
			sopLastModifiedDate: result?.sopLastModifiedDate && moment(result?.sopLastModifiedDate).format(MOMENT_DATE_FORMAT),
			plaintiff: result?.intakeCaseInfoList?.map((caseInfo) => caseInfo?.plaintiff).join(', '),
			answerDate: result?.answerDate,
			servedName: result?.servedName,
			documentServed: result?.documentServed,
			natureOfAction: result?.natureOfAction,
			sopVersion: result?.sopVersion
		})
	});
	return data;
}

/**
 * Function will use for slicing the export current page data
 * @param {*} completeTableData
 * @param {*} tableDisplayData
 * @param {*} page
 * @param {*} rowsPerPage
 */
export const getCurrentPageDataForExport = (completeTableData, tableDisplayData, page, rowsPerPage, fromMatter) => {
	const bData = fromMatter ? completeTableData : completeTableData?.nodeList;
	const result = tableDisplayData?.displayData?.map(item => {
		return bData && bData[item.dataIndex]
	});
	return result.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
}

// render default filter config
export const renderCustomFilterConfig = (label) => ({ render: (value) => `${label}: ${value}` })

// filter list options for date filter chip
export const renderCustomFilterOption = (label="") => {
    return {
        render: (v) => {
          return `${label} Min-Max Date: ${v[0]} - ${v[1]}`;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === -1) {
            filterList = [];
          }
          return filterList;
        },
    }
}

// filter options for date filter chip
export const dateFilterOptions = () => {
    return {
        names: [],
        logic(startDate, filters) {
          if (filters[0] && filters[1]) {
            return startDate < filters[0] || startDate > filters[1];
          } else if (filters[0]) {
            return startDate < filters[0];
          } else if (filters[1]) {
            return startDate > filters[1];
          }
          return false;
        }
      }
}

/**
 * Function to form allowed action list for mui datatable
 * @param {*} accessibilityObj
 * @returns
 */
export const getAllowedActionsList = (accessibilityObj) => {
  const actionKeys = ["addNew", "read", "export"];
  const allowedActionsObj = {};
  actionKeys.forEach(val => {
    if(accessibilityObj?.[val] !== undefined && accessibilityObj[val]) {
      allowedActionsObj[val] = accessibilityObj[val];
    }
  });
  return allowedActionsObj;
}


export const getInvoiceStatus = (status) => {
  if(status.toLowerCase() === "hold") {
    return "Ready For Payment"
  }
  return status;
}


export const matterDataMappingforDetail = (payload) => {
	const data = {
		matters: [],
		count: payload?.count
	};

	payload?.data?.map(result => {
		const linkedPeople = result?.people;
		const linkedOrganizations = result?.organizations;
		let leadAttorney = "-";
		let outsideCounsel = "-";
		let leadParalegal = "-";
		linkedPeople?.forEach((item) => {
			if(item?.isLeadAttorney === true)
			{
			leadAttorney = `${item?.firstName} ${item?.lastName}`;
			}
			if(item?.isLeadParalegal === true)
			{
			leadParalegal = `${item?.firstName} ${item?.lastName}`;
			}
		  });
		linkedOrganizations?.forEach((item) => {
			if(outsideCounsel === "-")
			{
			outsideCounsel = item?.companyName;
			}
			else
			{
			outsideCounsel = `${outsideCounsel}, ${item?.companyName}`
			}
		  })

		data.matters.push({
			identifier: result?.id,
			leadAttorney: leadAttorney,
			leadParalegal: leadParalegal,
			outsideCounsel: outsideCounsel,
			...result
		})
	});
	return data;
}

/* Get serial number*/
export const getSerialNumber = (tableMeta) => {
	const rowIndex = Number(tableMeta.rowIndex) + 1;
	const page = Number(tableMeta?.tableState.page);
	const rowPerPage = Number(tableMeta?.tableState.rowsPerPage);
	const startNumber = page*rowPerPage;
	return <div id={`table-row-${startNumber+rowIndex}`}>{startNumber+rowIndex}</div>
}

/* Function to display cell with ID */
export const tableCellDisplay = (cellId, value, cellClass) => {
	return <p id={cellId} className={cellClass || ""}>{renderTernary(value, value, '-')}</p>
}

/* Function to display cell header with ID */
export const tableCellHeaderDisplay = (cellId, value) => {
	return <p id={cellId}>{renderTernary(value, value, '-')}</p>
}