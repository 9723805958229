import types from '../types/organizationActionTypes';

export const setSelectedTimekeepersData = (payload) => {
  return { type: types.SET_SELECTED_TIMEKEEPERS_DATA, payload }
}

export const setSelectedMattersData = (payload) => {
  return { type: types.SET_SELECTED_MATTERS_DATA, payload }
}

export const setRemoveSelectedTimekeepersData = (payload) => {
  return { type: types.SET_REMOVE_SELECTED_TIMEKEEPERS_DATA, payload }
}

export const setRemoveSelectedMattersData = (payload) => {
  return { type: types.SET_REMOVE_SELECTED_MATTERS_DATA, payload }
}

export const setMatterListingData = (payload) => {
	return { type: types.SET_MATTER_LISTING_DATA, payload }
}

export const setOrgDetailValues = (payload) => {
  return {type: types.SET_ORG_DETAIL, payload }
}

export const setInputData = (name, value) => {
  return { type: types.SET_INPUT_DATA, payload: { name,value } }
}
