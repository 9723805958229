export const USER_REQUEST_SERVICE = "USER_REQUEST_SERVICE";
export const MATTER_SERVICE = "MATTER_SERVICE";
export const NODE_JS_URL = "NODE_JS_URL";
export const API_GATEWAY_URL = "API_GATEWAY_URL";
export const BUDGET_SERVICE = "BUDGET_SERVICE";
export const INVOICE_SERVICE = "INVOICE_SERVICE";
export const MATTER_DETAILS_DEFINITION = "MATTER_DETAILS_DEFINITION";
export const NOTE_SERVICE = "NOTE_SERVICE";
export const NOTE_SEARCH_SERVICE = "NOTE_SEARCH_SERVICE";
export const MASTER_DATA = "MASTER_DATA";
export const PRACTICE_AREA_SERVICE = "PRACTICE_AREA_SERVICE";
export const ORGANIZATION_SERVICE = "ORGANIZATION_SERVICE";
export const EXTERNAL_USER_SERVICE = "EXTERNAL_USER_SERVICE";
export const PEOPLE_SERVICE = "PEOPLE_SERVICE";
export const PREFERENCES_SERVICE = "PREFERENCES_SERVICE";
export const ACCESS_CONTROL_SERVICE = "ACCESS_CONTROL_SERVICE";
export const USER_SERVICE = "USER_SERVICE";
export const COMPOSITE_SEARCH_SERVICE = "COMPOSITE_SEARCH_SERVICE";
export const JOB_SERVICE = "JOB_SERVICE";
export const CONTENT_SEARCH_SERVICE = "CONTENT_SEARCH_SERVICE";
export const PEOPLE_SEARCH_SERVICE = "PEOPLE_SEARCH_SERVICE";
