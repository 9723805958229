import React, { useEffect, useState } from "react";
import {
  Body,
  Button,
  Card,
  Grid,
  GridColumn,
  SideNavigation,
  SideNavigationItem,
  Spinner,
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import { Article } from "../../constants/images";
import { Divider } from "@mui/material";
import TrainingGuidesStyles from "./TrainingGuides.styles";
import { getService, postServiceWithHeaders } from "../../axios/axios";
import { formSearchQuery, getErrorMessage } from "../../helpers/utils";
import { Operations } from "../../axios/operations";
import { CONTENT_SEARCH_SERVICE, NODE_JS_URL } from "../../constants/baseURLs";
import { useDispatch, useSelector } from "react-redux";
import { setTrainingGuidesData } from "../../store/actions/helpCenterAction";
import { CONTENT_SOURCE, MEDIA_TYPE, SUPPORTED_PREVIEW_FORMAT_HELP_CENTER, TRAINING_GUIDES_ITEMS } from "../../constants/constants";
import { CloudDownload } from "@walmart-web/livingdesign-icons";
import LoadingCards from "../../components/LoadingCards/LoadingCards";
import { ERROR_MSG_WHILE_FETCHING_DOCUMENT, PREVIEW_NOT_SUPPORTED_MSG } from "../../constants/messages";
import { DOWNLOAD } from "../../constants/actionButtons";
import HelpCenterDocumentPreview from "./HelpCenterDocumentPreview";
import IFrameViewer from "./IFrameViewer";
import VideoAudioPlayer from "./VideoAudioPlayer";

/**
 * Component used when preview is not supported
 */
const PreviewNotSupportedCard = ({loading, previewCardContent}) => {
  const classes = TrainingGuidesStyles();
  return (
    (loading) ?
    <Spinner  size="large" className={classes.spinner}/>
    :
    <Card size="small" className={classes.previewCard}>
      {previewCardContent()}
    </Card>
  );
}

/**
 * Component to render Training Guides in Help center
 */

const TrainingGuides = () => {
  const classes = TrainingGuidesStyles();
  const [isCurrent, setIsCurrent] = useState(0);
  const [currentCard, setCurrentCard] = useState(0);
  const [loading, setLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const {addSnack} = useSnackbar();
  const dispatch = useDispatch();
  const [ isPreviewSupported, setIsPreviewSupported] = useState(false);
  const {trainingGuidesData} = useSelector((state) => state?.helpCenter)
  const defaultFilterConditionContent = `${formSearchQuery(
    "isActive",
    Operations.EQ,
    true,
    'boolean'
  )}`;
  const defaultSortQuery = `&order=contentIndex asc`
  const loadComponentProps = {
    loader: loading,
    skeletonHeight: '80px',
    skeletonWidth: '100%',
    skeletonVariant: 'reactangle',
    skeletonLines: 1
  };
  const [isContentFailed,setIsContentFailed] = useState(false)

  /**
   * Function to get content data
   * @param {string} query
   *
   */
  const getContentData = (query = "") => {
    setLoading(true);
    const encodedUri=encodeURI(defaultFilterConditionContent)
    getService(
      CONTENT_SEARCH_SERVICE,
      `/search/contents/v1?filter=${encodedUri} AND ${query} ${defaultSortQuery}`
          )
      .then((response) => {
        dispatch(setTrainingGuidesData(response?.data));
        setLoading(false);
      })
      .catch((error) => {
        setIsContentFailed(true)
        setLoading(false);
        dispatch(setTrainingGuidesData([]));
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };
  /**
   * Function to handle click of content cards
   * @param {} value
   * @param {*} index
   */
  const handleClick = (value, index) => {
    setIsCurrent(index);
    getContentData(formSearchQuery(
      "category",
      Operations.EQ,
      value?.key))
  };

  /**
   *
   * @param {*} value
   * @param {*} index
   * @returns
   */
  const createItem = (value, index) => (
    <SideNavigationItem
      key={value?.key}
      onClick={() => handleClick(value, index)}
      isCurrent={isCurrent === index}
      data-testid={`nav-item-${value?.label?.split(' ').join('-')}`}
      id={`nav-item-${value?.label?.split(' ').join('-')}`}
    >
      {value?.label}
    </SideNavigationItem>
  );

  /**
  * Fumction to trigger callback on file download
  */
  const onClickDownLoad = () => {
    setSpinnerLoading(true);
    postServiceWithHeaders(NODE_JS_URL,'/download?version=1',{id: trainingGuidesData?.contents?.[currentCard]?.location, isContent: true}, {},"blob")
      .then((res) => {
        const url = URL.createObjectURL(res?.data);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.setAttribute("download", `${trainingGuidesData?.contents?.[currentCard]?.title}.${trainingGuidesData?.contents?.[currentCard]?.mediaType}`);
        document.body.appendChild(anchor);
        anchor.click();
        setSpinnerLoading(false);
      })
      .catch((error) => {
        setSpinnerLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      })
  }

  /**
   * Function to check whether preview of file is supported or not
   * @param {*} idx
   */
  const checkPreview = (idx) => {
    setCurrentCard(idx);
    // as docviewer is supporting limited files
    if (SUPPORTED_PREVIEW_FORMAT_HELP_CENTER?.indexOf(trainingGuidesData?.contents?.[idx]?.mediaType) > -1) {
      setIsPreviewSupported(true);
    } else {
      setIsPreviewSupported(false);
    }
  }

  /**
   * Component used when preview is not supported
   */

  const previewCardContent =  () => {
    return (
      isContentFailed ?
      <div className={classes.center}>
      <Body as="p" size="large" weight={700}>
            {ERROR_MSG_WHILE_FETCHING_DOCUMENT}
          </Body>
      </div>
          :
      <div className={classes.center}>
          <Body as="p" size="large" weight={700}>
            {PREVIEW_NOT_SUPPORTED_MSG}
          </Body>
          {
            spinnerLoading ?
            <Button
            variant="primary"
            size="medium"
            data-testid="spinner-btn"
            className={classes.downloadButton}
          >
            <Spinner color="white" size="small" />
          </Button> :
          <Button
            variant="primary"
            size="medium"
            data-testid="download-btn"
            leading={<CloudDownload />}
            className={classes.downloadButton}
            onClick={()=>onClickDownLoad()}
          >
            {DOWNLOAD}
          </Button>
          }
    </div>
    )
  }


  useEffect(() => {
    getContentData(formSearchQuery(
      "category",
      Operations.EQ,
      TRAINING_GUIDES_ITEMS?.[0]?.key))
    setIsCurrent(0)
  }, []);

  useEffect(() => {
    checkPreview(0);
  }, [trainingGuidesData]);

  /**
   * Function to render components based on media type
   * @param {*} mediaType
   * @returns
   */
  const canopusFileViewer =  (mediaType) => {
    switch (mediaType) {
      case MEDIA_TYPE.PDF:
      case MEDIA_TYPE.PNG:
      case MEDIA_TYPE.JPEG:
      case MEDIA_TYPE.TXT:
        return <HelpCenterDocumentPreview currentCard={currentCard} loading={loading} onClickDownLoad={onClickDownLoad} spinnerLoading = {spinnerLoading}/>;
      case MEDIA_TYPE.AUDIO:
      case MEDIA_TYPE.VIDEO:
          return <VideoAudioPlayer currentCard={currentCard} loading={loading}/>;
      default:
        return <></>;
    }
  }

  /**
   * Function to render components based on content source
   * @param {*} contentSource
   * @returns
   */
  const fileViewer =  (contentSource) => {
    switch (contentSource) {
      case CONTENT_SOURCE.CANOPUS:
        return canopusFileViewer(trainingGuidesData?.contents?.[currentCard]?.mediaType);
      case CONTENT_SOURCE.MS_STREAM: case CONTENT_SOURCE.VIMEO:
          return <IFrameViewer currentCard={currentCard} loading={loading}/>;
      default:
        return <></>;
    }
  }

  /**
   * Function to render component based on preview supported file types
   * @returns
   */
  const docViewer = () => {
    return(
      <div className={classes.docViewerWrap}>
      {isPreviewSupported ?
         fileViewer(trainingGuidesData?.contents?.[currentCard]?.contentSource)
      :
      <div className={classes.previewNotSupported}>
          <Grid>
            <PreviewNotSupportedCard loading={loading} previewCardContent={previewCardContent}/>
          </Grid>
      </div> }
      </div>
    )
  }

  const displayItems = [];
  TRAINING_GUIDES_ITEMS?.forEach((value, index) => {
    const item = createItem(value, index);
    displayItems.push(item)
  });

  /**
   * Function to render cards based on content type
   * @returns
   */
  const helpCenterCards = () => {
    return(
      <Grid>
      <GridColumn className={classes.cardContainer}>
      <LoadingCards {...loadComponentProps}>
        {trainingGuidesData?.contents?.map((item, idx) =>
        <Card size="small" className={currentCard === idx ? classes.selectedCard : classes.card} key={item?.title} onClick={() => {checkPreview(idx)
         }}>
          <Grid className={classes.cardContent}>
          <GridColumn sm={2} className={classes.article}>
            <Article/>
          </GridColumn>
          <GridColumn sm={16} className={classes.textContainer}>
            <Grid>
            <GridColumn className={classes.title}>
              {item?.title}
            </GridColumn>
            <GridColumn className={classes.subTitle}>
              {item?.description}
            </GridColumn>
            </Grid>
          </GridColumn>
          </Grid>
        </Card>
        )
      }
      </LoadingCards>
      </GridColumn>
      </Grid>
    )
  }

  return (
    <Grid className={classes.contentWrapper}>
      <GridColumn className={classes.navigation} sm={2}>
        <SideNavigation className={classes.sideNavigation}>
          {displayItems}
        </SideNavigation>
      </GridColumn>
      <GridColumn sm={1} className={classes.dividerContainer}>
          <Divider orientation="vertical"/>
      </GridColumn>
      <GridColumn sm={2} className={classes.cardWrap}>
            {helpCenterCards()}
      </GridColumn>
      <GridColumn sm={1} className={classes.dividerContainer}>
          <Divider orientation="vertical"/>
      </GridColumn>
      <GridColumn sm={12} className={classes.docViewerWrap}>
            {docViewer()}
      </GridColumn>
    </Grid>
  );
};

export default TrainingGuides;
