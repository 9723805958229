import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Caption, useSnackbar } from "@walmart-web/livingdesign-components";
import { postService } from "../../../axios/axios";
import { setMatterListingData } from '../../../store/actions/organizationAction';
import { getErrorMessage, getExportedData, getSensitiveMatterPayload, matterDataMapping } from "../../../helpers/utils";
import { tableCellDisplay } from "../../../helpers/tableUtils";
import { renderStatus } from "../../../helpers/commonUtils";
import MuiDataTable from '../../../components/Table/MuiDataTable/MuiDataTable';
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import {
  CS_DEFAULT_SORT_QUERY,
  CS_SCOPES,
  CS_SCORE_SORT_OBJ,
  MATTER_DEFAULT_SORT_QUERY,
} from "../../../constants/constants";
import {
  ENABLE_SERVER_SIDE_FEATURES_MATTER,
  ROWS_PER_PAGE_MATTER,
  ROWS_PER_PAGE_OPTIONS_DEFAULT,
  ROWS_PER_PAGE_OPTIONS_MATTER,
} from "../../../constants/tableConfigs";
import AssociatedMatterStyles from "./PeopleAssociatedMatters.styles";
import { useParams } from 'react-router-dom';
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery } from "../../../helpers/csQueryUtils";
import { isValueEmptyCheck } from "../../../helpers/validationUtils";

/**
 * Associated Matters listing component
 * contains associated matter from Matter Specific Rates Details
 */
const PeopleAssociatedMatters = () => {
	const classes = AssociatedMatterStyles();
	const dispatch = useDispatch();
	const { addSnack } = useSnackbar();
	const { peopleId } = useParams();
	const [loading, setLoading] = useState(false);
	const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);
	const matterListingData = useSelector((state) => state?.matter?.matterListingData);
	const matterAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.matters);
	const userDetails = useSelector((state) => state?.user?.userDetail?.attributes);
	const [exportLoading, setExportLoading] = useState(false)
	const [searchQuery, setSearchQuery] = useState({});
	const [filterQuery, setFilterQuery] = useState({});
	const [sortQuery, setSortQuery] = useState(MATTER_DEFAULT_SORT_QUERY);
	const [rowsPerPageMatter, setRowsPerPageMatter] = useState(ROWS_PER_PAGE_MATTER);

	/**
* Function to call Matter Search Service
* @param {string} query
*/
	const getMatterData = (paginationQuery) => {
    setLoading(true);
    const defaultQuery = {
      "filters": [
        formCsFilterObject("isActive", "match", true),
        formCsFilterObject("organizations.id", "match", identifier),
        formCsFilterObject("people.id", "match", peopleId)
      ],
      "operation": "AND",
      "properties": null
    }

		const sortQueryMatter = {...sortQuery};
		if(searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(CS_DEFAULT_SORT_QUERY)) {
      sortQueryMatter.sortBy = [CS_SCORE_SORT_OBJ, ...sortQuery?.sortBy];
    }

    let sensitiveMatterQuery = getSensitiveMatterPayload(matterAccessibility?.viewAll, userDetails?.userUniqueId);
    const query = formCompositeSearchQuery([CS_SCOPES.MATTER], filterQuery, searchQuery, sortQueryMatter, paginationQuery, {}, defaultQuery,sensitiveMatterQuery);
    postService(
      COMPOSITE_SEARCH_SERVICE,
	  `/composite-search/v1?returnCsv=false&organizationId=${identifier}&options=restrictMode`,
      query
    )
    .then((response) => {
      const matterResponse = response?.data?.matter;
      const mappedMatterResponse = matterDataMapping({count: matterResponse?.count, nodeList: matterResponse?.data});
      dispatch(setMatterListingData(mappedMatterResponse));
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      addSnack({
        message: getErrorMessage(error)
      });
    });
	};

	useEffect(() => {
    const paginationQuery = {
      "offset": 0,
      "limit": ROWS_PER_PAGE_MATTER,
    }
    getMatterData(paginationQuery);
  }, [sortQuery, searchQuery, filterQuery]);

	const displayValue = (value, tableMeta) => {
		return (
			<Caption as="p">{isValueEmptyCheck(value)}</Caption>
		)
	}

	const COLUMNS = [
		{
			name: "identifier",
			label: "identifier",
			colKey: "identifier",
			options: {
				display: false,
			}
		},
		{
			name: "matterNumber",
			label: "Matter No.",
			colKey: "matterNumber",
			options: {
				sort: true,
				customFilterListOptions: { render: (v) => `Matter No.: ${v}` },
				customBodyRender: (value, tableMeta) => tableCellDisplay(`table-row-matterNumber-${tableMeta.rowIndex}`, displayValue(value, tableMeta))
			},
		},
		{
			name: "matterName",
			label: "Matter Name",
			colKey: "matterName",
			options: {
				sort: true,
				customFilterListOptions: { render: (v) => `Matter Name: ${v}` },
			},
		},
		{
			name: "status",
			label: "Status",
			colKey: "status",
			options: {
				sort: true,
				customBodyRender: renderStatus,
				customFilterListOptions: { render: (v) => `Status: ${v}` },
			},
		},
		{
			name: "matterType",
			label: "Matter Type",
			colKey: "matterType",
			options: {
				sort: true,
			},
		},
		{
			name: "matterCreateDate",
			label: "Matter Created Date",
			colKey: "matterCreateDate",
			options: {
				sort: true
			},
		},
		{
			name: "leadAttorney",
			label: "Lead Attorney",
			colKey: "leadAttorney",
			options: {
				sort: true,
				customFilterListOptions: { render: (v) => `Lead Attorney: ${v}` },
			},
		},
		{
			name: "outsideCounsel",
			label: "Outside Counsel",
			colKey: "outsideCounsel",
			options: {
				sort: true
			},
		},
		{
			name: "facilityNumber",
			label: "Facility Number",
			colKey: "facilityNumber",
			options: {
				display: false,
				customFilterListOptions: { render: (v) => `Facility Number: ${v}` },
			},
		},
		{
			name: "state",
			label: "State",
			colKey: "state",
			options: {
				display: false,
				customFilterListOptions: { render: (v) => `State: ${v}` },
			},
		},
		{
			name: "city",
			label: "City",
			colKey: "city",
			options: {
				display: false,
				customFilterListOptions: { render: (v) => `City: ${v}` },
			},
		}
	];

	const FILTER_CONFIGS = [
		{
			colKey: "status",
			type: "checkbox",
			label: "Status",
			options: ["Open", "Closed"],
			defaultOpen: true,
		},
		{
			colKey: "matterNumber",
			type: "matterDropdownAutocomplete",
			label: "Matter Number",
			defaultOpen: true,
		},
		{
			colKey: "matterName",
			type: "matterDropdownAutocomplete",
			label: "Matter Name",
			defaultOpen: true,
		},
		{
			colKey: "leadAttorney",
			type: "peopleDropdownAutocomplete",
			label: "Lead Attorney",
			options: [],
			defaultOpen: true,
		},
		{
			colKey: "facilityNumber",
			type: "dropdownAutocomplete",
			label: "Facility Number",
			options: [],
			defaultOpen: true,
		},
		{
			colKey: "city",
			type: "dropdownAutocomplete",
			label: "City",
			options: [],
			defaultOpen: true,
		},
		{
			colKey: "state",
			type: "masterDataAutocomplete",
			label: "State",
			dataType: "state",
			defaultOpen: true,
		},
	];

	const [associatedMattersColumns, setAssociatedMattersColumns] = useState(COLUMNS);

  /**
  * Function to call api on pagination action (server side)
  * @param {number} rowsPerPage
  * @param {number} page */
  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPageMatter(rowsPerPage);
    const paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * page));
    getMatterData(paginationQuery);
  };

  /**
  * Function to validate search query and set query or show no results UI (server side)
  * @param {string} searchText
  */
  const handleServerSideSearch = (searchText) => {
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };

  /**
  *  Function to set filter query on filter action (server side)
  * @param {string} query
  */
  const handleServerSideFilter = (filterQuery) => {
    setFilterQuery(filterQuery);
  };

  /**
  * Function to call api on sort action (server side)
  * @param {string} name
  * @param {string} direction
  */
  const handleServerSideSort = ({ name, direction }) => {
    const sortQuery = formCsSortQuery(name,direction);
    setSortQuery(sortQuery);
  };

	const customOptions = {
		filterConfigs: FILTER_CONFIGS,
    rowsPerPage: rowsPerPageMatter,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_MATTER,
    totalCount: matterListingData?.count,
    isMatter: true,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_MATTER,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSideSort: handleServerSideSort,
	};

	/**
	* Gets called when clicked on export button
	*/
	const handleExport = () => {
		setExportLoading(true);
    const defaultQuery = {
      "filters": [
        formCsFilterObject("isActive", "match", true),
        formCsFilterObject("organizations.id", "match", identifier),
        formCsFilterObject("people.id", "match", peopleId)
      ],
      "operation": "AND",
      "properties": null
    }
    	let sensitiveMatterQuery = getSensitiveMatterPayload(matterAccessibility?.viewAll, userDetails?.userUniqueId);
		const exportQuery = formCompositeSearchQuery([CS_SCOPES.MATTER],  filterQuery, searchQuery, sortQuery, {},{getAll: true}, {}, defaultQuery,sensitiveMatterQuery);
    postService(
      COMPOSITE_SEARCH_SERVICE,
	  `/composite-search/v1?returnCsv=false&organizationId=${identifier}&options=restrictMode`,
      exportQuery
    )
    .then((response) => {
      const data = response?.data;
      const contentType = response?.headers["content-type"];
      getExportedData(data, contentType, "associated-matters-data");
      setExportLoading(false);
    })
    .catch((error) => {
      setExportLoading(false);
      addSnack({
        message: getErrorMessage(error)
      });
    });
	}

	return (
		<div className={classes.rowStyle} data-testid="associated-matters">
			<MuiDataTable
				columns={associatedMattersColumns}
				data={matterListingData?.matters ?? []}
				setTableColumns={setAssociatedMattersColumns}
				customOptions={customOptions}
				showRowsCounter={false}
				loading={loading}
				onExportClick={handleExport}
				exportLoading={exportLoading}
        hideAddNewButton={true}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DEFAULT}
			/>
		</div>
	);
};

export default PeopleAssociatedMatters;
