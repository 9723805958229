const types = {
  SET_INPUT_VALUE: 'SET_INPUT_VALUE',
  SET_NOTE_DETAIL: 'SET_NOTE_DETAIL',
  RESET_NOTE_DETAIL: 'RESET_NOTE_DETAIL',
  SET_MATTER_NOTE_DATA: "SET_MATTER_NOTE_DATA",
  SET_PUBLIC_NOTE_DATA: "SET_PUBLIC_NOTE_DATA",
  SET_PRIVATE_NOTE_DATA: "SET_PRIVATE_NOTE_DATA"
}

export default types;
