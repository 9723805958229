import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import {
  TextField,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { debounce } from '@mui/material/utils';
import { createUseStyles } from "react-jss";
import {
  CS_IS_EXTERNAL_USER_FILTER, CS_SCOPES,
  CS_TYPEAHEAD_PAGINATION_QUERY,
  CS_IS_TIMEKEEPER_ROLE_ENABLED_FILTER,
  PEOPLE_AUTOCOMPLETE_DEFAULT_SORT_QUERY
} from "../../../constants/constants";
import { formCompositeSearchQuery, formCsFilterObject, formCsInvoiceTimekeeperAutocompleteQuery } from "../../../helpers/csQueryUtils";
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import { postService } from "../../../axios/axios";
import { getHighlightedText, renderTernary } from "../../../helpers/utils";
import { LOADING, NO_OPTIONS } from "../../../constants/messages";

const createAutoStyles = createUseStyles({
  displayBlock: {
    display: "block"
  },
  boxLi: {
    overflow: "hidden",
    cursor: "pointer",
    paddingTop: "6px",
    boxSizing: "border-box",
    outline: 0,
    paddingBottom: "6px",
    paddingLeft: "16px",
    paddingRight: "16px",
    '&:hover': {
      background: "rgba(0, 0, 0, 0.04)"
    }
  }
})

/**
 * Component for timekeeper autocomplete in Invoice Lineitem
 * @param {*} props
 * @returns
 */
const InvoiceTimekeeperAutocomplete = (props) => {
  const classes = createAutoStyles();
  const [open, setOpen] = useState(false);
  const { externalPeopleDropDownData } = useSelector((state) => state?.masterData);
  const [tkList, setTkList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    name,
    value,
    setValue,
    label,
    placeholder,
    helperText,
    handleBlur,
    tableMeta,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading]=useState();
  const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);
  const {pureAdminRole} = useSelector((state) => state?.user?.featureMap);

  /**
   * Function to call associate peoplelist Service
   * @param {String} text
   */
  const getPeopleData = (text) => {
    setLoading(true);
    const filter = [
      CS_IS_EXTERNAL_USER_FILTER,
      formCsFilterObject("organizations.id", "match", identifier)
    ]
    pureAdminRole && filter.push(CS_IS_TIMEKEEPER_ROLE_ENABLED_FILTER);
    const defaultQuery = {
      "filters": filter,
      "operation": "AND",
      "properties": null
    }

    const searchQuery = formCsInvoiceTimekeeperAutocompleteQuery(text);
    const query = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, PEOPLE_AUTOCOMPLETE_DEFAULT_SORT_QUERY, CS_TYPEAHEAD_PAGINATION_QUERY, {}, defaultQuery, searchQuery);

    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=false`,
      query
    )
      .then((res) => {
        setTkList(res?.data?.people?.data || [])
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // Debounce logic to form search query
  const fetch = useMemo(() =>
    debounce((searchText) => {
      if (searchText.trim() !== '' && searchText?.length > 2) {
        getPeopleData(searchText);
      } else {
        // reset with initial set of data from store
        setTkList(externalPeopleDropDownData?.nodeList || []);
      }
    }, 600),
    [],
  );

  // Fetch results on search
  useEffect(() => {
    fetch(inputValue)
  }, [inputValue, fetch]);

  // Populate with inital set of data from store
  useEffect(() => {
    if(externalPeopleDropDownData?.nodeList?.length > 0) {
      setTkList(externalPeopleDropDownData?.nodeList)
    }
  }, [externalPeopleDropDownData?.nodeList]);

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option?.firstName ?? ''} ${option?.lastName ?? ''} (${option?.tkId ?? ''})`
  });

  return (
    <Autocomplete
      open={open}
      onOpen={() => { setOpen(true); }}
      onClose={() => { setOpen(false); }}
      name={`${name}-autocomplete`}
      data-testid={`${name}-autocomplete`}
      id={`${name}-autocomplete`}
      autoHighlight
      autoComplete
      options={tkList}
      getOptionLabel={(option) => (option?.tkId ?? '')}
      renderOption={(props, option) => (
        <li {...props} className={classes.boxLi} key={option?.id}>
        { getHighlightedText(`${option?.firstName ?? ''} ${option?.lastName ?? ''} (${option?.tkId ?? ''})`,inputValue) }
        </li>
      )}
      onInputChange={(event, newInputValue) => {
        if(!((!event) || (event?.type === "click" && newInputValue !== ""))) {
					setInputValue(newInputValue);
					setLoading(true)
					fetch(newInputValue)
				}
			}}
      noOptionsText={renderTernary(loading, LOADING, NO_OPTIONS)}
      filterOptions={filterOptions}
      value={value}
      onChange={(e, v) => {
        setSelectedOption(v);
        setValue(v)
      }}
      onBlur={() => handleBlur(name, tableMeta, selectedOption)}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params?.inputProps }}
          margin="dense"
          label={label}
          placeholder={placeholder}
          // helperText={helperText}
          variant="outlined"
          error={Boolean(helperText)}
        />
      )}
    />
  );
};

export default InvoiceTimekeeperAutocomplete;

InvoiceTimekeeperAutocomplete.propTypes = {
  /** pass the key name */
  name: PropTypes.string.isRequired,
  /** pass the selected value */
  value: PropTypes.object,
  /** callback to set value */
  setValue: PropTypes.func,
  /** pass the label text */
  label: PropTypes.string,
  /** pass the placeholder text */
  placeholder: PropTypes.string,
  /** pass the helper text */
  helperText: PropTypes.string,
  /** callback to handle on blur */
  handleBlur: PropTypes.func,
  /** pass the tablemeta data*/
  tableMeta: PropTypes.object
}
