const types = {
  SET_USER_LOGIN_DETAILS: 'SET_USER_LOGIN_DETAILS',
  SET_USER_LOGIN_TENANT_ID: 'SET_USER_LOGIN_TENANT_ID',
  SET_USER_RESPONSIBILITY_CODES: 'SET_USER_RESPONSIBILITY_CODES',
  SET_LOGOUT: 'SET_LOGOUT',
  SET_STATIC_TK: 'SET_STATIC_TK',
  SET_AUTH_SIGN : "SET_AUTH_SIGN",
  SET_AUTH_TK : "SET_AUTH_TK",
  SET_TKN_DETAILS : "SET_TKN_DETAILS",
  SET_USER_REQUEST_ID: "SET_USER_REQUEST_ID",
  SET_FEATURE_FLAGS : "SET_FEATURE_FLAGS",
  SET_RELEASE_NOTES_DATA: "SET_RELEASE_NOTES_DATA"
}

export default types;
