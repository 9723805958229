import { Alert, Body, Panel } from "@walmart-web/livingdesign-components";
import React from "react";
import WarningPanelStyles from "./WarningPanelStyles.Styles";

const WarningPanel = (props) => {
  const { isOpen, setIsOpen, title, description, alertMessage, children } = props
  const classes = WarningPanelStyles();

  /*
  * Function to close the panel
  */
  const handleClosePanel = () => {
    setIsOpen(false)
  }

  return (
    <Panel
      isOpen={isOpen}
      onClose={handleClosePanel}
      position="right"
      size="medium"
      title={title}
    >
      <div className={classes.alertWrap}>
        <Alert
          variant="info"
        >
          {alertMessage}
          <div className={classes.textStyle}>
            <Body as="p" size="medium" weight={700}>
              {description}
            </Body>
          </div>
        </Alert>
      </div>
      {children}
    </Panel >
  )
}

export default WarningPanel
