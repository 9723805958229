import { Grid, Spinner } from '@walmart-web/livingdesign-components';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IFrameViewerStyles from './IFrameViewer.styles';
import PropTypes from 'prop-types';

/**
 *
 * @param {*} props
 * @returns
 */
const IFrameViewer = (props) => {
    const classes = IFrameViewerStyles();
    const {currentCard,loading} = props
    const {trainingGuidesData} = useSelector((state) => state?.helpCenter)
    const [url,setUrl] = useState();

    useEffect(() => {
       setUrl(trainingGuidesData?.contents?.[currentCard]?.location);
      }, [currentCard]);

    return (
        (loading) ?
        <Spinner size="large" className={classes.spinner}/>
        :
        <Grid className={classes.docContent}>
            <iframe src={url} frameborder="0" allowFullScreen className={classes.video}></iframe>
        </Grid>)
}
 export default IFrameViewer;

IFrameViewer.propTypes = {
    currentCard: PropTypes.number,
    loading: PropTypes.bool
 }