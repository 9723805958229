import { createUseStyles } from "react-jss";
import { LIGHT_GRAY } from "../../constants/colors";

const JobStatusListStyle = createUseStyles({
  listing: {
    backgroundColor: LIGHT_GRAY,
    padding: "25px 30px",
    flex: 1,
    maxWidth: "100%",
    fontFamily: 'Bogle',
    "& > div > div > div.MuiToolbar-root:first-child":{
      display: 'none'
    },
    "& table > thead > tr > th.MuiTableCell-head": {
    fontFamily: 'Bogle',
    paddingTop:'16px'
    },
    "& table > tbody > tr > td.MuiTableCell-root": {
      fontFamily: 'Bogle',
    }
  },
  pageTitle: {
    fontSize: 36,
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    fontSize: "14px",
  },
  header: {
    paddingLeft: 16,
  },
  breadcrumb: {
    marginBottom: "26px",
  },
  parentHeader: {
    display: "flex",
    margin: '16px 10px',
    width: "100%",
    justifyContent: "space-between",
    paddingBottom: "12px"
  },
  refreshButton: {
    marginTop:"-8px",
    marginRight:"20px",
  },
});

export default JobStatusListStyle;