import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  Grid,
  GridColumn,
  Body,
  Breadcrumb,
  BreadcrumbItem,
  Heading,
  Button,
  useSnackbar,
  Skeleton
} from "@walmart-web/livingdesign-components";
import DocumentDropzone from "../../components/DocumentDropzone/DocumentDropzone";
import TagAutocomplete from "../../components/Autocomplete/TagAutocomplete/TagAutocomplete";
import {
  resetDocumentUploadData,
  setFolderSearchQuery,
  setDocumentBreadcrumbIndex
} from "../../store/actions/documentActions";
import { SPACING } from "../../constants/styleGuide";
import {
  ADD_NEW_DOCS,
  ADD_NEW_DOCS_TAG_LINE,
  ACCEPTED_FILES_LABEL,
  acceptedFilesForDocuments,
  SELECT_TAGS,
  DOCUMENT_UPLOAD_TAGS_TITLE,
  MAX_FILE_UPLOAD_SIZE,
} from "../../constants/constants";
import { postServiceWithHeaders } from "../../axios/axios";
import { NODE_JS_URL } from "../../constants/baseURLs";
import { getErrorMessage, renderTernary } from "../../helpers/utils";
import { GO_BACK, SAVE } from "../../constants/actionButtons";
import {
  FILE_UPLOAD_SIZE_LIMIT_MSG,
  FILE_UPLOAD_MSG
} from "../../constants/messages";
import { LIGHT_DARK_BLACK } from "../../constants/colors";

const useStyles = createUseStyles({
  rowStyle: {
    "& div>table>tbody>tr>td": {
      borderBottom: "none",
      fontFamily: "Bogle",
      height: "24px",
      fontWeight: "normal",
      fontStrech: "normal",
      lineHeight: "1.33",
    },
    '& table': {
      '& tr': {
        '& th' : {
          color: '#2E2F32',
          fontWeight: 'bold',
          fontSize: 12,
        },
      },
    }
  },
  addDocWrap : {
    flex: 1,
    paddingLeft: SPACING.s32,
    paddingRight: SPACING.s32,
    paddingTop: SPACING.s24,
  },
  addDocTitle: {
    fontSize: "36px",
    lineHeight: "1",
    margin: "32px 0 24px"
  },
  addDocTitleWrap: {
    marginLeft: "14px"
  },
  folderTags: {
    marginRight: '5px'
  },
  dropzoneWrap: {
    background: "#fff",
    padding: "40px 20px",
    marginBottom: '40px',
    "& .MuiTypography-subtitle1": {
      color: LIGHT_DARK_BLACK,
      margin: "15px 0 50px",
      display: "block",
      fontWeight: "bold",
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      '& .MuiTypography-body1': {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "none",
      },
      '&:hover span:last-child': {
        display: "block",
      }
    },
    "& .MuiDropzonePreviewList-removeButton": {
      boxShadow: "none",
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' fill='currentColor' data-testid='banner-close' aria-hidden='true' role='presentation' style='font-size: 1rem; vertical-align: -0.175em;'%3E%3Cpath d='M3.05 3.05a.5.5 0 0 1 .707 0L8 7.293l4.243-4.243a.5.5 0 0 1 .707.707L8.707 8l4.243 4.243a.5.5 0 0 1-.707.707L8 8.707 3.757 12.95a.5.5 0 0 1-.707-.707L7.293 8 3.05 3.757a.5.5 0 0 1 0-.707z' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "12px 12px",
      backgroundPosition: "center",
      top: "8px",
      right: "8px",
      "& .MuiSvgIcon-root": {
        display: "none"
      },
    }
  },
  formFieldsWrap: {
    paddingLeft: "9%"
  },
  root: {
    "& .MuiInputLabel-formControl": {
      fontFamily: "Bogle"
    }
  },
  newDocTitle: {
    fontSize: "24px",
    margin: "24px 0"
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  contentSpacing12: {
    paddingTop: SPACING.s12,
  },
  uploadButtonWrap: {
    borderTop: "1px solid #e3e4e5",
    marginTop: "32px",
  },
  cancelButton: {
    marginRight: SPACING.s16,
  },
  addDocTagline: {
    fontSize: "14px",
    marginBottom: "24px",
    lineHeight: "1",
  },
  tagLabelText: {
    fontSize: "14px"
  }
});

/**
 * Function to form and return file data required for MUI datatable
 * @param {string} files
 * @param {string} matterSubFolder
 * @param {array} documentTag
 * @returns array of files data
 */
export const formDataTableData = (files, matterSubFolder, documentTag) => {
  const list = [];
  files?.map(item => {
    list.push({
      id: item?.name?.split('.').slice(0, -1).join('.'),
      fileName: item?.name?.split('.').slice(0, -1).join('.'),
      folder: matterSubFolder,
      size: item?.size,
      tags: documentTag
    })
  })
  return list;
}

/**
 * Component for Document upload
 */
const DocumentUpload = (props) => {
  const classes = useStyles();
  const {state : { newFolderName}  = {} } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { uploadedDocumentData } = useSelector((state) => state?.document);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesFormData, setFilesFormData] = useState();
  const [documentTag, setDocumentTag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [fileReadLoading, setFileReadLoading] = useState(false);
  const { addSnack } = useSnackbar();
  const documentPath = useSelector((state) => state?.document?.documentPath);
  const documentMatterDetails = useSelector((state) => state?.document?.documentMatterDetails);
  const breadCrumbFolders = useSelector((state) => state?.document?.breadCrumbFolders);

  const folderPath = `${documentPath}${newFolderName ? newFolderName+'/' : ''}`;
  const masterDetails = useSelector(state => state?.masterData?.masterDatas);
  const sortedTagsData = masterDetails?.documentTags?.masterData?.sort((a, b) => a?.value?.localeCompare(b?.value)) ?? [];

  /**
   * Callback to handle when file is dropped in dropzone
   * @param {array} files
   */
  const handleFileUpload = (files) => {
    let newFiles = files;
    if(files && files?.length > 0) {
      newFiles = files?.slice(uploadedDocumentData?.uploadedDocs?.length, files?.length)
      setFilesFormData(newFiles);
    }
    const uploadedDocs = formDataTableData(newFiles, newFolderName, documentTag);
    setFilesToUpload(uploadedDocs)
  }

  useEffect(() => {
    if(filesToUpload?.length > 0) {
      const totalUploadSize = filesToUpload.reduce((acc, obj) => acc + obj['size'], 0);
      setDisableSaveButton(totalUploadSize > MAX_FILE_UPLOAD_SIZE)
      if(totalUploadSize > MAX_FILE_UPLOAD_SIZE) {
        addSnack({
          message: FILE_UPLOAD_SIZE_LIMIT_MSG
        });
      }
    } else {
      setDisableSaveButton(true)
    }
  }, [filesToUpload])

  /** Callback function to handle file drop */
  const handleOnDrop = () => {
    setFileReadLoading(true)
  }

  /**
   * Function to reset folder field and tags fields
   */
  const resetSubFields = () => {
    setDocumentTag([])
  }

  /**
   * Function to delete all the uploaded files and reset the screen
   */
  const handleCancelUpload = () => {
    dispatch(resetDocumentUploadData());
    setKey(key+1)
    resetSubFields()
    history.push(`/matter/${documentMatterDetails?.identifier}`,{currentMatterTab: 2});
  }

  /**
   * Function to return back to previous screen on save button click
   */
  const handleSaveUpload = () => {
    if(filesFormData?.length > 0) {
      const promises = [];
      filesFormData?.forEach((file)=>{
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file); // file in binary format
        formData.append('metadata',  // adding metadata for file
          JSON.stringify({
            "IL_OBJECT_ID": documentMatterDetails?.identifier,
            "IL_OBJECT_TYPE": "MATTER",
            isPublic: true, // document will be public by default
            "matterNumber": documentMatterDetails?.matterNumber,
            "tags": documentTag,
            "IL_SYS_FOLDER": folderPath,
            "practiceArea": documentMatterDetails?.practiceArea,
            "matterName": documentMatterDetails?.matterName,
          })
        );
        const headers = {
          'Content-Type': `multipart/form-data`,
        }
        promises.push(postServiceWithHeaders(NODE_JS_URL, "/upload", formData,headers));
        // in case of multiple files
        Promise.all(promises)
        .then((res)=>{
          setLoading(false);
          addSnack({
            message: FILE_UPLOAD_MSG
          });
        })
        .catch((error) => {
          setLoading(false);
          addSnack({
            message: getErrorMessage(error)
          })
        });
      })
    }
  }

  /**
   * Reset documents list in store and clear all fields & datatable
   */
  useEffect(() => {
    dispatch(resetDocumentUploadData());
    setKey(key+1)
    resetSubFields();
  }, [])

  /*
  * function to handle matter number navigation back to parent component
  */

  const handleMatterBreadCrumb = () => {
      history.push(`/matter/${documentMatterDetails?.identifier}`, { currentMatterTab: 2 })
  }


   /*
  * function to handle folder navigation back to parent component
  */
  const handleFolderBreadCrumbs = ( index ) => {
    dispatch(setDocumentBreadcrumbIndex(index));
    dispatch(setFolderSearchQuery(breadCrumbFolders?.[index]?.value));
    history.push(`/matter/${documentMatterDetails?.identifier}`,{ currentMatterTab: 2 })
  }

  return (
    <Grid className={classes.addDocWrap} data-testid="document-upload">
      <GridColumn sm={12}>
          <Breadcrumb>
            <BreadcrumbItem data-testid="breadcrumb-matter" onClick={() =>  history.push('/')}>{'Matter'}</BreadcrumbItem>
            <BreadcrumbItem data-testid="breadcrumb-matterNumber" onClick={() =>handleMatterBreadCrumb()}>{documentMatterDetails?.matterNumber}</BreadcrumbItem>
            {
              breadCrumbFolders?.map((item, idx) =>
                <BreadcrumbItem data-testid={`breadcrumb-matter-${item}`} key={`${item?.label}`} onClick={(() => handleFolderBreadCrumbs(idx))}>{item?.label}</BreadcrumbItem>)
            }
            <BreadcrumbItem href="">Add New Document</BreadcrumbItem>
          </Breadcrumb>
        <div className={classes.addDocTitleWrap}>
          <Heading as="h1" size="large" className={classes.addDocTitle}>
            {ADD_NEW_DOCS}
          </Heading>
          <Body as="p" size="small" className={classes.addDocTagline}>
            {ADD_NEW_DOCS_TAG_LINE}
          </Body>
        </div>
        <div className={classes.dropzoneWrap}>
          <Grid>
            <GridColumn lg="7" data-testid="document-dropzone" id="document-dropzone">
            {
              renderTernary(loading ,
                <Skeleton  height={"281px"}/> ,
              <DocumentDropzone
                key={key}
                acceptedFilesLabel={ACCEPTED_FILES_LABEL}
                acceptedFileTypes={acceptedFilesForDocuments}
                onFileUpload={handleFileUpload}
                onFileDrop={handleOnDrop}
                fileReadLoading={fileReadLoading}
                fileDropStatus={true}
                showFilePreview={true}
                hideInfoMsg={true}
                />)
              }
            </GridColumn>
            <GridColumn lg="5" className={classes.formFieldsWrap}>
              <Grid>
                <GridColumn lg="5">
                  <strong>Assosciated Matter:</strong>
                  </GridColumn>
                <GridColumn lg="7">
                  {  loading ?   <Skeleton  height={"18px"}/> : documentMatterDetails?.matterNumber  ?? 'None' }
                </GridColumn>
              </Grid>
              <Grid className={classes.contentSpacing16}>
                <GridColumn lg="5">
                  <strong>Current Folder:</strong>
                </GridColumn>
                <GridColumn lg="7">
                  {loading ?   <Skeleton  height={"18px"}/> : newFolderName}
                </GridColumn>
              </Grid>
              <div className={classes.contentSpacing16}>
                <Divider />
              </div>
              <div className={classes.contentSpacing16}>
                <Body as="p" size="small" className={classes.tagLabelText}>
                  {DOCUMENT_UPLOAD_TAGS_TITLE}
                </Body>
              </div>
              <div className={classes.contentSpacing12}>
                {
                loading ? <Skeleton height={"60px"} /> :
                  <TagAutocomplete
                    placeholder={SELECT_TAGS}
                    disabled={false}
                    value={documentTag}
                    setValue={(v) => setDocumentTag(v)}
                    tagData={sortedTagsData?.map(val => val?.value)}
                  />
                }
              </div>
            </GridColumn>
          </Grid>
          <Grid align="right">
            <GridColumn>
              <div className={`${classes.contentSpacing24} ${classes.uploadButtonWrap}`}>
                <Button
                  size="medium"
                  variant="secondary"
                  className={classes.cancelButton}
                  onClick={handleCancelUpload}
                  id="cancel-upload"
                  data-testid="cancel-upload">
                    {GO_BACK}
                </Button>
                {renderTernary(filesToUpload?.length > 0,
                  <Button
                    size="medium"
                    variant="primary"
                    disabled={disableSaveButton || fileReadLoading || loading}
                    onClick={handleSaveUpload}
                    data-testid="save-upload"
                    id="save-upload">
                      {SAVE}
                  </Button>, <></>
                )}
              </div>
            </GridColumn>
          </Grid>
        </div>

      </GridColumn>
    </Grid>
  );
};

export default DocumentUpload;
