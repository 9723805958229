export const linkMatterModalDescription = ({matterNumber, matterName}) => `The below matter(s) will be linked to ${matterNumber}, ${matterName}`
export const invoiceUploadSuccessModalText = ({count}) => `${count} file(s) are validated and submitted. You will receive an email upon completion.`;
export const invoiceUploadPartialSuccessText1 = ({successCount}) => `${successCount} file(s) have been successfully validated and are currently being processed. You will receive an email notification upon completion.`
export const invoiceUploadPartialSuccessText2 = ({count}) => `Below ${count} file(s) needs your attention`;
export const copyRightText = (year) =>`© ${year} Walmart. All Rights Reserved.`;
export const invoiceErrorMessage = ({value,index}) => `${index+1}. Invoice: ${value?.invoiceNumber} , Line Item: ${value?.lineitemNumber}  -  ${value?.errorMessage} `
export const getInvalidInputMessage = ({email}) => `${email} is not onboarded to eWMMS at the moment. Please contact system administrator`
export const getLoginFailedMessageWithForgotPassword = (failedMsg) => `${failedMsg}.\nForgot your password? Please reset using Forgot Password option below`


