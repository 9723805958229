import {
  Grid,
  GridColumn,
  Heading, IconButton, Skeleton, TabNavigation,
  TabNavigationItem,
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import { Pencil } from "@walmart-web/livingdesign-icons";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import OrganizationMatters from "./OrganizationMatters/OrganizationMatters";
import OrganizationPeople from "./OrganizationPeople/OrganizationPeople";
import OrganizationDetails from "./OrganizationDetails/OrganizationDetails";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMessage, getTabList } from "../../helpers/utils";
import { setOrgDetailValues } from "../../store/actions/organizationAction";
import { getService } from "../../axios/axios";
import { ORGANIZATION_SERVICE } from "../../constants/baseURLs";
import OrganizationDetailViewStyles from "./ViewOrganization.styles";

const ViewOrganization = () => {
  const classes = OrganizationDetailViewStyles();
	const [loading, setLoading] = useState(false)
  const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.organization);
	const { listOfTabs, tabIndexMap } = getTabList(accessibility?.tabs);

  const { addSnack } = useSnackbar();
  const dispatch = useDispatch();
  const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);

  const getOrganizationData = () => {
    setLoading(true)
    getService(ORGANIZATION_SERVICE, `/organizations/v1/${identifier}`)
      .then((res) => {
        setLoading(false)
        const detail = res?.data?.result?.organization;
        dispatch(setOrgDetailValues(detail));
      })
      .catch((error) => {
        setLoading(false)
        addSnack({
          message: getErrorMessage(error)
        });
      });
  }

  /*
  * useEffect Hook to call the get organization service on initial load
  */
  useEffect(() => {
    getOrganizationData()
  }, [])

  return (
    <div className={classes.listing}>
      <HeaderSection loading={loading} accessibility={accessibility}/>
      <TabsLayout loading={loading} listOfTabs={listOfTabs} tabIndexMap={tabIndexMap}/>
    </div>
  );
};

const HeaderSection = (props) => {
  const {loading, accessibility}=props;
  const classes = OrganizationDetailViewStyles();
  const history = useHistory();
  const { companyName, website, identifier } = useSelector((state) => state?.organization?.organizationDetailValues);
  const handleEdit = () => {
    history.push(`/organization/${identifier}`);
  }

  return (
    <div>
      <Grid>
        <GridColumn sm={7}>
          <Heading as="h1" size="large" className={classes.pageTitle} id="companyName">
          {loading ? <Skeleton width="150px" height="15px" /> : companyName}
          </Heading>
        </GridColumn>
        <GridColumn sm={5}>
          {accessibility?.edit &&
            <div className={classes.icons}>
              <IconButton a11yLabel={"edit"} size="medium" onClick={handleEdit} id="edit-btn">
                <Pencil size="medium" />
              </IconButton>
            </div>
          }
        </GridColumn>
      </Grid>

      <div className={classes.gridLayout}>
        <Grid>
          <GridColumn sm="2" className={classes.pageInfo}>
            <div className={classes.label}>
              <Heading as="h1" size="small" weight={400}>
                Website
              </Heading>
            </div>
            <div className={classes.website} >
              <Heading as="h1" size="small" id="website">
              {loading ? <Skeleton width="150px" height="15px" /> : website}
              </Heading>
            </div>
          </GridColumn>
        </Grid>
      </div>
    </div>
  );
};

const TabsLayout = (props) => {
  const {loading, listOfTabs, tabIndexMap}=props;
  const {state : {selectedTab, selectedPeopleTab}={}} = useLocation();
  const [isCurrent, setIsCurrent] = useState(selectedTab ? selectedTab : tabIndexMap[listOfTabs[0]]);
  const classes = OrganizationDetailViewStyles();
  const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);

  return (
    <div className={classes.tabContainer}>
      <TabNavigation>
        {listOfTabs.map((item) => (
          <TabNavigationItem
              onClick={() => setIsCurrent(tabIndexMap[item])}
              isCurrent={isCurrent === tabIndexMap[item]}
              key={tabIndexMap[item]}
              data-testid={item?.split(" ").join("-")}
              id={item?.split(" ").join("-")}
              UNSAFE_className={loading ? classes.disableTabs: ""}
          >
            {item}
          </TabNavigationItem>
        ))}
      </TabNavigation>
      {TabContent(isCurrent, loading, identifier, selectedPeopleTab)}
    </div>
  );
};

const TabContent = (isCurrent, loading, identifier, selectedPeopleTab) => {
  switch (isCurrent) {
    case 0:
      return <OrganizationDetails loading={loading} selectedTab={isCurrent} organizationId={identifier}/>;
    case 1:
      return <OrganizationPeople selectedTab={isCurrent} selectedPeopleTab={selectedPeopleTab} organizationId={identifier}/>;
    case 2:
      return <OrganizationMatters selectedTab={isCurrent}/>;
    default:
      return null;
  }
};

export default ViewOrganization;
