import types from "../types/helpCenterActionTypes";

export const initialState = {
  trainingGuidesData: [],
}

const helpCenterReducer = (state = initialState, action) => {
  if (action.type === types.SET_TRAINING_GUIDES_DATA) {
    return {
      ...state,
      trainingGuidesData: action.payload,
    };
  }
  else {
    return state;
  }
}

export default helpCenterReducer;
