import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import DocumentList from "../DocumentListing/DocumentList";
import { CREATED_BY } from "../../../constants/constants";
import withStarredItemsList from "../../Hoc/withStarredItemsList";
import { setStarredItemsIds } from "../../../store/actions/preferencesActions";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  wrapper: {
    background: "#F5F5F5",
    width: "100%"
  }
});

/**
 * Matter starred Documents tab component
 */
const MatterStarredDocumentsTab = () => {

  const classes = useStyles();
  const dispatch = useDispatch()
  const starredItemsIds = useSelector(state => state?.preferences?.starredItemsIds);
  const documentMatterDetails = useSelector((state) => state?.document?.documentMatterDetails);
  const [queryParams, setQueryParams] = useState(generateQuery);
  const MATTER_FILE_FILTER_CONFIG = [
    {
      colKey: "tags",
      type: "masterDataMultiAutocomplete",
      label: "Tagged with",
      dataType: "documentTags",
      defaultOpen: true,
      section: "left"
    },
    {
      colKey: "createdBy",
      type: "peopleMultiDropdownAutocomplete",
      label: CREATED_BY,
      options: [],
      defaultOpen: true,
      section: "left"
    },
    {
      colKey: "createdTimeStamp",
      type: "rangePicker",
      label: "Created Date Range",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Created Date Range'
    },
    {
      colKey: "version",
      type: "textField",
      label: "Version",
      section: "right",
      defaultOpen: true,
      id :"version"
    },
  ];

  useEffect(() => {
    setQueryParams(generateQuery());
  }, [starredItemsIds]);

  useEffect(() => {
    return () => {
      dispatch(setStarredItemsIds([]));
    }
  }, [])

  function generateQuery () {
    const queryParamList = [];
    if(starredItemsIds && starredItemsIds[0]){
      starredItemsIds.forEach(element => {
        const query = {
          "key": "fileID",
          "operation": "match",
          "value": element
        }
        queryParamList.push(query);
      });
      if(queryParamList.length>0){
        return [
          {
            "key": "metaData.IL_OBJECT_ID",
            "operation": "match",
            "value": documentMatterDetails?.identifier
          },
          {
            "query": {
              "condition": "OR",
              "queryParameters": queryParamList
            },
          }
        ];
      }
  }
  else{
      return []
    }
  }


  return (
    <div className={classes.wrapper}>
          <DocumentList
            defaultQuery={queryParams}
            filterConfigs={MATTER_FILE_FILTER_CONFIG}
            fromMatter={true}
            hideAddNewButton={true}
          />
    </div>
  );
};

export default withStarredItemsList(MatterStarredDocumentsTab, 'starredDocuments');

