import types from '../types/timeKeeperAdminTypes';

export const setInputValue = (name, value) => {
  return { type: types.SET_INPUT_VALUE, payload: { name, value } }
}
export const setInputValuePracticeArea = (practiceAreaRate) => {
  return { type: types.SET_INPUT_VALUE_PRACTICE_AREA, payload: {practiceAreaRate } }
}

export const setPracticeAreaNew = (value) => {
  return { type: types.SET_PRACTICE_AREA_NEW, payload: { value } };
}

export const resetTimeKeeperAdminValue = () => {
  return {type: types.RESET_TIMEKEEPER_VALUES}
}

export const setTimekeeperDetail = (value) => {
  return { type: types.SET_TIMEKEEPER_DETAIL, payload: { value } };
}
