import {
  Divider,
  Heading, TabNavigation,
  TabNavigationItem
} from '@walmart-web/livingdesign-components';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LINE_ITEM_TABS } from "../../../../constants/constants";
import InvoiceLineItemsStyles from "./InvoiceLineItems.styles";
import LineItemDatatable from './LineItemDataTable';
import {
  resetNonAdjustedPaginationForLineItems,
  resetAdjustedPaginationForLineItems
} from "../../../../store/actions/invoiceActions";

const TabContent = ({ isCurrent, isAdjusted, invoiceStatus, fromMatter, currentMatterTab, loading }) => {
  const renderFeeDatatable=()=>{
    return<LineItemDatatable
    isCurrent={isCurrent}
    invoiceStatus={invoiceStatus}
    isAdjusted={isAdjusted}
    lineItemType={"Fees"}
    fromMatter={fromMatter}
    currentMatterTab={currentMatterTab}
    parentLoading={loading}
  />
  }
  switch (isCurrent) {
    case "Fees":
      return renderFeeDatatable()
    case "Expenses":
      return <LineItemDatatable
        isCurrent={isCurrent}
        invoiceStatus={invoiceStatus}
        isAdjusted={isAdjusted}
        lineItemType={"Expense"}
        fromMatter={fromMatter}
        currentMatterTab={currentMatterTab}
        parentLoading={loading}
      />
    default:
      return renderFeeDatatable()
  }
}

const NonAdjustedTabLayout = ({ loading, fromMatter, currentMatterTab, invoiceStatus }) => {
  const classes = InvoiceLineItemsStyles();
  const nonAdjustedLineItemsPaginationDetail = useSelector((state) => state?.invoice?.nonAdjustedLineItemsPaginationDetail);
  const [nonAdjustedCurrentTab, setNonAdjustedCurrentTab] = useState(nonAdjustedLineItemsPaginationDetail?.tabName ? nonAdjustedLineItemsPaginationDetail?.tabName: "Fees");
  const dispatch = useDispatch();
  const handleTabChange = (item) => {
    setNonAdjustedCurrentTab(item)
    dispatch(resetNonAdjustedPaginationForLineItems())
  }
  return (
    <div className={classes.lineItemTabNav}>
      <TabNavigation>
        {LINE_ITEM_TABS.map((item) => (
          <TabNavigationItem
            onClick={() => handleTabChange(item)}
            isCurrent={nonAdjustedCurrentTab === item}
            key={item}
            data-testid={`invoice-${item.split(" ").join("-")}`}
            id={`invoice-line-item-${item.split(" ").join("-")}`}
            className={classes.lineItemTabNav}
          >
            {item}
          </TabNavigationItem>
        ))}
      </TabNavigation>
      <TabContent isAdjusted={false} isCurrent={nonAdjustedCurrentTab} invoiceStatus={invoiceStatus} fromMatter={fromMatter} currentMatterTab={currentMatterTab} loading={loading}/>
    </div>
  );
};

const NonAdjustedLineItems = ({ loading, fromMatter, currentMatterTab, invoiceStatus }) => {
  const classes = InvoiceLineItemsStyles();
  return (
    <div data-testid="lineitems-datatable-wrap">
      <div className={classes.invoiceTitleWrap}>
        <Heading as="h1" size="medium" weight={700}>
          Line Items
        </Heading>
      </div>
      <NonAdjustedTabLayout loading={loading} fromMatter={fromMatter} currentMatterTab={currentMatterTab} invoiceStatus={invoiceStatus}/>
    </div>
  )
}


const AdjustedTabLayout = ({ loading, fromMatter, currentMatterTab, invoiceStatus }) => {
  const adjustedLineItemsPaginationDetail = useSelector((state) => state?.invoice?.adjustedLineItemsPaginationDetail);
  const [adjustedCurrentTab, setAdjustedCurrentTab] = useState(adjustedLineItemsPaginationDetail?.tabName ? adjustedLineItemsPaginationDetail?.tabName: "Fees");
  const classes = InvoiceLineItemsStyles();
  const handleTabChange = (item) => {
    setAdjustedCurrentTab(item)
    dispatch(resetAdjustedPaginationForLineItems())
  }
  const dispatch = useDispatch();
  return (
    <div className={classes.lineItemTabNav}>
      <TabNavigation>
        {LINE_ITEM_TABS.map((item) => (
          <TabNavigationItem
            onClick={() => handleTabChange(item)}
            isCurrent={adjustedCurrentTab === item}
            key={item}
            data-testid={`invoice-${item.split(" ").join("-")}`}
            id={`invoice-${item.split(" ").join("-")}`}
            className={classes.lineItemTabNav}
          >
            {item}
          </TabNavigationItem>
        ))}
      </TabNavigation>
      <TabContent isAdjusted={true} isCurrent={adjustedCurrentTab} invoiceStatus={invoiceStatus} fromMatter={fromMatter} currentMatterTab={currentMatterTab} loading={loading}/>
    </div>
  );
};

const AdjustedLineItems = ({ loading, fromMatter, currentMatterTab, invoiceStatus }) => {
  const classes = InvoiceLineItemsStyles();
  return (
    <div data-testid="lineitems-datatable-wrap">
      <div className={classes.invoiceTitleWrap}>
        <Heading as="h1" size="medium" weight={700}>
          Adjusted Line Items
        </Heading>
      </div>
      <AdjustedTabLayout loading={loading} fromMatter={fromMatter} currentMatterTab={currentMatterTab} invoiceStatus={invoiceStatus}/>
    </div>
  )
}

const InvoiceLineItems = ({ loading, fromMatter, currentMatterTab, invoiceStatus }) => {
  const classes = InvoiceLineItemsStyles();

  return (
    <div className={classes.invoiceLineItemsContainer}>
      <Divider />
      <AdjustedLineItems loading={loading} fromMatter={fromMatter} currentMatterTab={currentMatterTab} invoiceStatus={invoiceStatus}/>
      <NonAdjustedLineItems loading={loading} fromMatter={fromMatter} currentMatterTab={currentMatterTab} invoiceStatus={invoiceStatus}/>
    </div>
  );
};

export default InvoiceLineItems;