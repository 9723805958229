import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  GridColumn,
  Divider,
  useSnackbar,
  Skeleton
} from "@walmart-web/livingdesign-components";
import { useParams,useLocation } from "react-router";
import { Folder } from "../../../../constants/images";
import { CloudDownload } from "@livingdesign/icons";
import {
  ACCEPTED_FILES_LABEL,
  acceptedFilesForDocuments,
  INVOICE_DROPZONE_LABEL,
  MAX_FILE_UPLOAD_SIZE
} from "../../../../constants/constants";

import { DropzoneArea } from 'material-ui-dropzone';
import { postService, postServiceWithHeaders } from "../../../../axios/axios";
import { useSelector } from "react-redux";
import { NODE_JS_URL } from "../../../../constants/baseURLs";
import { getErrorMessage } from "../../../../helpers/utils";
import { createUseStyles } from "react-jss";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const disableUploadForStatusList = ['rejected', 'paid', 'ready for payment', 'void'];
/**
 *
 * styles used in the component.
 */
const useStyles = createUseStyles({
  dataTableWrap: {
    margin: "0",
    minHeight: '190px',
    padding: '15px',
    background: '#fff',
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  folderTags: {
    marginRight: '5px'
  },
  dropzoneWrap: {
    background: "#fff",
    padding: "40px"
  },
  dropzoneArea: {
    border: "2px dashed #80b8ee !important",
    background: "#e6f1fc !important",
    height: "285px",
    "&>div.MuiDropzoneArea-textContainer": {
      height: "100%",
      display: "flex !important",
      textAlign: "center !important",
      alignItems: "center !important",
      flexDirection: "column-reverse !important",
      justifyContent: "center !important",
    }
  },
  dropzoneText: {
    fontFamily: "Bogle !Important",
    margin: "0 !important"
  },
  formFieldsWrap: {
    paddingLeft: "20px"
  },
  root: {
    "& .MuiInputLabel-formControl": {
      fontFamily: "Bogle"
    }
  },
  newDocTitle: {
    fontSize: "24px",
    margin: "24px 0"
  },
  dropzoneLabelButton: {
    fontFamily: "Bogle",
    display: "block",
    paddingTop: "12px",
    paddingBottom: "10px"
  },
  dropzoneLabelText: {
    fontFamily: "Bogle",
    display: "block",
    fontSize: "16px"
  },
  dropzoneSubLabelText: {
    fontFamily: "Bogle",
    display: "block",
    fontSize: "12px",
    width: '410px',
    margin: '0 auto'
  },
  fileName: {
    padding: '16px 0',
    color: '#2E2F32'
  },
  downloadIcon: {
    textAlign: 'right',
    padding: '16px 6px 16px 0',
    cursor: 'pointer'
  },
  uploadedFilesList: {
    marginTop: '24px'
  }
});

const getMuiTheme = () =>
  createTheme({
    components: {
      MuiDropzoneArea: {
        styleOverrides: {
          root: {
            textContainer: {
              textAlign: "center",
              display: "flex",
              flexDirection: "column-reverse",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            },
            icon: {
              height: "48px",
              width: "48px",
            }
          }
        },
      }
    }
  }
);

/**
 * Function to return dropzone label component
 * @returns dropzone label component
 */
const DropzoneLabelText = (props) => {
  const classes = useStyles();
  const {invoiceDetail} = props
  return (
    <>
      <span className={classes.dropzoneLabelButton}>
        <Button
          size="small"
          variant="primary"
          id="upload-btn"
          disabled={false}
        >
          Choose files to upload
        </Button>
      </span>
      <span className={classes.dropzoneLabelText}>
        {INVOICE_DROPZONE_LABEL}
      </span>
      <span className={classes.dropzoneSubLabelText}>
        {ACCEPTED_FILES_LABEL}
      </span>
    </>
  );
};

/**
 * InvoiceFiles component to list the invoice under different tabs
 */
const InvoiceFiles = () => {
  const { state: { fromMatter, matterNumber } = {} } = useLocation();
  const { addSnack } = useSnackbar();
  const { invoiceId } = useParams();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const { invoiceDetail } = useSelector((state) => state?.invoice);
  const userDetails = useSelector((state) => state?.user?.userDetail);

  const defaultParams = {
    id: invoiceId,
    condition: "AND",
    queryParameters: [
      {
        key: "metaData.invoiceId",
        operation: "match",
        value: invoiceId
      },
      {
        key: "metaData.IL_OBJECT_TYPE",
        operation: "match",
        value: "INVOICE"
      },
      {
        key: "metaData.IL_OBJECT_ID",
        operation: "match",
        value: invoiceId
      }
    ],
    requestedFields: [
      "id",
      "fileName",
      "fileID"
    ]
  }

  const headers = {
    "X-User-Id": userDetails?.userId,
    "X-User-First-Name": userDetails?.firstName,
    "X-User-Last-Name": userDetails?.lastName,
    "X-User-Email-Id": userDetails?.email,
  }

  useEffect(() => {
    getFiles();
  }, []);

  /**
   * Fumction to fetch invoice related files
   */
  const getFiles = () => {
    setLoading(true);
    postService(NODE_JS_URL, "/files/search", defaultParams)
      .then((res) => {
        setLoading(false);
        setInvoiceFiles(res?.data);
      })
      .catch((error) => {
        setLoading(false);
        setInvoiceFiles([]);
        addSnack({
          message: getErrorMessage(error)
        });
      })
  }

  /**
   * Fumction to trigger callback on file upload
   * @param {array} files
   */
  const handleFileChange = (files) => {
    if (files?.length !== 0) {
      const promises = [];
      const metaData = {
        "IL_OBJECT_ID": invoiceId,
        "IL_OBJECT_TYPE": "INVOICE",
        "invoiceId": invoiceId,
        ...(fromMatter && { "matterNumber": matterNumber }),
        "isPublic": true
      }
      headers['Content-Type'] = `multipart/form-data`;
      // upload functionality code goes here
      setLoading(true);
      files?.forEach((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('metadata', JSON.stringify(metaData));
        promises.push(postServiceWithHeaders(NODE_JS_URL, "/upload", formData, headers))
      })
      Promise.all(promises)
      .then((res) => {
        // added timeout to overcome composite search delay
        setTimeout(() => {
          getFiles();
        }, 3000);
        setLoading(false)
        addSnack({
          message: "File uploaded successfully!"
        })
      }).catch((error) => {
        setLoading(false)
        addSnack({
          message: getErrorMessage(error)
        });
      }
      )

    }

  }


  /**
  * Fumction to trigger callback on file download
  * @param {string} fileName & fileId
  */
  const onClickDownLoad = (fileName, fileId) => {
    postServiceWithHeaders(NODE_JS_URL, '/download?version=1', { id: fileId },headers, "blob")
      .then((res) => {
        const url = URL.createObjectURL(res?.data);
        const anchor = document?.createElement("a");
        anchor.href = url;
        anchor.setAttribute("download",fileName);
        document?.body.appendChild(anchor);
        anchor.click();
      })
      .catch((error) => {
        addSnack({
          message: getErrorMessage(error)
        });
      })
  }

  const handleOnAlert = (message, variant) => {
    if(variant === 'error') {
      addSnack({
        message: `${message}`
      });
    }
  }

  return (
    <div className={classes.dataTableWrap} data-testid="invoice-files">
      {
        loading ?
          <div>
            <Skeleton height="281px" variant="rectangle" />
            <Skeleton height="18px" variant="rectangle" className={classes.uploadedFilesList} />
            <Skeleton height="18px" variant="rectangle" className={classes.uploadedFilesList} />
            <Skeleton height="18px" variant="rectangle" className={classes.uploadedFilesList} />
          </div>
          :
          <React.Fragment>
            <ThemeProvider theme={getMuiTheme()}>
              <DropzoneArea
                dropzoneProps={{ disabled: false }}
                clearOnUnmount={true}
                onChange={(files) => handleFileChange(files)}
                Icon={Folder}
                dropzoneText={<DropzoneLabelText invoiceDetail={invoiceDetail}/>}
                showPreviewsInDropzone={false}
                previewText="Selected files"
                filesLimit={20}
                showAlerts={false}
                dropzoneClass={classes.dropzoneArea}
                dropzoneParagraphClass={classes.dropzoneText}
                acceptedFiles={acceptedFilesForDocuments}
                maxFileSize={MAX_FILE_UPLOAD_SIZE}
                onAlert={(message, variant) => handleOnAlert(message, variant)}
              />
            </ThemeProvider>
            <div className={classes.uploadedFilesList}>
              {invoiceFiles?.map((file, i) => (
                <div key={`${file?._source?.fileName}`} data-testid={`file-list-${i}`}>
                  <Divider />
                  <Grid>
                    <GridColumn sm={10} md={10} lg={10}>
                      <div className={classes.fileName} id={`file-name-${file?._source?.fileName}`}>
                        {file?._source?.fileName}
                      </div>
                    </GridColumn>
                    <GridColumn sm={2} md={2} lg={2}>
                      <div className={classes.downloadIcon}>
                        <CloudDownload size="small" data-testid={`btn-download-${i}`} id={`btn-download-${i}`} onClick={() => onClickDownLoad(file?._source?.fileName, file?._source?.fileID)} />
                      </div>
                    </GridColumn>
                  </Grid>
                </div>
              ))}
            </div>
          </React.Fragment>
      }
    </div>
  );
};

export default InvoiceFiles;
