import React from 'react';
import MuiValidationMessageStyles from './MuiValidationMessage.styles';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

/*
*    Mui Validation Message is for only MUI Text field errors
*/
const MuiValidationMessage = (props) => {
  const classes = MuiValidationMessageStyles();
  const {message, mode} = props;

  return (
    <p className={mode === "LD"? classes.errorLdContainer:classes.errorContainer}>
      {mode !== "LD" && <ErrorRoundedIcon />} {`${message}`}
    </p>
  );
};

export default MuiValidationMessage;
