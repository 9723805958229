import { createUseStyles } from "react-jss";

const ErrorPageStyles = createUseStyles({
    pageContainer: {
      backgroundColor: '#F5F5F5',
      padding: '32px 40px',
      height: '100%',
    },
    bannerContainer: {
      width: "100%",
      marginTop: "144px",
      marginBottom: "32px"
    },
    banner: {
      width: "100%",
    },
    text: {
      width: "280px",
      height: "32px",
      fontFamily: 'Bogle',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "32px",
      textAlign: "center",
      color: "#2E2F32",
      marginBottom: "8px",
      marginLeft: "auto",
      marginRight: "auto"
    },
    ErrorMsg: {
      width: "280px",
      height: "48px",
      fontFamily: 'Bogle',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "center",
      color: "#74767C",
      marginBottom: "24px",
      marginLeft: "auto",
      marginRight: "auto"
    },
    button:{
      textAlign: "center",
    }
  })

export default ErrorPageStyles;
