import React, { Fragment } from "react";
import { useSelector } from "react-redux";


/**
 *  Keywords for exisiting implementation
 * 
 *  FEATURE - TABLE ACTION BUTTON ~ Adding new button to table toolbar
 *  FEATURE - SIDE NAVIGATION ~ Adding new module to sidebar navigation
 *  FEATURE - DUMMY FLAG ~ Dummy feature flag in reducer
 *  FEATURE - <feature_name> - value toggle ~ Toggle value when feature is on / off
 */

/**
 * Features Implemented with feature flags
 * TKBackdating
 */

/**
 * FeatureToggle - This method returns feature if it is enabled
 * @param {*} props 
 * @returns 
 */
export const FeatureToggle = (props) => {
  const { children, name } = props;
  const features = useSelector((state) => state?.user?.featureMap)

  const featureEnabled = features?.[name] || false;

  return (
    featureEnabled ?
      <Fragment>
        {children}
      </Fragment> :
      null
  );
}

/**
 * FeatureValueToggle - This method returns value when feature is on / off
 * @param {*} name - feature name 
 * @param {*} onValue - Value when feature is enabled
 * @param {*} offValue - Value when feature is disabled
 * @param {} isNullWhenOff - Value is Null when feature is disabled - default value true
 * @returns 
 */
export const FeatureValueToggle = (name, onValue, offValue = null) => {
  const features = useSelector((state) => state?.user?.featureMap)
  const featureEnabled = features?.[name] || false;

  return (
    featureEnabled ? onValue : offValue
  )
}

