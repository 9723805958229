import { createUseStyles } from "react-jss";
import {
  Divider, Grid, Heading,
  GridColumn, Skeleton
} from "@walmart-web/livingdesign-components";
import React from "react";
import { useSelector } from "react-redux";
import DisplayValue from "../../../components/TextField/DisplayValue/DisplayValue";
import {
  APPROVAL_DATA, BILLING_CONTACT, CLASSIFICATION, CREATED_DATE, DETAILS,
  EMAIL, END_DATE, JD_YEAR, JUSTIFICATION_FOR_TIMEKEEPER,
  NAME, NO, PHONE, PRIMARYOFFICELOCATION, REQUESTER_EMAIL, START_DATE, STATE_BAR_LICENCE,
  SUBJECT_MATTER_EXPERTISE, CONTACT_INFO, TKID, WALMART_APPOVING_ATTORNEY, YES, ADDRESS_LINE_1, ADDRESS_LINE_2, CITY, STATE, COUNTRY
} from "../../../constants/constants";
import { SPACING } from "../../../constants/styleGuide";
import { renderTernary } from "../../../helpers/utils";
import { isValueEmptyCheck } from "../../../helpers/validationUtils";
import { formatPhoneNumber } from "../../../helpers/commonUtils";
import { displayLocalDate } from "../../../helpers/utcUtils";

const useStyles = createUseStyles({
  wrapper: {
    backgroundColor: "#FFFFFF",
    padding: "24px",
  },
  leftContent: {
    paddingRight: SPACING.s72,
  },
  contentSpacing8: {
    paddingTop: SPACING.s8,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  info: {
    marginLeft: SPACING.s10,
  },
	sectionTitle: {
		margin: "28px 0",
	},
});

const LoadField = ({ loader, skeletonHeight, skeletonVariant, skeletonLines = 1, node }) => {
  if (loader) {
    return <Skeleton data-testid={'skeleton'} height={skeletonHeight} variant={skeletonVariant} lines={skeletonLines} />;
  } else {
    return node;
  }
};

const UserRequestDetail = (props) => {
  const classes = useStyles();
  const { loading } = props;
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    lastName,
    billingContact,
    walmartApprovingAttorneyFullName,
    endDate,
    phoneNumber,
    stateBarLicence,
    jdYear,
    emailId,
    requesterEmailId,
    tkId,
    classification,
    justificationForRole,
    firstName,
    subjectMatterExpertise,
    primaryOfficeLocation,
    createdTs,
    startDate,
    isTimeKeeperRoleEnabled } = useSelector(state => state?.adminUserRequest?.userRequestData)

  /*
*Loading Skeleton
*/
  const loadFieldProps = {
    loader: loading,
    skeletonHeight: '18px',
    skeletonVariant: 'rectangle',
    skeletonLines: 1,
    node: <></>
  };
  return (
    <>
      <div className={classes.wrapper}>
        <Grid>
					<GridColumn sm={12}>
						<div className={classes.sectionTitle}>
							<Heading as="h3" size="small">
								{CONTACT_INFO}
							</Heading>
						</div>
					</GridColumn>
          <GridColumn sm={6}>
						<div className={classes.leftContent}>
              {LoadField({
                ...loadFieldProps, node: <DisplayValue label={NAME} value={isValueEmptyCheck(`${firstName} ${lastName}`)} />
              })}
              <div className={classes.contentSpacing16}>
                {LoadField({
                  ...loadFieldProps, node: <DisplayValue label={EMAIL} value={isValueEmptyCheck(emailId)} />
                })}
              </div>
              <div className={classes.contentSpacing16}>
                {LoadField({
                  ...loadFieldProps, node: <DisplayValue label={ADDRESS_LINE_1} value={isValueEmptyCheck(addressLine1)} />
                })}
              </div>
              <div className={classes.contentSpacing16}>
                {LoadField({
                  ...loadFieldProps, node: <DisplayValue label={CITY} value={isValueEmptyCheck(city)} />
                })}
              </div>
              <div className={classes.contentSpacing16}>
                {LoadField({
                  ...loadFieldProps, node: <DisplayValue label={COUNTRY} value={isValueEmptyCheck(country)} />
                })}
              </div>
            </div>
          </GridColumn>
          <GridColumn sm={6}>
            {LoadField({
              ...loadFieldProps, node: <DisplayValue label={PHONE} value={isValueEmptyCheck(formatPhoneNumber(phoneNumber))} />
            })}
            { typeof primaryOfficeLocation !== 'undefined' && !primaryOfficeLocation?.includes('{') && <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <DisplayValue
                  label={PRIMARYOFFICELOCATION}
                  value={isValueEmptyCheck(primaryOfficeLocation)}
                />
              })}
              </div>
            }
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <DisplayValue label={ADDRESS_LINE_2} value={isValueEmptyCheck(addressLine2)} />
              })}
            </div>
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <DisplayValue label={STATE} value={isValueEmptyCheck(state)} />
              })}
            </div>
          </GridColumn>
        </Grid>
        <GridColumn sm={12}>
          <div className={classes.contentSpacing24}>
            <Divider />
          </div>
        </GridColumn>
        <Grid>
					<GridColumn sm={12}>
						<div className={classes.sectionTitle}>
							<Heading as="h3" size="small">
								{APPROVAL_DATA}
							</Heading>
						</div>
					</GridColumn>
          <GridColumn sm={6}>
            <div className={classes.leftContent}>
              {isTimeKeeperRoleEnabled && <>
                <div>
                  {LoadField({
                    ...loadFieldProps, node: <DisplayValue label={STATE_BAR_LICENCE} value={isValueEmptyCheck(stateBarLicence)} />
                  })}
                </div>
                <div className={classes.contentSpacing16}>
                  {LoadField({
                    ...loadFieldProps, node: <DisplayValue
                      label={SUBJECT_MATTER_EXPERTISE}
                      value={isValueEmptyCheck(subjectMatterExpertise)}
                    />
                  })}
                </div>
              </> }
              <div className={classes.contentSpacing16}>
                {LoadField({
                  ...loadFieldProps, node: <DisplayValue
                    label={JUSTIFICATION_FOR_TIMEKEEPER}
                    value={isValueEmptyCheck(justificationForRole)}
                  />
                })}
              </div>
            </div>
          </GridColumn>
          <div className={classes.contentSpacing16}>
            <Divider />
          </div>
          <GridColumn sm={6}>
            {isTimeKeeperRoleEnabled && LoadField({
              ...loadFieldProps, node: <DisplayValue label={JD_YEAR} value={isValueEmptyCheck(jdYear)} />
            })}
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <DisplayValue
                  label={WALMART_APPOVING_ATTORNEY}
                  value={isValueEmptyCheck(walmartApprovingAttorneyFullName)}
                />
              })}
            </div>
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <DisplayValue label={REQUESTER_EMAIL} value={isValueEmptyCheck(requesterEmailId)} />
              })}
            </div>
          </GridColumn>
        </Grid>
        <GridColumn sm={12}>
          <div className={classes.contentSpacing24}>
            <Divider />
          </div>
        </GridColumn>
        <Grid>
					<GridColumn sm={12}>
						<div className={classes.sectionTitle}>
							<Heading as="h3" size="small">
								{DETAILS}
							</Heading>
						</div>
					</GridColumn>
          <GridColumn sm={6}>
            <div className={classes.leftContent}>
              { isTimeKeeperRoleEnabled && <><div className={classes.contentSpacing8}>
                {LoadField({
                  ...loadFieldProps, node: <DisplayValue label={TKID} value={isValueEmptyCheck(tkId)} />
                })}
              </div>
              <div className={classes.contentSpacing16}>
                {LoadField({
                  ...loadFieldProps, node: <DisplayValue label={CLASSIFICATION} value={isValueEmptyCheck(classification)} />
                })}
              </div></> }
              <div className={classes.contentSpacing16}>
                {LoadField({
              ...loadFieldProps, node: <DisplayValue label={CREATED_DATE} value={renderTernary(createdTs, displayLocalDate(createdTs), '-')} />
                })}
              </div>
              <div className={classes.contentSpacing16}>
                {LoadField({
                  ...loadFieldProps, node: <DisplayValue label={BILLING_CONTACT} value={billingContact ? YES : NO} />
                })}
              </div>
            </div>
          </GridColumn>
          <GridColumn sm={6}>
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <DisplayValue label={START_DATE} value={renderTernary(startDate, displayLocalDate(startDate), '-')} />
              })}
            </div>
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <DisplayValue
                  label={END_DATE}
                  value={renderTernary(endDate, displayLocalDate(endDate), '-')}
                />
              })}
            </div>
          </GridColumn>
        </Grid>
      </div>
    </>
  );
};
export default UserRequestDetail;
