import React, { useState } from 'react';
import { TabNavigation,TabNavigationItem} from '@walmart-web/livingdesign-components';
import { HELP_CENTER_TABS } from '../../constants/constants';
import HelpCenterBanner from '../../components/HelpCenterBanner/HelpCenterBanner';
import TrainingGuides from './TrainingGuides';
import HelpCenterStyles from './HelpCenter.styles';

/*
*   Help Center component
*/
const HelpCenter= () => {
  const classes = HelpCenterStyles();
  const [isCurrent, setIsCurrent] = useState(0);

  /*
  *   tabContent - load content of the tab on click
  *   @{param} - index
  */
  const tabContent = (index) => {
    if(index === 0){
      return <TrainingGuides/>;
    }
    else{
      return <></>
    }
  }

  return (
    <div className={classes.pageContainer} data-testid="help-center-container" id='content-container'>
      <div className={classes.bannerContainer}>
        <HelpCenterBanner heading='Help Center'/>
      </div>
            <div className={classes.tabContainer} data-testid="help-center-tab-container">
              <TabNavigation>
                {HELP_CENTER_TABS?.map((item,index) => (
                  <TabNavigationItem
                  data-testid={`help-center-tab-${index}`}
                  id={`help-center-tab-${index}`}
                  onClick={() => {
                    setIsCurrent(index);
                  }}
                  isCurrent={isCurrent === index}
                  selected={isCurrent === index}
                  key={"help-center-tab-"+item}
                  >
                    {item}
                  </TabNavigationItem>
                ))}
              </TabNavigation>
            </div>
            <div data-testid="help-center-content" id='tab-content'>
            {tabContent(isCurrent)}
            </div>
    </div>
  );
};

export default HelpCenter;
