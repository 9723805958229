import { createUseStyles } from "react-jss";
import { Grid, GridColumn, Skeleton } from "@walmart-web/livingdesign-components";
import React from "react";
import { SPACING } from "../../constants/styleGuide";

const useStyles = createUseStyles({
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  whiteContainer: {
    width: '100%',
    background: '#ffffff',
    padding: '16px',
    marginBottom: '20px',
    borderRadius: '5px',
    height: '20%'
  },
});

/*
* loading skeleton component
*/
export const LoadingSkeleton = () => {
  const classes = useStyles();
  return (
    <div>
      {[1, 2, 3].map((val,index) => {
        return (
          <Grid key={`${val}`}>
            <GridColumn sm="12" md="12" lg="12">
              <div className={classes.contentSpacing16}>
                <div className={classes.whiteContainer}>
                  {[1, 2, 3].map((val,index) => {
                    return (
                      <div className={classes.contentSpacing16} key={`${val}`}>
                        <Skeleton height="18px" variant="rectangle" />
                      </div>
                    )
                  })}
                </div>
              </div>
            </GridColumn>
          </Grid>
        )
      })}
    </div>
  )
}
