import axios from 'axios';
import { API_GATEWAY_URL, NODE_JS_URL } from '../constants/baseURLs';
import { NA } from '../constants/constants';
import services from '../constants/urls.json';

const urls = services['dev'];

let store;
// exposing an injectStore function from the axios interceptor file - to read tenantId
export const injectStore = _store => {
  store = _store
}

axios.interceptors.request.use(function (config) {
  const userDetails = store?.getState()?.user?.userDetail?.attributes
  config.headers['X-User-Id'] = userDetails?.userId || NA;
  config.headers['X-User-Unique-Id'] = userDetails?.userUniqueId || NA;
  config.headers['X-User-First-Name'] = userDetails?.firstName || NA;
  config.headers['X-User-Last-Name'] = userDetails?.lastName || NA;
  config.headers['X-User-Email-Id'] = userDetails?.emailId || NA;
  config.headers['Authorization'] = store?.getState()?.user?.staticTk;
  config.headers['X-Tenant-ID'] = store.getState().user.tenantId;
  config.headers['X-Sub-Tenant-ID'] = "LNG";
  config.headers['WM_CONSUMER_INTIMESTAMP'] = store?.getState()?.user?.authSign?.["WM_CONSUMER.INTIMESTAMP"];
  config.headers['WM_SEC_AUTH_SIGNATURE'] = store?.getState()?.user?.authSign?.["WM_SEC.AUTH_SIGNATURE"];
  if(store?.getState()?.user?.authTk) {
    config.headers['WM_SEC_AUTH_TOKEN'] = store?.getState()?.user?.authTk;
  }

  return config;
});

export const getService = (baseUrlType, url = '') =>
  axios.get(urls[baseUrlType] + url);

export const getServiceWithHeaders = (baseUrlType, url, headers) =>
  axios.get(urls[baseUrlType] + url, { headers: headers });

export const getServiceWithResponseType = (baseUrlType, url, responseType) =>
  axios.get(urls[baseUrlType] + url, { responseType: responseType });

export const getServiceWithParams = (baseUrlType, url, headers, params) =>
  axios.get(urls[baseUrlType] + url, { headers: headers, params: params });

export const postServiceWithHeaders = (baseUrlType, url, body, headers,responseType="application/json") =>
  axios.post(urls[baseUrlType] + url, body, { headers: headers, responseType: responseType });

export const postService = (baseUrlType, url, body) =>
  axios.post(urls[baseUrlType] + url, body);

export const patchService = (baseUrlType, url, body) =>
  axios.patch(urls[baseUrlType] + url, body);

export const patchServiceWithHeaders = (baseUrlType, url, body, headers) =>
  axios.patch(urls[baseUrlType] + url, body, { headers: headers, responseType: 'application/json' });

export const deleteService = (baseUrlType, url) =>
  axios.delete(urls[baseUrlType] + url);

export const putService = (baseUrlType, url) =>
  axios.put(urls[baseUrlType] + url);

export const putServiceWithHeaders = (baseUrlType, url, headers) =>
  axios.put(urls[baseUrlType] + url, { headers: headers });

export const deleteServiceWithHeaders = (baseUrlType, url, headers) =>
  axios.delete(urls[baseUrlType] + url, { headers: headers });

export const fileUpload = (file, requestId, setProgressValue) => {
  const url = `${urls[API_GATEWAY_URL]}/storage/v1/upload/${requestId}`;
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    },
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgressValue(percentCompleted);
    }
  };
  return axios.post(url, formData, config);
};

export const fileDelete = (fileId) => {
  const url = `${urls[API_GATEWAY_URL]}/storage/v1/delete/${fileId}`;
  return axios.delete(url);
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (requestError) => {
    const body = {
      error: requestError?.response?.data,
      headers: {
        "Accept": requestError?.config?.headers?.["Accept"],
        "Authorization": requestError?.config?.headers?.["Authorization"],
        "Content-Type": requestError?.config?.headers?.["Content-Type"],
        "WM_CONSUMER_INTIMESTAMP": requestError?.config?.headers?.["WM_CONSUMER_INTIMESTAMP"],
        "WM_SEC_AUTH_SIGNATURE": requestError?.config?.headers?.["WM_SEC_AUTH_SIGNATURE"],
        "WM_SEC_AUTH_TOKEN": requestError?.config?.headers?.["WM_SEC_AUTH_TOKEN"],
        "X-Sub-Tenant-ID": requestError?.config?.headers?.["X-Sub-Tenant-ID"],
        "X-Tenant-ID": requestError?.config?.headers?.["X-Tenant-ID"],
        "X-User-Email-Id": requestError?.config?.headers?.["X-User-Email-Id"],
        "X-User-First-Name": requestError?.config?.headers?.["X-User-First-Name"],
        "X-User-Id": requestError?.config?.headers?.["X-User-Id"],
        "X-User-Last-Name": requestError?.config?.headers?.["X-User-Last-Name"],
        "X-User-Unique-Id": requestError?.config?.headers?.["X-User-Unique-Id"]
      }
    }
    if(requestError?.response?.data) {
      postService(NODE_JS_URL, "/log", body )
      .then((res) => {
        // Logging Error Code siliently
      })
      .catch((error) => {
        // Failing API Silently
      })
    } else {
      // Ignoring API if there is no resposne
    }
    return Promise.reject(requestError);
  }
);