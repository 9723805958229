import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import MuiDataTable from "../../../components/Table/MuiDataTable/MuiDataTable";

import {
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import {
  CS_SCOPES,
  CS_IS_ACTIVE_FILTER,
  CS_DEFAULT_SORT_QUERY,
  CS_SCORE_SORT_OBJ,
  EXPORT_MAX_LIMIT_CONF,
  INVOICE
} from "../../../constants/constants";
import {
  ROWS_PER_PAGE_INVOICE,
  ROWS_PER_PAGE_OPTIONS_INVOICE,
  ENABLE_SERVER_SIDE_FEATURES_INVOICE,
} from "../../../constants/tableConfigs";
import { getExportedData, getErrorMessage } from "../../../helpers/utils";

import {
  postService,
} from "../../../axios/axios";
import { Operations } from "../../../axios/operations";
import { setMatterInvoiceData } from "../../../store/actions/invoiceActions";

import moment from 'moment';
import { COMPOSITE_SEARCH_SERVICE} from "../../../constants/baseURLs";
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery, getUpdatedFilterQuery } from "../../../helpers/csQueryUtils";
import { getAllowedActionsList, getInvoiceStatus, exportClientData, renderCustomFilterOption, dateFilterOptions, tableCellDisplay } from "../../../helpers/tableUtils";
import { displayLocalDate } from "../../../helpers/utcUtils";
import { MOMENT_DATE_FORMAT } from "../../../constants/dateFormats";
import ExportModal from "../../../components/Modal/ExportModal/ExportModal";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  dataTableWrap: {
    margin: "0",
    '& table > thead > tr > th.MuiTableCell-head': {
      color: '#000000',
      fontWeight: 'bold'
    },
    '& table > thead > tr > th.MuiTableCell-head button': {
      color: '#000000',
      fontWeight: 'bold'
    },
    "& div>table>tbody>tr>td": {
			fontFamily: "Bogle",
			height: "24px",
			fontWeight: "normal",
			lineHeight: "1.33",
		},
  },
  tabContainer: {
    "& nav > ul > li > a": {
      background: "none",
    }
  },
  starColumnHeader: {
    padding: "6px",
  },
  star: {
    padding: "6px",
  },
  handCursor: {
    cursor: "pointer",
  },
  percentChip: {
    width: '60px',
    height: '20px',
    display: 'block',
    margin: '0 auto',
    textAlign: 'center',
    borderRadius: '10px',
    background: '#E3E4E5'
  },
  exportName: {
    marginBottom: 20
  },
  exportNameAs: {
    paddingTop: 20,
    marginBottom: 20
  },
  modalSubText: {
    fontSize: 14,
    fontWeight: 'normal'
  },
  wd80: {
    width: '80px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  wd100: {
    width: '100px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  wd120: {
    width: '120px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  wd60: {
    width: '60px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
});

/**
 * Invoice component to list the invoice under Matter financial
 */
const MatterInvoiceList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { currentFinancialTab, currentMatterTab } = props;

  const COLUMNS = [
    {
      name: "organizationInvoiceNumber",
      label: "Invoice No.",
      colKey: "organizationInvoiceNumber",
      options: {
        sort: true,
        display : true,
        customBodyRender: (value, tableMeta) => tableCellDisplay(`Mui-Invoice-row-${tableMeta?.rowIndex}`, value),
        setCellHeaderProps: () => ({ style: { minWidth: "105px", maxWidth: "105px" }}),
        customFilterListOptions: { render: (v) => `Invoice No.: ${v}` },
      },
      operation: Operations.LIKE,
      type: "tag"
    },
    {
      name: "invoiceDate",
      label: "Invoice Date",
      colKey: "invoiceDate",
      options: {
        sort: true,
        display : true,
        setCellHeaderProps: () => ({ style: { minWidth: "112px", maxWidth: "112px" }}),
        customBodyRender: (value, tableMeta) => (
          tableMeta?.rowData[2] !== true ? tableCellDisplay(`Mui-InvoiceDate-row-${tableMeta?.rowIndex}`,  moment(value).format(MOMENT_DATE_FORMAT)) : null
        ),
        customFilterListOptions: renderCustomFilterOption(),
        filter: true,
        filterType: 'custom',
        filterList: [],
        filterOptions: dateFilterOptions()
      },
      operation: Operations.GTE,
      type:"date"
    },
    {
      name: "receivedDate",
      label: "Received Date",
      colKey: "receivedDate",
      options: {
        sort: true,
        display : true,
        customBodyRender: (value, tableMeta) => {
          return (
            tableMeta?.rowData[2] !== true ? tableCellDisplay(`Mui-receivedDate-row-${tableMeta?.rowIndex}`,  displayLocalDate(value)) : null
          )
        },
        customFilterListOptions: renderCustomFilterOption(),
        filter: true,
        filterType: 'custom',
        filterList: [],
        filterOptions: dateFilterOptions()
      },
      operation: Operations.GTE,
      type:"date"
    },
    {
      name: "daysLeft",
      label: "Days Left",
      colKey: "daysLeft",
      options: {
        display : true,
        setCellHeaderProps: () => ({ style: { minWidth: "96px", maxWidth: "96px" }}),
        customBodyRender: (value, tableMeta) => tableCellDisplay(`Mui-dayLeft-row-${tableMeta?.rowIndex}`, value),
        customFilterListOptions: { render: (v) => `Days Left: ${v}` },
      },
      operation: Operations.EQ,
      type: "number"
    },
    {
      name: "createdTs",
      label: "Created Date Range",
      colKey: "createdTs",
      options: {
        sort: false,
        display: false,
        customFilterListOptions: renderCustomFilterOption(),
        filter: true,
        filterType: 'custom',
        filterList: [],
        filterOptions: dateFilterOptions()
      },
      operation: Operations.GTE,
      type:"date"
    },
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        display : true,
        customFilterListOptions: { render: (v) => `Status: ${v}` },
        customBodyRender: (value, tableMeta) => tableCellDisplay(`Mui-status-row-${tableMeta?.rowIndex}`, getInvoiceStatus(value)),
      },
      operation: Operations.EQ,
      type: "id"
    },
    {
      name: "totalNetAmount",
      label: "$ Total",
      colKey: "totalNetAmount",
      options: {
        display : true,
        setCellHeaderProps: () => ({ style: { minWidth: "80px", maxWidth: "80px" }}),
        customFilterListOptions: { render: (v) => `Total: ${v}` },
      },
      operation: Operations.EQ,
      type: "number"
    },
    {
      name: "assignedTo",
      label: "Assigned To",
      colKey: "assignedTo",
      options: {
        sort: false,
        display : true,
        setCellHeaderProps: () => ({ style: { minWidth: "74px", fontFamily: "Bogle" }}),
        customFilterListOptions: { render: (v) => `Assigned To: ${v}` },
      },
      operation: Operations.LIKE,
      type: "tag"
    },
    {
      name: "invoiceType",
      label: "Invoice Type",
      colKey: "invoiceType",
      options: {
        display : false,
      },
    }
  ];

  const dispatch = useDispatch();
  const { matterInvoiceData } = useSelector((state) => state?.invoice);
  const [invoiceColumns, setInvoiceColumns] = useState(COLUMNS);
  const [loading, setLoading] = useState(false);
  const [rowsPerPageInvoice, setRowsPerPageInvoice] = useState(ROWS_PER_PAGE_INVOICE);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [sortQuery, setSortQuery] = useState(CS_DEFAULT_SORT_QUERY);
  const [exportLoading, setExportLoading] = useState(false);
  const [createExportLoading, setCreateExportLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectRowsData, setSelectRowsData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const { matterDetail } = useSelector((state) => state?.matter);
  const showExportAllOption = matterInvoiceData?.count < EXPORT_MAX_LIMIT_CONF.BUDGET;
  const defaultExportOption = showExportAllOption ? 'allInvoice' : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);
  const [currentPageExport, setCurrentPageExport] = useState(false);
  const orgIdentifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
  const invoiceAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.financial?.invoice);

  const defaultQuery = {
    "filters": [
      CS_IS_ACTIVE_FILTER,
      formCsFilterObject("matterNumber","match",matterDetail?.matter?.matterNumber),
      formCsFilterObject("organizationId","match",orgIdentifier),
      formCsFilterObject("invoiceType", "match", "Invoice")
    ],
    "operation": "AND",
    "properties": null
    }
  const { addSnack } = useSnackbar();

  const FILTER_CONFIGS = [
    {
      colKey: "organizationInvoiceNumber",
      type: "textField",
      label: "Find an Invoice No.",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Invoice Number',
    },
    {
      colKey: "daysLeft",
      type: "textField",
      label: "Number of Days",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Days Left',
    },
    {
      colKey: "totalNetAmount",
      type: "textField",
      label: "$ Total",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Net Amount',
      isPositiveDecimalNumber: true
    },
    {
      colKey: "assignedTo",
      type: "peopleDropdownAutocomplete",
      label: "Assignees",
      options: [],
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Assigned To'
    },
    {
      colKey: "createdTs",
      type: "rangePicker",
      label: "Start Date",
      defaultOpen: true,
      section: "right",
      displayLabel: true,
      accordian: 'Created Date Range'
    },
    {
      colKey: "status",
      type: "checkbox",
      label: "Status",
      options: [
        "Pending",
        "Workflow",
        "Appealed",
        "Ready For Payment",
        "Sent For Payment",
        "Paid",
        "Rejected",
        "Void",
        "Payment Failed",
        "Failed"
      ],
      defaultOpen: true,
      displayLabel: false,
      accordian: 'Status'
    },
  ];

  const [filterConfigs,] = useState(FILTER_CONFIGS);

  /**
   * Function to call Invoice Search Service
   * @param {string} query
   */
  const getinvoiceData = (paginationQuery) => {
    setLoading(true);
    const sortQueryInvoice = {...sortQuery};
    if(searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(CS_DEFAULT_SORT_QUERY)) {
      sortQueryInvoice.sortBy = [CS_SCORE_SORT_OBJ, ...sortQuery?.sortBy];
    }
    const updatedfilterQuery = getUpdatedFilterQuery({...filterQuery});
    const query = formCompositeSearchQuery([CS_SCOPES.INVOICE], updatedfilterQuery, searchQuery, sortQueryInvoice, paginationQuery, {}, defaultQuery);
    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=false&organizationId=${orgIdentifier}`,
      query
    )
      .then((response) => {
        setLoading(false);
        dispatch(setMatterInvoiceData({
          invoices:response?.data?.invoice?.data,
          count:response?.data?.invoice?.count
        }))
      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

  /**
   * Function to handle row click
   * @param {object} rowData: row data
   * @param {object} rowMeta: meta data of selected row
   */
  const handleRowClick = (rowData, rowMeta) => {
    const { id, matterNumber } = matterInvoiceData?.invoices[rowMeta?.rowIndex];
    history.push(`/invoice/${id}`, {
      invoiceData: matterInvoiceData?.invoices[rowMeta?.rowIndex],
      fromMatter: true,
      currentMatterTab: currentMatterTab,
      matterNumber: matterNumber,
    })
  }

  /**
   * Function to validate search query and set query or show no results UI
   * @param {string} searchText
   */
   const handleServerSideSearch = (searchText) => {
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };

  /**
   * Function to set filter query on filter action
   * @param {string} query
   */
  const handleServerSideFilter = (query) => {
    setFilterQuery(query);
  };

  /**
   * Function to call api on pagination action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPageInvoice(rowsPerPage);
    const paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * page));
    getinvoiceData(paginationQuery);
  };

  /**
   * Function to call api on sort action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSideSort = ({ name, direction }) => {
    const sortQuery = formCsSortQuery(name,direction);
    setSortQuery(sortQuery);
  };

  const invoiceListTableCustomOptions = {
    downloadOptions: {
      filename: "invoice",
    },
    responsive: "standard",
    rowsPerPage: rowsPerPageInvoice,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_INVOICE,
    isInvoice: true,
    filterConfigs: filterConfigs,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_INVOICE,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSort: handleServerSideSort,
    totalCount: matterInvoiceData?.count
  };

  /**
   * Cancel Export Modal
  */
  const onCancelBtnClk = () => {
    setExportLoading(false)
    setModalOpen(false)
  }



    /**
   * Gets called when user click on export button
   *
   * @public
   */
  const handleExport = () => {
    setExportSelection(defaultExportOption)
    setModalOpen(true);
    setExportLoading(true);
    setCurrentPageExport(true);
  };

  /**
   * After click on create button download Actions
   */
   const onClickCreate = () => {
    setCreateExportLoading(true);
    if(ENABLE_SERVER_SIDE_FEATURES_INVOICE  === false){
      exportClientData(matterInvoiceData?.invoices, exportSelection, rowsData, 'invoice-data');
      setModalOpen(false);
      setExportLoading(false);
      setCreateExportLoading(false);
    }else{
      let exportQuery = formCompositeSearchQuery([CS_SCOPES.INVOICE], filterQuery, searchQuery, sortQuery, {}, {getAll: true}, {}, defaultQuery);
      if(exportSelection === 'currentSelection'){
        const result = rowsData?.map(a => a?.id);
        const selectionQuery =
				{
				"filters": [formCsFilterObject("id","in",result)],
				"operation": "AND",
				"properties": null
				}
        exportQuery = formCompositeSearchQuery([CS_SCOPES.INVOICE], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery);
      }else if(exportSelection === 'currentPage'){
        const result = matterInvoiceData?.invoices?.map(a => a?.id);
        const selectionQuery =
				{
				"filters": [formCsFilterObject("id","in",result)],
				"operation": "AND",
				"properties": null
				}
        exportQuery = formCompositeSearchQuery([CS_SCOPES.INVOICE], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery);
      }
      postService(
        COMPOSITE_SEARCH_SERVICE,
        `/composite-search/v1?returnCsv=true&organizationId=${orgIdentifier}`,
        exportQuery
      )
        .then((response) => {
          const data = response?.data;
          const contentType = response?.headers["content-type"];
          getExportedData(data, contentType, 'matter-invoice');
          setModalOpen(false);
          setExportLoading(false);
          setCreateExportLoading(false);
        })
        .catch((error) => {
          setModalOpen(false);
          setExportLoading(false);
          setCreateExportLoading(false);
          addSnack({
            message: getErrorMessage(error)
          });
        });
    }
  }

  /**
   * function will use when row is select/deselect
   */
  const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
    const bData = matterInvoiceData?.invoices;
    const result = allSelected?.map(item => {
      return bData && bData[item?.index]
    });
    setSelectRowsData(selectRowsData);
    setRowsData(result);
  }

  /**
   * Function will call when user click and Add New Button
  **/
  const handleAddNew = () => {
    history.push("/invoice/0/view", {
      currentFinancialTab,
      fromMatter: true,
      practiceAreaCode: matterDetail?.matter?.practiceAreaCode,
      currentMatterTab,
    });
  }

  /**
   * To load initial data and handle search/filter/sort query change on Invoice datatable
   */
  useEffect(() => {
    const paginationQuery = {
      "offset": 0,
      "limit": rowsPerPageInvoice,
    }
    getinvoiceData(paginationQuery);
  }, [searchQuery, filterQuery, sortQuery]);

  const resetSelectedRows = () => {
    setSelectRowsData([]);
    setRowsData([]);
  }

  return (
    <>
      <div className={classes.dataTableWrap}>
        <MuiDataTable
          data={matterInvoiceData?.invoices}
          columns={invoiceColumns}
          setTableColumns={setInvoiceColumns}
          customOptions={invoiceListTableCustomOptions}
          selectableRowsType={"multiple"}
          onRowClick={handleRowClick}
          groupFilter={true}
          exportLoading={exportLoading}
          onExportClick={handleExport}
          selectedRows={selectRowsData}
          onRowSelection={handleRowSelectionChange}
          onAddNew={handleAddNew}
          loading={loading}
          showRowsCounter={false}
          resetSelectedRows={() => resetSelectedRows()}
          allowedActions={getAllowedActionsList(invoiceAccessibility)}
        />
      </div>
      <ExportModal
        title={INVOICE}
        mode={modalOpen}
        onCancel={onCancelBtnClk}
        onCreate={onClickCreate}
        rowsData={rowsData}
        showExportAllOption={showExportAllOption}
        exportSelection={exportSelection}
        setExportSelection={setExportSelection}
        currentPageExport={currentPageExport}
        setCurrentPageExport={setCurrentPageExport}
        exportLoading={createExportLoading}
      />
    </>
  );

}

export default MatterInvoiceList;



