import { createUseStyles } from "react-jss";

const MuiValidationMessageStyles = createUseStyles({
  errorContainer: {
    color: "#de1c24",
    fontWeight: 400,
    position: 'relative',
    fontSize: "12px",
    fontFamily: "Bogle",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    margin: "-3px -14px 0px",
    "& > svg": {
      top: '4px',
      fontSize: "1rem",
      position: 'relative'
    }
  },
  errorLdContainer: {
    color: "#de1c24",
    fontWeight: 400,
    position: 'relative',
    top: '-3px',
    fontSize: "12px",
    fontFamily: "Bogle",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    padding: "3px 0px 0px 16px",
    margin: "0 -1rem",
    "& > svg": {
      top: '4px',
      fontSize: "1rem",
      position: 'relative'
    },
    "& + svg": {
      display: 'none'
    }
  }
})

export default MuiValidationMessageStyles;
