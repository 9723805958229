import { createUseStyles } from "react-jss";

const RejectedUserRequestsStyles = createUseStyles({
  rowStyle: {
    "& div>table>tbody>tr>td": {
      fontFamily: "Bogle",
      height: "24px",
      fontWeight: "normal",
      fontStrech: "normal",
      lineHeight: "1.33",
    },
    "& div>div>table>thead>tr>th": {
      color: "#000000",
      fontWeight: "bold",
    },
  },
  exportName: {
    marginBottom: 20
  },
  exportNameAs: {
    paddingTop: 20,
    marginBottom: 20
  },
  modalSubText: {
    fontSize: 14,
    fontWeight: 'normal'
  },
  addNewBtnWrap: {
    margin: "0 0.8rem",
  }
});

export default RejectedUserRequestsStyles;
