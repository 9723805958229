import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import {
  Grid, GridColumn, Breadcrumb, BreadcrumbItem, ButtonGroup,
  Button, useSnackbar, Modal, Body, Heading, Divider,
} from "@walmart-web/livingdesign-components";
import DocumentDropzone from "../../../../components/DocumentDropzone/DocumentDropzone";
import {
  MAX_FILE_UPLOAD_SIZE, ACCEPTED_INVOICES_FILES_LABEL, acceptedFilesForInvoices,
  MAX_FILE_UPLOAD_SIZE_INVOICES, SUBMISSION_STATUS, UPLOAD_INVOICES, FINANCIALS, ASYNC, SYNC, BULK_INVOICE_CREATE, LNG_BJO_INVOICE_VALIDATION_ERROR,
} from "../../../../constants/constants";
import { postServiceWithHeaders } from "../../../../axios/axios";
import { JOB_SERVICE } from "../../../../constants/baseURLs";
import { renderTernary } from "../../../../helpers/utils";
import {
  GO_BACK, VALIDATE_AND_SUBMIT, CANCEL,
  OK, DOWNLOAD_ERROR_DETAILS } from "../../../../constants/actionButtons";
import {
  FILE_UPLOAD_SIZE_LIMIT_MSG, INVOICE_UPLOAD_CONFIRMATION_MSG, INVOICE_SUBMISSION_IN_PROGRESS_MSG, SOMETHING_WENT_WRONG
} from "../../../../constants/messages";
import  {
  invoiceUploadSuccessModalText, invoiceUploadPartialSuccessText1, invoiceUploadPartialSuccessText2, invoiceErrorMessage,
} from "../../../../constants/templates";
import UploadInvoiceStyles from "./UploadInvoice.styles";

/**
 * Function to format file data
 * @param {string} files
 * @returns array of files data
 */
export const formatFilesData = (files) => {
  const list = [];
  files?.map(item => {
    list.push({
      id: item?.name?.split('.').slice(0, -1).join('.'),
      fileName: item?.name?.split('.').slice(0, -1).join('.'),
      size: item?.size,
    })
  })
  return list;
}

/**
 * Component to render upload in progress modal
 * @param {Boolean} loading
 * @returns
 */
const UploadInprogressModal = (props) => {
  const { loading } = props;
  const classes = UploadInvoiceStyles();
  return (
    <Modal
      isOpen={loading}
      size="small"
      title={" "}
      id="upload-in-progress-modal"
      data-testid="upload-in-progress-modal"
      UNSAFE_className={classes.hideModalTitle}
    >
      <Heading as="h4" UNSAFE_className={classes.uploadInprogressModalContent}>
        {INVOICE_SUBMISSION_IN_PROGRESS_MSG}
      </Heading>
    </Modal>
  )
}

/**
 * Component to render upload success Modal
 * @param {*} count - count of successfully uploaded files
 * @param {Boolean} showUploadSuccessModal - Boolean state to show/hide upload success Modal
 * @param {*} setShowUploadSuccessModal - Callback to set show/hide upload success Modal
 * @returns
 */
export const UploadSuccessModal = (props) => {
  const { count, showUploadSuccessModal, setShowUploadSuccessModal } = props;

  return <Modal
    isOpen={showUploadSuccessModal}
    onClose={() => setShowUploadSuccessModal(false) }
    size="medium"
    title={SUBMISSION_STATUS}
    id="upload-success-modal"
    data-testid="upload-success-modal"
  >
    <Body size="medium" weight={700}>
      {invoiceUploadSuccessModalText({count: count})}
    </Body>
  </Modal>
}

/**
 * Component to render upload partial success Modal
 * @param {Boolean} showPartialSuccess - Boolean state to show/hide partial success Modal
 * @param {*} setShowPartialSuccess - Callback to set show/hide partial success Modal
 * @param {*} setShowConfirmationModal - Callback to set show/hide confirmation Modal
 * @param {*} successCount - count of successfully uploaded files
 * @param {Array} failedFilesList - List of files failed to upload
 * @returns
 */
export const UploadPartialSuccessModal = (props) => {
  const {
    showPartialSuccess,
    setShowPartialSuccess,
    setShowConfirmationModal,
    successCount,
    failedFilesList,
    downloadMessage } = props;
  const classes = UploadInvoiceStyles();

  // Function to form and  text file and download error report
  const handleErrorReportDownload = () => {
    const fileData = downloadMessage.join('\n');
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "invoice-upload-errors.txt";
    link.href = url;
    link.click();
  }

  const downloadActionButton = () => {
    return (
      <Button
        variant="primary"
        size="small"
        data-testid="download-btn"
        id="download-btn"
        onClick={() => {setShowPartialSuccess(false); handleErrorReportDownload()}}
      >
        {DOWNLOAD_ERROR_DETAILS}
      </Button>
    )
  }

  return <Modal
    isOpen={showPartialSuccess}
    size="large"
    title={SUBMISSION_STATUS}
    onClose={() => {setShowPartialSuccess(false); setShowConfirmationModal(true)}}
    actions={downloadActionButton()}
    id="upload-partial-success-modal"
    data-testid="upload-partial-success-modal"
  >
    <Body as="p" size="medium" weight={400}>
      {invoiceUploadPartialSuccessText1({successCount: successCount})}
    </Body>
    <Body as="p" size="medium" weight={700}>
      {invoiceUploadPartialSuccessText2({count: failedFilesList?.length})}
    </Body>
    <Divider UNSAFE_className={classes.uploadPartialDivider} />
    <div className={classes.failedFilesList}>
      {failedFilesList?.map(item => {
        return <Body as="p" size="medium" weight={400}key={item.fileName} UNSAFE_className={classes.uploadPartialListItem}>
          {item.fileName}
        </Body>
      })}
    </div>
  </Modal>
}

/**
 * Component to render upload confirmation Modal
 * @param {*} setShowPartialSuccess - Callback to set show/hide partial success Modal
 * @param {Boolean} showConfirmationModal - Boolean state to show/hide confirmation Modal
 * @param {*} setShowConfirmationModal - Callback to set show/hide confirmation Modal
 * @returns
 */
export const UploadConfirmationModal = (props) => {
  const { setShowPartialSuccess, showConfirmationModal, setShowConfirmationModal } = props;
  const classes = UploadInvoiceStyles();

  const confirmationActionButtons = () => {
    return <ButtonGroup>
      <Button
        size="small"
        variant="tertiary"
        data-testid="cancel-btn"
        id="cancel-btn"
        onClick={() => {setShowPartialSuccess(true); setShowConfirmationModal(false);}}
      >
        {CANCEL}
      </Button>
      <Button
        variant="primary"
        size="small"
        data-testid="ok-btn"
        id="ok-btn"
        onClick={() => setShowConfirmationModal(false)}
      >
        {OK}
      </Button>
    </ButtonGroup>
  }

  return <Modal
    isOpen={showConfirmationModal}
    size="small"
    title={" "}
    actions={confirmationActionButtons()}
    data-testid="upload-confirmation-modal"
    id="upload-confirmation-modal"
    UNSAFE_className={classes.hideModalTitle}
  >
    <Body as="p" size="medium" weight={700} UNSAFE_className={classes.uploadConfirmationText}>
      {INVOICE_UPLOAD_CONFIRMATION_MSG}
    </Body>
  </Modal>
}

/** Component for Invoice upload */
const UploadInvoice = () => {
  const classes = UploadInvoiceStyles();
  const history = useHistory();
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesFormData, setFilesFormData] = useState();
  const [showPartialSuccess, setShowPartialSuccess] = useState(false);
  const [failedFilesList, setFailedFilesList] = useState([]);
  const [successCount, setSuccessCount] = useState(0);
  const [downloadMessage, setDownloadMessage] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showUploadSuccessModal, setShowUploadSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [fileReadLoading, setFileReadLoading] = useState(false);
  const { addSnack } = useSnackbar();
  const orgId = useSelector((state) => state?.organization?.organizationDetailValues.identifier);

  /**
   * Callback to handle when file is dropped in dropzone
   * @param {array} files
   */
  const handleFileUpload = (files) => {
    let newFiles = files;
    setFilesFormData(newFiles);
    const uploadedDocs = formatFilesData(newFiles);
    setFilesToUpload(uploadedDocs)
  }

  const getInvoiceErrorMsg = (result,fileName) => {
    const errResponse = JSON.parse(result?.reason?.response?.data)
    if(errResponse?.errorCode === LNG_BJO_INVOICE_VALIDATION_ERROR)
    {
      let errorMsg = ""
      Object.entries(JSON.parse(errResponse?.message))?.forEach(([key,value],index) => {
        errorMsg = errorMsg + invoiceErrorMessage({value: value, index: index}) +"\n"
      });
      return `${fileName} - \n${errorMsg}`;
    }
    else if(fileName && errResponse?.message) {
      return `${fileName} - \n${errResponse?.message}`;
    } else {
      return SOMETHING_WENT_WRONG;
    }
  }

  /**
   * UseEffect to validate file upload size & enable/disable upload button
   */
  useEffect(() => {
    if(filesToUpload?.length > 0) {
      const totalUploadSize = filesToUpload.reduce((acc, obj) => acc + obj['size'], 0);
      setDisableSaveButton(totalUploadSize > MAX_FILE_UPLOAD_SIZE)
      if(totalUploadSize > MAX_FILE_UPLOAD_SIZE) {
        addSnack({
          message: FILE_UPLOAD_SIZE_LIMIT_MSG
        });
      }
    } else {
      setDisableSaveButton(true)
    }
  }, [filesToUpload])

  /** Callback function to handle file drop */
  const handleOnDrop = () => {
    setFileReadLoading(true)
  }

  /** Function to delete all the uploaded files and reset the screen */
  const handleCancelUpload = () => {
    setKey(key+1)
    history.push('/financial', { currentFinancialTab: 1 })
  }

  /** Function to return back to previous screen on save button click */
  const handleSaveUpload = () => {
    if(filesFormData?.length > 0) {
      const promises = [];
      filesFormData?.forEach((file)=>{
        setLoading(true);const headers = {
          'Content-Type': `multipart/form-data`,
        }
        const requestData = {
          file: file,
          executionType: ASYNC,
          validationType: SYNC,
          command: BULK_INVOICE_CREATE,
          dataBag : JSON.stringify({ "organizationId": orgId })
        }
        promises.push({promise: postServiceWithHeaders(JOB_SERVICE, "/jobs/v1/submit", requestData, headers), request: requestData});
      })

      // Resolve the file upload(s)
      Promise.allSettled(promises.map(obj => obj.promise))
        .then((res) => {
          let isFileUploadFailed = false;
          let failures = [];

          const messages = res.map((result, index) => {
            const fileName = promises[index]?.request?.file?.name;
            if (result?.status !== 'fulfilled') {
              isFileUploadFailed = true;
              failures.push({fileName: fileName})
              return getInvoiceErrorMsg(result, fileName);
            }
          });
          // Removing undefined from the array
          const finalMessages = messages.filter(item => item !== undefined);
          setSuccessCount(filesFormData?.length - failures?.length)
          setFailedFilesList(failures)

          setLoading(false);
          if(isFileUploadFailed) {
            setShowPartialSuccess(true);
            setDownloadMessage(finalMessages)
          } else {
            setShowUploadSuccessModal(true)
          }

          setKey(key+1)
        })
        .catch((error) => {
          setLoading(false);
          setKey(key+1)
        });
    }
  }

  /**  Reset dropzone */
  useEffect(() => {
    setKey(key+1)
  }, [])

  return (
    <Grid className={classes.addDocWrap} data-testid="invoice-upload">
      <GridColumn sm={12}>
          <Breadcrumb>
            <BreadcrumbItem
              data-testid="breadcrumb-financial"
              onClick={() => history.push('/financial', { currentFinancialTab: 1 })}>
                {FINANCIALS}
            </BreadcrumbItem>
            <BreadcrumbItem
              data-testid="breadcrumb-upload-invoice" href="">
                {UPLOAD_INVOICES}
            </BreadcrumbItem>
          </ Breadcrumb>
        <div className={classes.dropzoneWrap}>
          <Grid>
            <GridColumn lg="12" data-testid="document-dropzone" id="document-dropzone">
              <Heading as="h4" UNSAFE_className={classes.uploadInvoicesTitle}>
                {UPLOAD_INVOICES}
              </Heading>
              <DocumentDropzone
                key={key}
                showFilterIcon={true}
                acceptedFilesLabel={ACCEPTED_INVOICES_FILES_LABEL}
                acceptedFileTypes={acceptedFilesForInvoices}
                onFileUpload={handleFileUpload}
                onFileDrop={handleOnDrop}
                fileReadLoading={fileReadLoading}
                fileDropStatus={true}
                showFilePreview={true}
                hideInfoMsg={true}
                maxFileUploadSize={MAX_FILE_UPLOAD_SIZE_INVOICES}
                fileUploadInfoMsg={'Maximum file upload size is 10MB.'}
                dropZoneLabel={`Upload Invoices`}
              />
            </GridColumn>
          </Grid>
          <Grid align="right">
            <GridColumn>
              <div className={`${classes.contentSpacing24} ${classes.uploadButtonWrap}`}>
                <Button
                  size="medium" variant="secondary"
                  className={classes.goBackButton}
                  onClick={handleCancelUpload}
                  data-testid="cancel-upload" id="cancel-upload">
                    {GO_BACK}
                </Button>
                {renderTernary(filesToUpload?.length > 0,
                  <Button
                    size="medium" variant="primary"
                    disabled={disableSaveButton || fileReadLoading || loading}
                    onClick={handleSaveUpload}
                    data-testid="save-upload" id="save-upload">
                      {VALIDATE_AND_SUBMIT}
                  </Button>, <></>
                )}
              </div>
            </GridColumn>
          </Grid>
        </div>

        <UploadInprogressModal loading={loading} />
        <UploadSuccessModal
          count={successCount}
          showUploadSuccessModal={showUploadSuccessModal}
          setShowUploadSuccessModal={setShowUploadSuccessModal}
          />
        <UploadPartialSuccessModal
          successCount={successCount}
          failedFilesList={failedFilesList}
          showPartialSuccess={showPartialSuccess}
          setShowPartialSuccess={setShowPartialSuccess}
          setShowConfirmationModal={setShowConfirmationModal}
          downloadMessage={downloadMessage}
          />
        <UploadConfirmationModal
          setShowPartialSuccess={setShowPartialSuccess}
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          />
      </GridColumn>
    </Grid>
  );
};

export default UploadInvoice;
