import React, { useState } from "react";
import {
  TabNavigation,
  TabNavigationItem
} from "@walmart-web/livingdesign-components";
import { createUseStyles } from "react-jss";

import Listing from "../Listing/Listing";
import BudgetList from "./Budget/BudgetList";
import { useLocation } from "react-router-dom";
import Invoice from "./Invoice/FinancialInvoiceList";
import { useSelector } from "react-redux";
import { getTabList } from "../../helpers/utils";

const useStyles = createUseStyles({
  detailWrapper: {
    padding: "15px",
  },
  detailItem: {
    display: "inline-block",
    paddingRight: "62px",
  },
  smallText: {
    display: "block",
    paddingBottom: 4,
    fontSize: "14px",
    textAlign: "center",
  },
  detail: {
    textAlign: "center",
    "& h5": {
      fontSize: "18px",
    },
  },
  statusButton: {
    border: "1px solid #1d5f02",
    color: "#1d5f02",
    background: "#fff",
    fontSize: "11px",
    padding: "4px 10px 3px",
    borderRadius: "2px",
  },
  lightGreen: {
    color: "#2a8703",
  },
  financialTabNavigation: {
    "& nav > ul > li > a": {
      background: "none",
    }
  },
  star: {
    marginRight: "24px",
    height: "24px",
    width: "24px"
  },
  starFilled: {
    marginRight: "14px",
    height: "44px",
    width: "44px"
  },
  icon: {
    cursor: "pointer",
  },
  financialActionButtons: {
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  financialWrapper: {
    background: "#F5F5F5",
    width: "100%"
  }
});

/**
 * FinancialList component - It will use for displaying the overview of financial
 * alongside with Budgets, Invoices and workflows
 *
 */
const Financial = () => {
  const classes = useStyles();
  const financialAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.financial);
  const { listOfTabs, tabIndexMap } = getTabList(financialAccessibility);
  const {state: {currentFinancialTab : currentTab} = {}} = useLocation();
  const [isCurrent, setIsCurrent] = useState(currentTab || tabIndexMap[listOfTabs[0]]);

  const tabContent = () => {
    switch (isCurrent) {
      case tabIndexMap["Budgets"]:
        return <div data-testid="budget-wrapper"><BudgetList currentFinancialTab={isCurrent} /></div>;
      case tabIndexMap["Invoices"]:
        return <Invoice currentFinancialTab={isCurrent}/>;
      default:
        return null;
    }
  };

  return (
    <div className={classes.financialWrapper}>
    <Listing title="Financials" subtitle=" " tag={false}>
      <div className={classes.financialTabNavigation}>
        <TabNavigation>
          {listOfTabs?.map((item, index) => (
            <TabNavigationItem
              data-testid={`financial-tab-${tabIndexMap[item]}`}
              id={`financial-tab-${tabIndexMap[item]}`}
              onClick={() => setIsCurrent(tabIndexMap[item])}
              isCurrent={isCurrent === tabIndexMap[item]}
              key={tabIndexMap[item]}
            >
              {item}
            </TabNavigationItem>
          ))}
        </TabNavigation>
      </div>
      <div data-testid="financial-content" id='tab-content'>
      {tabContent()}
      </div>
    </Listing>
    </div>
  );
};

export default Financial;
